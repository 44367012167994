import router from "./router";
import store from "./store";
import { isLogged } from "@/utils/auth";
import { PermRedirect } from "@/router/permRedirect";

const whiteList = ["/login", "/auth-redirect"];

router.beforeEach(async (to, from, next) => {
  store.state.settings.previousUrl = from;
  const isUserLogged = isLogged();
  if (isUserLogged) {
    if (to.path === "/login") {
      next({ path: "/" });
    } else {
      // const { permissionsData } = await store.dispatch('user/getInfo');
      // const permissions = permissionsData.routePermissions;

      try {
        const permissions = localStorage.getItem("permissions").split(",");
        // Ak má routa permissnu, a user nie, tak ho presmeruje na dashboard
        if (to.meta.permission && to.meta.permission.length > 0) {
          let isAllowed = permissions.some((p) =>
            to.meta.permission.includes(p)
          );
          if (!isAllowed) {
            next(PermRedirect.nextRoute(to, from, permissions));
          } else {
            next();
          }
        }
      } catch {
        store.dispatch("user/logout");
        router.push(`/login?redirect=${this.$route.fullPath}`);
      }

      // Toto filtruje Routy, ale je tam nejaky bug
      // const accessRoutes = await store.dispatch("permission/generateRoutes", {
      //   permissions,
      // });
      // router.addRoutes(accessRoutes);

      next();
    }
  } else {
    if (whiteList.indexOf(to.matched[0] ? to.matched[0].path : "") !== -1) {
      next();
    } else if (to.meta.guest) {
      next();
    } else {
      next(`/login?redirect=${to.path}`);
    }
  }
});
