<template>
  <v-card class="auditor-integrity-score-chart-widget-block">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="savedTime-none"
    >
      <widget-toolbar
        v-if="!loading && !failed"
        :title="$t('widgets.auditorscore.integrity.headline')"
        :data-time="dataLoadedTime"
        :mount-time="time"
        has-timeline
        @timeline="changeTime($event)"
        @remove="remove"
      />
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="savedTime-none"
    >
      <v-card-text v-if="!loading && !failed" class="with-bg pb-4 pt-3">
        <chart
          :x-axis-data="xAxisData"
          :data="[data.devices, data.rules, data.score]"
        />
      </v-card-text>
    </transition>
    <v-overlay :value="loading">
      <v-progress-circular absolute indeterminate size="40" color="good-3" />
    </v-overlay>
    <v-overlay :value="failed">
      <v-card-text v-if="failed">
        {{ $t("widgets.auditorscore.integrity.headline") }} - loading data from
        api failed.
      </v-card-text>
      <v-card-actions>
        <v-btn small color="bad-2" class="mx-auto" @click="loadData">
          Reload
        </v-btn>
      </v-card-actions>
    </v-overlay>
  </v-card>
</template>

<script>
import chart from "@/components/Widgets/AuditorIntegrityScoreChartWidget/chart";
import { widgetAuditorScoreIntegrityChart } from "@/api/dashboard";
import WidgetToolbar from "@/components/Widgets/WidgetToolbar";

export default {
  components: {
    chart,
    WidgetToolbar,
  },
  props: {
    i: {
      type: String,
      default: function () {
        return "0";
      },
    },
    w: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    h: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {
      loading: true,
      failed: false,

      dataLoadedTime: "",
      updatedTime: "lastweek",
      time: "lastweek",
      data: {
        devices: {
          name: "Devices",
          type: "line",
          showSymbol: false,
          data: [],
          color: "#8cd4dd",
          lineStyle: {
            width: 5,
          },
        },
        rules: {
          name: "Rules",
          type: "line",
          showSymbol: false,
          data: [],
          color: "#a6dc86",
          lineStyle: {
            width: 4,
          },
        },
        score: {
          name: "Score",
          type: "line",
          showSymbol: false,
          data: [],
          color: "#f28d9f",
          lineStyle: {
            width: 3,
          },
        },
      },
      xAxisData: [],
    };
  },
  created() {
    this.loadData();
  },
  mounted() {
    setTimeout(() => {
      this.loadData();
    }, 1);
  },
  methods: {
    remove() {
      this.$emit("remove", this.i);
    },
    changeTime(time) {
      this.time = time;
      this.updatedTime = "";
      this.loadData();
    },
    loadData() {
      this.failed = false;
      this.loading = true;

      const data = {
        period: this.time,
      };

      if (this.updatedTime !== this.time) {
        this.updatedTime = this.time;
        widgetAuditorScoreIntegrityChart(data)
          .then((response) => {
            const self = this;
            this.clearData();
            response.data.forEach(function (value) {
              self.xAxisData.push(value.run_at);

              self.data.devices.data.push(value.devices);
              self.data.rules.data.push(value.all_count);
              self.data.score.data.push(value.score);
            });
            this.loading = false;

            const storedData = {
              xAxisData: this.xAxisData,
              data: this.data,
              time: data.period,
            };

            localStorage.setItem(
              "widgetAuditorIntegrityScoreChartData",
              JSON.stringify(storedData)
            );
            localStorage.setItem(
              "widgetAuditorIntegrityScoreChartTimestamp",
              Date.now()
            );
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }

      const storedData = JSON.parse(
        localStorage.getItem("widgetAuditorIntegrityScoreChartData")
      );
      const savedTime = JSON.parse(
        localStorage.getItem("widgetAuditorIntegrityScoreChartTimestamp")
      );
      if (savedTime && Date.now() - parseInt(savedTime, 10) < 300000) {
        this.dataLoadedTime = "";
        const transformedTimeStamp = new Date(savedTime);
        const hours = transformedTimeStamp.getHours();
        const minutes = transformedTimeStamp.getMinutes();
        this.dataLoadedTime = `${hours}:${minutes}`;
      }

      if (
        storedData &&
        savedTime &&
        Date.now() - parseInt(savedTime, 10) < 300000
      ) {
        this.updateWidgetData(storedData);
        this.time = storedData.time;
        this.updatedTime = storedData.time;
        this.loading = false;
      } else {
        widgetAuditorScoreIntegrityChart(data)
          .then((response) => {
            const self = this;
            this.clearData();
            response.data.forEach(function (value) {
              self.xAxisData.push(value.run_at);

              self.data.devices.data.push(value.devices);
              self.data.rules.data.push(value.all_count);
              self.data.score.data.push(value.score);
            });
            this.loading = false;

            const storedData = {
              xAxisData: this.xAxisData,
              data: this.data,
              time: data.period,
            };

            localStorage.setItem(
              "widgetAuditorIntegrityScoreChartData",
              JSON.stringify(storedData)
            );
            localStorage.setItem(
              "widgetAuditorIntegrityScoreChartTimestamp",
              Date.now()
            );

            const savedTime = JSON.parse(
              localStorage.getItem("widgetSystemLogsTimestamp")
            );

            this.dataLoadedTime = "";
            const transformedTimeStamp = new Date(savedTime);
            const hours = transformedTimeStamp.getHours();
            const minutes = transformedTimeStamp.getMinutes();
            this.dataLoadedTime = `${hours}:${minutes}`;
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }
    },
    updateWidgetData(storedData) {
      this.xAxisData = storedData.xAxisData;
      this.data = storedData.data;
    },
    clearData() {
      this.xAxisData = [];
      this.data.devices.data = [];
      this.data.rules.data = [];
      this.data.score.data = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-icon {
  width: 65px;
  height: 50px;
}
.v-card-text {
  background: #24272169;
  border-radius: 0;
}
.v-card__text.with-bg:not(.with-graph) {
  border-radius: 0 0 15px 15px !important;
}
.v-card__actions {
  border-radius: 0 0 15px 15px !important;
}
.dashboard-container {
  .v-card.theme--dark {
    .v-card__title {
      border-bottom: 2px solid #fff;
    }
  }
}
.dashboard-container {
  .v-card.theme--light {
    .v-card__title {
      border-bottom: 2px solid #3d3d3f;
    }
  }
}
</style>
