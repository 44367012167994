<template>
  <v-container
    fluid
    class="synapsa-settings-block"
    :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
  >
    <v-row>
      <v-col cols="12" class="pt-0 pb-2 px-0">
        <v-list-item-title class="font-roboto-condensed-regular">
          Table settings
          <v-btn
            text
            color="bad-3"
            height="25"
            class="text-capitalize"
            @click="setDefault"
          >
            set default
          </v-btn>
        </v-list-item-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" md="3" xl="2" class="pt-0 pl-0">
        <v-list-item-title
          class="font-roboto-condensed-regular font-size-15 mb-1"
          >Headers</v-list-item-title
        >
        <draggable :list="headers" @end="update" group="columns" class="h-100">
          <v-row
            v-for="column in headers"
            :key="column.value"
            class="my-0 mx-0 mb-2 cursor-pointer font-ubuntu-bold drag-column-block"
            :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
          >
            <v-col cols="9" class="py-2 font-size-12">
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <span v-if="column.visible">{{ column.text }}</span>
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <span v-if="!column.visible" class="primary--text">{{
                  column.text
                }}</span>
              </transition>
            </v-col>
            <v-col cols="3" class="pt-1 pb-0 pr-2 font-size-15 text-right">
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-avatar
                  v-if="column.visible"
                  size="22"
                  class="br-0"
                  @click="[(column.visible = !column.visible), update()]"
                >
                  <img
                    :src="
                      $vuetify.theme.dark
                        ? location + '/images/icons/white/minus-white.svg'
                        : location + '/images/icons/dark/minus.svg'
                    "
                    alt="minus"
                  />
                </v-avatar>
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-avatar
                  v-if="!column.visible"
                  size="22"
                  class="br-0"
                  @click="[(column.visible = !column.visible), update()]"
                >
                  <img
                    :src="location + '/images/icons/green/plus-green.svg'"
                    alt="plus"
                  />
                </v-avatar>
              </transition>
            </v-col>
          </v-row>
        </draggable>
      </v-col>
      <v-col cols="4" md="3" xl="2" class="pt-0 px-0">
        <v-list-item-title
          class="font-roboto-condensed-regular font-size-15 mb-1"
          >Expanded area</v-list-item-title
        >
        <draggable :list="expanded" @end="update" group="columns" class="h-100">
          <v-row
            v-for="column in expanded"
            :key="column.value"
            class="my-0 mx-0 mb-2 cursor-pointer font-ubuntu-bold drag-column-block"
            :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
          >
            <v-col cols="9" class="py-2 font-size-12">
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <span v-if="column.visible">{{ column.text }}</span>
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <span v-if="!column.visible" class="primary--text">{{
                  column.text
                }}</span>
              </transition>
            </v-col>
            <v-col cols="3" class="pt-1 pb-0 pr-2 font-size-15 text-right">
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-avatar
                  v-if="column.visible"
                  size="20"
                  class="br-0"
                  @click="[(column.visible = !column.visible), update()]"
                >
                  <img
                    :src="
                      $vuetify.theme.dark
                        ? location + '/images/icons/white/minus-white.svg'
                        : location + '/images/icons/dark/minus.svg'
                    "
                    alt="minus"
                  />
                </v-avatar>
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-avatar
                  v-if="!column.visible"
                  size="20"
                  class="br-0"
                  @click="[(column.visible = !column.visible), update()]"
                >
                  <img
                    :src="location + '/images/icons/green/plus-green.svg'"
                    alt="plus"
                  />
                </v-avatar>
              </transition>
            </v-col>
          </v-row>
        </draggable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { Errors } from "@/utils/error";
import { setUserTableSettings } from "@/api/system/user";
import { debounce } from "@/utils";
import { Tables } from "@/utils/tables";

export default {
  components: {
    draggable,
  },
  props: {
    process: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      headers: null,
      expanded: null,
      location: "",
    };
  },
  computed: {
    monitorInterconnectorSuccessfulTable: {
      get() {
        return this.$store.state.tables.monitorInterconnectorSuccessfulTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorInterconnectorSuccessfulTable",
          value: val,
        });
      },
    },
    monitorInterconnectorUnsuccessfulTable: {
      get() {
        return this.$store.state.tables.monitorInterconnectorUnsuccessfulTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorInterconnectorUnsuccessfulTable",
          value: val,
        });
      },
    },
    monitorInterconnectorRejectTable: {
      get() {
        return this.$store.state.tables.monitorInterconnectorRejectTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorInterconnectorRejectTable",
          value: val,
        });
      },
    },
    monitorInterconnectorRevokeTable: {
      get() {
        return this.$store.state.tables.monitorInterconnectorRevokeTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorInterconnectorRevokeTable",
          value: val,
        });
      },
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loadData(newValue);
      },
    },
    process: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.loadData(this.$route);
        }
      },
    },
  },
  methods: {
    loadData(route) {
      if (route.name === "successful") {
        this.headers = this.monitorInterconnectorSuccessfulTable.headers;
        this.expanded = this.monitorInterconnectorSuccessfulTable.expanded;
      } else if (route.name === "interconnectorAlertUnsuccessful") {
        this.headers = this.monitorInterconnectorUnsuccessfulTable.headers;
        this.expanded = this.monitorInterconnectorUnsuccessfulTable.expanded;
      } else if (route.name === "interconnectorAlertReject") {
        this.headers = this.monitorInterconnectorRejectTable.headers;
        this.expanded = this.monitorInterconnectorRejectTable.expanded;
      } else if (route.name === "revoke") {
        this.headers = this.monitorInterconnectorRevokeTable.headers;
        this.expanded = this.monitorInterconnectorRevokeTable.expanded;
      }
    },
    update: debounce(function () {
      let data = null;
      if (this.$route.name === "successful") {
        data = {
          table_name: "monitorInterconnectorSuccessfulTable",
          table_data: JSON.stringify(this.monitorInterconnectorSuccessfulTable),
        };
      } else if (this.$route.name === "interconnectorAlertUnsuccessful") {
        data = {
          table_name: "monitorInterconnectorUnsuccessfulTable",
          table_data: JSON.stringify(
            this.monitorInterconnectorUnsuccessfulTable
          ),
        };
      } else if (this.$route.name === "interconnectorAlertReject") {
        data = {
          table_name: "monitorInterconnectorRejectTable",
          table_data: JSON.stringify(this.monitorInterconnectorRejectTable),
        };
      } else if (this.$route.name === "revoke") {
        data = {
          table_name: "monitorInterconnectorRevokeTable",
          table_data: JSON.stringify(this.monitorInterconnectorRevokeTable),
        };
      }
      setUserTableSettings(data)
        .then(() => {
          if (this.$route.name === "successful") {
            Tables.setSetting(
              this.$route.name,
              this.monitorInterconnectorSuccessfulTable
            );
          } else if (this.$route.name === "interconnectorAlertUnsuccessful") {
            Tables.setSetting(
              this.$route.name,
              this.monitorInterconnectorUnsuccessfulTable
            );
          } else if (this.$route.name === "interconnectorAlertReject") {
            Tables.setSetting(
              this.$route.name,
              this.monitorInterconnectorRejectTable
            );
          } else if (this.$route.name === "revoke") {
            Tables.setSetting(
              this.$route.name,
              this.monitorInterconnectorRevokeTable
            );
          }
          this.$toast.open({
            message: "Table was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
        })
        .catch((e) => {
          Errors.show(e);
        });
    }, 500),
    setDefault() {
      if (this.$route.name === "successful") {
        this.$emit("default", "monitorInterconnectorSuccessfulTable");
      } else if (this.$route.name === "interconnectorAlertUnsuccessful") {
        this.$emit("default", "monitorInterconnectorUnsuccessfulTable");
      } else if (this.$route.name === "interconnectorAlertReject") {
        this.$emit("default", "monitorInterconnectorRejectTable");
      } else if (this.$route.name === "revoke") {
        this.$emit("default", "monitorInterconnectorRevokeTable");
      }
    },
  },
  created() {
    this.location = window.location.origin;
  },
};
</script>
