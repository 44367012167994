import request from "@/utils/request";

export function getRevoke(page, size) {
  return request({
    url: "api/monitor/interconnector/revoked?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function revokeCall(data, id) {
  return request({
    url: "api/interconnector/securitypolicy/" + id + "/revoke",
    method: "post",
    data: data,
  });
}
