import Layout from "@/layout";

const interconnectorRoutes = {
  path: "/interconnector",
  name: "interconnector",
  component: Layout,
  meta: {
    requiresAuth: true,
    icon: "interconnector.svg",
    permission: ["view menu interconnector"],
  },
  redirect: "/interconnector/security_policy",
  children: [
    {
      path: "security_policy",
      component: () => import("@/views/interconnector/securityPolicy"),
      name: "securityPolicy",
      meta: {
        id: "securityPolicy",
        active: ["securityPolicy"],
        icon: "security-policy.svg",
        permission: ["view securitypolicy"],
      },
    },
    {
      path: "rules",
      name: "rules",
      component: () => import("@/views/interconnector/rules/index"),
      redirect: "/interconnector/rules/default",
      meta: {
        active: ["rules", "default"],
        icon: "interconnector-rules.svg",
      },
      children: [
        {
          path: "default",
          component: () => import("@/views/interconnector/rules/default"),
          name: "default",
          meta: {
            active: ["rules", "default"],
            permission: ["view rules"],
          },
          hidden: true,
        },
        {
          path: "script",
          component: () => import("@/views/interconnector/rules/script"),
          name: "script",
          meta: {
            active: ["rules", "script"],
            permission: ["view rules"],
          },
          hidden: true,
        },
      ]
    },
  ],
};

export default interconnectorRoutes;
