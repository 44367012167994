<template>
  <v-card>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <widget-toolbar
        v-if="!loading && !failed"
        :title="$t('widgets.auditorscore.headline')"
        :data-time="dataLoadedTime"
        :select-items="selectItems"
        :selected-item="selectedItem"
        :local-selected-item="localSelectedItem"
        has-select-menu
        @remove="remove"
        @onSelect="selectedItem = $event"
      />
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-card-text v-if="!loading && !failed" class="with-bg pt-1 pb-4">
        <v-data-table
          :headers="headers"
          :items="data"
          hide-default-footer
          item-key="auditors-score-table"
          :items-per-page="size"
          :loading="loading"
          loading-text="Loading data from API...please wait"
        />
        <v-pagination
          v-if="data.length > 0"
          v-model="page"
          :length="pageTotalCount"
          :total-visible="7"
          circle
          class="d-inline-block pagination px-1 py-1"
          :class="page > 99 ? 'over100' : 'less100'"
        />
      </v-card-text>
    </transition>
    <v-overlay :value="loading">
      <v-progress-circular absolute indeterminate size="40" color="good-3" />
    </v-overlay>
    <v-overlay :value="failed">
      <v-card-text>
        {{ $t("widgets.auditorscore.headline") }} - loading data from api
        failed.
      </v-card-text>
      <v-card-actions>
        <v-btn small color="bad-2" class="mx-auto" @click="loadData">
          Reload
        </v-btn>
      </v-card-actions>
    </v-overlay>
    <v-overlay :value="showCountdown">
      <countdown :radius="20" :stroke-width="5" @action="loadData" />
      <v-card-text class="overlay-text">
        {{ $t("widgets.auditorscore.headline") }} - {{ processingMessage }}
      </v-card-text>
    </v-overlay>
  </v-card>
</template>

<script>
import {
  widgetAuditorScoreAll,
  widgetAuditorScoreIntegrity,
  widgetAuditorScoreRuleset,
} from "@/api/dashboard";
import WidgetToolbar from "@/components/Widgets/WidgetToolbar";
import Countdown from "@/components/Countdown";

export default {
  components: {
    WidgetToolbar,
    Countdown,
  },
  props: {
    i: {
      type: String,
      default: function () {
        return "0";
      },
    },
    w: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    h: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {
      loading: true,
      failed: false,
      processingMessage: "",
      showCountdown: false,

      dataLoadedTime: "",
      page: 1,
      updatedPage: 1,
      pageTotalCount: 1,
      size: 5,

      headers: [
        { text: "Device", value: "device", sortable: true, align: "start" },
        { text: "Devices", value: "devices" },
        { text: "Critical", value: "critical", sortable: true },
        { text: "High", value: "high", sortable: true },
        { text: "Medium", value: "medium", sortable: true },
        { text: "Low", value: "low", sortable: true },
      ],
      data: [],
      dataAll: [],
      dataRuleset: [],
      dataIntegrity: [],

      selectItems: [
        { id: 1, name: "All" },
        { id: 2, name: "Ruleset" },
        { id: 3, name: "Integrity" },
      ],
      selectedItem: { id: 1, name: "All" },
      localSelectedItem: { id: 1, name: "All" },
    };
  },
  watch: {
    page: {
      immediate: true,
      deep: true,
      handler() {
        if (this.updatedPage !== this.page) {
          this.loadData();
        }
      },
    },
    selectedItem: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.data = [];

        switch (newValue.id) {
          case 2:
            this.data = this.dataRuleset;
            break;
          case 3:
            this.data = this.dataIntegrity;
            break;
          default:
            this.data = this.dataAll;
        }
        if (
          this.data.length === 0 ||
          this.localSelectedItem.id !== this.selectedItem.id
        ) {
          this.loadData();
        }
      },
    },
    dataAll: {
      deep: true,
      handler(newValue) {
        this.data = newValue;
      },
    },
    dataRuleset: {
      deep: true,
      handler(newValue) {
        this.data = newValue;
      },
    },
    dataIntegrity: {
      deep: true,
      handler(newValue) {
        this.data = newValue;
      },
    },
  },
  created() {
    this.updatedPage = this.page;
    this.loadData();
  },
  methods: {
    async loadData() {
      this.failed = false;
      this.loading = true;
      this.showCountdown = false;
      this.processingMessage = "";

      if (
        this.localSelectedItem.id !== this.selectedItem.id ||
        this.updatedPage !== this.page
      ) {
        this.localSelectedItem.id = this.selectedItem.id;
        switch (this.selectedItem.id) {
          case 2:
            await widgetAuditorScoreRuleset(this.page, this.size)
              .then((response) => {
                this.updateWidgetData(response);
                this.updatedPage = this.page;
                this.loading = false;

                const dataToStore = {
                  response: response,
                  selectedItem: this.selectedItem,
                };

                localStorage.setItem(
                  "widgetAuditorScoreData",
                  JSON.stringify(dataToStore)
                );
                localStorage.setItem("widgetAuditorScoreTimestamp", Date.now());
              })
              .catch((e) => {
                this.$toast.open({
                  message: e.response
                    ? e.response.status + " - Data loading failed!"
                    : e,
                  type: "error",
                  position: "top-right",
                  duration: 2000,
                });
                this.loading = false;
                this.failed = true;
              });
            break;
          case 3:
            await widgetAuditorScoreIntegrity(this.page, this.size)
              .then((response) => {
                this.updateWidgetData(response);
                this.updatedPage = this.page;
                this.loading = false;

                const dataToStore = {
                  response,
                  selectedItem: this.selectedItem,
                };

                localStorage.setItem(
                  "widgetAuditorScoreData",
                  JSON.stringify(dataToStore)
                );
                localStorage.setItem("widgetAuditorScoreTimestamp", Date.now());
              })
              .catch((e) => {
                this.$toast.open({
                  message: e.response
                    ? e.response.status + " - Data loading failed!"
                    : e,
                  type: "error",
                  position: "top-right",
                  duration: 2000,
                });
                this.loading = false;
                this.failed = true;
              });
            break;
          default:
            await widgetAuditorScoreAll(this.page, this.size)
              .then((response) => {
                this.updateWidgetData(response);
                this.updatedPage = this.page;
                this.loading = false;

                const dataToStore = {
                  response: response,
                  selectedItem: this.selectedItem,
                };

                localStorage.setItem(
                  "widgetAuditorScoreData",
                  JSON.stringify(dataToStore)
                );
                localStorage.setItem("widgetAuditorScoreTimestamp", Date.now());
              })
              .catch((e) => {
                this.$toast.open({
                  message: e.response
                    ? e.response.status + " - Data loading failed!"
                    : e,
                  type: "error",
                  position: "top-right",
                  duration: 2000,
                });
                this.loading = false;
                this.failed = true;
              });
        }
      }

      const storedData = JSON.parse(
        localStorage.getItem("widgetAuditorScoreData")
      );
      const savedTime = JSON.parse(
        localStorage.getItem("widgetAuditorScoreTimestamp")
      );
      if (savedTime && Date.now() - parseInt(savedTime, 10) < 300000) {
        this.dataLoadedTime = "";
        const transformedTimeStamp = new Date(savedTime);
        const hours = transformedTimeStamp.getHours();
        const minutes = transformedTimeStamp.getMinutes();
        this.dataLoadedTime = `${hours}:${minutes}`;
      }

      if (storedData && savedTime && Date.now() - savedTime < 5 * 60 * 1000) {
        this.updateWidgetData(storedData.response);
        this.selectedItem = {
          id: storedData.selectedItem.id,
          name: storedData.selectedItem.name,
        };
        this.localSelectedItem = {
          id: storedData.selectedItem.id,
          name: storedData.selectedItem.name,
        };
        this.loading = false;
      } else {
        switch (this.selectedItem.id) {
          case 2:
            await widgetAuditorScoreRuleset(this.page, this.size)
              .then((response) => {
                this.updateWidgetData(response);
                this.updatedPage = this.page;
                this.loading = false;

                const dataToStore = {
                  response: response,
                  selectedItem: this.selectedItem,
                };

                localStorage.setItem(
                  "widgetAuditorScoreData",
                  JSON.stringify(dataToStore)
                );
                localStorage.setItem("widgetAuditorScoreTimestamp", Date.now());

                const savedTime = JSON.parse(
                  localStorage.getItem("widgetSystemLogsTimestamp")
                );

                if (
                  savedTime &&
                  Date.now() - parseInt(savedTime, 10) < 300000
                ) {
                  this.dataLoadedTime = "";
                  const transformedTimeStamp = new Date(savedTime);
                  const hours = transformedTimeStamp.getHours();
                  const minutes = transformedTimeStamp.getMinutes();
                  this.dataLoadedTime = `${hours}:${minutes}`;
                }
              })
              .catch((e) => {
                this.$toast.open({
                  message: e.response
                    ? e.response.status + " - Data loading failed!"
                    : e,
                  type: "error",
                  position: "top-right",
                  duration: 2000,
                });
                this.loading = false;
                this.failed = true;
              });
            break;
          case 3:
            await widgetAuditorScoreIntegrity(this.page, this.size)
              .then((response) => {
                this.updateWidgetData(response);
                this.updatedPage = this.page;
                this.loading = false;

                const dataToStore = {
                  response: response,
                  selectedItem: this.selectedItem,
                };

                localStorage.setItem(
                  "widgetAuditorScoreData",
                  JSON.stringify(dataToStore)
                );
                localStorage.setItem("widgetAuditorScoreTimestamp", Date.now());

                const savedTime = JSON.parse(
                  localStorage.getItem("widgetSystemLogsTimestamp")
                );

                if (
                  savedTime &&
                  Date.now() - parseInt(savedTime, 10) < 300000
                ) {
                  this.dataLoadedTime = "";
                  const transformedTimeStamp = new Date(savedTime);
                  const hours = transformedTimeStamp.getHours();
                  const minutes = transformedTimeStamp.getMinutes();
                  this.dataLoadedTime = `${hours}:${minutes}`;
                }
              })
              .catch((e) => {
                this.$toast.open({
                  message: e.response
                    ? e.response.status + " - Data loading failed!"
                    : e,
                  type: "error",
                  position: "top-right",
                  duration: 2000,
                });
                this.loading = false;
                this.failed = true;
              });
            break;
          default:
            await widgetAuditorScoreAll(this.page, this.size)
              .then((response) => {
                this.updateWidgetData(response);
                this.updatedPage = this.page;
                this.loading = false;

                const dataToStore = {
                  response: response,
                  selectedItem: this.selectedItem,
                };

                localStorage.setItem(
                  "widgetAuditorScoreData",
                  JSON.stringify(dataToStore)
                );
                localStorage.setItem("widgetAuditorScoreTimestamp", Date.now());

                const savedTime = JSON.parse(
                  localStorage.getItem("widgetSystemLogsTimestamp")
                );

                if (
                  savedTime &&
                  Date.now() - parseInt(savedTime, 10) < 300000
                ) {
                  this.dataLoadedTime = "";
                  const transformedTimeStamp = new Date(savedTime);
                  const hours = transformedTimeStamp.getHours();
                  const minutes = transformedTimeStamp.getMinutes();
                  this.dataLoadedTime = `${hours}:${minutes}`;
                }
              })
              .catch((e) => {
                this.$toast.open({
                  message: e.response
                    ? e.response.status + " - Data loading failed!"
                    : e,
                  type: "error",
                  position: "top-right",
                  duration: 2000,
                });
                this.loading = false;
                this.failed = true;
              });
        }
      }

      if (this.processingMessage) {
        this.showCountdown = true;
      }
    },
    updateWidgetData(response) {
      this.dataAll = response.data;
      this.dataRuleset = response.data;
      this.dataIntegrity = response.data;
      this.processingMessage = response.message;
      this.pageTotalCount = parseInt(response.last_page);
      this.page = parseInt(response.current_page);
      this.size = parseInt(response.per_page);
      this.pageTotalCount = parseInt(response.last_page);
    },
    remove() {
      this.$emit("remove", this.i);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-container {
  .v-card.theme--dark {
    .v-card__title {
      border-bottom: 2px solid #fff;
    }
  }
}
.dashboard-container {
  .v-card.theme--light {
    .v-card__title {
      border-bottom: 2px solid #3d3d3f;
    }
  }
}
.widget-icon {
  vertical-align: sub;
  width: 65px;
  height: 50px;
}
.v-card-text {
  background: #24272169;
  border-radius: 0;
}
.v-card__text.with-bg:not(.with-graph) {
  border-radius: 0 0 15px 15px !important;
}
.v-card__actions {
  border-radius: 0 0 15px 15px !important;
}
.v-data-footer__select {
  display: none;
}
.theme--dark.v-data-table {
  background-color: transparent;
  color: #fff;
}
.overlay-text {
  text-align: center;
  position: absolute;
  bottom: 1rem;
}
.theme--light {
  .overlay-text {
    color: #535452;
  }
}
</style>
