<template>
  <v-app id="inspire">
    <v-overlay
      :value="activeLicense && initialSetup"
      :z-index="100000"
      class="synapsa-overlay"
      :dark="$vuetify.theme.dark"
    >
      <initial-setup :location="location" />
    </v-overlay>

    <v-system-bar app height="50" color="secondary">
      <v-img
        contain
        :lazy-src="
          $vuetify.theme.dark
            ? location + defaultImages.synapsaLogoLight
            : location + defaultImages.synapsaLogo
        "
        max-height="45"
        max-width="180"
        :src="
          $vuetify.theme.dark
            ? location + defaultImages.synapsaLogoLight
            : location + defaultImages.synapsaLogo
        "
      />

      <v-spacer></v-spacer>

      <!--      <search />-->

      <v-btn icon @click="updateBackground" class="background-switch-btn">
        <v-avatar size="20" :class="appBackground ? 'withBg' : ''" class="br-0">
          <img
            :src="
              appBackground
                ? $vuetify.theme.dark
                  ? location + '/images/icons/white/background-img-on.svg'
                  : location + '/images/icons/dark/background-img-on.svg'
                : $vuetify.theme.dark
                ? location + '/images/icons/white/background-img-off.svg'
                : location + '/images/icons/dark/background-img-off.svg'
            "
            alt="Add"
          />
        </v-avatar>
      </v-btn>

      <v-btn icon @click="updateDarkMode" class="darkmode-switch-btn">
        <v-avatar size="27" :class="$vuetify.theme.dark ? 'darkmode' : ''">
          <img
            :src="
              $vuetify.theme.dark
                ? location + '/images/icons/green/mode.svg'
                : location + '/images/icons/dark/mode.svg'
            "
            alt="Add"
          />
        </v-avatar>
      </v-btn>

      <add-form-btn :location="location" />

      <!--      <notifications-menu :location="location" />-->

      <user-menu :location="location" />

      <v-btn icon @click="logout" class="logout-btn">
        <v-avatar size="20">
          <img :src="location + '/images/icons/red/logout.svg'" alt="Add" />
        </v-avatar>
      </v-btn>
    </v-system-bar>

    <sidebar :location="location" @mini="mini = $event" />

    <form-sidebar :sidebar-mini="mini" />

    <v-main :style="getAppBackground()">
      <app-bar :location="location" />
      <router-view class="router-view-block" />
    </v-main>

    <!--    <v-footer-->
    <!--        app-->
    <!--        color="transparent"-->
    <!--        height="72"-->
    <!--        inset-->
    <!--    >-->
    <!--      <v-spacer />-->

    <!--      Guides and help-->
    <!--    </v-footer>-->
  </v-app>
</template>

<script>
import AppBar from "./AppBar";
import Sidebar from "./Sidebar";
import FormSidebar from "./FormSidebar";
import UserMenu from "@/components/UserMenu";
import AddFormBtn from "@/components/AddFormBtn";
import InitialSetup from "@/components/InitialSetup";
import { Errors } from "@/utils/error";
import {
  getUserTableSettings,
  setUserBackground,
  setUserDarkmode,
} from "@/api/system/user";
import { Tables } from "@/utils/tables";
import { getApiUrl } from "@/api/base";
import { checkFirstRun } from "@/api/system/synapsa";

export default {
  components: {
    AppBar,
    Sidebar,
    FormSidebar,
    UserMenu,
    AddFormBtn,
    InitialSetup,
  },
  data() {
    return {
      mini: false,
      location: "",
    };
  },
  computed: {
    defaultImages: {
      get() {
        return this.$store.state.settings.defaultImages;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "defaultImages",
          value: val,
        });
      },
    },
    appBackground: {
      get() {
        return this.$store.state.settings.appBackground;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "appBackground",
          value: val,
        });
      },
    },
    screenWidth: {
      get() {
        return this.$store.state.settings.screenWidth;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "screenWidth",
          value: val,
        });
      },
    },
    activeLicense: {
      get() {
        return this.$store.state.settings.activeLicense;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "activeLicense",
          value: val,
        });
      },
    },
    initialSetup: {
      get() {
        return this.$store.state.settings.initialSetup;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "initialSetup",
          value: val,
        });
      },
    },
    apiUrl: {
      get() {
        return this.$store.state.settings.apiUrl;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "apiUrl",
          value: val,
        });
      },
    },
  },
  created() {
    this.checkSetup();
    this.loadApiUrl();
    this.location = window.location.origin;
    this.$vuetify.theme.dark = localStorage.getItem("darkmode") === "true";
    getUserTableSettings()
      .then((response) => {
        Tables.setSettings(response);
        Tables.getSettings();
      })
      .catch((e) => {
        Errors.show(e);
      });
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("click", this.checkIfOverlayActive);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("click", this.checkIfOverlayActive);
  },
  methods: {
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    getAppBackground() {
      if (this.$vuetify.theme.dark) {
        if (this.appBackground) {
          return (
            "background-color: #474d46; background-image: url('" +
            this.location +
            this.defaultImages.background +
            "');"
          );
        } else {
          return "background-color: #474d46;";
        }
      } else {
        if (this.appBackground) {
          return (
            "background-color: #e7ecdf; background-image: url('" +
            this.location +
            this.defaultImages.backgroundLight +
            "');"
          );
        } else {
          return "background-color: #e7ecdf;";
        }
      }
    },
    updateDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      const data = {
        value: this.$vuetify.theme.dark,
      };
      setUserDarkmode(data)
        .then(() => {
          localStorage.setItem(
            "darkmode",
            this.$vuetify.theme.dark ? "true" : "false"
          );
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    updateBackground() {
      this.appBackground = !this.appBackground;
      const data = {
        value: this.appBackground,
      };
      setUserBackground(data)
        .then(() => {
          localStorage.setItem(
            "appBackground",
            this.appBackground ? "true" : "false"
          );
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    checkSetup() {
      checkFirstRun()
        .then((response) => {
          this.initialSetup = response.first;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadApiUrl() {
      getApiUrl()
        .then((response) => {
          this.apiUrl = response.data.web;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-system-bar.secondary.theme--light {
  box-shadow: 13px 10px 30px rgb(0 0 0 / 10%);
}
.v-system-bar.secondary.theme--dark {
  box-shadow: 13px 10px 30px rgb(0 0 0 / 30%);
}
.v-main {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0;
  .router-view-block {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.v-navigation-drawer.sidebar {
  background-repeat: no-repeat;
  background-size: cover;
}
.darkmode-switch-btn {
  .v-avatar {
    -moz-transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    transition: transform 0.5s;
  }
  .v-avatar.darkmode {
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
}
.background-switch-btn,
.darkmode-switch-btn,
.logout-btn {
  opacity: 0.7;
  transition-duration: 0.3s;
}
.background-switch-btn:hover,
.darkmode-switch-btn:hover,
.logout-btn:hover {
  opacity: 1;
}
</style>

<style lang="scss">
.v-navigation-drawer.sidebar {
  .v-list-item__title {
    font-size: 14px;
  }
}
</style>

<style lang="scss"></style>
