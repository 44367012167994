<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-show="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pb-2 pt-3 px-5 mb-2">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Online Update" : "Offline Update" }}
            </h3>
          </v-col>

          <!-- FIRST MENU -->
          <v-col cols="12" class="pt-0" v-if="!updateMenu">
            <v-row class="mt-1 mx-0">
              <v-col cols="12" class="py-0 px-0">
                <v-row>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-col cols="12" class="px-5 py-0">
                      <v-file-input
                        v-if="id !== 0"
                        v-model="updateFile"
                        append-icon="mdi-folder"
                        prepend-icon=""
                        single-line
                        :rules="[
                          updateFileRules.required,
                          updateFileRules.size,
                        ]"
                        accept=".zip"
                        placeholder="Choose .zip file"
                        dense
                        solo
                      />
                    </v-col>
                    <v-col cols="12" class="px-5 py-0">
                      <v-btn
                        v-if="id !== 0"
                        color="primary"
                        class="text-capitalize"
                        small
                        @click="offlineValidate"
                      >
                        Upload Update
                      </v-btn>
                    </v-col>
                  </v-form>
                </v-row>

                <v-row>
                  <v-col cols="12" class="px-5 py-0">
                    <v-btn
                      v-if="availableNewVersion && id === 0"
                      color="primary"
                      class="text-capitalize"
                      small
                      @click="onlineUpdate"
                    >
                      Run Online update
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <!-- END FIRST MENU -->

          <!-- ONLINE UPDATE -->
          <v-col cols="12" class="pt-0" v-if="onlineSection">
            <v-row class="mt-1 mx-0">
              <ul>
                <li v-if="onlineStep1Label">
                  <h4>
                    <i :class="onlineStep1Class"></i>
                    Download update
                  </h4>
                </li>
                <code v-if="onlineStep1Result" class="mt-0 pt-0">
                  {{ onlineStep1ResultMessage }}
                </code>
              </ul>
            </v-row>
          </v-col>
          <!-- END ONLINE UPDATE -->

          <!-- OFFLINE UPDATE -->
          <v-col cols="12" class="pt-0" v-if="offlineSection">
            <v-row class="mt-1 mx-0">
              <ul>
                <li v-if="offlineStep1Label">
                  <h4>
                    <i :class="offlineStep1Class"></i>
                    Upload file
                  </h4>
                </li>
                <code v-if="offlineStep1Result" class="mt-0 pt-0">
                  {{ offlineStep1ResultMessage }}
                </code>
              </ul>
            </v-row>
          </v-col>
          <!-- END OFFLINE UPDATE -->

          <!-- SAME STEP UPDATE -->
          <v-col cols="12" class="pt-0" v-if="sameSection">
            <v-row class="mt-1 mx-0">
              <ul>
                <li v-if="step2Label">
                  <h4>
                    <i :class="step2Class"></i>
                    Unzip uploaded file
                  </h4>
                </li>
                <code v-if="step2Result" class="mt-0 pt-0">
                  {{ step2ResultMessage }}
                </code>

                <li v-if="step3Label">
                  <h4>
                    <i :class="step3Class"></i>
                    Check sign file
                  </h4>
                </li>
                <code v-if="step3Result" class="mt-0 pt-0"
                  >{{ step3ResultMessage }}
                </code>

                <li v-if="step4Label">
                  <h4>
                    <i :class="step4Class"></i>
                    Extract data
                  </h4>
                </li>
                <code v-if="step4Result" class="mt-0 pt-0">
                  {{ step4ResultMessage }}
                </code>
              </ul>
            </v-row>
          </v-col>
          <!-- END UPDATE -->

          <!-- BUTTONS -->
          <v-col cols="12" class="pt-0">
            <v-row class="mt-1 mx-0">
              <!-- RETRY BUTTON -->
              <v-row class="mt-1 mx-0">
                <v-col cols="12" class="py-0 px-0">
                  <v-btn
                    v-if="buttonRetry"
                    :disabled="!buttonRetry"
                    color="primary"
                    class="text-capitalize"
                    @click="retry"
                  >
                    Retry
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-1 mx-0">
                <v-col cols="12" class="py-0 px-0">
                  <v-btn
                    v-if="offlineButtonRetry"
                    :disabled="!offlineButtonRetry"
                    color="primary"
                    class="text-capitalize"
                    @click="offlineRetry"
                  >
                    Retry
                  </v-btn>
                </v-col>
              </v-row>
              <!-- END RETRY BUTTON -->
            </v-row>
          </v-col>
          <!-- END BUTTONS -->

          <!-- BUTTONS -->
          <v-col cols="12" class="pt-0">
            <v-row class="mt-1 mx-0">
              <!-- STARTUPDATE BUTTON -->
              <v-row class="mt-1 mx-0">
                <v-col cols="12" class="pb-3">
                  <v-btn
                    v-if="buttonStartUpdate"
                    :disabled="buttonStartUpdateDisabled"
                    color="primary"
                    class="text-capitalize"
                    @click="startUpdate"
                  >
                    {{ buttonStartUpdateText }}
                  </v-btn>
                </v-col>
              </v-row>
              <!-- END STARTUPDATE BUTTON -->
            </v-row>
          </v-col>
          <!-- END BUTTONS -->

          <!-- RUN UPDATE -->
          <v-col cols="12" class="pt-0" v-if="startSection">
            <v-row class="mt-1 mx-0">
              <ul>
                <li v-if="step5Label">
                  <h4>
                    <i :class="step5Class"></i>
                    Initializing
                  </h4>
                </li>
                <code v-if="step5Result" class="mt-0 pt-0">
                  {{ step5ResultMessage }}
                </code>

                <li v-if="step6Label">
                  <h4>
                    <i :class="step6Class"></i>
                    Check License
                  </h4>
                </li>
                <code v-if="step6Result" class="mt-0 pt-0">
                  {{ step6ResultMessage }}
                </code>

                <li v-if="step7Label">
                  <h4>
                    <i :class="step7Class"></i>
                    Check System
                  </h4>
                </li>
                <code v-if="step7Result" class="mt-0 pt-0"
                  >{{ step7ResultMessage }}
                </code>

                <li v-if="step8Label">
                  <h4>
                    <i :class="step8Class"></i>
                    Enable mainternance mode
                  </h4>
                </li>
                <code v-if="step8Result" class="mt-0 pt-0">
                  {{ step8ResultMessage }}
                </code>

                <li v-if="step9Label">
                  <h4>
                    <i :class="step9Class"></i>
                    Create backup
                  </h4>
                </li>
                <code v-if="step9Result" class="mt-0 pt-0">
                  {{ step9ResultMessage }}
                </code>

                <li v-if="step10Label">
                  <h4>
                    <i :class="step10Class"></i>
                    Extracting
                  </h4>
                </li>
                <code v-if="step10Result" class="mt-0 pt-0">
                  {{ step10ResultMessage }}
                </code>

                <li v-if="step11Label">
                  <h4>
                    <i :class="step11Class"></i>
                    Check files
                  </h4>
                </li>
                <code v-if="step11Result" class="mt-0 pt-0">
                  {{ step11ResultMessage }}
                </code>

                <li v-if="step13Label">
                  <h4>
                    <i :class="step13Class"></i>
                    Check permissions
                  </h4>
                </li>
                <code v-if="step13Result" class="mt-0 pt-0">
                  {{ step13ResultMessage }}
                </code>

                <li v-if="step14Label">
                  <h4>
                    <i :class="step14Class"></i>
                    Update data
                  </h4>
                </li>
                <code v-if="step14Result" class="mt-0 pt-0">
                  {{ step14ResultMessage }}
                </code>

                <li v-if="step12Label">
                  <h4>
                    <i :class="step12Class"></i>
                    Linking files
                  </h4>
                </li>
                <code v-if="step12Result" class="mt-0 pt-0">
                  {{ step12ResultMessage }}
                </code>

                <li v-if="step15Label">
                  <h4>
                    <i :class="step15Class"></i>
                    Update database
                  </h4>
                </li>
                <code v-if="step15Result" class="mt-0 pt-0">
                  {{ step15ResultMessage }}
                </code>

                <li v-if="step16Label">
                  <h4>
                    <i :class="step16Class"></i>
                    Disable mainternance mode
                  </h4>
                </li>
                <code v-if="step16Result" class="mt-0 pt-0">
                  {{ step16ResultMessage }}
                </code>

                <li v-if="step17Label">
                  <h4>
                    <i :class="step17Class"></i>
                    Clear cache
                  </h4>
                </li>
                <code v-if="step17Result" class="mt-0 pt-0">
                  {{ step17ResultMessage }}
                </code>

                <li v-if="step18Label">
                  <h4>
                    <i :class="step18Class"></i>
                    Delete old files
                  </h4>
                </li>
                <code v-if="step18Result" class="mt-0 pt-0">
                  {{ step18ResultMessage }}
                </code>

                <li v-if="step19Label">
                  <h4>
                    <i :class="step19Class"></i>
                    Done
                  </h4>
                </li>
                <code v-if="step19Result" class="mt-0 pt-0">
                  {{ step19ResultMessage }}
                </code>
              </ul>
            </v-row>
          </v-col>
          <!-- END RUN UPDATE -->

          <!-- mainternance BUTTONS -->
          <v-col cols="12" class="pt-0">
            <v-row class="mt-1 mx-0">
              <!-- STARTUPDATE BUTTON -->
              <v-row class="mt-1 mx-0">
                <v-col cols="12" class="py-0 px-0">
                  <v-btn
                    v-if="buttonDisableMainternance"
                    color="primary"
                    class="text-capitalize"
                    @click="disableMainternance"
                  >
                    Disable mainternance
                  </v-btn>
                </v-col>
              </v-row>
              <!-- END STARTUPDATE BUTTON -->
            </v-row>
          </v-col>
          <!-- END mainternance BUTTONS -->
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { Errors } from "@/utils/error";
import { update, hardDisableMainternance } from "@/api/system/version";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: false,
      availableNewVersion: false,
      buttonOfflineUpdate: false,
      buttonOnlineUpdate: false,
      buttonRetry: false,
      offlineButtonRetry: false,
      buttonStartUpdate: false,
      buttonStartUpdateText: "Start Update",
      buttonStartUpdateDisabled: false,
      buttonDisableMainternance: false,

      updateFile: null,
      updateFileRules: {
        required: (v) => !!v || "ZIP file is required",
        size: (v) =>
          !v ||
          v.size > 20000000 ||
          "ZIP file size should be greater than 20 MB!",
      },

      updateMenu: false,
      runProcess: false,

      onlineSection: false,
      offlineSection: false,
      sameSection: false,
      startSection: true,

      updateFileName: null,

      onlineStep1Label: false,
      onlineStep1Class: "fas fa-question",
      onlineStep1Result: false,
      onlineStep1ResultMessage: "",

      offlineStep1Label: false,
      offlineStep1Class: "fas fa-question",
      offlineStep1Result: false,
      offlineStep1ResultMessage: "",

      step2Label: false,
      step2Class: "fas fa-question",
      step2Result: false,
      step2ResultMessage: "",
      step3Label: false,
      step3Class: "fas fa-question",
      step3Result: false,
      step3ResultMessage: "",
      step4Label: false,
      step4Class: "fas fa-question",
      step4Result: false,
      step4ResultMessage: "",
      step5Label: false,
      step5Class: "fas fa-question",
      step5Result: false,
      step5ResultMessage: "",
      step6Label: false,
      step6Class: "fas fa-question",
      step6Result: false,
      step6ResultMessage: "",
      step7Label: false,
      step7Class: "fas fa-question",
      step7Result: false,
      step7ResultMessage: "",
      step8Label: false,
      step8Class: "fas fa-question",
      step8Result: false,
      step8ResultMessage: "",
      step9Label: false,
      step9Class: "fas fa-question",
      step9Result: false,
      step9ResultMessage: "",
      step10Label: false,
      step10Class: "fas fa-question",
      step10Result: false,
      step10ResultMessage: "",
      step11Label: false,
      step11Class: "fas fa-question",
      step11Result: false,
      step11ResultMessage: "",
      step12Label: false,
      step12Class: "fas fa-question",
      step12Result: false,
      step12ResultMessage: "",
      step13Label: false,
      step13Class: "fas fa-question",
      step13Result: false,
      step13ResultMessage: "",
      step14Label: false,
      step14Class: "fas fa-question",
      step14Result: false,
      step14ResultMessage: "",
      step15Label: false,
      step15Class: "fas fa-question",
      step15Result: false,
      step15ResultMessage: "",
      step16Label: false,
      step16Class: "fas fa-question",
      step16Result: false,
      step16ResultMessage: "",
      step17Label: false,
      step17Class: "fas fa-question",
      step17Result: false,
      step17ResultMessage: "",
      step18Label: false,
      step18Class: "fas fa-question",
      step18Result: false,
      step18ResultMessage: "",
      step19Label: false,
      step19Class: "fas fa-question",
      step19Result: false,
      step19ResultMessage: "",

      checkLoading: false,
      checkValid: null,

      valid: true,
      validCheck: true,

      saving: false,

      license: "",
      licenseRules: {
        required: (v) => !!v || "License is required",
      },
    };
  },
  mounted() {
    this.buttonOfflineUpdate = false;
    this.buttonOnlineUpdate = false;
    this.availableNewVersion = true;
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "systemLicense");
    },
    onlineUpdate() {
      this.updateMenu = true;
      this.onlineSection = true;
      this.offlineSection = false;
      this.sameSection = true;
      this.onlineStep1();
    },
    offlineValidate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.offlineUpdate();
      }
    },
    offlineUpdate() {
      this.updateMenu = true;
      this.onlineSection = false;
      this.offlineSection = true;
      this.sameSection = true;
      this.offlineStep1();
    },
    startUpdate() {
      this.updateMenu = true;
      this.runProcess = true;
      this.step5();
    },
    offlineStep1() {
      this.buttonRetry = false;
      this.offlineStep1Label = true;
      this.offlineStep1Class = "fas fa-spinner fa-spin";
      this.offlineStep1Result = "";

      let formData = new FormData();

      if (this.updateFile) {
        formData.append("step", 1);
        formData.append("type", "offline");
        formData.append("update_file", this.updateFile);

        update(formData)
          .then((response) => {
            this.updateFileName = response.time;
            this.offlineStep1Result = true;
            this.offlineStep1ResultMessage = response.message;
            this.offlineStep1Class = "fas fa-check";
            this.step2();
          })
          .catch((e) => {
            Errors.show(e);
            const response = e.response.data;
            this.offlineStep1Result = true;
            this.offlineStep1ResultMessage = response.message;
            this.offlineStep1Class = "fas fa-times";
            this.offlineButtonRetry = true;
          });
      }
    },
    onlineStep1() {
      this.buttonRetry = false;
      this.onlineStep1Label = true;
      this.onlineStep1Class = "fas fa-spinner fa-spin";
      this.onlineStep1Result = "";

      const data = {
        type: "online",
        step: 1,
      };
      update(data)
        .then((response) => {
          this.updateFileName = response.time;
          this.onlineStep1Result = true;
          this.onlineStep1ResultMessage = response.message;
          this.onlineStep1Class = "fas fa-check";
          this.step2();
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.onlineStep1Result = true;
          this.onlineStep1ResultMessage = response.message;
          this.onlineStep1Class = "fas fa-times";
          this.buttonRetry = true;
        });
    },
    step2() {
      this.buttonRetry = false;
      this.step2Label = true;
      this.step2Class = "fas fa-spinner fa-spin";
      this.step2Result = "";

      const data = {
        type: "online",
        step: 2,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step2Result = true;
          this.step2ResultMessage = response.message;
          this.step2Class = "fas fa-check";
          this.step3();
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step2Result = true;
          this.step2ResultMessage = response.message;
          this.step2Class = "fas fa-times";
          this.buttonRetry = true;
        });
    },
    step3() {
      this.buttonRetry = false;
      this.step3Label = true;
      this.step3Class = "fas fa-spinner fa-spin";
      this.step3Result = "";

      const data = {
        type: "online",
        step: 3,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step3Result = true;
          this.step3ResultMessage = response.message;
          this.step3Class = "fas fa-check";
          this.step4();
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step3Result = true;
          this.step3ResultMessage = response.message;
          this.step3Class = "fas fa-times";
          this.buttonRetry = true;
        });
    },
    step4() {
      this.buttonRetry = false;
      this.step4Label = true;
      this.step4Class = "fas fa-spinner fa-spin";
      this.step4Result = "";

      const data = {
        type: "online",
        step: 4,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step4Result = true;
          this.step4ResultMessage = response.message;
          this.step4Class = "fas fa-check";
          this.buttonStartUpdate = true;
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step4Result = true;
          this.step4ResultMessage = response.message;
          this.step4Class = "fas fa-times";
          this.buttonRetry = true;
        });
    },
    step5() {
      this.startSection = true;
      this.step5Label = true;
      this.step5Class = "fas fa-spinner fa-spin";
      this.step5Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 5,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step5Result = true;
          this.step5ResultMessage = response.message;
          this.step5Class = "fas fa-check";
          this.step7(); // Skip Licencií
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step5Result = true;
          this.step5ResultMessage = response.message;
          this.step5Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step6() {
      this.startSection = true;
      this.step6Label = true;
      this.step6Class = "fas fa-spinner fa-spin";
      this.step6Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 6,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step6Result = true;
          this.step6ResultMessage = response.message;
          this.step6Class = "fas fa-check";
          this.step7();
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step6Result = true;
          this.step6ResultMessage = response.message;
          this.step6Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step7() {
      this.startSection = true;
      this.step7Label = true;
      this.step7Class = "fas fa-spinner fa-spin";
      this.step7Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 7,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step7Result = true;
          this.step7ResultMessage = response.message;
          this.step7Class = "fas fa-check";
          this.step9();
          // this.step8(); // TODO edit pre ENABLE MAINTERNANCE IP na BE
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step7Result = true;
          this.step7ResultMessage = response.message;
          this.step7Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step8() {
      this.startSection = true;
      this.step8Label = true;
      this.step8Class = "fas fa-spinner fa-spin";
      this.step8Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 8,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step8Result = true;
          this.step8ResultMessage = response.message;
          this.step8Class = "fas fa-check";
          this.step9();
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step8Result = true;
          this.step8ResultMessage = response.message;
          this.step8Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step9() {
      this.startSection = true;
      this.step9Label = true;
      this.step9Class = "fas fa-spinner fa-spin";
      this.step9Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 9,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step9Result = true;
          this.step9ResultMessage = response.message;
          this.step9Class = "fas fa-check";
          this.step10();
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step9Result = true;
          this.step9ResultMessage = response.message;
          this.step9Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step10() {
      this.startSection = true;
      this.step10Label = true;
      this.step10Class = "fas fa-spinner fa-spin";
      this.step10Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 10,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step10Result = true;
          this.step10ResultMessage = response.message;
          this.step10Class = "fas fa-check";
          this.step11();
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step10Result = true;
          this.step10ResultMessage = response.message;
          this.step10Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step11() {
      this.startSection = true;
      this.step11Label = true;
      this.step11Class = "fas fa-spinner fa-spin";
      this.step11Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 11,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step11Result = true;
          this.step11ResultMessage = response.message;
          this.step11Class = "fas fa-check";
          this.step13(); // Najskor skontrolujeme permissny az potom nalinkujeme
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step11Result = true;
          this.step11ResultMessage = response.message;
          this.step11Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step12() {
      this.startSection = true;
      this.step12Label = true;
      this.step12Class = "fas fa-spinner fa-spin";
      this.step12Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 12,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step12Result = true;
          this.step12ResultMessage = response.message;
          this.step12Class = "fas fa-check";
          this.step15();
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step12Result = true;
          this.step12ResultMessage = response.message;
          this.step12Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step13() {
      this.startSection = true;
      this.step13Label = true;
      this.step13Class = "fas fa-spinner fa-spin";
      this.step13Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 13,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step13Result = true;
          this.step13ResultMessage = response.message;
          this.step13Class = "fas fa-check";
          this.step12();
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step13Result = true;
          this.step13ResultMessage = response.message;
          this.step13Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step14() {
      this.startSection = true;
      this.step14Label = true;
      this.step14Class = "fas fa-spinner fa-spin";
      this.step14Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 14,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step14Result = true;
          this.step14ResultMessage = response.message;
          this.step14Class = "fas fa-check";
          this.step15();
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step14Result = true;
          this.step14ResultMessage = response.message;
          this.step14Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step15() {
      this.startSection = true;
      this.step15Label = true;
      this.step15Class = "fas fa-spinner fa-spin";
      this.step15Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 15,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step15Result = true;
          this.step15ResultMessage = response.message;
          this.step15Class = "fas fa-check";
          this.step17();
          // this.step16();// TODO edit pre ENABLE MAINTERNANCE IP na BE
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step15Result = true;
          this.step15ResultMessage = response.message;
          this.step15Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step16() {
      this.startSection = true;
      this.step16Label = true;
      this.step16Class = "fas fa-spinner fa-spin";
      this.step16Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 16,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step16Result = true;
          this.step16ResultMessage = response.message;
          this.step16Class = "fas fa-check";
          this.step17();
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step16Result = true;
          this.step16ResultMessage = response.message;
          this.step16Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step17() {
      this.startSection = true;
      this.step17Label = true;
      this.step17Class = "fas fa-spinner fa-spin";
      this.step17Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 17,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step17Result = true;
          this.step17ResultMessage = response.message;
          this.step17Class = "fas fa-check";
          this.step18();
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step17Result = true;
          this.step17ResultMessage = response.message;
          this.step17Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step18() {
      this.startSection = true;
      this.step18Label = true;
      this.step18Class = "fas fa-spinner fa-spin";
      this.step18Result = "";
      this.buttonStartUpdateText = "Working";
      this.buttonStartUpdateDisabled = true;

      const data = {
        type: "start",
        step: 18,
        time: this.updateFileName,
      };
      update(data)
        .then((response) => {
          this.step18Result = true;
          this.step18ResultMessage = response.message;
          this.step18Class = "fas fa-check";
          this.step19();
        })
        .catch((e) => {
          Errors.show(e);
          const response = e.response.data;
          this.step18Result = true;
          this.step18ResultMessage = response.message;
          this.step18Class = "fas fa-times";
          this.buttonStartUpdateText = "Retry";
          this.buttonStartUpdateDisabled = false;
        });
    },
    step19() {
      this.startSection = true;
      this.step19Label = true;
      this.step19Class = "fas fa-spinner fa-spin";
      this.step19Result = "";

      this.step19Result = true;
      this.step19ResultMessage = "Success";
      this.step19Class = "fas fa-check";

      this.buttonStartUpdateText = "Complete";
      this.buttonStartUpdateDisabled = true;
      setTimeout(function () {
        location.reload();
      }, 2000);
    },
    retry() {
      this.onlineUpdate();
    },
    offlineRetry() {
      this.updateMenu = true;
    },
    disableMainternance() {
      hardDisableMainternance()
        .then(() => {
          this.buttonDisableMainternance = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
