import request from "@/utils/request";

export function storeDns(data) {
  return request({
    url: "api/system/dns",
    method: "post",
    data: data,
  });
}

export function dnsTest(data) {
  return request({
    url: "api/system/dnstest",
    method: "post",
    data: data,
  });
}

export function getDns() {
  return request({
    url: "api/system/dns",
    method: "get",
  });
}
