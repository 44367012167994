import request from "@/utils/request";

export function getDomainlistPredefined(page, size, search) {
  const hasSearch = search ? "&searchTerm=" + search.searchTerm + "&advancedMode=" + (search.advancedMode ? 1 : 0) : '';
  return request({
    url: "api/datafeed/domainlist/predefined?page=" + page + "&size=" + size + hasSearch,
    method: "get",
  });
}

export function getDomainlistPredefinedData(id, page, size) {
  return request({
    url:
      "api/datafeed/domainlist/predefineddomain/" +
      id +
      "?page=" +
      page +
      "&size=" +
      size,
    method: "get",
  });
}

export function getDomainListPredefinedName(id) {
  return request({
    url: "api/datafeed/domainlist/predefined_name/" + id,
    method: "get",
  });
}
