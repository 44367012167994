<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">{{ listName }} - {{ title }}</h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <!--SHOW-->
            <v-row class="mx-0 mt-3">
              <v-col cols="12" class="py-0">
                <label class="font-roboto-condensed-regular font-size-15"
                  >Show</label
                >
                <v-tooltip
                  right
                  color="primary"
                  open-delay="1"
                  :nudge-left="copiedShow ? 100 : 130"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-card-text
                      class="font-size-12 px-0 py-0 font-roboto-condensed-regular"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <button
                        id="show-link-textarea"
                        type="button"
                        class="show-link-textarea"
                        v-clipboard:copy="showUrl"
                        v-clipboard:success="onCopyShow"
                        v-clipboard:error="onErrorShow"
                      >
                        {{ showUrl }}
                      </button>
                    </v-card-text>
                  </template>
                  <span class="font-roboto-condensed-regular font-size-15">{{ copiedShow ? "Copied!" : "Click to copy" }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <!--END SHOW-->

            <!--DOWNLOAD-->
            <v-row class="mx-0 mt-8">
              <v-col cols="12" class="py-0">
                <label class="font-roboto-condensed-regular font-size-15"
                  >Download</label
                >
                <v-tooltip
                  right
                  color="primary"
                  open-delay="1"
                  :nudge-left="copiedShow ? 100 : 130"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-card-text
                      class="font-size-12 px-0 py-0 font-roboto-condensed-regular"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <button
                        id="download-link-textarea"
                        type="button"
                        class="download-link-textarea"
                        v-clipboard:copy="downloadUrl"
                        v-clipboard:success="onCopyDownload"
                        v-clipboard:error="onErrorDownload"
                      >
                        {{ downloadUrl }}
                      </button>
                    </v-card-text>
                  </template>
                  <span class="font-roboto-condensed-regular font-size-15">{{
                    copiedDownload ? "Copied!" : "Click to copy"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <!--END DOWNLOAD-->

            <!--SHOW JSON-->
            <v-row class="mx-0 mt-8" v-if="showJsonSection">
              <v-col cols="12" class="py-0">
                <label class="font-roboto-condensed-regular font-size-15"
                  >Checkpoint JSON</label
                >
                <v-tooltip
                  right
                  color="primary"
                  open-delay="1"
                  :nudge-left="copiedShow ? 100 : 130"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-card-text
                      class="font-size-12 px-0 py-0 font-roboto-condensed-regular"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <button
                        id="show-jsonlink-textarea"
                        type="button"
                        class="show-jsonlink-textarea"
                        v-clipboard:copy="showJsonUrl"
                        v-clipboard:success="onCopyShow"
                        v-clipboard:error="onErrorShow"
                      >
                        {{ showJsonUrl }}
                      </button>
                    </v-card-text>
                  </template>
                  <span class="font-roboto-condensed-regular font-size-15">{{ copiedShow ? "Copied!" : "Click to copy" }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <!--END SHOW JSON-->
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { DataFeedParser } from "@/layout/FormSidebar/dataFeed/parser";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import { Errors } from "@/utils/error";
import { getAddressListName } from "@/api/datafeeds/addresslist/custom";
import { getAddressListPredefinedName } from "@/api/datafeeds/addresslist/predefined";
import { getDomainListName } from "@/api/datafeeds/domainlist/custom";
import { getDomainListPredefinedName } from "@/api/datafeeds/domainlist/predefined";
import { getUrlListName } from "@/api/datafeeds/urllist/custom";
import { getUrlListPredefinedName } from "@/api/datafeeds/urllist/predefined";

Vue.use(VueClipboard);

export default {
  components: {
    Topbar,
  },
  props: {
    id: {
      type: Object,
      default: function () {
        return null;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  data() {
    return {
      loading: true,

      listName: "...",
      title: "",
      showUrl: "",
      showJsonUrl: "",
      downloadUrl: "",

      copiedShow: false,
      copiedDownload: false,
      showJsonSection: false,
    };
  },
  computed: {
    apiUrl: {
      get() {
        return this.$store.state.settings.apiUrl;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "apiUrl",
          value: val,
        });
      },
    },
    previousUrl: {
      get() {
        return this.$store.state.settings.previousUrl;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "previousUrl",
          value: val,
        });
      },
    },
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler() {
        this.getListName(this.id);
        this.loading = true;
        this.showUrl = DataFeedParser.generateUrl(
          this.apiUrl,
          this.id,
          false,
          "txt"
        );
        this.showJsonUrl = DataFeedParser.generateUrl(
          this.apiUrl,
          this.id,
          false,
          "json"
        );
        this.downloadUrl = DataFeedParser.generateUrl(
          this.apiUrl,
          this.id,
          true,
          "txt"
        );
        this.title = DataFeedParser.generateTitle(this.id);
        this.$nextTick(() => {
          this.loading = false;
        });
      },
    },
  },
  created() {
    this.getListName();
    this.showUrl = DataFeedParser.generateUrl(
      this.apiUrl,
      this.id,
      false,
      "txt"
    );
    this.showJsonUrl = DataFeedParser.generateUrl(
      this.apiUrl,
      this.id,
      false,
      "json"
    );
    this.downloadUrl = DataFeedParser.generateUrl(
      this.apiUrl,
      this.id,
      true,
      "txt"
    );
    this.title = DataFeedParser.generateTitle(this.id);
    this.loading = false;
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    onCopyShow: function (e) {
      console.log(
        "You just copied the following text to the clipboard: " + e.text
      );
      this.copiedShow = true;
      setTimeout(() => {
        this.copiedShow = false;
      }, 1000);
    },
    onErrorShow: function () {
      console.log("Failed to copy the text to the clipboard");
    },
    onCopyDownload: function (e) {
      console.log(
        "You just copied the following text to the clipboard: " + e.text
      );
      this.copiedDownload = true;
      setTimeout(() => {
        this.copiedDownload = false;
      }, 1000);
    },
    onErrorDownload: function () {
      console.log("Failed to copy the text to the clipboard");
    },
    getListName(data) {
      if (data) {
        this.listName = "...";
        if (data.type1 === "addressList") {
          this.showJsonSection = true;
          if (data.type2 === "custom") {
            getAddressListName(data.id)
              .then((response) => {
                this.listName = response.data.name;
              })
              .catch((e) => {
                Errors.show(e);
              });
          } else if (data.type2 === "predefined") {
            getAddressListPredefinedName(data.id)
              .then((response) => {
                this.listName = response.data.name;
              })
              .catch((e) => {
                Errors.show(e);
              });
          }
        } else if (data.type1 === "domainList") {
          this.showJsonSection = false;
          if (data.type2 === "custom") {
            getDomainListName(data.id)
              .then((response) => {
                this.listName = response.data.name;
              })
              .catch((e) => {
                Errors.show(e);
              });
          } else if (data.type2 === "predefined") {
            getDomainListPredefinedName(data.id)
              .then((response) => {
                this.listName = response.data.name;
              })
              .catch((e) => {
                Errors.show(e);
              });
          }
        } else if (data.type1 === "urlList") {
          this.showJsonSection = false;
          if (data.type2 === "custom") {
            getUrlListName(data.id)
              .then((response) => {
                this.listName = response.data.name;
              })
              .catch((e) => {
                Errors.show(e);
              });
          } else if (data.type2 === "predefined") {
            getUrlListPredefinedName(data.id)
              .then((response) => {
                this.listName = response.data.name;
              })
              .catch((e) => {
                Errors.show(e);
              });
          }
        }
      }
    },
  },
};
</script>
