<template>
  <v-menu v-model="visibleMenu" :close-on-content-click="false" rounded>
    <template v-slot:activator="{ on, attrs }">
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-btn
          :disabled="processing"
          :color="color"
          class="text-capitalize text-white"
          :class="className"
          small
          v-on="on"
          v-bind="attrs"
        >
          {{ mainBtnName }}
        </v-btn>
      </transition>
    </template>

    <v-card>
      <v-list :width="width">
        <v-list-item>
          <v-list-item-content class="pb-0">
            <v-list-item-title>{{ headline }} </v-list-item-title>
            <v-list-item-subtitle class="text-right mt-2">
              <v-btn
                rounded
                text
                color="tertiary"
                small
                class="text-capitalize"
                @click="visibleMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                rounded
                text
                :color="color"
                small
                class="text-capitalize"
                @click="proceed"
              >
                {{ insideBtnName }}
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    processing: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    mainBtnName: {
      type: String,
      default: function () {
        return "Confirm";
      },
    },
    headline: {
      type: String,
      default: function () {
        return "Do you want to proceed?";
      },
    },
    insideBtnName: {
      type: String,
      default: function () {
        return "Proceed";
      },
    },
    color: {
      type: String,
      default: function () {
        return "medium-3";
      },
    },
    width: {
      type: Number,
      default: function () {
        return 400;
      },
    },
    className: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      visibleMenu: false,
    };
  },
  methods: {
    proceed() {
      this.$emit("proceed", true);
      this.visibleMenu = false;
    },
  },
};
</script>
