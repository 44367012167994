import defaultSettings from "@/store/tables";

const {
  auditorRulesetTable,
  auditorCveTable,
  auditorIntegrityTable,
  auditorGroupsTable,
  auditorTemplatesTable,
  auditorTemplateRulesTable,

  monitorAuditorRulesetConfirmedTable,
  monitorAuditorRulesetFixedTable,
  monitorAuditorCveActiveTable,
  monitorAuditorCveConfirmedTable,
  monitorAuditorIntegrityActiveTable,
  monitorAuditorIntegrityConfirmedTable,
  monitorAuditorIntegrityHistoryTable,

  monitorEventsSyslogTable,
  monitorEventsSystemTable,
  monitorEventsThreatsTable,
  monitorInterconnectorSuccessfulTable,
  monitorInterconnectorUnsuccessfulTable,
  monitorInterconnectorRejectTable,
  monitorInterconnectorRevokeTable,

  interconnectorSecurityPolicyTable,
  interconnectorRulesDefaultTable,
  interconnectorRulesScriptTable,

  settingsMinerTable,
  settingsFirewallTable,
  settingsCentralManagementTable,
  settingsFirewallGroupsTable,
  settingsParserTable,
  settingsListenerTable,
  settingsIntegrationTable,
  settingsApiPaloAltoTable,
  settingsApiFortinetTable,
  settingsApiCheckpointTable,
  settingsApiSophosTable,
  settingsApiCustomTable,

  systemBackupRemoteTable,

  awaitingApprovalSecurityPolicyTable,
  awaitingApprovalDataFeedsTable,

  dataFeedsAddrListCustomAllTable,
  dataFeedsAddrListConnectionTable,
  dataFeedsAddrListCustomSrcTable,
  dataFeedsAddrListCustomDstTable,
  dataFeedsAddrListPredefinedAllTable,
  dataFeedsDomainListShowTable,
  dataFeedsDomainListAllTable,
  dataFeedsDomainListPredefinedAllTable,
  dataFeedsUrlListCustomAllTable,
  dataFeedsUrlListPredefinedAllTable,
  dataFeedsUrlListCustomShowTable,
} = defaultSettings;

const state = {
  auditorRulesetTable: auditorRulesetTable,
  auditorCveTable: auditorCveTable,
  auditorIntegrityTable: auditorIntegrityTable,
  auditorGroupsTable: auditorGroupsTable,
  auditorTemplatesTable: auditorTemplatesTable,
  auditorTemplateRulesTable: auditorTemplateRulesTable,

  interconnectorSecurityPolicyTable: interconnectorSecurityPolicyTable,
  interconnectorRulesDefaultTable: interconnectorRulesDefaultTable,
  interconnectorRulesScriptTable: interconnectorRulesScriptTable,

  monitorAuditorRulesetConfirmedTable: monitorAuditorRulesetConfirmedTable,
  monitorAuditorRulesetFixedTable: monitorAuditorRulesetFixedTable,
  monitorAuditorCveActiveTable: monitorAuditorCveActiveTable,
  monitorAuditorCveConfirmedTable: monitorAuditorCveConfirmedTable,
  monitorAuditorIntegrityActiveTable: monitorAuditorIntegrityActiveTable,
  monitorAuditorIntegrityConfirmedTable: monitorAuditorIntegrityConfirmedTable,
  monitorAuditorIntegrityHistoryTable: monitorAuditorIntegrityHistoryTable,

  monitorEventsSyslogTable: monitorEventsSyslogTable,
  monitorEventsSystemTable: monitorEventsSystemTable,
  monitorEventsThreatsTable: monitorEventsThreatsTable,
  monitorInterconnectorSuccessfulTable: monitorInterconnectorSuccessfulTable,
  monitorInterconnectorUnsuccessfulTable: monitorInterconnectorUnsuccessfulTable,
  monitorInterconnectorRejectTable: monitorInterconnectorRejectTable,
  monitorInterconnectorRevokeTable: monitorInterconnectorRevokeTable,

  settingsMinerTable: settingsMinerTable,
  settingsFirewallTable: settingsFirewallTable,
  settingsCentralManagementTable: settingsCentralManagementTable,
  settingsFirewallGroupsTable: settingsFirewallGroupsTable,
  settingsParserTable: settingsParserTable,
  settingsListenerTable: settingsListenerTable,
  settingsIntegrationTable: settingsIntegrationTable,
  settingsApiPaloAltoTable: settingsApiPaloAltoTable,
  settingsApiFortinetTable: settingsApiFortinetTable,
  settingsApiCheckpointTable: settingsApiCheckpointTable,
  settingsApiSophosTable: settingsApiSophosTable,
  settingsApiCustomTable: settingsApiCustomTable,

  systemBackupRemoteTable: systemBackupRemoteTable,

  awaitingApprovalSecurityPolicyTable: awaitingApprovalSecurityPolicyTable,
  awaitingApprovalDataFeedsTable: awaitingApprovalDataFeedsTable,

  dataFeedsAddrListCustomAllTable: dataFeedsAddrListCustomAllTable,
  dataFeedsAddrListConnectionTable: dataFeedsAddrListConnectionTable,
  dataFeedsAddrListCustomSrcTable: dataFeedsAddrListCustomSrcTable,
  dataFeedsAddrListCustomDstTable: dataFeedsAddrListCustomDstTable,
  dataFeedsAddrListPredefinedAllTable: dataFeedsAddrListPredefinedAllTable,
  dataFeedsDomainListAllTable: dataFeedsDomainListAllTable,
  dataFeedsDomainListShowTable: dataFeedsDomainListShowTable,
  dataFeedsDomainListPredefinedAllTable: dataFeedsDomainListPredefinedAllTable,
  dataFeedsUrlListCustomAllTable: dataFeedsUrlListCustomAllTable,
  dataFeedsUrlListPredefinedAllTable: dataFeedsUrlListPredefinedAllTable,
  dataFeedsUrlListCustomShowTable: dataFeedsUrlListCustomShowTable,
};

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  },
};

const actions = {
  changeSetting({ commit }, data) {
    commit("CHANGE_SETTING", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
