<template>
  <div>
    <!--ACTION-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor && !loading" class="mt-0 mx-0">
        <v-col cols="12" class="pb-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Action</label
          >
          <v-autocomplete
              v-model="formInputs.action"
              :items="formInputs.actions[vendor - 1]"
              :rules="formInputs.actionRules"
              item-text="name"
              item-value="id"
              label="Mode"
              placeholder="Mode"
              dense
              solo
              @change="update"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END ACTION-->

    <!--STATUS-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="[2, 3].includes(vendor) && !loading" class="mt-0 mx-0">
        <v-col cols="12" class="pb-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Status</label
          >
          <v-autocomplete
              v-model="formInputs.status"
              :items="formInputs.statuses"
              :rules="formInputs.statusRules"
              item-text="name"
              item-value="id"
              label="Status"
              placeholder="Status"
              dense
              solo
              @change="update"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END STATUS-->

    <!--SRC IP-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Source address</label
          >
          <v-text-field
              v-model="formInputs.srcIp"
              :rules="[formInputs.srcIpRules.required, ipValidateSrc]"
              v-if="[4, 5, 21].includes(formInputs.srcIpRule)"
              :disabled="[4, 5, 21].includes(formInputs.srcIpRule)"
              label="Source address"
              placeholder="Source address"
              dense
              solo
              @keyup="update"
          />
          <v-combobox
              v-else
              v-model="formInputs.srcIp"
              :rules="[formInputs.srcIpRules.required, ipValidateSrc]"
              :items="formInputs.objectAddress"
              item-text="name"
              item-value="ip"
              label="Source address"
              placeholder="Source address"
              dense
              solo
              @change="update"
          />
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-8 pl-1">
          <v-autocomplete
              v-model="formInputs.srcIpRule"
              :items="formInputs.ipRules"
              item-text="name"
              item-value="id"
              label="Rule"
              placeholder="Rule"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END SRC IP-->

    <!--SRC ZONE-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Source zone</label
          >
          <v-text-field
              v-if="[8, 9, 22].includes(formInputs.srcZoneRule)"
              v-model="formInputs.srcZone"
              :rules="[formInputs.srcZoneRules.required]"
              :disabled="[8, 9, 22].includes(formInputs.srcZoneRule)"
              label="Source zone"
              placeholder="Source zone"
              dense
              solo
              @keyup="update"
          />
          <v-combobox
              v-if="[6, 7, 24, 37].includes(formInputs.srcZoneRule)"
              v-model="formInputs.srcZone"
              :items="[]"
              :rules="[formInputs.srcZoneRules.required]"
              label="Source zone"
              placeholder="Source zone"
              :multiple="[7, 24, 37].includes(formInputs.srcZoneRule)"
              chips
              dense
              solo
              class="hide-append-icon"
              @change="update"
          >
            <template v-slot:selection="data">
              <v-chip small color="neutral-4" class="synapsa-chip text-white">
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.srcZoneRule"
              :items="formInputs.zoneRules"
              item-text="name"
              item-value="id"
              label="Rule"
              placeholder="Rule"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END SRC ZONE-->

    <!--SRC INTERFACE-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 2 && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Source interface</label
          >
          <v-text-field
              v-if="formInputs.srcInterfaceRule === 20"
              v-model="formInputs.srcInterface"
              :rules="[formInputs.srcInterfaceRules.required]"
              :disabled="formInputs.srcInterfaceRule === 20"
              label="Source interface"
              placeholder="Source interface"
              dense
              solo
              @keyup="update"
          />
          <v-combobox
              v-if="formInputs.srcInterfaceRule === 19"
              v-model="formInputs.srcInterface"
              :items="[]"
              :rules="[formInputs.srcInterfaceRules.required]"
              label="Source interface"
              placeholder="Source interface"
              multiple
              chips
              dense
              solo
              class="hide-append-icon"
              @change="update"
          >
            <template v-slot:selection="data">
              <v-chip small color="neutral-4" class="synapsa-chip text-white">
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.srcInterfaceRule"
              :items="formInputs.interfaceRules"
              item-text="name"
              item-value="id"
              label="Rule"
              placeholder="Rule"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END SRC INTERFACE-->

    <!--DST IP-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Destination address</label
          >
          <v-text-field
              v-model="formInputs.dstIp"
              :rules="[formInputs.dstIpRules.required, ipValidateDst]"
              v-if="[4, 5, 21].includes(formInputs.dstIpRule)"
              :disabled="[4, 5, 21].includes(formInputs.dstIpRule)"
              label="Destination address"
              placeholder="Destination address"
              dense
              solo
              @keyup="update"
          />
          <v-combobox
              v-else
              v-model="formInputs.dstIp"
              :rules="[formInputs.dstIpRules.required, ipValidateDst]"
              :items="formInputs.objectAddress"
              item-text="name"
              item-value="ip"
              label="Destination address"
              placeholder="Destination address"
              dense
              solo
              @change="update"
          />
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.dstIpRule"
              :items="formInputs.ipRules"
              item-text="name"
              item-value="id"
              label="Rule"
              placeholder="Rule"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END DST IP-->

    <!--DST ZONE-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Destination zone</label
          >
          <v-text-field
              v-if="[8, 9, 22].includes(formInputs.dstZoneRule)"
              v-model="formInputs.dstZone"
              :rules="[formInputs.dstZoneRules.required]"
              :disabled="[8, 9, 22].includes(formInputs.dstZoneRule)"
              label="Destination zone"
              placeholder="Destination zone"
              dense
              solo
              @keyup="update"
          />
          <v-combobox
              v-if="[6, 7, 24, 37].includes(formInputs.dstZoneRule)"
              v-model="formInputs.dstZone"
              :items="[]"
              :rules="[formInputs.dstZoneRules.required]"
              label="Destination zone"
              placeholder="Destination zone"
              :multiple="[7, 24, 37].includes(formInputs.dstZoneRule)"
              chips
              dense
              solo
              class="hide-append-icon"
              @change="update"
          >
            <template v-slot:selection="data">
              <v-chip small color="neutral-4" class="synapsa-chip text-white">
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.dstZoneRule"
              :items="formInputs.zoneRules"
              item-text="name"
              item-value="id"
              label="Rule"
              placeholder="Rule"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END DST ZONE-->

    <!--DST INTERFACE-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 2 && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Destination interface</label
          >
          <v-text-field
              v-if="formInputs.dstInterfaceRule === 20"
              v-model="formInputs.dstInterface"
              :rules="[formInputs.dstInterfaceRules.required]"
              :disabled="formInputs.dstInterfaceRule === 20"
              label="Destination interface"
              placeholder="Destination interface"
              dense
              solo
              @keyup="update"
          />
          <v-combobox
              v-if="formInputs.dstInterfaceRule === 19"
              v-model="formInputs.dstInterface"
              :items="[]"
              :rules="[formInputs.dstInterfaceRules.required]"
              label="Destination interface"
              placeholder="Destination interface"
              multiple
              chips
              dense
              solo
              class="hide-append-icon"
              @change="update"
          >
            <template v-slot:selection="data">
              <v-chip small color="neutral-4" class="synapsa-chip text-white">
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.dstInterfaceRule"
              :items="formInputs.interfaceRules"
              item-text="name"
              item-value="id"
              label="Rule"
              placeholder="Rule"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END DST INTERFACE-->

    <!--SERVICES-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Services</label
          >
          <v-text-field
              v-model="formInputs.service"
              :rules="[formInputs.serviceRules.required, serviceValidate]"
              :disabled="[16, 17, 18, 23].includes(formInputs.serviceRule)"
              label="Services"
              placeholder="Services"
              dense
              solo
              @keyup="update"
          />
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.serviceRule"
              :items="vendor === 1 ? formInputs.servicesRules[0] : formInputs.servicesRules[1]"
              item-text="name"
              item-value="id"
              label="Rule"
              placeholder="Rule"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END SERVICES-->

    <!--TAG-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Tag</label
          >
          <v-text-field
              v-if="[41,42].includes(formInputs.tagRule)"
              v-model="formInputs.tag"
              :rules="[formInputs.tagInputRules.required]"
              :disabled="[41,42].includes(formInputs.tagRule)"
              label="Tag"
              placeholder="Tag"
              dense
              solo
              @keyup="update"
          />
          <v-combobox
              v-if="[38,39,40].includes(formInputs.tagRule)"
              v-model="formInputs.tag"
              :items="[]"
              :rules="[formInputs.tagInputRules.required]"
              label="Tag"
              placeholder="Tag"
              :multiple="[39,40].includes(formInputs.tagRule)"
              chips
              dense
              solo
              class="hide-append-icon"
              @change="update"
          >
            <template v-slot:selection="data">
              <v-chip small color="neutral-4" class="synapsa-chip text-white">
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.tagRule"
              :items="formInputs.tagRules"
              item-text="name"
              item-value="id"
              label="Rule"
              placeholder="Rule"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END TAG-->

    <!--LOG START-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Log Start</label
          >
          <v-text-field
              v-model="formInputs.logStart"
              :rules="[formInputs.logStartInputRule.required]"
              disabled
              label="Log Start"
              placeholder="Log Start"
              dense
              solo
              @keyup="update"
          />
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.logStartRule"
              :items="formInputs.logStartRules"
              item-text="name"
              item-value="id"
              label="Rule"
              placeholder="Rule"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--LOG START-->

    <!--LOG END-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Log End</label
          >
          <v-text-field
              v-model="formInputs.logEnd"
              :rules="[formInputs.logEndInputRule.required]"
              disabled
              label="Log End"
              placeholder="Log End"
              dense
              solo
              @keyup="update"
          />
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.logEndRule"
              :items="formInputs.logEndRules"
              item-text="name"
              item-value="id"
              label="Rule"
              placeholder="Rule"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--LOG END-->

    <!--LOG SETTING-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Log Setting</label
          >
          <v-text-field
              v-if="[46, 47].includes(formInputs.logSettingRule)"
              v-model="formInputs.logSetting"
              :rules="[formInputs.logSettingInputRule.required]"
              :disabled="[46, 47].includes(formInputs.logSettingRule)"
              label="Log Setting"
              placeholder="Log Setting"
              dense
              solo
              @keyup="update"
          />
          <v-combobox
              v-if="[48].includes(formInputs.logSettingRule)"
              v-model="formInputs.logSetting"
              :items="[]"
              :rules="[formInputs.logSettingInputRule.required]"
              label="Log Setting"
              placeholder="Log Setting"
              chips
              dense
              solo
              class="hide-append-icon"
              @change="update"
          >
            <template v-slot:selection="data">
              <v-chip small color="neutral-4" class="synapsa-chip text-white">
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.logSettingRule"
              :items="formInputs.logSettingRules"
              item-text="name"
              item-value="id"
              label="Rule"
              placeholder="Rule"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END LOG SETTING-->

    <!--START PROFILE-->
    <!--START PROFILE TYPE-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Profile Setting</label
          >
          <v-text-field
              v-if="[50, 51].includes(formInputs.profileSettingRule)"
              v-model="formInputs.profileSetting"
              :rules="[formInputs.profileSettingInputRule.required]"
              :disabled="[50, 51].includes(formInputs.profileSettingRule)"
              label="Profile"
              placeholder="Profile"
              dense
              solo
              @keyup="update"
          />
          <v-combobox
              v-if="[49].includes(formInputs.profileSettingRule)"
              v-model="formInputs.profileSetting"
              :rules="[formInputs.profileSettingInputRule.required]"
              :items="[]"
              item-text="name"
              item-value="ip"
              label="Profile"
              placeholder="Profile"
              dense
              solo
              @change="update"
          >
            <template v-slot:selection="data">
              <v-chip small color="neutral-4" class="synapsa-chip text-white">
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.profileSettingRule"
              :items="formInputs.profileSettingRules"
              item-text="name"
              item-value="id"
              label="Profile Setting"
              placeholder="Profile Setting"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END PROFILE TYPE-->

    <!--START ANTIVIRUS-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading && formInputs.profileSettingRule === 50" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Profile Antivirus</label
          >
          <v-text-field
              v-model="formInputs.profileSettingVirus"
              :rules="[formInputs.profileSettingVirusInputRule.required]"
              :disabled="[53, 54].includes(formInputs.profileSettingVirusRule)"
              label="Profile Antivirus"
              placeholder="Profile Antivirus"
              dense
              solo
              @keyup="update"
          />
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.profileSettingVirusRule"
              :items="formInputs.profileSettingVirusRules"
              item-text="name"
              item-value="id"
              label="Profile Antivirus"
              placeholder="Profile Antivirus"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END ANTIVIRUS-->

    <!--START ANTISPYWARES-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading && formInputs.profileSettingRule === 50" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Profile Spyware</label
          >
          <v-text-field
              v-model="formInputs.profileSettingSpyware"
              :rules="[formInputs.profileSettingSpywareInputRule.required]"
              :disabled="[53, 54].includes(formInputs.profileSettingSpywareRule)"
              label="Profile Spyware"
              placeholder="Profile Spyware"
              dense
              solo
              @keyup="update"
          />
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.profileSettingSpywareRule"
              :items="formInputs.profileSettingSpywareRules"
              item-text="name"
              item-value="id"
              label="Profile Spyware"
              placeholder="Profile Spyware"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END ANTISPYWARES-->

    <!--START VULNERABILITY-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading && formInputs.profileSettingRule === 50" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Profile Vulnerability</label
          >
          <v-text-field
              v-model="formInputs.profileSettingVulnerability"
              :rules="[formInputs.profileSettingVulnerabilityInputRule.required]"
              :disabled="[53, 54].includes(formInputs.profileSettingVulnerabilityRule)"
              label="Profile Vulnerability"
              placeholder="Profile Vulnerability"
              dense
              solo
              @keyup="update"
          />
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.profileSettingVulnerabilityRule"
              :items="formInputs.profileSettingVulnerabilityRules"
              item-text="name"
              item-value="id"
              label="Profile Vulnerability"
              placeholder="Profile Vulnerability"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END VULNERABILITY-->

    <!--START WILDFIRE-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading && formInputs.profileSettingRule === 50" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Profile Wildfire</label
          >
          <v-text-field
              v-model="formInputs.profileSettingWildfire"
              :rules="[formInputs.profileSettingWildfireInputRule.required]"
              :disabled="[53, 54].includes(formInputs.profileSettingWildfireRule)"
              label="Profile Wildfire"
              placeholder="Profile Wildfire"
              dense
              solo
              @keyup="update"
          />
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.profileSettingWildfireRule"
              :items="formInputs.profileSettingWildfireRules"
              item-text="name"
              item-value="id"
              label="Profile Wildfire"
              placeholder="Profile Wildfire"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END WILDFIRE-->

    <!--START URL FILTERING-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading && formInputs.profileSettingRule === 50" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Profile Url Filtering</label
          >
          <v-text-field
              v-model="formInputs.profileSettingUrlFiltering"
              :rules="[formInputs.profileSettingUrlFilteringInputRule.required]"
              :disabled="[53, 54].includes(formInputs.profileSettingUrlFilteringRule)"
              label="Profile Url Filtering"
              placeholder="Profile Url Filtering"
              dense
              solo
              @keyup="update"
          />
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.profileSettingUrlFilteringRule"
              :items="formInputs.profileSettingUrlFilteringRules"
              item-text="name"
              item-value="id"
              label="Profile Url Filtering"
              placeholder="Profile Url Filtering"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END URL FILTERING-->

    <!--START DATA FILTERING-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading && formInputs.profileSettingRule === 50" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Profile Data Filtering</label
          >
          <v-text-field
              v-model="formInputs.profileSettingDataFiltering"
              :rules="[formInputs.profileSettingDataFilteringInputRule.required]"
              :disabled="[53, 54].includes(formInputs.profileSettingDataFilteringRule)"
              label="Profile Data Filtering"
              placeholder="Profile Data Filtering"
              dense
              solo
              @keyup="update"
          />
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.profileSettingDataFilteringRule"
              :items="formInputs.profileSettingDataFilteringRules"
              item-text="name"
              item-value="id"
              label="Profile Data Filtering"
              placeholder="Profile Data Filtering"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END DATA FILTERING-->

    <!--START URL BLOCKING-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading && formInputs.profileSettingRule === 50" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Profile File Blocking</label
          >
          <v-text-field
              v-model="formInputs.profileSettingFileBlocking"
              :rules="[formInputs.profileSettingFileBlockingInputRule.required]"
              :disabled="[53, 54].includes(formInputs.profileSettingFileBlockingRule)"
              label="Profile FileBlocking"
              placeholder="Profile FileBlocking"
              dense
              solo
              @keyup="update"
          />
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.profileSettingFileBlockingRule"
              :items="formInputs.profileSettingFileBlockingRules"
              item-text="name"
              item-value="id"
              label="Profile FileBlocking"
              placeholder="Profile FileBlocking"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END URL BLOCKING-->
    <!--END PROFILE-->

    <!--START APPLICATION-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Application</label
          >
          <v-text-field
              v-if="[57, 58].includes(formInputs.applicationRule)"
              v-model="formInputs.application"
              :rules="[formInputs.applicationInputRule.required]"
              disabled
              label="Application"
              placeholder="Application"
              dense
              solo
              @keyup="update"
          />
          <v-combobox
              v-if="[55,56].includes(formInputs.applicationRule)"
              v-model="formInputs.application"
              :items="[]"
              :rules="[formInputs.applicationInputRule.required]"
              label="Application"
              placeholder="Application"
              :multiple="[56].includes(formInputs.applicationRule)"
              chips
              dense
              solo
              class="hide-append-icon"
              @change="update"
          >
            <template v-slot:selection="data">
              <v-chip small color="neutral-4" class="synapsa-chip text-white">
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.applicationRule"
              :items="formInputs.applicationRules"
              item-text="name"
              item-value="id"
              label="Application"
              placeholder="Application"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END APPLICATION-->

    <!--ALLOWED EDL LISTS-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
        <v-col cols="12" class="pt-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Allowed EDL lists</label
          >
          <v-combobox
              v-model="formInputs.allowedEdl"
              :items="[]"
              label="Allowed EDL lists"
              placeholder="Allowed EDL lists"
              multiple
              chips
              dense
              solo
              class="hide-append-icon"
              @change="update"
          >
            <template v-slot:selection="data">
              <v-chip small color="neutral-4" class="synapsa-chip text-white">
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
    </transition>
    <!--END ALLOWED EDL LISTS-->

    <!--VPN-->
    <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
    >
      <v-row v-if="vendor === 3 && !loading" class="mt-0 mx-0">
        <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
          <label class="font-roboto-condensed-regular font-size-15">VPN</label>
          <v-text-field
              v-model="formInputs.vpn"
              :rules="[formInputs.vpnRules.required]"
              :disabled="[35, 36].includes(formInputs.vpnRule)"
              label="VPN"
              placeholder="VPN"
              dense
              solo
              @keyup="update"
          />
        </v-col>
        <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
          <v-autocomplete
              v-model="formInputs.vpnRule"
              :items="formInputs.vpnRulesList"
              item-text="name"
              item-value="id"
              label="Rule"
              placeholder="Rule"
              dense
              solo
              @change="update"
              class="pt-1"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END VPN-->
  </div>
</template>

<script>
import { Validation } from "@/utils/validation";

export default {
  props: {
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
    vendor: {
      type: Number,
      default: function () {
        return 0;
      }
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      }
    }
  },
  data() {
    return {
      formInputs: null,
    }
  },
  watch: {
    mountData: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.formInputs = newValue;
      },
    },
  },
  methods: {
    update() {
      this.$emit("update", {
        action: this.formInputs.action,
        auditorMode: this.formInputs.auditorMode,
        status: this.formInputs.status,
        srcIp: this.formInputs.srcIp.ip === undefined ? this.formInputs.srcIp : this.formInputs.srcIp.ip,
        srcIpRule: this.formInputs.srcIpRule,
        dstIp: this.formInputs.dstIp.ip === undefined ? this.formInputs.dstIp : this.formInputs.dstIp.ip,
        dstIpRule: this.formInputs.dstIpRule,
        srcZone: this.formInputs.srcZone,
        srcZoneRule: this.formInputs.srcZoneRule,
        dstZone: this.formInputs.dstZone,
        dstZoneRule: this.formInputs.dstZoneRule,
        srcInterface: this.formInputs.srcInterface,
        srcInterfaceRule: this.formInputs.srcInterfaceRule,
        dstInterface: this.formInputs.dstInterface,
        dstInterfaceRule: this.formInputs.dstInterfaceRule,
        service: this.formInputs.service,
        serviceRule: this.formInputs.serviceRule,
        logStart: this.formInputs.logStart,
        logStartRule: this.formInputs.logStartRule,
        logEnd: this.formInputs.logEnd,
        logEndRule: this.formInputs.logEndRule,
        logSetting: this.formInputs.logSetting,
        logSettingRule: this.formInputs.logSettingRule,
        profileSetting: this.formInputs.profileSetting,
        profileSettingRule: this.formInputs.profileSettingRule,
        profileSettingVirus: this.formInputs.profileSettingVirus,
        profileSettingVirusRule: this.formInputs.profileSettingVirusRule,
        profileSettingSpyware: this.formInputs.profileSettingSpyware,
        profileSettingSpywareRule: this.formInputs.profileSettingSpywareRule,
        profileSettingVulnerability: this.formInputs.profileSettingVulnerability,
        profileSettingVulnerabilityRule: this.formInputs.profileSettingVulnerabilityRule,
        profileSettingWildfire: this.formInputs.profileSettingWildfire,
        profileSettingWildfireRule: this.formInputs.profileSettingWildfireRule,
        profileSettingUrlFiltering: this.formInputs.profileSettingUrlFiltering,
        profileSettingUrlFilteringRule: this.formInputs.profileSettingUrlFilteringRule,
        profileSettingDataFiltering: this.formInputs.profileSettingDataFiltering,
        profileSettingDataFilteringRule: this.formInputs.profileSettingDataFilteringRule,
        profileSettingFileBlocking: this.formInputs.profileSettingFileBlocking,
        profileSettingFileBlockingRule: this.formInputs.profileSettingFileBlockingRule,
        application: this.formInputs.application,
        applicationRule: this.formInputs.applicationRule,
        tag: this.formInputs.tag,
        tagRule: this.formInputs.tagRule,
        vpn: this.formInputs.vpn,
        vpnRule: this.formInputs.vpnRule,
        allowedEdl: this.formInputs.allowedEdl,
      });
    },
    ipValidateSrc(val) {
      if(val.ip === undefined){
        return Validation.ipWithRule(val, this.srcIpRule);
      }else{
        return Validation.ipWithRule(val.ip, this.srcIpRule);
      }
    },
    ipValidateDst(val) {
      if(val.ip === undefined){
        return Validation.ipWithRule(val, this.dstIpRule);
      }else{
        return Validation.ipWithRule(val.ip, this.dstIpRule);
      }
    },
    serviceValidate(val) {
      return Validation.service(val, this.serviceRule);
    },
  },
};
</script>
