<template>
  <div
    id="dashboard-firewalls-widget"
    :class="className"
    :style="{ height: height, width: width }"
  />
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import { debounce } from "@/utils";

const animationDuration = 6000;

export default {
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "160px",
    },
    connected: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    disconnected: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initChart();
    this.__resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.__resizeHandler);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      const element = document.getElementById("dashboard-firewalls-widget");
      this.chart = echarts.init(element, "macarons");

      this.chart.setOption({
        xAxis: {
          show: false,
          type: "value",
        },
        yAxis: {
          show: false,
          type: "category",
          data: ["Disconnected", "Connected"],
        },
        grid: {
          top: 10,
          bottom: 10,
          left: 20,
          right: 20,
        },
        series: [
          {
            data: [
              {
                value: this.disconnected,
                itemStyle: {
                  color: "#eb637b",
                },
              },
              {
                value: this.connected,
                itemStyle: {
                  color: "#64c52a",
                },
              },
            ],
            label: {
              show: true,
              formatter: "{b}",
            },
            animationDuration,
            type: "bar",
          },
        ],
      });
    },
  },
};
</script>
