import request from "@/utils/request";

export function storeListener(data) {
  return request({
    url: "api/settings/listener",
    method: "post",
    data: data,
  });
}

export function updateListener(data, id) {
  return request({
    url: "api/settings/listener/" + id,
    method: "post",
    data: data,
  });
}

export function getListener(id) {
  return request({
    url: "api/settings/listener/" + id,
    method: "get",
  });
}

export function getListeners(page, size) {
  return request({
    url: "api/settings/listener?page=" + page + "&size=" + size,
    method: "get",
  });
}
export function getAllListeners() {
  return request({
    url: "api/settings/listener",
    method: "get",
  });
}

export function deleteListener(id) {
  return request({
    url: "api/settings/listener/" + id,
    method: "delete",
  });
}
