import request from "@/utils/request";

export function getSuccessful(page, size) {
  return request({
    url: "api/monitor/interconnector/successful?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function getSuccessfulCallData(data) {
  return request({
    url: "api/monitor/interconnector/calldata",
    method: "post",
    data: data,
  });
}
