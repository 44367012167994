import { render, staticRenderFns } from "./paloalto.vue?vue&type=template&id=4b44c9f1&scoped=true&"
import script from "./paloalto.vue?vue&type=script&lang=js&"
export * from "./paloalto.vue?vue&type=script&lang=js&"
import style0 from "./paloalto.vue?vue&type=style&index=0&id=4b44c9f1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b44c9f1",
  null
  
)

export default component.exports