<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new parser" : "Edit parser" }}
            </h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--NAME-->
              <v-row class="mx-0 mt-3">
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Name</label
                  >
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    placeholder="Name"
                    dense
                    solo
                  />
                </v-col>
                <!--END NAME-->
                <!--SYSLOG TYPE-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="!loading" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Syslog Type</label
                    >
                    <v-select
                      v-model="syslogTypes[0]"
                      :items="syslogTypes"
                      :rules="syslogTypesRules"
                      item-text="title"
                      item-value="id"
                      label="Syslog Type"
                      placeholder="Select Syslog Type"
                      return-object
                      dense
                      solo
                    />
                  </v-col>
                </transition>
                <!--END SYSLOG TYPE-->

                <!--TAGS-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="!loading" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15">
                      Threats
                    </label>
                    <v-combobox
                      v-model="tags"
                      :items="[]"
                      label="Threats"
                      placeholder="Threats"
                      multiple
                      chips
                      dense
                      solo
                      class="hide-append-icon"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          color="neutral-4"
                          class="synapsa-chip text-white"
                        >
                          {{ data.item }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </transition>
                <!--END TAGS-->

                <!--LISTS-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="!loading" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15">
                      Variables
                    </label>
                    <v-combobox
                      v-model="lists"
                      :items="[]"
                      label="Variables"
                      placeholder="Variables"
                      multiple
                      chips
                      dense
                      solo
                      class="hide-append-icon"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          color="neutral-4"
                          class="synapsa-chip text-white"
                        >
                          {{ data.item }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </transition>
                <!--END LISTS-->

                <!--DESCRIPTION-->
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Description</label
                  >
                  <v-textarea
                    v-model="description"
                    :rules="descriptionRules"
                    placeholder="Description"
                    auto-grow
                    rows="4"
                    dense
                    solo
                  />
                </v-col>
                <!--END DESCRIPTION-->
              </v-row>

              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    :disabled="!checkValid || !name || !description"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import { getParser, storeParser, updateParser } from "@/api/settings/parser";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: false,

      valid: false,
      validCheck: false,

      saving: false,

      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      description: "",
      descriptionRules: [(v) => !!v || "Description is required"],
      syslogTypes: [{ id: 1, title: "CEF" }],
      syslogTypesRules: [(v) => !!v || "Syslog type is required"],
      tags: "",
      lists: "",
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.valid = false;
          this.validCheck = false;
          this.checkValid = true;
          this.name = "";
          this.description = "";
          this.tags = "";
          this.lists = "";
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  mounted() {},
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "parser");
    },
    loadById() {
      getParser(this.id)
        .then((response) => {
          const parser = response.data;
          this.name = parser.name;
          this.description = parser.description;
          this.tags = parser.tags;
          this.lists = parser.lists;
          this.valid = true;
          this.validCheck = true;
          this.checkValid = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    store() {
      this.saving = true;
      const data = {
        name: this.name,
        description: this.description,
        parsertag: this.tags,
        parserlist: this.lists,
      };
      storeParser(data)
        .then(() => {
          this.$toast.open({
            message: "Parser has created successfully!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    update() {
      this.saving = true;
      const data = {
        name: this.name,
        description: this.description,
        parsertag: this.tags,
        parserlist: this.lists,
      };
      updateParser(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Parser has updated successfully!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
