import axios from "axios";
import router from "@/router";
import { isLogged, setLogged, removeToken } from "@/utils/auth";
import { resetRouter } from "@/router";
import { getInfo } from "@/api/auth";
import { Errors } from "@/utils/error";
const service = axios.create({
  baseURL: process.env.VUE_APP_API ? process.env.VUE_APP_API : "http://127.0.0.1:8000/",
  timeout: 60000, // Request timeout
});

// Request intercepter
service.interceptors.request.use(
  (config) => {
    const token = isLogged();
    if (token) {
      config.headers["Authorization"] = "Bearer " + isLogged(); // Set JWT token
    }
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  (response) => {
    if (response.headers.authorization) {
      setLogged(response.headers.authorization);
      response.data.token = response.headers.authorization;
    }
    return response.data;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      removeToken();
      resetRouter();
      router.push("/login");
    } else if (error.response && error.response.status === 403) {
      getInfo()
        .then((response) => {
          localStorage.setItem("permissions", response.data.permissions);
          // router.go();
        })
        .catch((e) => {
          Errors.show(e);
        });
    }
    return Promise.reject(error);
  }
);

export default service;
