import request from "@/utils/request";

export function getReject(page, size) {
  return request({
    url: "api/monitor/interconnector/rejected?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function rejectCall(id) {
  return request({
    url: "api/interconnector/reject/" + id,
    method: "post",
  });
}
