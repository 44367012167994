import request from "@/utils/request";

export function getDomainlists(page, size, search) {
  const hasSearch = search ? "&searchTerm=" + search.searchTerm + "&advancedMode=" + (search.advancedMode ? 1 : 0) : '';
  return request({
    url: "api/datafeed/domainlist/custom?page=" + page + "&size=" + size + hasSearch,
    method: "get",
  });
}

export function getDomainlistCustomIps(id, page, size) {
  return request({
    url:
      "api/datafeed/domainlist/customdata/" +
      id +
      "?page=" +
      page +
      "&size=" +
      size,
    method: "get",
  });
}

export function getDomainList(id) {
  return request({
    url: "api/datafeed/domainlist/custom/" + id,
    method: "get",
  });
}

export function getDomainListName(id) {
  return request({
    url: "api/datafeed/domainlist/custom_name/" + id,
    method: "get",
  });
}

export function storeDomainList(data) {
  return request({
    url: "api/datafeed/domainlist/custom",
    method: "post",
    data: data,
  });
}

export function updateDomainList(data, id) {
  return request({
    url: "api/datafeed/domainlist/custom/" + id,
    method: "put",
    data: data,
  });
}

export function deleteDomainlist(id) {
  return request({
    url: "api/datafeed/domainlist/custom/" + id,
    method: "delete",
  });
}

export function deleteDomainListData(id) {
  return request({
    url: "api/datafeed/domainlist/customdomain/" + id,
    method: "delete",
  });
}

export function storeDomainListData(data) {
  return request({
    url: "api/datafeed/domainlist/customdomain",
    method: "post",
    data: data,
  });
}
