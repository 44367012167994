<template>
  <v-container :fluid="true" class="px-0 pb-0">
    <v-divider v-if="!loading && emailList.length > 0" class="mt-2 mx-3" />

    <!--EMAIL ADDRESS-->
    <v-row v-if="!loading && emailList.length > 0" class="mx-0 mt-0 pb-3 px-3">
      <v-chip-group
        v-model="emailsChosen"
        column
        multiple
        class="py-4"
        @change="chooseEmail"
      >
        <v-chip
          v-for="email in emailList"
          :key="email.id"
          filter
          outlined
          small
          :color="email.chosen ? 'primary' : ''"
        >
          {{ email.address }}
        </v-chip>
      </v-chip-group>
    </v-row>

    <v-divider v-if="!loading && emailList.length > 0" class="mb-5 mx-3" />
    <!--END EMAIL ADDRESS-->

    <!--    <v-divider class="mt-2" />-->

    <!--EMAIL MESSAGE-->
    <v-row class="mt-0 mx-0">
      <v-col cols="12" class="py-0">
        <label class="font-roboto-condensed-regular font-size-15"
          >Email message</label
        >
        <v-textarea
          v-model="message_email"
          placeholder="Email Message"
          auto-grow
          rows="2"
          dense
          solo
          @keyup="chooseEmail"
        />
      </v-col>
    </v-row>
    <!--END EMAIL MESSAGE-->

    <v-row v-if="loading" class="mt-3 mx-0">
      <v-col cols="12" class="pt-0 px-0 pb-5">
        <v-icon color="primary"> fas fa-spinner fa-spin </v-icon>
        <span class="font-roboto-condensed-light font-size-15 ml-2">
          Loading emails...
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
    emailList: {
      type: Array,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      message_email: "",
      messageEmailRules: [(v) => !!v || "Message is required"],
      email: [],
      emailsChosen: [],

      loading: true,
    };
  },
  created() {
    if (this.mountData) {
      this.message_email = this.mountData.message_email;
      this.email = this.mountData.email;
      this.loadEmails();
      this.loading = false;
    }
  },
  methods: {
    loadEmails() {
      if (this.email) {
        const self = this;
        this.emailList.forEach(function (item, key) {
          item.chosen = self.email.includes(item.id);
          if (self.email.includes(item.id)) {
            self.emailsChosen.push(key);
          }
        });
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    chooseEmail() {
      const self = this;
      this.emailList.forEach(function (item) {
        item.chosen = false;
      });
      this.emailsChosen.forEach(function (item) {
        self.emailList[item].chosen = true;
      });
      const email = [];
      this.emailList.forEach(function (item) {
        if (item.chosen) {
          email.push(item.id);
        }
      });
      this.update(email);
    },
    update(data) {
      this.$emit("update", {
        message_email: this.message_email,
        email: data,
      });
    },
  },
};
</script>
