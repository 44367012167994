<template>
  <v-row class="my-0 mx-0 border" v-if="formInputs">
    <v-col>
      <div class="d-flex align-center justify-center section">
        <span
          class="section-title font-roboto-condensed-regular font-size-15"
        >Mode</span
        >

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span
              class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
              v-on="on"
            >Standard</span
            >
          </template>
          <span>Create alert for all match</span>
        </v-tooltip>

        <div class="toggle-info align-self-center mt-0">
          <v-switch
            v-model="formInputs.auditorMode"
            :true-value="2"
            :false-value="1"
            @change="update"
            inset
            hide-details
            :class="[
                        formInputs.auditorMode === 2
                          ? 'v-switch-on'
                          : 'v-switch-off',
                      ]"
            class="target-background v-switch-off"
          />
        </div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span
              class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
              v-on="on"
            >Negate</span
            >
          </template>
          <span>Create alert only if not found match</span>
        </v-tooltip>
      </div>
    </v-col>
    <v-col cols="12" class="bottom-padding-0">
      <v-btn
        v-for="(tab, key) in Object.values(tabs)"
        :key="key"
        class="text-capitalize mr-2 synapsa-tab"
        :text="activeTab.name !== tab.name"
        :color="activeTab.name === tab.name ? 'primary' : ''"
        :outlined="activeTab.name === tab.name"
        @click="updateTab(tab)"
      >
        <v-badge v-if="!tab.valid" color="error" icon="fas fa-exclamation">
          {{ tab.title }}
        </v-badge>
        <span v-else>{{ tab.title }}</span>
      </v-btn>
    </v-col>

    <v-col v-if="activeTab.name === tabs.general.name" class="pt-0 tabs">
      <v-form ref="generalTab">
        <!--TAG-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <div class="d-flex">
                <div
                  class="d-flex align-center justify-center mr-3 section flex-auto py-0"
                >
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Tag</span
                  >
                  <v-text-field
                    v-if="[41, 42].includes(formInputs.tagRule)"
                    v-model="formInputs.tag"
                    :rules="[formInputs.tagInputRules.required]"
                    disabled
                    label="Tag"
                    placeholder="Tag"
                    dense
                    solo
                    @keyup="update"
                    class="w-100 mt-4 mb-3"
                  />
                  <v-combobox
                    v-if="[38, 39, 40].includes(formInputs.tagRule)"
                    v-model="formInputs.tag"
                    :rules="[formInputs.tagInputRules.required]"
                    label="Tag"
                    placeholder="Tag"
                    multiple
                    chips
                    dense
                    solo
                    class="hide-append-icon mt-4 mb-3"
                    @change="update"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        color="neutral-4"
                        class="synapsa-chip text-white"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center justify-center section">
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Operator</span
                  >
                  <span
                    class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
                    >AND</span
                  >
                  <div class="toggle-info align-self-center mt-0">
                    <v-switch
                      v-model="formInputs.tagOperator"
                      :input-value="formInputs.tagOperator"
                      :true-value="true"
                      :false-value="false"
                      @change="update"
                      inset
                      hide-details
                      :disabled="[41, 42].includes(formInputs.tagRule)"
                      :class="[
                        formInputs.tagOperator ? 'v-switch-on' : 'v-switch-off',
                      ]"
                      class="target-background v-switch-off"
                    />
                  </div>
                  <span
                    class="font-roboto-condensed-light font-size-15 spans pr-1"
                    >OR</span
                  >
                </div>
              </div>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex flex-column align-center justify-end"
            >
              <div class="d-flex align-center justify-end section py-0">
                <span
                  class="section-title font-roboto-condensed-regular font-size-15"
                  >Condition</span
                >
                <v-autocomplete
                  v-model="formInputs.tagRule"
                  :items="formInputs.tagRules"
                  item-text="name"
                  item-value="id"
                  label="Rule"
                  placeholder="Rule"
                  dense
                  solo
                  @change="update"
                  class="mr-2 d-flex align-center mt-4 mb-3"
                />
                <div class="toggle align-self-center mt-0">
                  <v-switch
                    v-model="formInputs.tagNegate"
                    label="Negate"
                    @change="update"
                    inset
                    hide-details
                    :disabled="[42].includes(formInputs.tagRule)"
                    :class="[
                      formInputs.tagNegate ? 'v-switch-on' : 'v-switch-off',
                    ]"
                    class="target-background v-switch-off"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END TAG-->

        <!--ALLOWED EDL LISTS-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="12" class="pt-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Allowed EDL lists</label
              >
              <v-combobox
                v-model="formInputs.allowedEdl"
                :items="[]"
                label="Allowed EDL lists"
                placeholder="Allowed EDL lists"
                multiple
                chips
                dense
                solo
                class="hide-append-icon"
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </transition>
        <!--END ALLOWED EDL LISTS-->

        <!--VPN-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 3 && !loading" class="mt-0 mx-0">
            <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >VPN</label
              >
              <v-text-field
                v-model="formInputs.vpn"
                :rules="[formInputs.vpnRules.required]"
                :disabled="[35, 36].includes(formInputs.vpnRule)"
                label="VPN"
                placeholder="VPN"
                dense
                solo
                @keyup="update"
              />
            </v-col>
            <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
              <v-autocomplete
                v-model="formInputs.vpnRule"
                :items="formInputs.vpnRulesList"
                item-text="name"
                item-value="id"
                label="Rule"
                placeholder="Rule"
                dense
                solo
                @change="update"
                class="pt-1"
              />
            </v-col>
          </v-row>
        </transition>
        <!--END VPN-->
      </v-form>
    </v-col>

    <v-col v-if="activeTab.name === tabs.source.name" class="pt-0 tabs">
      <v-form ref="sourceTab">
        <!--SRC IP-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <div class="d-flex">
                <div
                  class="d-flex align-center justify-center mr-3 section flex-auto py-0"
                >
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Source address</span
                  >
                  <v-text-field
                    v-if="[4, 5, 21].includes(formInputs.srcIpRule)"
                    v-model="formInputs.srcIp"
                    :rules="[formInputs.srcIpRules.required]"
                    disabled
                    label="Source address"
                    placeholder="Source address"
                    dense
                    solo
                    @keyup="update"
                    class="w-100 mt-4 mb-3"
                  />
                  <v-combobox
                    v-if="[1, 2, 3, 66].includes(formInputs.srcIpRule)"
                    v-model="formInputs.srcIp"
                    :items="[1, 2, 3].includes(formInputs.srcIpRule) ? formInputs.objectAddress : []"
                    item-text="name"
                    item-value="ip"
                    :rules="[formInputs.srcIpRules.required]"
                    label="Source address"
                    placeholder="Source address"
                    multiple
                    chips
                    dense
                    solo
                    class="hide-append-icon mt-4 mb-3"
                    @change="update"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        color="neutral-4"
                        class="synapsa-chip text-white"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center justify-center section">
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Operator</span
                  >
                  <span
                    class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
                    >AND</span
                  >
                  <div class="toggle-info align-self-center mt-0">
                    <v-switch
                      v-model="formInputs.srcIpOperator"
                      :input-value="formInputs.srcIpOperator"
                      :true-value="true"
                      :false-value="false"
                      @change="update"
                      inset
                      hide-details
                      :disabled="[4, 5, 21].includes(formInputs.srcIpRule)"
                      :class="[
                        formInputs.srcIpOperator
                          ? 'v-switch-on'
                          : 'v-switch-off',
                      ]"
                      class="target-background v-switch-off"
                    />
                  </div>
                  <span
                    class="font-roboto-condensed-light font-size-15 spans pr-1"
                    >OR</span
                  >
                </div>
              </div>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex flex-column align-center justify-end"
            >
              <div class="d-flex align-center justify-end section py-0">
                <span
                  class="section-title font-roboto-condensed-regular font-size-15"
                  >Condition</span
                >
                <v-autocomplete
                  v-model="formInputs.srcIpRule"
                  :items="formInputs.ipRules"
                  item-text="name"
                  item-value="id"
                  label="Rule"
                  placeholder="Rule"
                  dense
                  solo
                  @change="update"
                  class="mr-2 d-flex align-center mt-4 mb-3"
                />
                <div class="toggle align-self-center mt-0">
                  <v-switch
                    v-model="formInputs.srcIpNegate"
                    label="Negate"
                    @change="update"
                    inset
                    hide-details
                    :disabled="[21].includes(formInputs.srcIpRule)"
                    :class="[
                      formInputs.srcIpNegate ? 'v-switch-on' : 'v-switch-off',
                    ]"
                    class="target-background v-switch-off"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END SRC IP-->

        <!--SRC ZONE-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <div class="d-flex">
                <div
                  class="d-flex align-center justify-center mr-3 section flex-auto py-0"
                >
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Source zone</span
                  >
                  <v-text-field
                    v-if="[9, 22].includes(formInputs.srcZoneRule)"
                    v-model="formInputs.srcZone"
                    :rules="[formInputs.srcZoneRules.required]"
                    disabled
                    label="Source zone"
                    placeholder="Source zone"
                    dense
                    solo
                    @keyup="update"
                    class="w-100 mt-4 mb-3"
                  />
                  <v-combobox
                    v-if="[6, 37].includes(formInputs.srcZoneRule)"
                    v-model="formInputs.srcZone"
                    :items="[]"
                    :rules="[formInputs.srcZoneRules.required]"
                    label="Source zone"
                    placeholder="Source zone"
                    multiple
                    chips
                    dense
                    solo
                    class="hide-append-icon mt-4 mb-3"
                    @change="update"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        color="neutral-4"
                        class="synapsa-chip text-white"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center justify-center section">
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Operator</span
                  >
                  <span
                    class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
                    >AND</span
                  >
                  <div class="toggle-info align-self-center mt-0">
                    <v-switch
                      v-model="formInputs.srcZoneOperator"
                      :input-value="formInputs.srcZoneOperator"
                      :true-value="true"
                      :false-value="false"
                      @change="update"
                      inset
                      hide-details
                      :disabled="[9, 22].includes(formInputs.srcZoneRule)"
                      :class="[
                        formInputs.srcZoneOperator
                          ? 'v-switch-on'
                          : 'v-switch-off',
                      ]"
                      class="target-background v-switch-off"
                    />
                  </div>
                  <span
                    class="font-roboto-condensed-light font-size-15 spans pr-1"
                    >OR</span
                  >
                </div>
              </div>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex flex-column align-center justify-end"
            >
              <div class="d-flex align-center justify-end section py-0">
                <span
                  class="section-title font-roboto-condensed-regular font-size-15"
                  >Condition</span
                >
                <v-autocomplete
                  v-model="formInputs.srcZoneRule"
                  :items="formInputs.zoneRules"
                  item-text="name"
                  item-value="id"
                  label="Rule"
                  placeholder="Rule"
                  dense
                  solo
                  @change="update"
                  class="mr-2 d-flex align-center mt-4 mb-3"
                />
                <div class="toggle align-self-center mt-0">
                  <v-switch
                    v-model="formInputs.srcZoneNegate"
                    label="Negate"
                    @change="update"
                    inset
                    hide-details
                    :disabled="[22].includes(formInputs.srcZoneRule)"
                    :class="[
                      formInputs.srcZoneNegate ? 'v-switch-on' : 'v-switch-off',
                    ]"
                    class="target-background v-switch-off"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END SRC ZONE-->

        <!--SRC INTERFACE-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 2 && !loading" class="mt-0 mx-0">
            <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Source interface</label
              >
              <v-text-field
                v-if="formInputs.srcInterfaceRule === 20"
                v-model="formInputs.srcInterface"
                :rules="[formInputs.srcInterfaceRules.required]"
                :disabled="formInputs.srcInterfaceRule === 20"
                label="Source interface"
                placeholder="Source interface"
                dense
                solo
                @keyup="update"
              />
              <v-combobox
                v-if="formInputs.srcInterfaceRule === 19"
                v-model="formInputs.srcInterface"
                :items="[]"
                :rules="[formInputs.srcInterfaceRules.required]"
                label="Source interface"
                placeholder="Source interface"
                multiple
                chips
                dense
                solo
                class="hide-append-icon"
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
              <v-autocomplete
                v-model="formInputs.srcInterfaceRule"
                :items="formInputs.interfaceRules"
                item-text="name"
                item-value="id"
                label="Rule"
                placeholder="Rule"
                dense
                solo
                @change="update"
                class="pt-1"
              />
            </v-col>
          </v-row>
        </transition>
        <!--END SRC INTERFACE-->
      </v-form>
    </v-col>

    <v-col v-if="activeTab.name === tabs.destination.name" class="pt-0 tabs">
      <v-form ref="destinationTab">
        <!--DST IP-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <div class="d-flex">
                <div
                  class="d-flex align-center justify-center mr-3 section flex-auto py-0"
                >
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Destination address</span
                  >
                  <v-text-field
                    v-if="[4, 5, 21].includes(formInputs.dstIpRule)"
                    v-model="formInputs.dstIp"
                    :rules="[formInputs.dstIpRules.required]"
                    disabled
                    label="Destination address"
                    placeholder="Destination address"
                    dense
                    solo
                    @keyup="update"
                    class="w-100 mt-4 mb-3"
                  />
                  <v-combobox
                    v-if="[1, 2, 3, 66].includes(formInputs.dstIpRule)"
                    v-model="formInputs.dstIp"
                    :items="[1, 2, 3].includes(formInputs.dstIpRule) ? formInputs.objectAddress : []"
                    item-text="name"
                    item-value="ip"
                    :rules="[formInputs.dstIpRules.required]"
                    label="Destination address"
                    placeholder="Destination address"
                    multiple
                    chips
                    dense
                    solo
                    class="hide-append-icon mt-4 mb-3"
                    @change="update"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        color="neutral-4"
                        class="synapsa-chip text-white"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center justify-center section">
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Operator</span
                  >
                  <span
                    class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
                    >AND</span
                  >
                  <div class="toggle-info align-self-center mt-0">
                    <v-switch
                      v-model="formInputs.dstIpOperator"
                      :input-value="formInputs.dstIpOperator"
                      :true-value="true"
                      :false-value="false"
                      @change="update"
                      inset
                      hide-details
                      :disabled="[4, 5, 21].includes(formInputs.dstIpRule)"
                      :class="[
                        formInputs.dstIpOperator
                          ? 'v-switch-on'
                          : 'v-switch-off',
                      ]"
                      class="target-background v-switch-off"
                    />
                  </div>
                  <span
                    class="font-roboto-condensed-light font-size-15 spans pr-1"
                    >OR</span
                  >
                </div>
              </div>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex flex-column align-center justify-end"
            >
              <div class="d-flex align-center justify-end section py-0">
                <span
                  class="section-title font-roboto-condensed-regular font-size-15"
                  >Condition</span
                >
                <v-autocomplete
                  v-model="formInputs.dstIpRule"
                  :items="formInputs.ipRules"
                  item-text="name"
                  item-value="id"
                  label="Rule"
                  placeholder="Rule"
                  dense
                  solo
                  @change="update"
                  class="mr-2 d-flex align-center mt-4 mb-3"
                />
                <div class="toggle align-self-center mt-0">
                  <v-switch
                    v-model="formInputs.dstIpNegate"
                    label="Negate"
                    @change="update"
                    inset
                    hide-details
                    :disabled="[21].includes(formInputs.dstIpRule)"
                    :class="[
                      formInputs.dstIpNegate ? 'v-switch-on' : 'v-switch-off',
                    ]"
                    class="target-background v-switch-off"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END DST IP-->

        <!--DST ZONE-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <div class="d-flex">
                <div
                  class="d-flex align-center justify-center mr-3 section flex-auto py-0"
                >
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Destination zone</span
                  >
                  <v-text-field
                    v-if="[9, 22].includes(formInputs.dstZoneRule)"
                    v-model="formInputs.dstZone"
                    :rules="[formInputs.dstZoneRules.required]"
                    disabled
                    label="Destination zone"
                    placeholder="Destination zone"
                    dense
                    solo
                    @keyup="update"
                    class="w-100 mt-4 mb-3"
                  />
                  <v-combobox
                    v-if="[6, 37].includes(formInputs.dstZoneRule)"
                    v-model="formInputs.dstZone"
                    :items="[]"
                    :rules="[formInputs.dstZoneRules.required]"
                    label="Destination zone"
                    placeholder="Destination zone"
                    multiple
                    chips
                    dense
                    solo
                    class="hide-append-icon mt-4 mb-3"
                    @change="update"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        color="neutral-4"
                        class="synapsa-chip text-white"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center justify-center section">
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Operator</span
                  >
                  <span
                    class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
                    >AND</span
                  >
                  <div class="toggle-info align-self-center mt-0">
                    <v-switch
                      v-model="formInputs.dstZoneOperator"
                      :input-value="formInputs.dstZoneOperator"
                      :true-value="true"
                      :false-value="false"
                      @change="update"
                      inset
                      hide-details
                      :disabled="[9, 22].includes(formInputs.dstZoneRule)"
                      :class="[
                        formInputs.dstZoneOperator
                          ? 'v-switch-on'
                          : 'v-switch-off',
                      ]"
                      class="target-background v-switch-off"
                    />
                  </div>
                  <span
                    class="font-roboto-condensed-light font-size-15 spans pr-1"
                    >OR</span
                  >
                </div>
              </div>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex flex-column align-center justify-end"
            >
              <div class="d-flex align-center justify-end section py-0">
                <span
                  class="section-title font-roboto-condensed-regular font-size-15"
                  >Condition</span
                >
                <v-autocomplete
                  v-model="formInputs.dstZoneRule"
                  :items="formInputs.zoneRules"
                  item-text="name"
                  item-value="id"
                  label="Rule"
                  placeholder="Rule"
                  dense
                  solo
                  @change="update"
                  class="mr-2 d-flex align-center mt-4 mb-3"
                />
                <div class="toggle align-self-center mt-0">
                  <v-switch
                    v-model="formInputs.dstZoneNegate"
                    label="Negate"
                    @change="update"
                    inset
                    hide-details
                    :disabled="[22].includes(formInputs.dstZoneRule)"
                    :class="[
                      formInputs.dstZoneNegate ? 'v-switch-on' : 'v-switch-off',
                    ]"
                    class="target-background v-switch-off"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END DST ZONE-->

        <!--DST INTERFACE-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 2 && !loading" class="mt-0 mx-0">
            <v-col cols="6" lg="7" xl="8" class="pr-0 pt-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Destination interface</label
              >
              <v-text-field
                v-if="formInputs.dstInterfaceRule === 20"
                v-model="formInputs.dstInterface"
                :rules="[formInputs.dstInterfaceRules.required]"
                :disabled="formInputs.dstInterfaceRule === 20"
                label="Destination interface"
                placeholder="Destination interface"
                dense
                solo
                @keyup="update"
              />
              <v-combobox
                v-if="formInputs.dstInterfaceRule === 19"
                v-model="formInputs.dstInterface"
                :items="[]"
                :rules="[formInputs.dstInterfaceRules.required]"
                label="Destination interface"
                placeholder="Destination interface"
                multiple
                chips
                dense
                solo
                class="hide-append-icon"
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="6" lg="5" xl="4" class="pt-5 pl-1">
              <v-autocomplete
                v-model="formInputs.dstInterfaceRule"
                :items="formInputs.interfaceRules"
                item-text="name"
                item-value="id"
                label="Rule"
                placeholder="Rule"
                dense
                solo
                @change="update"
                class="pt-1"
              />
            </v-col>
          </v-row>
        </transition>
        <!--END DST INTERFACE-->
      </v-form>
    </v-col>

    <v-col v-if="activeTab.name === tabs.application.name" class="pt-0 tabs">
      <v-form ref="applicationTab">
        <!--START APPLICATION-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <div class="d-flex">
                <div
                  class="d-flex align-center justify-center mr-3 section flex-auto py-0"
                >
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Application</span
                  >
                  <v-text-field
                    v-if="[57, 58].includes(formInputs.applicationRule)"
                    v-model="formInputs.application"
                    :rules="[formInputs.applicationInputRule.required]"
                    disabled
                    label="Application"
                    placeholder="Application"
                    dense
                    solo
                    @keyup="update"
                    class="w-100 mt-4 mb-3"
                  />
                  <v-combobox
                    v-if="[55, 56].includes(formInputs.applicationRule)"
                    v-model="formInputs.application"
                    :rules="[formInputs.applicationInputRule.required]"
                    label="Application"
                    placeholder="Application"
                    multiple
                    chips
                    dense
                    solo
                    class="hide-append-icon mt-4 mb-3"
                    @change="update"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        color="neutral-4"
                        class="synapsa-chip text-white"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center justify-center section">
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Operator</span
                  >
                  <span
                    class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
                    >AND</span
                  >
                  <div class="toggle-info align-self-center mt-0">
                    <v-switch
                      v-model="formInputs.applicationOperator"
                      :input-value="formInputs.applicationOperator"
                      :true-value="true"
                      :false-value="false"
                      @change="update"
                      inset
                      hide-details
                      :disabled="[57, 58].includes(formInputs.applicationRule)"
                      :class="[
                        formInputs.applicationOperator
                          ? 'v-switch-on'
                          : 'v-switch-off',
                      ]"
                      class="target-background v-switch-off"
                    />
                  </div>
                  <span
                    class="font-roboto-condensed-light font-size-15 spans pr-1"
                    >OR</span
                  >
                </div>
              </div>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex flex-column align-center justify-end"
            >
              <div class="d-flex align-center justify-end section py-0">
                <span
                  class="section-title font-roboto-condensed-regular font-size-15"
                  >Condition</span
                >
                <v-autocomplete
                  v-model="formInputs.applicationRule"
                  :items="formInputs.applicationRules"
                  item-text="name"
                  item-value="id"
                  label="Application"
                  placeholder="Application"
                  dense
                  solo
                  @change="update"
                  class="mr-2 d-flex align-center mt-4 mb-3"
                />
                <div class="toggle align-self-center mt-0">
                  <v-switch
                    v-model="formInputs.applicationNegate"
                    label="Negate"
                    @change="update"
                    inset
                    hide-details
                    :disabled="[58].includes(formInputs.applicationRule)"
                    :class="[
                      formInputs.applicationNegate
                        ? 'v-switch-on'
                        : 'v-switch-off',
                    ]"
                    class="target-background v-switch-off"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END APPLICATION-->
      </v-form>
    </v-col>

    <v-col v-if="activeTab.name === tabs.service.name" class="pt-0 tabs">
      <v-form ref="serviceTab">
        <!--SERVICES-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <div class="d-flex">
                <div
                  class="d-flex align-center justify-center mr-3 section flex-auto py-0"
                >
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Services</span
                  >
                  <v-text-field
                    v-if="[16, 17, 18, 23].includes(formInputs.serviceRule)"
                    v-model="formInputs.service"
                    :rules="[formInputs.serviceRules.required, serviceValidate]"
                    label="Services"
                    placeholder="Services"
                    dense
                    disabled
                    solo
                    @keyup="update"
                    class="w-100 mt-4 mb-3"
                  />
                  <v-combobox
                    v-if="[14, 15, 27, 33].includes(formInputs.serviceRule)"
                    v-model="formInputs.service"
                    :rules="[formInputs.serviceRules.required, serviceValidate]"
                    label="Services"
                    placeholder="Services"
                    :multiple="[14, 15, 33].includes(formInputs.serviceRule)"
                    chips
                    dense
                    solo
                    class="hide-append-icon mt-4 mb-3"
                    @input="update"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        color="neutral-4"
                        class="synapsa-chip text-white"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                    <template v-slot:rules="{ messages }">
                      messages: {{ messages }}
                      <span class="error-message">{{ messages }}</span>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center justify-center section mr-3">
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Protocol</span
                  >
                  <div class="d-flex justify-space-around bg-grey-lighten-4">
                    <v-btn-toggle>
                      <v-btn
                        @click="setProtocol('tcp')"
                        :disabled="
                          [16, 17, 18, 23].includes(formInputs.serviceRule)
                        "
                        small
                        id="protocol-btn"
                        :class="[
                          formInputs.serviceProtocol === 'tcp'
                            ? 'protocol-btn--active'
                            : '',
                        ]"
                        >TCP</v-btn
                      >
                      <v-btn
                        @click="setProtocol('any-protocol')"
                        :disabled="
                          [16, 17, 18, 23].includes(formInputs.serviceRule)
                        "
                        small
                        id="protocol-btn"
                        :class="[
                          formInputs.serviceProtocol === 'any-protocol'
                            ? 'protocol-btn--active'
                            : '',
                        ]"
                        >ANY</v-btn
                      >
                      <v-btn
                        @click="setProtocol('udp')"
                        :disabled="
                          [16, 17, 18, 23].includes(formInputs.serviceRule)
                        "
                        small
                        id="protocol-btn"
                        :class="[
                          formInputs.serviceProtocol === 'udp'
                            ? 'protocol-btn--active'
                            : '',
                        ]"
                        >UDP</v-btn
                      >
                    </v-btn-toggle>
                  </div>
                </div>
                <div class="d-flex align-center justify-center section">
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Operator</span
                  >
                  <span
                    class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
                    >AND</span
                  >
                  <div class="toggle-info align-self-center mt-0">
                    <v-switch
                      v-model="formInputs.serviceOperator"
                      :input-value="formInputs.serviceOperator"
                      :true-value="true"
                      :false-value="false"
                      @change="update"
                      inset
                      hide-details
                      :disabled="
                        [16, 17, 18, 23, 27].includes(formInputs.serviceRule)
                      "
                      :class="[
                        formInputs.serviceOperator
                          ? 'v-switch-on'
                          : 'v-switch-off',
                      ]"
                      class="target-background v-switch-off"
                    />
                  </div>
                  <span
                    class="font-roboto-condensed-light font-size-15 spans pr-1"
                    >OR</span
                  >
                </div>
              </div>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex flex-column align-center justify-end"
            >
              <div class="d-flex align-center justify-end section pb-3 pt-3">
                <span
                  class="section-title font-roboto-condensed-regular font-size-15"
                  >Condition</span
                >
                <v-autocomplete
                  v-model="formInputs.serviceRule"
                  :items="
                    vendor === 1
                      ? formInputs.servicesRules[0]
                      : formInputs.servicesRules[1]
                  "
                  item-text="name"
                  item-value="id"
                  label="Rule"
                  placeholder="Rule"
                  dense
                  solo
                  @change="update"
                  class="pt-1 mr-2"
                />
                <div class="toggle">
                  <v-switch
                    v-model="formInputs.serviceNegate"
                    label="Negate"
                    @change="update"
                    inset
                    hide-details
                    :disabled="[23].includes(formInputs.serviceRule)"
                    :class="[
                      formInputs.serviceNegate ? 'v-switch-on' : 'v-switch-off',
                    ]"
                    class="target-background v-switch-off"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END SERVICES-->
      </v-form>
      <v-form ref="serviceTab">
        <!--START CATEGORY-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <div class="d-flex">
                <div
                  class="d-flex align-center justify-center mr-3 section flex-auto py-0"
                >
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Category</span
                  >
                  <v-text-field
                    v-if="[64, 65].includes(formInputs.categoryRule)"
                    v-model="formInputs.category"
                    :rules="[formInputs.categoryInputRule.required]"
                    disabled
                    label="Category"
                    placeholder="Category"
                    dense
                    solo
                    @keyup="update"
                    class="w-100 mt-4 mb-3"
                  />
                  <v-combobox
                    v-if="[63].includes(formInputs.categoryRule)"
                    v-model="formInputs.category"
                    :items="[]"
                    :rules="[formInputs.categoryInputRule.required]"
                    label="Category"
                    placeholder="Category"
                    :multiple="[63].includes(formInputs.categoryRule)"
                    chips
                    dense
                    solo
                    class="hide-append-icon mt-4 mb-3"
                    @change="update"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        small
                        color="neutral-4"
                        class="synapsa-chip text-white"
                      >
                        {{ data.item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center justify-center section">
                  <span
                    class="section-title font-roboto-condensed-regular font-size-15"
                    >Operator</span
                  >
                  <span
                    class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
                    >AND</span
                  >
                  <div class="toggle-info align-self-center mt-0">
                    <v-switch
                      v-model="formInputs.categoryOperator"
                      :input-value="formInputs.categoryOperator"
                      :true-value="true"
                      :false-value="false"
                      @change="update"
                      inset
                      hide-details
                      :disabled="[64, 65].includes(formInputs.categoryRule)"
                      :class="[
                        formInputs.categoryOperator
                          ? 'v-switch-on'
                          : 'v-switch-off',
                      ]"
                      class="target-background v-switch-off"
                    />
                  </div>
                  <span
                    class="font-roboto-condensed-light font-size-15 spans pr-1"
                    >OR</span
                  >
                </div>
              </div>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex flex-column align-center justify-end"
            >
              <div class="d-flex align-center justify-end section py-0">
                <span
                  class="section-title font-roboto-condensed-regular font-size-15"
                  >Condition</span
                >
                <v-autocomplete
                  v-model="formInputs.categoryRule"
                  :items="formInputs.categoryRules"
                  item-text="name"
                  item-value="id"
                  label="Category"
                  placeholder="Category"
                  dense
                  solo
                  @change="update"
                  class="mr-2 d-flex align-center mt-4 mb-3"
                />
                <div class="toggle align-self-center mt-0">
                  <v-switch
                    v-model="formInputs.categoryNegate"
                    label="Negate"
                    @change="update"
                    inset
                    hide-details
                    :disabled="[65].includes(formInputs.categoryRule)"
                    :class="[
                      formInputs.categoryNegate
                        ? 'v-switch-on'
                        : 'v-switch-off',
                    ]"
                    class="target-background v-switch-off"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END CATEGORY-->
      </v-form>
    </v-col>

    <v-col v-if="activeTab.name === tabs.actions.name" class="pt-0 tabs">
      <v-form ref="actionsTab">
        <!--ACTION-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="10" class="pr-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Action</label
              >
              <v-autocomplete
                v-model="formInputs.action"
                :items="formInputs.actions[vendor - 1]"
                :rules="formInputs.actionRules"
                item-text="name"
                item-value="id"
                label="Mode"
                placeholder="Mode"
                dense
                solo
                @change="update"
              />
            </v-col>
            <v-col cols="2" class="d-flex align-center justify-end pt-8">
              <div class="toggle">
                <v-switch
                  v-if="!['any'].includes(formInputs.action)"
                  v-model="formInputs.actionNegate"
                  label="Negate"
                  @change="update"
                  inset
                  hide-details
                  :class="[
                    formInputs.actionNegate ? 'v-switch-on' : 'v-switch-off',
                  ]"
                  class="target-background v-switch-off"
                />
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END ACTION-->

        <!--START PROFILE-->
        <!--START PROFILE TYPE-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Profile Setting</label
              >
              <v-text-field
                v-if="[50, 51, 60].includes(formInputs.profileSettingRule)"
                v-model="formInputs.profileSetting"
                :rules="[formInputs.profileSettingInputRule.required]"
                :disabled="[50, 51, 60].includes(formInputs.profileSettingRule)"
                label="Profile"
                placeholder="Profile"
                dense
                solo
                @keyup="update"
                class="w-100"
              />
              <v-combobox
                v-if="[49, 59].includes(formInputs.profileSettingRule)"
                v-model="formInputs.profileSetting"
                :rules="[formInputs.profileSettingInputRule.required]"
                :items="[]"
                :multiple="[59].includes(formInputs.profileSettingRule)"
                item-text="name"
                item-value="ip"
                label="Group"
                placeholder="Group"
                dense
                solo
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex align-center justify-end pt-8"
            >
              <v-autocomplete
                v-model="formInputs.profileSettingRule"
                :items="formInputs.profileSettingRules"
                item-text="name"
                item-value="id"
                label="Profile Setting"
                placeholder="Profile Setting"
                dense
                solo
                @change="update"
                class="pt-1"
              />
              <div class="toggle">
                <v-switch
                  v-if="
                    [49, 59, 60, 50].includes(formInputs.profileSettingRule)
                  "
                  v-model="formInputs.profileSettingNegate"
                  label="Negate"
                  @change="update"
                  inset
                  hide-details
                  :class="[
                    formInputs.profileSettingNegate
                      ? 'v-switch-on'
                      : 'v-switch-off',
                  ]"
                  class="target-background v-switch-off"
                />
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END PROFILE TYPE-->

        <!--START ANTIVIRUS-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row
            v-if="
              vendor === 1 &&
              !loading &&
              ((formInputs.profileSettingRule !== 51 &&
                formInputs.profileSettingRule === 50 &&
                !formInputs.profileSettingNegate) ||
                (formInputs.profileSettingRule === 60 &&
                  formInputs.profileSettingNegate))
            "
            class="mt-0 mx-0"
          >
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Profile Antivirus</label
              >
              <v-text-field
                v-if="[53, 54].includes(formInputs.profileSettingVirusRule)"
                v-model="formInputs.profileSettingVirus"
                :rules="[formInputs.profileSettingVirusInputRule.required]"
                :disabled="
                  [53, 54].includes(formInputs.profileSettingVirusRule)
                "
                label="Profile Antivirus"
                placeholder="Profile Antivirus"
                dense
                solo
                @keyup="update"
                class="w-100"
              />
              <v-combobox
                v-if="[52, 61].includes(formInputs.profileSettingVirusRule)"
                v-model="formInputs.profileSettingVirus"
                :rules="[formInputs.profileSettingVirusInputRule.required]"
                :items="[]"
                :multiple="[61].includes(formInputs.profileSettingVirusRule)"
                item-text="name"
                item-value="ip"
                label="Virus"
                placeholder="Virus"
                dense
                solo
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex align-center justify-end pt-8"
            >
              <v-autocomplete
                v-model="formInputs.profileSettingVirusRule"
                :items="formInputs.profileSettingVirusRules"
                item-text="name"
                item-value="id"
                label="Profile Antivirus"
                placeholder="Profile Antivirus"
                dense
                solo
                @change="update"
                class="w-60 mr-2"
              />
              <div class="toggle">
                <v-switch
                  v-if="[52, 61].includes(formInputs.profileSettingVirusRule)"
                  v-model="formInputs.profileSettingVirusNegate"
                  label="Negate"
                  @change="update"
                  inset
                  hide-details
                  :class="[
                    formInputs.profileSettingVirusNegate
                      ? 'v-switch-on'
                      : 'v-switch-off',
                  ]"
                  class="target-background v-switch-off"
                />
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END ANTIVIRUS-->

        <!--START ANTISPYWARES-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row
            v-if="
              vendor === 1 &&
              !loading &&
              ((formInputs.profileSettingRule !== 51 &&
                formInputs.profileSettingRule === 50 &&
                !formInputs.profileSettingNegate) ||
                (formInputs.profileSettingRule === 60 &&
                  formInputs.profileSettingNegate))
            "
            class="mt-0 mx-0"
          >
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Profile Spyware</label
              >
              <v-text-field
                v-if="[53, 54].includes(formInputs.profileSettingSpywareRule)"
                v-model="formInputs.profileSettingSpyware"
                :rules="[formInputs.profileSettingSpywareInputRule.required]"
                :disabled="
                  [53, 54].includes(formInputs.profileSettingSpywareRule)
                "
                label="Profile Spyware"
                placeholder="Profile Spyware"
                dense
                solo
                @keyup="update"
                class="w-100"
              />
              <v-combobox
                v-if="[52, 61].includes(formInputs.profileSettingSpywareRule)"
                v-model="formInputs.profileSettingSpyware"
                :rules="[formInputs.profileSettingSpywareInputRule.required]"
                :items="[]"
                :multiple="[61].includes(formInputs.profileSettingSpywareRule)"
                item-text="name"
                item-value="ip"
                label="Profile Spyware"
                placeholder="Profile Spyware"
                dense
                solo
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex align-center justify-end pt-8"
            >
              <v-autocomplete
                v-model="formInputs.profileSettingSpywareRule"
                :items="formInputs.profileSettingSpywareRules"
                item-text="name"
                item-value="id"
                label="Profile Spyware"
                placeholder="Profile Spyware"
                dense
                solo
                @change="update"
                class="w-60 mr-2"
              />
              <div class="toggle">
                <v-switch
                  v-if="[52, 61].includes(formInputs.profileSettingSpywareRule)"
                  v-model="formInputs.profileSettingSpywareNegate"
                  label="Negate"
                  @change="update"
                  inset
                  hide-details
                  :class="[
                    formInputs.profileSettingSpywareNegate
                      ? 'v-switch-on'
                      : 'v-switch-off',
                  ]"
                  class="target-background v-switch-off"
                />
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END ANTISPYWARES-->

        <!--START VULNERABILITY-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row
            v-if="
              vendor === 1 &&
              !loading &&
              ((formInputs.profileSettingRule !== 51 &&
                formInputs.profileSettingRule === 50 &&
                !formInputs.profileSettingNegate) ||
                (formInputs.profileSettingRule === 60 &&
                  formInputs.profileSettingNegate))
            "
            class="mt-0 mx-0"
          >
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Profile Vulnerability</label
              >
              <v-text-field
                v-if="
                  [53, 54].includes(formInputs.profileSettingVulnerabilityRule)
                "
                v-model="formInputs.profileSettingVulnerability"
                :rules="[
                  formInputs.profileSettingVulnerabilityInputRule.required,
                ]"
                :disabled="
                  [53, 54].includes(formInputs.profileSettingVulnerabilityRule)
                "
                label="Profile Vulnerability"
                placeholder="Profile Vulnerability"
                dense
                solo
                @keyup="update"
                class="w-100"
              />
              <v-combobox
                v-if="
                  [52, 61].includes(formInputs.profileSettingVulnerabilityRule)
                "
                v-model="formInputs.profileSettingVulnerability"
                :rules="[
                  formInputs.profileSettingVulnerabilityInputRule.required,
                ]"
                :items="[]"
                :multiple="
                  [61].includes(formInputs.profileSettingVulnerabilityRule)
                "
                item-text="name"
                item-value="ip"
                label="Profile Vulnerability"
                placeholder="Profile Vulnerability"
                dense
                solo
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex align-center justify-end pt-8"
            >
              <v-autocomplete
                v-model="formInputs.profileSettingVulnerabilityRule"
                :items="formInputs.profileSettingVulnerabilityRules"
                item-text="name"
                item-value="id"
                label="Profile Vulnerability"
                placeholder="Profile Vulnerability"
                dense
                solo
                @change="update"
                class="w-60 mr-2"
              />
              <div class="toggle">
                <v-switch
                  v-if="
                    [52, 61].includes(
                      formInputs.profileSettingVulnerabilityRule
                    )
                  "
                  v-model="formInputs.profileSettingVulnerabilityNegate"
                  label="Negate"
                  @change="update"
                  inset
                  hide-details
                  :class="[
                    formInputs.profileSettingVulnerabilityNegate
                      ? 'v-switch-on'
                      : 'v-switch-off',
                  ]"
                  class="target-background v-switch-off"
                />
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END VULNERABILITY-->

        <!--START WILDFIRE-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row
            v-if="
              vendor === 1 &&
              !loading &&
              ((formInputs.profileSettingRule !== 51 &&
                formInputs.profileSettingRule === 50 &&
                !formInputs.profileSettingNegate) ||
                (formInputs.profileSettingRule === 60 &&
                  formInputs.profileSettingNegate))
            "
            class="mt-0 mx-0"
          >
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Profile Wildfire</label
              >
              <v-text-field
                v-if="[53, 54].includes(formInputs.profileSettingWildfireRule)"
                v-model="formInputs.profileSettingWildfire"
                :rules="[formInputs.profileSettingWildfireInputRule.required]"
                :disabled="
                  [53, 54].includes(formInputs.profileSettingWildfireRule)
                "
                label="Profile Wildfire"
                placeholder="Profile Wildfire"
                dense
                solo
                @keyup="update"
                class="w-100"
              />
              <v-combobox
                v-if="[52, 61].includes(formInputs.profileSettingWildfireRule)"
                v-model="formInputs.profileSettingWildfire"
                :rules="[formInputs.profileSettingWildfireInputRule.required]"
                :items="[]"
                :multiple="[61].includes(formInputs.profileSettingWildfireRule)"
                item-text="name"
                item-value="ip"
                label="Profile Wildfire"
                placeholder="Profile Wildfire"
                dense
                solo
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex align-center justify-end pt-8"
            >
              <v-autocomplete
                v-model="formInputs.profileSettingWildfireRule"
                :items="formInputs.profileSettingWildfireRules"
                item-text="name"
                item-value="id"
                label="Profile Wildfire"
                placeholder="Profile Wildfire"
                dense
                solo
                @change="update"
                class="w-60 mr-2"
              />
              <div class="toggle">
                <v-switch
                  v-if="
                    [52, 61].includes(formInputs.profileSettingWildfireRule)
                  "
                  v-model="formInputs.profileSettingWildfireNegate"
                  label="Negate"
                  @change="update"
                  inset
                  hide-details
                  :class="[
                    formInputs.profileSettingWildfireNegate
                      ? 'v-switch-on'
                      : 'v-switch-off',
                  ]"
                  class="target-background v-switch-off"
                />
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END WILDFIRE-->

        <!--START URL FILTERING-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row
            v-if="
              vendor === 1 &&
              !loading &&
              ((formInputs.profileSettingRule !== 51 &&
                formInputs.profileSettingRule === 50 &&
                !formInputs.profileSettingNegate) ||
                (formInputs.profileSettingRule === 60 &&
                  formInputs.profileSettingNegate))
            "
            class="mt-0 mx-0"
          >
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Profile Url Filtering</label
              >
              <v-text-field
                v-if="
                  [53, 54].includes(formInputs.profileSettingUrlFilteringRule)
                "
                v-model="formInputs.profileSettingUrlFiltering"
                :rules="[
                  formInputs.profileSettingUrlFilteringInputRule.required,
                ]"
                :disabled="
                  [53, 54].includes(formInputs.profileSettingUrlFilteringRule)
                "
                label="Profile Url Filtering"
                placeholder="Profile Url Filtering"
                dense
                solo
                @keyup="update"
                class="w-100"
              />
              <v-combobox
                v-if="
                  [52, 61].includes(formInputs.profileSettingUrlFilteringRule)
                "
                v-model="formInputs.profileSettingUrlFiltering"
                :rules="[
                  formInputs.profileSettingUrlFilteringInputRule.required,
                ]"
                :items="[]"
                :multiple="
                  [61].includes(formInputs.profileSettingUrlFilteringRule)
                "
                item-text="name"
                item-value="ip"
                label="Profile Url Filtering"
                placeholder="Profile Url Filtering"
                dense
                solo
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex align-center justify-end pt-8"
            >
              <v-autocomplete
                v-model="formInputs.profileSettingUrlFilteringRule"
                :items="formInputs.profileSettingUrlFilteringRules"
                item-text="name"
                item-value="id"
                label="Profile Url Filtering"
                placeholder="Profile Url Filtering"
                dense
                solo
                @change="update"
                class="w-60 mr-2"
              />
              <div class="toggle">
                <v-switch
                  v-if="
                    [52, 61].includes(formInputs.profileSettingUrlFilteringRule)
                  "
                  v-model="formInputs.profileSettingUrlFilteringNegate"
                  label="Negate"
                  @change="update"
                  inset
                  hide-details
                  :class="[
                    formInputs.profileSettingUrlFilteringNegate
                      ? 'v-switch-on'
                      : 'v-switch-off',
                  ]"
                  class="target-background v-switch-off"
                />
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END URL FILTERING-->

        <!--START DATA FILTERING-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row
            v-if="
              vendor === 1 &&
              !loading &&
              ((formInputs.profileSettingRule !== 51 &&
                formInputs.profileSettingRule === 50 &&
                !formInputs.profileSettingNegate) ||
                (formInputs.profileSettingRule === 60 &&
                  formInputs.profileSettingNegate))
            "
            class="mt-0 mx-0"
          >
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Profile Data Filtering</label
              >
              <v-text-field
                v-if="
                  [53, 54].includes(formInputs.profileSettingDataFilteringRule)
                "
                v-model="formInputs.profileSettingDataFiltering"
                :rules="[
                  formInputs.profileSettingDataFilteringInputRule.required,
                ]"
                :disabled="
                  [53, 54].includes(formInputs.profileSettingDataFilteringRule)
                "
                label="Profile Data Filtering"
                placeholder="Profile Data Filtering"
                dense
                solo
                @keyup="update"
                class="w-100"
              />
              <v-combobox
                v-if="
                  [52, 61].includes(formInputs.profileSettingDataFilteringRule)
                "
                v-model="formInputs.profileSettingDataFiltering"
                :rules="[
                  formInputs.profileSettingDataFilteringInputRule.required,
                ]"
                :items="[]"
                :multiple="
                  [61].includes(formInputs.profileSettingDataFilteringRule)
                "
                item-text="name"
                item-value="ip"
                label="Profile Data Filtering"
                placeholder="Profile Data Filtering"
                dense
                solo
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex align-center justify-end pt-8"
            >
              <v-autocomplete
                v-model="formInputs.profileSettingDataFilteringRule"
                :items="formInputs.profileSettingDataFilteringRules"
                item-text="name"
                item-value="id"
                label="Profile Data Filtering"
                placeholder="Profile Data Filtering"
                dense
                solo
                @change="update"
                class="w-60 mr-2"
              />
              <div class="toggle">
                <v-switch
                  v-if="
                    [52, 61].includes(
                      formInputs.profileSettingDataFilteringRule
                    )
                  "
                  v-model="formInputs.profileSettingDataFilteringNegate"
                  label="Negate"
                  @change="update"
                  inset
                  hide-details
                  :class="[
                    formInputs.profileSettingDataFilteringNegate
                      ? 'v-switch-on'
                      : 'v-switch-off',
                  ]"
                  class="target-background v-switch-off"
                />
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END DATA FILTERING-->

        <!--START URL BLOCKING-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row
            v-if="
              vendor === 1 &&
              !loading &&
              ((formInputs.profileSettingRule !== 51 &&
                formInputs.profileSettingRule === 50 &&
                !formInputs.profileSettingNegate) ||
                (formInputs.profileSettingRule === 60 &&
                  formInputs.profileSettingNegate))
            "
            class="mt-0 mx-0"
          >
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Profile File Blocking</label
              >
              <v-text-field
                v-if="
                  [53, 54].includes(formInputs.profileSettingFileBlockingRule)
                "
                v-model="formInputs.profileSettingFileBlocking"
                :rules="[
                  formInputs.profileSettingFileBlockingInputRule.required,
                ]"
                :disabled="
                  [53, 54].includes(formInputs.profileSettingFileBlockingRule)
                "
                label="Profile FileBlocking"
                placeholder="Profile FileBlocking"
                dense
                solo
                @keyup="update"
                class="w-100"
              />
              <v-combobox
                v-if="
                  [52, 61].includes(formInputs.profileSettingFileBlockingRule)
                "
                v-model="formInputs.profileSettingFileBlocking"
                :rules="[
                  formInputs.profileSettingFileBlockingInputRule.required,
                ]"
                :items="[]"
                :multiple="
                  [61].includes(formInputs.profileSettingFileBlockingRule)
                "
                item-text="name"
                item-value="ip"
                label="Profile FileBlocking"
                placeholder="Profile FileBlocking"
                dense
                solo
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex align-center justify-end pt-8"
            >
              <v-autocomplete
                v-model="formInputs.profileSettingFileBlockingRule"
                :items="formInputs.profileSettingFileBlockingRules"
                item-text="name"
                item-value="id"
                label="Profile FileBlocking"
                placeholder="Profile FileBlocking"
                dense
                solo
                @change="update"
                class="w-60 mr-2"
              />
              <div class="toggle">
                <v-switch
                  v-if="
                    [52, 61].includes(formInputs.profileSettingFileBlockingRule)
                  "
                  v-model="formInputs.profileSettingFileBlockingNegate"
                  label="Negate"
                  @change="update"
                  inset
                  hide-details
                  :class="[
                    formInputs.profileSettingFileBlockingNegate
                      ? 'v-switch-on'
                      : 'v-switch-off',
                  ]"
                  class="target-background v-switch-off"
                />
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END URL BLOCKING-->
        <!--END PROFILE-->

        <!--LOG START-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Log Start</label
              >
              <v-text-field
                v-model="formInputs.logStart"
                :rules="[formInputs.logStartInputRule.required]"
                disabled
                label="Log Start"
                placeholder="Log Start"
                dense
                solo
                @keyup="update"
                class="w-100"
              />
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex align-center justify-end pt-8"
            >
              <v-autocomplete
                v-model="formInputs.logStartRule"
                :items="formInputs.logStartRules"
                item-text="name"
                item-value="id"
                label="Rule"
                placeholder="Rule"
                dense
                solo
                @change="update"
                class="pt-1 mr-2"
              />
              <div class="toggle">
                <v-switch
                  v-if="[43, 44].includes(formInputs.logStartRule)"
                  v-model="formInputs.logStartNegate"
                  label="Negate"
                  @change="update"
                  inset
                  hide-details
                  :class="[
                    formInputs.logStartNegate ? 'v-switch-on' : 'v-switch-off',
                  ]"
                  class="target-background v-switch-off"
                />
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--LOG START-->

        <!--LOG END-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Log End</label
              >
              <v-text-field
                v-model="formInputs.logEnd"
                :rules="[formInputs.logEndInputRule.required]"
                disabled
                label="Log End"
                placeholder="Log End"
                dense
                solo
                @keyup="update"
                class="w-100"
              />
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex align-center justify-end pt-8"
            >
              <v-autocomplete
                v-model="formInputs.logEndRule"
                :items="formInputs.logEndRules"
                item-text="name"
                item-value="id"
                label="Rule"
                placeholder="Rule"
                dense
                solo
                @change="update"
                class="pt-1 mr-2"
              />
              <div class="toggle">
                <v-switch
                  v-if="[43, 44].includes(formInputs.logEndRule)"
                  v-model="formInputs.logEndNegate"
                  label="Negate"
                  @change="update"
                  inset
                  hide-details
                  :class="[
                    formInputs.logEndNegate ? 'v-switch-on' : 'v-switch-off',
                  ]"
                  class="target-background v-switch-off"
                />
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--LOG END-->

        <!--LOG SETTING-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="vendor === 1 && !loading" class="mt-0 mx-0">
            <v-col cols="7" lg="8" xl="9" class="pr-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Log Setting</label
              >
              <v-text-field
                v-if="[46, 47, 62].includes(formInputs.logSettingRule)"
                v-model="formInputs.logSetting"
                :rules="[formInputs.logSettingInputRule.required]"
                :disabled="[46, 47, 62].includes(formInputs.logSettingRule)"
                label="Log Setting"
                placeholder="Log Setting"
                dense
                solo
                @keyup="update"
                class="w-100"
              />
              <v-combobox
                v-if="[48].includes(formInputs.logSettingRule)"
                v-model="formInputs.logSetting"
                :items="[]"
                :rules="[formInputs.logSettingInputRule.required]"
                label="Log Setting"
                placeholder="Log Setting"
                chips
                dense
                solo
                class="hide-append-icon"
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col
              cols="5"
              lg="4"
              xl="3"
              class="d-flex align-center justify-end pt-8"
            >
              <v-autocomplete
                v-model="formInputs.logSettingRule"
                :items="formInputs.logSettingRules"
                item-text="name"
                item-value="id"
                label="Rule"
                placeholder="Rule"
                dense
                solo
                @change="update"
                class="pt-1 mr-2"
              />
              <div class="toggle">
                <v-switch
                  v-if="[48].includes(formInputs.logSettingRule)"
                  v-model="formInputs.logSettingNegate"
                  label="Negate"
                  @change="update"
                  inset
                  hide-details
                  :class="[
                    formInputs.logSettingNegate
                      ? 'v-switch-on'
                      : 'v-switch-off',
                  ]"
                  class="target-background v-switch-off"
                />
              </div>
            </v-col>
          </v-row>
        </transition>
        <!--END LOG SETTING-->

        <!--STATUS-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row v-if="[2, 3].includes(vendor) && !loading" class="mt-0 mx-0">
            <v-col cols="12" class="pb-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Status</label
              >
              <v-autocomplete
                v-model="formInputs.status"
                :items="formInputs.statuses"
                :rules="formInputs.statusRules"
                item-text="name"
                item-value="id"
                label="Status"
                placeholder="Status"
                dense
                solo
                @change="update"
              />
            </v-col>
          </v-row>
        </transition>
        <!--END STATUS-->
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { Validation } from "@/utils/validation";

export default {
  props: {
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
    vendor: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
  },
  data() {
    return {
      tabs: {
        general: {
          name: "general",
          title: "General",
          valid: true,
          visited: false,
          ref: "generalTab",
        },
        source: {
          name: "src",
          title: "Source",
          valid: true,
          visited: false,
          ref: "surceTab",
        },
        destination: {
          name: "dst",
          title: "Destination",
          valid: true,
          visited: false,
          ref: "destinationTab",
        },
        application: {
          name: "app",
          title: "Application",
          valid: true,
          visited: false,
          ref: "applicationTab",
        },
        service: {
          name: "service",
          title: "Service / URL Category",
          valid: true,
          visited: false,
          ref: "serviceTab",
        },
        actions: {
          name: "actions",
          title: "Actions",
          valid: true,
          visited: false,
          ref: "actionsTab",
        },
      },
      activeTab: {
        name: "general",
        title: "General",
        valid: true,
        visited: false,
        ref: "generalTab",
      },
      formInputs: null,
    };
  },
  watch: {
    mountData: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.formInputs = newValue;
      },
    },
  },
  methods: {
    validateTab() {
      switch (this.activeTab.ref) {
        case this.tabs.general.ref:
          if (this.$refs.generalTab) {
            this.tabs.general.valid = this.$refs.generalTab.validate();
            this.tabs.general.visited = true;
          }
          break;
        case this.tabs.source.ref:
          if (this.$refs.sourceTab) {
            this.tabs.source.valid = this.$refs.sourceTab.validate();
            this.tabs.source.visited = true;
          }
          break;
        case this.tabs.destination.ref:
          if (this.$refs.destinationTab) {
            this.tabs.destination.valid = this.$refs.destinationTab.validate();
            this.tabs.destination.visited = true;
          }
          break;
        case this.tabs.application.ref:
          if (this.$refs.applicationTab) {
            this.tabs.application.valid = this.$refs.applicationTab.validate();
            this.tabs.application.visited = true;
          }
          break;
        case this.tabs.service.ref:
          if (this.$refs.serviceTab) {
            this.tabs.service.valid = this.$refs.serviceTab.validate();
            this.tabs.service.visited = true;
          }
          break;
        case this.tabs.actions.ref:
          if (this.$refs.actionsTab) {
            this.tabs.actions.valid = this.$refs.actionsTab.validate();
            this.tabs.actions.visited = true;
          }
          break;
      }
    },
    updateTab(tab) {
      this.validateTab();
      this.activeTab = tab;
      if (this.activeTab.visited) {
        this.validateTab();
      }
    },
    setProtocol(protocol){
      this.formInputs.serviceProtocol = protocol
      this.update()
    },
    update() {
      this.ipValidateSrc();
      this.ipValidateDst();
      this.$emit("update", {
        auditorMode: this.formInputs.auditorMode,
        action: this.formInputs.action,
        actionNegate: this.formInputs.actionNegate,
        status: this.formInputs.status,
        srcIp:
          this.formInputs.srcIp.ip === undefined
            ? this.formInputs.srcIp
            : this.formInputs.srcIp.ip,
        srcIpOperator: this.formInputs.srcIpOperator,
        srcIpRule: this.formInputs.srcIpRule,
        srcIpNegate: this.formInputs.srcIpNegate,
        dstIp:
          this.formInputs.dstIp.ip === undefined
            ? this.formInputs.dstIp
            : this.formInputs.dstIp.ip,
        dstIpOperator: this.formInputs.dstIpOperator,
        dstIpRule: this.formInputs.dstIpRule,
        dstIpNegate: this.formInputs.dstIpNegate,
        srcZone: this.formInputs.srcZone,
        srcZoneOperator: this.formInputs.srcZoneOperator,
        srcZoneRule: this.formInputs.srcZoneRule,
        srcZoneNegate: this.formInputs.srcZoneNegate,
        dstZone: this.formInputs.dstZone,
        dstZoneOperator: this.formInputs.dstZoneOperator,
        dstZoneRule: this.formInputs.dstZoneRule,
        dstZoneNegate: this.formInputs.dstZoneNegate,
        srcInterface: this.formInputs.srcInterface,
        srcInterfaceRule: this.formInputs.srcInterfaceRule,
        dstInterface: this.formInputs.dstInterface,
        dstInterfaceRule: this.formInputs.dstInterfaceRule,
        service: this.formInputs.service,
        serviceOperator: this.formInputs.serviceOperator,
        serviceProtocol: this.formInputs.serviceProtocol,
        serviceRule: this.formInputs.serviceRule,
        serviceNegate: this.formInputs.serviceNegate,
        logStart: this.formInputs.logStart,
        logStartRule: this.formInputs.logStartRule,
        logStartNegate: this.formInputs.logStartNegate,
        logEnd: this.formInputs.logEnd,
        logEndRule: this.formInputs.logEndRule,
        logEndNegate: this.formInputs.logEndNegate,
        logSetting: this.formInputs.logSetting,
        logSettingRule: this.formInputs.logSettingRule,
        logSettingNegate: this.formInputs.logSettingNegate,
        profileSetting: this.formInputs.profileSetting,
        profileSettingNegate: this.formInputs.profileSettingNegate,
        profileSettingRule: this.formInputs.profileSettingRule,
        profileSettingVirus: this.formInputs.profileSettingVirus,
        profileSettingVirusRule: this.formInputs.profileSettingVirusRule,
        profileSettingVirusNegate: this.formInputs.profileSettingVirusNegate,
        profileSettingSpyware: this.formInputs.profileSettingSpyware,
        profileSettingSpywareRule: this.formInputs.profileSettingSpywareRule,
        profileSettingSpywareNegate: this.formInputs
          .profileSettingSpywareNegate,
        profileSettingVulnerability: this.formInputs
          .profileSettingVulnerability,
        profileSettingVulnerabilityRule: this.formInputs
          .profileSettingVulnerabilityRule,
        profileSettingVulnerabilityNegate: this.formInputs
          .profileSettingVulnerabilityNegate,
        profileSettingWildfire: this.formInputs.profileSettingWildfire,
        profileSettingWildfireRule: this.formInputs.profileSettingWildfireRule,
        profileSettingWildfireNegate: this.formInputs
          .profileSettingWildfireNegate,
        profileSettingUrlFiltering: this.formInputs.profileSettingUrlFiltering,
        profileSettingUrlFilteringRule: this.formInputs
          .profileSettingUrlFilteringRule,
        profileSettingUrlFilteringNegate: this.formInputs
          .profileSettingUrlFilteringNegate,
        profileSettingDataFiltering: this.formInputs
          .profileSettingDataFiltering,
        profileSettingDataFilteringRule: this.formInputs
          .profileSettingDataFilteringRule,
        profileSettingDataFilteringNegate: this.formInputs
          .profileSettingDataFilteringNegate,
        profileSettingFileBlocking: this.formInputs.profileSettingFileBlocking,
        profileSettingFileBlockingRule: this.formInputs
          .profileSettingFileBlockingRule,
        profileSettingFileBlockingNegate: this.formInputs
          .profileSettingFileBlockingNegate,
        application: this.formInputs.application,
        applicationOperator: this.formInputs.applicationOperator,
        applicationNegate: this.formInputs.applicationNegate,
        applicationRule: this.formInputs.applicationRule,
        category: this.formInputs.category,
        categoryNegate: this.formInputs.categoryNegate,
        categoryOperator: this.formInputs.categoryOperator,
        categoryRule: this.formInputs.categoryRule,
        tag: this.formInputs.tag,
        tagOperator: this.formInputs.tagOperator,
        tagRule: this.formInputs.tagRule,
        tagNegate: this.formInputs.tagNegate,
        vpn: this.formInputs.vpn,
        vpnRule: this.formInputs.vpnRule,
        allowedEdl: this.formInputs.allowedEdl,
      });
    },
    ipValidateSrc() {
      if (typeof this.formInputs.srcIp === "string") {
        return;
      }
      // Získame len pole so stringami (v impute je aj objekt)
      const processedValues = this.formInputs.srcIp.map((item) => {
        if (typeof item === "object" && item !== null) {
          return item.ip;
        } else {
          return item;
        }
      });

      // Aktualizujeme len hodnotami pre ip
      this.formInputs.srcIp = processedValues;
      this.formInputs.srcIp = processedValues.filter((value) => {
        return Validation.ipWithRule(value, this.formInputs.srcIpRule) === true;
      });
    },
    ipValidateDst() {
      if (typeof this.formInputs.dstIp === "string") {
        return;
      }
      // Získame len pole so stringami (v impute je aj objekt)
      const processedValues = this.formInputs.dstIp.map((item) => {
        if (typeof item === "object" && item !== null) {
          return item.ip;
        } else {
          return item;
        }
      });

      // Aktualizujeme len hodnotami pre ip
      this.formInputs.dstIp = processedValues;
      this.formInputs.dstIp = processedValues.filter((value) => {
        return Validation.ipWithRule(value, this.formInputs.dstIpRule) === true;
      });
    },
    serviceValidate() {
      if (typeof this.formInputs.service === "string") {
        if (this.formInputs.service === "") {
          return false;
        } else {
          return true;
        }
      }
      let isValid = true;
      let validationResult = true;
      for (const value of this.formInputs.service) {
        validationResult = Validation.service(
          value,
          this.formInputs.serviceRule
        );
        if (typeof validationResult === "string") {
          isValid = false;
          break; // Akonáhle sa nájde neplatná hodnota, ukončite cyklus
        }
      }

      if (!isValid) {
        return validationResult;
      }

      return isValid;
    },
  },
};
</script>

<style lang="scss" scoped>
$synapsaGreen: #69be28;

.tabs {
  width: 100%;
  border: 1px solid $synapsaGreen;
  border-radius: 15px;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-bottom: 0.5rem;
  border-radius: 0 0 15px 15px;
}

.bottom-padding-0 {
  padding-bottom: 0;
}
.add-form-sidebar {
  .form-block {
    .v-btn {
      border-radius: 15px 15px 0 0 !important;
      margin-bottom: -1px;
    }
  }
}
.v-application {
  .primary--text.theme--light.v-btn {
    border-bottom: 1px solid #fff;
  }
}

.v-application {
  .primary--text.theme--dark.v-btn {
    border-bottom: 1px solid #363636;
  }
}

.theme--dark {
  .v-btn {
    border-bottom: #363636;
  }
  .spans {
    color: hsla(0, 0%, 100%, 0.7);
  }
}
.theme--light {
  .spans {
    color: rgba(0, 0, 0, 0.6);
  }
}
.flex-auto {
  flex: auto;
}

.section {
  margin: 1rem 0;
  position: relative;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
}
.theme--dark {
  .section {
    background: #3e3e3e;
    box-shadow: 0px 0px 5px 0px #3e3e3e;
  }
}
.theme--light {
  .section {
    background: #f4f4f4;
    box-shadow: 0px 0px 5px 0px #f4f4f4;
  }
}
.section-title {
  position: absolute;
  top: -1.4rem;
  left: 0.4rem;
}
.theme--light.v-btn::before {
  opacity: 0 !important;
}
.theme--light.v-btn:hover::before {
  opacity: 0 !important;
}
.theme--dark.v-btn::before {
  opacity: 0 !important;
}
.theme--dark.v-btn:hover::before {
  opacity: 0 !important;
}
#protocol-btn {
  border-radius: 0 !important;
  border-left-width: 1px !important;
}
.theme--light.protocol-btn--active {
  background-color: #bfbfbf !important;
}
.theme--dark.protocol-btn--active {
  background-color: #4d4d4d !important;
}
.theme--dark.v-btn.v-btn--has-bg {
  background-color: #424242;
}
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  position: absolute;
  bottom: -2.4rem;
  left: 0;
}
</style>
