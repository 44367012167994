<template>
  <v-row class="mt-0 mx-0">
    <v-col cols="12" class="py-0 px-0">
      <v-data-table
        :headers="headers"
        :items="data"
        hide-default-footer
        item-key="id"
        :items-per-page="data.length"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table in-sidebar-form"
      >
        <template v-slot:item.time="{ item }">
          {{ item.created_at }}
        </template>
        <template v-slot:item.confirmedBy="{ item }">{{
          item.last_call_user ? item.last_call_user : "-"
        }}</template>
        <template v-slot:item.callName="{ item }">{{
          item.callname ? item.callname : "-"
        }}</template>
        <template v-slot:item.target="{ item }">{{
          item.target ? item.target : "-"
        }}</template>
        <template v-slot:item.httpStatus="{ item }">
          <v-chip
            v-if="item.status_code"
            x-small
            label
            :color="item.status_code === 200 ? 'good-3' : 'bad-3'"
            class="elevation-2 text-white"
          >
            {{ item.status_code }}
          </v-chip>
          <span v-else>-</span>
        </template>
        <template v-slot:item.checkStatus="{ item }">
          <v-chip
            x-small
            label
            :color="item.check_status_code ? 'good-3' : 'bad-3'"
            class="elevation-2 text-white"
          >
            {{ item.check_status_code ? "YES" : "NO" }}
          </v-chip>
        </template>
        <template v-slot:item.responseStatus="{ item }">
          <v-chip
            v-if="item.response_code"
            x-small
            label
            :color="item.response_code === 200 ? 'good-3' : 'bad-3'"
            class="elevation-2 text-white"
          >
            {{ item.response_code }}
          </v-chip>
          <span v-else>-</span>
        </template>
        <template v-slot:item.checkResponse="{ item }">
          <v-chip
            x-small
            label
            :color="item.check_response_code ? 'good-3' : 'bad-3'"
            class="elevation-2 text-white"
          >
            {{ item.check_status_code ? "YES" : "NO" }}
          </v-chip>
        </template>
        <template v-slot:item.responseMsg="{ item }">{{
          item.message_from_call ? item.message_from_call : "-"
        }}</template>
        <template v-slot:item.status="{ item }">
          <v-chip
            v-if="item.event_status_message"
            x-small
            label
            :color="getStatusColor(item.event_status_message)"
            class="elevation-2 text-white"
          >
            {{ item.event_status_message }}
          </v-chip>
          <span v-else>-</span>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="12" class="text-right">
      <v-icon v-if="processing" color="primary" class="va-middle mr-2"
        >fas fa-spinner fa-spin</v-icon
      >
      <proceed-menu
        :processing="processing"
        main-btn-name="Revoke"
        headline="Are you sure you want to REVOKE this call?"
        color="bad-3"
        :width="350"
        @proceed="revoke"
      />
    </v-col>
  </v-row>
</template>

<script>
import proceedMenu from "@/components/ProceedMenu";

export default {
  components: {
    proceedMenu,
  },
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    processing: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    revoke() {
      this.$emit("revoke", true);
    },
    getStatusColor(message) {
      if (message === "Accepted") {
        return "good-3";
      } else if (message === "Manual confirmation needed") {
        return "medium-4";
      } else if (message === "Revoked") {
        return "bad-3";
      } else if (message === "Not accepted") {
        return "bad-3";
      }
      return "";
    },
  },
};
</script>
