var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0 mh-100 form",attrs:{"fluid":true}},[_c('topbar',{attrs:{"size":_vm.size,"has-timeline":true,"mount-time":_vm.time,"has-bar-chart":""},on:{"toggle-chart":_vm.toggleCharts,"resize":function($event){return _vm.resize($event)},"timeline":function($event){return _vm.changeTime($event)},"close":_vm.close}}),_c('v-row',{staticClass:"form-block mt-0"},[_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"12"}},[(_vm.size !== 1 && !_vm.loading)?_c('v-row',{staticClass:"mt-0 mx-0"},[_c('v-col',{staticClass:"pb-2 pt-3",attrs:{"cols":"12"}},[_c('chart',{attrs:{"refresh":_vm.refreshChart,"id":'form-sidebar-system-logs-widget',"x-axis-data":_vm.xAxisData,"data":[
              this.data.auditor,
              this.data.config,
              this.data.firewall,
              this.data.login,
              this.data.miner,
              this.data.rule,
              this.data.secpolicy,
              this.data.system,
            ],"legend":_vm.legend,"height":'600px'}}),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"absolute":"","indeterminate":"","size":"40","color":"good-3"}})],1),_c('v-overlay',{attrs:{"value":_vm.failed}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("widgets.systemLogs.headline"))+" - loading data from api failed. ")]),_c('v-card-actions',[_c('v-btn',{staticClass:"mx-auto",attrs:{"small":"","color":"bad-2"},on:{"click":_vm.loadData}},[_vm._v(" Reload ")])],1)],1),_c('v-overlay',{attrs:{"value":_vm.showCountdown}},[_c('countdown',{attrs:{"radius":30,"stroke-width":5},on:{"action":_vm.loadData}}),_c('v-card-text',{staticClass:"overlay-text"},[_vm._v(" "+_vm._s(_vm.$t("widgets.systemLogs.headline"))+" - "+_vm._s(_vm.processingMessage)+" ")])],1)],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }