<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new miner" : "Edit miner" }}
            </h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="formCheck" v-model="validCheck" lazy-validation>
              <!--IP-->
              <v-row class="mx-0 mt-3">
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >IP address</label
                  >
                  <v-text-field
                    v-model="ip"
                    :rules="[ipRules.required, ipValidate]"
                    :disabled="checkValid"
                    label="IP address"
                    placeholder="IP address"
                    dense
                    solo
                  />
                </v-col>
                <!--END IP-->
                <!--SOURCE-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Source</label
                  >
                  <v-select
                    v-model="source"
                    :items="sources"
                    :rules="sourceRules"
                    :disabled="checkValid"
                    item-text="name"
                    item-value="id"
                    label="Source"
                    placeholder="Source"
                    return-object
                    dense
                    solo
                  />
                </v-col>
                <!--END SOURCE-->
                <!--PARSER-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Parser</label
                  >
                  <v-select
                    v-model="parser"
                    :items="source ? source.parsers : []"
                    :rules="parserRules"
                    :disabled="checkValid"
                    item-text="name"
                    item-value="id"
                    label="Parser"
                    placeholder="Parser"
                    dense
                    solo
                  />
                </v-col>
                <!--END PARSER-->

                <!--GRANT TYPE-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="source && source.id === 4" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Grant type login</label
                    >
                    <v-text-field
                      v-model="grantType"
                      :rules="grantTypeRules"
                      :disabled="checkValid"
                      label="Grant type login"
                      placeholder="Grant type login"
                      dense
                      solo
                    />
                  </v-col>
                </transition>
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="source && source.id === 4" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Grant type refresh</label
                    >
                    <v-text-field
                      v-model="grant_type_refresh"
                      :rules="grantType_refreshRules"
                      :disabled="checkValid"
                      label="Grant type refresh"
                      placeholder="Grant type refresh"
                      dense
                      solo
                    />
                  </v-col>
                </transition>
                <!--END GRANT TYPE-->
                <!--CLIENT ID-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="source && source.id === 4" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Client ID</label
                    >
                    <v-text-field
                      v-model="clientId"
                      :rules="clientIdRules"
                      :disabled="checkValid"
                      label="Client ID"
                      placeholder="Client ID"
                      dense
                      solo
                    />
                  </v-col>
                </transition>
                <!--END CLIENT ID-->
                <!--USERNAME-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="source && source.id === 4" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Username</label
                    >
                    <v-text-field
                      v-model="username"
                      :rules="usernameRules"
                      :disabled="checkValid"
                      label="Username"
                      placeholder="Username"
                      dense
                      solo
                    />
                  </v-col>
                </transition>
                <!--END USERNAME-->
                <!--PASSWORD-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="source && source.id === 4" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Password</label
                    >
                    <v-text-field
                      v-model="password"
                      :rules="passwordRules"
                      :disabled="checkValid"
                      label="Password"
                      placeholder="Password"
                      type="password"
                      dense
                      solo
                    />
                  </v-col>
                </transition>
                <!--END PASSWORD-->
              </v-row>

              <!--SOURCE API CHECK-->
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-row v-if="source && source.id === 4" class="mx-0 mt-3">
                  <v-col v-if="!checkValid" cols="12" class="pt-0">
                    <v-btn
                      v-if="!checkLoading"
                      color="primary"
                      class="text-capitalize mb-2"
                      @click="validateCheck"
                    >
                      Check miner credentials
                    </v-btn>
                    <v-icon v-if="checkLoading" color="primary" class="mb-2">
                      fas fa-spinner fa-spin
                    </v-icon>
                    <span
                      v-if="checkLoading"
                      class="font-roboto-condensed-light font-size-15 ml-2"
                    >
                      Checking...
                    </span>
                    <v-divider class="mb-3 mt-5" />
                  </v-col>
                  <v-col v-if="checkValid" cols="12" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Perspective</label
                    >
                    <v-text-field
                      v-model="perspective"
                      :rules="perspectiveRules"
                      :disabled="perspectiveValid"
                      label="Perspective"
                      placeholder="Perspective"
                      dense
                      solo
                    />
                  </v-col>
                  <v-col
                    v-if="checkValid && !perspectiveValid"
                    cols="12"
                    class="py-0"
                  >
                    <v-btn
                      v-if="!checkLoading"
                      color="primary"
                      class="text-capitalize mb-2 mr-2"
                      @click="validateCheck"
                    >
                      Get miner perspective
                    </v-btn>
                    <v-btn
                      v-if="!checkLoading"
                      color="bad-2"
                      class="text-capitalize mb-2 text-white"
                      @click="checkValid = false"
                    >
                      Change
                    </v-btn>
                    <v-icon v-if="checkLoading" color="primary" class="mb-2">
                      fas fa-spinner fa-spin
                    </v-icon>
                    <span
                      v-if="checkLoading"
                      class="font-roboto-condensed-light font-size-15 ml-2"
                    >
                      Checking...
                    </span>
                    <v-divider />
                  </v-col>
                  <v-col
                    v-if="checkValid && perspectiveValid"
                    cols="12"
                    class="pt-0"
                  >
                    <v-btn
                      v-if="!checkLoading"
                      color="bad-2"
                      class="text-capitalize mb-2 text-white"
                      @click="
                        checkValid = false;
                        perspectiveValid = false;
                        perspectiveId = null;
                      "
                    >
                      Change
                    </v-btn>
                  </v-col>
                </v-row>
              </transition>
            </v-form>

            <v-divider v-if="(source && source.id !== 4) || source === ''" />
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--NAME-->
              <v-row class="mx-0 mt-3">
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Name</label
                  >
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    placeholder="Name"
                    dense
                    solo
                  />
                </v-col>
                <!--END NAME-->
                <!--KEEP ALIVE-->
                <v-col v-if="source.id === 1" cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Keep alive time (in minutes)</label
                  >
                  <v-text-field
                    v-model="keepAlive"
                    :rules="keepAliveRules"
                    label="Keep alive time"
                    placeholder="Keep alive time"
                    type="number"
                    min="0"
                    dense
                    solo
                  />
                </v-col>
                <!--END KEEP ALIVE-->
                <!--DESCRIPTION-->
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Description</label
                  >
                  <v-textarea
                    v-model="description"
                    :rules="descriptionRules"
                    placeholder="Description"
                    auto-grow
                    rows="4"
                    dense
                    solo
                  />
                </v-col>
                <!--END DESCRIPTION-->
              </v-row>

              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    :disabled="source.id === 4 && !perspectiveId && !checkValid"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { parserGetByType } from "@/api/settings/parser";
import {
  checkMinerCredentials,
  getMiner,
  getMinerPerspective,
  storeMiner,
  updateMiner,
} from "@/api/settings/miner";

import { Errors } from "@/utils/error";
import { Validation } from "@/utils/validation";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,
      perspectiveValid: null,

      valid: true,
      validCheck: true,
      saving: false,

      ip: "",
      ipRules: {
        required: (v) => !!v || "IP address is required",
      },
      source: "",
      sourceRules: [(v) => !!v || "Source is required"],
      parser: "",
      parserRules: [(v) => !!v || "Parser is required"],
      grant_type_refresh: "",
      grantType_refreshRules: [(v) => !!v || "Grant type refresh is required"],
      grantType: "",
      grantTypeRules: [(v) => !!v || "Grant type is required"],
      clientId: "",
      clientIdRules: [(v) => !!v || "Client ID is required"],
      username: "",
      usernameRules: [(v) => !!v || "Username is required"],
      password: "",
      passwordRules: [(v) => !!v || "Password is required"],
      perspective: null,
      perspectiveId: null,
      perspectiveRules: [(v) => !!v || "Perspective ID is required"],
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      description: "",
      descriptionRules: [(v) => !!v || "Description is required"],
      keepAlive: 60,
      keepAliveRules: [(v) => !!v || "Keep alive is required"],

      sources: [],
      parsers: [],
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        parserGetByType()
          .then((response) => {
            this.sources = response.data;
            if (newValue === 0) {
              this.ip = "";
              this.source = "";
              this.parser = "";
              this.grantType = "";
              this.clientId = "";
              this.username = "";
              this.password = "";
              this.perspective = null;
              this.perspectiveId = null;
              this.name = "";
              this.description = "";
              this.keepAlive = 60;
              this.loading = false;
            } else if (newValue) {
              this.loadById();
            }
          })
          .catch((e) => {
            Errors.show(e);
          });
      },
    },
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "miner");
    },
    loadById() {
      getMiner(this.id)
        .then((response) => {
          const miner = response.data;
          this.ip = miner.ip_address;
          this.name = miner.name;
          this.description = miner.description;
          this.keepAlive = miner.keepalivetime;
          this.perspective = miner.perspective;
          this.perspectiveName = miner.perspectiveName;
          this.username = miner.username;
          this.clientId = miner.clientId;
          this.grantType = miner.grantType;
          const self = this;
          this.checkValid = true;
          this.sources.forEach(function (source) {
            if (source.id === miner.source) {
              self.source = source;
              if (source.id === 1) {
                self.checkValid = false;
              }
            }
          });
          this.parser = miner.parser.id;

          this.loading = false;
          this.perspectiveValid = true;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    validateCheck() {
      this.$refs.formCheck.validate();
      if (this.$refs.formCheck.validate()) {
        this.check();
      }
    },
    validate() {
      if (!this.checkValid) {
        this.$refs.formCheck.validate();
      }
      this.$refs.form.validate();
      if (
        this.$refs.form.validate() &&
        (this.source && this.source.id !== 4
          ? this.$refs.formCheck.validate()
          : true)
      ) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    ipValidate(val) {
      return Validation.ip(val);
    },
    check() {
      this.checkLoading = true;
      const data = {
        grant_type_login: this.grantType,
        grant_type_refresh: this.grant_type_refresh,
        client_id: this.clientId,
        username: this.username,
        password: this.password,
        ip_address: this.ip,
        parser_id: this.parser,
        perspective: this.perspective,
        perspectiveId: this.perspectiveId,
      };
      if (!this.checkValid) {
        checkMinerCredentials(data)
          .then((response) => {
            this.checkLoading = false;
            if (response.data.state) {
              this.checkValid = true;
            } else {
              Errors.show(response);
            }
          })
          .catch((e) => {
            Errors.show(e);
            this.checkLoading = false;
          });
      } else {
        getMinerPerspective(data)
          .then((response) => {
            this.checkLoading = false;
            if (response.data.state) {
              this.checkValid = true;
              this.perspectiveValid = true;
              this.perspectiveId = response.data.perspective_id;
            } else {
              Errors.show(response);
            }
          })
          .catch((e) => {
            Errors.show(e);
            this.checkLoading = false;
          });
      }
    },
    store() {
      this.saving = true;
      const data = {
        name: this.name,
        ip_address: this.ip,
        description: this.description,
        source: this.source.id,
        keepalivetime: this.keepAlive,
        parser_id: this.parser,
        minercredibility_id: 4,
        minergroup_id: 1,
        perspective: this.perspective,
        perspectiveId: this.perspectiveId,
        grant_type_login: this.grantType,
        grant_type_refresh: this.grant_type_refresh,
        client_id: this.clientId,
        username: this.username,
        password: this.password,
      };
      storeMiner(data)
        .then(() => {
          this.$toast.open({
            message: "Miner was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    update() {
      this.saving = true;
      let data;
      if (!this.perspectiveId && this.source.id === 4) {
        data = {
          id: this.id,
          name: this.name,
          description: this.description,
        };
      } else {
        data = data = {
          id: this.id,
          name: this.name,
          ip_address: this.ip,
          description: this.description,
          source: this.source.id,
          keepalivetime: this.keepAlive,
          parser_id: this.parser,
          minercredibility_id: 4,
          minergroup_id: 1,
          perspective: this.perspective,
          perspectiveId: this.perspectiveId,
          grant_type_login: this.grantType,
          grant_type_refresh: this.grant_type_refresh,
          client_id: this.clientId,
          username: this.username,
          password: this.password,
        };
      }
      updateMiner(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Miner was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.saving = false;
          this.change();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
