<template>
  <v-container
    fluid
    class="synapsa-settings-block"
    :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
  >
    <v-row>
      <v-col cols="12" class="pt-0 pb-2 px-0">
        <v-list-item-title class="font-roboto-condensed-regular">
          Table settings
          <v-btn
            text
            color="bad-3"
            height="25"
            class="text-capitalize"
            @click="setDefaultSettings"
          >
            set default
          </v-btn>
        </v-list-item-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" md="3" xl="2" class="pt-0 pl-0">
        <v-list-item-title
          class="font-roboto-condensed-regular font-size-15 mb-1"
          >Headers</v-list-item-title
        >
        <draggable :list="headers" @end="update" group="columns" class="h-100">
          <v-row
            v-for="column in headers"
            :key="column.value"
            class="my-0 mx-0 mb-2 cursor-pointer font-ubuntu-bold drag-column-block"
            :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
          >
            <v-col cols="9" class="py-2 font-size-12">
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <span v-if="column.visible">{{ column.text }}</span>
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <span v-if="!column.visible" class="primary--text">{{
                  column.text
                }}</span>
              </transition>
            </v-col>
            <v-col cols="3" class="pt-1 pb-0 pr-2 font-size-15 text-right">
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-avatar
                  v-if="column.visible"
                  size="22"
                  class="br-0"
                  @click="[(column.visible = !column.visible), update()]"
                >
                  <img
                    :src="
                      $vuetify.theme.dark
                        ? location + '/images/icons/white/minus-white.svg'
                        : location + '/images/icons/dark/minus.svg'
                    "
                    alt="minus"
                  />
                </v-avatar>
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-avatar
                  v-if="!column.visible"
                  size="22"
                  class="br-0"
                  @click="[(column.visible = !column.visible), update()]"
                >
                  <img
                    :src="location + '/images/icons/green/plus-green.svg'"
                    alt="plus"
                  />
                </v-avatar>
              </transition>
            </v-col>
          </v-row>
        </draggable>
      </v-col>
      <v-col cols="4" md="3" xl="2" class="pt-0 px-0">
        <v-list-item-title
          class="font-roboto-condensed-regular font-size-15 mb-1"
          >Expanded area</v-list-item-title
        >
        <draggable :list="expanded" @end="update" group="columns" class="h-100">
          <v-row
            v-for="column in expanded"
            :key="column.value"
            class="my-0 mx-0 mb-2 cursor-pointer font-ubuntu-bold drag-column-block"
            :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
          >
            <v-col cols="9" class="py-2 font-size-12">
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <span v-if="column.visible">{{ column.text }}</span>
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <span v-if="!column.visible" class="primary--text">{{
                  column.text
                }}</span>
              </transition>
            </v-col>
            <v-col cols="3" class="pt-1 pb-0 pr-2 font-size-15 text-right">
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-avatar
                  v-if="column.visible"
                  size="20"
                  class="br-0"
                  @click="[(column.visible = !column.visible), update()]"
                >
                  <img
                    :src="
                      $vuetify.theme.dark
                        ? location + '/images/icons/white/minus-white.svg'
                        : location + '/images/icons/dark/minus.svg'
                    "
                    alt="minus"
                  />
                </v-avatar>
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-avatar
                  v-if="!column.visible"
                  size="20"
                  class="br-0"
                  @click="[(column.visible = !column.visible), update()]"
                >
                  <img
                    :src="location + '/images/icons/green/plus-green.svg'"
                    alt="plus"
                  />
                </v-avatar>
              </transition>
            </v-col>
          </v-row>
        </draggable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { Errors } from "@/utils/error";
import { setUserTableSettings } from "@/api/system/user";
import { debounce } from "@/utils";
import { Tables } from "@/utils/tables";

export default {
  components: {
    draggable,
  },
  props: {
    process: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      headers: null,
      expanded: null,
      location: "",
    };
  },
  computed: {
    monitorAuditorRulesetConfirmedTable: {
      get() {
        return this.$store.state.tables.monitorAuditorRulesetConfirmedTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorAuditorRulesetConfirmedTable",
          value: val,
        });
      },
    },
    monitorAuditorRulesetFixedTable: {
      get() {
        return this.$store.state.tables.monitorAuditorRulesetFixedTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorAuditorRulesetFixedTable",
          value: val,
        });
      },
    },
    monitorAuditorCveActiveTable: {
      get() {
        return this.$store.state.tables.monitorAuditorCveActiveTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorAuditorCveActiveTable",
          value: val,
        });
      },
    },
    monitorAuditorCveConfirmedTable: {
      get() {
        return this.$store.state.tables.monitorAuditorCveConfirmedTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorAuditorCveConfirmedTable",
          value: val,
        });
      },
    },
    monitorAuditorIntegrityActiveTable: {
      get() {
        return this.$store.state.tables.monitorAuditorIntegrityActiveTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorAuditorIntegrityActiveTable",
          value: val,
        });
      },
    },
    monitorAuditorIntegrityConfirmedTable: {
      get() {
        return this.$store.state.tables.monitorAuditorIntegrityConfirmedTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorAuditorIntegrityConfirmedTable",
          value: val,
        });
      },
    },
    monitorAuditorIntegrityHistoryTable: {
      get() {
        return this.$store.state.tables.monitorAuditorIntegrityHistoryTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorAuditorIntegrityHistoryTable",
          value: val,
        });
      },
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loadData(newValue);
      },
    },
    process: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.loadData(this.$route);
        }
      },
    },
  },
  methods: {
    loadData(route) {
      if (route.name === "rulesetAlertConfirmed") {
        this.headers = this.monitorAuditorRulesetConfirmedTable.headers;
        this.expanded = this.monitorAuditorRulesetConfirmedTable.expanded;
      } else if (route.name === "rulesetAlertFixed") {
        this.headers = this.monitorAuditorRulesetFixedTable.headers;
        this.expanded = this.monitorAuditorRulesetFixedTable.expanded;
      } else if (route.name === "cveAlertActive") {
        this.headers = this.monitorAuditorCveActiveTable.headers;
        this.expanded = this.monitorAuditorCveActiveTable.expanded;
      } else if (route.name === "cveAlertConfirmed") {
        this.headers = this.monitorAuditorCveConfirmedTable.headers;
        this.expanded = this.monitorAuditorCveConfirmedTable.expanded;
      } else if (route.name === "integrityAlertActive") {
        this.headers = this.monitorAuditorIntegrityActiveTable.headers;
        this.expanded = this.monitorAuditorIntegrityActiveTable.expanded;
      } else if (route.name === "integrityAlertConfirmed") {
        this.headers = this.monitorAuditorIntegrityConfirmedTable.headers;
        this.expanded = this.monitorAuditorIntegrityConfirmedTable.expanded;
      } else if (route.name === "integrityAlertHistory") {
        this.headers = this.monitorAuditorIntegrityHistoryTable.headers;
        this.expanded = this.monitorAuditorIntegrityHistoryTable.expanded;
      }
    },
    update: debounce(function () {
      let data = null;
      if (this.$route.name === "rulesetAlertConfirmed") {
        data = {
          table_name: "monitorAuditorRulesetConfirmedTable",
          table_data: JSON.stringify(this.monitorAuditorRulesetConfirmedTable),
        };
      } else if (this.$route.name === "rulesetAlertFixed") {
        data = {
          table_name: "monitorAuditorRulesetFixedTable",
          table_data: JSON.stringify(this.monitorAuditorRulesetFixedTable),
        };
      } else if (this.$route.name === "cveAlertActive") {
        data = {
          table_name: "monitorAuditorCveActiveTable",
          table_data: JSON.stringify(this.monitorAuditorCveActiveTable),
        };
      } else if (this.$route.name === "cveAlertConfirmed") {
        data = {
          table_name: "monitorAuditorCveConfirmedTable",
          table_data: JSON.stringify(this.monitorAuditorCveConfirmedTable),
        };
      } else if (this.$route.name === "integrityAlertActive") {
        data = {
          table_name: "monitorAuditorIntegrityActiveTable",
          table_data: JSON.stringify(this.monitorAuditorIntegrityActiveTable),
        };
      } else if (this.$route.name === "integrityAlertConfirmed") {
        data = {
          table_name: "monitorAuditorIntegrityConfirmedTable",
          table_data: JSON.stringify(
            this.monitorAuditorIntegrityConfirmedTable
          ),
        };
      } else if (this.$route.name === "integrityAlertHistory") {
        data = {
          table_name: "monitorAuditorIntegrityHistoryTable",
          table_data: JSON.stringify(this.monitorAuditorIntegrityHistoryTable),
        };
      }
      setUserTableSettings(data)
        .then(() => {
          if (this.$route.name === "monitorAuditorRulesetConfirmedTable") {
            Tables.setSetting(
              this.$route.name,
              this.monitorAuditorRulesetConfirmedTable
            );
          } else if (this.$route.name === "monitorAuditorRulesetFixedTable") {
            Tables.setSetting(
              this.$route.name,
              this.monitorAuditorRulesetFixedTable
            );
          } else if (this.$route.name === "monitorAuditorCveActiveTable") {
            Tables.setSetting(
              this.$route.name,
              this.monitorAuditorCveActiveTable
            );
          } else if (this.$route.name === "monitorAuditorCveConfirmedTable") {
            Tables.setSetting(
              this.$route.name,
              this.monitorAuditorCveConfirmedTable
            );
          } else if (
            this.$route.name === "monitorAuditorIntegrityActiveTable"
          ) {
            Tables.setSetting(
              this.$route.name,
              this.monitorAuditorIntegrityActiveTable
            );
          } else if (
            this.$route.name === "monitorAuditorIntegrityConfirmedTable"
          ) {
            Tables.setSetting(
              this.$route.name,
              this.monitorAuditorIntegrityConfirmedTable
            );
          } else if (
            this.$route.name === "monitorAuditorIntegrityHistoryTable"
          ) {
            Tables.setSetting(
              this.$route.name,
              this.monitorAuditorIntegrityHistoryTable
            );
          }
          this.$toast.open({
            message: "Table was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
        })
        .catch((e) => {
          Errors.show(e);
        });
    }, 500),
    setDefaultSettings() {
      if (this.$route.name === "rulesetAlertConfirmed") {
        this.$emit("default", "monitorAuditorRulesetConfirmedTable");
      } else if (this.$route.name === "rulesetAlertFixed") {
        this.$emit("default", "monitorAuditorRulesetFixedTable");
      } else if (this.$route.name === "cveAlertActive") {
        this.$emit("default", "monitorAuditorCveActiveTable");
      } else if (this.$route.name === "cveAlertConfirmed") {
        this.$emit("default", "monitorAuditorCveConfirmedTable");
      } else if (this.$route.name === "integrityAlertActive") {
        this.$emit("default", "monitorAuditorIntegrityActiveTable");
      } else if (this.$route.name === "integrityAlertConfirmed") {
        this.$emit("default", "monitorAuditorIntegrityConfirmedTable");
      } else if (this.$route.name === "integrityAlertHistory") {
        this.$emit("default", "monitorAuditorIntegrityHistoryTable");
      }
    },
  },
  created() {
    this.location = window.location.origin;
  },
};
</script>
