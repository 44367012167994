<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :keywordsResize2="keywordsResize2"
      @resize="resize($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pb-2 pt-3">
            <h3 class="font-ubuntu-bold">Set SSL</h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--DOMAIN-->
              <v-row class="mx-0" :class="'mt-4'">
                <v-col cols="12" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Domain</label
                  >
                  <v-text-field
                    v-model="domain"
                    :rules="domainRules"
                    type="text"
                    label="Domain address"
                    placeholder="Domain address"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END DOMAIN-->
              <!--AUTHORITY-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Authority</label
                  >
                  <v-select
                    v-model="authority"
                    :disabled="checkValid"
                    :items="authorityItems"
                    :rules="authorityRules"
                    item-text="name"
                    item-value="value"
                    label="Authority"
                    placeholder="Authority"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END AUTHORITY-->
              <!--UPLOAD-->
              <v-row v-if="authority === 'own'" class="mt-0 mx-0">
                <v-col cols="12" class="px-0">
                  <v-file-input
                    v-model="crtFile"
                    :rules="[crtFileRules.required, crtFileRules.size]"
                    accept=".crt"
                    placeholder="Choose .crt file"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <v-row v-if="authority === 'own'" class="mt-0 mx-0">
                <v-col cols="12" class="px-0">
                  <v-file-input
                    v-model="keyFile"
                    :rules="[keyFileRules.required, keyFileRules.size]"
                    accept=".key"
                    placeholder="Choose .key file"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END UPLOAD-->

              <v-row class="mt-1 mx-0">
                <v-col cols="12" class="py-0 px-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    Create
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import { storeSsl } from "@/api/system/ssl";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
    keywordsResize2: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,

      valid: true,
      validCheck: true,
      crtFile: null,
      crtFileRules: {
        required: (v) => !!v || "CRT file is required",
        size: (v) =>
          !v || v.size < 2000000 || "CRT file size should be less than 2 MB!",
      },
      keyFile: null,
      keyFileRules: {
        required: (v) => !!v || "KEY file is required",
        size: (v) =>
          !v || v.size < 2000000 || "KEY file size should be less than 2 MB!",
      },
      saving: false,
      authority: "certbot",
      authorityRules: [(v) => !!v || "Authority is required"],
      authorityItems: [
        { name: "Let's Encrypt", value: "certbot" },
        { name: "Own", value: "own" },
      ],
      domain: "",
      domainRules: [(v) => !!v || "Domain is required"],
    };
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "systemSsl");
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.store();
      }
    },
    store() {
      let formData = new FormData();
      this.saving = true;
      formData.append("cert", this.authority);
      formData.append("domain", this.domain);
      formData.append("crt", this.crtFile);
      formData.append("key", this.keyFile);
      storeSsl(formData)
        .then(() => {
          this.$toast.open({
            message: "SSL was succesfully imported!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
          this.saving = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
