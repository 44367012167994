<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :only-full="true"
      @resize="resize($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="4" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold d-inline-block mr-3">
              CVE Alert detail
            </h3>
            <v-icon :class="severityClass(severity)">
              mdi-view-grid
            </v-icon>
            {{ severityNames[severity - 1] }}
          </v-col>
          <v-col cols="4" class="pt-5 pb-0 pl-6 text-center" style="align-self: center;">
            <b>{{ firewall }} </b>
            <span>(v.{{ firewallVersion }})</span>
          </v-col>

          <v-col cols="4" class="text-right pt-5 pb-0 pr-6">
            <alert-menu :processing="processing" @proceed="confirmAlert($event)" />
          </v-col>

          <v-col cols="12" md="7" class="pt-0">
            <v-row class="mx-0 mt-3">
              <v-col cols="12" class="py-0 pr-0">
                <v-progress-linear
                  :value="ratePercentage"
                  height="40"
                  :color="severityColors[severity - 1]"
                  class="cve-progress-bar elevation-4 mb-2"
                >
                  <strong>{{ rate }}</strong>
                </v-progress-linear>
              </v-col>
              <v-col cols="12" class="pb-0 pr-0">
                <v-card class="synapsa-table-card">
                  <v-row class="mt-1 mx-0">
                    <v-col cols="3">
                      <div class="font-ubuntu-bold synapsa-table-card-headline">
                        Attack Vector
                      </div>
                      <span
                        class="synapsa-table-card-text font-roboto-condensed-regular"
                        >{{ description.AV }}</span
                      >
                    </v-col>
                    <v-col cols="3">
                      <div class="font-ubuntu-bold synapsa-table-card-headline">
                        Attack Complexity
                      </div>
                      <span
                        class="synapsa-table-card-text font-roboto-condensed-regular"
                        >{{ description.AC }}</span
                      >
                    </v-col>
                    <v-col cols="3">
                      <div class="font-ubuntu-bold synapsa-table-card-headline">
                        Privileges Required
                      </div>
                      <span
                        class="synapsa-table-card-text font-roboto-condensed-regular"
                        >{{ description.PR }}</span
                      >
                    </v-col>
                    <v-col cols="3">
                      <div class="font-ubuntu-bold synapsa-table-card-headline">
                        User Interaction
                      </div>
                      <span
                        class="synapsa-table-card-text font-roboto-condensed-regular"
                        >{{ description.UI }}</span
                      >
                    </v-col>
                    <v-col cols="3">
                      <div class="font-ubuntu-bold synapsa-table-card-headline">
                        Scope
                      </div>
                      <span
                        class="synapsa-table-card-text font-roboto-condensed-regular"
                        >{{ description.S }}</span
                      >
                    </v-col>
                    <v-col cols="3">
                      <div class="font-ubuntu-bold synapsa-table-card-headline">
                        Integrity Impact
                      </div>
                      <span
                        class="synapsa-table-card-text font-roboto-condensed-regular"
                        >{{ description.I }}</span
                      >
                    </v-col>
                    <v-col cols="3">
                      <div class="font-ubuntu-bold synapsa-table-card-headline">
                        Confidentiality Impact
                      </div>
                      <span
                        class="synapsa-table-card-text font-roboto-condensed-regular"
                        >{{ description.C }}</span
                      >
                    </v-col>
                    <v-col cols="3">
                      <div class="font-ubuntu-bold synapsa-table-card-headline">
                        Availability Impact
                      </div>
                      <span
                        class="synapsa-table-card-text font-roboto-condensed-regular"
                        >{{ description.A }}</span
                      >
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5" class="pt-5 mt-1 pt-md-3 mt-md-0 pr-md-6">
            <v-data-table
              :headers="versionHeaders"
              :items="versionData"
              hide-default-footer
              item-key="id"
              :loading="loading"
              loading-text="Loading data from API...please wait"
              class="synapsa-table"
            />
          </v-col>
        </v-row>

        <v-row v-if="size !== 1 && !loading" class="mt-2 mx-0">
          <v-col cols="12" class="pt-4 px-6">
            <v-card class="synapsa-table-card">
              <v-row class="mt-1 mx-0">
                <v-col cols="12">
                  <div class="font-ubuntu-bold synapsa-table-card-headline">
                    Problem
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <div
                    class="font-roboto-condensed-regular synapsa-table-card-text"
                  >
                    <p v-for="(paragraph, key) in problem" :key="key">
                      {{ paragraph.value }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12" class="pt-6 px-6">
            <v-card class="synapsa-table-card">
              <v-row class="mt-1 mx-0">
                <v-col cols="12">
                  <div class="font-ubuntu-bold synapsa-table-card-headline">
                    Solution
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <div
                    class="font-roboto-condensed-regular synapsa-table-card-text"
                  >
                    <p v-for="(paragraph, key) in solution" :key="key">
                      {{ paragraph.value }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12" class="pt-6 px-6">
            <v-card class="synapsa-table-card">
              <v-row class="mt-1 mx-0">
                <v-col cols="12">
                  <div class="font-ubuntu-bold synapsa-table-card-headline">
                    Workarounds and Mitigations
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <div
                    class="font-roboto-condensed-regular synapsa-table-card-text"
                  >
                    <p v-for="(paragraph, key) in workAround" :key="key">
                      {{ paragraph.value }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { confirmCveAlerts, getCveAlert } from "@/api/monitor/auditor";
import { Errors } from "@/utils/error";
import { MonitorParser } from "@/layout/FormSidebar/monitor/parser";
import AlertMenu from "./cve/confirm";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 3;
      },
    },
  },
  components: {
    Topbar,
    AlertMenu,
  },
  data() {
    return {
      loading: true,
      processing: false,

      severity: 1,
      rate: "0.0",
      ratePercentage: 0,
      versionHeaders: [
        { text: "Version", value: "version", sortable: false },
        { text: "Fixed", value: "fixed", sortable: false },
        { text: "Affected", value: "affected", sortable: false },
      ],
      versionData: [],
      description: null,
      firewall: null,
      firewallVersion: null,
      problem: "",
      solution: "",
      workAround: "",

      severityColors: ["good-3", "medium-3", "high-1", "bad-3"],
      severityNames: ["Low", "Medium", "High", "Critical"],
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.tableLoading = true;
          this.loadById();
        }
      },
    },
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "auditorAlert");
    },
    loadById() {
      this.loading = true;
      this.resize("bigger");
      getCveAlert(this.id)
        .then((response) => {
          const alert = response.data;
          this.severity = alert.severity;
          this.rate = alert.rate;
          this.ratePercentage = alert.rate * 10;
          this.versionData = MonitorParser.cveVersionToTable(
            alert.affected,
            alert.fixed,
            alert.version
          );
          this.description = JSON.parse(alert.description);
          this.firewall = alert.firewall;
          this.firewallVersion = alert.firewall_version;
          this.problem = JSON.parse(alert.problem);
          this.solution = JSON.parse(alert.solution);
          this.workAround = JSON.parse(alert.work_around);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    confirmAlert(event) {
      this.processing = true;
      const ids = [this.id];
      const data = {
        alerts_id: JSON.stringify(ids),
        comment: event.comment,
      };
      confirmCveAlerts(data)
        .then(() => {
          this.$toast.open({
            message: "Alert was successfully confirmed!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.processing = false;
          this.close();
          this.change();
        })
        .catch((e) => {
          Errors.show(e);
          this.processing = false;
        });
    },
    severityClass(status){
      if(status === 1){
        return "signal-icon--low"
      } else if (status === 2){
        return "signal-icon--medium"
      } else if (status === 3) {
        return "signal-icon--high"
      } else {
        return "signal-icon--critical"
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cve-progress-bar {
  border-radius: 15px;
  box-shadow: 13px 13px 30px rgb(0 0 0 / 10%);
}
.form-block {
  .v-icon {
    vertical-align: inherit;
  }
}
</style>
