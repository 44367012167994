<template>
  <v-container :fluid="true" class="px-0 py-0">
    <!--NAME-->
    <v-row class="mx-0 mt-0">
      <v-col cols="6" class="pb-0">
        <label class="font-roboto-condensed-regular font-size-15">Name</label>
        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Name"
          placeholder="Name"
          dense
          solo
          @keyup="update"
        />
      </v-col>
    <!--END NAME-->

    <!--AUDITOR GROUPS-->
      <v-col cols="6" class="pb-0">
        <label class="font-roboto-condensed-regular font-size-15">Auditor Group</label>
        <v-autocomplete
            v-model="auditorGroup"
            :items="auditorGroups"
            item-text="name"
            item-value="id"
            placeholder="Auditor group"
            multiple
            outlined
            @change="update"
            class="auditor-group"
        >
          <template v-slot:selection="data">
            <v-chip
                small
                color="primary"
                class="elevation-2 synapsa-chip"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <v-chip
                small
                color="primary"
                class="elevation-2 synapsa-chip"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <!--AUDITOR GROUPS-->

    <!--SEVERITY-->
    <v-row class="mt-0 mx-0">
      <v-col cols="6" class="pb-0">
        <label class="font-roboto-condensed-regular font-size-15"
          >Severity</label
        >
        <v-autocomplete
          v-model="severity"
          :items="severities"
          :rules="severityRules"
          item-text="name"
          item-value="id"
          label="Severity"
          placeholder="Severity"
          dense
          solo
          @change="update"
        >
          <template v-slot:selection="data">
            <v-icon :class="severityClass(data.item.id)" small class="mr-2 severity-icon">
              mdi-view-grid
            </v-icon>
            <span class="default--text font-roboto-condensed-light">{{
              data.item.name
            }}</span>
          </template>
          <template v-slot:item="data">
            <v-icon :class="severityClass(data.item.id)" small class="mr-2 severity-icon">
              mdi-view-grid
            </v-icon>
            <span class="default--text font-roboto-condensed-light">{{
              data.item.name
            }}</span>
          </template>
        </v-autocomplete>
      </v-col>
    <!--END SEVERITY-->

    <!--ACTION-->
      <v-col cols="6" class="pb-0">
        <label class="font-roboto-condensed-regular font-size-15">Action</label>
        <v-select
          v-model="action"
          :items="actions"
          :rules="actionRules"
          item-text="name"
          item-value="id"
          label="Action"
          placeholder="Action"
          dense
          solo
          @change="update"
        />
      </v-col>
    </v-row>
    <!--END ACTION-->

    <!-- DESCRIPTIONS -->
    <v-col cols="12" class="pb-0">
        <label class="font-roboto-condensed-regular font-size-15"
          >Description</label
        >
        <v-textarea
          v-model="description"
          placeholder="Description"
          auto-grow
          rows="4"
          dense
          solo
          @keyup="update"
        />
      </v-col>
    <!--END DESCRIPTION-->
      
    <!--STATE-->
    <v-row class="mt-0 mx-0">
      <v-col cols="12" class="pb-0 align-self-center">
        <label class="font-roboto-condensed-regular font-size-15">State</label>
          <div class="toggle">
          <v-switch
          v-model="state"
          :value="true"
          hide-details
          label="Active"
          @change="update"
          inset
          :class="[state ? 'v-switch-on' : 'v-switch-off']"
          class="target-background v-switch-off"
          />
          </div>
      </v-col>
    </v-row>
    <!--END STATE-->
  </v-container>
</template>

<script>
import {getAuditorGroups} from "@/api/auditor/group";
import {Errors} from "@/utils/error";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
    vendors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      valid: true,

      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      description: "",
      severity: null,
      severityRules: [(v) => !!v || "Severity is required"],
      severities: [
        { id: 1, name: "Low", color: "good-3" },
        { id: 2, name: "Medium", color: "medium-3" },
        { id: 3, name: "High", color: "high-1" },
        { id: 4, name: "Critical", color: "bad-3" },
      ],
      action: null,
      actionRules: [(v) => v !== null || "Action is required"],
      actions: [
        { id: 0, name: "Alert" },
        { id: 1, name: "Alert and Disable" },
      ],
      state: true,
      auditorGroup: [],
      auditorGroups: [],
    };
  },
  created() {
    if (this.mountData) {
      this.name = this.mountData.name;
      this.description = this.mountData.description;
      this.severity = this.mountData.severity;
      this.action = this.mountData.action || this.actions[0].id;
      this.state = this.mountData.state;
      this.auditorGroup = this.mountData.auditorGroup;
    }
    this.loadAuditorGroups();
  },
  methods: {
    update() {
      this.$emit("update", {
        name: this.name,
        description: this.description,
        severity: this.severity,
        action: this.action,
        state: this.state,
        firstRender: false,
        auditorGroup: this.auditorGroup,
      });
    },
    loadAuditorGroups() {
      getAuditorGroups()
          .then((response) => {
            this.auditorGroups = response;
          })
          .catch((e) => {
            Errors.show(e);
          });
    },
    severityClass(status){
      if(status === 1){
        return "signal-icon--low"
      } else if (status === 2){
        return "signal-icon--medium"
      } else if (status === 3) {
        return "signal-icon--high"
      } else {
        return "signal-icon--critical"
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  ::v-deep .v-input__slot{
    min-height: 38px !important;
  }
  .auditor-group ::v-deep{
    .v-input__append-inner {
      margin-top: 7px !important;
    }
  }
  .v-select__selections{
    padding: 0 !important;
  }
  .severity-icon {
  font-size: 24px !important;
}
.toggle .v-input--selection-controls{
  margin-top: -6px !important;
}
</style>
