import request from "@/utils/request";

export function storeTask(data) {
  return request({
    url: "api/system/tasks",
    method: "post",
    data: data,
  });
}

export function updateTask(data, id) {
  return request({
    url: "api/system/tasks/" + id,
    method: "put",
    data: data,
  });
}

export function getTask(id) {
  return request({
    url: "api/system/tasks/" + id,
    method: "get",
  });
}

export function getTaskResults(id, page, size) {
  return request({
    url: `api/system/task/results/${id}?page=${page}&size=${size}`,
    method: "get",
  });
}

export function getTaskResultContent(id) {
  return request({
    url: `api/system/task/result/content/${id}`,
    method: "get",
  });
}

export function getTaskResultContentSearch(id, searchTerm, showSearchedRowsOnly) {
  return request({
    url: `api/system/task/result/content/${id}/search?searchTerm=${searchTerm}&rowsOnly=${showSearchedRowsOnly ? 1 : 0}`,
    method: "get",
  });
}

export function getTasks(page, size) {
  return request({
    url: "api/system/tasks?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function deleteTask(id) {
  return request({
    url: "api/system/tasks/" + id,
    method: "delete",
  });
}

export function taskExport() {
  return request({
    url: "api/system/task/export",
    method: "get",
    responseType: "blob",
  });
}

export function taskImport(data) {
  return request({
    url: "api/system/task/import",
    method: "post",
    data: data,
  });
}

export function taskExecute(id) {
  return request({
    url: "api/system/task/execute/" + id,
    method: "get",
    timeout: 360000,
  });
}

export function taskCommands() {
  return request({
    url: "api/system/task/commands",
    method: "get",
  });
}

export function taskCommandList() {
  return request({
    url: "api/system/task/commandlist",
    method: "get",
  });
}

export function taskFrequencies() {
  return request({
    url: "api/system/task/frequencies",
    method: "get",
  });
}

export function taskTimezones() {
  return request({
    url: "api/system/task/timezones",
    method: "get",
  });
}

export function activateTask(id) {
  return request({
    url: "api/system/task/status/" + id,
    method: "post",
  });
}

export function deactivateTask(id) {
  return request({
    url: "api/system/task/status/" + id,
    method: "delete",
  });
}
