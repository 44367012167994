<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new firewall" : "Edit firewall" }}
            </h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="formCheck" v-model="validCheck" lazy-validation>
              <!--VENDOR-->
              <v-row class="mx-0 mt-3">
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Vendor</label
                  >
                  <v-select
                    v-model="vendor"
                    :disabled="checkValid"
                    :items="vendors"
                    :rules="vendorRules"
                    item-text="name"
                    item-value="id"
                    label="Vendor"
                    placeholder="Vendor"
                    dense
                    solo
                    @change="loadCheckCalls"
                  />
                </v-col>
                <!--END VENDOR-->

                <!--IP-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="vendor" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >IP address</label
                    >
                    <v-text-field
                      v-model="ip"
                      :disabled="checkValid"
                      :rules="[ipRules.required, ipValidate]"
                      label="IP address"
                      placeholder="IP address"
                      dense
                      solo
                    />
                  </v-col>
                </transition>
                <!--END IP-->

                <!--SELECT CALL-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="vendor && vendor !== 1" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Select call</label
                    >
                    <v-select
                      v-model="checkCall"
                      :disabled="checkValid"
                      :items="checkCalls"
                      :rules="checkCallRules"
                      item-text="name"
                      item-value="id"
                      label="Check call"
                      placeholder="Check call"
                      dense
                      solo
                    />
                  </v-col>
                </transition>
                <!--END SELECT CALL-->
                <!--API SECRET-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="[1, 2].includes(vendor)" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >API Secret</label
                    >
                    <v-text-field
                      v-model="apiSecret"
                      :disabled="checkValid"
                      :rules="apiSecretRules"
                      label="API Secret"
                      placeholder="API Secret"
                      type="password"
                      dense
                      solo
                    />
                  </v-col>
                </transition>
                <!--END API SECRET-->

                <!--SELECT Ruleset-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="vendor && vendor === 1 && id !== 0" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                    >Ruleset Active</label
                    >
                    <v-select
                      v-model="rulesetStatus"
                      :disabled="checkValid"
                      :items="rulesetStatusData"
                      :rules="rulesetStatusRules"
                      item-text="name"
                      item-value="id"
                      label="Ruleset Active"
                      placeholder="Ruleset Active"
                      dense
                      solo
                    />
                  </v-col>
                </transition>
                <!--END SELECT Ruleset-->

                <!--VDOM-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="vendor === 2" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >VDOM (default: root)</label
                    >
                    <v-text-field
                      v-model="vdom"
                      :disabled="checkValid"
                      :rules="vdomRules"
                      label="VDOM"
                      placeholder="VDOM"
                      dense
                      solo
                    />
                  </v-col>
                </transition>
                <!--END VDOM-->

                <!--LOGIN-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="[3, 4].includes(vendor)" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Login</label
                    >
                    <v-text-field
                      v-model="login"
                      :disabled="checkValid"
                      :rules="loginRules"
                      label="Login"
                      placeholder="Login"
                      dense
                      solo
                    />
                  </v-col>
                </transition>
                <!--END LOGIN-->
                <!--PASSWORD-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col v-if="[3, 4].includes(vendor)" cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Password</label
                    >
                    <v-text-field
                      v-model="password"
                      :disabled="checkValid"
                      :rules="passwordRules"
                      label="Password"
                      placeholder="Password"
                      type="password"
                      dense
                      solo
                    />
                  </v-col>
                </transition>
                <!--END PASSWORD-->
              </v-row>

              <!--FIREWALL CHECK-->
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-row v-if="vendor" class="mx-0 mt-3">
                  <v-col v-if="!checkValid" cols="12" class="pt-0">
                    <v-btn
                      v-if="!checkLoading"
                      color="primary"
                      class="text-capitalize mb-2"
                      @click="validateCheck"
                    >
                      Check firewall
                    </v-btn>
                    <v-icon v-if="checkLoading" color="primary" class="mb-2">
                      fas fa-spinner fa-spin
                    </v-icon>
                    <span
                      v-if="checkLoading"
                      class="font-roboto-condensed-light font-size-15 ml-2"
                    >
                      Checking...
                    </span>
                    <v-divider />
                  </v-col>
                  <v-col
                    v-if="checkValid && firewallCheck"
                    cols="12"
                    class="py-0 px-0"
                  >
                    <div
                      v-if="[1, 2, 3].includes(vendor)"
                      class="font-size-12 primary--text"
                    >
                      Hostname: {{ firewallCheck.hostname }}
                    </div>
                    <div
                      v-if="[1, 2, 3].includes(vendor)"
                      class="font-size-12 primary--text"
                    >
                      SW version: {{ firewallCheck.sw_version }}
                    </div>
                    <div
                      v-if="[1, 2, 3].includes(vendor)"
                      class="font-size-12 primary--text"
                    >
                      Model: {{ firewallCheck.model }}
                    </div>
                    <div
                      v-if="[2].includes(vendor)"
                      class="font-size-12 primary--text"
                    >
                      Serial: {{ firewallCheck.serial }}
                    </div>
                  </v-col>
                  <v-col v-if="checkValid" cols="12" class="pt-0">
                    <v-btn
                      color="bad-2"
                      class="text-capitalize mb-2 text-white"
                      @click="checkValid = false"
                    >
                      Change
                    </v-btn>
                  </v-col>
                </v-row>
              </transition>
              <!--END FIREWALL CHECK-->
            </v-form>

            <v-divider v-if="!vendor" />
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--NAME-->
              <v-row class="mx-0 mt-3">
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Name</label
                  >
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    placeholder="Name"
                    dense
                    solo
                  />
                </v-col>
                <!--END NAME-->

                <!--MASKED TAGS-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Masked tags</label
                    >
                    <v-combobox
                      v-model="maskedTags"
                      :items="maskedTagsList"
                      label="Select predefined tags or set new"
                      placeholder="Select predefined tags or set new"
                      multiple
                      chips
                      dense
                      solo
                      class="hide-append-icon"
                      @change="updateMaskedTagList"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          color="neutral-4"
                          class="synapsa-chip text-white"
                        >
                          {{ data.item }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </transition>
                <!--END MASKED TAGS-->

                <!--MASKED TAGS-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Available Auditor types</label
                    >
                    <v-autocomplete
                      v-model="auditorType"
                      :items="auditorTypes"
                      item-text="name"
                      item-value="id"
                      label="Available Auditor Types"
                      placeholder="AuditorTypes"
                      dense
                      solo
                      multiple
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          color="neutral-4"
                          class="synapsa-chip text-white"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-chip
                          small
                          color="neutral-4"
                          class="synapsa-chip text-white"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </transition>
                <!--END MASKED TAGS-->

                <!--SKIP TAGS-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                >
                  <v-col cols="6" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Skip tags</label
                    >
                    <v-combobox
                      v-model="skipTags"
                      :items="skipTagsList"
                      label="Select predefined tags or set new"
                      placeholder="Select predefined tags or set new"
                      multiple
                      chips
                      dense
                      solo
                      class="hide-append-icon"
                      @change="updateSkipTagList"
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          color="neutral-4"
                          class="synapsa-chip text-white"
                        >
                          {{ data.item }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </transition>
                <!--END SKIP TAGS-->
                <!--DESCRIPTION-->
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Description</label
                  >
                  <v-textarea
                    v-model="description"
                    placeholder="Description"
                    auto-grow
                    rows="4"
                    dense
                    solo
                  />
                </v-col>
                <!--END DESCRIPTION-->
              </v-row>

              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    :disabled="!checkValid"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import { Validation } from "@/utils/validation";
import {
  checkFirewall,
  getFirewall,
  getFirewallCheckCalls,
  getFirewallVendors,
  storeFirewall,
  updateFirewall,
} from "@/api/settings/firewall";
import { getAuditorTypes } from "@/api/auditor/other";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,

      valid: true,
      validCheck: true,

      saving: false,

      vendor: "",
      vendorRules: [(v) => !!v || "Vendor is required"],
      auditorType: [],
      ip: "",
      ipRules: {
        required: (v) => !!v || "IP address is required",
      },
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      description: "",
      checkCall: 0,
      checkCallRules: [(v) => !!v || "Check call is required"],
      rulesetStatus: 1,
      rulesetStatusData: [
        { id: 1, name: 'Active' },
        { id: 2, name: 'Inactive' }
      ],
      rulesetStatusRules: [(v) => !!v || "Ruleset Status is required"],
      apiSecret: "",
      apiSecretRules: [(v) => !!v || "API secret is required"],
      vdom: "",
      vdomRules: [(v) => !!v || "VDOM is required"],
      login: "",
      loginRules: [(v) => !!v || "Login is required"],
      password: "",
      passwordRules: [(v) => !!v || "Password is required"],
      firewallCheck: null,

      vendors: [],
      auditorTypes: [],
      checkCalls: [],
      maskedTags: [],
      maskedTagsList: ["private-key", "phash", "key", "password", "secret"],
      skipTags: [],
      skipTagsList: ["test"],
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.valid = true;
          this.validCheck = true;
          this.checkValid = null;
          this.vendor = "";
          this.auditorType = [1,2,3];
          this.ip = "";
          this.name = "";
          this.description = "";
          this.checkCall = 0;
          this.apiSecret = "";
          this.rulesetStatus = 1;
          this.vdom = "";
          this.login = "";
          this.password = "";
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  mounted() {
    this.loadVendors();
    this.loadAuditorTypes();
    // if (this.id === 0) {
    //   this.loading = false;
    // } else {
    //   this.loadById();
    // }
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "firewall");
    },
    loadById() {
      getFirewall(this.id)
        .then((response) => {
          const firewall = response.data;
          this.vendor = firewall.vendorattribute_id;
          this.auditorType = firewall.related_auditor_types;
          this.loadCheckCalls();
          this.ip = firewall.ip_address;
          this.vdom = firewall.vdom;
          this.rulesetStatus = firewall.ruleset_status;
          // this.apiSecret = firewall.api_secret;
          this.name = firewall.name;
          this.login = firewall.login ? firewall.login : "";
          this.password = firewall.password;
          this.description = firewall.description;
          this.maskedTags = firewall.masked_tags;
          this.skipTags = firewall.skip_tags;
          this.checkValid = true;
          this.loading = false;
          this.updateMaskedTagList(this.maskedTags);
          this.updateSkipTagList(this.skipTags);
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    loadVendors() {
      getFirewallVendors()
        .then((response) => {
          this.vendors = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadAuditorTypes() {
      getAuditorTypes()
        .then((response) => {
          this.auditorTypes = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadCheckCalls() {
      getFirewallCheckCalls(this.vendor)
        .then((response) => {
          this.checkCall = 0;
          this.checkCalls = [];
          const self = this;
          response.data.forEach(function (checkCall) {
            self.checkCalls.push({
              id: checkCall.id,
              name:
                self.vendor === 1
                  ? checkCall.name
                  : checkCall.name + " (v." + checkCall.version + ")",
            });
          });

          this.checkCall = this.checkCalls[0].id;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    validateCheck() {
      this.$refs.formCheck.validate();
      if (this.$refs.formCheck.validate()) {
        this.check();
      }
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    ipValidate(val) {
      return Validation.ipWithPort(val);
    },
    check() {
      this.checkLoading = true;
      const data = {
        vendor_id: this.vendor,
        auditor_type: this.auditorType,
        ip_address: this.ip,
        checkcall: this.checkCall,
        api_secret: this.apiSecret,
        login: this.login,
        password: this.password,
        vdom: this.vdom,
      };
      checkFirewall(data)
        .then((response) => {
          this.checkLoading = false;
          if (response.state) {
            this.checkValid = true;
            this.firewallCheck = response;
            this.$toast.open({
              message: "Firewall check was succesfull!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
          } else {
            Errors.show(response);
          }
        })
        .catch((e) => {
          Errors.show(e);
          this.checkLoading = false;
        });
    },
    store() {
      this.saving = true;
      const data = {
        name: this.name,
        description: this.description,
        vendor_id: this.vendor,
        auditor_type: this.auditorType,
        ip_address: this.ip,
        checkcall: this.checkCall,
        api_secret: this.apiSecret,
        vdom: this.vdom,
        login: this.login,
        password: this.password,
        hostname: this.firewallCheck.hostname,
        sw_version: this.firewallCheck.sw_version,
        sid: this.firewallCheck.sid,
        model: this.firewallCheck.model,
        maskedTags: this.maskedTags,
      };

      storeFirewall(data)
        .then(() => {
          this.$toast.open({
            message: "Firewall was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    updateMaskedTagList(inputTags) {
      this.maskedTagsList = Array.from(
        new Set([...this.maskedTagsList, ...inputTags])
      );
    },
    updateSkipTagList(inputTags) {
      this.skipTagsList = Array.from(
        new Set([...this.skipTagsList, ...inputTags])
      );
    },
    update() {
      this.saving = true;
      let data = {
        id: this.id,
        name: this.name,
        description: this.description,
        vendor_id: this.vendor,
        auditor_type: this.auditorType,
        ip_address: this.ip,
        vdom: this.vdom,
        maskedTags: this.maskedTags,
        skipTags: this.skipTags,
      };
      if (this.firewallCheck) {
        data = {
          id: this.id,
          name: this.name,
          description: this.description,
          vendor_id: this.vendor,
          auditor_type: this.auditorType,
          ip_address: this.ip,
          checkcall: this.checkCall,
          ruleset_status: this.rulesetStatus === 1,
          api_secret: this.apiSecret,
          vdom: this.vdom,
          login: this.login,
          password: this.password,
          hostname: this.firewallCheck.hostname,
          sw_version: this.firewallCheck.sw_version,
          model: this.firewallCheck.model,
          maskedTags: this.maskedTags,
          skipTags: this.skipTags,
        };
      }
      updateFirewall(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Firewall was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
