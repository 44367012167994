<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :only-full="true"
      @resize="resize($event)"
      @close="close"
    />

    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold d-inline-block mr-3">
              Ruleset Alert detail
            </h3>
          </v-col>
        </v-row>

        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pb-2 pt-3">
            <v-row class="mx-0 mt-3">
              <!--AUDITOR-->
              <v-col cols="12" md="4" class="py-0 pr-3">
                <label class="font-roboto-condensed-regular font-size-15"
                  >Auditor</label
                >
                <v-autocomplete
                  v-model="auditor"
                  :items="auditors"
                  item-text="name"
                  item-value="id"
                  label="Auditor"
                  placeholder="Auditor"
                  dense
                  solo
                  @change="changeAuditor(auditor)"
                />
              </v-col>
              <!--END AUDITOR-->

              <!--FIREWALL-->
              <v-col cols="12" md="4" class="py-0 pl-0">
                <label class="font-roboto-condensed-regular font-size-15"
                  >Firewall</label
                >
                <v-autocomplete
                  v-model="firewall"
                  :items="firewalls"
                  item-text="name"
                  item-value="id"
                  label="Firewall"
                  placeholder="Firewall"
                  dense
                  solo
                  @change="loadData(1, resultSize)"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      small
                      color="primary"
                      class="elevation-2 synapsa-chip"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-chip
                      small
                      color="primary"
                      class="elevation-2 synapsa-chip"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <!--END FIREWALL-->

              <v-col
                cols="12"
                md="4"
                class="pb-md-0 pt-md-5 pt-0 pl-0 mt-md-1 text-md-right"
                :class="processing ? 'mt-2' : ''"
              >
                <v-icon v-if="processing" color="primary" class="va-middle mr-2"
                  >fas fa-spinner fa-spin</v-icon
                >
                <fix-menu
                  :selected="selected"
                  :processing="processing"
                  @proceed="fixAlerts($event)"
                />
                <confirm-menu
                  :selected="selected"
                  :processing="processing"
                  @proceed="confirmAlerts($event)"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <alert-table
            v-if="size !== 1 && !loading"
            :headers="headers"
            :data="data"
            :vendor-id="vendor"
            :loading="tableLoading"
            :table-page="page"
            :table-page-total-count="pageTotalCount"
            :table-size="resultSize"
            @change="loadData($event.page, $event.size)"
            @select="selected = $event"
          />
        </transition>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { Errors } from "@/utils/error";
import {
  confirmRulesetAlerts,
  fixRulesetAlerts,
  getActiveFortiRulesetAlerts,
  getActivePaloRulesetAlerts,
} from "@/api/monitor/auditor";
import { getAuditorsList } from "@/api/auditor/ruleset";
import AlertTable from "./ruleset/table";
import ConfirmMenu from "./ruleset/confirm";
import FixMenu from "./ruleset/fix";
import { MonitorParser } from "@/layout/FormSidebar/monitor/parser";

export default {
  components: {
    Topbar,
    AlertTable,
    ConfirmMenu,
    FixMenu,
  },
  props: {
    id: {
      type: Object,
      default: function () {
        return null;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 3;
      },
    },
  },
  data() {
    return {
      loading: true,
      tableLoading: true,
      processing: true,
      vendor: null,

      headers: [],
      data: [],
      selected: [],

      page: 1,
      pageTotalCount: 1,
      resultSize: 10,
      sizeTotalCount: 0,

      vendorRules: [(v) => !!v || "Vendor is required"],
      vendors: [],
      auditor: null,
      auditorRules: [(v) => !!v || "Firewall is required"],
      auditors: [],
      firewall: null,
      firewallRules: [(v) => !!v || "Firewall is required"],
      firewalls: [],

      severityColors: ["neutral-3", "good-3", "medium-3", "bad-3"],
      severityNames: ["Low", "Medium", "High", "Critical"],
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.tableLoading = true;
          this.vendor = this.id.auditor.vendorattribute_id;
          this.headers = MonitorParser.auditorAlertHeadersByVendor(this.vendor);
          this.auditor = this.id.auditor.id;
          this.firewall = this.id.firewall.id_fw;
          this.loadData(1, this.resultSize);
          this.loadAuditors();
        }
      },
    },
  },
  // created() {
  //   this.vendor = this.id.auditor.vendorattribute_id;
  //   this.headers = MonitorParser.auditorAlertHeadersByVendor(this.vendor);
  //   this.auditor = this.id.auditor.id;
  //   this.firewall = this.id.firewall.id_fw;
  //   this.loadData(1, this.resultSize);
  //   this.loadAuditors();
  // },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "auditorAlert");
    },
    changeAuditor() {
      this.firewall = null;
      this.firewalls = [];
      this.auditors.map((auditor) => {
        if (auditor.id === this.auditor) {
          this.firewall = auditor.fw[0] ? auditor.fw[0].id : null;
          this.firewalls = auditor.fw ? auditor.fw : [];
        }
      });
      this.loadData(1, this.resultSize);
    },
    loadData(page, size) {
      this.tableLoading = true;
      this.page = page;
      this.resultSize = size;
      this.resize("bigger");
      if (this.firewall) {
        if ([1, 3].includes(this.vendor)) {
          getActivePaloRulesetAlerts(
            this.auditor,
            this.firewall,
            this.page,
            this.resultSize
          )
            .then((response) => {
              this.selected = [];
              this.data = response.data;
              this.pageTotalCount = response.last_page;
              this.page = response.current_page;
              this.resultSize = parseInt(response.per_page);
              this.sizeTotalCount = response.total;
              this.tableLoading = false;
              this.processing = false;
            })
            .catch((e) => {
              Errors.show(e);
              this.tableLoading = false;
              this.processing = false;
            });
        } else if (this.vendor === 2) {
          getActiveFortiRulesetAlerts(
            this.auditor,
            this.firewall,
            this.page,
            this.resultSize
          )
            .then((response) => {
              this.data = response.data;
              this.pageTotalCount = response.last_page;
              this.page = response.current_page;
              this.resultSize = parseInt(response.per_page);
              this.sizeTotalCount = response.total;
              this.tableLoading = false;
              this.processing = false;
            })
            .catch((e) => {
              Errors.show(e);
              this.tableLoading = false;
              this.processing = false;
            });
        } else {
          this.data = [];
          this.tableLoading = false;
        }
      } else {
        this.data = [];
        this.tableLoading = false;
      }
    },
    loadAuditors() {
      getAuditorsList()
        .then((response) => {
          this.auditors = response;
          response.map((auditor) => {
            if (auditor.id === this.auditor) {
              this.firewall = auditor.fw[0] ? auditor.fw[0].id : null;
              this.firewalls = auditor.fw ? auditor.fw : [];
            }
          });
          this.loading = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    fixAlerts(eventData) {
      this.processing = true;
      const names = this.selected
        .map((alert) => alert.firewall_policy)
        .filter((value, index, self) => value && self.indexOf(value) === index);
      const ids = this.selected
        .map((alert) => alert.firewall_policy_id)
        .filter((value, index, self) => value && self.indexOf(value) === index);
      const data = {
        auditor_id: this.auditor,
        firewall_id: this.firewall,
        policies_name: JSON.stringify(names),
        policies_id: JSON.stringify(ids),
        comment: eventData.comment,
      };
      fixRulesetAlerts(data)
        .then(() => {
          this.$toast.open({
            message: "Alerts were successfully fixed!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.loadData(1, this.resultSize);
          this.change();
        })
        .catch((e) => {
          Errors.show(e);
          this.processing = false;
          this.tableLoading = false;
        });
    },
    confirmAlerts(eventData) {
      this.processing = true;
      const ids = this.selected.map((alert) => alert.id);
      const data = {
        auditor_id: this.auditor,
        firewall_id: this.firewall,
        comment: eventData.comment,
        alerts_id: JSON.stringify(ids),
      };
      confirmRulesetAlerts(data)
        .then(() => {
          this.$toast.open({
            message: "Alerts were successfully confirmed!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.loadData(1, this.resultSize);
          this.change();
        })
        .catch((e) => {
          Errors.show(e);
          this.tableLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-block {
  .v-icon {
    vertical-align: inherit;
  }
  .headline-fw {
    vertical-align: text-top;
  }
}
</style>
