var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-overlay',{staticClass:"synapsa-overlay",attrs:{"value":_vm.activeLicense && _vm.initialSetup,"z-index":100000,"dark":_vm.$vuetify.theme.dark}},[_c('initial-setup',{attrs:{"location":_vm.location}})],1),_c('v-system-bar',{attrs:{"app":"","height":"50","color":"secondary"}},[_c('v-img',{attrs:{"contain":"","lazy-src":_vm.$vuetify.theme.dark
          ? _vm.location + _vm.defaultImages.synapsaLogoLight
          : _vm.location + _vm.defaultImages.synapsaLogo,"max-height":"45","max-width":"180","src":_vm.$vuetify.theme.dark
          ? _vm.location + _vm.defaultImages.synapsaLogoLight
          : _vm.location + _vm.defaultImages.synapsaLogo}}),_c('v-spacer'),_c('v-btn',{staticClass:"background-switch-btn",attrs:{"icon":""},on:{"click":_vm.updateBackground}},[_c('v-avatar',{staticClass:"br-0",class:_vm.appBackground ? 'withBg' : '',attrs:{"size":"20"}},[_c('img',{attrs:{"src":_vm.appBackground
              ? _vm.$vuetify.theme.dark
                ? _vm.location + '/images/icons/white/background-img-on.svg'
                : _vm.location + '/images/icons/dark/background-img-on.svg'
              : _vm.$vuetify.theme.dark
              ? _vm.location + '/images/icons/white/background-img-off.svg'
              : _vm.location + '/images/icons/dark/background-img-off.svg',"alt":"Add"}})])],1),_c('v-btn',{staticClass:"darkmode-switch-btn",attrs:{"icon":""},on:{"click":_vm.updateDarkMode}},[_c('v-avatar',{class:_vm.$vuetify.theme.dark ? 'darkmode' : '',attrs:{"size":"27"}},[_c('img',{attrs:{"src":_vm.$vuetify.theme.dark
              ? _vm.location + '/images/icons/green/mode.svg'
              : _vm.location + '/images/icons/dark/mode.svg',"alt":"Add"}})])],1),_c('add-form-btn',{attrs:{"location":_vm.location}}),_c('user-menu',{attrs:{"location":_vm.location}}),_c('v-btn',{staticClass:"logout-btn",attrs:{"icon":""},on:{"click":_vm.logout}},[_c('v-avatar',{attrs:{"size":"20"}},[_c('img',{attrs:{"src":_vm.location + '/images/icons/red/logout.svg',"alt":"Add"}})])],1)],1),_c('sidebar',{attrs:{"location":_vm.location},on:{"mini":function($event){_vm.mini = $event}}}),_c('form-sidebar',{attrs:{"sidebar-mini":_vm.mini}}),_c('v-main',{style:(_vm.getAppBackground())},[_c('app-bar',{attrs:{"location":_vm.location}}),_c('router-view',{staticClass:"router-view-block"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }