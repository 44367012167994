import Layout from "@/layout";

const settingRoutes = {
  path: "/settings",
  name: "settings",
  component: Layout,
  meta: {
    requiresAuth: true,
    icon: "settings.svg",
    permission: ["view menu settings"],
  },
  redirect: "/settings/show/miners",
  children: [
    {
      path: "show",
      component: () => import("@/views/settings/index"),
      name: "show",
      meta: { active: ["settings", "miners"] },
      hidden: true,
      children: [
        {
          path: "miners",
          component: () => import("@/views/settings/miners"),
          name: "miners",
          meta: {
            active: ["settings", "miners"],
            permission: ["view miner"],
          },
          hidden: true,
        },
        {
          path: "firewalls",
          component: () => import("@/views/settings/firewalls"),
          name: "firewalls",
          meta: {
            active: ["settings", "firewall"],
            permission: ["view firewall"],
          },
          hidden: true,
        },
        {
          path: "central_management",
          component: () => import("@/views/settings/centralManagement"),
          name: "centralManagement",
          meta: {
            active: ["settings", "centralManagement"],
            permission: ["view firewall"],
          },
          hidden: true,
        },
        {
          path: "firewall_groups",
          component: () => import("@/views/settings/firewall_groups"),
          name: "firewallGroups",
          meta: {
            active: ["settings", "firewall_groups"],
            permission: ["view firewallgroup"],
          },
          hidden: true,
        },
        {
          path: "parser",
          component: () => import("@/views/settings/parser"),
          name: "parser",
          meta: {
            active: ["settings", "parser"],
            permission: ["view parser"],
          },
          hidden: true,
        },
        {
          path: "api",
          component: () => import("@/views/settings/api"),
          name: "api",
          meta: {
            title: "API",
            active: ["settings", "api"],
            permission: ["view api"],
          },
          redirect: "/settings/show/api/palo_alto",
          hidden: true,
          children: [
            {
              path: "palo_alto",
              component: () => import("@/views/settings/api/paloAlto"),
              name: "apiPaloAlto",
              meta: {
                id: "apiPaloAlto",
                permission: ["view apipaloalto"],
                active: ["settings", "api", "apiPaloAlto"],
              },
              hidden: true,
            },
            {
              path: "fortinet",
              component: () => import("@/views/settings/api/fortinet"),
              name: "apiFortinet",
              meta: {
                id: "apiFortinet",
                permission: ["view apifortinet"],
                active: ["settings", "api", "apiFortinet"],
              },
              hidden: true,
            },
            {
              path: "checkpoint",
              component: () => import("@/views/settings/api/checkpoint"),
              name: "apiCheckpoint",
              meta: {
                id: "apiCheckpoint",
                permission: ["view apicheckpoint"],
                active: ["settings", "api", "apiCheckpoint"],
              },
              hidden: true,
            },
            {
              path: "sophos",
              component: () => import("@/views/settings/api/sophos"),
              name: "apiSophos",
              meta: {
                id: "apiSophos",
                permission: ["view apisophos"],
                active: ["settings", "api", "apiSophos"],
              },
              hidden: true,
            },
            {
              path: "custom",
              component: () => import("@/views/settings/api/custom"),
              name: "apiCustom",
              meta: {
                id: "apiCustom",
                permission: ["view apicustom"],
                active: ["settings", "api", "apiCustom"],
              },
              hidden: true,
            },
          ],
        },
        {
          path: "listener",
          component: () => import("@/views/settings/listener"),
          name: "listener",
          meta: {
            title: "Listener",
            active: ["settings", "listener"],
            permission: ["view listener"],
          },
          hidden: true,
        },
        {
          path: "integration",
          component: () => import("@/views/settings/integration"),
          name: "integration",
          meta: {
            title: "Integration",
            active: ["settings", "integration"],
            permission: ["view integration"],
          },
          hidden: true,
        },
      ],
    },
  ],
};

export default settingRoutes;
