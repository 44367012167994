export default {
  /**
   * @type {String}
   */
  title: "Synapsa",

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {Object}
   * @description Url to default images locations
   */
  defaultImages: {
    background: "/images/background.png",
    backgroundLight: "/images/background_light.png",
    // sidebar: "/images/syn_background.webp",
    sidebar: "/images/synapsa_sidebar_logo.svg",
    synapsaLogo: "/images/synapsa_logo.webp",
    synapsaLogoSm: "/images/synapsa_logo_sm.webp",
    synapsaLogoLight: "/images/synapsa_logo_light.webp",
    synapsaLogoFull: "/images/synapsa_logo_full.webp",
    synapsaLogoFullLight: "/images/synapsa_logo_full_light.webp",
  },

  /**
   * @type {Boolean}
   * @description Whether show background image or not
   */
  appBackground: true,

  /**
   * @type {Number}
   * @description Screen width
   */
  screenWidth: 0,

  /**
   * @type {Object}
   * @description Previous route
   */
  previousUrl: { name: null },

  /**
   * @type {Object}
   * @description Base url of api
   */
  apiUrl: "",

  /**
   * @type {Number}
   * @description Id of element used in aside form
   */
  idAddForm: 0,

  /**
   * @type {String}
   * @description Render the form according to the keyword
   */
  keywordAddForm: "",

  /**
   * @type {String}
   * @description Trigger action according to the keyword
   */
  changeInAddForm: "",

  /**
   * @type {Boolean}
   * @description State of licence whether is active or not
   */
  activeLicense: true,

  /**
   * @type {Boolean}
   * @description State whether is application url in db
   */
  initialSetup: false,

  /**
   * @type {Boolean}
   * @description Object of default settings of tables
   */
  defaultTables: null,
};
