import request from "@/utils/request";

export function storeFirewall(data) {
  return request({
    url: "api/settings/firewall",
    method: "post",
    data: data,
  });
}

export function updateFirewall(data, id) {
  return request({
    url: "api/settings/firewall/" + id,
    method: "put",
    data: data,
  });
}

export function getFirewall(id) {
  return request({
    url: "api/settings/firewall/" + id,
    method: "get",
  });
}

export function getFirewalls(page, size, order, by) {
  const orderBy = order && by ? "&order=" + order + "&by=" + by : "";
  return request({
    url: "api/settings/firewall?page=" + page + "&size=" + size + orderBy,
    method: "get",
  });
}

export function getFirewallsAdditionalData(data) {
  return request({
    url: "api/settings/firewall_data",
    method: "post",
    data: data,
  });
}

export function getFirewallList() {
  return request({
    url: "api/settings/firewalllist",
    method: "get",
  });
}

export function getFirewallListByVendor(id) {
  return request({
    url: "api/settings/firewall/listbyvendor/" + id,
    method: "get",
  });
}

export function getFirewallCheckCalls(id) {
  return request({
    url: "api/settings/getFirewallCheckCall/" + id,
    method: "get",
  });
}

export function getFirewallVendors() {
  return request({
    url: "api/settings/vendorlist",
    method: "get",
  });
}

export function checkFirewall(data) {
  return request({
    url: "api/settings/checkFirewall",
    method: "post",
    data: data,
  });
}

export function deleteFirewall(id) {
  return request({
    url: "api/settings/firewall/" + id,
    method: "delete",
  });
}
