<template>
  <v-card class="most-reported-ips-widget-block">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <widget-toolbar
        v-if="!loading && !failed"
        :title="
          type === 'dst'
            ? $t('widgets.mostReportedDst.headline')
            : $t('widgets.mostReportedSrc.headline')
        "
        :data-time="dataLoadedTime"
        :mount-time="time"
        has-timeline
        @timeline="changeTime($event)"
        @remove="remove"
      />
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-card-text
        v-if="!loading && !failed"
        class="with-bg py-0 px-0 content-block"
      >
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr v-for="(record, key) in data" :key="key">
                <td class="py-0 font-roboto-condensed-regular">
                  {{ record.ip }}
                </td>
                <td class="py-0 text-right font-roboto-condensed-bold">
                  {{ record.count }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </transition>
    <v-overlay :value="loading">
      <v-progress-circular absolute indeterminate size="40" color="good-3" />
    </v-overlay>
    <v-overlay :value="failed">
      <v-card-text>
        {{
          type === "dst"
            ? $t("widgets.mostReportedDst.headline")
            : $t("widgets.mostReportedSrc.headline")
        }}
        - loading data from api failed.
      </v-card-text>
      <v-card-actions>
        <v-btn small color="bad-2" class="mx-auto" @click="loadData">
          Reload
        </v-btn>
      </v-card-actions>
    </v-overlay>
  </v-card>
</template>

<script>
import { widgetMostReportedDst, widgetMostReportedSrc } from "@/api/dashboard";
import WidgetToolbar from "@/components/Widgets/WidgetToolbar";

export default {
  components: {
    WidgetToolbar,
  },
  props: {
    i: {
      type: String,
      default: function () {
        return "";
      },
    },
    type: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      updatedTime: "all",
      time: "all",
      data: [],
      dataLoadedTime: "",

      loading: true,
      failed: false,
    };
  },
  computed: {
    screenWidth: {
      get() {
        return this.$store.state.settings.screenWidth;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "screenWidth",
          value: val,
        });
      },
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    remove() {
      this.$emit("remove", this.i);
    },
    changeTime(time) {
      this.time = time;
      this.loadData();
    },
    loadData() {
      this.failed = false;
      this.loading = true;

      const count = 10;

      if (this.updatedTime !== this.time) {
        this.updatedTime = this.time;
        const data = {
          period: this.time,
        };
        let widgetApi;

        if (this.type === "dst") {
          widgetApi = widgetMostReportedDst;
        } else if (this.type === "src") {
          widgetApi = widgetMostReportedSrc;
        }

        widgetApi(data)
          .then((response) => {
            const formattedData = [];
            for (let i = 0; i < count; i++) {
              if (response.data[i]) {
                formattedData.push({
                  ip: response.data[i].ip,
                  count: response.data[i].count,
                });
              } else {
                formattedData.push({
                  ip: "",
                  count: "",
                });
              }
            }
            this.updateData(formattedData);
            this.loading = false;

            localStorage.setItem(
              "widgetMostReportedSourcesData",
              JSON.stringify(formattedData)
            );
            localStorage.setItem(
              "widgetMostReportedSourcesTimestamp",
              Date.now()
            );
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }

      const storedData = JSON.parse(
        localStorage.getItem("widgetMostReportedSourcesData")
      );
      const savedTime = JSON.parse(
        localStorage.getItem("widgetMostReportedSourcesTimestamp")
      );
      if (savedTime && Date.now() - parseInt(savedTime, 10) < 300000) {
        this.dataLoadedTime = "";
        const transformedTimeStamp = new Date(savedTime);
        const hours = transformedTimeStamp.getHours();
        const minutes = transformedTimeStamp.getMinutes();
        this.dataLoadedTime = `${hours}:${minutes}`;
      }
      if (
        storedData &&
        savedTime &&
        Date.now() - parseInt(savedTime, 10) < 300000
      ) {
        this.updateData(storedData);
        this.loading = false;
      } else {
        const data = {
          period: this.time,
        };
        let widgetApi;

        if (this.type === "dst") {
          widgetApi = widgetMostReportedDst;
        } else if (this.type === "src") {
          widgetApi = widgetMostReportedSrc;
        }

        widgetApi(data)
          .then((response) => {
            const formattedData = [];
            for (let i = 0; i < count; i++) {
              if (response.data[i]) {
                formattedData.push({
                  ip: response.data[i].ip,
                  count: response.data[i].count,
                });
              } else {
                formattedData.push({
                  ip: "",
                  count: "",
                });
              }
            }
            this.updateData(formattedData);
            this.loading = false;

            localStorage.setItem(
              "widgetMostReportedSourcesData",
              JSON.stringify(formattedData)
            );
            localStorage.setItem(
              "widgetMostReportedSourcesTimestamp",
              Date.now()
            );
            const savedTime = JSON.parse(
              localStorage.getItem("widgetSystemLogsTimestamp")
            );

            this.dataLoadedTime = "";
            const transformedTimeStamp = new Date(savedTime);
            const hours = transformedTimeStamp.getHours();
            const minutes = transformedTimeStamp.getMinutes();
            this.dataLoadedTime = `${hours}:${minutes}`;
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }
    },
    updateData(data) {
      this.data = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-icon {
  width: 65px;
  height: 50px;
}
.v-card__text {
  border-radius: 0 0 15px 15px !important;
  tbody tr:nth-of-type(odd) {
    background-color: #69be281a;
  }
  td {
    height: 32px !important;
  }
}
.v-card.theme--light {
  .v-card__title {
    border-bottom: 2px solid #3d3d3f;
  }
  .v-card__text {
    tbody tr:nth-of-type(odd),
    tbody tr:nth-of-type(odd):hover {
      background-color: transparent !important;
    }
    tbody tr:nth-of-type(even),
    tbody tr:nth-of-type(even):hover {
      background-color: #fff !important;
    }
    tbody td {
      border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
    }
  }
}
.v-card.theme--dark {
  .v-card__title {
    border-bottom: 2px solid #fff;
  }
  .v-card__text {
    tbody tr:nth-of-type(odd),
    tbody tr:nth-of-type(odd):hover {
      background-color: transparent !important;
    }
    tbody tr:nth-of-type(even),
    tbody tr:nth-of-type(even):hover {
      background-color: #4b4c4d !important;
    }
    tbody td {
      border-bottom: thin solid hsla(0, 0%, 100%, 0.12);
    }
  }
}
.theme--dark.v-data-table {
  background-color: transparent;
  color: #ffffffb3 !important;
}
.content-block {
  min-height: 337px;
}
.v-data-table {
  padding: 0 20px;
}
</style>
