import Layout from "@/layout";

const auditorRoutes = {
  path: "/auditor",
  name: "auditor",
  component: Layout,
  meta: {
    requiresAuth: true,
    icon: "auditor.svg",
    permission: ["view menu auditor"],
  },
  redirect: "/auditor/show/ruleset",
  children: [
    {
      path: "show",
      component: () => import("@/views/auditor/index"),
      name: "show",
      meta: { active: ["auditor", "ruleset", "cve", "integrity"] },
      hidden: true,
      children: [
        {
          path: "ruleset",
          component: () => import("@/views/auditor/ruleset"),
          name: "ruleset",
          meta: {
            active: ["auditor", "ruleset"],
            permission: ["view auditorruleset"],
          },
          hidden: true,
        },
        {
          path: "cve",
          component: () => import("@/views/auditor/cve"),
          name: "cve",
          meta: {
            active: ["auditor", "cve"],
            permission: ["view auditorcve"],
          },
          hidden: true,
        },
        {
          path: "integrity",
          component: () => import("@/views/auditor/integrity"),
          name: "integrity",
          meta: {
            active: ["auditor", "integrity"],
            permission: ["view auditorintegrity"],
          },
          hidden: true,
        },
        {
          path: "groups",
          component: () => import("@/views/auditor/auditor_groups"),
          name: "groups",
          meta: {
            active: ["auditor", "groups"],
            permission: ["view auditorintegrity"],
          },
          hidden: true,
        },
        {
          path: "templates",
          component: () => import("@/views/auditor/templates"),
          name: "templates",
          meta: {
            active: ["auditor", "templates"],
            permission: ["view auditortemplates"],
          },
          hidden: true,
        },
        {
          path: "templates/:id/rules",
          component: () => import("@/views/auditor/templates/rules"),
          name: "auditorTemplateRules",
          meta: {
            active: ["auditor", "groups"],
            permission: ["view auditortemplates"],
          },
          hidden: true,
        },
      ],
    },
  ],
};

export default auditorRoutes;
