<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :keywordsResize2="keywordsResize2"
      @resize="resize($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{
                id === 0
                  ? "Add new Central Management Firewall"
                  : "Edit Central Management Firewall"
              }}
            </h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="formCheck" v-model="validCheck" lazy-validation>
              <!--VENDOR-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Vendor</label
                  >
                  <v-select
                    v-model="vendor"
                    disabled
                    :items="vendors"
                    :rules="vendorRules"
                    item-text="name"
                    item-value="id"
                    label="Vendor"
                    placeholder="Vendor"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END VENDOR-->

              <!--IP-->
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                {{ vendor }}
                <v-row v-if="vendor" class="mx-0 mt-3">
                  <v-col cols="12" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >IP address</label
                    >
                    <v-text-field
                      v-model="ip"
                      :disabled="checkValid"
                      :rules="[ipRules.required, ipValidate]"
                      label="IP address"
                      placeholder="IP address"
                      dense
                      solo
                    />
                  </v-col>
                </v-row>
              </transition>
              <!--END IP-->

              <!--API SECRET-->
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-row v-if="[1, 2].includes(vendor)" class="mx-0 mt-3">
                  <v-col cols="12" class="py-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >API Secret</label
                    >
                    <v-text-field
                      v-model="apiSecret"
                      :disabled="checkValid"
                      :rules="apiSecretRules"
                      label="API Secret"
                      placeholder="API Secret"
                      type="password"
                      dense
                      solo
                    />
                  </v-col>
                </v-row>
              </transition>
              <!--END API SECRET-->

              <!--FIREWALL CHECK-->
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-row v-if="vendor" class="mx-0 mt-3">
                  <v-col v-if="!checkValid" cols="12" class="pt-0">
                    <v-btn
                      v-if="!checkLoading"
                      color="primary"
                      class="text-capitalize mb-2"
                      @click="validateCheck"
                    >
                      Check firewall
                    </v-btn>
                    <v-icon v-if="checkLoading" color="primary" class="mb-2">
                      fas fa-spinner fa-spin
                    </v-icon>
                    <span
                      v-if="checkLoading"
                      class="font-roboto-condensed-light font-size-15 ml-2"
                    >
                      Checking...
                    </span>
                    <v-divider />
                  </v-col>
                  <v-col
                    v-if="checkValid && firewallCheck"
                    cols="12"
                    class="pt-0 pb-4 px-4"
                  >
                    <div
                      v-if="[1, 2, 3].includes(vendor)"
                      class="font-size-12 primary--text"
                    >
                      Hostname: {{ firewallCheck.hostname }}
                    </div>
                    <div
                      v-if="[1, 2, 3].includes(vendor)"
                      class="font-size-12 primary--text"
                    >
                      Version: {{ firewallCheck.version }}
                    </div>
                    <div
                      v-if="[1, 2, 3].includes(vendor)"
                      class="font-size-12 primary--text"
                    >
                      Model: {{ firewallCheck.model }}
                    </div>
                    <div
                      v-if="[1, 2].includes(vendor)"
                      class="font-size-12 primary--text"
                    >
                      Serial: {{ firewallCheck.serial }}
                    </div>
                  </v-col>
                  <v-col v-if="checkValid" cols="12" class="pt-0">
                    <v-btn
                      color="bad-2"
                      class="text-capitalize mb-2 text-white"
                      @click="checkValid = false"
                    >
                      Change
                    </v-btn>
                  </v-col>
                </v-row>
              </transition>
              <!--END FIREWALL CHECK-->
            </v-form>

            <v-form ref="form" v-model="valid" lazy-validation>
              <!--NAME-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Name</label
                  >
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    placeholder="Name"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END NAME-->

              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    :disabled="!checkValid"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import { Validation } from "@/utils/validation";
import {
  checkCentralManagement,
  getCentralManagement,
  storeCentralManagement,
  updateCentralManagement,
} from "@/api/settings/centralManagement";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
    keywordsResize2: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,

      valid: true,
      validCheck: true,

      saving: false,

      vendor: 1,
      vendorRules: [(v) => !!v || "Vendor is required"],
      ip: "",
      ipRules: {
        required: (v) => !!v || "IP address is required",
      },
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      apiSecret: "",
      apiSecretRules: [(v) => !!v || "API secret is required"],
      firewallCheck: null,

      vendors: [
        {
          id: 1,
          name: "PaloAlto",
        },
      ],
      checkCall: 0,
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.valid = true;
          this.validCheck = true;
          this.checkValid = null;
          this.vendor = 1;
          this.ip = "";
          this.name = "";
          this.checkCall = 0;
          this.apiSecret = "";
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "firewall");
    },
    loadById() {
      getCentralManagement(this.id)
        .then((response) => {
          const firewall = response.data;
          this.vendor = firewall.vendor_id;
          this.ip = firewall.ip_address;
          this.name = firewall.name;
          this.checkValid = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    validateCheck() {
      this.$refs.formCheck.validate();
      if (this.$refs.formCheck.validate()) {
        this.check();
      }
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    ipValidate(val) {
      return Validation.ipWithPort(val);
    },
    check() {
      this.checkLoading = true;
      const data = {
        vendor_id: this.vendor,
        ip_address: this.ip,
        checkcall: this.checkCall,
        api_secret: this.apiSecret,
      };
      checkCentralManagement(data)
        .then((response) => {
          this.checkLoading = false;
          if (response.state) {
            this.checkValid = true;
            this.firewallCheck = response;
            if (this.name === "") {
              this.name = response.hostname + " " + response.serial;
            }
            this.$toast.open({
              message: "Firewall check was succesfull!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
          } else {
            Errors.show(response);
          }
        })
        .catch((e) => {
          Errors.show(e);
          this.checkLoading = false;
        });
    },
    store() {
      this.saving = true;
      const data = {
        name: this.name,
        vendor_id: this.vendor,
        ip_address: this.ip,
        checkcall: this.checkCall,
        api_secret: this.apiSecret,
        hostname: this.firewallCheck.hostname,
        version: this.firewallCheck.version,
        serial: this.firewallCheck.serial,
        model: this.firewallCheck.model,
      };

      storeCentralManagement(data)
        .then(() => {
          this.$toast.open({
            message: "Firewall was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    update() {
      this.saving = true;
      let data = {
        id: this.id,
        name: this.name,
        vendor_id: this.vendor,
        ip_address: this.ip,
      };
      if (this.firewallCheck) {
        data = {
          id: this.id,
          name: this.name,
          vendor_id: this.vendor,
          ip_address: this.ip,
          checkcall: 1,
          api_secret: this.apiSecret,
          hostname: this.firewallCheck.hostname,
          version: this.firewallCheck.version,
          serial: this.firewallCheck.serial,
          model: this.firewallCheck.model,
        };
      }
      updateCentralManagement(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Firewall was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
