import request from "@/utils/request";

export function getApiCallsByFw(data) {
  return request({
    url: "api/settings/apicalllist",
    method: "post",
    data: data,
  });
}
export function getApiCalls(page, size, url) {
  return request({
    url: "api/settings/api/" + url + "?page=" + page + "&size=" + size,
    method: "get",
  });
}
export function getApiCall(id) {
  return request({
    url: "api/settings/api/" + id,
    method: "get",
  });
}
export function getApiCallVersions(data) {
  return request({
    url: "api/settings/api/name",
    method: "post",
    data: data,
  });
}
export function getFirewallVersions(id) {
  return request({
    url: "api/settings/api/version/" + id,
    method: "get",
  });
}
export function getCallTypes(id) {
  return request({
    url: "api/settings/api/calltype/" + id,
    method: "get",
  });
}
export function getIntegrityCalls(vendorId) {
  return request({
    url: "api/settings/api/integrity/" + vendorId,
    method: "get",
  });
}
export function storeApiCall(data) {
  return request({
    url: "api/settings/api",
    method: "post",
    data: data,
  });
}
export function updateApiCall(data, id) {
  return request({
    url: "api/settings/api/" + id,
    method: "put",
    data: data,
  });
}
export function deleteApiCall(id) {
  return request({
    url: "api/settings/api/" + id,
    method: "delete",
  });
}
