<template>
  <v-card class="overall-auditor-failure-widget-block">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <widget-toolbar
        v-if="!loading && !failed"
        :title="$t('widgets.overallAuditorFailure.headline')"
        :data-time="dataLoadedTime"
        @remove="remove"
      />
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-card-actions v-if="!loading && !failed" class="with-bg px-4 pb-5">
        <chart :score="score" :width="'400px'" />
      </v-card-actions>
    </transition>
    <v-overlay :value="loading">
      <v-progress-circular absolute indeterminate size="40" color="good-3" />
    </v-overlay>
    <v-overlay :value="failed">
      <v-card-text>
        {{ $t("widgets.overallAuditorFailure.headline") }} - loading data from
        api failed.
      </v-card-text>
      <v-card-actions>
        <v-btn small color="bad-2" class="mx-auto" @click="loadData">
          Reload
        </v-btn>
      </v-card-actions>
    </v-overlay>
  </v-card>
</template>

<script>
import chart from "@/components/Widgets/OverallAuditorFailureWidget/chart";
import { widgetOverallAuditorFailure } from "@/api/dashboard";
import WidgetToolbar from "@/components/Widgets/WidgetToolbar";

export default {
  components: {
    chart,
    WidgetToolbar,
  },
  props: {
    i: {
      type: String,
      default: function () {
        return "0";
      },
    },
    w: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    h: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {
      score: 0,
      dataLoadedTime: "",

      loading: true,
      failed: false,
    };
  },
  created() {
    this.loadData();
  },
  mounted() {
    setTimeout(() => {
      this.loadData();
    }, 1);
  },
  methods: {
    loadData() {
      this.failed = false;
      this.loading = true;

      const storedScore = JSON.parse(
        localStorage.getItem("widgetOverallAuditorFailureScore")
      );
      const savedTime = JSON.parse(
        localStorage.getItem("widgetOverallAuditorFailureTimestamp")
      );

      if (savedTime && Date.now() - parseInt(savedTime, 10) < 300000) {
        this.dataLoadedTime = "";
        const transformedTimeStamp = new Date(savedTime);
        const hours = transformedTimeStamp.getHours();
        const minutes = transformedTimeStamp.getMinutes();
        this.dataLoadedTime = `${hours}:${minutes}`;
      }

      if (
        storedScore &&
        savedTime &&
        Date.now() - parseInt(savedTime, 10) < 300000
      ) {
        this.score = storedScore;
        this.loading = false;
      } else {
        widgetOverallAuditorFailure()
          .then((response) => {
            const global = response.data[0];
            const [critical, criticalAll] = global?.critical?.split("/") || [0, 0];
            const [high, highAll] = global?.high?.split("/") || [0, 0];
            const [medium, mediumAll] = global?.medium?.split("/") || [0, 0];
            const [low, lowAll] = global?.low?.split("/") || [0, 0];

            this.score = Math.round(
              ((this.safeParse(critical) +
                  this.safeParse(high) +
                  this.safeParse(medium) +
                  this.safeParse(low)) /
                (this.safeParse(criticalAll) +
                  this.safeParse(highAll) +
                  this.safeParse(mediumAll) +
                  this.safeParse(lowAll))) *
              100
            );

            localStorage.setItem(
              "widgetOverallAuditorFailureScore",
              JSON.stringify(this.score)
            );
            localStorage.setItem(
              "widgetOverallAuditorFailureTimestamp",
              Date.now()
            );

            const savedTime = JSON.parse(
              localStorage.getItem("widgetOverallAuditorFailureTimestamp")
            );
            this.dataLoadedTime = "";
            const transformedTimeStamp = new Date(savedTime);
            const hours = transformedTimeStamp.getHours();
            const minutes = transformedTimeStamp.getMinutes();
            this.dataLoadedTime = `${hours}:${minutes}`;
            this.loading = false;
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }
    },
    safeParse (value) {
      return isNaN(parseInt(value)) ? 0 : parseInt(value);
    },
    remove() {
      this.$emit("remove", this.i);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-icon {
  vertical-align: sub;
  width: 65px;
  height: 50px;
}
.v-card-text {
  background: #24272169;
  border-radius: 0;
}
.v-card__text.with-bg:not(.with-graph) {
  border-radius: 0 0 15px 15px !important;
}
.v-card__actions {
  border-radius: 0 0 15px 15px !important;
}
.dashboard-container {
  .v-card.theme--dark {
    .v-card__title {
      border-bottom: 2px solid #fff;
    }
  }
}
.dashboard-container {
  .v-card.theme--light {
    .v-card__title {
      border-bottom: 2px solid #3d3d3f;
    }
  }
}
</style>
