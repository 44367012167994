<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ "Edit Credentials for " + name }}
            </h3>
          </v-col>
          <v-col cols="12">
            <v-form ref="form" v-model="validCheck" lazy-validation>
              <v-row class="mx-0" v-for="(id, key) in credentialIds" :key="key">
                <v-col class="py-0 px-0">
                  <!--Description-->
                  <v-row class="mx-0 mt-3">
                    <v-col cols="12" class="py-0">
                      <label
                        class="font-roboto-condensed-regular font-size-15"
                        >{{ id[3] }}</label
                      >
                      <v-text-field
                        v-model="keys[key]"
                        :rules="keyRules"
                        :label="id[1] === true ? id[2] : '******'"
                        :placeholder="id[2]"
                        dense
                        solo
                        aria-autocomplete="none"
                      />
                    </v-col>
                  </v-row>
                  <!--END Description-->
                </v-col>
              </v-row>
              <v-row class="mx-0 mt-6">
                <v-col cols="12" class="py-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import {
  getAllIntegrations,
  updateIntegration,
  getIntegration,
} from "@/api/settings/integrations";

import { Errors } from "@/utils/error";
export default {
  name: "index",
  components: {
    Topbar,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  data() {
    return {
      activeTab: "general",
      loading: true,
      checkLoading: false,
      checkValid: null,
      perspectiveValid: null,
      checked: null,
      valid: true,
      validCheck: true,
      saving: false,
      data: null,
      name: "",
      description: "",
      key: "",
      keyRules: [(v) => !!v || "Key is required"],
      credentials: [],
      keys: [],
      credentialIds: [],
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.close();
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadData();
        }
      },
    },
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "integration");
    },
    loadData() {
      this.loading = true;
      this.credentials = [];
      this.keys= [];
      this.credentialIds = [];
      getAllIntegrations()
        .then((response) => {
          this.data = response.data;
          const self = this;
          var name = "";
          var description = "";
          response.data.forEach(function (integration) {
            if (integration.id === self.id) {
              name = integration.name;
              description = integration.detail.Description;
            }
          });
          this.name = name;
          this.description = description;
          getIntegration(this.id)
            .then((response) => {
              const self = this;
              response.credentials.forEach(function (credential) {
                self.credentialIds.push([credential.id, credential.is_empty, credential.parameter ,credential.description]);
                self.keys.push(credential.value);
              });
              this.loading = false;
            })
            .catch((e) => {
              Errors.show(e);
              this.loading = false;
            });
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.$toast.open({
            message: "There is no possibility of creating new integration!",
            type: "error",
            position: "top-right",
            duration: 2000,
          });
        } else {
          this.update();
        }
      }
    },
    update() {
      this.saving = true;
      const self = this;
      this.credentials = [];
      this.credentialIds.forEach(function (credential, index) {
        self.credentials.push([credential[0], self.keys[index]]);
      });

      const data = {
        credentials: this.credentials,
      };
      updateIntegration(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Integration was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.saving = false;
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped></style>
