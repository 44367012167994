<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-container :fluid="true" class="px-0 py-0">
            <v-row class="mx-0" :class="'mt-4'">
              <v-col cols="12" class="py-0 px-0">
                <v-col cols="12" class="pt-0">
                  <v-data-table
                      :headers="headers"
                      :items="data"
                      :expanded.sync="expanded"
                      hide-default-footer
                      item-key="id"
                      show-expand
                      :loading="loading"
                      loading-text="Loading data from API...please wait"
                      class="synapsa-table maintenance-table"
                  >
                    <template v-slot:expanded-item="{ headers, item }">
                      <td
                          :colspan="headers.length"
                          class="expand-block"
                          :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
                      >
                        <v-simple-table>
                          <template v-slot:default>
                            <tbody class="expanded">
                            <tr class="expand-tr">
                              <td class="expand-td">
                                <span v-html="item.result" class="item-result-text">
                                </span>
                              </td>
                            </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </td>
                    </template>
                  </v-data-table>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import { getMaintenanceCommandResult } from "@/api/system/maintenance";

export default {
  props: {
    mountData: {
      type: Array,
      default: function () {
        return null;
      },
    },
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      loadingTask: true,
      description: "",

      headers: [
        { text: "Executed At", value: "ran_at" },
      ],
      expanded: [],
      data: [],

      results: [],
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        console.log(newValue)
        this.loading = true;
        this.loadById();
      },
    },
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "systemmaintenance");
    },
    loadById() {
      getMaintenanceCommandResult(this.id)
        .then((response) => {
          this.data = response.data;

          this.loadingTask = false;
          this.setLoadingData();
        })
        .catch((e) => {
          this.loadingTask = false;
          this.setLoadingData();
          Errors.show(e);
        });
    },
    setLoadingData() {
      if (this.id === 0) {
        if ( !this.loadingCommandList ) {
          this.loading = false;
        }
      } else {
        if ( !this.loadingTask && !this.loadingFrequencyList ) {
          this.loading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.item-result-text {
  /* padding: 10px; */
}
.expand-td {
  padding: 10px !important;
}
.expand-tr {
  td {
    padding: 25px;
  }
}

.expand-tr td {
  td {
    padding: 25px;
  }
}
</style>
