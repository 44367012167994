import request from "@/utils/request";

export function storeLicense(data) {
  return request({
    url: "api/system/license",
    method: "post",
    data: data,
  });
}

export function getLicense() {
  return request({
    url: "api/system/license",
    method: "get",
  });
}
