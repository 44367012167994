<template>
  <v-card-title class="py-1 pr-2 font-ubuntu-bold text-uppercase">
    <div
      class="widget-headline font-size-16"
      :style="'width: calc(100% - ' + calcHeadlineWidth() + 'px);'"
    >
      {{ title }}
    </div>
    <div class="d-inline-block cursor-pointer ml-1">
      <!--MENU SELECT ITEMS-->
      <v-menu
        v-if="hasSelectMenu"
        v-model="menuSelects"
        :close-on-content-click="false"
        :nudge-width="200"
        :nudge-top="5"
        :nudge-left="240"
        offset-x
        content-class="synapsa-widget-timeline-menu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            x-small
            class="mr-1 widget-toolbar-select"
            v-bind="attrs"
            v-on="on"
          >
            <span class="font-roboto-condensed-bold">{{
              selectedItem.name
            }}</span>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item class="pl-1 pr-2">
              <v-list-item-content class="py-0">
                <v-virtual-scroll
                  :items="selectItems"
                  height="100"
                  item-height="25"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item
                      class="px-2 pb-1"
                      :class="{ active: selectedItem.id === item.id }"
                      @click="selectbox(item)"
                    >
                      <v-list-item-content class="py-1 pl-2">
                        <v-list-item-title class="font-size-12"
                          >{{ item.name }}
                          <transition
                            enter-active-class="animate__animated animate__heartBeat"
                            leave-active-class="d-none"
                          >
                            <v-icon
                              v-if="selectedItem.id === item.id"
                              color="primary"
                              x-small
                              class="float-right mr-2"
                              >mdi mdi-check</v-icon
                            >
                          </transition>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <!--END MENU SELECT ITEMS-->

      <!--TIMELINE BUTTON-->
      <v-menu
        v-if="hasTimeline"
        v-model="menuTimes"
        :close-on-content-click="false"
        :nudge-width="200"
        :nudge-top="5"
        :nudge-left="240"
        offset-x
        content-class="synapsa-widget-timeline-menu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            :size="iconSize"
            class="br-0 mr-2 cursor-pointer widget-toolbar-icon"
            v-bind="attrs"
            v-on="on"
          >
            <img
              :src="
                $vuetify.theme.dark
                  ? location + '/images/icons/white/time.svg'
                  : location + '/images/icons/dark/time.svg'
              "
              alt="Add"
            />
          </v-avatar>
        </template>

        <v-card>
          <v-list>
            <v-list-item class="pl-1 pr-2">
              <v-list-item-content class="py-0">
                <v-virtual-scroll :items="items" height="100" item-height="25">
                  <template v-slot:default="{ item }">
                    <v-list-item
                      class="px-2 pb-1"
                      :class="{ active: time === item.id }"
                      @click="timeline(item)"
                    >
                      <v-list-item-content class="py-1 pl-2">
                        <v-list-item-title class="font-size-12"
                          >{{ item.name }}
                          <transition
                            enter-active-class="animate__animated animate__heartBeat"
                            leave-active-class="d-none"
                          >
                            <v-icon
                              v-if="time === item.id"
                              color="primary"
                              x-small
                              class="float-right mr-2"
                              >mdi mdi-check</v-icon
                            >
                          </transition>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <!--END TIMELINE BUTTON-->

      <!--ZOOM BUTTON-->
      <v-avatar
        v-if="hasZoom"
        :size="iconSize"
        class="br-0 mr-2 cursor-pointer widget-toolbar-icon"
        @click="zoom"
      >
        <img
          :src="
            $vuetify.theme.dark
              ? location + '/images/icons/white/zoom.svg'
              : location + '/images/icons/dark/zoom.svg'
          "
          alt="Add"
        />
      </v-avatar>
      <!--END ZOOM BUTTON-->

      <!--CHART BUTTON-->
      <v-avatar
        v-if="hasChart"
        :size="iconSize"
        class="br-0 mr-2 cursor-pointer widget-toolbar-icon"
        @click="chart"
      >
        <img
          :src="
            $vuetify.theme.dark
              ? location + '/images/icons/white/chart.svg'
              : location + '/images/icons/dark/chart.svg'
          "
          alt="Add"
        />
      </v-avatar>
      <!--END CHART BUTTON-->

      <!-- TOGGLE CHART TYPE BUTTON -->
      <v-avatar
        v-if="hasBarChart"
        :size="iconSize"
        class="br-0 mr-2 cursor-pointer widget-toolbar-icon"
        @click="toggleChart"
      >
        <img
          v-if="chartBar"
          :src="
            $vuetify.theme.dark
              ? location + '/images/icons/white/chart_bar.svg'
              : location + '/images/icons/dark/chart_bar.svg'
          "
        />
        <img
          v-else
          :src="
            $vuetify.theme.dark
              ? location + '/images/icons/white/chart_line.svg'
              : location + '/images/icons/dark/chart_line.svg'
          "
        />
      </v-avatar>
      <!-- END TOGGLE CHART TYPE BUTTON -->

      <v-avatar
        :size="iconSize - 3"
        class="br-0 cursor-pointer widget-toolbar-icon close"
      >
        <img
          :src="
            $vuetify.theme.dark
              ? location + '/images/icons/white/close-white.svg'
              : location + '/images/icons/dark/close.svg'
          "
          @click="remove"
          alt="Add"
        />
      </v-avatar>
    </div>
    <div class="time-stamp">
      <span class="time-stamp__text">Data loaded at: {{ dataTime }}</span>
    </div>
  </v-card-title>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: function () {
        return "Widget";
      },
    },
    dataTime: {
      type: String,
      default: function () {
        return "";
      },
    },
    selectItems: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectedItem: {
      type: Object,
      default: function () {
        return undefined;
      },
    },
    hasTimeline: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    mountTime: {
      type: String,
      default: function () {
        return undefined;
      },
    },
    hasZoom: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    hasChart: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    hasBarChart: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    hasSelectMenu: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    iconSize: {
      type: Number,
      default: function () {
        return 20;
      },
    },
  },
  data() {
    return {
      location: "",
      chartBar: true,

      time: "",
      items: [
        { id: "last24hours", name: "Last 24 Hours" },
        { id: "lasttwodays", name: "Last Two Days" },
        { id: "lastweek", name: "Last Week" },
        { id: "lasttwoweeks", name: "Last Two Weeks" },
        { id: "lastmonth", name: "Last Month" },
        { id: "lasttwomonths", name: "Last Two Months" },
        { id: "lastsixmonths", name: "Last Six Months" },
        { id: "lastyear", name: "Last Year" },
        { id: "all", name: "All" },
      ],

      menuTimes: false,
      menuSelects: false,
    };
  },
  created() {
    this.location = window.location.origin;
    this.time = this.mountTime;
  },
  methods: {
    timeline(time) {
      this.time = time.id;
      this.$emit("timeline", this.time);
    },
    selectbox(item) {
      this.$emit("onSelect", item);
    },
    zoom() {
      this.$emit("zoom", true);
    },
    chart() {
      this.$emit("chart", true);
    },
    remove() {
      this.$emit("remove", true);
    },
    calcHeadlineWidth() {
      let width = 22;
      if (this.hasTimeline) {
        width += 30;
      }
      if (this.hasZoom) {
        width += 30;
      }
      if (this.hasChart) {
        width += 30;
      }
      if (this.hasSelectMenu) {
        width += 90;
      }
      if (this.hasBarChart) {
        width += 30;
      }
      return width;
    },
    toggleChart() {
      if (this.chartBar === true) {
        this.chartBar = false;
      } else {
        this.chartBar = true;
      }
      this.$emit("toggle-chart");
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-headline {
  display: flex;
}
.widget-toolbar-select {
  opacity: 0.6;
  vertical-align: text-bottom;
  transition-duration: 0.3s;
  width: 85px;
}
.widget-toolbar-select:hover {
  opacity: 1;
}
.widget-toolbar-icon {
  opacity: 0.6;
  vertical-align: text-bottom;
  transition-duration: 0.3s;
}
.widget-toolbar-icon.close {
  margin-bottom: 1px;
}
.widget-toolbar-icon:hover {
  opacity: 1;
}
.time-stamp {
  display: block;
  line-height: 8px;
  font-size: 8px;
  color: #9fa3a6;
}
</style>
