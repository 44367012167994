<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :keywordsResize2="keywordsResize2"
      @resize="resize($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pb-2 pt-3">
            <h3 class="font-ubuntu-bold">Set DNS</h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--DNS-->
              <v-row class="mx-0" :class="'mt-4'">
                <v-col cols="12" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Primary DNS</label
                  >
                  <v-text-field
                    v-model="primaryDns"
                    :rules="[primaryDnsRules.required, ipValidate]"
                    type="text"
                    label="Primary DNS"
                    placeholder="8.8.8.8"
                    dense
                    solo
                  />
                </v-col>
              </v-row>

              <v-row class="mx-0" :class="'mt-4'">
                <v-col cols="12" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Secondary DNS</label
                  >
                  <v-text-field
                    v-model="secondaryDns"
                    :rules="[ipValidate]"
                    type="text"
                    label="Secondary DNS"
                    placeholder="1.1.1.1"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END DNS-->

              <v-row class="mt-1 mx-0">
                <v-col cols="12" class="py-0 px-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import { Validation } from "@/utils/validation";
import { storeDns, getDns } from "@/api/system/dns";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
    keywordsResize2: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,

      valid: true,
      validCheck: true,

      saving: false,

      primaryDns: "",
      primaryDnsRules: {
        required: (v) => !!v || "Primary DNS is required",
      },
      secondaryDns: "",
      secondaryDnsRules: {},
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "systemDns");
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.store();
      }
    },
    ipValidate(val) {
      return Validation.ipWithPort(val);
    },
    load() {
      getDns()
        .then((response) => {
          const dns = response.data;
          this.primaryDns = dns.dns_primary;
          this.secondaryDns = dns.dns_secondary;
          this.checkValid = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    store() {
      this.saving = true;
      const data = {
        dns_primary: this.primaryDns,
        dns_secondary: this.secondaryDns,
      };
      storeDns(data)
        .then(() => {
          this.$toast.open({
            message: "DNS was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
