import request from "@/utils/request";

export function storeScriptRule(data) {
  return request({
    url: "api/rules_script",
    method: "post",
    data: data,
  });
}

export function updateScriptRule(data, id) {
  return request({
    url: "api/rules_script/" + id,
    method: "put",
    data: data,
  });
}

export function getScriptRule(id) {
  return request({
    url: "api/rules_script/" + id,
    method: "get",
  });
}

export function getScriptRules(page, size) {
  return request({
    url: "api/rules_script?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function deleteScriptRule(id) {
  return request({
    url: 'api/rules_script/' + id,
    method: 'delete',
  });
}
