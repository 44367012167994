export const DataFeedParser = {
  generateTitle: function (data) {
    if (data.type1 === "addressList") {
      // ADDRESS
      if (data.type2 === "custom") {
        // CUSTOM
        if (data.type3 === "src") {
          return "Source links";
        } else {
          return "Destination links";
        }
      } else if (data.type2 === "predefined") {
        // PREDEFINED
        if (data.type3 === "ipv4") {
          return "IPv4 links";
        } else {
          return "IPv6 links";
        }
      }
    } else if (data.type1 === "domainList") {
      if (data.type2 === "custom") {
        return "Domain list";
      } else if (data.type2 === "predefined") {
        return "Domain Predefined list";
      }
    } else if (data.type1 === "urlList") {
      if (data.type2 === "custom") {
        return "Url list";
      } else if (data.type2 === "predefined") {
        return "Url Predefined list";
      }
    }
  },
  generateUrl: function (baseUrl, data, download, type) {
    const base = baseUrl ? baseUrl + "/api/" : ".../api/";
    const routes = DataFeedParser.routes(data.id);

    let url = "";
    if (data.type1 === "addressList") {
      // ADDRESS
      if (data.type2 === "custom") {
        // CUSTOM
        if (data.type3 === "src") {
          if (download) {
            url = routes.address.custom.downloadSrc;
          } else {
            if (type === "json") {
              url = routes.address.custom.showJsonSrc;
            } else {
              url = routes.address.custom.showSrc;
            }
          }
        } else {
          if (download) {
            url = routes.address.custom.downloadDst;
          } else {
            if (type === "json") {
              url = routes.address.custom.showJsonDst;
            } else {
              url = routes.address.custom.showDst;
            }
          }
        }
      } else if (data.type2 === "predefined") {
        // PREDEFINED
        if (data.type3 === "ipv4") {
          if (download) {
            url = routes.address.predefined.downloadIPv4;
          } else {
            if (type === "json") {
              url = routes.address.predefined.showJsonIPv4;
            } else {
              url = routes.address.predefined.showIPv4;
            }
          }
        } else {
          if (download) {
            url = routes.address.predefined.downloadIPv6;
          } else {
            if (type === "json") {
              url = routes.address.predefined.showJsonIPv6;
            } else {
              url = routes.address.predefined.showIPv6;
            }
          }
        }
      }
    } else if (data.type1 === "domainList") {
      if (data.type2 === "custom") {
        if (download) {
          url = routes.domain.custom.download;
        } else {
          url = routes.domain.custom.show;
        }
      } else if (data.type2 === "predefined") {
        if (download) {
          url = routes.domain.predefined.download;
        } else {
          url = routes.domain.predefined.show;
        }
      }
    } else if (data.type1 === "urlList") {
      if (data.type2 === "custom") {
        if (download) {
          url = routes.url.custom.download;
        } else {
          url = routes.url.custom.show;
        }
      } else if (data.type2 === "predefined") {
        if (download) {
          url = routes.url.predefined.download;
        } else {
          url = routes.url.predefined.show;
        }
      }
    }
    return base + url;
  },
  routes: function (id) {
    return {
      address: {
        custom: {
          showSrc: "datafeed/iplists/custom/show_txt/" + id + "/src",
          showDst: "datafeed/iplists/custom/show_txt/" + id + "/dst",
          showJsonSrc: "datafeed/iplists/custom/show_json/" + id + "/src",
          showJsonDst: "datafeed/iplists/custom/show_json/" + id + "/dst",
          downloadSrc: "datafeed/iplists/custom/download_txt/" + id + "/src",
          downloadDst: "datafeed/iplists/custom/download_txt/" + id + "/dst",
        },
        predefined: {
          showIPv4: "datafeed/iplists/predefined/show_txt/" + id + "/v4",
          showJsonIPv4: "datafeed/iplists/predefined/show_json/" + id + "/v4",
          downloadIPv4:
            "datafeed/iplists/predefined/download_txt/" + id + "/v4",
          showIPv6: "datafeed/iplists/predefined/show_txt/" + id + "/v6",
          showJsonIPv6: "datafeed/iplists/predefined/show_json/" + id + "/v6",
          downloadIPv6:
            "datafeed/iplists/predefined/download_txt/" + id + "/v6",
        },
      },
      domain: {
        custom: {
          show: "datafeed/domainlist/custom/show_txt/" + id,
          download: "datafeed/domainlist/custom/download_txt/" + id,
        },
        predefined: {
          show: "datafeed/domainlist/predefined/show_txt/" + id,
          download: "datafeed/domainlist/predefined/download_txt/" + id,
        },
      },
      url: {
        custom: {
          show: "datafeed/urllists/custom/show_txt/" + id,
          download: "datafeed/urllists/custom/download_txt/" + id,
        },
        predefined: {
          show: "datafeed/urllists/predefined/show_txt/" + id,
          download: "datafeed/urllists/predefined/download_txt/" + id,
        },
      },
    };
  },
};
