<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new Connection" : "Edit Connection" }}
            </h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--TITLE-->
              <v-row class="mx-0 mt-3">
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Title</label
                  >
                  <v-text-field
                    v-model="title"
                    :rules="[titleRules.required]"
                    label="Name"
                    placeholder="Name"
                    dense
                    solo
                  />
                </v-col>
                <!--END TITLE-->
                <!--CALL-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Call</label
                  >
                  <v-select
                    v-model="customCall"
                    :items="customCalls"
                    :rules="customCallRules"
                    item-text="name"
                    item-value="id"
                    label="Custom call"
                    placeholder="Select Call"
                    return-object
                    dense
                    solo
                  />
                </v-col>
                <!--END CALL-->
                <!--ADDRESS LIST-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Address List</label
                  >
                  <v-select
                    v-model="customAddressList"
                    :items="customAddressLists"
                    :rules="customAddressListRules"
                    item-text="name"
                    item-value="id"
                    label="Custom address list"
                    placeholder="Select List"
                    return-object
                    dense
                    solo
                  />
                </v-col>
                <!--END ADDRESS LIST-->

                <!--INTERVAL-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Interval</label
                  >
                  <v-text-field
                    v-model="interval"
                    :rules="intervalRules"
                    label="0"
                    placeholder="0"
                    type="number"
                    min="1"
                    max="1440"
                    dense
                    solo
                  />
                </v-col>
                <!--END INTERVAL-->

                <!--TARGET-->
                <v-col cols="2" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Target</label
                  >
                  <v-switch
                    v-model="target"
                    true-value="src"
                    false-value="dst"
                    :label="target"
                    placeholder="Select Call"
                    return-object
                    class="mt-0 target-background"
                  />
                </v-col>
                <!--END TARGET-->

                <!--ACTIVE-->
                <v-col cols="2" class="pb-8 pt-2">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Active</label
                  >
                  <div class="toggle">
                    <v-switch
                      v-model="active"
                      :true-value="true"
                      :false-value="false"
                      hide-details
                      label="Active"
                      inset
                      :class="[active ? 'v-switch-on' : 'v-switch-off']"
                      class="target-background v-switch-off"
                    />
                  </div>
                </v-col>
                <!--END ACTIVE-->
              </v-row>

              <v-row class="my-0 mx-0">
                <v-col cols="12" class="pb-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { Errors } from "@/utils/error";
import {
  getAddressListConnectionType,
  storeAddressListConnectionType,
  updateAddressListConnectionType,
} from "../../../../api/datafeeds/addresslist/connection";
import { getCustomApiList } from "../../../../api/settings/apicallcustom";
import { getAddresslistsList } from "../../../../api/datafeeds/addresslist/custom";
export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,

      valid: true,
      validCheck: true,

      saving: false,

      title: "",
      titleRules: {
        required: (v) => !!v || "Title is required",
      },
      customCall: null,
      customCallRules: [(v) => v !== null || "Custom api call is required"],
      customAddressList: null,
      customAddressListRules: [
        (v) => v !== null || "Custom address list is required",
      ],
      interval: 24,
      intervalRules: [
        (v) => parseInt(v, 10) >= 1 || "Interval is required. Min 1",
        (v) => parseInt(v, 10) <= 1440 || "Max 1440",
      ],
      customCalls: [],
      customAddressLists: [],
      active: true,
      target: "src",
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      async handler(newValue) {
        this.loading = true;
        await this.loadCustomCalls();
        await this.loadCustomList();
        if (newValue === 0) {
          this.name = "";
          this.lifetime = 24;
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  // mounted() {
  //   if (this.id === 0) {
  //     this.loading = false;
  //   } else {
  //     this.loadById();
  //   }
  // },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "connectionaddresslist");
    },
    loadById() {
      getAddressListConnectionType(this.id)
        .then((response) => {
          const data = response.data;
          this.title = data.title;
          this.interval = data.interval;
          this.lastRun = data.last_run;
          this.active = data.active;
          this.customCall = data.api;
          this.customAddressList = data.list;
          this.target = data.target;
          this.checkValid = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    async loadCustomCalls() {
      await getCustomApiList()
        .then((response) => {
          const self = this;
          this.customCalls = [];

          response.data.forEach(function (list) {
            self.customCalls.push({
              id: list.id,
              name: list.name,
            });
          });
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    async loadCustomList() {
      this.customApiCallsLoading = true;
      await getAddresslistsList()
        .then((response) => {
          this.customAddressLists = [];
          const self = this;
          response.data.forEach(function (list) {
            self.customAddressLists.push({
              id: list.id,
              name: list.name,
            });
          });
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    store() {
      this.saving = true;
      const data = {
        title: this.title,
        interval: this.interval,
        active: this.active,
        target: this.target,
        api_id: this.customCall.id,
        list_id: this.customAddressList.id,
      };
      storeAddressListConnectionType(data)
        .then(() => {
          this.$toast.open({
            message: "Address List was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    update() {
      this.saving = true;

      const data = {
        title: this.title,
        interval: this.interval,
        active: this.active,
        target: this.target,
        api_id: this.customCall.id,
        list_id: this.customAddressList.id,
      };

      updateAddressListConnectionType(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Address List was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>
<style lang="scss">
.target-background {
  .v-input__slot {
    background: none !important;
    box-shadow: none !important;
  }
}
.toggle {
  .v-input--selection-controls {
    margin-top: 0px;
  }
}
</style>
