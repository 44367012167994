<template>
  <v-card class="most-violating-security-policies-widget-block">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <widget-toolbar
        v-if="!loading && !failed"
        :title="$t('widgets.violatingsecurity.headline')"
        :data-time="dataLoadedTime"
        @remove="remove"
      />
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-card-text v-if="!loading && !failed" class="with-bg pt-1 pb-4">
        <v-data-table
          :headers="headers"
          :items="data"
          hide-default-footer
          item-key="auditors-score-table"
          :items-per-page="size"
          :loading="loading"
          loading-text="Loading data from API...please wait"
        />
        <v-pagination
          v-if="data.length > 0"
          v-model="page"
          :length="pageTotalCount"
          :total-visible="7"
          circle
          class="d-inline-block pagination px-1 py-1"
          :class="page > 99 ? 'over100' : 'less100'"
        />
      </v-card-text>
    </transition>
    <v-overlay :value="loading">
      <v-progress-circular absolute indeterminate size="40" color="good-3" />
    </v-overlay>
    <v-overlay :value="failed">
      <v-card-text>
        {{ $t("widgets.violatingsecurity.headline") }} - loading data from api
        failed.
      </v-card-text>
      <v-card-actions>
        <v-btn small color="bad-2" class="mx-auto" @click="loadData">
          Reload
        </v-btn>
      </v-card-actions>
    </v-overlay>
  </v-card>
</template>

<script>
import WidgetToolbar from "@/components/Widgets/WidgetToolbar";
import { widgetAuditorRulesetMostViolating } from "@/api/dashboard";

export default {
  components: {
    WidgetToolbar,
  },
  props: {
    i: {
      type: String,
      default: function () {
        return "0";
      },
    },
    w: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    h: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {
      loading: true,
      failed: false,
      processingMessage: "",
      dataLoadedTime: "",

      page: 1,
      updatedPage: 1,
      pageTotalCount: 1,
      size: 5,

      headers: [
        { text: "Name", value: "name", sortable: true, align: "start" },
        { text: "Alerts", value: "alerts", sortable: true, align: "start" },
      ],
      data: [],
      dataAll: [],
      dataRuleset: [],
      dataIntegrity: [],

      selectItems: [
        { id: 1, name: "All" },
        { id: 2, name: "Ruleset" },
        { id: 3, name: "Integrity" },
      ],
      selectedItem: { id: 1, name: "All" },
    };
  },
  watch: {
    page: {
      immediate: true,
      deep: true,
      handler() {
        if (this.updatedPage !== this.page) {
          this.loadData();
        }
      },
    },
    selectedItem: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.data = [];

        switch (newValue.id) {
          case 2:
            this.data = this.dataRuleset;
            break;
          case 3:
            this.data = this.dataIntegrity;
            break;
          default:
            this.data = this.dataAll;
        }

        if (!this.data.length) {
          this.loadData();
        }
      },
    },
    dataAll: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.data = newValue;
      },
    },
    dataRuleset: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.data = newValue;
      },
    },
    dataIntegrity: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.data = newValue;
      },
    },
  },
  created() {
    this.updatedPage = this.page;
    this.loadData();
  },
  methods: {
    loadData() {
      this.failed = false;
      this.loading = true;
      if (this.updatedPage !== this.page) {
        widgetAuditorRulesetMostViolating(this.page, this.size)
          .then((response) => {
            this.updateWidgetData(response);
            this.updatedPage = this.page;

            localStorage.setItem(
              "widgetMostViolatingSecurityPoliciesData",
              JSON.stringify(response)
            );
            localStorage.setItem(
              "widgetMostViolatingSecurityPoliciesTimestamp",
              Date.now()
            );
            this.loading = false;
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }

      const storedData = JSON.parse(
        localStorage.getItem("widgetMostViolatingSecurityPoliciesData")
      );
      const savedTime = JSON.parse(
        localStorage.getItem("widgetMostViolatingSecurityPoliciesTimestamp")
      );

      if (savedTime && Date.now() - parseInt(savedTime, 10) < 300000) {
        this.dataLoadedTime = "";
        const transformedTimeStamp = new Date(savedTime);
        const hours = transformedTimeStamp.getHours();
        const minutes = transformedTimeStamp.getMinutes();
        this.dataLoadedTime = `${hours}:${minutes}`;
      }

      if (
        storedData &&
        savedTime &&
        Date.now() - parseInt(savedTime, 10) < 300000
      ) {
        this.updateWidgetData(storedData);
        this.loading = false;
      } else {
        widgetAuditorRulesetMostViolating(this.page, this.size)
          .then((response) => {
            this.updateWidgetData(response);

            localStorage.setItem(
              "widgetMostViolatingSecurityPoliciesData",
              JSON.stringify(response)
            );
            localStorage.setItem(
              "widgetMostViolatingSecurityPoliciesTimestamp",
              Date.now()
            );

            const savedTime = JSON.parse(
              localStorage.getItem("widgetSystemLogsTimestamp")
            );

            this.dataLoadedTime = "";
            const transformedTimeStamp = new Date(savedTime);
            const hours = transformedTimeStamp.getHours();
            const minutes = transformedTimeStamp.getMinutes();
            this.dataLoadedTime = `${hours}:${minutes}`;

            this.loading = false;
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }
    },
    updateWidgetData(response) {
      this.dataRuleset = response.data;
      this.pageTotalCount = parseInt(response.last_page);
      this.page = parseInt(response.current_page);
      this.size = parseInt(response.per_page);
    },
    remove() {
      this.$emit("remove", this.i);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-container {
  .v-card.theme--dark {
    .v-card__title {
      border-bottom: 2px solid #fff;
    }
  }
}
.dashboard-container {
  .v-card.theme--light {
    .v-card__title {
      border-bottom: 2px solid #3d3d3f;
    }
  }
}
.widget-icon {
  vertical-align: sub;
  width: 65px;
  height: 50px;
}
.v-card-text {
  background: #24272169;
  border-radius: 0;
}
.v-card__text.with-bg:not(.with-graph) {
  border-radius: 0 0 15px 15px !important;
}
.v-card__actions {
  border-radius: 0 0 15px 15px !important;
}
.v-data-footer__select {
  display: none;
}
.theme--dark.v-data-table {
  background-color: transparent;
  color: #fff;
}
.overlay-text {
  text-align: center;
  position: absolute;
  bottom: 1rem;
}
.theme--light {
  .overlay-text {
    color: #535452;
  }
}
</style>
