<template>
  <v-container fluid class="toolbox-settings-block"> </v-container>
</template>

<script>
export default {
  name: "settings",
};
</script>

<style scoped></style>
