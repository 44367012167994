import request from "@/utils/request";

export function secPolicyEvents(page, size, order, by) {
  const orderBy = order && by ? "&order=" + order + "&by=" + by : "";
  return request({
    url: "api/eventcall/shownotsent?page=" + page + "&size=" + size + orderBy,
    method: "get",
  });
}

export function secPolicyEventsCall(data) {
  return request({
    url: "api/eventcall/shownotsent/call",
    method: "post",
    data: data,
    timeout: 60000,
  });
}

export function dataFeedsEvents(page, size, order, by) {
  const orderBy = order && by ? "&order=" + order + "&by=" + by : "";
  return request({
    url:
      "api/eventcall/shownotsentfeeds?page=" + page + "&size=" + size + orderBy,
    method: "get",
  });
}

export function dataFeedsEventsCall(data) {
  return request({
    url: "api/eventcall/shownotsentfeeds/call",
    method: "post",
    data: data,
  });
}
