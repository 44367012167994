<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new Remote Server" : "Edit Remote Server" }}
            </h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--NAME-->
              <v-row class="mx-0 mt-3">
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Name</label
                  >
                  <v-text-field
                    v-model="name"
                    :rules="[nameRules.required]"
                    label="Name"
                    placeholder="Name"
                    dense
                    solo
                  />
                </v-col>
                <!--END NAME-->
                <!--IP ADDRESS-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >IP address</label
                  >
                  <v-text-field
                    v-model="ip"
                    :rules="[ipRules.required, ipValidate]"
                    label="IP address"
                    placeholder="IP address"
                    dense
                    solo
                  />
                </v-col>
                <!--END IP ADDRESS-->
                <!--IP PORT-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Port</label
                  >
                  <v-text-field
                    v-model="port"
                    label="Port"
                    placeholder="Port"
                    type="number"
                    min="1"
                    max="65535"
                    dense
                    solo
                  />
                </v-col>
                <!--END IP PORT-->
                <!--LOGIN-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Login</label
                  >
                  <v-text-field
                    v-model="login"
                    :rules="[loginRules.required]"
                    label="Login"
                    placeholder="Login"
                    dense
                    solo
                  />
                </v-col>
                <!--END LOGIN-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Password/Key</label
                  >
                  <div class="toggle-info mt-1">
                    <v-switch
                      v-model="loginWithKey"
                      :true-value="true"
                      :false-value="false"
                      :label="loginWithKey ? 'key' : 'password'"
                      hide-details
                      inset
                      :class="[loginWithKey ? 'v-switch-on' : 'v-switch-off']"
                      class="target-background v-switch-off"
                      @click="loginWithKey === true"
                    />
                  </div>
                </v-col>
                <!--PASSWORD-->
                <v-col v-if="!loginWithKey" cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Password</label
                  >
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    type="password"
                    label="Password"
                    placeholder="Password"
                    dense
                    solo
                  />
                </v-col>
                <!--END PASSWORD-->
                <!--KEY-->
                <v-col v-if="loginWithKey" cols="12">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >KEY</label
                  >
                  <v-textarea
                    v-model="key"
                    :rules="keyRules"
                    label="KEY"
                    placeholder
                    auto-grow
                    rows="4"
                    dense
                    solo
                  />
                </v-col>
                <!--END KEY-->
                <!--PATH-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Path</label
                  >
                  <v-text-field
                    v-model="path"
                    :rules="[pathRules.required]"
                    label="Path"
                    placeholder="Path"
                    dense
                    solo
                  />
                </v-col>
                <!--END PATH-->
              </v-row>

              <v-row class="mt-1 mx-0">
                <v-col cols="12" class="pb-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                  <span
                  v-if="checkLoading"
                  class="font-roboto-condensed-light font-size-15 ml-2"
                >
                  Processing...
                </span>
                  <v-btn
                    v-if="!checkLoading"
                    outlined
                    color="primary"
                    class="text-capitalize ml-1"
                    @click="validateTest"
                  >
                    <v-icon x-small class="mr-1">fas fa-play</v-icon>
                    Test Connect
                  </v-btn>
                  <span
                    v-if="!checkLoading && resultCount > 0"
                    class="font-size-15 ml-1"
                    @click="showResult"
                  >Show result</span
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import { getServer, storeServer, testServer, updateServer } from "@/api/objects/server";
import { Validation } from "@/utils/validation";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      testIsSuccess: false,
      checkValid: null,
      resultCount: 0,
      checkMessage: null,
      content: null,

      valid: true,
      validCheck: true,

      saving: false,

      name: "",

      nameRules: {
        required: (v) => !!v || "Name is required",
      },
      ip: "",
      ipRules: {
        required: (v) => !!v || "IP address is required",
      },
      port: null,
      login: "",
      loginRules: {
        required: (v) => !!v || "Login is required",
      },
      loginWithKey: true,
      password: "",
      passwordRules: [
        (v) => this.loginWithKey || !!v || "Password is required",
      ],
      key: null,
      keyRules: [(v) => !this.loginWithKey || !!v || "Key is required"],
      path: "",
      pathRules: {
        required: (v) => !!v || "Path is required",
      },
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.name = "";
          this.ip = "";
          this.login = "";
          this.password = "";
          this.path = "";
          this.port = null;
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  // mounted() {
  //   if (this.id === 0) {
  //     this.loading = false;
  //   } else {
  //     this.loadById();
  //   }
  // },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "objectserver");
    },
    loadById() {
      getServer(this.id)
        .then((response) => {
          const server = response.data;
          this.name = server.name;
          this.ip = server.ip;
          this.port = server.port;
          this.path = server.path;
          this.login = server.login;
          this.loginWithKey = server.loginWithKey;
          this.checkValid = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    validateCheck() {
      this.$refs.formCheck.validate();
      if (this.$refs.formCheck.validate()) {
        this.check();
      }
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    validateTest() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.testConnection();
      }
    },
    testConnection() {
      this.checkLoading = true;
      this.resultCount++;
      const data = this.getServerData();
      testServer(data)
        .then((response) => {
          this.checkLoading = false;
          this.testIsSuccess = true;
          this.content = response.message;
          this.showNotify(this.content, "success", 10000);
        })
        .catch((e) => {
          this.checkLoading = false;
          this.testIsSuccess = false;
          if (e.response.status === 422) {
            this.content = e.response.data.message;
          } else {
            this.content = e.message;
          }
          this.showNotify(this.content, "error", 10000);
        });
    },
    showNotify(message, type, duration) {
      this.$toast.open({
        message: message,
        type: type,
        position: "top-right",
        duration: duration,
      });
    },
    showResult() {
      this.showNotify(
        this.content,
        this.testIsSuccess ? "success" : "error",
        10000
      );
    },
    ipValidate(val) {
      return Validation.ip(val);
    },
    getServerData() {
      return {
        id: this.id,
        name: this.name,
        ip: this.ip,
        port: this.port !== "" ? parseInt(this.port) : null,
        path: this.path,
        login: this.login,
        loginWithKey: this.loginWithKey,
        password: this.password,
        key: this.key,
      };
    },
    store() {
      this.saving = true;
      const data = this.getServerData();

      storeServer(data)
        .then(() => {
          this.$toast.open({
            message: "Server was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    update() {
      this.saving = true;
      const data = this.getServerData();

      updateServer(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Server was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
