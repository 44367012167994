<template>
  <v-card class="auditor-alert-score-chart-widget-block">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <widget-toolbar
        v-if="!loading && !failed"
        :title="$t('widgets.auditorscore.alert.headline')"
        :data-time="dataLoadedTime"
        :mount-time="time"
        has-timeline
        has-select-menu
        :select-items="selectItems"
        :selected-item="selectedItem"
        @timeline="changeTime($event)"
        @onSelect="selectedItem = $event"
        @remove="remove"
      />
    </transition>

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <div v-if="!loading && !failed">
        <!--Search Firewalls-->
        <v-autocomplete
          v-model="selectedFirewalls"
          :items="firewalls"
          :loading="searching"
          prepend-icon="mdi-magnify"
          class="search-firewall"
          item-value="id"
          item-text="name"
          clearable
          :multiple="multipleFirewalls"
          label="Select firewalls"
          :disabled="!firewalls.length"
          @change="onSelectedFirewallsChange"
        >
          <template v-if="multipleFirewalls" v-slot:selection="data">
            <v-chip small color="primary" class="elevation-2 synapsa-chip">
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-if="multipleFirewalls" v-slot:item="data">
            <v-chip small color="primary" class="elevation-2 synapsa-chip">
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
        <!--END Search Firewalls-->
        <v-card-text v-if="!loading && !failed" class="with-bg pb-4 pt-3">
          <chart
            :x-axis-data="xAxisData"
            :data="[data.devices, data.rules, data.alerts]"
          />
        </v-card-text>
      </div>
    </transition>
    <v-overlay :value="loading">
      <v-progress-circular absolute indeterminate size="40" color="good-3" />
    </v-overlay>
    <v-overlay :value="failed">
      <v-card-text v-if="failed">
        {{ $t("widgets.auditorscore.alert.headline") }} - loading data from api
        failed.
      </v-card-text>
      <v-card-actions>
        <v-btn small color="bad-2" class="mx-auto" @click="loadData">
          Reload
        </v-btn>
      </v-card-actions>
    </v-overlay>
    <v-overlay :value="showCountdown">
      <countdown :radius="20" :stroke-width="5" @action="loadData" />
      <v-card-text class="overlay-text">
        {{ $t("widgets.auditorscore.alert.headline") }} -
        {{ processingMessage }}
      </v-card-text>
    </v-overlay>
  </v-card>
</template>

<script>
import chart from "@/components/Widgets/AuditorAlertScoreChartWidget/chart";
import { widgetAuditorScoreAlerts } from "@/api/dashboard";

import WidgetToolbar from "@/components/Widgets/WidgetToolbar";
import { getFirewalls } from "@/api/settings/firewall";

export default {
  components: {
    chart,
    WidgetToolbar,
  },
  props: {
    i: {
      type: String,
      default: function () {
        return "0";
      },
    },
    w: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    h: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {
      loading: true,
      searching: false,
      failed: false,
      processingMessage: "",
      showCountdown: false,
      dataLoadedTime: "",
      width: null,

      data: {
        devices: {
          name: "Devices",
          type: "line",
          showSymbol: false,
          data: [],
          color: "#8cd4dd",
          lineStyle: {
            width: 5,
          },
        },
        rules: {
          name: "Rules",
          type: "line",
          showSymbol: false,
          data: [],
          color: "#a6dc86",
          lineStyle: {
            width: 4,
          },
        },
        alerts: {
          name: "Alerts",
          type: "line",
          showSymbol: false,
          data: [],
          color: "#f28d9f",
          lineStyle: {
            width: 3,
          },
        },
      },
      xAxisData: [],

      updatedTime: "lastweek",
      time: "lastweek",
      selectItems: [
        { id: 1, name: "All", auditorType: undefined },
        { id: 2, name: "Ruleset", auditorType: 1 },
        { id: 3, name: "Integrity", auditorType: 3 },
      ],
      selectedItem: { id: 1, name: "All", auditorType: undefined },
      updatedSelectedItem: 1,
      multipleFirewalls: false,
      selectedFirewalls: undefined,
      updatedSelectedFirewalls: undefined,
      firewalls: [],
    };
  },
  watch: {
    selectedItem: {
      deep: true,
      handler() {
        if (this.updatedSelectedItem !== this.selectedItem.id) {
          this.loadData();
        }
      },
    },
  },
  mounted() {
    setTimeout(() => {
      this.loadData();
    }, 1);
  },
  methods: {
    remove() {
      this.$emit("remove", this.i);
    },
    changeTime(time) {
      this.time = time;
      this.loadData();
    },
    async loadData() {
      this.failed = false;
      this.loading = true;
      this.showCountdown = false;
      this.processingMessage = "";

      const data = {
        period: this.time,
        firewallIds: [].concat(this.selectedFirewalls || []),
        auditorType: this.selectedItem.auditorType,
        auditor: this.selectedItem,
      };

      const storedFirewalls = JSON.parse(localStorage.getItem("firewalls"));

      if (storedFirewalls) {
        this.firewalls = storedFirewalls;
        this.loading = false;
      } else {
        getFirewalls(1, 100)
          .then((response) => {
            localStorage.setItem("firewalls", JSON.stringify(response.data));
            this.firewalls = response.data;
            this.loading = false;
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }

      if (
        this.updatedTime !== this.time ||
        this.updatedSelectedFirewalls !== this.selectedFirewalls ||
        this.updatedSelectedItem !== this.selectedItem.id
      ) {
        this.updatedTime = this.time;
        this.updatedSelectedFirewalls = this.selectedFirewalls;
        this.updatedSelectedItem = this.selectedItem.id;
        await widgetAuditorScoreAlerts(data)
          .then((response) => {
            this.updateChartData(response.data);
            this.loading = false;

            const dataToStore = {
              data: response.data,
              time: data.period,
              firewallIds: data.firewallIds,
              auditor: data.auditor,
            };

            localStorage.setItem(
              "widgetAuditorTotalAlertsData",
              JSON.stringify(dataToStore)
            );
            localStorage.setItem(
              "widgetAuditorTotalAlertsTimestamp",
              Date.now()
            );
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }

      const storedData = JSON.parse(
        localStorage.getItem("widgetAuditorTotalAlertsData")
      );
      const savedTime = JSON.parse(
        localStorage.getItem("widgetAuditorTotalAlertsTimestamp")
      );
      if (savedTime && Date.now() - parseInt(savedTime, 10) < 300000) {
        this.dataLoadedTime = "";
        const transformedTimeStamp = new Date(savedTime);
        const hours = transformedTimeStamp.getHours();
        const minutes = transformedTimeStamp.getMinutes();
        this.dataLoadedTime = `${hours}:${minutes}`;
      }

      if (
        storedData &&
        savedTime &&
        Date.now() - parseInt(savedTime, 10) < 300000
      ) {
        this.time = storedData.time;
        this.updatedTime = storedData.time;
        const storedSelectedFirewallIds =
          this.multipleFirewalls && Array.isArray(storedData.firewallIds)
            ? storedData.firewallIds
            : storedData.firewallIds[0];
        this.selectedFirewalls = storedSelectedFirewallIds;
        this.updatedSelectedFirewalls = storedSelectedFirewallIds;
        this.selectedItem = {
          id: storedData.auditor.id,
          name: storedData.auditor.name,
          auditorType: storedData.auditor.auditorType,
        };
        this.updatedSelectedItem = storedData.auditor.id;
        this.updateChartData(storedData.data);
        this.loading = false;
      } else {
        await widgetAuditorScoreAlerts(data)
          .then((response) => {
            this.loading = false;

            const dataToStore = {
              data: response.data,
              time: data.period,
              firewallIds: data.firewallIds,
              auditor: data.auditor,
            };

            localStorage.setItem(
              "widgetAuditorTotalAlertsData",
              JSON.stringify(dataToStore)
            );
            localStorage.setItem(
              "widgetAuditorTotalAlertsTimestamp",
              Date.now()
            );

            const savedTime = JSON.parse(
              localStorage.getItem("widgetSystemLogsTimestamp")
            );

            this.dataLoadedTime = "";
            const transformedTimeStamp = new Date(savedTime);
            const hours = transformedTimeStamp.getHours();
            const minutes = transformedTimeStamp.getMinutes();
            this.dataLoadedTime = `${hours}:${minutes}`;

            this.updateChartData(response.data);
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }

      if (this.processingMessage) {
        this.showCountdown = true;
      }
    },
    updateChartData(data) {
      const self = this;
      this.clearData();
      data.forEach(function (value) {
        self.xAxisData.push(value.run_at);

        self.data.devices.data.push(
          value.devices ? value.devices : self.selectedFirewalls.length
        );
        self.data.rules.data.push(value.all_count);
        self.data.alerts.data.push(value.alerts);
      });
    },
    clearData() {
      this.xAxisData = [];
      this.data.alerts.data = [];
      this.data.devices.data = [];
      this.data.rules.data = [];
    },
    onSelectedFirewallsChange() {
      this.$nextTick(() => {
        this.loadData();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--dark .dashboard-container .v-card {
  background: #4b4c4d !important;
}
.theme--light .dashboard-container .v-card {
  background: #fff !important;
}
.search-firewall {
  padding: 1rem 2rem 0 1rem;
}
.dashboard-container {
  .v-card {
    overflow: hidden;
  }
  .v-card.theme--dark {
    .v-card__title {
      border-bottom: 2px solid #fff;
    }
  }
}
.dashboard-container {
  .v-card.theme--light {
    .v-card__title {
      border-bottom: 2px solid #3d3d3f;
    }
  }
}
</style>
