import { Validation } from "@/utils/validation.js";

export const AuditorValidation = {
  general: function (general) {
    return !(
      general.name === "" ||
      general.severity === null ||
      general.action === null
    );
  },
  ruleset: function (ruleset) {
    if (
      ruleset.vendor !== null &&
      ruleset.firewall !== null &&
      ruleset.action !== null &&
      ruleset.srcIp !== "" &&
      ruleset.srcIpRule !== null &&
      ruleset.dstIp !== "" &&
      ruleset.dstIpRule !== null &&
      ruleset.service !== "" &&
      ruleset.serviceRule !== null
    ) {
      if (
        Validation.ipWithRule(ruleset.srcIp, ruleset.srcIpRule) === true &&
        Validation.ipWithRule(ruleset.dstIp, ruleset.dstIpRule) === true &&
        Validation.service(ruleset.service, ruleset.serviceRule) === true
      ) {
        if (
          ruleset.vendor === 1 &&
          ruleset.srcZone !== "" &&
          ruleset.srcZoneRule !== null &&
          ruleset.dstZone !== "" &&
          ruleset.dstZoneRule !== null
        ) {
          return true;
        } else if (
          ruleset.vendor === 2 &&
          ruleset.status !== null &&
          ruleset.srcInterface !== "" &&
          ruleset.srcInterfaceRule !== null &&
          ruleset.dstInterface !== "" &&
          ruleset.dstInterfaceRule !== null
        ) {
          return true;
        } else if (
          ruleset.vendor === 3 &&
          ruleset.status !== null &&
          ruleset.vpn !== "" &&
          ruleset.vpnRule !== null
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
};
