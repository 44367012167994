<template>
  <v-dialog v-model="visible" width="auto">
    <v-card color="primary" v-if="loading">
      <v-card-text>
        Please stand by
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-container v-else>
      <v-row class="diff-wrapper" v-if="alert">
        <v-col cols="12">
          <h3 class="bad-2" v-if="alert">Alert would be generated!</h3>
        </v-col>
        <v-col cols="12">
          <!--Select Firewall-->
          <v-autocomplete
            v-if="data.length > 1"
            v-model="selectedFirewall"
            :items="firewalls"
            :loading="loading"
            class="search-firewall"
            item-value="id"
            item-text="name"
            clearable
            label="Select firewall"
            :disabled="!data.length"
            @change="onSelectedFirewallsChange"
          />
          <!--END Select Firewall-->
          <div class="text-content mb-2" v-if="alert">
            <h5>Reasons for alert:</h5>
            <ul>
              <li v-if="dbResponse && fwResponse && dbResponse !== fwResponse">
                <b>Compared configurations are not the same!</b>
              </li>
              <li v-if="customConditionsResults.length">
                <b
                  v-if="
                    customConditionsResults.filter(
                      (condition) => condition.failed
                    ).length
                  "
                  >Some of the custom conditions are not matched:</b
                >
                <b v-else>Custom condition list:</b>
                <v-list dense :elevation="1" class="commands-list">
                  <v-list-item
                    v-for="condition in customConditionsResults"
                    :key="condition.fullCondition"
                    rounded
                  >
                    <span class="font-roboto-condensed-regular">
                      <v-icon
                        :color="condition.failed ? 'bad-2' : 'good-2'"
                        small
                      >
                        fas fa-circle
                      </v-icon>
                      {{ formattedConditions(condition.fullCondition) }}
                      <small class="bad-1--text" v-if="condition.notFound">
                        (Condition not found in XML)
                      </small>
                    </span>
                  </v-list-item>
                </v-list>
              </li>
            </ul>
          </div>
          <div
            v-if="
              dbResponseWithConditions &&
              fwResponseWithConditions &&
              dbResponseWithConditions !== fwResponseWithConditions
            "
          >
            <div class="d-flex">
              <span class="w-50">Database config</span>
              <span>Firewall config</span>
            </div>
            <code-diff
              class="diff-area"
              :old-string="formattedXml(dbResponseWithConditions)"
              :new-string="formattedXml(fwResponseWithConditions)"
              output-format="side-by-side"
            />
          </div>
          <div
            class="text-content"
            v-if="alert && (dbResponse === fwResponse || !dbResponse)"
          >
            <div v-if="dbResponse">
              <h5>Config stored at database:</h5>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="expansion-panel-header__icon"
                        >$expand</v-icon
                      >
                    </template>
                    <span class="expansion-panel-header__header"
                      >Formated data</span
                    >
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <xml-viewer
                      ref="viewer"
                      :xml="formattedXml(dbResponse)"
                      :class="$vuetify.theme.dark ? 'dark' : 'light'"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="expansion-panel-header__icon"
                        >$expand</v-icon
                      >
                    </template>
                    <span class="expansion-panel-header__header">Raw data</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      <small>{{ formattedXml(dbResponse) }}</small>
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <hr v-if="dbResponse" />
            <div v-if="fwResponse">
              <h5 class="mt-2">Config stored at firewall:</h5>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="expansion-panel-header__icon"
                        >$expand</v-icon
                      >
                    </template>
                    <span class="expansion-panel-header__header"
                      >Formated data</span
                    >
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <xml-viewer
                      ref="viewer"
                      :xml="formattedXml(fwResponse)"
                      :class="$vuetify.theme.dark ? 'dark' : 'light'"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template v-slot:actions>
                      <v-icon class="expansion-panel-header__icon"
                        >$expand</v-icon
                      >
                    </template>
                    <span class="expansion-panel-header__header">Raw data</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>
                      <small>{{ formattedXml(fwResponse) }}</small>
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <v-btn
            rounded
            color="primary"
            class="text-capitalize bottom-btn font-ubuntu-bold"
            @click="onClose"
          >
            Close
          </v-btn>
          <v-btn
            v-permission="['view advancedmode']"
            rounded
            outlined
            class="bottom-btn mr-2 font-ubuntu-bold"
            @click="isMask = !isMask"
          >
            Mask <v-icon class="mask" v-if="isMask">mdi-eye</v-icon
            ><v-icon class="mask" v-else>mdi-eye-off</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else class="diff-wrapper-no-alert">
        <v-col cols="12" class="d-flex justify-center">
          <div class="d-flex flex-column alert">
            <div class="d-flex flex-column align-center pb-4 icon-wrapper">
              <v-icon class="alert-icon"> mdi-alert-box-outline </v-icon>
              <h4 class="font-ubuntu-regular">Alert would not be generated</h4>
            </div>
            <div class="pt-4">
              <h4 class="font-ubuntu-regular">- Conditions are matched</h4>
              <h4 class="font-ubuntu-regular">
                - There are not any changes between configurations provided
              </h4>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <v-btn
            rounded
            color="primary"
            class="text-capitalize bottom-btn font-ubuntu-bold"
            @click="onClose"
          >
            Close
          </v-btn>
          <v-btn
            v-permission="['view advancedmode']"
            rounded
            outlined
            class="bottom-btn mr-2 font-ubuntu-bold"
            @click="isMask = !isMask"
          >
            Mask <v-icon class="mask" v-if="isMask">mdi-eye</v-icon
            ><v-icon class="mask" v-else>mdi-eye-off</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import permission from "@/directive/permission/permission.js";
import { CodeDiff } from "v-code-diff";
import {
  getFormattedXml,
  getFormattedConditions,
  maskTags,
} from "@/utils/auditor";
import { testAuditorIntegrity } from "@/api/auditor/integrity";
import { Errors } from "@/utils/error";
import XmlViewer from "@/plugins/xmlViewer";

export default {
  name: "testIntegrity",
  directives: { permission },
  components: {
    CodeDiff,
    XmlViewer,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return undefined;
      },
    },
    show: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    auditorData: {
      type: Object,
      default: function () {
        return undefined;
      },
    },
  },
  watch: {
    show: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.visible = newValue;
        if (newValue) {
          this.loading = true;
          this.testIntegrity();
        }
      },
    },
    visible: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) {
          this.onClose();
        }
      },
    },
  },
  data() {
    return {
      contentTabDatabase: "format",
      contentTabFirewall: "format",
      visible: false,
      loading: true,
      data: [],
      selectedFirewall: "",
      firewalls: [],
      maskedTags: [],
      mask: "",
      isMask: true,

      dbResponse: undefined,
      fwResponse: undefined,
      dbResponseWithConditions: undefined,
      fwResponseWithConditions: undefined,
      customConditionsResults: [],
      alert: false,
    };
  },
  methods: {
    testIntegrity() {
      testAuditorIntegrity(this.id, this.auditorData)
        .then((response) => {
          this.data = response.data.tests.filter((test) => test.alert);
          this.firewalls = this.data.map((test) => {
            return test.firewall;
          });

          if (this.data.length) {
            this.selectedFirewall = this.data[0].firewall;
            this.dbResponse = this.data[0].dbResponse;
            this.fwResponse = this.data[0].fwResponse;
            this.dbResponseWithConditions = this.data[0].dbResponseWithConditions;
            this.fwResponseWithConditions = this.data[0].fwResponseWithConditions;
            this.customConditionsResults = this.data[0].customConditionsResults;
            this.alert = !!this.data[0].alert;
          }

          this.maskedTags = response.data.maskedTags;
          this.mask = response.data.mask;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.onClose();
          Errors.show(e);
        });
    },
    onSelectedFirewallsChange() {
      const index = this.data.findIndex(
        (obj) => obj.firewall.id === this.selectedFirewall
      );
      this.selectedFirewall = this.data[index].firewall;
      this.dbResponse = this.data[index].dbResponse;
      this.fwResponse = this.data[index].fwResponse;
      this.dbResponseWithConditions = this.data[index].dbResponseWithConditions;
      this.fwResponseWithConditions = this.data[index].fwResponseWithConditions;
      this.customConditionsResults = this.data[index].customConditionsResults;
      this.alert = !!this.data[index].alert;
    },
    formattedXml(xml) {
      if (xml && this.maskedTags && this.mask && this.isMask) {
        return getFormattedXml(maskTags(xml, this.maskedTags, this.mask));
      }
      return getFormattedXml(xml);
    },
    formattedConditions(text) {
      return getFormattedConditions(text);
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss" scoped>
.theme--dark {
  .diff-wrapper {
    background-color: #1e1e1e;
    box-shadow: 13px 13px 30px rgb(0 0 0 / 30%);
  }
  .text-content {
    border: 0.5px solid #ffffff;
  }
}
.theme--light {
  .diff-wrapper {
    background-color: #ffffff;
    box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
  }
  .text-content {
    border: 0.5px solid #1e1e1e;
  }
}
.diff-wrapper {
  .diff-area {
    padding-bottom: 0.1em;
    color: #008;
    background-color: #ffffff;
    box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
    margin: 0;
  }
  .text-content {
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 0.5rem;
  }
  .bottom-btn {
    float: right;
  }
}
.theme--dark {
  .diff-wrapper-no-alert {
    background-color: #1e1e1e;
    box-shadow: 13px 13px 30px rgb(0 0 0 / 30%);
  }
  .text-content {
    border: 0.5px solid #ffffff;
  }
}
.theme--light {
  .diff-wrapper-no-alert {
    background-color: #ffffff;
    box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
  }
  .text-content {
    border: 0.5px solid #1e1e1e;
  }
}
.diff-wrapper-no-alert {
  width: 53%;
  margin: auto;
  .diff-area {
    padding-bottom: 0.1em;
    color: #008;
    background-color: #ffffff;
    box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
    margin: 0;
  }
  .text-content {
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 0.5rem;
  }
  .bottom-btn {
    float: right;
  }
}
::v-deep .d2h-cntx {
  background-color: #fff;
}
::v-deep .d2h-emptyplaceholder {
  background-color: #f1f1f1;
}
::v-deep .d2h-code-line-prefix,
::v-deep .d2h-wrapper .hljs,
::v-deep .d2h-wrapper .hljs-section,
::v-deep .d2h-wrapper .hljs-keyword,
::v-deep .d2h-wrapper .hljs-string,
::v-deep .d2h-wrapper .hljs-literal,
::v-deep .d2h-wrapper .hljs-title,
::v-deep .d2h-wrapper .hljs-number,
::v-deep .d2h-wrapper .d2h-code-side-linenumber,
::v-deep .d2h-wrapper .d2h-code-side-line {
  color: #000;
}
::v-deep .d2h-wrapper tr {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
::v-deep .d2h-wrapper .d2h-code-side-linenumber {
  height: 18px;
}
::v-deep .d2h-diff-table {
  font-family: RobotoCondensedRegular;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f7f7f7;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.v-expansion-panel-header__icon {
  margin-bottom: 6px !important;
}
.expansion-panel-header__icon {
  order: 0;
}
.expansion-panel-header__header {
  order: 1;
}

.theme--light {
  .alert {
    width: 60rem;
    background-color: #338dd3;
    padding: 8px;
    border-radius: 4px;
  }
  .alert-icon {
    width: fit-content;
    font-size: 60px !important;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 100%;
    color: #000 !important;
  }
  .icon-wrapper {
    border-bottom: 2px solid #000;
  }
}
.theme--dark {
  .alert {
    width: 60rem;
    background-color: #1f7f7d;
    padding: 8px;
    border-radius: 4px;
  }
  .alert-icon {
    width: fit-content;
    font-size: 60px !important;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 100%;
  }
  .icon-wrapper {
    border-bottom: 2px solid #fff;
  }
}

::v-deep .v-dialog {
  box-shadow: none;
}
</style>
