import request from "@/utils/request";

export function storeSynapsa(data) {
  return request({
    url: "api/system/synapsa",
    method: "post",
    data: data,
  });
}

export function storeInitialSetup(data) {
  return request({
    url: "api/system/initialsetup",
    method: "post",
    data: data,
  });
}

export function getSynapsa() {
  return request({
    url: "api/system/synapsa",
    method: "get",
  });
}

export function checkFirstRun() {
  return request({
    url: "api/system/check_first_run",
    method: "get",
  });
}
