<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="py-5 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new API Call" : "Edit API Call" }}
            </h3>
          </v-col>
          <v-col cols="12" class="pt-0 pb-2 pl-6">
            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'general'"
              :color="activeTab === 'general' ? 'primary' : ''"
              :outlined="activeTab === 'general'"
              @click="activeTab = 'general'"
            >
              <span>General</span>
            </v-btn>

            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'help'"
              :color="activeTab === 'help' ? 'primary' : ''"
              :outlined="activeTab === 'help'"
              @click="activeTab = 'help'"
            >
              <span>Help</span>
            </v-btn>
          </v-col>
          <v-col v-if="activeTab === 'general'" cols="12" class="pt-0">
            <v-form ref="form" v-model="validCheck" lazy-validation>
              <!--Firewall-->
              <v-row class="mt-0 mx-0">
                <v-col cols="6" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Firewall</label
                  >
                  <v-select
                    v-model="firewall"
                    :items="firewalls"
                    :rules="firewallRules"
                    item-text="name"
                    item-value="id"
                    label="Firewall"
                    placeholder="Select Firewall"
                    return-object
                    dense
                    solo
                    @change="version = callType = ''"
                  />
                </v-col>
                <!--Version-->
                <v-col v-if="firewall.id !== 1" cols="6" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Version</label
                  >
                  <v-select
                    v-model="version"
                    :items="versions"
                    :rules="versionRules"
                    item-text="name"
                    item-value="id"
                    label="Version"
                    placeholder="Select Version"
                    return-object
                    dense
                    solo
                    @change="onChange"
                  />
                </v-col>
                <!--END Version-->
                <!--Call type-->
                <v-col cols="6" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Call type</label
                  >
                  <v-select
                    v-model="callType"
                    :items="callTypes"
                    :rules="callTypeRules"
                    item-text="name"
                    item-value="id"
                    label="Call type"
                    placeholder="Select type"
                    return-object
                    dense
                    solo
                  />
                </v-col>
                <!--END Call type-->
                <!--Name-->
                <v-col cols="6" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Name</label
                  >
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    placeholder="Name"
                    dense
                    solo
                  />
                </v-col>
                <!--END Name -->
                <!--Description-->
                <v-col cols="12" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Description</label
                  >
                  <v-textarea
                    v-model="description"
                    :rules="descriptionRules"
                    placeholder="Description"
                    auto-grow
                    rows="3"
                    dense
                    solo
                  />
                </v-col>
                <!--END Description-->
              </v-row>
              <!--END Firewall-->

              <!--URL-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Url</label
                  >
                  <v-textarea
                    v-model="url"
                    :rules="urlRules"
                    placeholder="Url"
                    auto-grow
                    rows="3"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END URL-->
              <!--Method-->
              <v-row class="mt-0 mx-0">
                <v-col cols="6" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Method</label
                  >
                  <v-select
                    v-model="method"
                    :items="methods"
                    :rules="methodRules"
                    item-text="name"
                    item-value="id"
                    label="Method"
                    placeholder="Select method"
                    return-object
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END Method-->
              <!--Data-->
              <v-row
                class="mt-0 mx-0"
                v-if="method === 'PUT' || method === 'POST'"
              >
                <v-col cols="12" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Data</label
                  >
                  <v-textarea
                    v-model="data"
                    placeholder="Data"
                    auto-grow
                    rows="3"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END Data-->
              <!--Variables-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Variables</label
                  >
                  <v-textarea
                    v-model="variables"
                    placeholder="API Variables"
                    auto-grow
                    rows="3"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END Variables-->
              <!--Check HTTP Status code-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="pb-0">
                  <div class="toggle">
                    <v-switch
                      v-model="checkStatusCode"
                      :value="true"
                      hide-details
                      label="Check HTTP Status code"
                      inset
                      @click="checkStatusCode"
                      :class="[
                        checkStatusCode ? 'v-switch-on' : 'v-switch-off',
                      ]"
                      class="target-background v-switch-off"
                    />
                  </div>
                </v-col>
              </v-row>
              <!--END Check HTTP Status code-->
              <!--Check Response code-->
              <v-row class="mt-5 mx-0">
                <v-col cols="12" class="pb-0">
                  <div class="toggle mb-4">
                    <v-switch
                      v-model="checkResponseCode"
                      :value="true"
                      hide-details
                      label="Check Response code"
                      inset
                      @click="checkResponseCode"
                      :class="[
                        checkResponseCode ? 'v-switch-on' : 'v-switch-off',
                      ]"
                      class="target-background v-switch-off"
                    />
                  </div>
                </v-col>
              </v-row>
              <!--END Check Response code-->
              <!--Response message-->
              <v-row v-if="checkResponseCode" class="mt-5 mx-0">
                <v-col cols="12" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Response code</label
                  >
                  <v-textarea
                    v-model="responseCode"
                    placeholder="Response code"
                    auto-grow
                    rows="3"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END Response message-->
              <v-row class="mx-0" :class="id === 0 ? 'mt-10' : 'mt-3'">
                <v-col cols="12" class="py-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col
            v-else-if="activeTab === 'help'"
            cols="12"
            class="px-6 font-roboto-condensed-regular font-size-15"
          >
            <span class="font-ubuntu-bold">How to add a custom call:</span>
            <p>
              Replace the IP with $FW_IP and replace the security key with
              $API_KEY. These variables are defined in the specific Firewall
              settings. We replace the remaining data with unique names, which
              we then define in API variables.
            </p>
            <span class="font-ubuntu-bold">Data from Firewall:</span>
            <p>
              $FW_IP => Ip from Firewall <br />
              $API_KEY => Api key from Firewall <br />
              $POLICY_ID => Policy ID for security policy (fortinet) <br />
              $BEFORE_ID => First policy ID from Firewall (fortinet) <br />
            </p>
            <span class="font-ubuntu-bold">Data from Minner:</span>
            <p>
              DST_IP=MINER_SYSLOG_DST_IP <br />
              SRC_IP=MINER_SYSLOG_SRC_IP <br />
              DST_TAG=MINER_SYSLOG_TAG <br />
              DST_DESCRIPTION=MINER_SYSLOG_DESCRIPTION <br />
            </p>
            <span class="font-ubuntu-bold">System Variable:</span>
            <p class="mb-3">UNIQUE_NUMBER</p>
            <span class="font-ubuntu-bold">How to add variables:</span>
            <p class="mb-3">
              If we need to add values from the parser to the call, use one of
              the allowed values that the parser has defined (<span
                class="text-red"
                >MINER_SYSLOG_SRC_IP, MINER_SYSLOG_DST_IP, MINER_SYSLOG_TAG,
                MINER_SYSLOG_DESCRIPTION</span
              >) Example:
              <span class="text-red">SRC_IP=MINER_SYSLOG_SRC_IP </span>or
              <span class="text-red"> SOURCE_IP=MINER_SYSLOG_SRC_IP </span>
            </p>
            <p class="mb-3">
              If we want to add custom values rather than values from the
              parser, we will use We need to end the text we want to add to call
              <span class="text-red">@</span>
              Example:
              <span class="text-red">ANY1=local@ </span>or
              <span class="text-red"> ANY2=network@ </span>
            </p>
            <p>
              If necessary, we can add some of the allowed system variables
              before @. Currently only UNIQUE_NUMBER is available Example:
              <span class="text-red">RULENAME=SYNAPSA_@UNIQUE_NUMBER</span>
            </p>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { Errors } from "@/utils/error";
import { Validation } from "@/utils/validation";
import { getApiCall } from "@/api/settings/apicall";
import { getApiCallVersions } from "@/api/settings/apicall";
import { getFirewallVersions } from "@/api/settings/apicall";
import { getCallTypes } from "@/api/settings/apicall";
import { storeApiCall } from "@/api/settings/apicall";
import { updateApiCall } from "@/api/settings/apicall";
import { getFirewallVendors } from "@/api/settings/firewall";

export default {
  name: "index",
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      activeTab: "general",
      loading: true,
      checkLoading: false,
      checkValid: null,
      perspectiveValid: null,

      valid: true,
      validCheck: true,
      saving: false,
      help: false,
      dataApicalls: null,
      dataVersions: null,
      newId: null,

      firewall: "",
      firewallRules: [(v) => !!v || "Firewall is required"],
      url: "",
      urlRules: [(v) => !!v || "Url is required"],
      version: "",
      versionRules: [(v) => !!v || "Version is required"],
      callType: "",
      callTypeRules: [(v) => !!v || "Call type is required"],
      method: "",
      methodRules: [(v) => !!v || "Method is required"],
      data: "",
      variables: "",
      checkStatusCode: null,
      checkResponseCode: null,
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      description: "",
      descriptionRules: [(v) => !!v || "Description is required"],
      responseCode: "",

      firewalls: [],
      versions: [],
      callTypes: [],
      methods: ["GET", "POST", "PUT", "DELETE"],
    };
  },
  watch: {
    firewall: {
      immediate: true,
      deep: true,
      handler(newValue) {
        getFirewallVersions(newValue.id)
          .then((response) => {
            const self = this;
            this.versions = [];
            response.data.forEach(function (response) {
              self.versions.push(response.version);
            });
          })
          .catch((e) => {
            Errors.show(e);
          });
        getCallTypes(newValue.id)
          .then((response) => {
            const self = this;
            this.callTypes = [];
            if (this.callType !== "") {
              response.data.forEach(function (response) {
                self.callTypes.push(response);
                if (self.callType === response.id) {
                  self.callType = response;
                }
              });
            } else {
              response.data.forEach(function (response) {
                self.callTypes.push(response);
              });
            }
          })
          .catch((e) => {
            Errors.show(e);
          });
      },
    },
    version: {
      immediate: true,
      deep: true,
      handler(newValue) {
        var apiIndex = null;
        if (this.dataVersions) {
          this.dataVersions.forEach(function (response, index) {
            if (response === newValue) {
              apiIndex = index;
            }
          });
          if (apiIndex !== null) {
            const apiCall = this.dataApicalls[apiIndex];
            this.$emit("changeEditId", apiCall.id);
            this.name = apiCall.name;
            this.description = apiCall.description;
            this.url = apiCall.url;
            this.firewall = apiCall.vendor;
            this.version = apiCall.version;
            this.callType = apiCall.calltype_id;
            this.method = apiCall.method;
            this.checkStatusCode = apiCall.check_status_code === 1;
            this.checkResponseCode = apiCall.check_responsecode === 1;
            this.responseCode = apiCall.response_code;
            this.variables = "";
            if (apiCall.apivariable) {
              for (const [key, value] of Object.entries(apiCall.apivariable)) {
                this.variables += key + "=" + value + "\n";
              }
            }
          }
        }
      },
    },
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        this.activeTab = "general";
        this.checkValid = null;
        this.perspectiveValid = null;
        this.validCheck = true;
        this.help = false;
        this.dataApicalls = null;
        this.dataVersions = null;
        this.firewall = "";
        this.url = "";
        this.version = "";
        this.callType = "";
        this.method = "";
        this.data = "";
        this.variables = "";
        this.checkStatusCode = null;
        this.checkResponseCode = null;
        this.name = "";
        this.description = "";
        this.responseCode = "";
        this.firewalls = [];
        this.versions = [];
        this.callTypes = [];
        getFirewallVendors()
          .then((response) => {
            response.data.map((firewall) => this.firewalls.push(firewall));
            if (newValue === 0) {
              this.loading = false;
              if (this.$route.name === "apiPaloAlto") {
                this.firewall = this.firewalls[0];
              } else if (this.$route.name === "apiFortinet") {
                this.firewall = this.firewalls[1];
              } else if (this.$route.name === "apiCheckpoint") {
                this.firewall = this.firewalls[2];
              } else if (this.$route.name === "apiSophos") {
                this.firewall = this.firewalls[3];
              }
            } else {
              this.loadById(this.id);
            }
          })
          .catch((e) => {
            Errors.show(e);
            if (newValue === 0) {
              this.loading = false;
            }
          });
      },
    },
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "apicall");
    },
    urlValidate(val) {
      return Validation.url(val);
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    loadById(id) {
      getApiCall(id)
        .then((response) => {
          const apiCall = response.data;
          this.newId = id;
          this.name = apiCall.name;
          this.description = apiCall.description;
          this.url = apiCall.url;
          this.firewall = apiCall.vendorattribute;
          this.version = apiCall.version;
          this.callType = apiCall.calltype_id;
          this.method = apiCall.method;
          this.data = apiCall.data;
          this.checkStatusCode = apiCall.check_status_code === 1;
          this.checkResponseCode = apiCall.check_responsecode === 1;
          this.responseCode = apiCall.response_code;
          this.variables = "";
          if (apiCall.apivariable) {
            for (const [key, value] of Object.entries(apiCall.apivariable)) {
              this.variables += key + "=" + value + "\n";
            }
          }
          const data = {
            vendor: this.firewall.id,
            name: this.name,
          };
          getApiCallVersions(data)
            .then((response) => {
              this.dataVersions = response.versions;
              this.dataApicalls = response.data;
              this.loading = false;
              this.perspectiveValid = true;
            })
            .catch((e) => {
              this.loading = false;
              this.perspectiveValid = true;
              Errors.show(e);
            });
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    store() {
      this.saving = true;
      let variables = null;

      if (typeof this.variables === "string" && this.variables.length === 0) {
        variables = null;
      } else {
        variables = this.variables.split("\n");
        variables = variables.filter((element) => {
          return element !== "";
        });
      }

      const data = {
        vendor_id: this.firewall.id,
        version: this.version,
        calltype: this.callType.id,
        name: this.name,
        description: this.description,
        url: this.url,
        callmethod: this.method,
        data: this.data,
        variables: variables,
        check_response_code: this.checkResponseCode,
        check_status_code: this.checkStatusCode,
        response_code: this.responseCode,
      };
      storeApiCall(data)
        .then(() => {
          this.$toast.open({
            message: "Apicall was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    onChange() {
      this.dataApicalls.map((call) => {
        if (call.version === this.version) {
          this.loadById(call.id);
        }
      });
    },
    update() {
      this.saving = true;
      let variables = null;

      if (typeof this.variables === "string" && this.variables.length === 0) {
        variables = null;
      } else {
        variables = this.variables.split("\n");
        variables = variables.filter((element) => {
          return element !== "";
        });
      }

      const data = {
        vendor_id: this.firewall.id,
        version: this.version,
        calltype: this.callType.id,
        name: this.name,
        description: this.description,
        url: this.url,
        callmethod: this.method,
        data: this.data,
        variables: variables,
        check_response_code: this.checkResponseCode,
        check_status_code: this.checkStatusCode,
        response_code: this.responseCode,
      };
      updateApiCall(data, this.newId ? this.newId : this.id)
        .then(() => {
          this.$toast.open({
            message: "Apicall was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.saving = false;
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped>
.text-red {
  color: indianred;
}
</style>
