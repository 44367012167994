export const AuditorParser = {
  multiToString: function (data, rule) {
    if (rule) {
      if (data && [1, 2, 3, 6, 14, 15, 33, 19, 24, 37, 38, 55, 59, 61, 63, 66].includes(rule)) {
        let string = "";
        data.forEach(function (value, key) {
          const notLast = key !== data.length - 1 ? ", " : "";
          string += value + notLast;
        });
        return string;
      } else {
        return data;
      }
    } else {
      let string = "";
      if (data) {
        data.forEach(function (value, key) {
          const notLast = key !== data.length - 1 ? ", " : "";
          string += value + notLast;
        });
      }
      return string;
    }
  },
  stringToMulti: function (data, rule) {
    if (rule) {
      if (data) {
        if ([1, 2, 3, 6, 19, 14, 15, 33, 24, 37, 38, 55, 59, 61, 63, 66].includes(rule)) {
          return data.split(", ");
        } else {
          return data;
        }
      }
    } else {
      if (data) {
        return data.split(", ");
      } else {
        return data;
      }
    }
  },
};
