<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <policy-table
            v-if="size !== 1 && !loading"
            :headers="headers"
            :data="data"
            :loading="tableLoading"
            :processing="processing"
            @change="loadData($event.page, $event.size)"
            @revoke="revoke"
          />
        </transition>
        <v-row v-if="tableLoading && size === 1" class="mt-0 mx-0">
          <v-col cols="12" class="text-center">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import PolicyTable from "./components/table";
import { revokeCall } from "@/api/monitor/revoke";
import { Errors } from "@/utils/error";
import { getPolicyCallData } from "@/api/interconnector/policy";

export default {
  components: {
    Topbar,
    PolicyTable,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  data() {
    return {
      loading: true,
      tableLoading: true,
      processing: false,

      headers: [
        { text: "Time", value: "time", sortable: false },
        { text: "Confirmed by", value: "confirmedBy", sortable: false },
        { text: "Call name", value: "callName", sortable: false },
        { text: "Target", value: "target", sortable: false },
        { text: "HTTP status", value: "httpStatus", sortable: false },
        { text: "Check status", value: "checkStatus", sortable: false },
        { text: "Response status", value: "responseStatus", sortable: false },
        { text: "Check response", value: "checkResponse", sortable: false },
        { text: "Response message", value: "responseMsg", sortable: false },
        { text: "Status", value: "status", sortable: false },
      ],
      data: [],
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.tableLoading = true;
          this.loadData();
        }
      },
    },
  },
  // created() {
  //   this.loadData();
  // },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "securityPolicy");
    },
    revoke() {
      this.processing = true;
      const data = {
        commit: false,
      };
      revokeCall(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Event call was successfully revoked!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.processing = false;
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.processing = false;
          this.loadData();
        });
    },
    loadData() {
      this.loading = false;
      this.resize("bigger");
      getPolicyCallData(this.id)
        .then((response) => {
          this.data = response.data;
          this.tableLoading = false;
        })
        .catch((e) => {
          this.tableLoading = false;
          Errors.show(e);
        });
    },
  },
};
</script>

<style lang="scss"></style>
