<template>
  <div class="circle-counter">
    <svg class="circle" :viewBox="viewBox">
      <circle
        class="border"
        :cx="cx"
        :cy="cy"
        :r="radius"
        :stroke-width="strokeWidth"
        :stroke-dasharray="dashArray"
      />
      <circle
        class="progress"
        :cx="cx"
        :cy="cy"
        :r="radius"
        :stroke-width="strokeWidth"
        :stroke-dasharray="dashArray"
        :stroke-dashoffset="dashOffset"
      />
    </svg>
    <div class="countdown">{{ countProgress }}</div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 10,
    },
    radius: {
      type: Number,
      default: 20,
    },
    strokeWidth: {
      type: Number,
      default: 4,
    },
  },
  watch: {
    count: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.countProgress = newValue;
        }
      },
    },
  },
  data() {
    return {
      countProgress: 0,
      viewBox: "0 0 100 100",
      cx: 50,
      cy: 50,
      dashArray: 2 * Math.PI * this.radius,
      dashOffset: 0,
      intervalId: null,
    };
  },
  mounted() {
    this.intervalId = setInterval(this.updateCountdown, 1000);
  },
  methods: {
    updateCountdown() {
      this.countProgress--;
      this.dashOffset = (1 - this.countProgress / this.count) * this.dashArray;
      if (this.countProgress === 0) {
        clearInterval(this.intervalId);
        this.$emit("action");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.circle-counter {
  position: relative;
  display: inline-block;
  width: 100%;
}
.circle {
  width: 100%;
  height: 100%;
}
.border {
  fill: none;
  stroke: #fff;
}
.progress {
  fill: none;
  stroke: #69be28;
  stroke-linecap: round;
  transform: rotate(-90deg);
  transform-origin: center;
  transition: stroke-dashoffset 1s linear;
}
.theme--dark {
  .border {
    stroke: #535452;
  }
}
.countdown {
  color: #69be28;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
}
</style>
