import request from "@/utils/request";

export function storeUser(data) {
  return request({
    url: "api/system/user",
    method: "post",
    data: data,
  });
}

export function updateUser(data, id) {
  return request({
    url: "api/system/user/" + id,
    method: "put",
    data: data,
  });
}

export function getUser(id) {
  return request({
    url: "api/system/user/" + id,
    method: "get",
  });
}

export function getUsers(page, size) {
  return request({
    url: "api/system/user?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function deleteUser(id) {
  return request({
    url: "api/system/user/" + id,
    method: "delete",
  });
}

export function getRoleList() {
  return request({
    url: "api/system/roleList",
    method: "get",
  });
}

export function getUserList() {
  return request({
    url: "api/system/userList",
    method: "get",
  });
}

// SETTINGS
export function setUserDarkmode(data) {
  return request({
    url: "api/user/config/darkmode",
    method: "post",
    data: data,
  });
}

export function setUserBackground(data) {
  return request({
    url: "api/user/config/background",
    method: "post",
    data: data,
  });
}

export function getUserTableSettings() {
  return request({
    url: "api/user/config/table",
    method: "get",
  });
}

export function setUserTableSettings(data) {
  return request({
    url: "api/user/config/table",
    method: "post",
    data: data,
  });
}

export function setDefaultUserTableSetting(data) {
  return request({
    url: "api/user/config/reset-column",
    method: "post",
    data: data,
  });
}
export function userInfo() {
  return request({
    url: "api/auth/userInfo",
    method: "get",
  });
}
