import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

import Layout from "@/layout/index";
import Login from "@/views/login";

import awaitingApprovalRoutes from "@/router/modules/awaitingApproval";
import interconnectorRoutes from "@/router/modules/interconnector";
import auditorRoutes from "@/router/modules/auditor";
import dataFeedsRoutes from "@/router/modules/dataFeeds";
import toolboxRoutes from "@/router/modules/toolbox";
import monitorRoutes from "@/router/modules/monitor";
import objectRoutes from "@/router/modules/objects";
import settingRoutes from "@/router/modules/settings";
import systemRoutes from "@/router/modules/system";

export const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      guest: true,
    },
    hidden: true,
  },
  {
    path: "/reset-password",
    meta: {
      guest: true,
    },
    component: () => import("@/views/reset-password/index"),
    hidden: true,
  },
  // { path: '/home', redirect: '/dashboard' },
  {
    path: "/",
    name: "dashboard",
    component: Layout,
    meta: {
      requiresAuth: true,
      icon: "home.svg",
    },
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        component: () => import("@/views/dashboard/index"),
        name: "dashboard",
        meta: { active: ["dashboard"] },
        hidden: true,
      },
    ],
  },
  awaitingApprovalRoutes,
  interconnectorRoutes,
  auditorRoutes,
  dataFeedsRoutes,
  {
    path: "/data_feeds/url_lists/custom/show/:id",
    component: () => import("@/views/dataFeeds/showList"),
    name: "dataFeedsCustomShow",
    meta: {
      guest: true,
    },
    hidden: true,
  },
  {
    path: "/data_feeds/url_lists/predefined/show/:id",
    component: () => import("@/views/dataFeeds/showList"),
    name: "dataFeedsPredefinedShow",
    meta: {
      guest: true,
    },
    hidden: true,
  },
  {
    path: "/look_up",
    name: "lookUp",
    component: Layout,
    meta: {
      requiresAuth: true,
      icon: "lookup.svg",
      permission: ["view menu lookup"],
    },
    redirect: "/look_up/show",
    children: [
      {
        path: "show",
        component: () => import("@/views/lookUp/show"),
        name: "lookUp",
        meta: { active: ["lookUp"], permission: ["view lookup"] },
        hidden: true,
      },
    ],
  },
  toolboxRoutes,
  monitorRoutes,
  objectRoutes,
  settingRoutes,
  systemRoutes,
];

const createRouter = () =>
  new Router({
    mode: "history",
    scrollBehavior: () => ({ y: 0 }),
    // base: process.env.MIX_LARAVUE_PATH,
    routes: routes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;
