import request from "@/utils/request";

export function getPolicy(page, size) {
  return request({
    url: "api/interconnector/securitypolicy?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function getPolicyCallData(id) {
  return request({
    url: "api/interconnector/securitypolicy/" + id + "/calldata",
    method: "get",
  });
}
