<template>
  <v-card width="600">
    <v-card-title class="py-1 pr-2 font-ubuntu-bold text-uppercase">
      <v-row>
        <v-col cols="6"> Initial setup </v-col>
        <v-col cols="6" class="text-right">
          <v-btn icon small @click="close">
            <v-avatar size="15">
              <img
                :src="
                  $vuetify.theme.dark
                    ? location + '/images/icons/small/close-white.png'
                    : location + '/images/icons/small/close.png'
                "
                alt="Times"
              />
            </v-avatar>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text
      class="font-size-12 pt-1 pb-0 px-0 font-roboto-condensed-regular"
    >
      <v-stepper alt-labels v-model="step">
        <v-stepper-header>
          <v-stepper-step step="1">System</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="2">Mail server</v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">SSL</v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </v-card-text>
    <v-card-text class="px-5">
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-form
          v-if="systemForm"
          ref="systemForm"
          v-model="validSystemForm"
          lazy-validation
        >
          <!--APPLICATION URL-->
          <v-row>
            <v-col cols="12" class="px-5">
              <label class="font-roboto-condensed-regular font-size-15"
                >Application url</label
              >
              <v-text-field
                v-model="appUrl"
                :rules="appUrlRules"
                label="Application url"
                placeholder="Application url"
                dense
                solo
              />
            </v-col>
          </v-row>
          <!--END APPLICATION URL-->
        </v-form>
      </transition>

      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-form
          v-if="mailForm"
          ref="mailForm"
          v-model="validMailForm"
          lazy-validation
        >
          <!--SMTP SERVER-->
          <v-row>
            <v-col cols="12" class="px-5 py-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >SMTP server</label
              >
              <v-text-field
                v-model="smtp"
                :rules="[smtpRules.required, mailServerRulesValidate]"
                label="SMTP server"
                placeholder="SMTP server"
                dense
                solo
              />
            </v-col>
          </v-row>
          <!--END SMTP SERVER-->

          <!--LOGIN-->
          <v-row>
            <v-col cols="12" class="px-5 py-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Login</label
              >
              <v-text-field
                v-model="login"
                :rules="loginRules"
                label="Login"
                placeholder="Login"
                dense
                solo
              />
            </v-col>
          </v-row>
          <!--END LOGIN-->

          <!--PASSWORD-->
          <v-row>
            <v-col cols="12" class="px-5 py-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Password</label
              >
              <v-text-field
                v-model="password"
                type="password"
                label="Password"
                placeholder="Password"
                dense
                solo
              />
            </v-col>
          </v-row>
          <!--END PASSWORD-->

          <!--FROM EMAIL-->
          <v-row>
            <v-col cols="12" class="px-5 py-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >FROM Email address</label
              >
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="FROM Email address"
                placeholder="FROM Email address"
                dense
                solo
              />
            </v-col>
          </v-row>
          <!--END FROM EMAIL-->

          <!--PORT-->
          <v-row>
            <v-col cols="12" class="px-5 py-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Port</label
              >
              <v-text-field
                v-model="port"
                :rules="[portRules.required, portValidate]"
                label="Port"
                placeholder="Port"
                dense
                solo
              />
            </v-col>
          </v-row>
          <!--END PORT-->

          <!--MAIL ENCRYPTION-->
          <v-row>
            <v-col cols="12" class="px-5 pt-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Mail encryption</label
              >
              <v-autocomplete
                v-model="mailEncryption"
                :items="mailEncryptions"
                item-value="id"
                item-text="name"
                label="Mail encryption"
                placeholder="Mail encryption"
                dense
                solo
              />
            </v-col>
          </v-row>
          <!--END MAIL ENCRYPTION-->
        </v-form>
      </transition>

      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-form
          v-if="sslForm"
          ref="sslForm"
          v-model="validSslForm"
          lazy-validation
        >
          <v-row>
            <v-col cols="6" class="px-5 pt-0 pb-5">
              <v-switch
                v-model="withSsl"
                label="With SSL?"
                color="good-3"
                hide-details
              />
            </v-col>
          </v-row>

          <!--DOMAIN ADDRESS-->
          <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="d-none"
          >
            <v-row v-if="withSsl">
              <v-col cols="12" class="px-5 py-0">
                <label class="font-roboto-condensed-regular font-size-15"
                  >Domain address</label
                >
                <v-text-field
                  v-model="domainAddress"
                  :rules="domainAddressRules"
                  label="Domain address"
                  placeholder="Domain address"
                  dense
                  solo
                />
              </v-col>
            </v-row>
          </transition>
          <!--END DOMAIN ADDRESS-->

          <!--SSL TYPE-->
          <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="d-none"
          >
            <v-row v-if="withSsl">
              <v-col cols="12" class="px-5 pt-0">
                <v-radio-group v-model="sslType" row hide-details>
                  <v-radio label="Certbot" value="certbot" />
                  <v-radio label="Own-cert" value="own" />
                </v-radio-group>
              </v-col>
            </v-row>
          </transition>
          <!--END SSL TYPE-->

          <!--SSL TYPE-->
          <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="d-none"
          >
            <v-row v-if="withSsl && sslType === 'own'">
              <v-col cols="12" class="px-5 py-0">
                <v-file-input
                  v-model="crtFile"
                  :rules="[crtFileRules.required, crtFileRules.size]"
                  accept=".crt"
                  placeholder="Choose .crt file"
                  dense
                  solo
                />
              </v-col>
            </v-row>
          </transition>
          <!--END SSL TYPE-->

          <!--SSL TYPE-->
          <transition
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="d-none"
          >
            <v-row v-if="withSsl && sslType === 'own'">
              <v-col cols="12" class="px-5 pt-0">
                <v-file-input
                  v-model="keyFile"
                  :rules="[keyFileRules.required, keyFileRules.size]"
                  accept=".key"
                  placeholder="Choose .key file"
                  dense
                  solo
                />
              </v-col>
            </v-row>
          </transition>
          <!--END SSL TYPE-->
        </v-form>
      </transition>
    </v-card-text>

    <v-card-actions class="pt-0 pb-5">
      <v-row class="mx-0">
        <v-col cols="6" class="pl-5 pt-0">
          <v-btn
            v-if="mailForm || sslForm"
            small
            color="primary"
            class="text-capitalize"
            @click="validate(false)"
          >
            Back
          </v-btn>
        </v-col>
        <v-col cols="6" class="pr-5 text-right pt-0">
          <v-btn
            v-if="systemForm || mailForm"
            small
            color="primary"
            class="text-capitalize"
            @click="validate(true)"
          >
            Next
          </v-btn>
          <v-icon v-if="saving" color="primary">
            fas fa-spinner fa-spin
          </v-icon>
          <span
            v-if="saving"
            class="font-roboto-condensed-light font-size-15 ml-2"
          >
            Processing...
          </span>
          <v-btn
            v-if="sslForm && !saving"
            small
            color="primary"
            class="text-capitalize"
            @click="validate(true)"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Validation } from "@/utils/validation";
import { storeInitialSetup } from "@/api/system/synapsa";
import { Errors } from "@/utils/error";

export default {
  props: {
    location: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      step: 1,
      saving: false,

      validSystemForm: true,
      validMailForm: true,
      validSslForm: true,

      systemForm: true,
      mailForm: false,
      sslForm: false,

      appUrl: "",
      appUrlRules: [(v) => !!v || "Application url is required"],
      smtp: "",
      smtpRules: {
        required: (v) => !!v || "SMTP is required",
      },
      login: "",
      loginRules: [(v) => !!v || "Login is required"],
      password: "",
      email: "",
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
      ],
      port: "",
      portRules: {
        required: (v) => !!v || "Port is required",
      },
      mailEncryption: "",
      mailEncryptions: [
        { id: "", name: "None" },
        { id: "ssl", name: "SSL" },
        { id: "tls", name: "TLS" },
        { id: "starttls", name: "StartTLS" },
      ],
      withSsl: false,
      domainAddress: "",
      domainAddressRules: [(v) => !!v || "Domain address is required"],
      sslType: "certbot",

      crtFile: null,
      crtFileRules: {
        required: (v) => !!v || "CRT file is required",
        size: (v) =>
          !v || v.size < 2000000 || "CRT file size should be less than 2 MB!",
      },
      keyFile: null,
      keyFileRules: {
        required: (v) => !!v || "KEY file is required",
        size: (v) =>
          !v || v.size < 2000000 || "KEY file size should be less than 2 MB!",
      },
    };
  },
  computed: {
    initialSetup: {
      get() {
        return this.$store.state.settings.initialSetup;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "initialSetup",
          value: val,
        });
      },
    },
  },
  methods: {
    close() {
      this.initialSetup = false;
    },
    validate(next) {
      if (this.systemForm && this.$refs.systemForm.validate()) {
        this.systemForm = false;
        this.step += 1;
        this.mailForm = true;
      } else if (this.mailForm && this.$refs.mailForm.validate()) {
        this.mailForm = false;
        if (next) {
          this.step += 1;
          this.sslForm = true;
        } else {
          this.step -= 1;
          this.systemForm = true;
        }
      } else if (this.sslForm && this.$refs.sslForm.validate()) {
        if (next) {
          this.store();
        } else {
          this.step -= 1;
          this.sslForm = false;
          this.mailForm = true;
        }
      }
    },
    portValidate(port) {
      return Validation.port(port);
    },
    serverValidate(server) {
      return Validation.server(server);
    },
    store() {
      this.saving = true;
      if (!this.withSsl) {
        this.sslType = "-";
      }
      let formData = new FormData();
      formData.append("application_url", this.appUrl);
      formData.append("mail_server", this.smtp);
      formData.append("mail_login", this.login);
      formData.append("mail_password", this.password);
      formData.append("mail_from_address", this.email);
      formData.append("mail_port", this.port);
      formData.append("mail_encryption", this.mailEncryption);
      formData.append("ssl_enable", this.withSsl);
      formData.append("ssl_domain", this.domainAddress);
      formData.append("ssl_type", this.sslType);
      formData.append("crt", this.crtFile);
      formData.append("key", this.keyFile);

      const domain = this.domainAddress;
      storeInitialSetup(formData)
        .then(() => {
          this.$toast.open({
            message: "Initial setup was succesfully completed!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          if (domain) {
            this.$toast.open({
              message: "The page will be refreshed to complete the setup.",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            setTimeout(() => {
              window.location = "http://" + domain + "/system/show/ssl";
            }, 2000);
          }
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    mailServerRulesValidate(val) {
      let domain = Validation.domain(val);
      let ip = Validation.ip(val);
      if (domain === true) {
        return true;
      } else if (ip === true) {
        return true;
      } else {
        return "Value must be IP or FQDN";
      }
    },
  },
};
</script>
