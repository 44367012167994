<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :keywordsResize2="keywordsResize2"
      @resize="resize($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{
                id === 0
                  ? "Add new Custom Domain List"
                  : "Edit Custom Domain List"
              }}
            </h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--ADDRESS-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Title</label
                  >
                  <v-text-field
                    v-model="title"
                    :rules="[titleRules.required]"
                    label="Title"
                    placeholder="Title"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END ADDRESS-->

              <!--LIFETIME-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Lifetime</label
                  >
                  <h6>0 (zero) for disable lifetime</h6>
                  <v-text-field
                    v-model="lifetime"
                    :rules="lifetimeRules"
                    label="0"
                    placeholder="0"
                    type="number"
                    min="0"
                    max="168"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END LIFETIME-->

              <v-row class="mt-1 mx-0">
                <v-col cols="12" class="pb-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import {
  getDomainList,
  storeDomainList,
  updateDomainList,
} from "@/api/datafeeds/domainlist/custom";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
    keywordsResize2: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,

      valid: true,
      validCheck: true,

      saving: false,

      title: "",
      titleRules: {
        required: (v) => !!v || "Title is required",
      },
      lifetime: 24,
      lifetimeRules: [
        (v) => !!v || "Lifetime is required",
        (v) => parseInt(v, 10) <= 168 || "Max 168",
        (v) => parseInt(v, 10) >= 0 || "Min 0",
      ],
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (newValue === 0) {
          this.title = "";
          this.lifetime = 24;
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  // mounted() {
  //   if (this.id === 0) {
  //     this.loading = false;
  //   } else {
  //     this.loadById();
  //   }
  // },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "customdomainlist");
    },
    loadById() {
      getDomainList(this.id)
        .then((response) => {
          const domain = response.data;
          this.title = domain.title;
          this.lifetime = domain.lifetime;
          this.checkValid = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    validateCheck() {
      this.$refs.formCheck.validate();
      if (this.$refs.formCheck.validate()) {
        this.check();
      }
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    store() {
      this.saving = true;
      const data = {
        title: this.title,
        lifetime: this.lifetime,
      };
      storeDomainList(data)
        .then(() => {
          this.$toast.open({
            message: "Domain List was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    update() {
      this.saving = true;
      const data = {
        id: this.id,
        title: this.title,
        lifetime: this.lifetime,
      };
      updateDomainList(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Domain List was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
