import Layout from "@/layout";

const objectRoutes = {
  path: "/objects",
  name: "objects",
  component: Layout,
  meta: {
    requiresAuth: true,
    icon: "objects.svg",
    permission: ["view menu objects"],
  },
  redirect: "/objects/show/emails",
  children: [
    {
      path: "show",
      component: () => import("@/views/objects/index"),
      name: "show",
      meta: { active: ["objects", "emails"] },
      hidden: true,
      children: [
        {
          path: "emails",
          component: () => import("@/views/objects/emails"),
          name: "emails",
          meta: {
            active: ["objects", "emails"],
            permission: ["view objectemail"],
          },
          hidden: true,
        },
        {
          path: "syslogs",
          component: () => import("@/views/objects/syslog"),
          name: "syslog",
          meta: {
            active: ["objects", "syslog"],
            permission: ["view objectsyslog"],
          },
          hidden: true,
        },
        {
          path: "notification_profiles",
          component: () => import("@/views/objects/notification_profiles"),
          name: "notificationProfiles",
          meta: {
            active: ["objects", "notification_profiles"],
            permission: ["view objectprofile"],
          },
          hidden: true,
        },
        {
          path: "remote_servers",
          component: () => import("@/views/objects/remote_servers"),
          name: "remoteServers",
          meta: {
            active: ["objects", "remote_servers"],
            permission: ["view objectremoteserver"],
          },
          hidden: true,
        },
        {
          path: "address",
          component: () => import("@/views/objects/address"),
          name: "address",
          meta: {
            active: ["objects", "address"],
            permission: ["view objectaddress"],
          },
          hidden: true,
        },
      ],
    },
  ],
};

export default objectRoutes;
