import request from "@/utils/request";

export function getVersion() {
  return request({
    url: "api/system/version",
    method: "get",
  });
}

export function getLocalVersion() {
  return request({
    url: "api/system/localversion",
    method: "get",
  });
}

export function hardDisableMainternance() {
  return request({
    url: "api/system/harddisablemainternance",
    method: "get",
  });
}

export function update(data) {
  return request({
    url: "api/system/update",
    method: "post",
    data: data,
    timeout: 1800000,
  });
}
