<template>
  <v-dialog
    v-model="visible"
    width="700"
    content-class="my-dialog"
  >
    <v-card color="primary" v-if="loading && pagination.current_page === 1">
      <v-card-text>
        Please stand by
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
    <v-container v-else class="diff-wrapper">
      <v-row>
        <v-col cols="12">
          <h3 class="bad-2" v-if="alerts">{{ alerts }} alerts would be generated!</h3>
          <h3 class="primary" v-if="!alerts">Alerts would not be found</h3>
        </v-col>
        <v-col cols="12">
          <div class="response">
            <p ><small v-html="output" /></p>
            <v-btn
              v-if="pagination.last_page > pagination.current_page"
              rounded
              :disabled="loading"
              outlined
              color="primary"
              class="text-capitalize load-more-btn"
              @click="loadMore"
            >
              Load more
            </v-btn>
            <v-icon color="primary" v-if="loading" class="ml-1">
              fas fa-spinner fa-spin
            </v-icon>
            <small class="neutral-4--text ml-1" v-else-if="pagination.last_page">
                Loaded: {{ pagination.current_page }} / {{ pagination.last_page }}
            </small>
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="white"
              class="mb-0"
            />
          </div>
        </v-col>
        <v-col cols="12">
          <v-btn
              rounded
              color="primary"
              class="text-capitalize bottom-btn"
              @click="onClose"
          >
            Close
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import { getFormattedXml } from "@/utils/auditor";
import { Errors } from "@/utils/error";
import { testAuditorRuleset } from "@/api/auditor/ruleset";

export default {
  name: "test",
  props: {
    id: {
      type: Number,
      default: function () {
        return undefined;
      },
    },
    show: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    auditorData: {
      type: Object,
      default: function () {
        return undefined;
      }
    }
  },
  watch: {
    show: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.visible = newValue;
        if (newValue) {
          this.output = '';
          this.alerts = 0;
          this.pagination = {
            total: undefined,
            size: 1000,
            current_page: 1,
            last_page: undefined,
            from: undefined,
            to: undefined
          };
          this.testById();
        }
      },
    },
    visible: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) {
          this.onClose();
        }
      },
    },
  },
  data() {
    return {
      visible: false,
      loading: true,
      output: '',
      alerts: 0,
      pagination: {
        total: undefined,
        size: 1000,
        current_page: 1,
        last_page: undefined,
        from: undefined,
        to: undefined
      }
    }
  },
  methods: {
    testById() {
      this.loading = true;
      testAuditorRuleset(this.id, {...this.auditorData, page: this.pagination.current_page, size: this.pagination.size})
        .then((response) => {
          this.output += response.data;
          this.alerts = response.alerts;
          this.pagination = response.pagination;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.onClose();
          Errors.show(e);
        });
    },
    loadMore() {
      if (this.pagination.last_page > this.pagination.current_page) {
        this.pagination.current_page++;
        this.testById();
      }
    },
    formattedXml(xml) {
      return getFormattedXml(xml);
    },
    onClose() {
      this.$emit("onClose");
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  padding: .5rem;
  border-radius: .4rem;
}
.theme--dark {
  .diff-wrapper {
    background-color: #1e1e1e;
    box-shadow: 13px 13px 30px rgb(0 0 0 / 30%);
  }
  .response {
    padding: 0 .5rem;
    max-height: 500px;
    overflow-y: scroll;
    border: .5px solid #ffffff;
    border-radius: .4rem;

    .load-more-btn {
      margin: .5rem;
    }
  }
}
.theme--light {
  .diff-wrapper {
    background-color: #ffffff;
    box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
  }

  .response {
    padding: 0 .5rem;
    max-height: 500px;
    overflow-y: scroll;
    border: .5px solid #313231;
    border-radius: .4rem;

    .load-more-btn {
      margin: .5rem;
    }
  }
  .raw-content {
    border: .5px solid #1e1e1e;
  }
}
.diff-wrapper {
  .diff-area {
    padding-bottom: .1em;
    color: #008;
    background-color: #ffffff;
    box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
    margin: 0;
  }
  .raw-content {
    border-radius: .5rem;
    border-color: red;
    padding: 1rem;
    margin-top: .5rem;
  }
  .bottom-btn {
    float: right;
  }
}
</style>
<style>
.my-dialog {
    overflow-y: visible;
  }
</style>
