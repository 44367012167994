import Layout from "@/layout";

const toolboxRoutes = {
  path: "/toolbox",
  name: "toolbox",
  component: Layout,
  meta: {
    requiresAuth: true,
    title: "Toolbox",
    icon: "toolbox.svg",
    permission: ["view menu toolbox"],
  },
  redirect: "/toolbox/policy_tester",
  children: [
    {
      path: "threat_simulator",
      component: () => import("@/views/toolbox/threatSimulator"),
      name: "threatSimulator",
      meta: {
        active: ["toolbox", "threat_simulator"],
        icon: "threat-simulator.svg",
        permission: ["view threatsimulator"],
      },
    },
    {
      path: "policy_tester",
      component: () => import("@/views/toolbox/policyTester"),
      name: "policyTester",
      meta: {
        active: ["toolbox", "policy_tester"],
        icon: "policy-tester.svg",
        permission: ["view policytester"],
      },
    },
  ],
};

export default toolboxRoutes;
