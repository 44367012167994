import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        default: "#535452",
        primary: "#69be28",
        secondary: "#fff",
        tertiary: "#313231",
        white: "#fff",
        grey: "#313231",
        green: "#44f666",
        lightRed: "#ff816c",

        //synapsa colours
        "neutral-1": "#e2e3e5",
        "neutral-2": "#d6dadb",
        "neutral-3": "#ccd0d3",
        "neutral-4": "#9fa3a6",
        "neutral-5": "#808485",
        "good-1": "#a6dc86",
        "good-2": "#87d05a",
        "good-3": "#64c52a",
        "good-4": "#4e9a1e",
        "good-5": "#3b7c16",
        "medium-1": "#ffe39a",
        "medium-2": "#ffd976",
        "medium-3": "#ffd04e",
        "medium-4": "#cca23c",
        "medium-5": "#a3832c",
        "high-1": "#fd981a",
        "bad-1": "#f28d9f",
        "bad-2": "#eb637b",
        "bad-3": "#e53355",
        "bad-4": "#b22641",
        "bad-5": "#911d34",
        "info-1-1": "#85e5e3",
        "info-1-2": "#5cdcda",
        "info-1-3": "#33d3d1",
        "info-1-4": "#27a19f",
        "info-1-5": "#1f7f7d",
        "info-2-1": "#85bbe5",
        "info-2-2": "#5ca4dc",
        "info-2-3": "#338dd3",
        "info-2-4": "#276ba1",
        "info-2-5": "#1f557f",
        "info-3-1": "#da85e5",
        "info-3-2": "#ce5cdc",
        "info-3-3": "#c233d3",
        "info-3-4": "#9427a1",
        "info-3-5": "#741f7f",
      },
      dark: {
        default: "#fff",
        primary: "#69be28",
        secondary: "#313231",
        tertiary: "#fff",
        grey: "#c5c5c5",
        white: "#fff",
        green: "#44f666",
        lightRed: "#ff816c",

        //synapsa colours
        "neutral-1": "#e2e3e5",
        "neutral-2": "#d6dadb",
        "neutral-3": "#ccd0d3",
        "neutral-4": "#9fa3a6",
        "neutral-5": "#808485",
        "good-1": "#a6dc86",
        "good-2": "#87d05a",
        "good-3": "#64c52a",
        "good-4": "#4e9a1e",
        "good-5": "#3b7c16",
        "medium-1": "#ffe39a",
        "medium-2": "#ffd976",
        "medium-3": "#ffd04e",
        "medium-4": "#cca23c",
        "medium-5": "#a3832c",
        "high-1": "#fd981a",
        "bad-1": "#f28d9f",
        "bad-2": "#eb637b",
        "bad-3": "#e53355",
        "bad-4": "#b22641",
        "bad-5": "#911d34",
        "info-1-1": "#85e5e3",
        "info-1-2": "#5cdcda",
        "info-1-3": "#33d3d1",
        "info-1-4": "#27a19f",
        "info-1-5": "#1f7f7d",
        "info-2-1": "#85bbe5",
        "info-2-2": "#5ca4dc",
        "info-2-3": "#338dd3",
        "info-2-4": "#276ba1",
        "info-2-5": "#1f557f",
        "info-3-1": "#da85e5",
        "info-3-2": "#ce5cdc",
        "info-3-3": "#c233d3",
        "info-3-4": "#9427a1",
        "info-3-5": "#741f7f",
      },
    },
  },
};

export default new Vuetify(opts);
