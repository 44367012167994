import request from "@/utils/request";

export function getServers(page, size) {
  return request({
    url: "api/object/servers?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function getServerList() {
  return request({
    url: "api/object/serverlist",
    method: "get",
  });
}

export function getServer(id) {
  return request({
    url: "api/object/server/" + id,
    method: "get",
  });
}

export function testServer(data) {
  return request({
    url: "api/object/server/connection_test",
    method: "post",
    data: data,
  });
}

export function storeServer(data) {
  return request({
    url: "api/object/server/store",
    method: "post",
    data: data,
  });
}

export function updateServer(data, id) {
  return request({
    url: "api/object/server/update/" + id,
    method: "put",
    data: data,
  });
}

export function deleteServer(id) {
  return request({
    url: "api/object/server/delete/" + id,
    method: "delete",
  });
}
