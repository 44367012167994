<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="py-5 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new rule" : "Edit rule" }}
            </h3>
          </v-col>

          <v-col cols="12" class="pt-0 pb-2 pl-6">
            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'general'"
              :color="activeTab === 'general' ? 'primary' : ''"
              :outlined="activeTab === 'general'"
              @click="activeTab = 'general'"
            >
              <v-badge
                v-if="!validGeneral"
                color="error"
                icon="fas fa-exclamation"
              >
                General
              </v-badge>
              <span v-else>General</span>
            </v-btn>

            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'threats'"
              :color="activeTab === 'threats' ? 'primary' : ''"
              :outlined="activeTab === 'threats'"
              @click="activeTab = 'threats'"
            >
              <v-badge
                v-if="!validThreats"
                color="error"
                icon="fas fa-exclamation"
              >
                Threats
              </v-badge>
              <span v-else>Threats</span>
            </v-btn>

            <v-btn
              class="text-capitalize synapsa-tab"
              :text="activeTab !== 'api'"
              :color="activeTab === 'api' ? 'primary' : ''"
              :outlined="activeTab === 'api'"
              @click="activeTab = 'api'"
            >
              <v-badge v-if="!validApi" color="error" icon="fas fa-exclamation">
                API Calls
              </v-badge>
              <span v-else>API Calls</span>
            </v-btn>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <general
                  v-if="activeTab === 'general'"
                  :mount-data="general"
                  :miners="miners"
                  :ip-lists="ipLists"
                  @update="
                    $event.miner !== general.miner ? (threats.tags = []) : null;
                    general = $event;
                  "
                />
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <threats
                  v-if="activeTab === 'threats'"
                  :mount-data="threats"
                  :miner-id="general.miner ? general.miner : 0"
                  :ads="general.ads ? general.ads : false"
                  :size="size"
                  @update="threats = $event"
                />
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <api-calls
                  v-if="activeTab === 'api'"
                  :id="id"
                  :mount-data="api"
                  :firewalls="firewalls"
                  @update="api = $event"
                />
              </transition>
            </v-form>

            <v-row class="mt-4 mx-0">
              <v-col cols="12" class="py-0">
                <v-icon v-if="saving" color="primary">
                  fas fa-spinner fa-spin
                </v-icon>
                <span
                  v-if="saving"
                  class="font-roboto-condensed-light font-size-15 ml-2"
                >
                  Processing...
                </span>
                <v-btn
                  v-if="!saving"
                  color="primary"
                  class="text-capitalize"
                  @click="validate"
                >
                  {{ id === 0 ? "Create" : "Update" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import general from "./general";
import threats from "./threats";
import apiCalls from "./apiCalls";
import { getMinersList } from "@/api/settings/miner";

import { Errors } from "@/utils/error";
import { Validation } from "./validation";
import { getAddresslistsList } from "@/api/datafeeds/addresslist/custom";
import { getFirewallList } from "@/api/settings/firewall";
import { getFirewallGroupsList } from "@/api/settings/firewallGroup";
import { getRule, storeRule, updateRule } from "@/api/interconnector/rule";
import { getApiCallsByFw } from "@/api/settings/apicall";
import { getCustomApiList } from "@/api/settings/apicallcustom";
export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
    general,
    threats,
    apiCalls,
  },
  data() {
    return {
      activeTab: "general",

      valid: true,
      validGeneral: true,
      validThreats: true,
      validApi: true,
      loading: true,
      saving: false,

      general: {
        name: "",
        ads: false,
        miner: "",
        mode: "",
        description: "",
        state: true,
        fillList: false,
        sourceKey: null,
        destinationKey: null,
        ipList: "",
        allowFrom: false,
        allowList: "",
        denyFrom: false,
        denyList: "",
        firstRender: true,
      },
      threats: {
        tags: [],
        ignoreNoCn2: null,
        cn2: "*",
        ignoreNoCn3: null,
        cn3: "*",
      },
      loadingApi: true,
      api: {
        fwUnit: "firewall",
        firewall: "",
        firewallGroup: "",
        policy: [],
        customCalls: [],
        rollback: [],
        vendor: null,
        membertimeout: null,
      },

      miners: [],
      ipLists: [],
      firewalls: [],
    };
  },
  watch: {
    activeTab: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.validateTabs(newValue);
      },
    },
    valid: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (this.activeTab === "general") {
          this.validGeneral = newValue;
        } else if (this.activeTab === "threats") {
          this.validThreats = newValue;
        } else if (this.activeTab === "api") {
          this.validApi = newValue;
        }
      },
    },
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        this.loadIPLists();
        this.loadFirewalls();
        this.loadFirewallGroups();
        this.activeTab = "general";
        if (newValue === 0) {
          this.validGeneral = true;
          this.validThreats = true;
          this.validApi = true;
          this.loadMiners();
          this.general = {
            name: "",
            ads: false,
            miner: "",
            mode: "",
            description: "",
            state: true,
            fillList: false,
            ipList: "",
            sourceKey: null,
            destinationKey: null,
            allowFrom: false,
            allowList: "",
            denyFrom: false,
            denyList: "",
            firstRender: true,
          };
          this.threats = {
            tags: [],
            ignoreNoCn2: null,
            cn2: "*",
            ignoreNoCn3: null,
            cn3: "*",
          };
          this.api = {
            fwUnit: "firewall",
            firewall: "",
            firewallGroup: "",
            policy: [],
            customCalls: [],
            rollback: [],
            vendor: null,
            membertimeout: null,
          };
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "rule");
    },
    loadById() {
      this.validGeneral = true;
      this.validThreats = true;
      this.validApi = true;
      getRule(this.id)
        .then((response) => {
          const rule = response.data;
          //general
          this.general.name = rule.name;
          this.general.miner = rule.miner.id;
          this.general.mode = rule.mode;
          this.general.description = rule.description;
          this.general.state = rule.active ? true : null;
          this.general.fillList = rule.fill_ip_list ? true : null;
          this.general.ipList =
            rule.ip_list && rule.ip_list.id ? rule.ip_list.id : "";
          this.general.allowFrom = rule.allow_from;
          this.general.denyFrom = rule.deny_from;
          this.general.allowList =
            rule.allow_list && rule.allow_list.id ? rule.allow_list.id : "";
          this.general.denyList =
            rule.deny_list && rule.deny_list.id ? rule.deny_list.id : "";
          this.general.firstRender = true;
          this.general.ads = rule.custom_parser !== true;
          this.general.sourceKey = rule.source_key;
          this.general.destinationKey = rule.destination_key;
          //threats
          this.threats.tags = rule.threats;
          this.threats.ignoreNoCn2 = rule.ignore_no_cn2 ? true : null;
          this.threats.cn2 = rule.cn2 ? rule.cn2 : "";
          this.threats.ignoreNoCn3 = rule.ignore_no_cn3 ? true : null;
          this.threats.cn3 = rule.cn3 ? rule.cn3 : "";
          this.api.vendor = rule.vendor ? rule.vendor : "";
          this.api.membertimeout = rule.member_timeout
            ? rule.member_timeout
            : null;

          this.loadMiners();
          this.loading = false;
          //api
          if (rule.firewall || rule.firewallGroup) {
            this.api.fwUnit =
              rule.firewallGroup && rule.firewallGroup.id
                ? "group"
                : "firewall";
          } else {
            this.api.fwUnit = "custom";
          }

          this.api.firewall =
            rule.firewall && rule.firewall.id ? rule.firewall.id : "";
          this.api.firewallGroup =
            rule.firewallGroup && rule.firewallGroup.id
              ? rule.firewallGroup.id
              : "";
          if (this.id && this.api.firewall) {
            this.loadApiCalls(rule.policy_call, rule.rollback_call);
          }
          if (this.id && rule.vendor === 0) {
            this.loadCustomApiCalls(rule.policy_call);
          }
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    loadMiners() {
      getMinersList()
        .then((response) => {
          this.miners = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadIPLists() {
      getAddresslistsList()
        .then((response) => {
          this.ipLists = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadFirewalls() {
      getFirewallList()
        .then((response) => {
          this.firewalls = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadFirewallGroups() {
      getFirewallGroupsList()
        .then((response) => {
          this.firewallGroups = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadApiCalls(policyCalls, rollbackCalls) {
      this.loadingApi = true;
      const data = {
        type: this.api.fwUnit === "firewall" ? "firewall" : "firewallgroup",
        id:
          this.api.fwUnit === "firewall"
            ? this.api.firewall
            : this.api.firewallGroup,
      };
      getApiCallsByFw(data)
        .then((response) => {
          this.api.policy = [];
          this.api.rollback = [];
          const self = this;
          policyCalls.forEach(function (policyCall) {
            response.data.forEach(function (call) {
              if (policyCall === call.id) {
                self.api.policy.push({
                  id: call.id,
                  name: call.name,
                  chosen: true,
                });
              }
            });
          });
          response.data.forEach(function (call) {
            if (!policyCalls.includes(call.id)) {
              self.api.policy.push({
                id: call.id,
                name: call.name,
                chosen: false,
              });
            }
          });
          rollbackCalls.forEach(function (rollbackCall) {
            response.data.forEach(function (call) {
              if (rollbackCall === call.id) {
                self.api.rollback.push({
                  id: call.id,
                  name: call.name,
                  chosen: true,
                });
              }
            });
          });
          response.data.forEach(function (call) {
            if (!rollbackCalls.includes(call.id)) {
              self.api.rollback.push({
                id: call.id,
                name: call.name,
                chosen: false,
              });
            }
          });
          this.loadingApi = false;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadCustomApiCalls(policyCalls) {
      this.loadingApi = true;
      getCustomApiList()
        .then((response) => {
          this.api.fwUnit = "custom";
          this.api.customCalls = [];
          const self = this;
          policyCalls.forEach(function (policyCall) {
            response.data.forEach(function (call) {
              if (policyCall === call.id) {
                self.api.customCalls.push({
                  id: call.id,
                  name: call.name,
                  chosen: true,
                });
              }
            });
          });
          response.data.forEach(function (call) {
            if (!policyCalls.includes(call.id)) {
              self.api.customCalls.push({
                id: call.id,
                name: call.name,
                chosen: false,
              });
            }
          });
          this.loadingApi = false;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    validate() {
      this.$refs.form.validate();
      this.validateTabs();
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    validateTabs(tab) {
      if (tab) {
        if (tab === "general") {
          //TODO
        } else if (tab === "threats") {
          this.validGeneral = !!Validation.general(this.general);
        } else if (tab === "api") {
          this.validGeneral = !!Validation.general(this.general);
        }
      }
      if (this.activeTab === "general" && !this.validGeneral) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      } else if (this.activeTab === "threats" && !this.validThreats) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      } else if (this.activeTab === "api" && !this.validApi) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      }
    },
    changeThreats(data) {
      this.threats.ignoreNoCn2 = data.ignoreNoCn2;
      this.threats.cn2 = data.cn2;
      this.threats.ignoreNoCn3 = data.ignoreNoCn3;
      this.threats.cn3 = data.cn3;
    },
    changeTags(tag) {
      if (this.threats.tags.includes(tag.id)) {
        const index = this.threats.tags.indexOf(tag.id);
        this.threats.tags.splice(index, 1);
      } else {
        this.threats.tags.push(tag.id);
      }
    },
    save() {
      this.saving = true;
      const policyCalls = [];
      this.api.policy.forEach(function (call) {
        if (call.chosen) {
          policyCalls.push(call.id);
        }
      });
      const rollbackCalls = [];
      this.api.rollback.forEach(function (call) {
        if (call.chosen) {
          rollbackCalls.push(call.id);
        }
      });
      const customCalls = [];
      this.api.customCalls.forEach(function (call) {
        if (call.chosen) {
          customCalls.push(call.id);
        }
      });

      const data = {
        name: this.general.name,
        description: this.general.description,
        member_timeout: this.api.membertimeout,
        vendor: this.api.vendor,
        mode: this.general.mode,
        miner_id: this.general.miner,
        parsertag: this.threats.tags,
        fill_ip_list: !!this.general.fillList,
        ip_list: this.general.ipList,
        allowFrom: this.general.allowFrom,
        allowList: this.general.allowList,
        denyFrom: this.general.denyFrom,
        denyList: this.general.denyList,
        active: this.general.state ? 1 : 0,
        unit: this.api.fwUnit === "firewall" ? "on" : null,
        firewall_id: this.api.firewall,
        group: this.api.fwUnit === "group" ? "on" : null,
        firewallgroup_id: this.api.firewallGroup,
        apicall: policyCalls,
        customCalls: customCalls,
        ruleback: rollbackCalls,
        ignore_no_cn2: !!this.threats.ignoreNoCn2,
        ignore_no_cn3: !!this.threats.ignoreNoCn3,
        cn2: this.threats.cn2,
        cn3: this.threats.cn3,
        custom_parser: this.general.ads !== true,
        source_key: this.general.sourceKey,
        destination_key: this.general.destinationKey,
      };

      if (this.api.fwUnit === "custom") {
        data.vendor = 0;
      }
      if (this.id === 0) {
        storeRule(data)
          .then(() => {
            this.saving = false;
            this.$toast.open({
              message: "Rule was successfully created!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.close();
          })
          .catch((e) => {
            Errors.show(e);
            this.saving = false;
          });
      } else {
        updateRule(data, this.id)
          .then(() => {
            this.saving = false;
            this.$toast.open({
              message: "Rule was successfully updated!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.close();
          })
          .catch((e) => {
            Errors.show(e);
            this.saving = false;
          });
      }
    },
  },
};
</script>
