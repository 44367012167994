<template>
  <div
    id="dashboard-sec-policy-auditor-widget"
    :class="className"
    :style="{ height: height, width: width }"
  />
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
import { debounce } from "@/utils";

const animationDuration = 6000;

export default {
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "160px",
    },
    active: {
      type: Array,
      default: function () {
        return [0, 0, 0, 0];
      },
    },
    disabled: {
      type: Array,
      default: function () {
        return [0, 0, 0, 0];
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    active: {
      immediate: true,
      deep: true,
      handler() {
        if (this.chart) {
          this.chart.dispose();
          this.chart = null;
        }
        this.$nextTick(() => {
          this.initChart();
        });
      },
    },
  },
  mounted() {
    this.initChart();
    this.__resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.__resizeHandler);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      const element = document.getElementById(
        "dashboard-sec-policy-auditor-widget"
      );
      if (this.$vuetify.theme.dark) {
        this.chart = echarts.init(element, "darkTheme");
      } else {
        this.chart = echarts.init(element, "lightTheme");
      }

      this.chart.setOption({
        grid: {
          top: 10,
          bottom: 10,
          left: 20,
          right: 10,
        },
        series: [
          {
            data: [
              {
                name: "Low",
                value: this.active[0],
                itemStyle: {
                  color: "#ccd0d3",
                },
              },
              {
                name: "Medium",
                value: this.active[1],
                itemStyle: {
                  color: "#64c52a",
                },
              },
              {
                name: "High",
                value: this.active[2],
                itemStyle: {
                  color: "#ffd976",
                },
              },
              {
                name: "Critical",
                value: this.active[3],
                itemStyle: {
                  color: "#eb637b",
                },
              },
            ],
            label: {
              show: true,
              formatter: "{b} ({c})",
            },
            animationDuration,
            type: "pie",
            radius: ["40%", "70%"],
          },
        ],
      });
    },
  },
};
</script>
