<template>
  <v-btn v-if="hasForm" icon @click="open" class="has">
    <v-avatar size="20" class="pulse">
      <img
        :src="
          $vuetify.theme.dark
            ? location + '/images/icons/white/add.svg'
            : location + '/images/icons/dark/add.svg'
        "
        alt="Add"
      />
    </v-avatar>
  </v-btn>
</template>

<script>
import { Settings } from "@/components/AddFormBtn/settings";

export default {
  props: {
    location: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      hasForm: false,
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    $route: {
      handler: function (newValue) {
        this.hasForm = Settings.getAddFormKeyword(newValue) !== "";
      },
      immediate: true,
    },
  },
  methods: {
    open() {
      this.keywordAddForm = Settings.getAddFormKeyword(this.$route);
      this.idAddForm = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
$synapsaGreen: #69be28;

.v-btn {
  min-width: 40px !important;
  opacity: 0.7;
  transition-duration: 0.3s;

  .pulse {
    animation: pulse-animation 2.5s infinite;
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0 $synapsaGreen;
      -webkit-transform:scale(1.15);
      -moz-transform:scale(1.15);
      transform:scale(1.15);
    }
    100% {
      box-shadow: 0 0 0 1rem rgba(0, 0, 0, 0);
    }
  }
}
.v-btn.has:hover {
  opacity: 1;
}
</style>
