<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :only-full="true"
      @resize="resize($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <awaiting-table
            v-if="size !== 1 && !loading"
            :headers="headers"
            :data="data"
            :loading="tableLoading"
            :processing="processing"
            :done="done"
            :failed="failed"
            @reject="reject"
            @confirm="confirm(0, 0)"
            @close="close"
          />
        </transition>
        <v-row v-if="tableLoading && size === 1" class="mt-0 mx-0">
          <v-col cols="12" class="text-center">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import awaitingTable from "./components/table";
import { Errors } from "@/utils/error";
import { rejectCall } from "@/api/monitor/reject";
import { confirmCall } from "@/api/monitor/unsuccessful";
import { secPolicyEventsCall } from "@/api/awaitingApproval";

export default {
  components: {
    Topbar,
    awaitingTable,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    keyword: {
      type: String,
      default: function () {
        return "";
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  data() {
    return {
      loading: true,
      tableLoading: true,
      processing: false,
      done: false,
      failed: false,

      headers: [
        { text: "Time", value: "time", sortable: false },
        { text: "Rule", value: "rule", sortable: false },
        { text: "Call name", value: "call_name", sortable: false },
        { text: "Target", value: "target", sortable: false },
        { text: "HTTP status", value: "http", sortable: false },
        { text: "Response status", value: "response_status", sortable: false },
        {
          text: "Response message",
          value: "response_message",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
        },
      ],
      data: [],
      ruleName: "-",
      firewallName: "-",
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.tableLoading = true;
          this.loadData();
        }
      },
    },
  },
  // created() {
  //   this.loadData();
  // },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "awaiting-approval");
    },
    reject() {
      this.processing = true;
      rejectCall(this.id)
        .then(() => {
          this.$toast.open({
            message: "Event call was successfully rejected!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.processing = false;
          this.done = true;
          this.change();
        })
        .catch((e) => {
          Errors.show(e);
          this.processing = false;
          this.loadData();
        });
    },
    confirm(index, max) {
      this.tableLoading = true;
      this.processing = true;
      const data = {
        id: this.data[index].eventcall_id,
        index: index,
        max: max,
      };
      confirmCall(data)
        .then((response) => {

          if (response.data.calldata.is_call === 1) {
            // Info o calle pushneme do view len ak bol poslaný
            // Niektoré cally sa totiž neodošlú, ak sa nemusia odoslať (napríklad Fortinet Create Address
            let call = response.data.calldata;
            call.rule = this.ruleName;
            call.target = this.firewallName;
            this.data.push(call);
          }

          if (
            index + 1 <= response.data.successfulcalls - 1 &&
            response.data.state !== "false"
          ) {
            this.confirm(index + 1, response.data.successfulcalls);
          } else {
            this.tableLoading = false;
            this.processing = false;
            if(response.data.state === 'false'){
              this.$toast.open({
                message: "Event call was not sent successfully!",
                type: "warning",
                position: "top-right",
                duration: 2000,
              });
              this.failed = true;
              this.done = true;
              this.change();
            }else{
              this.$toast.open({
                message: "Event call was successfully sent!",
                type: "success",
                position: "top-right",
                duration: 2000,
              });
              this.done = true;
              this.change();
            }

          }
        })
        .catch((e) => {
          Errors.show(e);
          this.tableLoading = false;
          this.processing = false;
        });
    },
    loadData() {
      this.loading = false;
      this.resize("bigger");
      const data = {
        eventcall_id: this.id,
      };
      secPolicyEventsCall(data)
        .then((response) => {
          this.data = response.data.calldata;
          this.data.map((call) => {
            if (response.data.rule) {
              this.ruleName = response.data.rule.name;
            }
            if (response.data.firewall) {
              this.firewallName = response.data.firewall.name;
            }
            call.rule = response.data.rule ? response.data.rule.name : "-";
            call.target = response.data.firewall
              ? response.data.firewall.name
              : "-";
          });
          this.tableLoading = false;
        })
        .catch((e) => {
          this.tableLoading = false;
          Errors.show(e);
        });
    },
  },
};
</script>
