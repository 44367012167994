export const MonitorParser = {
  cveVersionToTable: function (affectedRaw, fixedRaw, versionRaw) {
    const affected = JSON.parse(affectedRaw);
    const fixed = JSON.parse(fixedRaw);
    const version = JSON.parse(versionRaw);
    const rows = Math.max(affected.length, fixed.length, version.length);
    const data = [];
    for (let i = 0; i < rows; i++) {
      let a = "-";
      if (affected[i]) {
        a = affected[i];
      }
      let f = "-";
      if (fixed[i]) {
        f = fixed[i];
      }
      let v = "-";
      if (version[i]) {
        v = version[i];
      }
      data.push({
        affected: a,
        fixed: f,
        version: v,
      });
    }

    return data;
  },
  auditorAlertHeadersByVendor: function (vendor_id) {
    if (vendor_id === 1) {
      return [
        { text: "Name", value: "secPolicy", sortable: false },
        { text: "ID/UUID", value: "idUuid", sortable: false },
        { text: "Zone", value: "srcZone", sortable: false },
        { text: "Address", value: "srcAddr", sortable: false },
        { text: "Zone", value: "dstZone", sortable: false },
        { text: "Address", value: "dstAddr", sortable: false },
        { text: "Service", value: "service", sortable: false },
        { text: "App", value: "app", sortable: false },
        { text: "App (effective)", value: "effectiveApplication", sortable: false },
        { text: "Tag", value: "tag", sortable: false },
        { text: "Log Start", value: "logStart", sortable: false },
        { text: "Log End", value: "logEnd", sortable: false },
        { text: "Log Setting", value: "logSetting", sortable: false },
        { text: "Profile", value: "profileSetting", sortable: false },
        { text: "Antivirus", value: "antivirus", sortable: false },
        { text: "Spyware", value: "spyware", sortable: false },
        { text: "Vulnerability", value: "vulnerability", sortable: false },
        { text: "Wildfire", value: "wildfire", sortable: false },
        { text: "Url Filtering", value: "urlFiltering", sortable: false },
        { text: "File Blocking", value: "fileBlocking", sortable: false },
        { text: "Data Filtering", value: "dataFiltering", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ];
    } else if (vendor_id === 2) {
      return [
        { text: "Security policy", value: "secPolicy", sortable: false },
        { text: "Src interface", value: "srcInterface", sortable: false },
        { text: "Src addr", value: "srcAddr", sortable: false },
        { text: "Dst interface", value: "dstInterface", sortable: false },
        { text: "Dst addr", value: "dstAddr", sortable: false },
        { text: "Service", value: "service", sortable: false },
        { text: "App", value: "app", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ];
    } else if (vendor_id === 3) {
      return [
        { text: "Security policy", value: "secPolicy", sortable: false },
        { text: "Src addr", value: "srcAddr", sortable: false },
        { text: "Dst addr", value: "dstAddr", sortable: false },
        { text: "Service", value: "service", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ];
    } else {
      return [];
    }
  },
};
