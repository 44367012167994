<template>
  <v-tooltip v-if="mini" right color="primary" open-delay="1">
    <template v-slot:activator="{ on, attrs }">
      <v-list-group :value="false" v-bind="attrs" v-on="on">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-badge
              v-if="
                route.name === 'monitor' &&
                alertCount.total > 0
              "
              color="bad-3"
              dot
            >
              <v-avatar size="20" class="br-0">
                <img
                  :src="
                    childrenActive()
                      ? location + '/images/icons/green/sidebar/' + route.icon
                      : location + iconRoute + route.icon
                  "
                  alt="Add"
                />
              </v-avatar>
            </v-badge>
            <v-avatar v-else size="20" class="br-0">
              <img
                :src="
                  childrenActive()
                    ? location + '/images/icons/green/sidebar/' + route.icon
                    : location + iconRoute + route.icon
                "
                alt="Add"
              />
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-title>{{
            $t("sidebar." + route.name)
          }}</v-list-item-title>
        </template>

        <div class="list-group-body">
          <v-list-item
            v-for="child in route.children"
            :key="child.id"
            :to="child.route"
            :class="{ active: active.includes(child.id) }"
            color="#fff"
            link
          >
            <v-list-item-icon>
              <v-tooltip
                v-if="mini"
                right
                color="primary"
                open-delay="1"
                nudge-right="20"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    v-if="
                      (child.name === 'auditorAlert' &&
                        alertCount.auditor > 0) ||
                      (child.name === 'interconnectorAlert' &&
                        alertCount.interconnector > 0)
                    "
                    color="bad-3"
                    dot
                  >
                    <v-avatar size="20" class="br-0" v-bind="attrs" v-on="on">
                      <img
                        :src="
                          active.includes(child.id)
                            ? location +
                              '/images/icons/green/sidebar/' +
                              child.icon
                            : location +
                              '/images/icons/white/sidebar/' +
                              child.icon
                        "
                        alt="Add"
                      />
                    </v-avatar>
                  </v-badge>
                  <v-avatar
                    v-else
                    size="20"
                    class="br-0"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <img
                      :src="
                        active.includes(child.id)
                          ? location +
                            '/images/icons/green/sidebar/' +
                            child.icon
                          : location +
                            '/images/icons/white/sidebar/' +
                            child.icon
                      "
                      alt="Add"
                    />
                  </v-avatar>
                </template>
                <span>{{ $t("sidebar." + child.name) }}</span>
              </v-tooltip>
              <v-avatar v-else size="20" class="br-0">
                <img
                  :src="
                    active.includes(child.id)
                      ? location + '/images/icons/green/sidebar/' + child.icon
                      : location + '/images/icons/white/sidebar/' + child.icon
                  "
                  alt="Add"
                />
              </v-avatar>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                $t("sidebar." + child.name)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list-group>
    </template>
    <span>{{ $t("sidebar." + route.name) }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    active: {
      type: Array,
      default: function () {
        return [];
      },
    },
    mini: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    route: {
      type: Object,
      default: function () {
        return null;
      },
    },
    location: {
      type: String,
      default: function () {
        return "";
      },
    },
    alertCount: {
      type: Object,
      default: function () {
        return {
          auditor: 0,
          approval: 0,
          interconnector: 0,
        };
      },
    },
  },
  data() {
    return {
      iconRoute: "/images/icons/white/sidebar/",
    };
  },
  methods: {
    childrenActive() {
      let active = false;
      this.route.children.map((child) => {
        if (this.active.includes(child.id)) {
          active = true;
        }
      });
      return active;
    },
  },
};
</script>

<!--<style lang="scss" scoped>-->
<!--.v-list-item {-->
<!--  .v-list-item__title {-->
<!--    color: #fff !important;-->
<!--  }-->
<!--  .v-list-item__icon {-->
<!--    margin-right: 5px !important;-->
<!--  }-->
<!--}-->
<!--.v-list-item.active {-->
<!--  color: #69be28;-->
<!--}-->
<!--</style>-->

<style lang="scss" scoped>
.list-group-body {
  background: rgb(78, 79, 79);
}
</style>
