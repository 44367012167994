import SystemLogsWidget from "@/components/Widgets/SystemLogsWidget";
import SecPolicyAuditorWidget from "@/components/Widgets/SecPolicyAuditorWidget";
import OverallAuditorFailureWidget from "@/components/Widgets/OverallAuditorFailureWidget";
import MostReportedIpsWidget from "@/components/Widgets/MostReportedIpsWidget";
import MinersWidget from "@/components/Widgets/MinersWidget";
import SystemInfoWidget from "@/components/Widgets/SystemInfoWidget";
import FirewallsWidget from "@/components/Widgets/FirewallsWidget";
import AuditorScoreWidget from "@/components/Widgets/AuditorScoreWidget";
import AuditorRulesetScoreChartWidget from "@/components/Widgets/AuditorRulesetScoreChartWidget";
import AuditorIntegrityScoreChartWidget from "@/components/Widgets/AuditorIntegrityScoreChartWidget";
import AuditorAlertScoreChart from "@//components/Widgets/AuditorAlertScoreChartWidget";
import MostViolatingSecurityPoliciesWidget from "@/components/Widgets/MostViolatingSecurityPoliciesWidget";

export default {
  defaultDashboardTabs: [
    {
      id: 1,
      active: false,
      name: "dashboard.tabs.all",
      widgets: [
        {
          x: 0,
          y: 0,
          w: 1,
          h: 7,
          i: "system-logs-widget",
          static: false,
          component: SystemLogsWidget,
        },
        {
          x: 1,
          y: 0,
          w: 1,
          h: 7,
          i: "system-info-widget",
          static: false,
          component: SystemInfoWidget,
        },
        {
          x: 2,
          y: 0,
          w: 1,
          h: 7,
          i: "sec-policy-auditor-widget",
          static: false,
          component: SecPolicyAuditorWidget,
        },
        {
          x: 1,
          y: 1,
          w: 1,
          h: 3,
          i: "firewalls-widget",
          static: false,
          component: FirewallsWidget,
        },
        {
          x: 2,
          y: 1,
          w: 1,
          h: 3,
          i: "miners-widget",
          static: false,
          component: MinersWidget,
        },
        {
          x: 0,
          y: 7,
          w: 1,
          h: 9,
          i: "most-reported-src-ips-widget",
          static: false,
          component: MostReportedIpsWidget,
          type: "src",
        },
        {
          x: 0,
          y: 7,
          w: 1,
          h: 9,
          i: "most-reported-dst-ips-widget",
          static: false,
          component: MostReportedIpsWidget,
          type: "dst",
        },
        {
          x: 0,
          y: 8,
          w: 1,
          h: 9,
          i: "overall-auditor-failure-widget",
          static: false,
          component: OverallAuditorFailureWidget,
        },
        {
          x: 1,
          y: 3,
          w: 2,
          h: 7,
          i: "auditors-ruleset-score-chart-widget",
          static: false,
          component: AuditorRulesetScoreChartWidget,
        },
        {
          x: 1,
          y: 4,
          w: 2,
          h: 7,
          i: "auditors-integrity-score-chart-widget",
          static: false,
          component: AuditorIntegrityScoreChartWidget,
        },
        {
          x: 1,
          y: 5,
          w: 2,
          h: 9,
          i: "auditors-score-widget",
          static: false,
          component: AuditorScoreWidget,
        },
        {
          x: 1,
          y: 6,
          w: 2,
          h: 9.5,
          i: "auditors-alerts-score-chart-widget",
          static: false,
          component: AuditorAlertScoreChart,
        },
        {
          x: 1,
          y: 2,
          w: 2,
          h: 9,
          i: "most-violating-security-policies-widget",
          static: false,
          component: MostViolatingSecurityPoliciesWidget,
        },

        // { 'x': 0, 'y': 11, 'w': 1, 'h': 5, 'i': '6', static: false, component: MinerLogsWidget },
      ],
    },
    {
      id: 2,
      active: true,
      name: "dashboard.tabs.system",
      widgets: [
        {
          x: 0,
          y: 0,
          w: 1,
          h: 7,
          i: "tab-system-logs-widget",
          static: false,
          component: SystemLogsWidget,
        },
        {
          x: 1,
          y: 0,
          w: 1,
          h: 7,
          i: "tab-system-info-widget",
          static: false,
          component: SystemInfoWidget,
        },
        {
          x: 2,
          y: 3,
          w: 1,
          h: 3,
          i: "tab-firewalls-widget",
          static: false,
          component: FirewallsWidget,
        },
        {
          x: 2,
          y: 0,
          w: 1,
          h: 3,
          i: "tab-miners-widget",
          static: false,
          component: MinersWidget,
        },
      ],
    },
    {
      id: 3,
      active: false,
      name: "dashboard.tabs.auditor",
      widgets: [
        {
          x: 0,
          y: 0,
          w: 1,
          h: 7,
          i: "tab-sec-policy-auditor-widget",
          static: false,
          component: SecPolicyAuditorWidget,
        },
        {
          x: 0,
          y: 9,
          w: 3,
          h: 7,
          i: "tab-auditors-ruleset-score-chart-widget",
          static: false,
          component: AuditorRulesetScoreChartWidget,
        },
        {
          x: 0,
          y: 16,
          w: 3,
          h: 7,
          i: "tab-auditors-integrity-score-chart-widget",
          static: false,
          component: AuditorIntegrityScoreChartWidget,
        },
        {
          x: 1,
          y: 0,
          w: 2,
          h: 9,
          i: "tab-auditors-score-widget",
          static: false,
          component: AuditorScoreWidget,
        },
        {
          x: 0,
          y: 23,
          w: 3,
          h: 9.5,
          i: "tab-auditors-alerts-score-chart-widget",
          static: false,
          component: AuditorAlertScoreChart,
        },
        {
          x: 0,
          y: 2,
          w: 2,
          h: 9,
          i: "tab-most-violating-security-policies-widget",
          static: false,
          component: MostViolatingSecurityPoliciesWidget,
        },
        {
          x: 2,
          y: 2,
          w: 1,
          h: 9,
          i: "tab-overall-auditor-failure-widget",
          static: false,
          component: OverallAuditorFailureWidget,
        },
      ],
    },
    {
      id: 4,
      active: false,
      name: "dashboard.tabs.interconnector",
      widgets: [
        {
          x: 0,
          y: 0,
          w: 1,
          h: 9,
          i: "tab-most-reported-src-ips-widget",
          static: false,
          component: MostReportedIpsWidget,
          type: "src",
        },
        {
          x: 1,
          y: 0,
          w: 1,
          h: 9,
          i: "tab-most-reported-dst-ips-widget",
          static: false,
          component: MostReportedIpsWidget,
          type: "dst",
        },
      ],
    },
  ],
};
