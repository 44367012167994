<template>
  <v-container :fluid="true" class="px-0 py-0">
    <!--NAME-->
    <v-row class="mx-0 mt-0">
      <v-col cols="6" class="py-0">
        <label class="font-roboto-condensed-regular font-size-15">Name</label>
        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Name"
          placeholder="Name"
          dense
          solo
          @keyup="update"
        />
      </v-col>
      <!--END NAME-->
      <!--MINER-->
      <v-col cols="6" class="py-0">
        <label class="font-roboto-condensed-regular font-size-15">Miner</label>
        <v-autocomplete
          v-model="miner"
          :items="miners"
          :rules="minerRules"
          item-text="name"
          item-value="id"
          label="Miner"
          placeholder="Miner"
          dense
          solo
          @change="update"
        />
      </v-col>
      <!--END MINER-->
      <!--MODE-->
      <v-col cols="6" class="py-0">
        <label class="font-roboto-condensed-regular font-size-15">Mode</label>
        <v-select
          v-model="mode"
          :items="modes"
          :rules="modeRules"
          item-text="name"
          item-value="id"
          label="Mode"
          placeholder="Mode"
          dense
          solo
          @change="update"
        />
      </v-col>
      <!--END MODE-->
      <!--DESCRIPTION-->
      <v-col cols="12" class="py-0">
        <label class="font-roboto-condensed-regular font-size-15"
          >Description</label
        >
        <v-textarea
          v-model="description"
          :rules="descriptionRules"
          placeholder="Description"
          auto-grow
          rows="4"
          dense
          solo
          @keyup="update"
        />
      </v-col>
      <!--END DESCRIPTION-->
      <!--STATE-->
      <v-col cols="6" class="pb-2 pt-2 mb-2">
        <label class="font-roboto-condensed-regular font-size-15">State</label>

        <div class="toggle">
          <v-switch
            v-model="state"
            :value="true"
            hide-details
            label="Active"
            @change="update"
            inset
            :class="[state ? 'v-switch-on' : 'v-switch-off']"
            class="target-background v-switch-off"
          />
        </div>
      </v-col>
      <!--END STATE-->
      <!--ADDRESS LIST SETTING-->
      <v-col cols="6" class="pb-2 pt-2 mb-2">
        <label class="font-roboto-condensed-regular font-size-15"
          >Adress lists setting
        </label>

        <div class="toggle">
          <v-switch
            v-model="fillList"
            :value="true"
            hide-details
            label="Fill source & destination ip addresses into address lists?"
            @change="update"
            inset
            :class="[fillList ? 'v-switch-on' : 'v-switch-off']"
            class="target-background v-switch-off"
          />
        </div>
      </v-col>
      <!--END ADDRESS LIST SETTING-->

      <!--ALLOW LIST SETTING-->
      <v-col cols="6" class="pb-2 pt-0 mb-2">
        <label class="font-roboto-condensed-regular font-size-15"
          >Allow List</label
        >
        <div class="toggle">
          <v-switch
            v-model="allowFrom"
            :value="true"
            hide-details
            label="Allow only for IP from address list?"
            @change="update"
            inset
            :class="[allowFrom ? 'v-switch-on' : 'v-switch-off']"
            class="target-background v-switch-off"
          />
        </div>
      </v-col>
      <!--END ALLOW LIST SETTING-->

      <!--ALLOW LIST SETTING-->
      <v-col cols="6" class="pb-2 pt-0 mb-2">
        <label class="font-roboto-condensed-regular font-size-15"
          >Deny List</label
        >
        <div class="toggle">
          <v-switch
            v-model="denyFrom"
            :value="true"
            hide-details
            label="Disable only for IP from address list?"
            @change="update"
            inset
            :class="[denyFrom ? 'v-switch-on' : 'v-switch-off']"
            class="target-background v-switch-off"
          />
        </div>
      </v-col>
      <!--END ALLOW LIST SETTING-->
    </v-row>
    <v-row class="mx-0">
      <!--IP LIST-->
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-col v-if="fillList" cols="6" class="py-0">
          <label class="font-roboto-condensed-regular font-size-15"
            >IP list</label
          >
          <v-autocomplete
            v-model="ipList"
            :items="ipLists"
            :rules="ipListRules"
            item-text="name"
            item-value="id"
            label="IP list"
            placeholder="IP list"
            dense
            solo
            @change="update"
          />
        </v-col>
      </transition>
      <!--END IP LIST-->
      <!--ALLOW LIST-->
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-col v-if="allowFrom" cols="6" class="py-0">
          <label class="font-roboto-condensed-regular font-size-15"
            >Allow From</label
          >
          <v-autocomplete
            v-model="allowList"
            :items="ipLists"
            :rules="allowListRules"
            item-text="name"
            item-value="id"
            label="Allow From"
            placeholder="Allow From"
            dense
            solo
            @change="update"
          />
        </v-col>
      </transition>
      <!--END ALLOW LIST-->
      <!--DENY LIST-->
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-col v-if="denyFrom" cols="6" class="py-0">
          <label class="font-roboto-condensed-regular font-size-15"
            >Deny From</label
          >
          <v-autocomplete
            v-model="denyList"
            :items="ipLists"
            :rules="denyListRules"
            item-text="name"
            item-value="id"
            label="Deny From"
            placeholder="Deny From"
            dense
            solo
            @change="update"
          />
        </v-col>
      </transition>
      <!--END DENY LIST-->
      <!--CUSTOM FIELDS LIST-->
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-col
          v-if="(fillList || allowFrom || denyFrom) && !ads"
          cols="6"
          class="py-0"
        >
          <label class="font-roboto-condensed-regular font-size-15"
            >Source</label
          >
          <v-text-field
            v-model="sourceKey"
            :rules="sourceKeyRules"
            label="Custom Key for Source"
            placeholder="Custom Key for Source"
            dense
            solo
            @keyup="update"
          />
        </v-col>
      </transition>
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-col
          v-if="(fillList || allowFrom || denyFrom) && !ads"
          cols="6"
          class="py-0"
        >
          <label class="font-roboto-condensed-regular font-size-15"
            >Destination</label
          >
          <v-text-field
            v-model="destinationKey"
            :rules="destinationKeyRules"
            label="Custom Key for Destination"
            placeholder="Custom Key for Destination"
            dense
            solo
            @keyup="update"
          />
        </v-col>
      </transition>
      <!--CUSTOM FIELDS LIST-->
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
    miners: {
      type: Array,
      default: function () {
        return [];
      },
    },
    ipLists: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      valid: true,

      name: "",
      sourceKey: null,
      sourceKeyRules:
        this.ads ||
        (!this.ads && (this.fillList || this.allowFrom || this.denyFrom))
          ? null
          : [(v) => !!v || "Source Key is required"],
      destinationKey: null,
      destinationKeyRules:
        this.ads ||
        (!this.ads && (this.fillList || this.allowFrom || this.denyFrom))
          ? null
          : [(v) => !!v || "Destination Key is required"],
      ads: false,
      nameRules: [(v) => !!v || "Name is required"],
      miner: "",
      minerRules: [(v) => !!v || "Miner is required"],
      mode: "",
      modes: [
        { id: "Manual", name: "On Approval" },
        { id: "Automat", name: "Automat" },
      ],
      modeRules: [(v) => !!v || "Mode is required"],
      description: "",
      descriptionRules: [(v) => !!v || "Description is required"],
      state: true,
      fillList: null,
      ipList: "",
      ipListRules: [(v) => !!v || "IP list is required"],
      allowFrom: null,
      allowList: "",
      allowListRules: [(v) => !!v || "Allow List is required"],
      denyFrom: null,
      denyList: "",
      denyListRules: [(v) => !!v || "Deny List is required"],
    };
  },
  created() {
    if (this.mountData) {
      this.name = this.mountData.name;
      this.miner = this.mountData.miner;
      this.mode = this.mountData.mode;
      this.description = this.mountData.description;
      this.state = this.mountData.state;
      this.fillList = this.mountData.fillList;
      this.ipList = this.mountData.ipList;
      this.allowFrom = this.mountData.allowFrom;
      this.allowList = this.mountData.allowList;
      this.denyFrom = this.mountData.denyFrom;
      this.denyList = this.mountData.denyList;
      this.ads = this.mountData.ads;
      this.sourceKey = this.mountData.sourceKey;
      this.destinationKey = this.mountData.destinationKey;
    }
  },
  methods: {
    update() {
      if (this.miner > 0) {
        this.ads = this.miners.find((x) => x.id === this.miner).type === 1;
      }
      this.$emit("update", {
        name: this.name,
        miner: this.miner,
        mode: this.mode,
        description: this.description,
        state: this.state,
        fillList: this.fillList,
        ipList: this.ipList,
        allowFrom: this.allowFrom,
        allowList: this.allowList,
        denyFrom: this.denyFrom,
        denyList: this.denyList,
        membertimeout: this.membertimeout,
        firstRender: false,
        vendor: this.vendor,
        ads: this.ads,
        sourceKey: this.sourceKey,
        destinationKey: this.destinationKey,
      });
    },
  },
};
</script>

<style scoped>
.text-red {
  color: indianred;
}
</style>
