<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :only-full="true"
      @resize="resize($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <successful-table
            v-if="size !== 1 && !loading"
            :type="type"
            :headers="
              type === 'intUnsuccessful'
                ? headersUnsuccessful
                : headersSuccessful
            "
            :data="data"
            :loading="tableLoading"
            :processing="processing"
            @change="loadData($event.page, $event.size)"
            @revoke="revoke"
            @reject="reject"
            @confirm="confirm(0, 0)"
          />
        </transition>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import SuccessfulTable from "@/layout/FormSidebar/monitor/interconnector/successful/table";
import { Errors } from "@/utils/error";
import { getSuccessfulCallData } from "@/api/monitor/successful";
import { revokeCall } from "@/api/monitor/revoke";
import { rejectCall } from "@/api/monitor/reject";
import { confirmCall } from "@/api/monitor/unsuccessful";

export default {
  components: {
    Topbar,
    SuccessfulTable,
  },
  props: {
    type: {
      type: String,
      default: function () {
        return "successful";
      },
    },
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 3;
      },
    },
  },
  data() {
    return {
      loading: true,
      tableLoading: true,
      processing: false,

      headersSuccessful: [
        { text: "Time", value: "time", sortable: false },
        { text: "Confirmed by", value: "confirmedBy", sortable: false },
        { text: "Call name", value: "callName", sortable: false },
        { text: "Target", value: "target", sortable: false },
        { text: "HTTP status", value: "httpStatus", sortable: false },
        { text: "Check status", value: "checkStatus", sortable: false },
        { text: "Response status", value: "responseStatus", sortable: false },
        { text: "Check response", value: "checkResponse", sortable: false },
        { text: "Response message", value: "responseMsg", sortable: false },
        { text: "Status", value: "status", sortable: false },
      ],
      headersUnsuccessful: [
        { text: "Call name", value: "callName", sortable: false },
        { text: "Target", value: "target", sortable: false },
        { text: "HTTP status", value: "httpStatus", sortable: false },
        { text: "Check status", value: "checkStatus", sortable: false },
        { text: "Response status", value: "responseStatus", sortable: false },
        { text: "Check response", value: "checkResponse", sortable: false },
        { text: "Response message", value: "responseMsg", sortable: false },
        { text: "Status", value: "status", sortable: false },
      ],
      data: [],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.tableLoading = true;
          this.loadData();
        }
      },
    },
  },
  // created() {
  //   this.loadData();
  // },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "monitorInterconnector");
    },
    revoke() {
      this.processing = true;
      const data = {
        commit: false,
      };
      revokeCall(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Event call was successfully revoked!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.processing = false;
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.processing = false;
          this.loadData();
        });
    },
    reject() {
      this.processing = true;
      rejectCall(this.id)
        .then(() => {
          this.$toast.open({
            message: "Event call was successfully rejected!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.processing = false;
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.processing = false;
          this.loadData();
        });
    },
    confirm(index, max) {
      this.tableLoading = true;
      this.processing = true;
      const data = {
        id: this.data[index].eventcall_id,
        index: index,
        max: max,
      };
      confirmCall(data)
        .then((response) => {
          let call = response.data.calldata;
          this.data.push(call);
          if (
            index + 1 <= response.data.successfulcalls - 1 &&
            response.data.state !== "false"
          ) {
            this.confirm(index + 1, response.data.successfulcalls);
          } else {
            this.tableLoading = false;
            this.processing = false;
            if(response.data.state === 'false'){
              this.$toast.open({
                message: "Event call was not sent successfully!",
                type: "warning",
                position: "top-right",
                duration: 4000,
              });
              this.failed = true;
              this.done = true;
              this.change();
            }else{
              this.$toast.open({
                message: "Event call was successfully sent!",
                type: "success",
                position: "top-right",
                duration: 2000,
              });
              this.done = true;
              this.change();
            }
            this.close();
          }
        })
        .catch((e) => {
          Errors.show(e);
          this.tableLoading = false;
          this.processing = false;
        });
    },
    loadData() {
      this.loading = false;
      this.resize("bigger");
      const data = {
        eventcall_id: this.id,
        event_type: "successful",
      };
      getSuccessfulCallData(data)
        .then((response) => {
          this.data = response.data;
          this.tableLoading = false;
        })
        .catch((e) => {
          this.tableLoading = false;
          Errors.show(e);
        });
    },
  },
};
</script>
