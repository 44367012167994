import request from "@/utils/request";

export function getCustomApiList() {
  return request({
    url: "api/settings/api/custom/list",
    method: "get",
  });
}
export function getCustomApiCalls(page, size, url) {
  return request({
    url: "api/settings/api/" + url + "?page=" + page + "&size=" + size,
    method: "get",
  });
}
export function getCustomApiCall(id) {
  return request({
    url: "api/settings/api/custom/" + id,
    method: "get",
  });
}
export function storeCustomApiCall(data) {
  return request({
    url: "api/settings/api/custom",
    method: "post",
    data: data,
  });
}
export function updateCustomApiCall(data, id) {
  return request({
    url: "api/settings/api/custom/" + id,
    method: "put",
    data: data,
  });
}
export function deleteCustomApiCall(id) {
  return request({
    url: "api/settings/api/custom/" + id,
    method: "delete",
  });
}
