export default {
  sidebar: {
    addressLists: "Address lists",
    addressListsCustom: "Address lists - Custom",
    addressListsCustomAll: "Address lists - Custom",
    addressListsConnection: "Address lists - Connection",
    addressListsCustomDestination:
      "Address list - <span class='text-initial'>{title}</span> - Destination",
    addressListsCustomSource:
      "Address list - <span class='text-initial'>{title}</span> - Source",
    addressListsPredefined: "Address lists - Predefined",
    addressListsPredefinedAll: "Address lists - Predefined",
    addressListsPredefinedIPv4:
      "Address list - <span class='text-initial'>{title}</span> - IPv4",
    addressListsPredefinedIPv6:
      "Address list - <span class='text-initial'>{title}</span> - IPv6",
    apiCheckpoint: "Settings - Api - Checkpoint",
    apiCustom: "Settings - Api - Custom",
    apiFortinet: "Settings - Api - Fortinet",
    apiPaloAlto: "Settings - Api - Palo Alto",
    apiSophos: "Settings - Api - Sophos",
    auditor: "Auditor",
    auditorAlert: "Auditor",
    awaitingApproval: "Awaiting approval",
    backup: "System - Backup",
    cve: "Auditor - CVE",
    dashboard: "My Dashboard",
    dataFeeds: "Data feeds",
    dataFeedsAlert: "Awaiting approval - Data feeds",
    default: "Default",
    dns: "System - DNS",
    maintenance: "System - Maintenance",
    login: "System - Login",
    domainLists: "Domain lists",
    domainListsCustom: "Domain lists - Custom",
    domainListsCustomAll: "Domain lists - Custom",
    domainListsCustomShow:
      "Domain list - <span class='text-initial'>{title}</span>",
    domainListsPredefined: "Domain lists - Predefined",
    domainListsPredefinedAll: "Domain lists - Predefined",
    domainListsPredefinedShow:
      "Domain list - <span class='text-initial'>{title}</span>",
    emails: "Objects - Emails",
    events: "Events",
    firewallGroups: "Settings - Firewall Groups",
    firewalls: "Settings - Firewalls",
    centralManagement: "Settings - Central Management",
    integration: "Settings - Integration",
    integrity: "Auditor - Integrity Check",
    groups: "Auditor - Groups",
    interconnector: "Interconnector",
    interconnectorAlert: "Interconnector",
    interconnectorAlertReject: "Monitor - Interconnector - Reject",
    interconnectorAlertUnsuccessful: "Monitor - Interconnector - Unsuccessfull",
    license: "System - License",
    listener: "Settings - Listener",
    logs: "System - Logs",
    lookUp: "Look up",
    miners: "Settings - Miners",
    monitor: "Monitor",
    monitorsyslog: "Monitor - Syslog",
    monitorsystem: "Monitor - System",
    notificationProfiles: "Objects - Notification Profiles",
    address: "Objects - Address",
    objects: "Objects",
    parser: "Settings - Parser",
    policyTester: "Policy tester",
    reject: "Monitor - Interconnector - Reject",
    remoteServers: "Objects - Remote Servers",
    revoke: "Monitor - Interconnector - Revoke",
    roles: "System - Roles",
    rules: "Rules",
    ruleset: "Auditor - Ruleset",
    templates: "Auditor - Templates",
    auditorTemplateRules:
      "Auditor - Template - <span class='text-initial'>{title}</span> - Rules",
    script: "Script",
    secPolicyAlert: "Awaiting approval - Security policy",
    securityPolicy: "Security policy",
    settings: "Settings",
    ssl: "System - SSL",
    successful: "Monitor - Interconnector - Successfull",
    synapsa: "System - Synapsa",
    syslog: "Objects - Syslog",
    system: "System",
    backupRemote: "Remote Backup",
    backupConfiguration: "Configuration Backup",
    backupAll: "All Backup",
    systemAlert: "System",
    tasks: "System - Tasks",
    threats: "Monitor - Threats",
    threatSimulator: "Threat Simulator",
    toolbox: "Toolbox",
    unsuccessful: "Monitor - Interconnector - Unsuccessfull",
    urlLists: "Url lists",
    urlListsCustom: "Url lists - Custom",
    urlListsCustomAll: "Url lists - Custom",
    urlListsCustomShow: "Url list - <span class='text-initial'>{title}</span>",
    urlListsPredefined: "Url lists - Predefined",
    urlListsPredefinedAll: "Url lists - Predefined",
    urlListsPredefinedShow:
      "Url list - <span class='text-initial'>{title}</span>",
    users: "System - Users",
    version: "System - Version",
    rulesetAlertConfirmed: "Monitor - Ruleset confirmed alerts",
    rulesetAlertFixed: "Monitor - Ruleset fixed alerts",
    cveAlertActive: "Monitor - CVE active alerts",
    cveAlertConfirmed: "Monitor - CVE confirmed alerts",
    integrityAlertActive: "Monitor - Integrity active alerts",
    integrityAlertConfirmed: "Monitor - Integrity confirmed alerts",
    integrityAlertHistory: "Monitor - Integrity history alerts",
  },
  dashboard: {
    selectModules: "Select modules",
    pageStyleSettings: "Page style settings",
    openTagsView: "Open tags view",
    fixedHeader: "Fixed header",
    theme: "Theme",
    tabs: {
      all: "All",
      system: "System",
      auditor: "Auditor",
      interconnector: "Interconnector",
    },
  },
  awaitingApproval: {
    secPolicy: "Security policy",
    dataFeeds: "Data feeds",
  },
  monitor: {
    interconnector: {
      successful: "Successful",
      unsuccessful: "Unsuccessful",
      reject: "Reject",
      revoke: "Revoke",
    },
    events: {
      threats: "Threats",
      system: "System",
      syslog: "Syslog",
    },
    auditor: {
      ruleset: {
        active: "Active",
        confirmed: "Confirmed",
        fixed: "Fixed",
      },
      integrity: {
        history: "History",
      },
    },
  },
  auditor: {
    ruleset: "Ruleset",
    cve: "CVE",
    integrity: "Integrity Check",
    groups: "Groups",
    templates: "Templates",
  },
  dataFeeds: {
    addresslist: {
      custom: "Custom",
      predefined: "Predefined",
      source: "Source",
      destination: "Destination",
      connection: "Connection",
    },
    domainList: {
      index: "Index",
      custom: "Custom",
      predefined: "Predefined",
    },
    urlList: {
      custom: "Custom",
      predefined: "Predefined",
    },
  },
  objects: {
    emails: "Emails",
    syslogs: "Syslog",
    profiles: "Notification profiles",
    servers: "Remote servers",
    address: "Address",
  },
  settings: {
    miners: "Miners",
    firewalls: "Firewalls",
    centralManagement: "Central Management",
    firewallGroups: "Firewall groups",
    parser: "Parser",
    api: "API",
    listener: "Listener",
    integration: "Integration",
    version: {
      systemVersion: "Local Version",
      conectionStatus: "Conection status",
      lastVersion: "Last Version",
      Date: "Release Date",
      systemInfo: "System Info",
      hardwareInfo: "Hardware Info",
      changelog: "Changelog",
    },
  },
  interconnector: {
    rules: {
      default: "Default",
      script: "Script",
    },
  },
  system: {
    backup: "Backup",
    logs: "Logs",
    tasks: "Tasks",
    users: "Users",
    roles: "Roles",
    version: "Version",
    tab: {
      backup: "Backup",
      logs: "Logs",
      tasks: "Tasks",
      users: "Users",
      roles: "Roles",
      license: "License",
      version: "Version",
      synapsa: "Synapsa",
      dns: "DNS",
      maintenance: "Maintenance",
      login: "Login",
      ssl: "SSL",
    },
    backupTab: {
      configuration: "Configuration",
      fullbackup: "Full backup",
      all: "All",
      remote: "Remote",
    },
    loginTab: {
      ldap: "LDAP",
    },
    login: {
      ldap: {
        host: "Host",
        port: "Port",
        adminLogin: "Admin Login",
        userDn: "User DN",
        groupDn: "Group DN",
      },
    },
    ssl: {
      ssl: "SSL",
      status: "Status",
      type: "Type",
      domain: "Domain",
      ownCertName: "Certification Name",
      validFrom: "Valid From",
      validTo: "Valid To",
    },
    synapsa: {
      synapsa: "Synapsa",
      domainUrl: "Domain Url",
      mailServer: "Mail Server",
      mailLogin: "Mail Login",
      mailFromAddress: "Mail From Address",
      mailPort: "Mail Port",
      mailEncryption: "Mail Encryption",
      timeFormat: "Time Format",
    },
    dns: {
      dns: "DNS",
      Primary: "Primary",
      Secondary: "Secondary",
    },
    license: {
      License: "License",
      Name: "Name",
      Type: "Type",
      Trial: "Trial",
      Update: "Update",
      Expiry: "Expiry",
      Key: "Key",
      Modules: "Modules",
      Sources: "Sources",
      Scripts: "Scripts",
      Services: "Services",
    },
  },
  widgets: {
    systemInfo: {
      headline: "System info",
    },
    miners: {
      headline: "Miners",
    },
    firewalls: {
      headline: "Firewalls",
    },
    secPolicyAuditor: {
      headline: "Security policy auditor",
    },
    overallAuditorFailure: {
      headline: "Overall auditor failures",
    },
    mostReportedDst: {
      headline: "Most reported destinations",
    },
    mostReportedSrc: {
      headline: "Most reported sources",
    },
    systemLogs: {
      headline: "System logs",
    },
    auditorscore: {
      headline: "Auditor's score",
      ruleset: {
        headline: "Score - Auditor rule sets chart - global",
      },
      integrity: {
        headline: "Score - Auditor integrity chart - global",
      },
      alert: {
        headline: "Auditor total alerts",
      },
    },
    violatingsecurity: {
      headline: "Most violating security policies",
    },
  },
  permissions: {
    menu_awaitingapproval: "Menu Awaiting Approval",
    menu_interconnector: "Menu Interconnector",
    menu_auditor: "Menu Auditor",
    menu_datafeed: "Menu Datafeed",
    menu_lookup: "Menu Lookup",
    menu_toolbox: "Menu Toolbox",
    menu_monitor: "Menu Monitor",
    menu_objects: "Menu Objects",
    menu_settings: "Menu Settings",
    menu_system: "Menu System",
    advancedmode: "Advanced Mode",
    awaitingsecuitypolicy: "Security Policy",
    awaitingdatafeed: "Data Feeds",
    securitypolicy: "Security Policy",
    rules: "Rules",
    auditorruleset: "Ruleset",
    auditorcve: "CVE",
    auditorintegrity: "Integrity Check",
    auditorgroups: "Groups",
    auditortemplates: "Templates",
    addresslist: "Address lists",
    domainlist: "Domain lists",
    urllist: "Url lists",
    lookup: "Lookup",
    threatsimulator: "Threat Simulator",
    policytester: "Policy tester",
    auditor: "Auditor",
    interconnector: "Interconnector",
    events: "Events",
    objectemail: "Emails",
    objectsyslog: "Syslog",
    objectprofile: "Notification Profiles",
    objectaddress: "Address",
    objectremoteserver: "Remote Servers",
    miner: "Miners",
    firewall: "Firewalls",
    firewallgroup: "Firewall Groups",
    parser: "Parser",
    api: "API",
    integration: "Integration",
    backup: "Backup",
    import: "Import",
    log: "Logs",
    clearsyslog: "Clear syslog",
    task: "Tasks",
    user: "Users",
    role: "Roles",
    permission: "Permissions",
    version: "Version",
    license: "License",
    ssl: "SSL",
    synapsa: "Synapsa",
    dns: "DNS",
    maintenance: "Maintenance",
    login: "Login",
    customaddress: "Custom",
    predefinedaddress: "Predefined",
    customdomain: "Custom",
    customurl: "Custom",
    predefinedurl: "Predefined",
    predefineddomain: "Predefined",
    monitorauditorruleset: "Ruleset",

    monitorauditorcve: "CVE",
    monitorauditorintegrity: "Integrity",
    integrityactivealert: "Alert",
    integrityconfirmedalert: "Confirmed",
    successful: "Successful",
    unsuccessful: "Unsuccessful",
    rejected: "Rejected",
    revoked: "Revoked",
    eventthreats: "Threats",
    eventsystem: "System",
    eventsyslog: "Syslog",
    apipaloalto: "PaloAlto",
    apifortinet: "Fortinet",
    apicheckpoint: "Checkpoint",
    apisophos: "Sophos",
    apicustom: "Custom",
    rulesetactivealert: "Active",
    rulesetconfirmedalert: "Confirmed",
    rulesetfixedalert: "Fixed",
    cveactivealert: "Active",
    cveconfirmedalert: "Confirmed",
    listener: "Listener",
  },
};
