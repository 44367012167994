import Vue from "vue";
import VueToast from "vue-toast-notification";

Vue.use(VueToast);

export const Errors = {
  show: function (e) {
    if (e.response) {
      if (e.response.data.errors) {
        const keys = Object.getOwnPropertyNames(e.response.data.errors);
        keys.forEach(function (key) {
          e.response.data.errors[key].forEach(function (error) {
            Vue.$toast.open({
              message: error,
              type: "error",
              position: "top-right",
              duration: 3000,
            });
          });
        });
      } else {
        if (e.response.data.message) {
          const status = e.response.data.status
            ? e.response.data.status + " - "
            : "";
          Vue.$toast.open({
            message: status + e.response.data.message,
            type: "error",
            position: "top-right",
            duration: 3000,
          });
        } else {
          if (e.response.data.data && e.response.data.data.message) {
            Vue.$toast.open({
              message:
                e.response.data.data.status +
                " - " +
                e.response.data.data.message,
              type: "error",
              position: "top-right",
              duration: 3000,
            });
          } else {
            Vue.$toast.open({
              message: e.response.status + " - Loading data failed!",
              type: "error",
              position: "top-right",
              duration: 3000,
            });
          }
        }
      }
    } else {
      if (e.message) {
        Vue.$toast.open({
          message: e.message,
          type: "error",
          position: "top-right",
          duration: 3000,
        });
      } else {
        Vue.$toast.open({
          message: e,
          type: "error",
          position: "top-right",
          duration: 3000,
        });
      }
    }
  },
};
