<template>
  <v-navigation-drawer
    id="sidebar-drawer"
    v-model="drawer"
    :mini-variant="mini"
    app
    permanent
    width="200"
    color="blue-grey darken-4"
    class="sidebar-menu-wrapper elevation-4"
    :style="
      'background-image: url(\'' +
      location +
      defaultImages.sidebar +
      '\'); ' +
      'background-image: url(\'' +
      location +
      defaultImages.sidebar +
      '\'), linear-gradient(90deg, rgba(66,66,69,1) 0%, rgba(27,30,23,1) 100%); '
    "
  >
    <v-list-item
      id="sidebar-resize"
      class="resize-item"
      @click="mini = !mini"
      @mouseenter="
        [
          (resizeIcon = '/images/icons/green/arrow-left.svg'),
          (resizeIconMini = '/images/icons/green/menu.svg'),
        ]
      "
      @mouseleave="
        [
          (resizeIcon = '/images/icons/white/arrow-left.svg'),
          (resizeIconMini = '/images/icons/white/menu.svg'),
        ]
      "
    >
      <v-spacer v-if="!mini" />
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-avatar v-if="!mini" size="18" class="br-0">
          <img :src="location + resizeIcon" alt="arrow left icon" />
        </v-avatar>
      </transition>
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-avatar v-if="mini" size="18" class="br-0">
          <img
            :src="location + resizeIconMini"
            alt="menu icon"
            @mouseenter="resizeIconMini = '/images/icons/green/menu.svg'"
            @mouseleave="resizeIconMini = '/images/icons/white/menu.svg'"
          />
        </v-avatar>
      </transition>
    </v-list-item>
    <v-list
      v-if="sidebarHeight"
      dense
      class="sidebar pt-0"
      :style="'height: ' + sidebarHeight + 'px;'"
    >
      <div v-for="route in routes" :key="route.name">
        <group
          v-if="route.children && !mini"
          :active="active"
          :route="route"
          :mini="mini"
          :location="location"
          :alert-count="alertCount"
        />
        <group-mini
          v-if="route.children && mini"
          :active="active"
          :route="route"
          :mini="mini"
          :location="location"
          :alert-count="alertCount"
        />
        <item
          v-if="!route.children"
          :active="active"
          :route="route"
          :mini="mini"
          :location="location"
          :alert-count="alertCount"
        />
      </div>
    </v-list>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-list-item v-if="!mini" id="sidebar-footer" class="py-5">
        <v-list-item-content>
          <v-list-item-title
            class="font-size-12 font-ubuntu-bold mb-2 synapsa-link"
            @click="openGuide"
            >Guides and help</v-list-item-title
          >
          <v-list-item-subtitle
            class="text-white font-size-12 font-ubuntu-regular"
            >© {{ new Date().getFullYear() }} Synapsa v{{
              version
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </transition>
  </v-navigation-drawer>
</template>

<script>
import group from "./group";
import groupMini from "./groupMini";
import item from "./item";
import { getLocalVersion } from "@/api/system/version";
import { Errors } from "@/utils/error";
import { alertCount } from "@/api/menu";

export default {
  components: {
    group,
    groupMini,
    item,
  },
  props: {
    location: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      drawer: null,
      mini: false,
      active: "",
      routes: [],

      alertCount: {
        auditor: 0,
        approval: 0,
        interconnector: 0,
      },

      sidebarHeight: null,

      resizeIcon: "/images/icons/white/arrow-left.svg",
      resizeIconMini: "/images/icons/white/menu.svg",

      version: "",
      intervalId: null,
    };
  },
  computed: {
    defaultImages: {
      get() {
        return this.$store.state.settings.defaultImages;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "defaultImages",
          value: val,
        });
      },
    },
    allAlertsCount: {
      get() {
        return this.$store.state.synapsa.allAlertsCount;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "allAlertsCount",
          value: val,
        });
      },
    },
    auditorAlertsCount: {
      get() {
        return this.$store.state.synapsa.auditorAlertsCount;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "auditorAlertsCount",
          value: val,
        });
      },
    },
    rulesetAlertsCount: {
      get() {
        return this.$store.state.synapsa.rulesetAlertsCount;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "rulesetAlertsCount",
          value: val,
        });
      },
    },
    rulesetLowAlertsCount: {
      get() {
        return this.$store.state.synapsa.rulesetLowAlertsCount;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "rulesetLowAlertsCount",
          value: val,
        });
      },
    },
    rulesetMediumAlertsCount: {
      get() {
        return this.$store.state.synapsa.rulesetMediumAlertsCount;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "rulesetMediumAlertsCount",
          value: val,
        });
      },
    },
    rulesetHighAlertsCount: {
      get() {
        return this.$store.state.synapsa.rulesetHighAlertsCount;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "rulesetHighAlertsCount",
          value: val,
        });
      },
    },
    rulesetCriticalAlertsCount: {
      get() {
        return this.$store.state.synapsa.rulesetCriticalAlertsCount;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "rulesetCriticalAlertsCount",
          value: val,
        });
      },
    },
    cveAlertsCount: {
      get() {
        return this.$store.state.synapsa.cveAlertsCount;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "cveAlertsCount",
          value: val,
        });
      },
    },
    integrityAlertsCount: {
      get() {
        return this.$store.state.synapsa.integrityAlertsCount;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "integrityAlertsCount",
          value: val,
        });
      },
    },
    auditorTaskProcessing: {
      get() {
        return this.$store.state.synapsa.auditorTaskProcessing;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "auditorTaskProcessing",
          value: val,
        });
      },
    },
    auditorRulesetTaskProcessing: {
      get() {
        return this.$store.state.synapsa.auditorRulesetTaskProcessing;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "auditorRulesetTaskProcessing",
          value: val,
        });
      },
    },
    auditorCveTaskProcessing: {
      get() {
        return this.$store.state.synapsa.auditorCveTaskProcessing;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "auditorCveTaskProcessing",
          value: val,
        });
      },
    },
    auditorIntegrityTaskProcessing: {
      get() {
        return this.$store.state.synapsa.auditorIntegrityTaskProcessing;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeAlerts", {
          key: "auditorIntegrityTaskProcessing",
          value: val,
        });
      },
    },
    monitorAuditorRulesetLoadBatch: {
      get() {
        return this.$store.state.synapsa.monitorAuditorRulesetLoadBatch;
      },
    },
    monitorAuditorIntegrityLoadBatch: {
      get() {
        return this.$store.state.synapsa.monitorAuditorIntegrityLoadBatch;
      },
    },
    monitorAuditorRulesetFirewalls: {
      get() {
        return this.$store.state.synapsa.monitorAuditorRulesetFirewalls;
      },
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.active = newValue.meta.active;
      },
    },
    mini: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit("mini", newValue);
      },
    },
    monitorAuditorRulesetLoadBatch: {
      immediate: true,
      deep: true,
      handler() {
        this.getAlertCount();
      },
    },
    monitorAuditorIntegrityLoadBatch: {
      immediate: true,
      deep: true,
      handler() {
        this.getAlertCount();
      },
    },
    monitorAuditorRulesetFirewalls: {
      immediate: true,
      deep: true,
      handler() {
        this.getAlertCount();
      },
    },
  },
  created() {
    this.showRoutes();
    getLocalVersion()
      .then((response) => {
        this.version = response.data.localversion;
      })
      .catch((e) => {
        Errors.show(e);
      });
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.intervalId = setInterval(this.getAlertCount, 30000);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    clearInterval(this.intervalId);
  },
  methods: {
    showRoutes() {
      const routes = [];
      this.$router.options.routes.forEach(function (value) {
        let children = [];
        if (!value.hidden) {
          if (value.children) {
            value.children.forEach(function (child) {
              if (!child.hidden) {
                children.push({
                  id: child.meta.id ? child.meta.id : child.path,
                  name: child.name,
                  active: child.meta.active,
                  route: value.path + "/" + child.path,
                  icon: child.meta.icon,
                });
              }
            });
            routes.push({
              id: value.name,
              name: value.name,
              route: value.path,
              children: children.length > 0 ? children : null,
              icon: value.meta.icon,
            });
          }
        }
      });
      this.routes = routes;
    },
    handleResize() {
      const fullHeight = document.getElementById("sidebar-drawer")
        ? document.getElementById("sidebar-drawer").getBoundingClientRect()
            .height
        : 0;
      const resizeHeight = document.getElementById("sidebar-resize")
        ? document.getElementById("sidebar-resize").getBoundingClientRect()
            .height
        : 0;
      const footerHeight = document.getElementById("sidebar-footer")
        ? document.getElementById("sidebar-footer").getBoundingClientRect()
            .height
        : 100;
      this.sidebarHeight = fullHeight - resizeHeight - footerHeight;
    },
    openGuide() {
      window.open(
        "https://www.synapsa.tech/category/guides-and-datasheets",
        "_blank"
      );
    },
    getAlertCount() {
      if (localStorage.getItem("id")) {
        const data = {
          rulesetBatch: this.monitorAuditorRulesetLoadBatch,
          integrityBatch: this.monitorAuditorIntegrityLoadBatch,
          rulesetFirewalls: this.monitorAuditorRulesetFirewalls,
        };
        alertCount(data)
          .then((response) => {
            this.alertCount = response.counts;
            this.auditorTaskProcessing = response.pending;
            this.auditorRulesetTaskProcessing = response.pendingRuleset;
            this.auditorCveTaskProcessing = response.pendingCve;
            this.auditorIntegrityTaskProcessing = response.pendingIntegrity;
            this.allAlertsCount = this.alertCount.total;
            this.auditorAlertsCount = this.alertCount.auditor;
            this.rulesetAlertsCount = this.alertCount.ruleset;
            this.rulesetLowAlertsCount = this.alertCount.rulesetLow;
            this.rulesetMediumAlertsCount = this.alertCount.rulesetMedium;
            this.rulesetHighAlertsCount = this.alertCount.rulesetHigh;
            this.rulesetCriticalAlertsCount = this.alertCount.rulesetCritical;
            this.cveAlertsCount = this.alertCount.cve;
            this.integrityAlertsCount = this.alertCount.integrity;
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list {
  .v-list-item {
    .v-list-item__title {
      color: #fff !important;
    }
    .v-list-item__icon {
      margin-right: 5px !important;
    }
  }
}
.resize-item {
  min-height: 40px;
  cursor: pointer;
}
</style>

<style lang="scss">
#sidebar-resize {
  .v-ripple__container {
    display: none !important;
  }
}
#sidebar-resize:hover:before,
#sidebar-resize:focus:before {
  opacity: 0;
}
#sidebar-drawer {
  .v-navigation-drawer__border {
    display: none;
  }
  box-shadow: 2px 13px 30px rgb(0 0 0 / 40%) !important;
}
.sidebar {
  /* 1px offset for the scrollbar */
  width: calc(100% - 1px);
  /* thinner scroll for firefox */
  scrollbar-width: thin;
  overflow-y: auto;
  overflow-x: hidden;
  .v-list-item__icon.v-list-group__header__prepend-icon {
    margin-right: 5px !important;

    .v-icon {
      font-size: 16px;
      color: #fff;
    }
  }
  .v-list-item__icon.v-list-group__header__append-icon {
    .v-icon {
      font-size: 16px;
      color: #fff;
    }
  }
  .v-list-item--active {
    .v-icon {
      color: #fff !important;
    }
  }
  .v-list-item {
    opacity: 0.7;
    transition-duration: 0.3s;
    .v-list-item__title {
      color: #fff;
    }
    .v-list-item__icon {
      margin-right: 5px !important;
    }
  }
  .v-list-item.active,
  .v-list-item--active {
    opacity: 1;
  }
  .v-list-item:hover {
    opacity: 1;
  }
  .v-list-item.active {
    .v-list-item__title {
      color: #69be28 !important;
    }
  }
  .v-list-item.active:before,
  .v-list-item:hover:before,
  .v-list-item.v-list-item--active:before {
    opacity: 0 !important;
  }
  .v-list-group.active {
    .v-list-group__header {
      opacity: 1;
    }
  }
  .alert-count {
    float: right;
    background: #e53355;
    color: #fff;
    padding: 0 5px;
    border-radius: 4px;
  }
}

.sidebar-menu-wrapper {
  /* Editing scorllbar for webkit browsers */
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #4d4e4e;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
