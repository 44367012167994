export const convertToCsv = {
  install(Vue) {
    Vue.prototype.$convertToCsv = function(data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(';'));

      for (const item of data) {
        const values = headers.map(header => {
          const field = item[header];
          if (field === null) {
            return `""`;
          }else{
            const escapedField = field.toString().replace(/"/g, '\\"');
            return `"${escapedField}"`;
          }

        });
        csvRows.push(values.join(';'));
      }

      return csvRows.join('\n');
    };
  },
};
