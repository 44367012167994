import request from "@/utils/request";

export function storeAuditorGroup(data) {
  return request({
    url: "api/auditorgroups",
    method: "post",
    data: data,
  });
}

export function updateAuditorGroup(data, id) {
  return request({
    url: "api/auditorgroups/" + id,
    method: "put",
    data: data,
  });
}

export function getAuditorGroup(id) {
  return request({
    url: "api/auditorgroups/" + id,
    method: "get",
  });
}

export function getAuditorGroups(page, size, order, by) {
  const orderBy = order && by ? "&order=" + order + "&by=" + by : "";
  let url = 'api/auditorgroups';
  if (page && size) {
    url += `?page=${page}&size=${size}${orderBy}`
  }
  return request({
    url,
    method: "get",
  });
}

export function deleteAuditorGroup(id) {
  return request({
    url: "api/auditorgroups/" + id,
    method: "delete",
  });
}
