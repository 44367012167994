<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import { Tables } from "@/utils/tables";

export default {
  name: "App",
  created() {
    this.$vuetify.theme.dark = localStorage.getItem("darkmode") === "true";
    // this.appBackground = localStorage.getItem("appBackground") === "true";
    Tables.setDefaultSettings();
    Tables.getSettings();
  },
  computed: {
    appBackground: {
      get() {
        return this.$store.state.settings.appBackground;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "appBackground",
          value: val,
        });
      },
    },
  },
};
</script>
