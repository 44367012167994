var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.route.children)?_c('v-list-item',{class:{ active: _vm.active.includes(_vm.route.id) },attrs:{"to":_vm.route.route,"color":"#fff","link":""}},[_c('v-list-item-icon',[(_vm.mini)?_c('v-tooltip',{attrs:{"right":"","color":"primary","open-delay":"1","nudge-right":"23"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.route.name === 'awaitingApproval' && _vm.alertCount.approval > 0)?_c('v-badge',{attrs:{"color":"bad-3","dot":""}},[_c('v-avatar',_vm._g(_vm._b({staticClass:"br-0",attrs:{"size":"20"}},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":_vm.active.includes(_vm.route.id)
                  ? _vm.location + '/images/icons/green/sidebar/' + _vm.route.icon
                  : _vm.location + _vm.iconRoute + _vm.route.icon,"alt":"Add"}})])],1):_c('v-avatar',_vm._g(_vm._b({staticClass:"br-0",attrs:{"size":"20"}},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":_vm.active.includes(_vm.route.id)
                ? _vm.location + '/images/icons/green/sidebar/' + _vm.route.icon
                : _vm.location + _vm.iconRoute + _vm.route.icon,"alt":"Add"}})])]}}],null,false,1521053896)},[_c('span',{staticClass:"font-roboto-condensed-regular"},[_vm._v(_vm._s(_vm.$t("sidebar." + _vm.route.name)))])]):_c('v-avatar',{staticClass:"br-0",attrs:{"size":"20"}},[_c('img',{attrs:{"src":_vm.active.includes(_vm.route.id)
            ? _vm.location + '/images/icons/green/sidebar/' + _vm.route.icon
            : _vm.location + _vm.iconRoute + _vm.route.icon,"alt":"Add"}})])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-roboto-condensed-regular"},[_vm._v(_vm._s(_vm.$t("sidebar." + _vm.route.name))+" "),_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"d-none"}},[(
            _vm.route.name === 'awaitingApproval' &&
            _vm.alertCount.approval > 0 &&
            _vm.showAlertCount
          )?_c('span',{staticClass:"alert-count"},[_vm._v(" "+_vm._s(_vm.alertCount.approval < 10000 ? _vm.alertCount.approval : ">9999")+" ")]):_vm._e()])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }