<template>
  <v-container :fluid="true" class="px-0 py-0">
    <v-row class="mx-0" :class="'mt-4'">
      <v-col cols="12" class="py-0 px-0">
        <!--TAB CONTENT-->

        <v-data-table
          :headers="headers"
          :items="results"
          :expanded.sync="expanded"
          hide-default-footer
          item-key="id"
          show-expand
          :loading="loading"
          loading-text="Loading data from API...please wait"
          class="synapsa-table task-table"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              class="expand-block"
              :class="results.indexOf(item) === results.length - 1 ? 'last' : ''"
            >
              <v-simple-table>
                <template v-slot:default>
                  <tbody class="expanded">
                    <tr class="expand-tr">
                      <td class="expand-td">
                        <div class="mb-2" v-if="searchable && item.result">
                          <SearchBar
                              search-label="Search in result"
                              @onSearch="onSearch(item, results.indexOf(item), $event)"
                              :is-loading="loading"
                              :width="'100'"
                          />
                          <div class="toggle">
                            <v-switch
                            v-model="showSearchedRowsOnly"
                            :value="true"
                            hide-details
                            label="Show searched rows only not entire alert section"
                            inset
                            :class="[showSearchedRowsOnly ? 'v-switch-on' : 'v-switch-off']"
                            class="target-background v-switch-off mb-1"
                            />
                          </div>
                          <v-btn
                              color="medium-1--text neutral-5"
                              small
                              @click="onSearch(item, results.indexOf(item), { searchTerm: 'MATCH.' })"
                          >
                            MATCH.
                          </v-btn>
                          <v-btn
                              color="medium-1--text neutral-5"
                              small
                              @click="onSearch(item, results.indexOf(item), { searchTerm: 'Alert' })"
                          >
                            Alert
                          </v-btn>
                        </div>

                        <div class="mb-2">
                          <v-btn
                              v-if="item.load_status < 100"
                              rounded
                              :disabled="contentLoading"
                              outlined
                              x-small
                              color="primary"
                              class="text-capitalize synapsa-tab"
                              @click="loadContent(item, results.indexOf(item))"
                          >
                            Load everything
                          </v-btn>
                          <v-icon color="primary" v-if="contentLoading" class="ml-1">
                            fas fa-spinner fa-spin
                          </v-icon>
                          <small class="neutral-4--text ml-1" v-else-if="item.result">
                            <span v-if="item.searchedCount">Searched term found: {{ item.searchedCount }} times</span>
                            <span v-else>Loaded: {{ item.load_status.toFixed(2) }}%</span>
                          </small>
                        </div>
                        <span v-html="item.result" class="item-result-text" />
                        <div class="mt-2">
                          <v-btn
                              v-if="item.load_status < 100"
                              rounded
                              :disabled="contentLoading"
                              outlined
                              x-small
                              color="primary"
                              class="text-capitalize"
                              @click="loadContent(item, results.indexOf(item))"
                          >
                            Load everything
                          </v-btn>
                          <v-icon color="primary" v-if="contentLoading" class="ml-1">
                            fas fa-spinner fa-spin
                          </v-icon>
                          <small class="neutral-4--text ml-1" v-else-if="item.result">
                            <span v-if="item.searchedCount">Searched term found: {{ item.searchedCount }} times</span>
                            <span v-else>Loaded: {{ item.load_status.toFixed(2) }}%</span>
                          </small>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
        <v-col
            v-if="pagination.last_page > 1"
            cols="12"
            class="text-right py-0 px-0 synapsa-pagination"
            :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
        >
          <v-pagination
              v-model="pagination.current_page"
              :length="pagination.last_page"
              :total-visible="4"
              circle
              class="d-inline-block pagination px-1 py-1"
              :class="pagination.current_page > 99 ? 'over100' : 'less100'"
          />
        </v-col>
        <!--END TAB CONTENT-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { getTaskResultContent, getTaskResultContentSearch, getTaskResults } from "@/api/system/task";
import { Errors } from "@/utils/error";
import SearchBar from "@/components/SearchBar";

export default {
  components: {
    SearchBar
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return undefined;
      },
    },
    searchable: {
      type: Boolean,
      default: function () {
        return false;
      },
    }
  },
  data() {
    return {
      expanded: [],
      valid: true,
      loading: false,
      contentLoading: false,

      state: true,

      headers: [
        { text: "Executed At", value: "ran_at" },
        { text: "Duration", value: "duration" },
      ],

      results: [],
      pagination: {
        total: undefined,
        size: 5,
        current_page: 1,
        last_page: undefined,
        from: undefined,
        to: undefined
      },

      showSearchedRowsOnly: false,
    };
  },
  watch: {
    'pagination.current_page': {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (this.id && newValue !== oldValue) {
          this.loadResults();
        }
      },
    },
  },
  methods: {
    loadResults() {
      this.loading = true;
      getTaskResults(this.id, this.pagination.current_page, this.pagination.size)
          .then((response) => {
            this.results = response.data;
            this.pagination = response.pagination;
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            Errors.show(e);
          });
    },
    loadContent(result, index) {
      this.contentLoading = true;
      getTaskResultContent(result.id)
          .then((response) => {
            this.results[index].result = response.data;
            this.results[index].load_status = 100;
            this.contentLoading = false;
          })
          .catch((e) => {
            this.contentLoading = false;
            Errors.show(e);
          });
    },
    onSearch(item, index, searchBar) {
      if (item.id && index >= 0) {
        this.contentLoading = true;
        getTaskResultContentSearch(item.id, searchBar.searchTerm, this.showSearchedRowsOnly)
            .then((response) => {
              this.results[index].searchedCount = response.data.count;
              this.results[index].result = (response.data.count || !searchBar.searchTerm) ? response.data.content : 'Searched term not found!';
              this.results[index].load_status = 100;
              this.contentLoading = false;
            })
            .catch((e) => {
              this.contentLoading = false;
              Errors.show(e);
            });
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.item-result-text {
  word-break: break-all;
}
.expand-td {
  padding: 10px !important;
}
.expand-tr {
  td {
    padding: 25px;
  }
}

.expand-tr td {
  td {
    padding: 25px;
  }
}
</style>
