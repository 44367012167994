<template>
  <v-card class="system-info-widget-block">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <widget-toolbar
        v-if="!loading && !failed"
        :title="$t('widgets.systemInfo.headline')"
        :data-time="dataLoadedTime"
        @remove="remove"
      />
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-card-text v-if="!loading && !failed" class="pb-1 with-bg pt-1">
        <div class="d-inline-block widget-icon mt-1">
          <v-img
            lazy-src="images/icons/system_info.svg"
            src="images/icons/system_info.svg"
            alt="Pie chart graph"
            max-height="55"
            max-width="45"
            contain
          />
        </div>
        <div
          class="d-inline-block widget-content"
          style="width: calc(100% - 65px)"
        >
          <v-card-text class="py-1">
            <div
              class="d-inline-block text-right mr-2 font-roboto-condensed-regular"
              :style="screenWidth > 1290 ? 'width: 115px;' : ''"
            >
              System disk space
            </div>
            <div class="d-inline-block">
              <div style="display: inline-table">
                <div class="font-roboto-condensed-regular">
                  <span class="font-roboto-condensed-bold">Size</span>
                  {{ diskSpace }}
                </div>
                <div
                  :class="
                    availableSpaceAlert
                      ? 'font-roboto-condensed-bold bad-2--text'
                      : 'font-roboto-condensed-regular'
                  "
                >
                  <span class="font-roboto-condensed-bold">Free</span>
                  {{ availableSpace }}
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-text class="py-1">
            <div
              class="d-inline-block text-right mr-2 font-roboto-condensed-regular"
              :style="screenWidth > 1290 ? 'width: 115px;' : ''"
            >
              Application space
            </div>
            <div
              class="d-inline-block font-roboto-condensed-bold"
              style="display: flex; align-items: center"
            >
              <div style="display: inline-table">
                <!--                <div class="font-roboto-condensed-regular ">-->
                <!--                  <span class="font-roboto-condensed-bold">FE</span>-->
                <!--                  {{ fileSizeFe }}-->
                <!--                </div>-->
                <!--                <div class="font-roboto-condensed-regular ">-->
                <!--                  <span class="font-roboto-condensed-bold">BE</span>-->
                <!--                  {{ fileSizeBe }}-->
                <!--                </div>-->
                <div class="font-roboto-condensed-regular">
                  <span class="font-roboto-condensed-bold">Logs</span>
                  {{ fileSizeLogs }}
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-text class="py-1">
            <div
              class="d-inline-block text-right mr-2 font-roboto-condensed-regular"
              :style="screenWidth > 1290 ? 'width: 115px;' : ''"
            >
              Database
            </div>
            <div
              class="d-inline-block font-roboto-condensed-bold"
              style="display: flex; align-items: center"
            >
              <div style="display: inline-table">
                <div
                  :class="
                    databaseFirstAlert
                      ? 'font-roboto-condensed-bold bad-2--text'
                      : 'font-roboto-condensed-regular'
                  "
                >
                  <span class="font-roboto-condensed-bold">Primary</span>
                  {{ databaseFirst }}
                </div>
                <div
                  :class="
                    databaseSecondAlert
                      ? 'font-roboto-condensed-bold bad-2--text'
                      : 'font-roboto-condensed-regular'
                  "
                >
                  <span class="font-roboto-condensed-bold">Secondary</span>
                  {{ databaseSecond }}
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-text class="py-1">
            <div
              class="d-inline-block text-right mr-2 font-roboto-condensed-regular"
              :style="screenWidth > 1290 ? 'width: 115px;' : ''"
            >
              Tables with logs
            </div>
            <div
              class="d-inline-block font-roboto-condensed-bold"
              style="display: flex; align-items: center"
            >
              <div style="display: inline-table">
                <div
                  :class="
                    systemlogAlert
                      ? 'font-roboto-condensed-bold bad-2--text'
                      : 'font-roboto-condensed-regular'
                  "
                >
                  <span class="font-roboto-condensed-bold">System</span>
                  {{ databaseSystemLogs }}
                </div>
                <div
                  :class="
                    tasklogAlert
                      ? 'font-roboto-condensed-bold bad-2--text'
                      : 'font-roboto-condensed-regular'
                  "
                >
                  <span class="font-roboto-condensed-bold">Task</span>
                  {{ databaseTaskLogs }}
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-card-text>
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-card-actions v-if="!loading && !failed" class="with-bg px-4">
        <v-progress-linear :value="percentage" height="39" color="#64c52a">
          <strong>{{ percentage }}% free</strong>
        </v-progress-linear>
      </v-card-actions>
    </transition>
    <v-overlay :value="loading">
      <v-progress-circular absolute indeterminate size="40" color="good-3" />
    </v-overlay>
    <v-overlay :value="failed">
      <v-card-text>
        {{ $t("widgets.systemInfo.headline") }} - loading data from api failed.
      </v-card-text>
      <v-card-actions>
        <v-btn small color="bad-2" class="mx-auto" @click="loadData">
          Reload
        </v-btn>
      </v-card-actions>
    </v-overlay>
  </v-card>
</template>

<script>
import { widgetSystemInfo } from "@/api/dashboard";
import WidgetToolbar from "@/components/Widgets/WidgetToolbar";

export default {
  components: {
    WidgetToolbar,
  },
  props: {
    i: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      diskSpace: "0 GB",
      availableSpace: "0 GB",
      percentage: 0,
      fileSizeLogs: 0,
      databaseFirst: 0,
      databaseSystemLogs: 0,
      databaseTaskLogs: 0,
      databaseSecond: 0,
      availableSpaceAlert: false,
      databaseFirstAlert: false,
      databaseSecondAlert: false,
      systemlogAlert: false,
      tasklogAlert: false,
      loading: true,
      failed: false,
      dataLoadedTime: "",
    };
  },
  computed: {
    screenWidth: {
      get() {
        return this.$store.state.settings.screenWidth;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "screenWidth",
          value: val,
        });
      },
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.failed = false;
      this.loading = true;

      const storedData = JSON.parse(
        localStorage.getItem("widgetSystemInfoData")
      );
      const savedTime = JSON.parse(
        localStorage.getItem("widgetSystemInfoTimestamp")
      );

      if (savedTime && Date.now() - parseInt(savedTime, 10) < 300000) {
        this.dataLoadedTime = "";
        const transformedTimeStamp = new Date(savedTime);
        const hours = transformedTimeStamp.getHours();
        const minutes = transformedTimeStamp.getMinutes();
        this.dataLoadedTime = `${hours}:${minutes}`;
      }

      if (
        storedData &&
        savedTime &&
        Date.now() - parseInt(savedTime, 10) < 300000
      ) {
        this.updateWidgetData(storedData);
        this.loading = false;
      } else {
        widgetSystemInfo()
          .then((response) => {
            this.updateWidgetData(response);
            this.loading = false;

            localStorage.setItem(
              "widgetSystemInfoData",
              JSON.stringify(response)
            );
            localStorage.setItem("widgetSystemInfoTimestamp", Date.now());

            const savedTime = JSON.parse(
              localStorage.getItem("widgetSystemLogsTimestamp")
            );

            const transformedTimeStamp = new Date(savedTime);
            const hours = transformedTimeStamp.getHours();
            const minutes = transformedTimeStamp.getMinutes();
            this.dataLoadedTime = `${hours}:${minutes}`;
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }
    },
    updateWidgetData(response) {
      this.diskSpace = this.formatBytes(response.disk_size, 2);
      this.availableSpace = this.formatBytes(response.disk_free, 2);
      this.availableSpaceAlert = response.disk_free_alert;
      this.fileSizeLogs = this.formatBytes(response.file_size_logs, 2);
      this.databaseFirst = this.formatBytes(response.database_first.all, 2);
      this.databaseFirstAlert = response.database_first_alert;
      this.systemlogAlert = response.systemlog_alert;
      this.tasklogAlert = response.tasklog_alert;
      this.databaseSystemLogs = this.formatBytes(
        response.database_first.systemlogs,
        2
      );
      this.databaseTaskLogs = this.formatBytes(
        response.database_first.task_results,
        2
      );
      this.databaseSecond = this.formatBytes(response.database_second.all, 2);
      this.databaseSecondAlert = response.database_second_alert;
      this.percentage = Math.round(
        (response.disk_free / response.disk_size) * 100
      );
    },
    formatBytes(bytes, decimals) {
      if (bytes === 0) return "0 GB";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    remove() {
      this.$emit("remove", this.i);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-icon {
  vertical-align: sub;
  width: 65px;
  height: 50px;
}
.v-card-text {
  background: #fff;
  border-radius: 0;
}
.v-card__actions {
  border-radius: 0 0 15px 15px;
}
.dashboard-container {
  .v-card.theme--dark {
    .v-card__title {
      border-bottom: 2px solid #fff;
    }
  }
}
.dashboard-container {
  .v-card.theme--light {
    .v-card__title {
      border-bottom: 2px solid #3d3d3f;
    }
  }
}
</style>
