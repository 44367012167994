/* eslint no-undef: 0 */
/* eslint-disable no-alert, no-console */
(function (root, factory) {
  if (typeof define === "function" && define.amd) {
    // AMD. Register as an anonymous module.
    define(["exports", "echarts"], factory);
  } else if (
    typeof exports === "object" &&
    typeof exports.nodeName !== "string"
  ) {
    // CommonJS
    factory(exports, require("echarts"));
  } else {
    // Browser globals
    factory({}, root.echarts);
  }
})(this, function (exports, echarts) {
  var log = function (msg) {
    if (typeof console !== "undefined") {
      console && console.error && console.error(msg);
    }
  };
  if (!echarts) {
    log("ECharts is not Loaded");
    return;
  }
  echarts.registerTheme("darkTheme", {
    color: [
      "#8cd4dd",
      "#a6dc86",
      "#ffd976",
      "#f28d9f",
      "#d6dadb",
      "#1b7984",
      "#b22641",
      "#cca23c",
      "#4e9a1e",
    ],
    backgroundColor: "rgba(0,0,0,0)",
    textStyle: {},
    title: {
      textStyle: {
        color: "#eeeeee",
      },
      subtextStyle: {
        color: "#eeeeee",
      },
    },
    line: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 4,
      symbol: "emptyCircle",
      smooth: false,
    },
    radar: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 4,
      symbol: "emptyCircle",
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: "#eeeeee",
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#eeeeee",
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#eeeeee",
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#eeeeee",
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#eeeeee",
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#eeeeee",
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#eeeeee",
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#eeeeee",
      },
    },
    candlestick: {
      itemStyle: {
        color: "#c23531",
        color0: "#314656",
        borderColor: "#c23531",
        borderColor0: "#314656",
        borderWidth: 1,
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: "#eeeeee",
      },
      lineStyle: {
        width: 1,
        color: "#aaaaaa",
      },
      symbolSize: 4,
      symbol: "emptyCircle",
      smooth: false,
      color: [
        "#8cd4dd",
        "#a6dc86",
        "#ffd976",
        "#f28d9f",
        "#d6dadb",
        "#1b7984",
        "#b22641",
        "#cca23c",
        "#4e9a1e",
      ],
      label: {
        color: "#eeeeee",
      },
    },
    map: {
      itemStyle: {
        normal: {
          areaColor: "#eeeeee",
          borderColor: "#444444",
          borderWidth: 0.5,
        },
        emphasis: {
          areaColor: "rgba(255,215,0,0.8)",
          borderColor: "#444444",
          borderWidth: 1,
        },
      },
      label: {
        normal: {
          textStyle: {
            color: "#000000",
          },
        },
        emphasis: {
          textStyle: {
            color: "rgb(100,0,0)",
          },
        },
      },
    },
    geo: {
      itemStyle: {
        normal: {
          areaColor: "#eeeeee",
          borderColor: "#444444",
          borderWidth: 0.5,
        },
        emphasis: {
          areaColor: "rgba(255,215,0,0.8)",
          borderColor: "#444444",
          borderWidth: 1,
        },
      },
      label: {
        normal: {
          textStyle: {
            color: "#000000",
          },
        },
        emphasis: {
          textStyle: {
            color: "rgb(100,0,0)",
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: "#eeeeee",
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: "#eeeeee",
        },
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#eeeeee",
        },
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ["#ccc"],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: "#eeeeee",
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: "#eeeeee",
        },
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#eeeeee",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#eeeeee"],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: "#eeeeee",
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: "#eeeeee",
        },
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#eeeeee",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#eeeeee"],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: "#eeeeee",
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: "#eeeeee",
        },
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#eeeeee",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ["#eeeeee"],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ["rgba(250,250,250,0.3)", "rgba(200,200,200,0.3)"],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: "#999999",
        },
        emphasis: {
          borderColor: "#666666",
        },
      },
    },
    legend: {
      textStyle: {
        color: "#eeeeee",
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: "#cccccc",
          width: 1,
        },
        crossStyle: {
          color: "#cccccc",
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: "#293c55",
        width: 1,
      },
      itemStyle: {
        normal: {
          color: "#293c55",
          borderWidth: 1,
        },
        emphasis: {
          color: "#a9334c",
        },
      },
      controlStyle: {
        normal: {
          color: "#293c55",
          borderColor: "#293c55",
          borderWidth: 0.5,
        },
        emphasis: {
          color: "#293c55",
          borderColor: "#293c55",
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: "#e43c59",
        borderColor: "#c23531",
      },
      label: {
        normal: {
          textStyle: {
            color: "#293c55",
          },
        },
        emphasis: {
          textStyle: {
            color: "#293c55",
          },
        },
      },
    },
    visualMap: {
      color: ["#bf444c", "#d88273", "#f6efa6"],
    },
    dataZoom: {
      backgroundColor: "rgba(47,69,84,0)",
      dataBackgroundColor: "rgba(47,69,84,0.3)",
      fillerColor: "rgba(167,183,204,0.4)",
      handleColor: "#a7b7cc",
      handleSize: "100%",
      textStyle: {
        color: "#333333",
      },
    },
    markPoint: {
      label: {
        color: "#eeeeee",
      },
      emphasis: {
        label: {
          color: "#eeeeee",
        },
      },
    },
  });
});
/* eslint-enable no-alert */
