<template>
  <v-container
    fluid
    class="app-bar-container"
    :class="!component ? 'py-0' : ''"
  >
    <v-row v-if="component" align="center" justify="center">
      <v-col cols="12" class="py-0 app-bar-block">
        <v-expansion-panels v-model="panel" popout :readonly="disabled">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <v-row>
                <v-col cols="8">
                  <h1
                    class="text-uppercase font-size-25"
                    v-html="$t('sidebar.' + $route.name, { title: title })"
                  />
                </v-col>
                <v-col cols="4" class="text-right pt-2 pb-1">
                  <v-avatar v-if="!disabled" size="30" class="br-0 mr-1">
                    <img
                      :src="
                        $vuetify.theme.dark
                          ? location + '/images/icons/white/setup-white.png'
                          : location + '/images/icons/dark/setup.png'
                      "
                      alt="Add"
                    />
                  </v-avatar>
                  <v-avatar
                    v-if="!disabled"
                    size="25"
                    class="br-0 group-append-icon"
                  >
                    <img
                      :src="
                        $vuetify.theme.dark
                          ? location +
                            '/images/icons/white/sidebar/arrow-bottom.svg'
                          : location +
                            '/images/icons/dark/sidebar/arrow-bottom.svg'
                      "
                      alt="Add"
                    />
                  </v-avatar>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <component
                :is="component"
                @default="setDefault($event)"
                :process="process"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardSettings from "@/views/dashboard/settings";
import AwaitingApprovalSettings from "@/views/awaitingApproval/settings";
import ObjectsSettings from "@/views/objects/settings";
import SettingsSettings from "@/views/settings/settings";
import DatafeedsAddressSettings from "@/views/dataFeeds/addressLists/settings";
import DatafeedsDomainSettings from "@/views/dataFeeds/domainLists/settings";
import DatafeedsUrlSettings from "@/views/dataFeeds/urlLists/settings";
import InterconnectorSettings from "@/views/interconnector/settings";
import SystemSettings from "@/views/system/settings";
import AuditorSettings from "@/views/auditor/settings";
import MonitorAuditorSettings from "@/views/monitor/auditor/settings";
import MonitorEventsSettings from "@/views/monitor/events/settings";
import MonitorInterconnectorSettings from "@/views/monitor/interconnector/settings";
import ToolboxSettings from "@/views/toolbox/settings";
import { Errors } from "@/utils/error";
import { setUserTableSettings } from "@/api/system/user";
import { getAddressListName } from "@/api/datafeeds/addresslist/custom";
import { getAddressListPredefinedName } from "@/api/datafeeds/addresslist/predefined";
import { getDomainListName } from "@/api/datafeeds/domainlist/custom";
import { getDomainListPredefinedName } from "@/api/datafeeds/domainlist/predefined";
import { getUrlListName } from "@/api/datafeeds/urllist/custom";
import { getUrlListPredefinedName } from "@/api/datafeeds/urllist/predefined";
import { getTemplateName } from "../../api/auditor/template";

export default {
  components: {
    DashboardSettings,
    AwaitingApprovalSettings,
    ObjectsSettings,
    SettingsSettings,
    DatafeedsAddressSettings,
    DatafeedsDomainSettings,
    DatafeedsUrlSettings,
    InterconnectorSettings,
    SystemSettings,
    AuditorSettings,
    MonitorAuditorSettings,
    MonitorEventsSettings,
    MonitorInterconnectorSettings,
    ToolboxSettings,
  },
  props: {
    location: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      panel: [],
      component: null,
      disabled: false,
      process: false,

      title: "...",

      disableRoutes: [
        "dashboard",
        "addressListsPredefinedShow",
        "addressListsPredefinedIPv4",
        "addressListsPredefinedIPv6",
        "domainListsPredefinedShow",
        "urlListsPredefinedShow",
        "emails",
        "syslog",
        "address",
        "notificationProfiles",
        "remoteServers",
        "threatSimulator",
        "policyTester",
        "backup",
        "logs",
        "tasks",
        "users",
        "roles",
        "version",
        "license",
        "ssl",
        "synapsa",
        "dns",
        "maintenance",
        "login",
      ],
    };
  },
  computed: {
    previousUrl: {
      get() {
        return this.$store.state.settings.previousUrl;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "previousUrl",
          value: val,
        });
      },
    },
    defaultTables: {
      get() {
        return this.$store.state.settings.defaultTables;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "defaultTables",
          value: val,
        });
      },
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.renderPanel();
      },
      immediate: true,
    },
  },
  created() {
    this.renderPanel();
  },
  methods: {
    renderPanel() {
      this.disabled = this.disableRoutes.includes(this.$route.name);
      if (this.$route.name === "dashboard") {
        this.component = DashboardSettings;
      } else if (
        [
          "/awaiting_approval/show/security_policy",
          "/awaiting_approval/show/data_feeds",
        ].includes(this.$route.path)
      ) {
        this.component = AwaitingApprovalSettings;
      } else if (
        [
          "miners",
          "firewalls",
          "centralManagement",
          "firewallGroups",
          "parser",
          "api",
          "listener",
          "integration",
          "apiPaloAlto",
          "apiFortinet",
          "apiCheckpoint",
          "apiSophos",
          "apiCustom",
        ].includes(this.$route.name)
      ) {
        this.component = SettingsSettings;
      } else if (
        [
          "/objects/show/emails",
          "/objects/show/syslogs",
          "/objects/show/notification_profiles",
          "/objects/show/remote_servers",
          "/objects/show/address",
        ].includes(this.$route.path)
      ) {
        this.component = ObjectsSettings;
      } else if (
        [
          "/data_feeds/address_lists/custom/all",
          "/data_feeds/address_lists/predefined/all",
        ].includes(this.$route.path) ||
        ["/data_feeds/address_lists/connection"].includes(this.$route.path) ||
        [
          "addressListsCustomShow",
          "addressListsCustomSource",
          "addressListsCustomDestination",
        ].includes(this.$route.name) ||
        [
          "addressListsPredefinedShow",
          "addressListsPredefinedIPv4",
          "addressListsPredefinedIPv6",
        ].includes(this.$route.name)
      ) {
        this.dataFeedsAddressTitle();
        this.component = DatafeedsAddressSettings;
      } else if (
        [
          "/data_feeds/domain_lists/custom/all",
          "/data_feeds/domain_lists/predefined/all",
        ].includes(this.$route.path) ||
        ["domainListsCustomShow"].includes(this.$route.name) ||
        ["domainListsPredefinedShow"].includes(this.$route.name)
      ) {
        this.dataFeedsDomainTitle();
        this.component = DatafeedsDomainSettings;
      } else if (
        [
          "/data_feeds/url_lists/custom/all",
          "/data_feeds/url_lists/predefined/all",
        ].includes(this.$route.path) ||
        ["urlListsCustomShow"].includes(this.$route.name) ||
        ["urlListsPredefinedShow"].includes(this.$route.name)
      ) {
        this.dataFeedsUrlTitle();
        this.component = DatafeedsUrlSettings;
      } else if (
        [
          "/system/show/backup",
          "/system/show/backup/configuration",
          "/system/show/backup/all",
          "/system/show/backup/remote",
          "/system/show/logs",
          "/system/show/tasks",
          "/system/show/users",
          "/system/show/roles",
          "/system/show/version",
          "/system/show/license",
          "/system/show/ssl",
          "/system/show/synapsa",
          "/system/show/dns",
          "/system/show/maintenance",
          "/system/show/login",
        ].includes(this.$route.path)
      ) {
        this.component = SystemSettings;
      } else if (
        [
          "/interconnector/rules/default",
          "/interconnector/rules/script",
          "/interconnector/security_policy",
        ].includes(this.$route.path)
      ) {
        this.component = InterconnectorSettings;
      } else if (
        [
          "/auditor/show/ruleset",
          "/auditor/show/cve",
          "/auditor/show/integrity",
          "/auditor/show/groups",
          "/auditor/show/templates",
        ].includes(this.$route.path)
      ) {
        this.component = AuditorSettings;
      } else if (this.$route.name === "auditorTemplateRules") {
        this.auditorTemplateRulesTitle();
        this.component = AuditorSettings;
      } else if (
        [
          "/monitor/auditor/ruleset/confirmed",
          "/monitor/auditor/ruleset/fixed",
          "/monitor/auditor/cve/active",
          "/monitor/auditor/cve/confirmed",
          "/monitor/auditor/integrity/active",
          "/monitor/auditor/integrity/confirmed",
          "/monitor/auditor/integrity/history",
        ].includes(this.$route.path)
      ) {
        this.component = MonitorAuditorSettings;
      } else if (
        [
          "/monitor/events/threats",
          "/monitor/events/system",
          "/monitor/events/syslog",
        ].includes(this.$route.path)
      ) {
        this.component = MonitorEventsSettings;
      } else if (
        [
          "/monitor/interconnector/successful",
          "/monitor/interconnector/unsuccessful",
          "/monitor/interconnector/reject",
          "/monitor/interconnector/revoke",
        ].includes(this.$route.path)
      ) {
        this.component = MonitorInterconnectorSettings;
      } else if (
        ["/toolbox/threat_simulator", "/toolbox/policy_tester"].includes(
          this.$route.path
        )
      ) {
        this.component = ToolboxSettings;
      } else {
        this.component = null;
      }
    },
    setDefault(table) {
      const headers = [];
      this.defaultTables[table].headers.map((column) => {
        headers.push({ ...column });
      });
      const expanded = [];
      this.defaultTables[table].expanded.map((column) => {
        expanded.push({ ...column });
      });
      localStorage.removeItem(table);
      this.$store.state.tables[table] = {
        headers: headers,
        expanded: expanded,
      };
      this.process = true;
      const data = {
        table_name: table,
        table_data: null,
      };
      setUserTableSettings(data)
        .then(() => {
          this.process = false;
          this.$toast.open({
            message: "Table was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    dataFeedsAddressTitle() {
      if (
        ["addressListsCustomSource", "addressListsCustomDestination"].includes(
          this.$route.name
        )
      ) {
        this.title =
          [
            "addressListsCustomSource",
            "addressListsCustomDestination",
          ].includes(this.previousUrl.name) &&
          this.previousUrl.params.id === this.$route.params.id
            ? this.title
            : "...";
        getAddressListName(this.$route.params.id)
          .then((response) => {
            this.title = response.data.name;
          })
          .catch((e) => {
            Errors.show(e);
          });
      } else if (
        ["addressListsPredefinedIPv4", "addressListsPredefinedIPv6"].includes(
          this.$route.name
        )
      ) {
        this.title =
          ["addressListsPredefinedIPv4", "addressListsPredefinedIPv6"].includes(
            this.previousUrl.name
          ) && this.previousUrl.params.id === this.$route.params.id
            ? this.title
            : "...";
        getAddressListPredefinedName(this.$route.params.id)
          .then((response) => {
            this.title = response.data.name;
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
    dataFeedsDomainTitle() {
      this.title = "...";
      if (this.$route.name === "domainListsCustomShow") {
        getDomainListName(this.$route.params.id)
          .then((response) => {
            this.title = response.data.name;
          })
          .catch((e) => {
            Errors.show(e);
          });
      } else if (this.$route.name === "domainListsPredefinedShow") {
        getDomainListPredefinedName(this.$route.params.id)
          .then((response) => {
            this.title = response.data.name;
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
    dataFeedsUrlTitle() {
      this.title = "...";
      if (this.$route.name === "urlListsCustomShow") {
        getUrlListName(this.$route.params.id)
          .then((response) => {
            this.title = response.data.name;
          })
          .catch((e) => {
            Errors.show(e);
          });
      } else if (this.$route.name === "urlListsPredefinedShow") {
        getUrlListPredefinedName(this.$route.params.id)
          .then((response) => {
            this.title = response.data.name;
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
    auditorTemplateRulesTitle() {
      this.title = "...";
      if (this.$route.name === "auditorTemplateRules") {
        getTemplateName(this.$route.params.id)
          .then((response) => {
            this.title = response.name;
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar-block {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.v-expansion-panels--popout > .v-expansion-panel {
  max-width: 100%;
  border-radius: 0 0 15px 15px;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 0 0 15px 15px;
  .group-append-icon {
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
}
.group-append-icon {
  -moz-transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: #1e1e1e96 !important;
  color: #fff !important;
  .v-icon {
    color: #fff !important;
  }
}
.theme--dark.v-expansion-panels .v-expansion-panel:before {
  box-shadow: 13px 13px 30px rgb(0 0 0 / 30%);
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #ffffff6e !important;
  color: #3d3d3f !important;
  .v-icon {
    color: #3d3d3f !important;
  }
}
.theme--light.v-expansion-panels .v-expansion-panel:before {
  box-shadow: 13px 13px 30px rgb(0 0 0 / 10%);
}
</style>

<style lang="scss">
.app-bar-container {
  .v-expansion-panel-header__icon {
    display: none;
  }
}
</style>
