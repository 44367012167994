<template>
  <v-card class="miners-widget-block">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <widget-toolbar
        v-if="!loading && !failed"
        :title="$t('widgets.firewalls.headline')"
        :data-time="dataLoadedTime"
        has-chart
        @remove="remove"
        @chart="chart"
      />
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-card-text
        v-if="!loading && !failed"
        class="with-bg pt-1"
        :class="showGraph ? 'with-graph pb-0' : 'pb-4'"
      >
        <div class="d-inline-block widget-icon mt-1">
          <v-img
            lazy-src="images/icons/firewall.svg"
            src="images/icons/firewall.svg"
            alt="Firewall avatar"
            max-height="55"
            max-width="45"
            contain
          />
        </div>
        <div
          class="d-inline-block widget-content"
          style="width: calc(100% - 65px)"
        >
          <v-card-text class="py-1">
            <div
              class="d-inline-block text-right mr-2 font-roboto-condensed-regular"
              style="width: 90px"
            >
              Connected
            </div>
            <div class="d-inline-block font-roboto-condensed-bold">
              {{ connectedFirewalls }}
            </div>
          </v-card-text>
          <v-divider />
          <v-card-text class="py-1">
            <div
              class="d-inline-block text-right mr-2 font-roboto-condensed-regular"
              style="width: 90px"
            >
              Disconnected
            </div>
            <div class="d-inline-block font-roboto-condensed-bold">
              {{ disconnectedFirewalls }}
            </div>
          </v-card-text>
        </div>
      </v-card-text>
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-card-actions
        v-if="!loading && showGraph && !failed"
        class="with-bg px-4 pb-5"
      >
        <chart
          :connected="connectedFirewalls"
          :disconnected="disconnectedFirewalls"
        />
      </v-card-actions>
    </transition>
    <v-overlay :value="loading">
      <v-progress-circular absolute indeterminate size="40" color="good-3" />
    </v-overlay>
    <v-overlay :value="failed">
      <v-card-text>
        {{ $t("widgets.firewalls.headline") }} - loading data from api failed.
      </v-card-text>
      <v-card-actions>
        <v-btn small color="bad-2" class="mx-auto" @click="loadData">
          Reload
        </v-btn>
      </v-card-actions>
    </v-overlay>
  </v-card>
</template>

<script>
import Chart from "@/components/Widgets/FirewallsWidget/chart";
import { widgetFirewalls } from "@/api/dashboard";
import WidgetToolbar from "@/components/Widgets/WidgetToolbar";

export default {
  components: {
    Chart,
    WidgetToolbar,
  },
  props: {
    i: {
      type: String,
      default: function () {
        return "0";
      },
    },
    w: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    h: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {
      connectedFirewalls: 0,
      disconnectedFirewalls: 0,
      showGraph: false,
      loading: true,
      failed: false,
      dataLoadedTime: "",
    };
  },
  watch: {
    h: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.showGraph = newValue !== 3;
      },
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.failed = false;
      this.loading = true;

      const storedData = JSON.parse(
        localStorage.getItem("widgetFirewallsData")
      );
      const savedTime = JSON.parse(
        localStorage.getItem("widgetFirewallsTimestamp")
      );

      if (savedTime && Date.now() - parseInt(savedTime, 10) < 300000) {
        this.dataLoadedTime = "";
        const transformedTimeStamp = new Date(savedTime);
        const hours = transformedTimeStamp.getHours();
        const minutes = transformedTimeStamp.getMinutes();
        this.dataLoadedTime = `${hours}:${minutes}`;
      }

      if (
        storedData &&
        savedTime &&
        Date.now() - parseInt(savedTime, 10) < 300000
      ) {
        this.updateWidgetData(storedData);
        this.loading = false;
      } else {
        widgetFirewalls()
          .then((response) => {
            this.updateWidgetData(response);
            this.loading = false;

            localStorage.setItem(
              "widgetFirewallsData",
              JSON.stringify(response)
            );
            localStorage.setItem("widgetFirewallsTimestamp", Date.now());

            const savedTime = JSON.parse(
              localStorage.getItem("widgetSystemLogsTimestamp")
            );

            const transformedTimeStamp = new Date(savedTime);
            const hours = transformedTimeStamp.getHours();
            const minutes = transformedTimeStamp.getMinutes();
            this.dataLoadedTime = `${hours}:${minutes}`;
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }
    },
    updateWidgetData(response) {
      this.connectedFirewalls = response.connected;
      this.disconnectedFirewalls = response.disconnected;
    },
    chart() {
      const height = this.showGraph ? 3 : 7;
      this.$emit("resize", { i: this.i, h: height });
    },
    remove() {
      this.$emit("remove", this.i);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-icon {
  vertical-align: sub;
  width: 65px;
  height: 50px;
}
.v-card-text {
  background: #24272169;
  border-radius: 0;
}
.v-card__text.with-bg:not(.with-graph) {
  border-radius: 0 0 15px 15px !important;
}
.v-card__actions {
  border-radius: 0 0 15px 15px !important;
}
.dashboard-container {
  .v-card.theme--dark {
    .v-card__title {
      border-bottom: 2px solid #fff;
    }
  }
}
.dashboard-container {
  .v-card.theme--light {
    .v-card__title {
      border-bottom: 2px solid #3d3d3f;
    }
  }
}
</style>
