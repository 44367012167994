<template>
  <v-row id="add-form-topbar" class="mt-0 topbar-block">
    <v-col
      :cols="size === 1 ? 6 : 7"
      class="py-1"
      :class="size === 1 ? 'pl-1 pr-0' : ''"
    >
      <div v-if="keywordsResize2.length > 0">
        <v-btn v-if="[1].includes(size)" icon small @click="resize('bigger')">
          <v-avatar size="23" class="br-0">
            <img
              :src="
                $vuetify.theme.dark
                  ? location + '/images/icons/small/arrow-left-white.png'
                  : location + '/images/icons/small/arrow-left.png'
              "
              alt="Arrow left"
              class="arrow-icon"
            />
          </v-avatar>
        </v-btn>
        <v-btn v-if="size !== 1" icon small @click="resize('smaller')">
          <v-avatar size="23" class="br-0">
            <img
              :src="
                $vuetify.theme.dark
                  ? location + '/images/icons/small/arrow-right-white.png'
                  : location + '/images/icons/small/arrow-right.png'
              "
              alt="Arrow right"
              class="arrow-icon"
            />
          </v-avatar>
        </v-btn>
      </div>
      <div v-if="keywordsResize2 <= 0">
        <v-btn
          v-if="![4, 3].includes(size)"
          icon
          small
          @click="resize('bigger')"
        >
          <v-avatar size="23" class="br-0">
            <img
              :src="
                $vuetify.theme.dark
                  ? location + '/images/icons/small/arrow-left-white.png'
                  : location + '/images/icons/small/arrow-left.png'
              "
              alt="Arrow left"
              class="arrow-icon"
            />
          </v-avatar>
        </v-btn>
        <v-btn v-if="size !== 1" icon small @click="resize('smaller')">
          <v-avatar size="23" class="br-0">
            <img
              :src="
                $vuetify.theme.dark
                  ? location + '/images/icons/small/arrow-right-white.png'
                  : location + '/images/icons/small/arrow-right.png'
              "
              alt="Arrow right"
              class="arrow-icon"
            />
          </v-avatar>
        </v-btn>
      </div>
    </v-col>
    <v-col
      :cols="size === 1 ? 6 : 5"
      class="py-1 text-right"
      :class="size === 1 ? 'pl-0 pr-1' : ''"
    >
      <!--TIMELINE BUTTON-->
      <v-menu
        v-if="hasTimeline && size !== 1"
        v-model="timelineMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        :nudge-top="5"
        :nudge-left="240"
        offset
        content-class="synapsa-widget-timeline-menu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            :size="15"
            class="br-0 cursor-pointer widget-toolbar-icon"
            v-bind="attrs"
            v-on="on"
          >
            <img
              :src="
                $vuetify.theme.dark
                  ? location + '/images/icons/white/time.svg'
                  : location + '/images/icons/dark/time.svg'
              "
              alt="Add"
            />
          </v-avatar>
        </template>

        <v-card>
          <v-list>
            <v-list-item class="pl-1 pr-2">
              <v-list-item-content class="py-0">
                <v-virtual-scroll :items="items" height="100" item-height="25">
                  <template v-slot:default="{ item }">
                    <v-list-item
                      class="px-2 pb-1"
                      :class="{ active: time === item.id }"
                      @click="timeline(item)"
                    >
                      <v-list-item-content class="py-1 pl-2">
                        <v-list-item-title class="font-size-12"
                          >{{ item.name }}
                          <transition
                            enter-active-class="animate__animated animate__heartBeat"
                            leave-active-class="d-none"
                          >
                            <v-icon
                              v-if="time === item.id"
                              color="primary"
                              x-small
                              class="float-right mr-2"
                              >mdi mdi-check</v-icon
                            >
                          </transition>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <!--END TIMELINE BUTTON-->

      <!-- TOGGLE CHART TYPE BUTTON -->
      <v-btn icon small @click="toggleChart" v-if="size !== 1">
        <v-avatar v-if="hasBarChart" size="15" class="br-0 ml-2 cursor-pointer">
          <img
            v-if="chartBar"
            :src="
              $vuetify.theme.dark
                ? location + '/images/icons/white/chart_bar.svg'
                : location + '/images/icons/dark/chart_bar.svg'
            "
          />
          <img
            v-else
            :src="
              $vuetify.theme.dark
                ? location + '/images/icons/white/chart_line.svg'
                : location + '/images/icons/dark/chart_line.svg'
            "
          />
        </v-avatar>
      </v-btn>
      <!-- END TOGGLE CHART TYPE BUTTON -->

      <v-btn icon small @click="close">
        <v-avatar size="15">
          <img
            :src="
              $vuetify.theme.dark
                ? location + '/images/icons/small/close-white.png'
                : location + '/images/icons/small/close.png'
            "
            alt="Times"
          />
        </v-avatar>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
    onlyFull: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    hasTimeline: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    mountTime: {
      type: String,
      default: function () {
        return "last24hours";
      },
    },
    hasBarChart: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    keywordsResize2: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      location: "",
      chartBar: true,

      timelineMenu: false,
      time: "",
      items: [
        { id: "last24hours", name: "Last 24 Hours" },
        { id: "lasttwodays", name: "Last Two Days" },
        { id: "lastweek", name: "Last Week" },
        { id: "lasttwoweeks", name: "Last Two Weeks" },
        { id: "lastmonth", name: "Last Month" },
        { id: "lasttwomonths", name: "Last Two Months" },
        { id: "lastsixmonths", name: "Last Six Months" },
        { id: "lastyear", name: "Last Year" },
        { id: "all", name: "All" },
      ],
    };
  },
  watch: {
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue === 1) {
          this.timelineMenu = false;
        }
      },
    },
  },
  created() {
    this.location = window.location.origin;
    this.time = this.mountTime;
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    timeline(time) {
      this.time = time.id;
      this.$emit("timeline", this.time);
    },
    toggleChart() {
      if (this.chartBar === true) {
        this.chartBar = false;
      } else {
        this.chartBar = true;
      }
      this.$emit("toggle-chart");
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar-block {
  .v-avatar {
    img.arrow-icon {
      padding: 3px;
    }
  }
}
</style>
