import request from "@/utils/request";

export function getUnsuccessful(page, size) {
  return request({
    url:
      "api/monitor/interconnector/unsuccessful?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function confirmCall(data) {
  return request({
    url: "api/interconnector/sendbulkcalls",
    method: "post",
    data: data,
  });
}
