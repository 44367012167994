<template>
  <v-container :fluid="true" class="px-0 py-0">
    <v-row v-if="loading" class="mt-0 mb-5 mx-0">
      <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
        <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
        <span
          v-if="size !== 1"
          class="font-roboto-condensed-light font-size-15 ml-2"
          >Loading...</span
        >
      </v-col>
    </v-row>

    <!--VENDOR-->
    <v-row v-if="!loading" class="mt-0 mx-0">
      <v-col cols="4" class="pb-0">
        <label class="font-roboto-condensed-regular font-size-15">Vendor</label>
        <v-autocomplete
          v-model="vendor"
          :items="vendors"
          :rules="vendorRules"
          :disabled="id !== 0"
          item-text="name"
          item-value="id"
          label="Vendor"
          placeholder="Vendor"
          dense
          solo
          @change="changeVendor"
        />
      </v-col>
      <!--END VENDOR-->

      <v-col cols="4" class="pb-0 d-flex align-center">
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-row class="mx-0 mt-0 mb-1" v-if="vendor && !loading">
            <v-col cols="12" class="py-0">
              <!-- <v-radio-group v-model="fwType" row class="mt-2">
                <v-radio
                  label="Firewall"
                  value="firewall"
                  selected
                  @click="fwType = 'firewall'"
                />
                <v-radio
                  label="Firewall group"
                  value="group"
                  @click="fwType = 'group'"
                />
              </v-radio-group> -->
              <div class="d-flex align-center justify-center">
                <span
                  class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
                  >Firewall</span
                >
                <div class="toggle-info align-self-center mt-0">
                  <v-switch
                    v-model="fwType"
                    :true-value="'group'"
                    :false-value="'firewall'"
                    hide-details
                    inset
                    :class="[
                      fwType === 'group' ? 'v-switch-on' : 'v-switch-off',
                    ]"
                    class="target-background v-switch-off"
                  />
                </div>
                <span
                  class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
                  >Firewall group</span
                >
              </div>
            </v-col>
          </v-row>
        </transition>
      </v-col>

      <v-col cols="4" class="pb-0 d-flex align-center">
        <!--FIREWALLS-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
          v-if="fwType === 'firewall'"
        >
          <v-row
            v-if="
              ([1, 2].includes(vendor) ||
                (vendor === 3 && firewallManagementServer)) &&
              !loading
            "
            class="mt-0 mx-0 mb-1"
          >
            <v-col cols="12" class="py-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Firewall</label
              >
              <v-autocomplete
                v-model="firewall"
                :items="firewalls"
                :rules="firewallRules"
                item-text="name"
                item-value="id"
                label="Firewall"
                placeholder="Firewall"
                dense
                solo
                multiple
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </transition>
        <!--END FIREWALLS-->

        <!--FIREWALL GROUPS-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
          v-if="fwType === 'group'"
        >
          <v-row v-if="vendor && !loading" class="mt-0 mx-0 mb-1">
            <v-col cols="12" class="py-0">
              <label class="font-roboto-condensed-regular font-size-15"
                >Firewall Group</label
              >
              <v-autocomplete
                v-model="firewallGroup"
                :items="firewallGroups"
                :rules="firewallGroupsRules"
                item-text="name"
                item-value="id"
                label="Firewall group"
                placeholder="Firewall group"
                dense
                solo
                multiple
                @change="update"
              >
                <template v-slot:selection="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <v-chip
                    small
                    color="neutral-4"
                    class="synapsa-chip text-white"
                  >
                    {{ data.item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </transition>
        <!--END FIREWALL GROUPS-->
      </v-col>
    </v-row>

    <!-- <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-row class="mx-0 mt-0" v-if="vendor && !loading">
        <v-col cols="6" class="pb-0">
          <v-radio-group v-model="fwType" row class="mt-2">
            <v-radio
              label="Firewall"
              value="firewall"
              selected
              @click="fwType = 'firewall'"
            />
            <v-radio
              label="Firewall group"
              value="group"
              @click="fwType = 'group'"
            />
          </v-radio-group>
          <div class="d-flex align-center justify-center">
            <span
              class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
              >Firewall</span
            >
            <div class="toggle-info align-self-center mt-0">
              <v-switch
                v-model="fwType"
                :true-value="'group'"
                :false-value="'firewall'"
                hide-details
                inset
                :class="[fwType === 'group' ? 'v-switch-on' : 'v-switch-off']"
                class="target-background v-switch-off"
              />
            </div>
            <span
              class="pl-1 pr-3 font-roboto-condensed-light font-size-15 spans"
              >Firewall group</span
            >
          </div>
        </v-col>
      </v-row>
    </transition> -->

    <!--FIREWALL MANAGEMENT SERVERS-->
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-row class="mt-0 mx-0" v-if="vendor === 3 && !loading">
        <v-col cols="12" class="pb-0">
          <label class="font-roboto-condensed-regular font-size-15"
            >Firewall management server</label
          >
          <v-autocomplete
            v-model="firewallManagementServer"
            :items="firewallManagementServers"
            :rules="firewallManagementServerRules"
            item-text="name"
            item-value="id"
            label="Firewall management server"
            placeholder="Firewall management server"
            dense
            solo
            @change="changeFwServer"
          />
        </v-col>
      </v-row>
    </transition>
    <!--END FIREWALL MANAGEMENT SERVERS-->

    <!--FIREWALLS-->
    <!-- <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
      v-if="fwType === 'firewall'"
    >
      <v-row
        v-if="
          ([1, 2].includes(vendor) ||
            (vendor === 3 && firewallManagementServer)) &&
          !loading
        "
        class="mt-0 mx-0"
      >
        <v-col cols="6" class="pb-0">
          <label class="font-roboto-condensed-regular font-size-15"
            >Firewall</label
          >
          <v-autocomplete
            v-model="firewall"
            :items="firewalls"
            :rules="firewallRules"
            item-text="name"
            item-value="id"
            label="Firewall"
            placeholder="Firewall"
            dense
            solo
            multiple
            @change="update"
          >
            <template v-slot:selection="data">
              <v-chip small color="neutral-4" class="synapsa-chip text-white">
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <v-chip small color="neutral-4" class="synapsa-chip text-white">
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </transition> -->
    <!--END FIREWALLS-->

    <!--FIREWALL GROUPS-->
    <!-- <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
      v-if="fwType === 'group'"
    >
      <v-row v-if="vendor && !loading" class="mt-0 mx-0">
        <v-col cols="6" class="pb-0">
          <label class="font-roboto-condensed-regular font-size-15"
            >Firewall Group</label
          >
          <v-autocomplete
            v-model="firewallGroup"
            :items="firewallGroups"
            :rules="firewallGroupsRules"
            item-text="name"
            item-value="id"
            label="Firewall group"
            placeholder="Firewall group"
            dense
            solo
            multiple
            @change="update"
          >
            <template v-slot:selection="data">
              <v-chip small color="neutral-4" class="synapsa-chip text-white">
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <v-chip small color="neutral-4" class="synapsa-chip text-white">
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </transition> -->
    <!--END FIREWALLS-->

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <paloalto
        v-if="vendor === 1 && !loading"
        :loading="loading"
        :vendor="vendor"
        :mount-data="allData"
        @update="updateChildInputs"
      />
      <default-vendor
        v-else-if="!loading"
        :loading="loading"
        :vendor="vendor"
        :mount-data="allData"
        @update="updateChildInputs"
      />
    </transition>
  </v-container>
</template>

<script>
import { getFirewallVendors } from "@/api/settings/firewall";
import { Errors } from "@/utils/error";
import { getAuditorFwsByVendor } from "@/api/auditor/ruleset";
import { debounce } from "@/utils";
import { getObjectAddressList } from "@/api/objects/address";
import { getFirewallGroupListByVendor } from "@/api/settings/firewallGroup";
import paloalto from "@/layout/FormSidebar/auditor/ruleset/paloalto";
import defaultVendor from "@/layout/FormSidebar/auditor/ruleset/defaultVendor";

export default {
  components: {
    paloalto,
    defaultVendor,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    mountFirewalls: {
      type: Array,
      default: function () {
        return null;
      },
    },
    mountFirewallGroups: {
      type: Array,
      default: function () {
        return null;
      },
    },
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      valid: true,
      firstRender: true,
      loading: true,
      size: 1,

      auditorMode: 1,
      vendor: null,
      vendorRules: [(v) => !!v || "Vendor is required"],
      vendors: [],
      objectAddress: [],
      firewallManagementServer: null,
      firewallManagementServerRules: [
        (v) => !!v || "Firewall management server is required",
      ],
      firewallManagementServers: [],
      fwType: "firewall",
      firewall: null,
      firewallRules: [
        (v) => !!v || "Firewall is required",
        (v) => (Array.isArray(v) && v.length > 0) || "Firewall is required",
      ],
      firewalls: [],
      firewallGroup: [],
      firewallGroups: [],
      firewallGroupsRules: [
        (v) => !!v || "Firewall group is required",
        (v) =>
          (Array.isArray(v) && v.length > 0) || "Firewall group is required",
      ],

      // used inside a child component
      action: "any",
      actionRules: [(v) => !!v || "Action is required"],
      actionNegate: false,
      actions: [
        [
          { id: "allow", name: "Allow" },
          { id: "deny", name: "Deny" },
          { id: "drop", name: "Drop" },
          { id: "reset-client", name: "Reset Client" },
          { id: "reset-server", name: "Reset Server" },
          { id: "reset-both", name: "Reset Both" },
          { id: "any", name: "Anything" },
        ],
        [
          { id: "allow", name: "Allow" },
          { id: "not-allow", name: "Deny" },
          { id: "any", name: "Any" },
        ],
        [
          { id: "allow", name: "Allow" },
          { id: "not-allow", name: "Drop" },
          { id: "any", name: "Any" },
          { id: "reject", name: "Reject" },
          { id: "user-auth", name: "User auth" },
          { id: "client-auth", name: "Client auth" },
        ],
        [],
      ],
      status: null,
      statusRules: [(v) => !!v || "Action is required"],
      statuses: [
        { id: "enabled", name: "Enabled" },
        { id: "disabled", name: "Disabled" },
        { id: "any", name: "Any" },
      ],
      srcIp: "",
      srcIpOperator: false,
      srcIpRules: {
        required: (v) => !!v || "Source address is required",
      },
      srcIpRule: 1,
      srcIpNegate: false,
      dstIp: "",
      dstIpOperator: false,
      dstIpRules: {
        required: (v) => !!v || "Destination address is required",
      },
      dstIpRule: 1,
      dstIpNegate: false,
      ipRules: [
        { id: 1, name: "ip" },
        { id: 2, name: "net" },
        { id: 3, name: "within.net" },
        { id: 66, name: "edl" },
        { id: 5, name: "any" },
        { id: 21, name: "anything" },
      ],
      srcZone: "",
      srcZoneOperator: false,
      srcZoneRules: {
        required: (v) => !!v || "Source zone is required",
      },
      srcZoneRule: 6,
      srcZoneNegate: false,
      dstZone: "",
      dstZoneOperator: false,
      dstZoneRules: {
        required: (v) => !!v || "Destination zone is required",
      },
      dstZoneRule: 6,
      dstZoneNegate: false,
      zoneRules: [
        { id: 6, name: "zone" },
        { id: 37, name: "any-or-zones" },
        { id: 9, name: "any" },
        { id: 22, name: "anything" },
      ],
      srcInterface: "",
      srcInterfaceRules: {
        required: (v) => !!v || "Source interface is required",
      },
      srcInterfaceRule: 19,
      dstInterface: "",
      dstInterfaceRules: {
        required: (v) => !!v || "Destination interface is required",
      },
      dstInterfaceRule: 19,
      interfaceRules: [
        { id: 19, name: "interface" },
        { id: 20, name: "any" },
      ],
      service: "",
      serviceProtocol: 'any-protocol',
      serviceRules: {
        required: (v) => !!v || "Service is required",
      },
      serviceRule: 14,
      serviceOperator: false,
      serviceNegate: false,
      servicesRules: [
        [
          { id: 14, name: "port" },
          { id: 15, name: "range" },
          { id: 27, name: "port-range_geq" },
          { id: 18, name: "app-default" },
          { id: 17, name: "any" },
          { id: 23, name: "anything" },
        ],
        [
          { id: 10, name: "tcp.port" },
          { id: 11, name: "tcp.range" },
          { id: 25, name: "tcp.port-range_geq" },
          { id: 31, name: "tcp.port_includes" },
          { id: 12, name: "udp.port" },
          { id: 13, name: "udp.range" },
          { id: 26, name: "udp.port-range_geq" },
          { id: 32, name: "udp.port_includes" },
          { id: 14, name: "port" },
          { id: 15, name: "range" },
          { id: 27, name: "port-range_geq" },
          { id: 33, name: "port_includes" },
          { id: 16, name: "any-services" },
          { id: 17, name: "any" },
          { id: 23, name: "anything" },
        ],
      ],
      vpn: "",
      vpnRules: {
        required: (v) => !!v || "VPN is required",
      },
      vpnRule: 34,
      vpnRulesList: [
        { id: 34, name: "vpn.specific" },
        { id: 35, name: "vpn.all-connection" },
        { id: 36, name: "vpn.all-site-to-site" },
      ],
      logStart: "",
      logStartInputRule: {
        required: (v) => !!v || "Log Start is required",
      },
      logStartRule: 45,
      logStartNegate: false,
      logStartRules: [
        { id: 43, name: "yes" },
        { id: 44, name: "no" },
        { id: 45, name: "anything" },
      ],
      logEnd: "",
      logEndInputRule: {
        required: (v) => !!v || "Log End is required",
      },
      logEndRule: 45,
      logEndNegate: false,
      logEndRules: [
        { id: 43, name: "yes" },
        { id: 44, name: "no" },
        { id: 45, name: "anything" },
      ],
      logSetting: "",
      logSettingNegate: false,
      logSettingInputRule: {
        required: (v) => !!v || "Log Setting is required",
      },
      logSettingRule: 46,
      logSettingRules: [
        { id: 46, name: "anything" },
        { id: 47, name: "disabled" },
        { id: 62, name: "enabled" },
        { id: 48, name: "profile" },
      ],
      tag: "",
      tagOperator: false,
      tagInputRules: {
        required: (v) => !!v || "Tag is required",
      },
      tagRule: 42,
      tagNegate: false,
      tagRules: [
        { id: 38, name: "value" },
        { id: 41, name: "no" },
        { id: 42, name: "anything" },
      ],
      profileSetting: "",
      profileSettingInputRule: {
        required: (v) => !!v || "Profile Setting is required",
      },
      profileSettingRule: 51,
      profileSettingRules: [
        { id: 49, name: "group-exact" },
        { id: 59, name: "group-any-of" },
        { id: 60, name: "disabled" },
        { id: 50, name: "enabled" },
        { id: 51, name: "anything" },
      ],
      profileSettingNegate: false,
      profileSettingVirus: "",
      profileSettingVirusInputRule: {
        required: (v) => !!v || "Profile Antivirus is required",
      },
      profileSettingVirusRule: 54,
      profileSettingVirusNegate: false,
      profileSettingVirusRules: [
        { id: 52, name: "value-exact" },
        { id: 61, name: "value-any-of" },
        { id: 53, name: "none" },
        { id: 54, name: "anything" },
      ],
      profileSettingSpyware: "",
      profileSettingSpywareInputRule: {
        required: (v) => !!v || "Profile AntiSpyware is required",
      },
      profileSettingSpywareRule: 54,
      profileSettingSpywareNegate: false,
      profileSettingSpywareRules: [
        { id: 52, name: "value-exact" },
        { id: 61, name: "value-any-of" },
        { id: 53, name: "none" },
        { id: 54, name: "anything" },
      ],
      profileSettingVulnerability: "",
      profileSettingVulnerabilityInputRule: {
        required: (v) => !!v || "Profile Vulnerability is required",
      },
      profileSettingVulnerabilityRule: 54,
      profileSettingVulnerabilityNegate: false,
      profileSettingVulnerabilityRules: [
        { id: 52, name: "value-exact" },
        { id: 61, name: "value-any-of" },
        { id: 53, name: "none" },
        { id: 54, name: "anything" },
      ],
      profileSettingWildfire: "",
      profileSettingWildfireInputRule: {
        required: (v) => !!v || "Profile Wildfire is required",
      },
      profileSettingWildfireRule: 54,
      profileSettingWildfireNegate: false,
      profileSettingWildfireRules: [
        { id: 52, name: "value-exact" },
        { id: 61, name: "value-any-of" },
        { id: 53, name: "none" },
        { id: 54, name: "anything" },
      ],
      profileSettingUrlFiltering: "",
      profileSettingUrlFilteringInputRule: {
        required: (v) => !!v || "Profile Url Filtering is required",
      },
      profileSettingUrlFilteringRule: 54,
      profileSettingUrlFilteringNegate: false,
      profileSettingUrlFilteringRules: [
        { id: 52, name: "value-exact" },
        { id: 61, name: "value-any-of" },
        { id: 53, name: "none" },
        { id: 54, name: "anything" },
      ],
      profileSettingDataFiltering: "",
      profileSettingDataFilteringInputRule: {
        required: (v) => !!v || "Profile Data Filtering is required",
      },
      profileSettingDataFilteringRule: 54,
      profileSettingDataFilteringNegate: false,
      profileSettingDataFilteringRules: [
        { id: 52, name: "value-exact" },
        { id: 61, name: "value-any-of" },
        { id: 53, name: "none" },
        { id: 54, name: "anything" },
      ],
      profileSettingFileBlocking: "",
      profileSettingFileBlockingInputRule: {
        required: (v) => !!v || "Profile File Blocking is required",
      },
      profileSettingFileBlockingRule: 54,
      profileSettingFileBlockingNegate: false,
      profileSettingFileBlockingRules: [
        { id: 52, name: "value-exact" },
        { id: 61, name: "value-any-of" },
        { id: 53, name: "none" },
        { id: 54, name: "anything" },
      ],
      application: "",
      applicationOperator: false,
      applicationInputRule: {
        required: (v) => !!v || "Applications is required",
      },
      applicationRule: 58,
      applicationNegate: false,
      applicationRules: [
        { id: 55, name: "value" },
        { id: 57, name: "any" },
        { id: 58, name: "anything" },
      ],
      category: "",
      categoryInputRule: {
        required: (v) => !!v || "Category is required",
      },
      categoryRule: 65,
      categoryNegate: false,
      categoryOperator: false,
      categoryRules: [
        { id: 63, name: "value" },
        { id: 64, name: "any" },
        { id: 65, name: "anything" },
      ],
      allowedEdl: [],
    };
  },
  watch: {
    action: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue === "any") {
          this.actionNegate = false;
        }
        this.update();
      },
    },
    srcIpRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.srcIp = "";
        }
        if (newValue === 4) {
          this.srcIp = "any-ip";
          this.srcIpNegate = false;
        } else if (newValue === 5) {
          this.srcIp = "any";
        } else if (newValue === 21) {
          this.srcIp = "anything";
          this.srcIpNegate = false;
        }
        this.update();
      },
    },
    dstIpRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.dstIp = "";
        }
        if (newValue === 4) {
          this.dstIp = "any-ip";
          this.dstIpNegate = false;
        } else if (newValue === 5) {
          this.dstIp = "any";
        } else if (newValue === 21) {
          this.dstIp = "anything";
          this.dstIpNegate = false;
        }
        this.update();
      },
    },
    srcZoneRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.srcZone = "";
        }
        if (newValue === 8) {
          this.srcZone = "any-zone";
          this.srcZoneNegate = false;
        } else if (newValue === 9) {
          this.srcZone = "any";
        } else if (newValue === 22) {
          this.srcZone = "anything";
          this.srcZoneNegate = false;
        }
        this.update();
      },
    },
    dstZoneRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.dstZone = "";
        }
        if (newValue === 8) {
          this.dstZone = "any-zone";
          this.dstZoneNegate = false;
        } else if (newValue === 9) {
          this.dstZone = "any";
        } else if (newValue === 22) {
          this.dstZone = "anything";
          this.dstZoneNegate = false;
        }
        this.update();
      },
    },
    srcInterfaceRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.srcInterface = "";
        }
        if (newValue === 20) {
          this.srcInterface = "any";
        }
        this.update();
      },
    },
    dstInterfaceRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.dstInterface = "";
        }
        if (newValue === 20) {
          this.dstInterface = "any";
        }
        this.update();
      },
    },
    serviceRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.service = "";
        }
        if (newValue === 16) {
          this.service = "any-services";
          this.serviceNegate = false;
        } else if (newValue === 17) {
          this.service = "any";
        } else if (newValue === 18) {
          this.service = "app-default";
          this.serviceNegate = false;
        } else if (newValue === 23) {
          this.service = "anything";
          this.serviceNegate = false;
        }
        this.update();
      },
    },
    logStartRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.logStart = "";
        }
        if (newValue === 43) {
          this.logStart = "yes";
        } else if (newValue === 44) {
          this.logStart = "no";
        } else if (newValue === 45) {
          this.logStart = "anything";
          this.logStartNegate = false;
        }
        this.update();
      },
    },
    logEndRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.logEnd = "";
        }
        if (newValue === 43) {
          this.logEnd = "yes";
        } else if (newValue === 44) {
          this.logEnd = "no";
        } else if (newValue === 45) {
          this.logEnd = "anything";
          this.logEndNegate = false;
        }
        this.update();
      },
    },
    tagRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.tag = "";
        }
        if (newValue === 41) {
          this.tag = "no";
        } else if (newValue === 42) {
          this.tag = "anything";
          this.tagNegate = false;
        }
        this.update();
      },
    },
    logSettingRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.logSetting = "";
        }
        if (newValue === 46) {
          this.logSetting = "anything";
          this.logSettingNegate = false;
        } else if (newValue === 47) {
          this.logSetting = "disabled";
          this.logSettingNegate = false;
        } else if (newValue === 62) {
          this.logSetting = "enabled";
          this.logSettingNegate = false;
        }
        this.update();
      },
    },
    profileSettingRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.profileSetting = "";
        }
        if (newValue === 51) {
          this.profileSetting = "anything";
          this.profileSettingNegate = false;
        } else if (newValue === 50) {
          this.profileSetting = "enabled";
        } else if (newValue === 60) {
          this.profileSetting = "disabled";
        }
        this.update();
      },
    },
    profileSettingVirusRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.profileSettingVirus = "";
        }
        if (newValue === 54) {
          this.profileSettingVirus = "anything";
          this.profileSettingVirusNegate = false;
        } else if (newValue === 53) {
          this.profileSettingVirus = "none";
          this.profileSettingVirusNegate = false;
        }
        this.update();
      },
    },
    profileSettingSpywareRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.profileSettingSpyware = "";
        }
        if (newValue === 54) {
          this.profileSettingSpyware = "anything";
          this.profileSettingSpywareNegate = false;
        } else if (newValue === 53) {
          this.profileSettingSpyware = "none";
          this.profileSettingSpywareNegate = false;
        }
        this.update();
      },
    },
    profileSettingVulnerabilityRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.profileSettingVulnerability = "";
        }
        if (newValue === 54) {
          this.profileSettingVulnerability = "anything";
          this.profileSettingVulnerabilityNegate = false;
        } else if (newValue === 53) {
          this.profileSettingVulnerability = "none";
          this.profileSettingVulnerabilityNegate = false;
        }
        this.update();
      },
    },
    profileSettingWildfireRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.profileSettingWildfire = "";
        }
        if (newValue === 54) {
          this.profileSettingWildfire = "anything";
          this.profileSettingWildfireNegate = false;
        } else if (newValue === 53) {
          this.profileSettingWildfire = "none";
          this.profileSettingWildfireNegate = false;
        }
        this.update();
      },
    },
    profileSettingUrlFilteringRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.profileSettingUrlFiltering = "";
        }
        if (newValue === 54) {
          this.profileSettingUrlFiltering = "anything";
          this.profileSettingUrlFilteringNegate = false;
        } else if (newValue === 53) {
          this.profileSettingUrlFiltering = "none";
          this.profileSettingUrlFilteringNegate = false;
        }
        this.update();
      },
    },
    profileSettingDataFilteringRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.profileSettingDataFiltering = "";
        }
        if (newValue === 54) {
          this.profileSettingDataFiltering = "anything";
          this.profileSettingDataFilteringNegate = false;
        } else if (newValue === 53) {
          this.profileSettingDataFiltering = "none";
          this.profileSettingDataFilteringNegate = false;
        }
        this.update();
      },
    },
    profileSettingFileBlockingRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.profileSettingFileBlocking = "";
        }
        if (newValue === 54) {
          this.profileSettingFileBlocking = "anything";
          this.profileSettingFileBlockingNegate = false;
        } else if (newValue === 53) {
          this.profileSettingFileBlocking = "none";
          this.profileSettingFileBlockingNegate = false;
        }
        this.update();
      },
    },
    applicationRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.application = "";
        }
        if (newValue === 58) {
          this.application = "anything";
          this.applicationNegate = false;
        } else if (newValue === 57) {
          this.application = "any";
        }
        this.update();
      },
    },
    categoryRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.category = "";
        }
        if (newValue === 65) {
          this.category = "anything";
          this.categoryNegate = false;
        } else if (newValue === 64) {
          this.category = "any";
        }
        this.update();
      },
    },
    vpnRule: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!this.firstRender) {
          this.vpn = "";
        }
        if (newValue === 35) {
          this.vpn = "all";
        } else if (newValue === 36) {
          this.vpn = "all";
        }
        this.update();
      },
    },
  },
  computed: {
    allData() {
      return Object.assign({}, this.$data);
    },
  },
  created() {
    this.loadVendors();
    this.loadObjectAddress();
  },
  async mounted() {
    if (this.mountData) {
      this.vendor = this.mountData.vendor;
      this.auditorMode = this.mountData.auditorMode;
      this.fwType = this.mountData.fwType;
      this.firewall = this.mountData.firewall;
      this.firewallGroup = this.mountData.firewallGroup;
      this.firewallManagementServer = this.mountData.firewallManagementServer;
      this.action = this.mountData.action;
      this.actionNegate = this.mountData.actionNegate;
      this.status = this.mountData.status;
      this.srcIp = this.mountData.srcIp;
      this.srcIpOperator = this.mountData.srcIpOperator || false;
      this.srcIpRule = this.mountData.srcIpRule;
      this.srcIpNegate = this.mountData.srcIpNegate;
      this.dstIp = this.mountData.dstIp;
      this.dstIpOperator = this.mountData.dstIpOperator || false;
      this.dstIpRule = this.mountData.dstIpRule;
      this.dstIpNegate = this.mountData.dstIpNegate;
      this.srcZone = this.mountData.srcZone;
      this.srcZoneOperator = this.mountData.srcZoneOperator || false;
      this.srcZoneRule = this.mountData.srcZoneRule;
      this.srcZoneNegate = this.mountData.srcZoneNegate;
      this.dstZone = this.mountData.dstZone;
      this.dstZoneOperator = this.mountData.dstZoneOperator || false;
      this.dstZoneRule = this.mountData.dstZoneRule;
      this.dstZoneNegate = this.mountData.dstZoneNegate;
      this.srcInterface = this.mountData.srcInterface;
      this.srcInterfaceRule = this.mountData.srcInterfaceRule;
      this.dstInterface = this.mountData.dstInterface;
      this.dstInterfaceRule = this.mountData.dstInterfaceRule;
      this.service = this.mountData.service;
      this.serviceOperator = this.mountData.serviceOperator || false;
      this.serviceProtocol = this.mountData.serviceProtocol || 'any-protocol';
      this.serviceRule = this.mountData.serviceRule;
      this.serviceNegate = this.mountData.serviceNegate;
      this.logStart = this.mountData.logStart;
      this.logStartRule = this.mountData.logStartRule;
      this.logStartNegate = this.mountData.logStartNegate;
      this.logEnd = this.mountData.logEnd;
      this.logEndRule = this.mountData.logEndRule;
      this.logEndNegate = this.mountData.logEndNegate;
      this.logSetting =
        this.mountData.logSettingRule === 46
          ? "anything"
          : this.mountData.logSetting;
      this.logSettingNegate = this.mountData.logSettingNegate || false;
      this.logSettingRule = this.mountData.logSettingRule || 46;
      this.profileSetting =
        this.mountData.profileSettingRule === 51
          ? "anything"
          : this.mountData.profileSetting;
      this.profileSettingNegate = this.mountData.profileSettingNegate || false;
      this.profileSettingRule = this.mountData.profileSettingRule || 51;
      this.profileSettingVirus =
        this.mountData.profileSettingVirusRule === 54
          ? "anything"
          : this.mountData.profileSettingVirus;
      this.profileSettingVirusRule =
        this.mountData.profileSettingVirusRule || 54;
      this.profileSettingVirusNegate =
        this.mountData.profileSettingVirusNegate || false;
      this.profileSettingSpyware =
        this.mountData.profileSettingSpywareRule === 54
          ? "anything"
          : this.mountData.profileSettingSpyware;
      this.profileSettingSpywareRule =
        this.mountData.profileSettingSpywareRule || 54;
      this.profileSettingSpywareNegate =
        this.mountData.profileSettingSpywareNegate || false;
      this.profileSettingVulnerability =
        this.mountData.profileSettingVulnerabilityRule === 54
          ? "anything"
          : this.mountData.profileSettingVulnerability;
      this.profileSettingVulnerabilityRule =
        this.mountData.profileSettingVulnerabilityRule || 54;
      this.profileSettingVulnerabilityNegate =
        this.mountData.profileSettingVulnerabilityNegate || false;
      this.profileSettingWildfire =
        this.mountData.profileSettingWildfireRule === 54
          ? "anything"
          : this.mountData.profileSettingWildfire;
      this.profileSettingWildfireRule =
        this.mountData.profileSettingWildfireRule || 54;
      this.profileSettingWildfireNegate =
        this.mountData.profileSettingWildfireNegate || false;
      this.profileSettingUrlFiltering =
        this.mountData.profileSettingUrlFilteringRule === 54
          ? "anything"
          : this.mountData.profileSettingUrlFiltering;
      this.profileSettingUrlFilteringRule =
        this.mountData.profileSettingUrlFilteringRule || 54;
      this.profileSettingUrlFilteringNegate =
        this.mountData.profileSettingUrlFilteringNegate || false;
      this.profileSettingDataFiltering =
        this.mountData.profileSettingDataFilteringRule === 54
          ? "anything"
          : this.mountData.profileSettingDataFiltering;
      this.profileSettingDataFilteringRule =
        this.mountData.profileSettingDataFilteringRule || 54;
      this.profileSettingDataFilteringNegate =
        this.mountData.profileSettingDataFilteringNegate || false;
      this.profileSettingFileBlocking =
        this.mountData.profileSettingFileBlockingRule === 54
          ? "anything"
          : this.mountData.profileSettingFileBlocking;
      this.profileSettingFileBlockingRule =
        this.mountData.profileSettingFileBlockingRule || 54;
      this.profileSettingFileBlockingNegate =
        this.mountData.profileSettingFileBlockingNegate || false;
      this.application =
        this.mountData.applicationRule === 58
          ? "anything"
          : this.mountData.application;
      this.applicationOperator = this.mountData.applicationOperator || false;
      this.applicationNegate = this.mountData.applicationNegate || false;
      this.applicationRule = this.mountData.applicationRule || 58;
      this.category =
        this.mountData.categoryRule === 65
          ? "anything"
          : this.mountData.category;
      this.categoryNegate = this.mountData.categoryNegate || false;
      this.categoryOperator = this.mountData.categoryOperator || false;
      this.categoryRule = this.mountData.categoryRule || 65;
      this.tag =
        this.mountData.tagRule === 42 ? "anything" : this.mountData.tag;
      this.tagOperator = this.mountData.tagOperator || false;
      this.tagRule = this.mountData.tagRule || 42;
      this.tagNegate = this.mountData.tagNegate || false;
      this.vpn = this.mountData.vpn;
      this.vpnRule = this.mountData.vpnRule;
      this.allowedEdl = this.mountData.allowedEdl;

      await this.loadFirewalls();
      await this.loadFirewallGroups();

      this.loading = false;
    }
    if (this.mountFirewalls) {
      this.firewalls = this.mountFirewalls;
    }
    if (this.mountFirewallGroups) {
      this.firewallGroups = this.mountFirewallGroups;
    }
  },
  methods: {
    loadVendors() {
      getFirewallVendors()
        .then((response) => {
          this.vendors = response.data;
          this.vendor = 1;
          this.loadFirewalls();
          this.loadFirewallGroups();
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadObjectAddress() {
      getObjectAddressList()
        .then((response) => {
          this.objectAddress = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadFirewalls() {
      if (this.vendor) {
        const data = {
          vendorid: this.vendor,
        };
        getAuditorFwsByVendor(data)
          .then((response) => {
            if ([1, 2].includes(this.vendor)) {
              this.firewalls = response.data;
            } else {
              this.firewallManagementServers = response.data;
            }
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
    loadFirewallGroups() {
      if (this.vendor) {
        getFirewallGroupListByVendor(this.vendor)
          .then((response) => {
            this.firewallGroups = response.data;
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
    changeVendor() {
      this.loadFirewalls();
      this.loadFirewallGroups();
      this.action = null;
      this.firewallManagementServer = null;
      this.firewall = null;
      this.firewallGroup = null;
      this.update();
    },
    changeFwServer() {
      this.firewalls = null;
      this.firewallManagementServers.map((server) => {
        if (server.id === this.firewallManagementServer) {
          this.firewalls = server.children;
        }
      });
      this.update();
    },
    update: debounce(function () {
      this.firstRender = false;
      this.$emit("update", {
        vendor: this.vendor,
        auditorMode: this.auditorMode,
        fwType: this.fwType,
        firewall: this.firewall,
        firewallGroup: this.firewallGroup,
        firewallManagementServer: this.firewallManagementServer,
        action: this.action,
        actionNegate: this.actionNegate,
        status: this.status,
        srcIp: this.srcIp.ip === undefined ? this.srcIp : this.srcIp.ip,
        srcIpOperator: this.srcIpOperator,
        srcIpRule: this.srcIpRule,
        srcIpNegate: this.srcIpNegate,
        dstIp: this.dstIp.ip === undefined ? this.dstIp : this.dstIp.ip,
        dstIpOperator: this.dstIpOperator,
        dstIpRule: this.dstIpRule,
        dstIpNegate: this.dstIpNegate,
        srcZone: this.srcZone,
        srcZoneOperator: this.srcZoneOperator,
        srcZoneRule: this.srcZoneRule,
        srcZoneNegate: this.srcZoneNegate,
        dstZone: this.dstZone,
        dstZoneOperator: this.dstZoneOperator,
        dstZoneRule: this.dstZoneRule,
        dstZoneNegate: this.dstZoneNegate,
        srcInterface: this.srcInterface,
        srcInterfaceRule: this.srcInterfaceRule,
        dstInterface: this.dstInterface,
        dstInterfaceRule: this.dstInterfaceRule,
        service: this.service,
        serviceOperator: this.serviceOperator,
        serviceProtocol: this.serviceProtocol,
        serviceRule: this.serviceRule,
        serviceNegate: this.serviceNegate,
        logStart: this.logStart,
        logStartRule: this.logStartRule,
        logStartNegate: this.logStartNegate,
        logEnd: this.logEnd,
        logEndRule: this.logEndRule,
        logEndNegate: this.logEndNegate,
        logSetting: this.logSetting,
        logSettingNegate: this.logSettingNegate,
        logSettingRule: this.logSettingRule,
        profileSetting: this.profileSetting,
        profileSettingNegate: this.profileSettingNegate,
        profileSettingRule: this.profileSettingRule,
        profileSettingVirus: this.profileSettingVirus,
        profileSettingVirusRule: this.profileSettingVirusRule,
        profileSettingVirusNegate: this.profileSettingVirusNegate,
        profileSettingSpyware: this.profileSettingSpyware,
        profileSettingSpywareRule: this.profileSettingSpywareRule,
        profileSettingSpywareNegate: this.profileSettingSpywareNegate,
        profileSettingVulnerability: this.profileSettingVulnerability,
        profileSettingVulnerabilityRule: this.profileSettingVulnerabilityRule,
        profileSettingVulnerabilityNegate: this
          .profileSettingVulnerabilityNegate,
        profileSettingWildfire: this.profileSettingWildfire,
        profileSettingWildfireRule: this.profileSettingWildfireRule,
        profileSettingWildfireNegate: this.profileSettingWildfireNegate,
        profileSettingUrlFiltering: this.profileSettingUrlFiltering,
        profileSettingUrlFilteringRule: this.profileSettingUrlFilteringRule,
        profileSettingUrlFilteringNegate: this.profileSettingUrlFilteringNegate,
        profileSettingDataFiltering: this.profileSettingDataFiltering,
        profileSettingDataFilteringRule: this.profileSettingDataFilteringRule,
        profileSettingDataFilteringNegate: this
          .profileSettingDataFilteringNegate,
        profileSettingFileBlocking: this.profileSettingFileBlocking,
        profileSettingFileBlockingRule: this.profileSettingFileBlockingRule,
        profileSettingFileBlockingNegate: this.profileSettingFileBlockingNegate,
        application: this.application,
        applicationOperator: this.applicationOperator,
        applicationNegate: this.applicationNegate,
        applicationRule: this.applicationRule,
        category: this.category,
        categoryNegate: this.categoryNegate,
        categoryOperator: this.categoryOperator,
        categoryRule: this.categoryRule,
        tag: this.tag,
        tagOperator: this.tagOperator,
        tagRule: this.tagRule,
        tagNegate: this.tagNegate,
        vpn: this.vpn,
        vpnRule: this.vpnRule,
        allowedEdl: this.allowedEdl,
        firstRender: false,
      });
    }, 200),
    updateChildInputs(event) {
      this.action = event.action;
      this.auditorMode = event.auditorMode;
      this.actionNegate = event.actionNegate;
      this.status = event.status;
      this.srcIp = event.srcIp.ip === undefined ? event.srcIp : event.srcIp.ip;
      this.srcIpOperator = event.srcIpOperator;
      this.srcIpRule = event.srcIpRule;
      this.srcIpNegate = event.srcIpNegate;
      this.dstIp = event.dstIp.ip === undefined ? event.dstIp : event.dstIp.ip;
      this.dstIpOperator = event.dstIpOperator;
      this.dstIpRule = event.dstIpRule;
      this.dstIpNegate = event.dstIpNegate;
      this.srcZone = event.srcZone;
      this.srcZoneOperator = event.srcZoneOperator;
      this.srcZoneRule = event.srcZoneRule;
      this.srcZoneNegate = event.srcZoneNegate;
      this.dstZone = event.dstZone;
      this.dstZoneOperator = event.dstZoneOperator;
      this.dstZoneRule = event.dstZoneRule;
      this.dstZoneNegate = event.dstZoneNegate;
      this.serviceOperator = event.serviceOperator;
      this.serviceProtocol = event.serviceProtocol;
      this.srcInterface = event.srcInterface;
      this.srcInterfaceRule = event.srcInterfaceRule;
      this.dstInterface = event.dstInterface;
      this.dstInterfaceRule = event.dstInterfaceRule;
      this.service = event.service;
      this.serviceRule = event.serviceRule;
      this.serviceNegate = event.serviceNegate;
      this.logStart = event.logStart;
      this.logStartRule = event.logStartRule;
      this.logStartNegate = event.logStartNegate;
      this.logEnd = event.logEnd;
      this.logEndRule = event.logEndRule;
      this.logEndNegate = event.logEndNegate;
      this.logSetting = event.logSetting;
      this.logSettingNegate = event.logSettingNegate;
      this.logSettingRule = event.logSettingRule;
      this.profileSetting = event.profileSetting;
      this.profileSettingNegate = event.profileSettingNegate;
      this.profileSettingRule = event.profileSettingRule;
      this.profileSettingVirus = event.profileSettingVirus;
      this.profileSettingVirusRule = event.profileSettingVirusRule;
      this.profileSettingVirusNegate = event.profileSettingVirusNegate;
      this.profileSettingSpyware = event.profileSettingSpyware;
      this.profileSettingSpywareRule = event.profileSettingSpywareRule;
      this.profileSettingSpywareNegate = event.profileSettingSpywareNegate;
      this.profileSettingVulnerability = event.profileSettingVulnerability;
      this.profileSettingVulnerabilityRule =
        event.profileSettingVulnerabilityRule;
      this.profileSettingVulnerabilityNegate =
        event.profileSettingVulnerabilityNegate;
      this.profileSettingWildfire = event.profileSettingWildfire;
      this.profileSettingWildfireRule = event.profileSettingWildfireRule;
      this.profileSettingWildfireNegate = event.profileSettingWildfireNegate;
      this.profileSettingUrlFiltering = event.profileSettingUrlFiltering;
      this.profileSettingUrlFilteringRule =
        event.profileSettingUrlFilteringRule;
      this.profileSettingUrlFilteringNegate =
        event.profileSettingUrlFilteringNegate;
      this.profileSettingDataFiltering = event.profileSettingDataFiltering;
      this.profileSettingDataFilteringRule =
        event.profileSettingDataFilteringRule;
      this.profileSettingDataFilteringNegate =
        event.profileSettingDataFilteringNegate;
      this.profileSettingFileBlocking = event.profileSettingFileBlocking;
      this.profileSettingFileBlockingRule =
        event.profileSettingFileBlockingRule;
      this.profileSettingFileBlockingNegate =
        event.profileSettingFileBlockingNegate;
      this.application = event.application;
      this.applicationOperator = event.applicationOperator;
      this.applicationNegate = event.applicationNegate;
      this.applicationRule = event.applicationRule;
      this.category = event.category;
      this.categoryNegate = event.categoryNegate;
      this.categoryOperator = event.categoryOperator;
      this.categoryRule = event.categoryRule;
      this.tag = event.tag;
      this.tagOperator = event.tagOperator;
      this.tagRule = event.tagRule;
      this.tagNegate = event.tagNegate;
      this.vpn = event.vpn;
      this.vpnRule = event.vpnRule;
      this.allowedEdl = event.allowedEdl;

      this.update();
    },
  },
};
</script>
<style lang="scss" scoped></style>
