<template>
  <v-container :fluid="true" class="px-0 py-0">
    <!--NAME-->
    <v-row class="mx-0 mt-0">
      <v-col cols="6" class="pb-0">
        <label class="font-roboto-condensed-regular font-size-15">Name</label>
        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Name"
          placeholder="Name"
          dense
          solo
          @keyup="update"
        />
      </v-col>
      <!--END NAME-->
      <!--MINER-->
      <v-col cols="6" class="pb-0">
        <label class="font-roboto-condensed-regular font-size-15">Miner</label>
        <v-autocomplete
          v-model="miner"
          :items="miners"
          :rules="minerRules"
          item-text="name"
          item-value="id"
          label="Miner"
          placeholder="Miner"
          dense
          solo
          @change="update"
        />
      </v-col>
      <!--END MINER-->
      <!--DESCRIPTION-->
      <v-col cols="12" class="pb-0">
        <label class="font-roboto-condensed-regular font-size-15"
          >Description</label
        >
        <v-textarea
          v-model="description"
          placeholder="Description"
          auto-grow
          rows="4"
          dense
          solo
          @keyup="update"
        />
      </v-col>
      <!--END DESCRIPTION-->
    </v-row>

    <!--STATE-->
    <v-row class="mt-1 mx-0">
      <v-col cols="12" class="pb-8 pt-2">
        <label class="font-roboto-condensed-regular font-size-15">State</label>
        <div class="toggle">
          <v-switch
            v-model="state"
            :value="true"
            hide-details
            label="Active"
            @change="update"
            inset
            :class="[state ? 'v-switch-on' : 'v-switch-off']"
            class="target-background v-switch-off"
          />
        </div>
      </v-col>
    </v-row>
    <!--END STATE-->
  </v-container>
</template>

<script>
export default {
  props: {
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
    miners: {
      type: Array,
      default: function () {
        return [];
      },
    },
    ipLists: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      valid: true,
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      miner: "",
      minerRules: [(v) => !!v || "Miner is required"],
      description: "",
      state: true,
    };
  },
  created() {
    if (this.mountData) {
      this.name = this.mountData.name;
      this.miner = this.mountData.miner;
      this.description = this.mountData.description;
      this.state = this.mountData.state;
    }
  },
  methods: {
    update() {
      this.$emit("update", {
        name: this.name,
        miner: this.miner,
        description: this.description,
        state: this.state,
      });
    },
  },
};
</script>
