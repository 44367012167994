<template>
  <v-list-item
    v-if="!route.children"
    :to="route.route"
    :class="{ active: active.includes(route.id) }"
    color="#fff"
    link
  >
    <v-list-item-icon>
      <v-tooltip
        v-if="mini"
        right
        color="primary"
        open-delay="1"
        nudge-right="23"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            v-if="route.name === 'awaitingApproval' && alertCount.approval > 0"
            color="bad-3"
            dot
          >
            <v-avatar size="20" class="br-0" v-bind="attrs" v-on="on">
              <img
                :src="
                  active.includes(route.id)
                    ? location + '/images/icons/green/sidebar/' + route.icon
                    : location + iconRoute + route.icon
                "
                alt="Add"
              />
            </v-avatar>
          </v-badge>
          <v-avatar v-else size="20" class="br-0" v-bind="attrs" v-on="on">
            <img
              :src="
                active.includes(route.id)
                  ? location + '/images/icons/green/sidebar/' + route.icon
                  : location + iconRoute + route.icon
              "
              alt="Add"
            />
          </v-avatar>
        </template>

        <span class="font-roboto-condensed-regular">{{
          $t("sidebar." + route.name)
        }}</span>
      </v-tooltip>
      <v-avatar v-else size="20" class="br-0">
        <img
          :src="
            active.includes(route.id)
              ? location + '/images/icons/green/sidebar/' + route.icon
              : location + iconRoute + route.icon
          "
          alt="Add"
        />
      </v-avatar>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title class="font-roboto-condensed-regular"
        >{{ $t("sidebar." + route.name) }}
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <span
            v-if="
              route.name === 'awaitingApproval' &&
              alertCount.approval > 0 &&
              showAlertCount
            "
            class="alert-count"
          >
            {{ alertCount.approval &lt; 10000 ? alertCount.approval : ">9999" }}
          </span>
        </transition>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    active: {
      type: Array,
      default: function () {
        return [];
      },
    },
    mini: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    route: {
      type: Object,
      default: function () {
        return null;
      },
    },
    location: {
      type: String,
      default: function () {
        return "";
      },
    },
    alertCount: {
      type: Object,
      default: function () {
        return {
          auditor: 0,
          approval: 0,
          interconnector: 0,
        };
      },
    },
  },
  data() {
    return {
      iconRoute: "/images/icons/white/sidebar/",

      showAlertCount: false,
    };
  },
  watch: {
    mini: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) {
          setTimeout(() => {
            this.showAlertCount = true;
          }, 500);
        } else {
          this.showAlertCount = false;
        }
      },
    },
  },
};
</script>
