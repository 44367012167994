<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :keywordsResize2="keywordsResize2"
      @resize="resize($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new firewall group" : "Edit firewall group" }}
            </h3>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="validCheck" lazy-validation>
              <!--VENDOR-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Vendor</label
                  >
                  <v-select
                    v-model="vendor"
                    :disabled="checkValid"
                    :items="vendors"
                    :rules="vendorRules"
                    item-text="name"
                    item-value="id"
                    label="Vendor"
                    placeholder="Vendor"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END VENDOR-->

              <!--Name-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Name</label
                  >
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    placeholder="Name"
                    dense
                    solo
                    aria-autocomplete="none"
                  />
                </v-col>
              </v-row>
              <!--END Name-->
              <!--Description-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Description</label
                  >
                  <v-textarea
                    v-model="description"
                    placeholder="Description"
                    auto-grow
                    rows="2"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END Description-->
              <v-row class="mx-0 mt-3">
                <v-col
                  :cols="size === 3 ? 4 : 6"
                  v-for="(firewall, key) in firewalls"
                  :key="key"
                  class="py-0"
                >
                  <div class="toggle">
                    <v-switch
                      v-model="firewall.checked"
                      :value="true"
                      hide-details
                      :label="firewall.name"
                      inset
                      @click="firewall.isActive = !firewall.isActive"
                      :class="[
                        firewall.isActive ? 'v-switch-off' : 'v-switch-on',
                      ]"
                      class="target-background v-switch-off"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-6 mx-0">
                <v-col cols="12" class="pb-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { Errors } from "@/utils/error";
import { getFirewallVendors } from "@/api/settings/firewall";
import { getFirewallListByVendor } from "@/api/settings/firewall";
import {
  getFirewallGroup,
  storeFirewallGroup,
  updateFirewallGroup,
} from "../../../api/settings/firewallGroup";
export default {
  name: "index",
  components: {
    Topbar,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
    keywordsResize2: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      activeTab: "general",
      loading: true,
      checkLoading: false,
      checkValid: null,
      perspectiveValid: null,
      checked: null,
      valid: true,
      validCheck: true,
      saving: false,
      firewalls: [],
      firewallsIncluded: [],
      firewallNames: null,
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      description: "",
      vendor: "",
      vendors: [],
      vendorRules: [(v) => !!v || "Vendor is required"],
    };
  },
  watch: {
    vendor: {
      immediate: false,
      deep: true,
      handler(newValue) {
        getFirewallListByVendor(newValue)
          .then((response) => {
            const firewalls = response;
            const self = this;
            this.firewalls = [];

            if (this.firewallNames && this.firewallNames.length > 0) {
              firewalls.data.forEach(function (firewall) {
                const index = self.firewallNames.findIndex(
                  (x) => x === firewall.name
                );
                self.firewalls.push({
                  id: firewall.id,
                  name: firewall.name,
                  checked: index === -1 ? null : true,
                  isActive: index === -1 ? true : false,
                });
              });
            } else {
              this.checkValid = false;
              firewalls.data.forEach(function (firewall) {
                self.firewalls.push({
                  id: firewall.id,
                  name: firewall.name,
                  checked: null,
                  isActive: true,
                });
              });
            }
            this.loading = false;
          })
          .catch((e) => {
            Errors.show(e);
          });
      },
    },
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.checkValid = null;
          this.perspectiveValid = null;
          this.checked = null;
          this.validCheck = true;
          this.firewallsIncluded = [];
          this.name = "";
          this.description = "";
          this.vendor = 1;
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  mounted() {
    this.loadVendors();
    // if (this.id === 0) {
    //   this.vendor = 1;
    // } else {
    //   this.loadById();
    // }
  },
  methods: {
    loadVendors() {
      getFirewallVendors()
        .then((response) => {
          this.vendors = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "firewallgroup");
    },
    loadById() {
      getFirewallGroup(this.id)
        .then((response) => {
          this.name = response.data.name;
          this.description = response.data.description;
          this.firewallNames = response.data.firewallNames;
          this.vendor = response.data.vendorattributte_id;
          this.checkValid = true;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    store() {
      this.saving = true;
      const self = this;
      this.firewalls.forEach(function (response) {
        if (response.checked === true) {
          self.firewallsIncluded.push(response.id);
        }
      });
      const data = {
        name: this.name,
        description: this.description,
        vendor: this.vendor,
        firewall: this.firewallsIncluded,
      };
      storeFirewallGroup(data)
        .then(() => {
          this.$toast.open({
            message: "FirewallGroup was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    update() {
      this.saving = true;
      const self = this;
      this.firewalls.forEach(function (response) {
        if (response.checked === true) {
          self.firewallsIncluded.push(response.id);
        }
      });
      const data = {
        name: this.name,
        description: this.description,
        firewall: this.firewallsIncluded,
        vendor: this.vendor,
        id: this.id,
      };
      updateFirewallGroup(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "FirewallGroup was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.saving = false;
          this.change();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped></style>
