<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :keywordsResize2="keywordsResize2"
      @resize="resize($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new Syslog" : "Edit Syslog" }}
            </h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--IP ADDRESS-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >IP address</label
                  >
                  <v-text-field
                    v-model="ip"
                    :rules="[ipRules.required, ipValidate]"
                    label="IP address"
                    placeholder="IP address"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END IP ADDRESS-->

              <!--PORT-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Port</label
                  >
                  <v-text-field
                    v-model="port"
                    :rules="[portRules.required]"
                    label="Port"
                    placeholder="Port"
                    type="number"
                    min="1"
                    max="65535"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END PORT-->

              <!--TYPE-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Protocol</label
                  >
                  <v-select
                    v-model="type"
                    :items="types"
                    :rules="typeRules"
                    item-text="name"
                    item-value="id"
                    label="Type"
                    placeholder="Type"
                    return-object
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END TYPE-->

              <!--FORMAT-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Format</label
                  >
                  <v-select
                    v-model="format"
                    :items="formats"
                    :rules="formatRules"
                    item-text="name"
                    item-value="id"
                    label="Format"
                    placeholder="Format"
                    return-object
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END FORMAT-->

              <v-row class="mt-1 mx-0">
                <v-col cols="12" class="pb-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import { getSyslog, storeSyslog, updateSyslog } from "@/api/objects/syslog";
import { Validation } from "@/utils/validation";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
    keywordsResize2: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,

      valid: true,
      validCheck: true,

      saving: false,

      ip: "",
      ipRules: {
        required: (v) => !!v || "IP address is required",
      },
      port: "",
      portRules: {
        required: (v) => !!v || "Port is required",
      },
      type: "",
      typeRules: [(v) => !!v || "Type is required"],
      format: "",
      formatRules: [(v) => !!v || "Type is required"],

      types: ["tcp", "udp"],
      formats: ["json", "raw"],
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.ip = "";
          this.port = "";
          this.type = "";
          this.format = "";
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  // mounted() {
  //   if (this.id === 0) {
  //     this.loading = false;
  //   } else {
  //     this.loadById();
  //   }
  // },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "objectsyslog");
    },
    loadById() {
      getSyslog(this.id)
        .then((response) => {
          const syslog = response.data;
          this.ip = syslog.ip;
          this.port = syslog.port;
          this.type = syslog.type;
          this.format = syslog.format;
          this.checkValid = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    validateCheck() {
      this.$refs.formCheck.validate();
      if (this.$refs.formCheck.validate()) {
        this.check();
      }
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    ipValidate(val) {
      return Validation.ip(val);
    },
    store() {
      this.saving = true;
      const data = {
        ip: this.ip,
        port: this.port,
        type: this.type,
        format: this.format,
      };
      storeSyslog(data)
        .then(() => {
          this.$toast.open({
            message: "Syslog was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    update() {
      this.saving = true;
      const data = {
        id: this.id,
        ip: this.ip,
        port: this.port,
        type: this.type,
        format: this.format,
      };
      updateSyslog(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Syslog was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
