<template>
  <v-container :fluid="true" class="px-0 pb-0">
    <v-divider v-if="!loading && objectList.length > 0" class="mt-2 mx-3" />

    <!--OBJECT-->
    <v-row v-if="!loading && objectList.length > 0" class="mx-0 mt-0 pb-3 px-3">
      <v-chip-group
        v-model="objectnamesChosen"
        column
        multiple
        class="py-4"
        @change="chooseObjectname"
      >
        <v-chip
          v-for="object in objectList"
          :key="object.id"
          filter
          outlined
          small
          :color="object.chosen ? 'primary' : ''"
        >
          {{ object.name }}
        </v-chip>
      </v-chip-group>
    </v-row>
    <!--END OBJECT-->

    <v-divider v-if="!loading && objectList.length > 0" class="mb-5 mx-3" />

    <!--SEVERITY-->
    <v-row
      v-if="!loading && severityList.length > 0"
      class="mx-0 mt-0 pb-4 px-3"
    >
      <v-chip-group
        v-model="severitiesChosen"
        column
        multiple
        @change="chooseSeverity"
      >
        <v-chip
          v-for="severity in severityList"
          :key="severity.id"
          filter
          outlined
          small
          :color="severity.chosen ? 'primary' : ''"
        >
          {{ severity.name }}
        </v-chip>
      </v-chip-group>
    </v-row>
    <!--END SEVERITY-->

    <v-row v-if="loading" class="mt-3 mx-0">
      <v-col cols="12" class="pt-0 px-0 pb-5">
        <v-icon color="primary"> fas fa-spinner fa-spin </v-icon>
        <span class="font-roboto-condensed-light font-size-15 ml-2">
          Loading datatype...
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
    objectList: {
      type: Array,
      default: function () {
        return null;
      },
    },
    severityList: {
      type: Array,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      objectname: [],
      objectnamesChosen: [],
      severity: [],
      severitiesChosen: [],

      loading: true,
    };
  },
  created() {
    if (this.mountData) {
      this.objectname = this.mountData.objectname;
      this.severity = this.mountData.severity;
      this.loadObjects();
      this.loadSeverities();
      this.loading = false;
    }
  },
  methods: {
    loadObjects() {
      if (this.objectname) {
        const self = this;
        this.objectList.forEach(function (item, key) {
          item.chosen = self.objectname.includes(item.id);
          if (self.objectname.includes(item.id)) {
            self.objectnamesChosen.push(key);
          }
        });
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    loadSeverities() {
      if (this.severity) {
        const self = this;
        this.severityList.forEach(function (item, key) {
          item.chosen = self.severity.includes(item.id);
          if (self.severity.includes(item.id)) {
            self.severitiesChosen.push(key);
          }
        });
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    chooseObjectname() {
      const self = this;
      this.objectList.forEach(function (item) {
        item.chosen = false;
      });
      this.objectnamesChosen.forEach(function (item) {
        self.objectList[item].chosen = true;
      });
      const objectname = [];
      this.objectList.forEach(function (item) {
        if (item.chosen) {
          objectname.push(item.id);
        }
      });
      this.updateObjectname(objectname);
    },
    chooseSeverity() {
      const self = this;
      this.severityList.forEach(function (item) {
        item.chosen = false;
      });
      this.severitiesChosen.forEach(function (item) {
        self.severityList[item].chosen = true;
      });
      const severity = [];
      this.severityList.forEach(function (item) {
        if (item.chosen) {
          severity.push(item.id);
        }
      });
      this.updateSeverity(severity);
    },
    updateObjectname(objectname) {
      // this.chooseSeverity();
      this.objectname = objectname;
      this.$emit("update", {
        objectname: objectname,
        severity: this.severity,
      });
    },
    updateSeverity(severity) {
      // this.chooseObjectname();
      this.severity = severity;
      this.$emit("update", {
        objectname: this.objectname,
        severity: severity,
      });
    },
  },
};
</script>
