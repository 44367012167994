<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import echarts from "echarts";
require("@/plugins/echarts/lightTheme");
require("@/plugins/echarts/darkTheme");
import { debounce } from "@/utils";

// const animationDuration = 6000;

export default {
  props: {
    id: {
      type: String,
      default: "dashboard-system-logs-widget",
    },
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "224px",
    },
    xAxisData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    refresh: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    legend: {
      type: Object,
      default: function () {
        return {
          data: [
            "Auditor",
            "Config",
            "Firewall",
            "Login",
            "Miner",
            "Rule",
            "SecPolicy",
            "System",
          ],
        };
      },
    },
    data: {
      type: Array,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    "$vuetify.theme.dark": {
      immediate: true,
      deep: true,
      handler() {
        if (this.chart) {
          this.chart.dispose();
          this.chart = null;
          this.$nextTick(() => {
            this.initChart();
          });
        }
      },
    },
    data: {
      deep: true,
      handler() {
        if (this.chart) {
          this.chart.dispose();
          this.chart = null;
        }
        this.$nextTick(() => {
          this.initChart();
        });
      },
    },
    refresh: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          if (this.chart) {
            this.chart.dispose();
            this.chart = null;
          }
          this.$nextTick(() => {
            this.initChart();
          });
        }
      },
    },
  },
  created() {
    window.addEventListener("focus", this.onFocus);
  },
  destroyed() {
    window.removeEventListener("focus", this.onFocus);
  },
  mounted() {
    this.initChart();
    this.chart.resize();
    this.__resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.__resizeHandler);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    onFocus() {
      // User has returned to the app
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      this.$nextTick(() => {
        this.initChart();
      });
    },
    initChart() {
      const element = document.getElementById(this.id);
      if (element) {
        if (this.$vuetify.theme.dark) {
          this.chart = echarts.init(element, "darkTheme");
        } else {
          this.chart = echarts.init(element, "lightTheme");
        }

        this.chart.setOption({
          xAxis: {
            show: true,
            type: "category",
            axisLabel: {
              formatter: (value) => {
                const date = new Date(value);
                if (date.getDate() === new Date().getDate()) {
                  // if it's the current date, display only the time
                  return date.toLocaleTimeString();
                } else {
                  // otherwise, display the date and time
                  return `{a|${date.toLocaleDateString()}} {b|${date.toLocaleTimeString(
                    [],
                    { hour: "numeric", minute: "2-digit", hour12: false }
                  )}}`;
                }
              },
              rich: {
                a: {
                  fontSize: 10,
                },
                b: {
                  fontSize: 8,
                },
              },
            },
            data: this.xAxisData,
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: function (value) {
                if (value >= 1000000) {
                  return `{a|${value / 1000000}M}`;
                } else if (value >= 1000) {
                  return `{a|${value / 1000}K}`;
                } else {
                  return `{a|${value}}`;
                }
              },
              rich: {
                a: {
                  fontSize: 11.5,
                },
              },
            },
          },
          grid: {
            top: 30,
            bottom: 20,
            left: 35,
            right: 20,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            formatter: function (params) {
              const date = new Date(params[0].name);
              let text = "";
              if (date.getDate() === new Date().getDate()) {
                // if it's the current date, display only the time
                text += `<b>${date.toLocaleTimeString([], {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: false,
                })}</b><hr/>`;
              } else {
                text = `<b>${date.toLocaleDateString()} ${date.toLocaleTimeString(
                  [],
                  { hour: "numeric", minute: "2-digit", hour12: false }
                )}</b><hr/>`;
              }
              params.forEach(
                (param) =>
                  (text += `<span style="color:${param.color};">${param.seriesName}: ${param.value}</span><br />`)
              );
              return text;
            },
          },
          legend: this.legend,
          series: this.data,
        });
      }
    },
  },
};
</script>
<styles lang="scss" scoped>
.chart {
  font-size: 14px;
}
</styles>
