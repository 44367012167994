<template>
  <v-container :fluid="true" class="role-container pt-0 mh-100 form">
    <topbar
      :size="size"
      :keywordsResize2="keywordsResize2"
      @resize="resize($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-show="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pb-2 pt-3">
            <v-col cols="6" class="px-0">
              <h3 class="font-ubuntu-bold roles-sidebar-heading">
                {{ id === 0 ? "Create a role" : "Edit role" }}
              </h3>
              <v-text-field
                class="name-field"
                label="Name"
                v-model="name"
                dense
                solo
              ></v-text-field>
            </v-col>
            <v-list dense>
              <v-list-item class="pb-4">
                <v-list-item-title
                  class="font-roboto-condensed-regular font-size-15"
                >
                  Permissions</v-list-item-title
                >
              </v-list-item>
              <v-row class="custom-grid">
                <v-col
                  class="permissionOuter section"
                  :class="`grid-item__${index}`"
                  v-for="(permission, index) in permissions"
                  :key="permission.name"
                >
                  <v-row class="px-2">
                    <v-col cols="12">
                      <span
                        class="section-title font-roboto-condensed-regular font-size-15"
                        >{{
                      }}</span>
                      <v-list-item>
                        <template v-slot:default>
                          <v-list-item-action>
                            <i
                              @click="
                                changePermissionAll(
                                  permission.id,
                                  null,
                                  'parent'
                                )
                              "
                              :id="permission.id"
                              :data-count="permission.count"
                              :data-cluster="index"
                              data-type="parent"
                              class="role-icon cursor-pointer fas"
                            ></i>
                          </v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                $t("permissions." + permission.name)
                              }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                      <div
                        v-for="(
                          permChildren, indexChildren
                        ) in permission.children"
                        :key="permChildren.id"
                        class="permission-children-outer"
                      >
                        <v-list-item class="permission-children">
                          <template v-slot:default>
                            <v-list-item-action>
                              <i
                                @click="
                                  changePermissionAll(
                                    permChildren.id,
                                    permChildren.parent_id,
                                    'children'
                                  )
                                "
                                :id="permChildren.id"
                                :data-count="permChildren.count"
                                :data-cluster="index"
                                :data-childrenCluster="
                                  index + ',' + indexChildren
                                "
                                :data-parentid="permChildren.parent_id"
                                data-type="children"
                                class="role-icon cursor-pointer fas"
                              ></i>
                            </v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("permissions." + permChildren.name) }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>

                        <div
                          v-for="(
                            permGrandChildren, indexGrandchildren
                          ) in permChildren.grandchildren"
                          :key="permGrandChildren.id"
                          class="permission-grandchildren-outer"
                        >
                          <v-list-item class="permission-grandchildren">
                            <template v-slot:default>
                              <v-list-item-action>
                                <i
                                  @click="
                                    changePermissionAll(
                                      permGrandChildren.id,
                                      permGrandChildren.parent_id,
                                      'grandchildren'
                                    )
                                  "
                                  :id="permGrandChildren.id"
                                  :data-count="permGrandChildren.count"
                                  :data-cluster="index"
                                  :data-childrenCluster="
                                    index + ',' + indexChildren
                                  "
                                  :data-grandchildrenCluster="
                                    index +
                                    ',' +
                                    indexChildren +
                                    ',' +
                                    indexGrandchildren
                                  "
                                  data-type="grandchildren"
                                  :data-parentid="permGrandChildren.parent_id"
                                  class="role-icon cursor-pointer fas"
                                ></i>
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title>
                                  {{
                                    $t("permissions." + permGrandChildren.name)
                                  }}</v-list-item-title
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                          <v-list-item
                            v-for="(
                              permGrandGrandChildren, indexGrandGrandchildren
                            ) in permGrandChildren.grandgrandchildren"
                            :key="permGrandGrandChildren.id"
                            class="permission-grandgrandchildren"
                          >
                            <template v-slot:default>
                              <v-list-item-action>
                                <i
                                  @click="
                                    changePermissionAll(
                                      permGrandGrandChildren.id,
                                      permGrandGrandChildren.parent_id,
                                      'grandgrandchildren'
                                    )
                                  "
                                  :id="permGrandGrandChildren.id"
                                  :data-count="permGrandGrandChildren.count"
                                  :data-cluster="index"
                                  :data-childrenCluster="
                                    index + ',' + indexChildren
                                  "
                                  :data-grandchildrenCluster="
                                    index +
                                    ',' +
                                    indexChildren +
                                    ',' +
                                    indexGrandchildren
                                  "
                                  :data-grandgrandchildrenCluster="
                                    index +
                                    ',' +
                                    indexChildren +
                                    ',' +
                                    indexGrandchildren +
                                    ',' +
                                    indexGrandGrandchildren
                                  "
                                  :data-parentid="
                                    permGrandGrandChildren.parent_id
                                  "
                                  data-type="grandgrandchildren"
                                  class="role-icon cursor-pointer fas"
                                ></i>
                              </v-list-item-action>

                              <v-list-item-content>
                                <v-list-item-title
                                  ><!-- Menu: -->
                                  {{
                                    $t(
                                      "permissions." +
                                        permGrandGrandChildren.name
                                    )
                                  }}</v-list-item-title
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-list>
            <!-- <v-col cols="12" class="btn-wrapper py-0 px-0"> -->
            <div class="btn-wrapper py-0 px-0">
              <v-icon v-if="saving" color="primary">
                fas fa-spinner fa-spin
              </v-icon>
              <span
                v-if="saving"
                class="font-roboto-condensed-light font-size-15 ml-2"
              >
                Processing...
              </span>
              <div v-else>
                <v-btn
                  v-if="id === 0"
                  @click="createRole()"
                  color="primary"
                  small
                  rounded
                  class="text-right"
                  >Create</v-btn
                >
                <v-btn
                  v-else
                  @click="updateRolePermissions()"
                  color="primary"
                  small
                  rounded
                  class="text-right"
                  >Update</v-btn
                >
              </div>
            </div>
            <!-- </v-col> -->
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import {
  getRoleList,
  getRolePermission,
  updateRole,
  getRole,
  storeRole,
} from "@/api/system/role";
import { getPermissions } from "@/api/system/permission";
import { getInfo } from "@/api/auth";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      name: "",
      loading: true,
      checkLoading: false,
      checkValid: null,
      valid: true,
      validCheck: true,
      flatPermissions: [],
      saving: false,
      role: "",
      rolePermissions: [],
      permissions: [],
      roleRules: {
        required: (v) => !!v || "Role is required",
      },
      roles: [],
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(newValue) {
        this.loadPermissions();
        if (newValue === 0) {
          this.name = "";
        } else {
          this.loadRoleData();
        }
      },
    },
  },
  mounted() {
    this.renderEmptyIcons();
  },
  methods: {
    loadRoleData() {
      getRole(this.id)
        .then((response) => {
          this.name = response.data.name;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },

    renderIcons() {
      this.rolePermissions.forEach(function (value) {
        const icon = document.querySelector(
          `#${CSS.escape(value.permission_id)}`
        );

        if (icon) {
          if (value.permission === 0) {
            icon.classList.add("fa-times-circle", "red-icon");
            icon.setAttribute("value", 0);
          }
          if (value.permission === 1 && value.permission_id === 40) {
            icon.classList.add("fa-check-circle", "green-icon");
            icon.setAttribute("value", 1);
          } else if (value.permission === 1) {
            icon.classList.add("fa-eye", "blue-icon");
            icon.setAttribute("value", 1);
          }
          if (value.permission === 2) {
            icon.classList.add("fa-edit", "green-icon");
            icon.setAttribute("value", 2);
          }
        }
      });
      this.$nextTick(() => {
        this.loading = false;
      });
    },

    changePermissionAll(itemId, parentId, type) {
      let icon = document.querySelector(`#${CSS.escape(itemId)}`);
      let iconValue = icon.getAttribute("value");

      if (type === "parent") {
        /* Get all the icons that belong to the same root along with parent*/
        let iconsCluster = document.querySelectorAll(
          `[data-cluster="${icon.dataset.cluster}"]`
        );

        for (let i = 0; i < iconsCluster.length; ++i) {
          if (iconValue == 0) {
            this.valueToIcon(iconsCluster[i], "toBlue");
            iconsCluster[i].setAttribute("value", 1);
          }
          if (iconValue == 1) {
            this.valueToIcon(iconsCluster[i], "toRed");
            iconsCluster[i].setAttribute("value", 0);
          }
          if (iconValue == 2) {
            this.valueToIcon(iconsCluster[i], "toBlue");
            iconsCluster[i].setAttribute("value", 2);
          }
        }

        if (icon.count === 3) {
          console.log("Parents should not have count 3");
        }
      } else if (type === "children") {
        let iconsClusterChildren = document.querySelectorAll(
          `[data-childrencluster="${icon.dataset.childrencluster}"]`
        );
        if (icon.dataset.count == 2) {
          for (let i = 0; i < iconsClusterChildren.length; ++i) {
            if (iconValue == 0) {
              this.valueToIcon(iconsClusterChildren[i], "toBlue");
              iconsClusterChildren[i].setAttribute("value", 1);
            }
            if (iconValue == 1) {
              this.valueToIcon(iconsClusterChildren[i], "toRed");
              iconsClusterChildren[i].setAttribute("value", 0);
            }
          }
        } else if (icon.dataset.count == 3) {
          if (iconValue == 1) {
            this.valueToIcon(icon, "toGreen");
            icon.setAttribute("value", 2);
          } else {
            for (let i = 0; i < iconsClusterChildren.length; ++i) {
              if (iconValue == 0) {
                this.valueToIcon(iconsClusterChildren[i], "toBlue");
                iconsClusterChildren[i].setAttribute("value", 1);
              }
              if (iconValue == 2) {
                this.valueToIcon(iconsClusterChildren[i], "toRed");
                iconsClusterChildren[i].setAttribute("value", 0);
              }
            }
          }
        }
        this.checkEquality(itemId, "children");
      } else if (type === "grandchildren") {
        let iconsClusterChildren = document.querySelectorAll(
          `[data-grandchildrencluster="${icon.dataset.grandchildrencluster}"]`
        );
        if (icon.dataset.count == 2) {
          for (let i = 0; i < iconsClusterChildren.length; ++i) {
            if (iconValue == 0) {
              this.valueToIcon(iconsClusterChildren[i], "toBlue");
              iconsClusterChildren[i].setAttribute("value", 1);
            }
            if (iconValue == 1) {
              this.valueToIcon(iconsClusterChildren[i], "toRed");
              iconsClusterChildren[i].setAttribute("value", 0);
            }
          }
        } else if (icon.dataset.count == 3) {
          if (iconValue == 1) {
            this.valueToIcon(icon, "toGreen");
            icon.setAttribute("value", 2);
          } else {
            for (let i = 0; i < iconsClusterChildren.length; ++i) {
              if (iconValue == 0) {
                this.valueToIcon(iconsClusterChildren[i], "toBlue");
                iconsClusterChildren[i].setAttribute("value", 1);
              }
              if (iconValue == 2) {
                this.valueToIcon(iconsClusterChildren[i], "toRed");
                iconsClusterChildren[i].setAttribute("value", 0);
              }
            }
          }
        }
        this.checkEquality(itemId, "grandchildren");
      } else if (type === "grandgrandchildren") {
        if (icon.dataset.count == 2) {
          if (iconValue == 0) {
            this.valueToIcon(icon, "toBlue");
            icon.setAttribute("value", 1);
          }
          if (iconValue == 1) {
            this.valueToIcon(icon, "toRed");
            icon.setAttribute("value", 0);
          }
        }
        if (icon.dataset.count == 3) {
          if (iconValue == 0) {
            this.valueToIcon(icon, "toBlue");
            icon.setAttribute("value", 1);
          }
          if (iconValue == 1) {
            this.valueToIcon(icon, "toRed");
            icon.setAttribute("value", 0);
          }
          if (iconValue == 2) {
            this.valueToIcon(icon, "toRed");
            icon.setAttribute("value", 0);
          }
        }
        this.checkEquality(itemId, "grandgrandchildren");
      }
    },

    valueToIcon(icon, type) {
      if (type === "toGreen") {
        icon.classList.remove(
          "fa-times-circle",
          "red-icon",
          "fa-eye",
          "blue-icon"
        );
        icon.classList.add("fa-edit", "green-icon");
      }
      if (type === "toRed") {
        icon.classList.remove("fa-edit", "green-icon", "fa-eye", "blue-icon");
        icon.classList.add("fa-times-circle", "red-icon");
      }
      if (type === "toBlue") {
        icon.classList.remove(
          "fa-edit",
          "green-icon",
          "fa-times-circle",
          "red-icon"
        );
        if (icon.id === "40") {
          icon.classList.add("fa-check-circle", "green-icon");
        } else {
          icon.classList.add("fa-eye", "blue-icon");
        }
      }
    },
    /*Checks whether parent icon value should be changed based on the values of children  */
    checkEquality(itemId, type) {
      let icon = document.querySelector(`#${CSS.escape(itemId)}`);

      /*Level 4 check  */
      if (type === "grandgrandchildren") {
        let iconsCluster = document.querySelectorAll(
          `[data-grandchildrencluster="${icon.dataset.grandchildrencluster}"][data-parentid="${icon.dataset.parentid}"]`
        );
        const permissionsValues = [];
        for (let i = 0; i < iconsCluster.length; ++i) {
          permissionsValues.push(iconsCluster[i].getAttribute("value"));
        }
        const allEqual = permissionsValues.every(
          (val, i, arr) => val === arr[0]
        );
        let parentIcon = document.querySelector(
          `#${CSS.escape(iconsCluster[0].dataset.parentid)}`
        );
        if (allEqual) {
          let sameValue = iconsCluster[0].getAttribute("value");
          if (sameValue == 1) {
            this.valueToIcon(parentIcon, "toBlue");
            parentIcon.setAttribute("value", 1);
          }
          if (sameValue == 0) {
            this.valueToIcon(parentIcon, "toRed");
            parentIcon.setAttribute("value", 0);
          }
        } else {
          this.valueToIcon(parentIcon, "toBlue");
          parentIcon.setAttribute("value", 1);
        }
      }
      /*Level 3 check  */
      if (type === "grandchildren" || type === "grandgrandchildren") {
        if (type === "grandgrandchildren") {
          /* If coming from level 4 go level higher */
          icon = document.querySelector(
            `#${CSS.escape(icon.dataset.parentid)}`
          );
        }
        let iconsCluster = document.querySelectorAll(
          `[data-childrencluster="${icon.dataset.childrencluster}"][data-parentid="${icon.dataset.parentid}"]`
        );
        const permissionsValues = [];
        for (let i = 0; i < iconsCluster.length; ++i) {
          permissionsValues.push(iconsCluster[i].getAttribute("value"));
        }

        const allEqual = permissionsValues.every(
          (val, i, arr) => val === arr[0]
        );
        let parentIcon = document.querySelector(
          `#${CSS.escape(iconsCluster[0].dataset.parentid)}`
        );
        if (allEqual) {
          let sameValue = iconsCluster[0].getAttribute("value");
          if (sameValue == 1) {
            this.valueToIcon(parentIcon, "toBlue");
            parentIcon.setAttribute("value", 1);
          }
          if (sameValue == 0) {
            this.valueToIcon(parentIcon, "toRed");
            parentIcon.setAttribute("value", 0);
          }
        } else {
          this.valueToIcon(parentIcon, "toBlue");
          parentIcon.setAttribute("value", 1);
        }
      }
      /*Level 2 check  */
      if (
        type === "children" ||
        type === "grandchildren" ||
        type === "grandgrandchildren"
      ) {
        if (type === "grandgrandchildren" || type === "grandchildren") {
          /* If coming from level 3 or 4 go level higher */
          icon = document.querySelector(
            `#${CSS.escape(icon.dataset.parentid)}`
          );
        }
        let iconsCluster = document.querySelectorAll(
          `[data-cluster="${icon.dataset.cluster}"][data-parentid="${icon.dataset.parentid}"]`
        );
        const permissionsValues = [];
        for (let i = 0; i < iconsCluster.length; ++i) {
          permissionsValues.push(iconsCluster[i].getAttribute("value"));
        }

        const allEqual = permissionsValues.every(
          (val, i, arr) => val === arr[0]
        );
        let parentIcon = document.querySelector(
          `#${CSS.escape(iconsCluster[0].dataset.parentid)}`
        );
        if (allEqual) {
          let sameValue = iconsCluster[0].getAttribute("value");
          if (sameValue == 1) {
            this.valueToIcon(parentIcon, "toBlue");
            parentIcon.setAttribute("value", 1);
          }
          if (sameValue == 0) {
            this.valueToIcon(parentIcon, "toRed");
            parentIcon.setAttribute("value", 0);
          }
        } else {
          this.valueToIcon(parentIcon, "toBlue");
          parentIcon.setAttribute("value", 1);
        }
      }
    },
    loadRoles() {
      getRoleList()
        .then((response) => {
          this.roles = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadPermissions() {
      getPermissions()
        .then((response) => {
          response.data.forEach(function (value) {
            value.name = value.name.split(" ").join("_");
          });
          this.permissions = response.data;
          if (this.id !== 0) {
            this.loadRolePermissions();
          } else if (this.id == 0) {
            this.$nextTick(() => {
              this.renderEmptyIcons();
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    loadRolePermissions() {
      getRolePermission(this.id)
        .then((response) => {
          this.rolePermissions = response.data;
          this.renderIcons();
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    updateRolePermissions() {
      this.saving = true;
      this.rolePermissions.forEach(function (value) {
        let icon = document.querySelector(
          `#${CSS.escape(value.permission_id)}`
        );
        value.permission = icon ? icon.getAttribute("value") : 1;
      });
      const data = {
        id: this.id,
        permissions: this.rolePermissions ?? [],
        name: this.name,
      };

      updateRole(data, this.id)
        .then(() => {
          this.changeInAddForm = "role";
          this.close();
          this.$toast.open({
            message: "Role was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          getInfo()
            .then((response) => {
              localStorage.setItem("permissions", response.data.permissions);
              if (this.name === response.data.userData.role) {
                const to = this.$route;
                const permissions = localStorage
                  .getItem("permissions")
                  .split(",");
                let isAllowed = permissions.some((p) =>
                  to.meta.permission.includes(p)
                );
                if (!isAllowed) {
                  this.$router.go();
                }
              }
            })
            .catch((e) => {
              Errors.show(e);
            });
          this.saving = false;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },

    createRole() {
      this.saving = true;
      if (this.name) {
        const rolePermissions = [];
        const allRoleIcons = document.querySelectorAll("i.role-icon");
        allRoleIcons.forEach(function (icon) {
          rolePermissions.push({
            permission: icon.getAttribute("value"),
            permission_id: icon.id,
          });
        });
        const data = {
          id: this.id,
          permissions: rolePermissions,
          name: this.name,
        };
        storeRole(data)
          .then(() => {
            this.changeInAddForm = "role";
            this.close();
            this.$toast.open({
              message: "Role was successfully updated!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.saving = true;
          })
          .catch((e) => {
            this.saving = true;
            Errors.show(e);
          });
      } else {
        this.$toast.open({
          message: "Name is required!",
          type: "error",
          position: "top-right",
          duration: 2000,
        });
      }
    },
    /* If in create mode, render all icons with no permissions */
    renderEmptyIcons() {
      const allRoleIcons = document.querySelectorAll("i.role-icon");
      allRoleIcons.forEach(function (icon) {
        icon.classList.add("fa-times-circle", "red-icon");
        icon.setAttribute("value", 0);
      });
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },

    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "user");
    },
  },
};
</script>

<style lang="scss" scoped>
.group-activator {
  background: blue !important;
}

.permission-children {
  margin-left: 30px;
}
.permission-grandchildren {
  margin-left: 70px;
}
.permission-grandgrandchildren {
  margin-left: 100px;
}

.v-list-item__action {
  margin-right: 10px !important;
}
.blue-icon {
  color: #808485;
}
.green-icon {
  color: #3b7c16;
}
.red-icon {
  color: #911d34;
}
.name-field {
  padding: 10px !important;
  margin-bottom: -20px !important;
}
.btn-wrapper {
  margin-top: 20px;
  margin-left: 5px;
  display: flex;
}
.v-list-item {
  height: 30px;
}

.v-list--dense .v-list-item,
.v-list-item--dense {
  min-height: 30px;
}

.add-form-sidebar.theme--dark .topbar-block {
  background-color: #000000;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.role-container {
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
  overflow-x: scroll;
}

.v-navigation-drawer__content {
  height: unset;
  overflow-y: unset;
  overflow-x: auto;
}

.roles-sidebar-heading {
  padding-left: 10px;
  padding-top: 10px;
}

.custom-grid {
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(2, 48%);
}

.grid-item__0 {
  grid-row: 1 / 2;
}
.grid-item__1 {
  grid-row: 1 / 2;
}
.grid-item__2 {
  grid-row: 2 / 3;
}
.grid-item__7 {
  grid-row: 2 / 3;
}
.grid-item__3 {
  grid-row: 3 / 4;
}
.grid-item__8 {
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}
.grid-item__6 {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
}
.grid-item__9 {
  grid-row: 4 / 5;
}

.section {
  margin: 0.5rem;
  margin-right: 2rem;
  position: relative;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
}
.theme--dark {
  .section {
    background: #3e3e3e;
    box-shadow: 0px 0px 5px 0px #3e3e3e;
  }
}
.theme--light {
  .section {
    background: #f4f4f4;
    box-shadow: 0px 0px 5px 0px #f4f4f4;
  }
}
</style>
