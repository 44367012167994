<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <!--TABS-->
          <v-col cols="12" class="pb-0">
            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'general'"
              :color="activeTab === 'general' ? 'primary' : ''"
              :outlined="activeTab === 'general'"
              rounded
              @click="activeTab = 'general'"
            >
              <v-badge
                v-if="!validGeneral"
                color="error"
                icon="fas fa-exclamation"
              >
                General
              </v-badge>
              <span v-else>General</span>
            </v-btn>

            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'results'"
              :color="activeTab === 'results' ? 'primary' : ''"
              :outlined="activeTab === 'results'"
              rounded
              @click="activeTab = 'results'"
            >
              <span>Results</span>
            </v-btn>

            <v-btn
                class="text-capitalize mr-2 synapsa-tab"
                :text="activeTab !== 'help'"
                :color="activeTab === 'help' ? 'primary' : ''"
                :outlined="activeTab === 'help'"
                rounded
                @click="activeTab = 'help'"
            >
              <span>Help</span>
            </v-btn>
          </v-col>
          <!--END TABS-->

          <v-col cols="12" class="pt-0">
            <transition
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="d-none"
            >
              <general
                v-if="!loading && activeTab === 'general'"
                :mount-data="general"
                :show-auditor-groups="this.showAuditorGroups()"
                :show-force="this.showForce()"
                @updateEmit="updateGeneral($event)"
                @close="close()"
                @change="change()"
              />
            </transition>
            <transition
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="d-none"
            >
              <results
                v-if="activeTab === 'results'"
                :id="id"
                searchable
              />
            </transition>
            <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
            >
              <v-container v-if="activeTab === 'help'" :fluid="true" class="px-0 py-0">
                <v-row class="mx-0" :class="'mt-4'">
                  <v-col cols="12" class="py-0 px-0 font-roboto-condensed-regular">
                    <span class="font-ubuntu-bold">Auditor groups:</span>
                    <p>
                      Auditor groups are available only for these types of call:
                    </p>
                    <ol class="mb-2">
                      <li><b>auditor:integrityCheck</b> - Integrity Check on Firewall</li>
                      <li><b>auditor:ruleset</b> - Check Ruleset of Firewall</li>
                    </ol>
                    <p>
                      <b>NOTE:</b> If you leave an empty list of auditor groups, the task will execute every auditor.
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </transition>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import general from "./general";
import results from "./results";

import { Errors } from "@/utils/error";
import { Validation } from "./validation";
import {
  getTask,
  taskTimezones,
  taskCommandList,
  taskFrequencies,
} from "@/api/system/task";
import { getAuditorGroups } from "@/api/auditor/group";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
    general,
    results,
  },
  data() {
    return {
      activeTab: "general",

      valid: true,
      validGeneral: true,
      loading: true,
      loadingTask: true,
      loadingTimezoneList: true,
      loadingCommandList: true,
      loadingFrequencyList: true,
      saving: false,

      general: {
        id: this.id,
        description: "",
        command: "",
        timezone: "",
        expression: "",
        expression_type: "",
        dont_overlap: false,
        run_in_maintenance: false,
        notification_email_address: "",
        notification_slack_webhook: "",
        auto_cleanup_num: "",
        auto_cleanup_type: "",
        frequencies: [],
        timezoneList: [],
        commandList: [],
        frequencyList: [],
        auditorGroup: [],
        auditorGroups: [],
      },
    };
  },
  watch: {
    activeTab: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.validateTabs(newValue);
      },
    },
    valid: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (this.activeTab === "general") {
          this.validGeneral = newValue;
        }
      },
    },
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.activeTab = "general";
          this.general = {
            id: this.id,
            description: "",
            command: "",
            timezone: "",
            expression: "",
            expression_type: "",
            dont_overlap: false,
            run_in_maintenance: false,
            notification_email_address: "",
            notification_slack_webhook: "",
            auto_cleanup_num: 20,
            auto_cleanup_type: "results",
            frequencies: [],
            timezoneList: [],
            commandList: [],
            frequencyList: [],
            auditorGroup: [],
            auditorGroups: [],
          };

          this.setLoadingData();
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  mounted() {
    this.getTimezones();
    this.getCommands();
    this.getFrequencies();
  },
  created() {
    this.loadAuditorGroups();
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "systemtask");
    },
    loadById() {
      getTask(this.id)
        .then((response) => {
          const task = response.data;
          //general
          this.general.description = task.description;
          this.general.command = task.command;
          this.general.timezone = task.timezone;
          this.general.expression = task.expression;
          this.general.expression_type = task.expression_type;
          this.general.frequencies = task.frequencies;
          this.general.dont_overlap = task.dont_overlap;
          this.general.run_in_maintenance = task.run_in_maintenance;
          this.general.force = task.force;
          this.general.notification_email_address =
            task.notification_email_address;
          this.general.notification_slack_webhook =
            task.notification_slack_webhook;
          this.general.auto_cleanup_num = task.auto_cleanup_num;
          this.general.auto_cleanup_type = task.auto_cleanup_type;
          this.general.auditorGroup = task.auditorGroups;

          this.loadingTask = false;
          this.setLoadingData();
        })
        .catch((e) => {
          this.loadingTask = false;
          this.setLoadingData();
          Errors.show(e);
        });
    },
    validate() {
      this.$refs.form.validate();
      this.validateTabs();
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    validateTabs(tab) {
      if (tab) {
        if (tab === "general") {
          //TODO
        } else if (tab === "results") {
          this.validGeneral = !!Validation.general(this.general);
        }
      }
      if (this.activeTab === "general" && !this.validGeneral) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      }
    },
    getTimezones() {
      taskTimezones()
        .then((response) => {
          this.general.timezoneList = response.data;
          this.loadingTimezoneList = false;
          this.setLoadingData();
        })
        .catch((e) => {
          this.loadingTimezoneList = false;
          this.setLoadingData();
          Errors.show(e);
        });
    },
    getCommands() {
      taskCommandList()
        .then((response) => {
          this.general.commandList = response.data;
          this.loadingCommandList = false;
          this.setLoadingData();
        })
        .catch((e) => {
          this.loadingCommandList = false;
          this.setLoadingData();
          Errors.show(e);
        });
    },
    getFrequencies() {
      taskFrequencies()
        .then((response) => {
          this.general.frequencyList = response.data;
          this.loadingFrequencyList = false;
          this.setLoadingData();
        })
        .catch((e) => {
          this.loadingFrequencyList = false;
          this.setLoadingData();
          Errors.show(e);
        });
    },
    loadAuditorGroups() {
      getAuditorGroups()
          .then((response) => {
            this.general.auditorGroups = response;
          })
          .catch((e) => {
            Errors.show(e);
          });
    },
    showAuditorGroups() {
      const auditorGroupsConditionCommand = ['auditor:integrityCheck', 'auditor:ruleset'];
      return auditorGroupsConditionCommand.includes(this.general.command);
    },
    showForce() {
      const auditorGroupsConditionCommand = ['auditor:ruleset'];
      return auditorGroupsConditionCommand.includes(this.general.command);
    },
    setLoadingData() {
      if (this.id === 0) {
        if (
          !this.loadingTimezoneList &&
          !this.loadingCommandList &&
          !this.loadingFrequencyList
        ) {
          this.loading = false;
        }
      } else {
        if (
          !this.loadingTask &&
          !this.loadingTimezoneList &&
          !this.loadingCommandList &&
          !this.loadingFrequencyList
        ) {
          this.loading = false;
        }
      }
    },
    updateGeneral(event) {
      this.general = {
        ...this.general,
        ...event
      }
    }
  },
};
</script>
