<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :keywordsResize2="keywordsResize2"
      @resize="resize($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pb-2 pt-3">
            <h3 class="font-ubuntu-bold">Add new License</h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--LICENSE-->
              <v-row class="mx-0" :class="'mt-4'">
                <v-col cols="12" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >License Key</label
                  >
                  <v-text-field
                    v-model="license"
                    :rules="[licenseRules.required]"
                    type="password"
                    label="License"
                    placeholder="License"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END LICENSE-->

              <v-row class="mt-1 mx-0">
                <v-col cols="12" class="py-0 px-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import { storeLicense } from "@/api/system/license";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
    keywordsResize2: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,

      valid: true,
      validCheck: true,

      saving: false,

      license: "",
      licenseRules: {
        required: (v) => !!v || "License is required",
      },
    };
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "systemLicense");
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.store();
      }
    },
    store() {
      this.saving = true;
      const data = {
        license: this.license,
      };
      storeLicense(data)
        .then(() => {
          this.$toast.open({
            message: "License was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
