import Layout from "@/layout";

const monitorRoutes = {
  path: "/monitor",
  name: "monitor",
  component: Layout,
  meta: {
    requiresAuth: true,
    id: "monitor",
    icon: "monitor.svg",
    permission: ["view menu monitor"],
  },
  redirect: "/monitor/auditor/ruleset",
  children: [
    {
      path: "auditor",
      component: () => import("@/views/monitor/auditor"),
      name: "auditorAlert",
      meta: {
        id: "auditorAlert",
        active: ["monitor", "auditorAlert"],
        icon: "monitor-auditor.svg",
        permission: ["view auditor"],
      },
      redirect: "/monitor/auditor/ruleset",
      children: [
        {
          path: "ruleset",
          component: () => import("@/views/monitor/auditor/ruleset"),
          name: "rulesetAlert",
          meta: {
            id: "rulesetAlert",
            active: ["monitor", "auditorAlert", "rulesetAlert"],
            permission: ["view monitorauditorruleset"],
          },
          hidden: true,
          redirect: "/monitor/auditor/ruleset/active",
          children: [
            {
              path: "active",
              component: () => import("@/views/monitor/auditor/ruleset/active"),
              name: "rulesetAlertActive",
              meta: {
                id: "rulesetAlertActive",
                active: [
                  "monitor",
                  "auditorAlert",
                  "rulesetAlert",
                  "rulesetAlertActive",
                ],
                permission: ["view rulesetactivealert"],
              },
              hidden: true,
            },
            {
              path: "confirmed",
              component: () =>
                import("@/views/monitor/auditor/ruleset/confirmed"),
              name: "rulesetAlertConfirmed",
              meta: {
                id: "rulesetAlertConfirmed",
                active: [
                  "monitor",
                  "auditorAlert",
                  "rulesetAlert",
                  "rulesetAlertConfirmed",
                ],
                permission: ["view rulesetconfirmedalert"],
              },
              hidden: true,
            },
            {
              path: "fixed",
              component: () => import("@/views/monitor/auditor/ruleset/fixed"),
              name: "rulesetAlertFixed",
              meta: {
                id: "rulesetAlertFixed",
                active: [
                  "monitor",
                  "auditorAlert",
                  "rulesetAlert",
                  "rulesetAlertFixed",
                ],
                permission: ["view rulesetfixedalert"],
              },
              hidden: true,
            },
          ],
        },
        {
          path: "cve",
          component: () => import("@/views/monitor/auditor/cve"),
          name: "cveAlert",
          meta: {
            id: "cveAlert",
            active: ["monitor", "auditorAlert", "cveAlert"],
            permission: ["view monitorauditorcve"],
          },
          hidden: true,
          children: [
            {
              path: "active",
              component: () => import("@/views/monitor/auditor/cve/active"),
              name: "cveAlertActive",
              meta: {
                id: "cveAlertActive",
                active: [
                  "monitor",
                  "auditorAlert",
                  "cveAlert",
                  "cveAlertActive",
                ],
                permission: ["view monitorauditorcve"],
              },
              hidden: true,
            },
            {
              path: "confirmed",
              component: () => import("@/views/monitor/auditor/cve/confirmed"),
              name: "cveAlertConfirmed",
              meta: {
                id: "cveAlertConfirmed",
                active: [
                  "monitor",
                  "auditorAlert",
                  "cveAlert",
                  "cveAlertConfirmed",
                ],
                permission: ["view monitorauditorcve"],
              },
              hidden: true,
            },
          ],
        },
        {
          path: "integrity",
          component: () => import("@/views/monitor/auditor/integrity"),
          name: "integrityAlert",
          meta: {
            id: "integrityAlert",
            active: ["monitor", "auditorAlert", "integrityAlert"],
            // permission: ["view monitorauditorintegrity"],
          },
          hidden: true,
          children: [
            {
              path: "active",
              component: () => import("@/views/monitor/auditor/integrity/active"),
              name: "integrityAlertActive",
              meta: {
                id: "integrityAlertActive",
                active: [
                  "monitor",
                  "auditorAlert",
                  "integrityAlert",
                  "integrityAlertActive",
                ],
                // permission: ["view monitorauditorintegrity"],
              },
              hidden: true,
            },
            {
              path: "confirmed",
              component: () => import("@/views/monitor/auditor/integrity/confirmed"),
              name: "integrityAlertConfirmed",
              meta: {
                id: "integrityAlertConfirmed",
                active: [
                  "monitor",
                  "auditorAlert",
                  "integrityAlert",
                  "integrityAlertConfirmed",
                ],
                // permission: ["view monitorauditorintegrity"],
              },
              hidden: true,
            },
            {
              path: "history",
              component: () => import("@/views/monitor/auditor/integrity/history"),
              name: "integrityAlertHistory",
              meta: {
                id: "integrityAlertHistory",
                active: [
                  "monitor",
                  "auditorAlert",
                  "integrityAlert",
                  "integrityAlertHistory",
                ],
                // permission: ["view monitorauditorintegrity"],
              },
              hidden: true,
            },
          ],
        },
      ],
    },
    {
      path: "interconnector",
      component: () => import("@/views/monitor/interconnector"),
      name: "interconnectorAlert",
      meta: {
        id: "interconnectorAlert",
        active: ["monitor", "interconnectorAlert"],
        icon: "monitor-interconnector.svg",
        permission: ["view interconnector"],
      },
      redirect: "/monitor/interconnector/successful",
      children: [
        {
          path: "successful",
          component: () => import("@/views/monitor/interconnector/successful"),
          name: "successful",
          meta: {
            id: "interconnectorAlertSuccessful",
            active: [
              "monitor",
              "interconnectorAlert",
              "interconnectorAlertSuccessful",
            ],
            permission: ["view successful"],
          },
          hidden: true,
        },
        {
          path: "unsuccessful",
          component: () =>
            import("@/views/monitor/interconnector/unsuccessful"),
          name: "interconnectorAlertUnsuccessful",
          meta: {
            id: "interconnectorAlertUnsuccessful",
            active: [
              "monitor",
              "interconnectorAlert",
              "interconnectorAlertUnsuccessful",
            ],
            permission: ["view unsuccessful"],
          },
          hidden: true,
        },
        {
          path: "reject",
          component: () => import("@/views/monitor/interconnector/reject"),
          name: "interconnectorAlertReject",
          meta: {
            id: "interconnectorAlertReject",
            active: [
              "monitor",
              "interconnectorAlert",
              "interconnectorAlertReject",
            ],
            permission: ["view rejected"],
          },
          hidden: true,
        },
        {
          path: "revoke",
          component: () => import("@/views/monitor/interconnector/revoke"),
          name: "revoke",
          meta: {
            id: "interconnectorAlertRevoke",
            active: [
              "monitor",
              "interconnectorAlert",
              "interconnectorAlertRevoke",
            ],
            permission: ["view revoked"],
          },
          hidden: true,
        },
      ],
    },
    {
      path: "events",
      component: () => import("@/views/monitor/events"),
      name: "events",
      meta: {
        active: ["events"],
        icon: "monitor-events.svg",
        permission: ["view events"],
      },
      redirect: "/monitor/events/threats",
      children: [
        {
          path: "threats",
          component: () => import("@/views/monitor/events/threats"),
          name: "threats",
          meta: {
            active: ["events", "threats"],
            permission: ["view eventthreats"],
          },
          hidden: true,
        },
        {
          path: "system",
          component: () => import("@/views/monitor/events/system"),
          name: "monitorsystem",
          meta: { active: ["events"], permission: ["view eventsystem"] },
          hidden: true,
        },
        {
          path: "syslog",
          component: () => import("@/views/monitor/events/syslog"),
          name: "monitorsyslog",
          meta: {
            active: ["events", "syslog"],
            permission: ["view eventsyslog"],
          },
          hidden: true,
        },
      ],
    },
  ],
};

export default monitorRoutes;
