<template>
  <v-navigation-drawer
    v-model="drawer"
    id="add-form-sidebar"
    absolute
    right
    temporary
    :permanent="permanent"
    class="add-form-sidebar pt-0 px-0"
    :class="['size' + size, sidebarMini ? 'sidebar-mini' : '']"
    :hide-overlay="true"
  >
    <miner
      v-if="keywordAddForm === 'miner'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <firewall
      v-if="keywordAddForm === 'firewall'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <firewall-central-management
      v-if="keywordAddForm === 'firewallCentralManagement'"
      :size="size"
      :id="idAddForm"
      :keywordsResize2="keywordsResize2"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <firewallgroup
      v-if="keywordAddForm === 'firewallgroup'"
      :size="size"
      :id="idAddForm"
      :keywordsResize2="keywordsResize2"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <apicall
      v-if="keywordAddForm === 'apicall'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <apicall-custom
      v-if="keywordAddForm === 'apicallCustom'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <listener
      v-if="keywordAddForm === 'listener'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <integration
      v-if="keywordAddForm === 'integration'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <rule-default
      v-if="keywordAddForm === 'rule-default'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <rule-script
      v-if="keywordAddForm === 'rule-script'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <object-email
      v-if="keywordAddForm === 'objectemail'"
      :size="size"
      :id="idAddForm"
      :keywordsResize2="keywordsResize2"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <object-syslog
      v-if="keywordAddForm === 'objectsyslog'"
      :size="size"
      :id="idAddForm"
      :keywordsResize2="keywordsResize2"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <object-address
      v-if="keywordAddForm === 'objectaddress'"
      :size="size"
      :id="idAddForm"
      :keywordsResize2="keywordsResize2"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <object-profile
      v-if="keywordAddForm === 'objectprofile'"
      :size="size"
      :id="idAddForm"
      :keywordsResize2="keywordsResize2"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <object-server
      v-if="keywordAddForm === 'objectserver'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <system-remote-backup
      v-if="keywordAddForm === 'systemRemoteBackup'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <system-user
      v-if="keywordAddForm === 'systemuser'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <system-task
      v-if="keywordAddForm === 'systemtask'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <system-maintenance
      v-if="keywordAddForm === 'systemmaintenance'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <system-ldap
      v-if="keywordAddForm === 'systemLdap'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <system-roles
      v-if="keywordAddForm === 'systemroles'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <auditor
      v-if="keywordAddForm === 'auditor'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <auditor-cve
      v-if="keywordAddForm === 'auditorcve'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <auditor-integrity
      v-if="keywordAddForm === 'auditorintegrity'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <auditor-group
      v-if="keywordAddForm === 'auditorgroup'"
      :size="size"
      :id="idAddForm"
      :keywordsResize2="keywordsResize2"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <auditor-template
      v-if="keywordAddForm === 'auditorTemplate'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <auditor-template-rule
      v-if="keywordAddForm === 'auditorTemplateRule'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <security-policy
      v-if="keywordAddForm === 'securityPolicy'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <monitor-cve-alert
      v-if="keywordAddForm === 'cvealert'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <monitor-ruleset-alert
      v-if="keywordAddForm === 'rulesetalert'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <monitor-successful
      v-if="
        ['intSuccessful', 'intUnsuccessful', 'intReject', 'intRevoke'].includes(
          keywordAddForm
        )
      "
      :type="keywordAddForm"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <system-version
      v-if="keywordAddForm === 'systemVersion'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <system-license
      v-if="keywordAddForm === 'systemLicense'"
      :size="size"
      :id="idAddForm"
      :keywordsResize2="keywordsResize2"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <system-ssl
      v-if="keywordAddForm === 'systemSsl'"
      :size="size"
      :id="idAddForm"
      :keywordsResize2="keywordsResize2"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <system-synapsa
      v-if="keywordAddForm === 'systemSynapsa'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <system-dns
      v-if="keywordAddForm === 'systemDns'"
      :size="size"
      :id="idAddForm"
      :keywordsResize2="keywordsResize2"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <awaiting-approval
      v-if="keywordAddForm === 'awaitingApprovalSecPolicy'"
      :size="size"
      :keyword="keywordAddForm"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />

    <widget-system-logs
      v-if="keywordAddForm === 'widgetSystemLogs'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <datafeed-address-customlist
      v-if="keywordAddForm === 'customaddresslist'"
      :size="size"
      :id="idAddForm"
      :keywordsResize2="keywordsResize2"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <datafeed-address-connection
      v-if="keywordAddForm === 'connectionaddresslist'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <datafeed-domain-customlist
      v-if="keywordAddForm === 'customdomainlist'"
      :size="size"
      :id="idAddForm"
      :keywordsResize2="keywordsResize2"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <datafeed-domain-customlist-show
      v-if="keywordAddForm === 'customdomainlistshow'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <datafeed-url-customlist
      v-if="keywordAddForm === 'customurllist'"
      :size="size"
      :id="idAddForm"
      :keywordsResize2="keywordsResize2"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <datafeed-url-customlist-show
      v-if="keywordAddForm === 'customurllistshow'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <datafeed-address-custom-source
      v-if="keywordAddForm === 'customSourceAddress'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <datafeed-address-custom-destination
      v-if="keywordAddForm === 'customDestinationAddress'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
    <datafeed-url-links
      v-if="keywordAddForm === 'feedUrl'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
    />
    <user
      v-if="keywordAddForm === 'user'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
    />
    <parser
      v-if="keywordAddForm === 'parser'"
      :size="size"
      :id="idAddForm"
      @resize="resizeSidebar($event)"
      @close="closeSidebar"
      @change="reloadData($event)"
    />
  </v-navigation-drawer>
</template>

<script>
import miner from "./miner";
import firewall from "./firewall";
import firewallCentralManagement from "./firewallCentralManagement";
import parser from "./parser";
import firewallgroup from "./firewallgroup";
import ruleDefault from "./ruleDefault";
import ruleScript from "./ruleScript";
import objectEmail from "./objectemail";
import objectSyslog from "./objectsyslog";
import objectAddress from "./objectaddress";
import objectProfile from "./objectprofile";
import objectServer from "./objectserver";
import systemRemoteBackup from "./system/remoteBackup";
import systemUser from "./systemuser";
import systemTask from "./task";
import systemMaintenance from "./maintenance";
import systemLdap from "./ldap";
import systemRoles from "./systemroles";
import user from "./user";
import auditor from "./auditor";
import auditorCve from "./auditorcve";
import auditorIntegrity from "./auditorintegrity";
import auditorGroup from "./auditorgroup";
import auditorTemplate from "./auditortemplate";
import auditorTemplateRule from "./auditortemplaterule";
import securityPolicy from "./securitypolicy";
import apicall from "./apicall";
import apicallCustom from "./settings/apicall/custom";
import listener from "./listener";
import integration from "./integration";
import monitorCveAlert from "./monitor/auditor/cve";
import monitorRulesetAlert from "./monitor/auditor/ruleset";
import monitorSuccessful from "./monitor/interconnector/successful";
import systemVersion from "./system/version";
import systemLicense from "./system/license";
import systemSsl from "./system/ssl";
import systemSynapsa from "./system/synapsa";
import systemDns from "./system/dns";
import awaitingApproval from "./awaitingapproval";
//widgets
import widgetSystemLogs from "./widgets/systemLogs";
// DataFeed
import datafeedAddressCustomlist from "./dataFeed/addressList";
import datafeedAddressConnection from "./dataFeed/connection";
import datafeedAddressCustomSource from "./dataFeed/addressList/source.vue";
import datafeedAddressCustomDestination from "./dataFeed/addressList/destination.vue";
import datafeedDomainCustomlist from "./dataFeed/domainList";
import datafeedDomainCustomlistShow from "./dataFeed/domainList/show";
import datafeedUrlCustomlist from "./dataFeed/urlList";
import datafeedUrlCustomlistShow from "./dataFeed/urlList/show";
import datafeedUrlLinks from "./dataFeed/feedUrl";

export default {
  components: {
    miner,
    firewall,
    firewallCentralManagement,
    parser,
    firewallgroup,
    ruleDefault,
    ruleScript,
    objectEmail,
    objectSyslog,
    objectAddress,
    objectProfile,
    objectServer,
    systemRemoteBackup,
    systemUser,
    systemTask,
    systemMaintenance,
    systemLdap,
    systemRoles,
    auditor,
    auditorCve,
    auditorIntegrity,
    auditorGroup,
    auditorTemplate,
    auditorTemplateRule,
    securityPolicy,
    apicall,
    apicallCustom,
    listener,
    integration,
    monitorCveAlert,
    monitorRulesetAlert,
    monitorSuccessful,
    systemVersion,
    systemLicense,
    systemSsl,
    systemSynapsa,
    systemDns,
    awaitingApproval,
    //widgets
    widgetSystemLogs,
    datafeedAddressCustomlist,
    datafeedAddressConnection,
    datafeedAddressCustomSource,
    datafeedAddressCustomDestination,
    datafeedDomainCustomlist,
    datafeedDomainCustomlistShow,
    datafeedUrlCustomlist,
    datafeedUrlCustomlistShow,
    datafeedUrlLinks,
    user,
  },
  props: {
    sidebarMini: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      drawer: false,
      permanent: false,
      keyword: "",
      keywordsWithoutResize: [
        "cvealert",
        "rulesetalert",
        "intSuccessful",
        "intUnsuccessful",
        "intReject",
        "intRevoke",
        "securityPolicy",
        "widgetSystemLogs",
        "systemVersion",
        "awaitingApprovalSecPolicy",
      ],
      keywordsResize3: [
        "auditor",
        "auditorintegrity",
        "apicall",
        "apicallCustom",
        "systemLdap",
        "systemtask",
        "auditorcve",
        "systemuser",
        "user",
        "systemSynapsa",
        "firewall",
        "rule-default",
        "rule-script",
        "connectionaddresslist",
        "objectserver",
        "miner",
        "parser",
        "systemRemoteBackup",
        "systemroles",
      ],
      keywordsResize2: [
        "objectemail",
        "auditorgroup",
        "customaddresslist",
        "customdomainlist",
        "customurllist",
        "objectsyslog",
        "objectprofile",
        "objectaddress",
        "firewallCentralManagement",
        "firewallgroup",
        "systemLicense",
        "systemSsl",
        "systemDns",
      ],

      size: 2,
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
    previousUrl: {
      get() {
        return this.$store.state.settings.previousUrl;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "previousUrl",
          value: val,
        });
      },
    },
  },
  watch: {
    keywordAddForm: {
      deep: true,
      handler(newValue) {
        if (newValue !== null && newValue !== "") {
          this.drawer = true;
          this.permanent = true;
          this.keyword = newValue;
          if (this.keywordsWithoutResize.includes(this.keywordAddForm)) {
            this.size = 4;
          } else if (this.keywordsResize3.includes(this.keywordAddForm)) {
            this.size = 3;
          } else if (this.keywordsResize2.includes(this.keywordAddForm)) {
            this.size = 2;
          }
        } else {
          this.drawer = false;
          this.permanent = false;
          this.size = 2;
        }
      },
    },
  },
  methods: {
    closeSidebar() {
      this.drawer = false;
      this.permanent = false;
      this.keyword = null;

      this.keywordAddForm = "";
      this.idAddForm = 0;
      this.size = 2;
    },
    resizeSidebar(size) {
      if (
        this.keywordsWithoutResize.includes(this.keywordAddForm) ||
        this.keywordsResize3.includes(this.keywordAddForm)
      ) {
        if (size === "bigger") {
          this.size = 4;
        } else {
          this.size = 1;
        }
      } else {
        if (size === "bigger") {
          this.size += 1;
        } else {
          this.size -= 1;
        }
      }
      if (this.keywordsResize3.includes(this.keywordAddForm)) {
        if (size === "bigger") {
          this.size = 3;
        } else {
          this.size = 1;
        }
      }
      if (this.keywordsResize2.includes(this.keywordAddForm)) {
        if (size === "bigger") {
          this.size = 2;
        } else {
          this.size = 1;
        }
      }
    },
    reloadData(keyword) {
      this.changeInAddForm = keyword;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-form-sidebar {
  height: 80% !important;
  border-radius: 15px 0 0 0;
  overflow-y: hidden;
  position: fixed !important;
  top: 25% !important;
  z-index: 10 !important;
  padding-bottom: 28px;
  ::-webkit-scrollbar {
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 100vw;
    margin-block-start: 36px;
  }

  ::-webkit-scrollbar-thumb {
    background: #9fa3a6;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #808485;
  }
}
.add-form-sidebar.size1 {
  width: 60px !important;
}
.add-form-sidebar.size2 {
  width: 30% !important;
}
.add-form-sidebar.size3 {
  width: 60% !important;
}
.add-form-sidebar.size4 {
  width: calc(100% - 220px) !important;
}
.add-form-sidebar.sidebar-mini.size4 {
  width: calc(100% - 76px) !important;
}

.add-form-sidebar {
  top: 25% !important;
}
@supports (-moz-appearance: none) {
  .add-form-sidebar {
    top: 25% !important;
  }
}

// @media only screen and (min-width: 992px) {
// }
</style>

<style lang="scss">
.add-form-sidebar {
  .topbar-block {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 10;
  }
  .v-navigation-drawer__border {
    display: none;
  }
  .form-block {
    padding-top: 35px;
    padding-bottom: 40px;
    .v-select.v-select--chips.v-input--dense .v-select__selections {
      min-height: 38px;
    }
    .v-input__slot {
      border-radius: 15px !important;
      label,
      input,
      textarea,
      .v-select__selection {
        font-size: 15px;
        font-family: RobotoCondensedLight, sans-serif !important;
      }
      input {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
    }
    .v-btn {
      border-radius: 15px !important;
    }
    .show-link-textarea,
    .download-link-textarea,
    .show-jsonlink-textarea {
      border-radius: 15px;
      text-align: left;
      width: 100%;
      resize: none;
      cursor: pointer;
      padding: 10px;
    }
  }
  @media only screen and (min-width: 992px) {
    .form-block {
      // padding-bottom: 200px;
    }
  }
}
.add-form-sidebar.size1 {
  .topbar-block {
    right: 11px;
  }
}
#add-form-sidebar.theme--light {
  .form-block {
    .v-input__slot {
      background: #f4f6f2;
      box-shadow: inset 0 0 6px 2px rgb(181 181 181 / 51%),
        inset -4px -4px 8px -5px rgb(181 181 181 / 51%);
    }
    .v-input--checkbox {
      margin: 0;
      padding: 0;
      .v-input__slot {
        background: transparent !important;
        box-shadow: none !important;
        label {
          font-size: 12px;
        }
      }
    }
    .v-input--radio-group {
      .v-input__slot {
        background: transparent !important;
        box-shadow: none !important;
      }
    }
    .show-link-textarea,
    .download-link-textarea,
    .show-jsonlink-textarea {
      background-color: #f5f8f1 !important;
      box-shadow: inset 0 0 6px 2px rgb(181 181 181 / 51%),
        inset -4px -4px 8px -5px rgb(181 181 181 / 51%) !important;
    }
  }
  .topbar-block {
    background-color: #f5f8f1;
  }
}
#add-form-sidebar.theme--dark {
  .form-block {
    background-color: #363636;
    .v-input__slot {
      background: #5a5a5d;
      box-shadow: inset 0 0 6px 2px rgb(53 53 53 / 56%),
        inset -4px -4px 8px -5px rgb(48 48 48 / 65%);
    }
    .v-input--checkbox {
      margin: 0;
      padding: 0;
      .v-input__slot {
        background: transparent !important;
        box-shadow: none !important;
        label {
          font-size: 12px;
        }
      }
    }
    .v-input--radio-group {
      .v-input__slot {
        background: transparent !important;
        box-shadow: none !important;
      }
    }
    .show-link-textarea,
    .download-link-textarea,
    .show-jsonlink-textarea {
      background-color: #5a5a5d !important;
      color: #fff;
      box-shadow: inset 0 0 6px 2px rgb(53 53 53 / 56%),
        inset -4px -4px 8px -5px rgb(48 48 48 / 65%) !important;
    }
  }
  .topbar-block {
    background-color: #000000;
  }
}
@supports (-moz-appearance: none) {
  .add-form-sidebar {
    .v-navigation-drawer__content {
      margin-top: 19px;
    }
    .form-block {
      padding-top: 16px;
    }
  }
}
</style>
