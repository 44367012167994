<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new User" : "Edit User" }}
            </h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--USERNAME-->
              <v-row class="mx-0 mt-3">
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >User Name</label
                  >
                  <v-text-field
                    v-model="username"
                    :rules="usernameRules"
                    label="User Name"
                    placeholder="User Name"
                    dense
                    solo
                  />
                </v-col>
                <!--END USERNAME-->
                <!--FIRSTNAME-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >First Name</label
                  >
                  <v-text-field
                    v-model="firstname"
                    :rules="firstnameRules"
                    label="First Name"
                    placeholder="First Name"
                    dense
                    solo
                  />
                </v-col>
                <!--END FIRSTNAME-->
                <!--LASTNAME-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Last Name</label
                  >
                  <v-text-field
                    v-model="lastname"
                    :rules="lastnameRules"
                    label="Last Name"
                    placeholder="Last Name"
                    dense
                    solo
                  />
                </v-col>
                <!--END LASTNAME-->
                <!--EMAIL-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Email</label
                  >
                  <v-text-field
                    v-model="email"
                    :rules="[...emailRules, emailValidate]"
                    label="Email"
                    placeholder="Email"
                    dense
                    solo
                  />
                </v-col>
                <!--END EMAIL-->
                <!--PASSWORD-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Password</label
                  >
                  <v-text-field
                    v-model="password"
                    :rules="[passwordRule]"
                    type="password"
                    label="Password"
                    placeholder="Password"
                    dense
                    solo
                  />
                </v-col>
                <!--END PASSWORD-->
                <!--PASSWORD CONFIRM-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Confirm Password</label
                  >
                  <v-text-field
                    v-model="password_confirmation"
                    :rules="[confirmPasswordRule]"
                    type="password"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    dense
                    solo
                  />
                </v-col>
                <!--END PASSWORD CONFIRM-->

                <v-col cols="6" class="py-0 d-flex flex-column justify-center">
                  <div class="toggle mb-2 mt-0">
                    <v-switch
                      v-model="twoFAStatus"
                      :disabled="email === ''"
                      :value="false"
                      hide-details
                      label="Enable 2FA"
                      inset
                      @click="changeMade = true"
                      :class="[twoFAStatus ? 'v-switch-on' : 'v-switch-off']"
                      class="target-background v-switch-off"
                    />
                  </div>
                </v-col>
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >2FA Type</label
                  >
                  <!--2FA Types-->
                  <v-select
                    v-model="twoFAType"
                    :disabled="!twoFAStatus"
                    :items="twoFATypes"
                    item-text="name"
                    item-value="value"
                    label="2FA"
                    placeholder="2FA"
                    dense
                    solo
                    @click="changeMade = true"
                  />
                  <!--END 2FA Types-->
                </v-col>
                <!--ROLE-->
                <v-col cols="6" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Role</label
                  >
                  <v-select
                    v-model="role"
                    :items="roles"
                    :rules="roleRules"
                    item-text="name"
                    item-value="id"
                    label="Role"
                    placeholder="Role"
                    dense
                    solo
                  />
                </v-col>
                <!--END ROLE-->
              </v-row>

              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import { getUser, storeUser, updateUser, getRoleList } from "@/api/system/user";
import { Validation } from "@/utils/validation";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,

      valid: true,
      validCheck: true,

      saving: false,

      username: "",
      usernameRules: [(v) => !!v || "User Name is required"],
      firstname: "",
      firstnameRules: [(v) => !!v || "First Name is required"],
      lastname: "",
      lastnameRules: [(v) => !!v || "Last Name is required"],
      email: "",
      emailRules: [(v) => !!v || "Email is required"],
      password: "",
      password_confirmation: "",
      password_confirmationRules: [(v) => !!v || "Password is required"],
      role: "",
      roleRules: [(v) => !!v || "Role is required"],
      roles: [],
      twoFAStatus: false,
      twoFAType: 1,
      twoFATypes: [{ value: 1, name: "E-mail" }],
    };
  },
  computed: {
    /* triggers when user edits first password field
       otherwise passes so you can update user without changing pw */
    confirmPasswordRule() {
      if (!this.password) {
        return true;
      } else {
        if (this.password === this.password_confirmation) {
          return true;
        } else {
          return "Passwords must match!";
        }
      }
    },
    /* different rule for upadte and create mode */
    passwordRule() {
      if (this.id !== 0) {
        if (this.password && this.password.length < 8) {
          return "Password must have at least 8 characters";
        } else {
          return true;
        }
      } else if (!this.password) {
        return "Password is required";
      } else if (this.password.length < 8) {
        return "Password must have at least 8 characters";
      } else {
        return true;
      }
    },
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.username = "";
          this.firstname = "";
          this.lastname = "";
          this.email = "";
          this.password = "";
          this.password_confirmation = "";
          this.role = "";
          this.twoFAStatus = false;
          this.twoFAType = 1;
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  mounted() {
    this.loadRoles();
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "user");
    },
    loadById() {
      getUser(this.id)
        .then((response) => {
          const user = response.data;
          this.username = user.username;
          this.firstname = user.firstname;
          this.lastname = user.lastname;
          this.email = user.email;
          this.password = user.password;
          this.role = user.role.id;
          this.checkValid = true;
          this.loading = false;
          this.twoFAStatus = user.twoFAStatus;
          this.twoFAType = user.twoFAType ? user.twoFAType : 1;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    validateCheck() {
      this.$refs.formCheck.validate();
      if (this.$refs.formCheck.validate()) {
        this.check();
      }
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    emailValidate(val) {
      if (val) {
        return Validation.email(val);
      } else {
        return true;
      }
    },
    store() {
      this.saving = true;
      const data = {
        username: this.username,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
        role: this.role,
        twoFAStatus: this.twoFAStatus,
        twoFAType: this.twoFAType ? this.twoFAType : 1,
      };
      storeUser(data)
        .then(() => {
          this.$toast.open({
            message: "User was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.changeInAddForm = "user";
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    update() {
      this.saving = true;
      const data = {
        id: this.id,
        username: this.username,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
        role: this.role,
        twoFAStatus: this.twoFAStatus,
        twoFAType: this.twoFAType ? this.twoFAType : 1,
        onlyPassword: false,
      };
      updateUser(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "User was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.changeInAddForm = "user";
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    loadRoles() {
      getRoleList()
        .then((response) => {
          this.roles = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
  },
};
</script>

<style lang="scss"></style>
