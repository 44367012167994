<template>
  <v-container
    fluid
    class="synapsa-settings-block"
    :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
  >
    <v-row>
      <v-col cols="12" class="pt-0 pb-2 px-0">
        <v-list-item-title class="font-roboto-condensed-regular">
          Table settings
          <v-btn
            text
            color="bad-3"
            height="25"
            class="text-capitalize"
            @click="setDefault"
          >
            set default
          </v-btn>
        </v-list-item-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" md="3" xl="2" class="pt-0 pl-0">
        <v-list-item-title
          class="font-roboto-condensed-regular font-size-15 mb-1"
          >Headers</v-list-item-title
        >
        <draggable :list="headers" @end="update" group="columns" class="h-100">
          <v-row
            v-for="column in headers"
            :key="column.value"
            class="my-0 mx-0 mb-2 cursor-pointer font-ubuntu-bold drag-column-block"
            :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
          >
            <v-col cols="9" class="py-2 font-size-12">
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <span v-if="column.visible">{{ column.text }}</span>
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <span v-if="!column.visible" class="primary--text">{{
                  column.text
                }}</span>
              </transition>
            </v-col>
            <v-col cols="3" class="pt-1 pb-0 pr-2 font-size-15 text-right">
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-avatar
                  v-if="column.visible"
                  size="22"
                  class="br-0"
                  @click="[(column.visible = !column.visible), update()]"
                >
                  <img
                    :src="
                      $vuetify.theme.dark
                        ? location + '/images/icons/white/minus-white.svg'
                        : location + '/images/icons/dark/minus.svg'
                    "
                    alt="minus"
                  />
                </v-avatar>
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-avatar
                  v-if="!column.visible"
                  size="22"
                  class="br-0"
                  @click="[(column.visible = !column.visible), update()]"
                >
                  <img
                    :src="location + '/images/icons/green/plus-green.svg'"
                    alt="plus"
                  />
                </v-avatar>
              </transition>
            </v-col>
          </v-row>
        </draggable>
      </v-col>
      <v-col cols="4" md="3" xl="2" class="pt-0 px-0">
        <v-list-item-title
          class="font-roboto-condensed-regular font-size-15 mb-1"
          >Expanded area</v-list-item-title
        >
        <draggable :list="expanded" @end="update" group="columns" class="h-100">
          <v-row
            v-for="column in expanded"
            :key="column.value"
            class="my-0 mx-0 mb-2 cursor-pointer font-ubuntu-bold drag-column-block"
            :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
          >
            <v-col cols="9" class="py-2 font-size-12">
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <span v-if="column.visible">{{ column.text }}</span>
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <span v-if="!column.visible" class="primary--text">{{
                  column.text
                }}</span>
              </transition>
            </v-col>
            <v-col cols="3" class="pt-1 pb-0 pr-2 font-size-15 text-right">
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-avatar
                  v-if="column.visible"
                  size="20"
                  class="br-0"
                  @click="[(column.visible = !column.visible), update()]"
                >
                  <img
                    :src="
                      $vuetify.theme.dark
                        ? location + '/images/icons/white/minus-white.svg'
                        : location + '/images/icons/dark/minus.svg'
                    "
                    alt="minus"
                  />
                </v-avatar>
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <v-avatar
                  v-if="!column.visible"
                  size="20"
                  class="br-0"
                  @click="[(column.visible = !column.visible), update()]"
                >
                  <img
                    :src="location + '/images/icons/green/plus-green.svg'"
                    alt="plus"
                  />
                </v-avatar>
              </transition>
            </v-col>
          </v-row>
        </draggable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { Errors } from "@/utils/error";
import { setUserTableSettings } from "@/api/system/user";
import { debounce } from "@/utils";
import { Tables } from "@/utils/tables";

export default {
  components: {
    draggable,
  },
  props: {
    process: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      headers: null,
      expanded: null,
      location: "",
    };
  },
  computed: {
    settingsMinerTable: {
      get() {
        return this.$store.state.tables.settingsMinerTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "settingsMinerTable",
          value: val,
        });
      },
    },
    settingsFirewallTable: {
      get() {
        return this.$store.state.tables.settingsFirewallTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "settingsFirewallTable",
          value: val,
        });
      },
    },
    settingsCentralManagementTable: {
      get() {
        return this.$store.state.tables.settingsCentralManagementTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "settingsCentralManagementTable",
          value: val,
        });
      },
    },
    settingsFirewallGroupsTable: {
      get() {
        return this.$store.state.tables.settingsFirewallGroupsTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "settingsFirewallGroupsTable",
          value: val,
        });
      },
    },
    settingsParserTable: {
      get() {
        return this.$store.state.tables.settingsParserTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "settingsParserTable",
          value: val,
        });
      },
    },
    settingsListenerTable: {
      get() {
        return this.$store.state.tables.settingsListenerTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "settingsListenerTable",
          value: val,
        });
      },
    },
    settingsIntegrationTable: {
      get() {
        return this.$store.state.tables.settingsIntegrationTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "settingsIntegrationTable",
          value: val,
        });
      },
    },
    settingsApiPaloAltoTable: {
      get() {
        return this.$store.state.tables.settingsApiPaloAltoTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "settingsApiPaloAltoTable",
          value: val,
        });
      },
    },
    settingsApiFortinetTable: {
      get() {
        return this.$store.state.tables.settingsApiFortinetTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "settingsApiFortinetTable",
          value: val,
        });
      },
    },
    settingsApiCheckpointTable: {
      get() {
        return this.$store.state.tables.settingsApiCheckpointTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "settingsApiCheckpointTable",
          value: val,
        });
      },
    },
    settingsApiSophosTable: {
      get() {
        return this.$store.state.tables.settingsApiSophosTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "settingsApiSophosTable",
          value: val,
        });
      },
    },
    settingsApiCustomTable: {
      get() {
        return this.$store.state.tables.settingsApiCustomTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "settingsApiCustomTable",
          value: val,
        });
      },
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loadData(newValue);
      },
    },
    process: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.loadData(this.$route);
        }
      },
    },
  },
  created() {
    this.location = window.location.origin;
  },
  methods: {
    loadData(route) {
      if (route.name === "miners") {
        this.headers = this.settingsMinerTable.headers;
        this.expanded = this.settingsMinerTable.expanded;
      } else if (route.name === "firewalls") {
        this.headers = this.settingsFirewallTable.headers;
        this.expanded = this.settingsFirewallTable.expanded;
      } else if (route.name === "centralManagement") {
        this.headers = this.settingsCentralManagementTable.headers;
        this.expanded = this.settingsCentralManagementTable.expanded;
      } else if (route.name === "firewallGroups") {
        this.headers = this.settingsFirewallGroupsTable.headers;
        this.expanded = this.settingsFirewallGroupsTable.expanded;
      } else if (route.name === "parser") {
        this.headers = this.settingsParserTable.headers;
        this.expanded = this.settingsParserTable.expanded;
      } else if (route.name === "listener") {
        this.headers = this.settingsListenerTable.headers;
        this.expanded = this.settingsListenerTable.expanded;
      } else if (route.name === "integration") {
        this.headers = this.settingsIntegrationTable.headers;
        this.expanded = this.settingsIntegrationTable.expanded;
      } else if (route.name === "apiPaloAlto") {
        this.headers = this.settingsApiPaloAltoTable.headers;
        this.expanded = this.settingsApiPaloAltoTable.expanded;
      } else if (route.name === "apiFortinet") {
        this.headers = this.settingsApiFortinetTable.headers;
        this.expanded = this.settingsApiFortinetTable.expanded;
      } else if (route.name === "apiCheckpoint") {
        this.headers = this.settingsApiCheckpointTable.headers;
        this.expanded = this.settingsApiCheckpointTable.expanded;
      } else if (route.name === "apiSophos") {
        this.headers = this.settingsApiSophosTable.headers;
        this.expanded = this.settingsApiSophosTable.expanded;
      } else if (route.name === "apiCustom") {
        this.headers = this.settingsApiCustomTable.headers;
        this.expanded = this.settingsApiCustomTable.expanded;
      }
    },
    update: debounce(function () {
      let data = null;
      if (this.$route.name === "miners") {
        data = {
          table_name: "settingsMinerTable",
          table_data: JSON.stringify(this.settingsMinerTable),
        };
      } else if (this.$route.name === "firewallGroups") {
        data = {
          table_name: "settingsFirewallGroupsTable",
          table_data: JSON.stringify(this.settingsFirewallGroupsTable),
        };
      } else if (this.$route.name === "firewalls") {
        data = {
          table_name: "settingsFirewallTable",
          table_data: JSON.stringify(this.settingsFirewallTable),
        };
      } else if (this.$route.name === "centralManagement") {
        data = {
          table_name: "settingsCentralManagementTable",
          table_data: JSON.stringify(this.settingsCentralManagementTable),
        };
      } else if (this.$route.name === "parser") {
        data = {
          table_name: "settingsParserTable",
          table_data: JSON.stringify(this.settingsParserTable),
        };
      } else if (this.$route.name === "listener") {
        data = {
          table_name: "settingsListenerTable",
          table_data: JSON.stringify(this.settingsListenerTable),
        };
      } else if (this.$route.name === "integration") {
        data = {
          table_name: "settingsIntegrationTable",
          table_data: JSON.stringify(this.settingsIntegrationTable),
        };
      } else if (this.$route.name === "apiPaloAlto") {
        data = {
          table_name: "settingsApiPaloAltoTable",
          table_data: JSON.stringify(this.settingsApiPaloAltoTable),
        };
      } else if (this.$route.name === "apiFortinet") {
        data = {
          table_name: "settingsApiFortinetTable",
          table_data: JSON.stringify(this.settingsApiFortinetTable),
        };
      } else if (this.$route.name === "apiCheckpoint") {
        data = {
          table_name: "settingsApiCheckpointTable",
          table_data: JSON.stringify(this.settingsApiCheckpointTable),
        };
      } else if (this.$route.name === "apiSophos") {
        data = {
          table_name: "settingsApiSophosTable",
          table_data: JSON.stringify(this.settingsApiSophosTable),
        };
      } else if (this.$route.name === "apiCustom") {
        data = {
          table_name: "settingsApiCustomTable",
          table_data: JSON.stringify(this.settingsApiCustomTable),
        };
      }
      setUserTableSettings(data)
        .then(() => {
          if (this.$route.name === "miners") {
            Tables.setSetting(this.$route.name, this.settingsMinerTable);
          } else if (this.$route.name === "firewallGroups") {
            Tables.setSetting(
              this.$route.name,
              this.settingsFirewallGroupsTable
            );
          } else if (this.$route.name === "firewalls") {
            Tables.setSetting(this.$route.name, this.settingsFirewallTable);
          } else if (this.$route.name === "parser") {
            Tables.setSetting(this.$route.name, this.settingsParserTable);
          } else if (this.$route.name === "centralManagement") {
            Tables.setSetting(this.$route.name, this.settingsCentralManagementTable);
          } else if (this.$route.name === "listener") {
            Tables.setSetting(this.$route.name, this.settingsListenerTable);
          } else if (this.$route.name === "integration") {
            Tables.setSetting(this.$route.name, this.settingsIntegrationTable);
          } else if (this.$route.name === "apiPaloAlto") {
            Tables.setSetting(this.$route.name, this.settingsApiPaloAltoTable);
          } else if (this.$route.name === "apiFortinet") {
            Tables.setSetting(this.$route.name, this.settingsApiFortinetTable);
          } else if (this.$route.name === "apiCheckpoint") {
            Tables.setSetting(
              this.$route.name,
              this.settingsApiCheckpointTable
            );
          } else if (this.$route.name === "apiSophos") {
            Tables.setSetting(this.$route.name, this.settingsApiSophosTable);
          } else if (this.$route.name === "apiCustom") {
            Tables.setSetting(this.$route.name, this.settingsApiCustomTable);
          }
          this.$toast.open({
            message: "Table was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
        })
        .catch((e) => {
          Errors.show(e);
        });
    }, 500),
    setDefault() {
      if (this.$route.name === "miners") {
        this.$emit("default", "settingsMinerTable");
      } else if (this.$route.name === "firewallGroups") {
        this.$emit("default", "settingsFirewallGroupsTable");
      } else if (this.$route.name === "firewalls") {
        this.$emit("default", "settingsFirewallTable");
      } else if (this.$route.name === "centralManagement") {
        this.$emit("default", "settingsCentralManagementTable");
      } else if (this.$route.name === "parser") {
        this.$emit("default", "settingsParserTable");
      } else if (this.$route.name === "integration") {
        this.$emit("default", "settingsIntegrationTable");
      } else if (this.$route.name === "apiPaloAlto") {
        this.$emit("default", "settingsApiPaloAltoTable");
      } else if (this.$route.name === "apiFortinet") {
        this.$emit("default", "settingsApiFortinetTable");
      } else if (this.$route.name === "apiCheckpoint") {
        this.$emit("default", "settingsApiCheckpointTable");
      } else if (this.$route.name === "apiSophos") {
        this.$emit("default", "settingsApiSophosTable");
      } else if (this.$route.name === "apiCustom") {
        this.$emit("default", "settingsApiCustomTable");
      }
    },
  },
};
</script>
