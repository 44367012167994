<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pb-2 pt-3">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new auditor cve" : "Edit auditor cve" }}
            </h3>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--NAME-->
              <v-row class="mx-0 mt-0">
                <v-col cols="6" class="py-0 px-0 pr-2">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Name</label
                  >
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    placeholder="Name"
                    dense
                    solo
                  />
                </v-col>
                <v-col cols="6" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Vendor</label
                  >
                  <v-select
                    v-model="vendor"
                    :items="vendors"
                    :rules="vendorRules"
                    disabled
                    item-text="name"
                    item-value="id"
                    label="Vendor"
                    placeholder="Vendor"
                    dense
                    solo
                  />
                </v-col>
                <v-col cols="6" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Firewall type</label
                  >
                  <v-radio-group v-model="fwType" row class="mt-2">
                    <v-radio
                      label="Firewall"
                      value="firewall"
                      selected
                      @click="fwType = 'firewall'"
                    />
                    <v-radio
                      label="Firewall group"
                      value="group"
                      @click="fwType = 'group'"
                    />
                    <v-radio
                      label="Central management"
                      value="centralManagement"
                      @click="fwType = 'centralManagement'"
                    />
                  </v-radio-group>
                </v-col>
                <!--FIREWALLS-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                  v-if="fwType === 'firewall'"
                >
                  <v-col cols="6" class="py-0 px-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Firewall</label
                    >
                    <v-autocomplete
                      v-model="firewall"
                      :items="firewalls"
                      :rules="firewallRules"
                      item-text="name"
                      item-value="id"
                      label="Firewall"
                      placeholder="Firewall"
                      dense
                      solo
                      multiple
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          color="primary"
                          class="elevation-2 synapsa-chip"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-chip
                          small
                          color="primary"
                          class="elevation-2 synapsa-chip"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </transition>
                <!--END FIREWALLS-->

                <!--FIREWALL GROUPS-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                  v-if="fwType === 'group'"
                >
                  <v-col cols="6" class="py-0 px-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Firewall Group</label
                    >
                    <v-autocomplete
                      v-model="firewallGroup"
                      :items="firewallGroups"
                      :rules="firewallGroupsRules"
                      item-text="name"
                      item-value="id"
                      placeholder="Firewall group"
                      dense
                      solo
                      multiple
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          color="primary"
                          class="elevation-2 synapsa-chip"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-chip
                          small
                          color="primary"
                          class="elevation-2 synapsa-chip"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </transition>
                <!--FIREWALL GROUPS-->



                <!--centralManagement-->
                <transition
                  enter-active-class="animate__animated animate__fadeIn"
                  leave-active-class="d-none"
                  v-if="fwType === 'centralManagement'"
                >

                  <v-col cols="6" class="py-0 px-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                    >Central Management</label
                    >
                    <v-autocomplete
                      v-model="centralManagement"
                      :items="centralManagements"
                      :rules="centralManagementRules"
                      item-text="name"
                      item-value="id"
                      label="Central Management"
                      placeholder="Central Management"
                      dense
                      solo
                      multiple
                    >
                      <template v-slot:selection="data">
                        <v-chip
                          small
                          color="primary"
                          class="elevation-2 synapsa-chip"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                      <template v-slot:item="data">
                        <v-chip
                          small
                          color="primary"
                          class="elevation-2 synapsa-chip"
                        >
                          {{ data.item.name }}
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </transition>
                <!--END centralManagement-->
              </v-row>
              <!--END NAME-->

              <!--DESCRIPTION-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Description</label
                  >
                  <v-textarea
                    v-model="description"
                    placeholder="Description"
                    auto-grow
                    rows="4"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END DESCRIPTION-->

              <!--STATE-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="pt-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >State</label
                  >
                  <div class="toggle mb-2">
                    <v-switch
                      v-model="state"
                      :value="true"
                      hide-details
                      label="Active"
                      inset
                      :class="[state ? 'v-switch-on' : 'v-switch-off']"
                      class="target-background v-switch-off"
                    />
                  </div>
                </v-col>
              </v-row>
              <!--END STATE-->
            </v-form>

            <v-row class="mt-1 mx-0">
              <v-col cols="12" class="py-0 px-0">
                <v-icon v-if="saving" color="primary">
                  fas fa-spinner fa-spin
                </v-icon>
                <span
                  v-if="saving"
                  class="font-roboto-condensed-light font-size-15 ml-2"
                >
                  Processing...
                </span>
                <v-btn
                  v-if="!saving"
                  color="primary"
                  class="text-capitalize"
                  @click="validate"
                >
                  {{ id === 0 ? "Create" : "Update" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { getFirewallListByVendor } from "@/api/settings/firewall";
import { Errors } from "@/utils/error";
import { storeCveAuditor, updateCveAuditor } from "@/api/auditor/cve";
import { getAuditor } from "@/api/auditor/ruleset";
import { getFirewallGroupListByVendor } from "@/api/settings/firewallGroup";
import { getCentralManagementListByVendor } from "@/api/settings/centralManagement";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      valid: true,
      saving: false,
      loading: true,

      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      description: "",
      vendor: 1,
      vendorRules: [(v) => !!v || "Vendor is required"],
      vendors: [{ id: 1, name: "Palo Alto" }],
      fwType: "firewall",
      firewall: [],
      firewallGroup: [],
      firewallRules: [(v) => (!!v && !!v.length) || "Firewalls are required"],
      firewallGroupsRules: [
        (v) => (!!v && !!v.length) || "Firewall groups are required",
      ],
      firewalls: [],
      firewallGroups: [],

      centralManagement: [],
      centralManagements: [],
      centralManagementRules: [(v) => (!!v && !!v.length) || "Central Management are required"],

      state: true,
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (newValue === 0) {
          this.name = "";
          this.description = "";
          this.vendor = 1;
          this.firewall = [];
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  created() {
    this.loadFirewalls();
    this.loadFirewallGroups();
    this.loadCentralManagement();
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "auditor");
    },
    loadById() {
      getAuditor(this.id)
        .then((response) => {
          this.loading = false;
          const auditor = response.data;
          this.name = auditor.name;
          this.description = auditor.description;
          this.state = auditor.active;
          this.firewall = auditor.relatedFirewall;
          this.firewallGroup = auditor.relatedFirewallGroup;
          this.centralManagement = auditor.relatedCentralManagement;
          this.fwType = auditor.relatedFirewallType;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    loadFirewalls() {
      if (this.vendor) {
        getFirewallListByVendor(this.vendor)
          .then((response) => {
            this.firewalls = response.data;
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
    loadFirewallGroups() {
      getFirewallGroupListByVendor(this.vendor)
        .then((response) => {
          this.firewallGroups = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadCentralManagement() {
      getCentralManagementListByVendor(this.vendor)
        .then((response) => {
          this.centralManagements = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    save() {
      this.saving = true;
      const data = {
        name: this.name,
        description: this.description,
        active: this.state,
        firewall: this.firewall,
        fwType: this.fwType,
        firewallGroup: this.firewallGroup,
        centralManagement: this.centralManagement,
      };
      if (this.id === 0) {
        storeCveAuditor(data)
          .then(() => {
            this.saving = false;
            this.$toast.open({
              message: "Auditor CVE was successfully created!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.close();
          })
          .catch((e) => {
            Errors.show(e);
            this.saving = false;
          });
      } else {
        updateCveAuditor(data, this.id)
          .then(() => {
            this.saving = false;
            this.$toast.open({
              message: "Auditor CVE was successfully updated!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.close();
          })
          .catch((e) => {
            Errors.show(e);
            this.saving = false;
          });
      }
    },
  },
};
</script>
