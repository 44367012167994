<template>
  <v-container :fluid="true" class="px-0 py-0">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="mx-0" :class="'mt-4'">
        <v-col cols="12" class="pb-2 pt-3 pl-0">
          <h3 class="font-ubuntu-bold">
            {{ id === 0 ? "Add new Task" : "Edit Task" }}
          </h3>
        </v-col>
      </v-row>

      <!--FORM CONTENT-->

      <v-row class="mx-0" :class="'mt-4'">
        <!--NAME-->
        <v-col cols="6" class="py-0 px-0 pr-2">
          <label class="font-roboto-condensed-regular font-size-15">Name</label>
          <v-text-field
            v-model="description"
            :rules="descriptionRules"
            label="Name"
            placeholder="Name"
            dense
            solo
            @change="updateEmit"
          />
        </v-col>
        <!--END NAME-->
        <!--COMMAND-->
        <v-col cols="6" class="py-0 px-0">
          <label class="font-roboto-condensed-regular font-size-15"
            >Command</label
          >
          <v-select
            v-model="command"
            :items="commandList"
            :rules="commandRules"
            item-text="name"
            item-value="id"
            label="Command"
            placeholder="Command"
            dense
            solo
            @change="updateEmit"
          />
        </v-col>
        <!--END COMMAND-->
      </v-row>

      <!--AUDITOR GROUPS-->
      <v-row class="mt-0 mx-0" v-if="showAuditorGroups">
        <v-col cols="12" class="py-0 px-0">
          <label class="font-roboto-condensed-regular font-size-15"
            >Auditor Group</label
          >
          <v-autocomplete
            v-model="auditorGroup"
            :items="auditorGroups"
            item-text="name"
            item-value="id"
            placeholder="Auditor group"
            multiple
            outlined
            @change="updateEmit"
          >
            <template v-slot:selection="data">
              <v-chip small color="primary" class="elevation-2 synapsa-chip">
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <v-chip small color="primary" class="elevation-2 synapsa-chip">
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <!--AUDITOR GROUPS-->

      <!--TIMEZONE-->
      <v-row class="mx-0" :class="'mt-4'">
        <v-col cols="6" class="py-0 px-0 pr-2">
          <label class="font-roboto-condensed-regular font-size-15"
            >Timezone</label
          >
          <v-select
            v-model="timezone"
            :items="timezoneList"
            :rules="timezoneRules"
            item-text="value"
            item-value="value"
            label="Timezone"
            placeholder="Timezone"
            dense
            solo
            @change="updateEmit"
          />
        </v-col>
        <!--FREQUENCY-->
        <v-col cols="6" class="py-0 px-0">
          <label class="font-roboto-condensed-regular font-size-15"
            >Frequency</label
          >
          <!--  :disabled="expression_type != 'frequency'" -->
          <v-select
            class="mb-1"
            v-model="frequencies"
            :items="frequencyList"
            :rules="[frequencyRules]"
            item-text="label"
            item-value="interval"
            label="Frequency"
            placeholder="Frequency"
            dense
            multiple
            solo
            @change="updateEmit"
          />
        </v-col>
        <!--END FREQUENCY-->
      </v-row>
      <!--END TIMEZONE-->

      <!--TYPE-->
      <v-row class="mx-0" :class="'mt-4'">
        <v-col cols="12" class="py-0 px-0">
          <!--  <label class="font-roboto-condensed-regular font-size-15">Type</label> -->
          <!--           <v-radio-group
            v-model="expression_type"
            :rules="expression_typeRules"
            column
            dense
            solo
            @change="updateEmit"
          >
                       <v-radio label="Expression" value="expression"
              >Cron Expression</v-radio
            >
            <v-radio label="Frequency" value="frequency">Frequency</v-radio>
          </v-radio-group> -->
        </v-col>
      </v-row>
      <!--END TYPE-->

      <!--NOTIFY EMAIL-->
      <v-row class="mx-0" :class="'mt-4'">
        <v-col cols="6" class="py-0 px-0 pr-2">
          <label class="font-roboto-condensed-regular font-size-15"
            >Notification Email Address</label
          >
          <v-text-field
            v-model="notification_email_address"
            label="e.g. notify@synapsa.tech"
            placeholder="e.g. notify@synapsa.tech"
            dense
            solo
            @change="updateEmit"
          />
        </v-col>
        <!--MISCELLANEOUS OPTIONS-->
        <v-col cols="6" class="py-0 px-0">
          <label class="font-roboto-condensed-regular font-size-15"
            >Miscellaneous Options</label
          >
          <div class="d-flex align-center">
            <div class="toggle pr-2 mt-2">
              <v-switch
                v-model="dont_overlap"
                label="Don't Overlap"
                @change="updateEmit"
                hide-details
                inset
                :class="[dont_overlap ? 'v-switch-on' : 'v-switch-off']"
                class="target-background v-switch-off"
              />
            </div>
            <div class="toggle mt-2">
              <v-switch
                v-model="run_in_maintenance"
                label="Run in Maintenance Mode"
                placeholder="Run in Maintenance Mode"
                @change="updateEmit"
                hide-details
                inset
                :class="[run_in_maintenance ? 'v-switch-on' : 'v-switch-off']"
                class="target-background v-switch-off"
              />
            </div>
          </div>
          <div class="toggle" v-if="showForce">
            <v-switch
              v-model="force"
              label="Force"
              placeholder="Force"
              @change="updateEmit"
              hide-details
              inset
              :class="[force ? 'v-switch-on' : 'v-switch-off']"
              class="target-background v-switch-off"
            />
          </div>
        </v-col>
        <!--END MISCELLANEOUS OPTIONS-->
      </v-row>
      <!--END NOTIFY EMAIL-->

      <!--AUTO CLEANUP-->
      <v-row class="mx-0" :class="'mt-4'">
        <v-col cols="6" class="py-0 px-0 pr-2">
          <label class="font-roboto-condensed-regular font-size-15"
            >Auto Cleanup results after</label
          >
          <v-text-field
            v-model="auto_cleanup_num"
            :rules="auto_cleanup_numRules"
            type="number"
            min="1"
            max="100"
            label="Auto Cleanup results after"
            placeholder="Auto Cleanup results after"
            dense
            solo
            @change="updateEmit"
          />
        </v-col>
        <v-col cols="6" class="py-0 px-0">
          <label class="font-roboto-condensed-regular font-size-15">Type</label>
          <v-radio-group
            class="mb-3 d-flex mt-1"
            v-model="auto_cleanup_type"
            :rules="auto_cleanup_typeRules"
            column
            dense
            solo
            @change="updateEmit"
          >
            <v-radio label="Days" value="days" class="mr-6">Days</v-radio>
            <v-radio label="Results" value="results">Results</v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <!--END AUTO CLEANUP-->

      <!--END FORM CONTENT-->
      <v-row class="mt-1 mx-0">
        <v-col cols="12" class="py-0 px-0">
          <v-icon v-if="saving" color="primary">
            fas fa-spinner fa-spin
          </v-icon>
          <span
            v-if="saving"
            class="font-roboto-condensed-light font-size-15 ml-2"
          >
            Processing...
          </span>
          <v-btn
            v-if="!saving"
            color="primary"
            class="text-capitalize"
            @click="validate"
          >
            {{ this.mountData.id === 0 ? "Create" : "Update" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { Validation } from "@/layout/FormSidebar/task/validation";
import { storeTask, updateTask } from "@/api/system/task";
import { Errors } from "@/utils/error";

export default {
  props: {
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
    showAuditorGroups: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    showForce: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      valid: true,
      description: "",
      descriptionRules: [(v) => !!v || "Name is required"],
      command: "",
      commandList: "",
      commandRules: [(v) => !!v || "Command is required"],
      auditorGroup: [],
      auditorGroups: [],
      timezone: "",
      timezoneRules: [(v) => !!v || "Timezone is required"],
      expression_type: "frequency",
      expression_typeRules: [(v) => !!v || "Type is required"],
      expression: "",
      frequencies: [],
      frequencyList: [],
      notification_email_address: "",
      notification_slack_webhook: "",
      dont_overlap: false,
      run_in_maintenance: false,
      force: false,
      auto_cleanup_num: "",
      auto_cleanup_numRules: [
        (v) => !!v || "Results is required",
        (v) => parseInt(v, 10) <= 100 || "Max 100",
        (v) => parseInt(v, 10) >= 1 || "Min 1",
      ],
      auto_cleanup_type: "",
      auto_cleanup_typeRules: [(v) => !!v || "Option is required"],

      state: true,
      saving: false,
    };
  },
  computed: {
    frequencyRules() {
      return this.frequencies.length > 0
        ? true
        : "At least one frequency required";
    },
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
  },
  created() {
    if (this.mountData) {
      this.id = this.mountData.id;
      this.description = this.mountData.description;
      this.command = this.mountData.command;
      this.timezone = this.mountData.timezone;
      this.expression = this.mountData.expression;
      this.expression_type = this.mountData.expression_type;
      this.notification_email_address = this.mountData.notification_email_address;
      this.notification_slack_webhook = this.mountData.notification_slack_webhook;
      this.dont_overlap = this.mountData.dont_overlap;
      this.run_in_maintenance = this.mountData.run_in_maintenance;
      this.force = this.mountData.force;
      this.auto_cleanup_num = this.mountData.auto_cleanup_num;
      this.auto_cleanup_type = this.mountData.auto_cleanup_type;
      this.frequencies = this.mountData.frequencies;
      this.results = this.mountData.results;
      this.commandList = this.mountData.commandList;
      this.timezoneList = this.mountData.timezoneList;
      this.frequencyList = this.mountData.frequencyList;
      this.auditorGroup = this.mountData.auditorGroup;
      this.auditorGroups = this.mountData.auditorGroups;
    }
  },
  mounted() {},
  methods: {
    updateEmit() {
      if (this.showAuditorGroups) {
        this.parameters = this.transformAuditorGroupsIntoParameters(
          this.auditorGroup
        );
      }
      if (this.force) {
        this.parameters = this.parameters + ' --force="true"';
      }
      this.$emit("updateEmit", {
        description: this.description,
        command: this.command,
        timezone: this.timezone,
        expression: this.expression,
        /* expression_type: this.expression_type, */
        dont_overlap: this.dont_overlap,
        parameters: this.parameters,
        frequencies: this.frequencies,
        is_active: this.is_active,
        run_in_maitenance: this.run_in_maitenance,
        notification_email_address: this.notification_email_address,
        notification_phone_number: this.notification_phone_number,
        notification_slack_webhook: this.notification_slack_webhook,
        auto_cleanup_num: this.auto_cleanup_num,
        auto_cleanup_type: this.auto_cleanup_type,
        auditorGroup: this.auditorGroup,
      });
    },
    validateCheck() {
      this.$refs.formCheck.validate();
      if (this.$refs.formCheck.validate()) {
        this.check();
      }
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    validateTabs(tab) {
      if (tab) {
        if (tab === "results") {
          this.validGeneral = !!Validation.general(this.general);
        }
      }
      if (this.activeTab === "general" && !this.validGeneral) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      } else if (this.activeTab === "results" && !this.validResults) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      }
    },
    store() {
      const frequencyArr = [];
      const self = this;
      this.frequencies.forEach(function (value) {
        if (value.interval && value.label) {
          frequencyArr.push({ interval: value.interval, label: value.label });
        } else {
          const obj = self.frequencyList.find(
            (freq) => freq.interval === value
          );
          if (obj) {
            frequencyArr.push({ interval: obj.interval, label: obj.label });
          }
        }
      });
      this.saving = true;
      const auditorGroups = this.showAuditorGroups ? this.auditorGroup : [];
      const data = {
        description: this.description,
        command: this.command,
        timezone: this.timezone,
        expression: this.expression,
        expression_type: this.expression_type,
        dont_overlap: this.dont_overlap,
        parameters: this.parameters,
        type: "frequency",
        frequencies: frequencyArr,
        is_active: this.is_active,
        run_in_maitenance: this.run_in_maitenance,
        notification_email_address: this.notification_email_address,
        notification_phone_number: this.notification_phone_number,
        notification_slack_webhook: this.notification_slack_webhook,
        auto_cleanup_num: this.auto_cleanup_num,
        auto_cleanup_type: this.auto_cleanup_type,
        auditorGroups,
      };
      storeTask(data)
        .then(() => {
          this.$toast.open({
            message: "Task was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.changeInAddForm = "systemtask";
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    update() {
      const frequencyArr = [];
      const self = this;
      this.frequencies.forEach(function (value) {
        if (value.interval && value.label) {
          frequencyArr.push({ interval: value.interval, label: value.label });
        } else {
          const obj = self.frequencyList.find(
            (freq) => freq.interval === value
          );
          if (obj) {
            frequencyArr.push({ interval: obj.interval, label: obj.label });
          }
        }
      });
      this.saving = true;
      const auditorGroups = this.showAuditorGroups ? this.auditorGroup : [];
      const data = {
        id: this.id,
        description: this.description,
        command: this.command,
        timezone: this.timezone,
        expression: this.expression,
        expression_type: this.expression_type,
        dont_overlap: this.dont_overlap,
        parameters: this.parameters,
        type: "frequency",
        frequencies: frequencyArr,
        is_active: this.is_active,
        run_in_maitenance: this.run_in_maitenance,
        notification_email_address: this.notification_email_address,
        notification_phone_number: this.notification_phone_number,
        /*         notification_slack_webhook: this.notification_slack_webhook, */
        auto_cleanup_num: this.auto_cleanup_num,
        auto_cleanup_type: this.auto_cleanup_type,
        auditorGroups,
      };
      updateTask(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Task was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.saving = false;
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "user");
    },
    transformAuditorGroupsIntoParameters(groups) {
      return `--groups="[${groups.join(",")}]"`;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
}
::v-deep
  .v-input--radio-group--column
  .v-radio:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}
</style>
