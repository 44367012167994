<template>
  <v-container :fluid="true" class="pb-0 pt-2">
    <v-row class="mx-0 mt-0">

      <!--SCRIPT-->
      <v-row class="mt-0 mx-0">
        <v-col cols="12" class="pb-0">
          <label class="font-roboto-condensed-regular font-size-15"
          >Script</label
          >
          <v-textarea
              v-model="customQuery"
              :rules="customQueryRules"
              placeholder="<?php ... ?>"
              auto-grow
              rows="20"
              dense
              solo
              @keyup="update"
          />
        </v-col>
      </v-row>
      <!--END SCRIPT-->
    </v-row>
  </v-container>
</template>

<script>

export default {
  props: {
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      customQuery: "",
      customQueryRules: [(v) => !!v || "Script is required"],
      loading: true,
    };
  },
  created() {
    this.customQuery = this.mountData.customQuery;
  },
  methods: {
    update() {
      this.$emit("update", {
        customQuery: this.customQuery,
      });
    },
  },
};
</script>
