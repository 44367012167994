export const Validation = {
  ip: function (ip) {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (ip.match(ipRegex)) {
      return true;
    } else {
      return "Invalid format of ip address";
    }
  },
  ipWithOptionalMask: function (ip) {
    const ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))?$/.test(ip);
    if (ipRegex) {
      return true;
    } else {
      return "Please add IPv4 address or IPv4/CIDR";
    }
  },
  ipWithMask: function (ip) {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const maskRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;

    const ipSplit = ip.split("/");
    if (ipSplit.length === 2) {
      if (ipSplit[0].match(ipRegex) && ip.match(maskRegex)) {
        return true;
      } else {
        return "Invalid format of ip address";
      }
    } else {
      return "Invalid format of ip address";
    }
  },
  ipWithPort: function (ip) {
    // TODO simplify in one regex
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const ipRegexWithPort = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):(6553[0-5]|655[0-2][0-9]|65[0-4][0-9][0-9]|6[0-4][0-9][0-9][0-9][0-9]|[1-5](\d){4}|[1-9](\d){0,3})$/;

    if (ip.split(":").length > 1 && ip.match(ipRegexWithPort)) {
      return true;
    } else {
      if (ip.match(ipRegex)) {
        return true;
      } else {
        return "Invalid format of ip address";
      }
    }
  },
  ipWithRule: function (ip, rule) {
    if (rule === 1) {
      return Validation.ip(ip);
    } else if (rule === 2) {
      return Validation.ipWithMask(ip);
    } else if (rule === 3) {
      return Validation.ipWithMask(ip);
    } else if (rule === 4) {
      return ip === "any-ip" ? true : "Invalid format of ip address";
    } else if (rule === 5) {
      return ip === "any" ? true : "Invalid format of ip address";
    } else if (rule === 21) {
      return ip === "anything" ? true : "Invalid format of ip address";
    }
    return true;
  },
  service: function (port, rule) {
    const min = 1;
    const max = 65535;
    if ([14, 27, 33].includes(rule)) {
      if (port >= min && port <= max) {
        return true;
      } else {
        return "Port must be between 1 and 65535";
      }
    } else if ([15].includes(rule)) {
      const portRange = port.split("-");
      if (portRange.length === 2) {
        if (parseInt(portRange[0]) && parseInt(portRange[1])) {
          const num1 = parseInt(portRange[0]);
          const num2 = parseInt(portRange[1]);
          if (num1 < num2) {
            if (num1 >= min && num1 <= max && num2 >= min && num2 <= max) {
              return true;
            } else {
              return "Port range min/max must be numbers between 1 and 65535";
            }
          } else {
            return "Port range min must be lower than max";
          }
        } else {
          return "Port range min/max must be numbers";
        }
      } else {
        return "Wrong format of port range";
      }
    } else {
      return true;
    }
  },
  port: function (port) {
    const min = 1;
    const max = 65535;
    if (port >= min && port <= max) {
      return true;
    } else {
      return "Port must be between 1 and 65535";
    }
  },
  email: function (address) {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
    if (address.match(emailRegex)) {
      return true;
    } else {
      return "Invalid format of email";
    }
  },
  url: function (link) {
    const linkRegex = /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/;
    if (link.match(linkRegex)) {
      return true;
    } else {
      return "Invalid format of url";
    }
  },
  server: function (server) {
    const serverRegex = /^(https?:\/\/)?([\da-z-]+)\.([a-z]{2,9})\.([\w-]*)*\/?$/;
    if (server.match(serverRegex)) {
      return true;
    } else {
      return "Invalid format of server url";
    }
  },
  domain: function (server) {
    const serverRegex = /([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/;
    if (server.match(serverRegex)) {
      return true;
    } else {
      return "Invalid domain format";
    }
  },
};
