import request from "@/utils/request";

export function storeAuditor(data) {
  return request({
    url: "api/auditor/integrity",
    method: "post",
    data: data,
  });
}

export function checkAuditor(data) {
  return request({
    url: "api/auditor/integrity/check",
    method: "post",
    data: data,
  });
}

export function updateAuditor(data, id) {
  return request({
    url: "api/auditor/integrity/" + id,
    method: "put",
    data: data,
  });
}

export function getAuditors(page, size, search) {
  const hasSearch = search
    ? "&searchTerm=" +
      search.searchTerm +
      "&advancedMode=" +
      (search.advancedMode ? 1 : 0)
    : "";
  return request({
    url: "api/auditor/integrity?page=" + page + "&size=" + size + hasSearch,
    method: "get",
  });
}

export function getIntegrityXpath() {
  return request({
    url: "api/auditor/xpath",
    method: "get",
  });
}

export function getAuditor(id) {
  return request({
    url: "api/auditor/integrity/" + id,
    method: "get",
  });
}

export function deleteAuditor(id) {
  return request({
    url: "api/auditor/integrity/" + id,
    method: "delete",
  });
}

export function testAuditorIntegrity(id, data) {
  return request({
    url: "api/auditor/integrity/test/" + id,
    method: "post",
    data: data,
  });
}
