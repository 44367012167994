<template>
  <v-container :fluid="true" class="pb-0 pt-2">

    <div class="container">
      <span class="font-ubuntu-bold">How to add a script:</span>
      <p>
        Write php script with<br />
        <b>&lt;?php</b> on first line and<br />
        <b>?&gt;</b> on end<br />
      </p>

      <span class="font-ubuntu-bold">How connect to database:</span>
      <p>
        For connect to DB please add <b>include 'helpers.php';</b>.<br />
        This file have two functions <b>openConnection</b> and <b>closeConnection</b>
      </p>

      <span class="font-ubuntu-bold">Data for script:</span>
      <p>
        For Event data please add:<br />
        $event = <b>json_decode($argv[<span class="text-red">1</span>], true);</b><br />
        $event is array with your custom fields for Event from CEF parser<br /><br />

        For Rule please add:<br />
        $rule= <b>json_decode($argv[<span class="text-red">2</span>], true);</b><br />
        $rule is array with <b>id</b> and <b>name</b> from rule<br />
      </p>

      <span class="font-ubuntu-bold">Notification profile:</span>
      <p>
        If you want Synapsa to monitor logs that you manually insert into the DB, then the INSERT statement must include a <b>insert_type</b> column with a value of <b>1</b>.<br />
        Data that you save this way will be processed according to the Notification profile. Because it is a direct insert into the DB, it is expected that you will save the result you want to have sent. The setting of the type (raw/json) in Objects->Syslog will not be taken into account when sending data from these inserts and the data will not be subsequently supplemented and converted.
      </p>
    </div>

    <v-row class="mt-0">
      <v-col cols="12" class="pb-2">
        <span
            class="pl-3 font-roboto-condensed-regular font-size-15 cursor-pointer text-green"
            @click="example = !example"
        >
          <v-icon class="font-size-12 mb-1"> fas fa-info </v-icon>
          Script Example
        </span>
        <span
            v-if="example"
            class="pl-3 font-roboto-condensed-regular font-size-10 cursor-pointer"
            @click="copyToClipboard(exampleData)"
        >
          COPY
        </span>
      </v-col>
    </v-row>

    <v-row v-if="example">
      <v-col cols="12">
        <v-textarea
            v-model="exampleData"
            placeholder="<?php ... ?>"
            auto-grow
            rows="20"
            dense
            solo
        />
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

export default {
  props: {
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      example: false,
      exampleData: '<?php\n' +
          'include \'helpers.php\';\n' +
          'echo "Run Script from Rule<br>";\n' +
          '\n' +
          '// Variables for search\n' +
          '$event = json_decode($argv[1], true);\n' +
          '$rule = json_decode($argv[2], true);\n' +
          '$edl = "MISP";\n' +
          '\n' +
          '// Variables for result\n' +
          '$date = date("Y-m-d G:i:s");\n' +
          '$severity = \'2\'; // 1=low, 2=medium, 3=high, 4=critical\n' +
          '\n' +
          'execute($event, $rule, $date, $edl, $severity);\n' +
          '\n' +
          'function execute($event, $rule, $date, $edl, $severity) {\n' +
          '\n' +
          '    // Message\n' +
          '    $msg = new stdClass();\n' +
          '    $msg->timestamp = $date;\n' +
          '    $msg->EDL = $edl;\n' +
          '    $msg->severity = "medium";\n' +
          '\n' +
          '    $conn = openConnection();\n' +
          '    echo "Open connect to Database<br>";\n' +
          '\n' +
          '    $iplist = $conn->query("SELECT id, title FROM ip_list_type WHERE title = \'". $edl . "\' LIMIT 1");\n' +
          '\n' +
          '    if ($iplist->num_rows === 0) {\n' +
          '        echo "IP lList ($edl) not found";\n' +
          '        exit;\n' +
          '    }else{\n' +
          '        $ipListResult = mysqli_fetch_assoc($iplist);\n' +
          '\n' +
          '        if(is_array($event["src"])){\n' +
          '            // echo "Source is array of ip<br>";\n' +
          '            // echo "Source is array of ip<br>";\n' +
          '            $result = $conn->query("SELECT id, ip_address, ip_list FROM ip_list WHERE ip_list = \'". $ipListResult["id"] . "\' AND ip_address IN (\'". implode(\'\',\'\', $event["src"]) ."\')");\n' +
          '        }else{\n' +
          '            // echo "Source is single ip<br>";\n' +
          '            $result = $conn->query("SELECT id, ip_address, ip_list FROM ip_list WHERE ip_list = \'". $ipListResult["id"] . "\' AND ip_address = \'".$event["src"]."\'");\n' +
          '        }\n' +
          '\n' +
          '\n' +
          '        if (!empty($result) && $result->num_rows > 0) {\n' +
          '            echo "Record found in EDL lists<br>";\n' +
          '\n' +
          '            while ($result_row = mysqli_fetch_assoc($result)) {\n' +
          '                echo "<b>RECORD FOUND: ". $result_row[\'ip_address\'] . "</b><br>";\n' +
          '\n' +
          '                // Example msg 1:\n' +
          '                // $conn->query("INSERT INTO systemlogs (name, modul, severity, insert_type, created_at, updated_at) VALUES(\'IP " . $result_row["ip_address"] . " found matching SYNAPSA rule " . $rule["id"] . " in EDL " . $edl . " \', \'Rule Script\', " . $severity . ", 1, \'" . $date . "\', \'" . $date . "\');");\n' +
          '\n' +
          '                // Example msg 2:\n' +
          '                $msg->ip = $result_row["ip_address"];\n' +
          '                $msgInJson = json_encode($msg);\n' +
          '                $conn->query("INSERT INTO systemlogs (name, modul, severity, insert_type, created_at, updated_at) VALUES(\'" . $msgInJson . "\', \'Rule Script\', " . $severity . ", 1, \'" . $date . "\', \'" . $date . "\');");\n' +
          '\n' +
          '            }\n' +
          '        }else{\n' +
          '            echo "IP not found in EDL list $edl<br>";\n' +
          '        }\n' +
          '    }\n' +
          '\n' +
          '    closeConnection($conn);\n' +
          '    echo "Close connect to database<br>";\n' +
          '}\n' +
          '?>',
    };
  },
  methods: {
    copyToClipboard(text) {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.message = text;
    },
  },
};
</script>
<style scoped>
.text-red {
  color: indianred;
}
.text-green {
  color: forestgreen;
}
</style>
