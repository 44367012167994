const state = {
  allAlertsCount: 0,
  auditorAlertsCount: 0,
  alertsCount: 0,
  rulesetAlertsCount: 0,
  rulesetLowAlertsCount: 0,
  rulesetMediumAlertsCount: 0,
  rulesetHighAlertsCount: 0,
  rulesetCriticalAlertsCount: 0,
  cveAlertsCount: 0,
  integrityAlertsCount: 0,
  auditorTaskProcessing: false,
  auditorRulesetTaskProcessing: false,
  auditorCveTaskProcessing: false,
  auditorIntegrityTaskProcessing: false,
  monitorAuditorRulesetLoadBatch: undefined,
  monitorAuditorRulesetBatchDate: undefined,
  monitorAuditorIntegrityLoadBatch: undefined,
  monitorAuditorIntegrityBatchDate: undefined,
  monitorAuditorRulesetFirewalls: undefined,
};

const mutations = {
  CHANGE_RULESET_ALERT: (state, { key, value }) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  },
  CHANGE_MONITOR_BATCH: (state, { key, value }) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  },
  CHANGE_MONITOR_SEARCH: (state, { key, value }) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  },
  CHANGE_MONITOR_FIREWALLS: (state, { key, value }) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  },
};

const actions = {
  changeAlerts({ commit }, data) {
    commit("CHANGE_RULESET_ALERT", data);
  },
  changeMonitorBatch({ commit }, data) {
    commit("CHANGE_MONITOR_BATCH", data);
  },
  changeMonitorSearch({ commit }, data) {
    commit("CHANGE_MONITOR_SEARCH", data);
  },
  changeMonitorFirewalls({ commit }, data) {
    commit("CHANGE_MONITOR_FIREWALLS", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
