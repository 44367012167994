import { login, verify, logout } from "@/api/auth";
import { isLogged, setLogged, removeToken } from "@/utils/auth";
import { resetRouter } from "@/router";
import { getUserTableSettings } from "@/api/system/user";
import { Errors } from "@/utils/error";
import { Tables } from "@/utils/tables";

const state = {
  id: null,
  user: null,
  token: isLogged(),
};

const actions = {
    // eslint-disable-next-line no-unused-vars
  login({ commit }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then((response) => {
          setLogged(response.access_token);
          localStorage.clear();
          localStorage.setItem("permissions", response.permissions);
          localStorage.setItem("role", response.userData.role);
          localStorage.setItem("username", response.userData.username);
          localStorage.setItem("email", response.userData.email);
          localStorage.setItem("id", response.userData.id);
          localStorage.setItem("darkmode", response.userData.darkmode);
          localStorage.setItem("appBackground", response.userData.background);
          getUserTableSettings()
            .then((response) => {
              Tables.setSettings(response);
              resolve();
            })
            .catch((e) => {
              Errors.show(e);
              resolve();
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
    // eslint-disable-next-line no-unused-vars
    verify({ commit }, userInfo) {
        const { username, password, authCode } = userInfo;
        return new Promise((resolve, reject) => {
            verify({ username: username.trim(), password: password, authCode: authCode })
                .then((response) => {
                    setLogged(response.access_token);
                    localStorage.clear();
                    localStorage.setItem("permissions", response.permissions);
                    localStorage.setItem("role", response.userData.role);
                    localStorage.setItem("username", response.userData.username);
                    localStorage.setItem("email", response.userData.email);
                    localStorage.setItem("id", response.userData.id);
                    localStorage.setItem("darkmode", response.userData.darkmode);
                    localStorage.setItem("appBackground", response.userData.background);
                    getUserTableSettings()
                        .then((response) => {
                            Tables.setSettings(response);
                            resolve();
                        })
                        .catch((e) => {
                            Errors.show(e);
                            resolve();
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },


  logout() {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          removeToken();
          resetRouter();
          const darkMode = localStorage.getItem("darkmode") === "true";
          localStorage.clear();
          localStorage.setItem("darkmode", darkMode ? "true" : "false");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken() {
    return new Promise((resolve) => {
      removeToken();
      localStorage.clear();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
};
