import format from "xml-formatter";

/**
 * @param {string} content
 * @param {array<string>} maskedTags
 * @param {string} mask
 */
export function maskTags(content, maskedTags, mask) {
    try {
        const parser = new DOMParser();
        const xml = parser.parseFromString(content, "text/xml");

        if (xml) {
            maskedTags.forEach(mappedTag => {
                const xmlElements = xml.evaluate('//'+mappedTag, xml, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
                if (xmlElements.snapshotLength) {
                    for (let i = 0; i < xmlElements.snapshotLength; i++) {
                        const xmlElement = xmlElements.snapshotItem(i);
                        let attributes = '';
                        for (let j = 0; j < xmlElement.attributes.length; j++) {
                            const attribute = xmlElement.attributes[j];
                            attributes += ` ${attribute.nodeName}="${attribute.nodeValue}"`;
                        }
                        content = content.replace(
                            `<${xmlElement.nodeName}${attributes}>${xmlElement.textContent}</${xmlElement.nodeName}>`,
                            `<${xmlElement.nodeName}${attributes}>${mask}</${xmlElement.nodeName}>`
                        );
                    }
                }
            });
        }
    } catch (e) {
        console.error('##FAILED getContentWithMaskedTags(): ' + e.message + ' | Line: ' + e.lineNumber);
    }

    return content;
}

/**
 * @param {string} xml
 * @returns {string}
 */
export function getFormattedXml(xml) {
    return xml ? getFormattedConditions(format(xml)) : '';
}

/**
 * @param {string} text
 * @returns {string}
 */
export function getFormattedConditions(text) {
    return text.replace(/&(#60|lt|amp|#62|gt);/g, (match) => {
        const replacements = {
            '&lt;': '<',
            '&gt;': '>',
            '&#60;': '<',
            '&#62;': '>',
            '&amp;': '&',
        };
        return replacements[match];
    });
}
