<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="py-5 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new rule" : "Edit rule" }}
            </h3>
          </v-col>

          <v-col cols="12" class="pt-0 pb-2 pl-6">
            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'general'"
              :color="activeTab === 'general' ? 'primary' : ''"
              :outlined="activeTab === 'general'"
              @click="activeTab = 'general'"
            >
              <v-badge
                v-if="!validGeneral"
                color="error"
                icon="fas fa-exclamation"
              >
                General
              </v-badge>
              <span v-else>General</span>
            </v-btn>

            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'script'"
              :color="activeTab === 'script' ? 'primary' : ''"
              :outlined="activeTab === 'script'"
              @click="activeTab = 'script'"
            >
              <v-badge
                v-if="!validScript"
                color="error"
                icon="fas fa-exclamation"
              >
                Script
              </v-badge>
              <span v-else>Script</span>
            </v-btn>

            <v-btn
                class="text-capitalize mr-2 synapsa-tab"
                :text="activeTab !== 'help'"
                :color="activeTab === 'help' ? 'primary' : ''"
                :outlined="activeTab === 'help'"
                @click="activeTab = 'help'"
            >
              <span>Help</span>
            </v-btn>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <general
                  v-if="activeTab === 'general'"
                  :mount-data="general"
                  :miners="miners"
                  @update="
                    general = $event;
                  "
                />
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <custom-query
                  v-if="activeTab === 'script'"
                  :mount-data="script"
                  @update="script = $event"
                />
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <help-tab
                  v-if="activeTab === 'help'"
                />
              </transition>
            </v-form>

            <v-row v-if="activeTab !== 'help'" class="mt-1 mx-0">
              <v-col cols="12" class="py-0">
                <v-icon v-if="saving" color="primary">
                  fas fa-spinner fa-spin
                </v-icon>
                <span
                  v-if="saving"
                  class="font-roboto-condensed-light font-size-15 ml-2"
                >
                  Processing...
                </span>
                <v-btn
                  v-if="!saving"
                  color="primary"
                  class="text-capitalize"
                  @click="validate"
                >
                  {{ id === 0 ? "Create" : "Update" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import general from "./general";
import helpTab from "./help";
import customQuery from "./script";
import { getMinersList } from "@/api/settings/miner";

import { Errors } from "@/utils/error";
import { Validation } from "./validation";
import { getScriptRule, storeScriptRule, updateScriptRule } from "@/api/interconnector/ruleScript";
export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
    general,
    helpTab,
    customQuery,
  },
  data() {
    return {
      activeTab: "general",

      valid: true,
      validGeneral: true,
      validScript: true,
      loading: true,
      saving: false,

      general: {
        name: "",
        miner: "",
        description: "",
        state: true,
      },
      script: {
        customQuery: "",
      },

      miners: [],
    };
  },
  watch: {
    activeTab: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.validateTabs(newValue);
      },
    },
    valid: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (this.activeTab === "general") {
          this.validGeneral = newValue;
        } else if (this.activeTab === "script") {
          this.validThreats = newValue;
        }
      },
    },
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        this.activeTab = "general";
        if (newValue === 0) {
          this.validGeneral = true;
          this.validThreats = true;
          this.validApi = true;
          this.loadMiners();
          this.general = {
            name: "",
            miner: "",
            description: "",
            state: true,
            firstRender: true,
          };
          this.script = {
            customQuery: "",
          };
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "rule-script");
    },
    loadById() {
      this.validGeneral = true;
      this.validThreats = true;
      this.validApi = true;
      getScriptRule(this.id)
        .then((response) => {
          const rule = response.data;
          //general
          this.general.name = rule.name;
          this.general.miner = rule.miner.id;
          this.general.description = rule.description;
          this.general.state = rule.active ? true : null;

          this.script.customQuery = rule.customQuery;

          this.loadMiners();
          this.loading = false;

        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    loadMiners() {
      getMinersList()
        .then((response) => {
          this.miners = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    validate() {
      this.$refs.form.validate();
      this.validateTabs();
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    validateTabs(tab) {
      if (tab) {
        if (tab === "general") {
          //TODO
        } else if (tab === "script") {
          this.validGeneral = !!Validation.general(this.general);
        }
      }
      if (this.activeTab === "general" && !this.validGeneral) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      } else if (this.activeTab === "script" && !this.validScript) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      }
    },
    save() {
      this.saving = true;
      const data = {
        name: this.general.name,
        description: this.general.description,
        minerId: this.general.miner,
        active: this.general.state ? 1 : 0,
        customQuery: this.script.customQuery,
      };

      if (this.id === 0) {
        storeScriptRule(data)
          .then(() => {
            this.saving = false;
            this.$toast.open({
              message: "Rule was successfully created!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.close();
          })
          .catch((e) => {
            Errors.show(e);
            this.saving = false;
          });
      } else {
        updateScriptRule(data, this.id)
          .then(() => {
            this.saving = false;
            this.$toast.open({
              message: "Rule was successfully updated!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.close();
          })
          .catch((e) => {
            Errors.show(e);
            this.saving = false;
          });
      }
    },
  },
};
</script>
