import request from "@/utils/request";

export function widgetSystemInfo() {
  return request({
    url: "api/widget/systeminfo",
    method: "get",
  });
}

export function widgetMiners() {
  return request({
    url: "api/widget/miners",
    method: "get",
  });
}

export function widgetMinerLogs() {
  return request({
    url: "api/widget/minerlogs",
    method: "get",
  });
}

export function widgetFirewalls() {
  return request({
    url: "api/widget/firewalls",
    method: "get",
  });
}

export function widgetSecPolicyAuditor() {
  return request({
    url: "api/widget/security-policy-auditor",
    method: "get",
  });
}

export function widgetOverallAuditorFailure() {
  return request({
    url: "api/widget/overall-auditor-failure",
    method: "get",
  });
}

export function widgetMostReportedDst(data) {
  return request({
    url: "api/widget/most-reported-destinations",
    method: "get",
    params: data,
  });
}

export function widgetMostReportedSrc(data) {
  return request({
    url: "api/widget/most-reported-sources",
    method: "get",
    params: data,
  });
}

export function widgetSystemLogs(data) {
  return request({
    url: "api/widget/systemlogs",
    method: "get",
    params: data,
  });
}

export function widgetAuditorScoreAll(page, size) {
  return request({
    url: "api/widget/auditor/score/all?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function widgetAuditorScoreRuleset(page, size) {
  return request({
    url: "api/widget/auditor/score/ruleset?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function widgetAuditorScoreIntegrity(page, size) {
  return request({
    url: "api/widget/auditor/score/integrity?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function widgetAuditorScoreRulesetChart(data) {
  return request({
    url: "api/widget/auditor/score/ruleset/chart",
    method: "get",
    params: data,
  });
}

export function widgetAuditorScoreIntegrityChart(data) {
  return request({
    url: "api/widget/auditor/score/integrity/chart",
    method: "get",
    params: data,
  });
}

export function widgetAuditorScoreAlerts(data) {
  return request({
    url: "api/widget/auditor/score/alerts",
    method: "get",
    params: data,
  });
}

export function widgetFirewallsSearch(data) {
  return request({
    url: "api/widget/firewalls/search",
    method: "get",
    params: data,
  });
}

export function widgetAuditorRulesetMostViolating(page, size) {
  return request({
    url:
      "api/widget/auditor/ruleset/most-violating-security-policies?page=" +
      page +
      "&size=" +
      size,
    method: "get",
  });
}
