import request from "@/utils/request";

export function getEmails(page, size) {
  return request({
    url: "api/object/email?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function getEmailList() {
  return request({
    url: "api/object/emaillist",
    method: "get",
  });
}

export function getEmail(id) {
  return request({
    url: "api/object/email/" + id,
    method: "get",
  });
}
export function storeEmail(data) {
  return request({
    url: "api/object/email/store",
    method: "post",
    data: data,
  });
}

export function updateEmail(data, id) {
  return request({
    url: "api/object/email/update/" + id,
    method: "put",
    data: data,
  });
}

export function deleteEmail(id) {
  return request({
    url: "api/object/email/destroy/" + id,
    method: "delete",
  });
}
