<template>
  <v-container :fluid="true" class="pb-0 pt-2 m-fix">
    <v-row v-if="!loading && !minerId" class="mx-0 mt-0">
      <v-col cols="12" class="pt-2 pb-5">
        <label class="font-roboto-condensed-regular font-size-15">
          You have to select miner first to see available threats.
        </label>
      </v-col>
    </v-row>

    <v-row v-if="!loading && tags.length === 0 && minerId" class="mx-0 mt-0">
      <v-col cols="12" class="pt-2 pb-5">
        <label class="font-roboto-condensed-regular font-size-15">
          No tags available for this miner.
        </label>
      </v-col>
    </v-row>

    <v-divider v-if="!loading && tags.length > 0" class="mt-2" />

    <v-row v-if="!loading && tags.length > 0" class="mx-0 mt-0 pb-3 px-3">
      <v-chip-group
        v-model="tagsChosen"
        column
        multiple
        @change="chooseTag"
        class="py-4"
      >
        <v-chip
          v-for="tag in tags"
          :key="tag.id"
          filter
          outlined
          small
          :color="tag.chosen ? 'primary' : ''"
        >
          {{ tag.tagname }}
        </v-chip>
      </v-chip-group>
      <!--      <v-col-->
      <!--        v-for="tag in tags"-->
      <!--        :key="tag.id"-->
      <!--        :cols="size === 3 ? 4 : 6"-->
      <!--        :lg="size === 3 ? 2 : 4"-->
      <!--        :xl="size === 3 ? 2 : 3"-->
      <!--        class="px-2 font-size-12"-->
      <!--      >-->
      <!--        <v-btn-->
      <!--          :text="!tag.chosen"-->
      <!--          :outlined="tag.chosen"-->
      <!--          :rounded="tag.chosen"-->
      <!--          x-small-->
      <!--          :color="tag.chosen ? 'primary' : ''"-->
      <!--          @click="chooseTag(tag)"-->
      <!--        >-->
      <!--          {{ tag.tagname }}-->
      <!--          <v-icon v-if="tag.chosen" x-small class="ml-1">-->
      <!--            fas fa-check-circle-->
      <!--          </v-icon>-->
      <!--        </v-btn>-->
      <!--      </v-col>-->
    </v-row>

    <v-divider v-if="!loading && tags.length > 0" class="mb-5" />

    <!--CN2-->
    <v-row v-if="!loading && tags.length > 0 && ads" class="mt-0 mx-0">
      <v-col cols="6" class="pt-1 pb-1 px-0">
        <div class="toggle mb-2">
          <v-switch
            v-model="ignoreNoCn2"
            :value="true"
            hide-details
            label="Ignore event with no DataFeed or DataFeed is"
            @change="chooseTag"
            inset
            :class="[ignoreNoCn2 ? 'v-switch-on' : 'v-switch-off']"
            class="target-background v-switch-off"
          />
        </div>
      </v-col>
      <v-col cols="6" class="pt-2 pb-1 px-0">
        <v-text-field
          v-model="cn2"
          label="DataFeed"
          placeholder="DataFeed"
          dense
          solo
          @keyup="chooseTag"
        />
      </v-col>
    </v-row>
    <!--END CN2-->

    <!--CN3-->
    <v-row v-if="!loading && tags.length > 0 && ads" class="my-0 mx-0">
      <v-col cols="6" class="pt-1 pb-1 px-0">
        <div class="toggle mb-2">
          <v-switch
            v-model="ignoreNoCn3"
            :value="true"
            hide-details
            label="Ignore event with no Perspective or Perspective is"
            @change="chooseTag"
            inset
            :class="[ignoreNoCn3 ? 'v-switch-on' : 'v-switch-off']"
            class="target-background v-switch-off"
          />
        </div>
      </v-col>
      <v-col cols="6" class="pt-2 pb-1 px-0">
        <v-text-field
          v-model="cn3"
          label="Perspective"
          placeholder="Perspective"
          dense
          solo
          @keyup="chooseTag"
        />
      </v-col>
    </v-row>
    <!--END CN3-->

    <v-row v-if="loading" class="my-3 mx-0">
      <v-col cols="12" class="pt-0 pb-5">
        <v-icon color="primary"> fas fa-spinner fa-spin </v-icon>
        <span class="font-roboto-condensed-light font-size-15 ml-2">
          Loading tags...
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Errors } from "@/utils/error";
import { getMinerTags } from "@/api/settings/miner";

export default {
  props: {
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
    minerId: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    ads: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  data() {
    return {
      tags: [],
      tagsChosen: [],
      ignoreNoCn2: null,
      cn2: "*",
      ignoreNoCn3: null,
      cn3: "*",
      loading: true,
    };
  },
  created() {
    this.loadMinerTags();
    this.ignoreNoCn2 = this.mountData.ignoreNoCn2;
    this.cn2 = this.mountData.cn2;
    this.ignoreNoCn3 = this.mountData.ignoreNoCn3;
    this.cn3 = this.mountData.cn3;
  },
  methods: {
    loadMinerTags() {
      if (this.minerId) {
        getMinerTags(this.minerId)
          .then((response) => {
            this.tags = response.data.tags;
            const self = this;
            this.tags.forEach(function (tag, key) {
              tag.chosen = self.mountData.tags.includes(tag.id);
              if (self.mountData.tags.includes(tag.id)) {
                self.tagsChosen.push(key);
              }
            });
            this.loading = false;
          })
          .catch((e) => {
            Errors.show(e);
          });
      } else {
        this.loading = false;
      }
    },
    chooseTag() {
      const self = this;
      this.tags.forEach(function (tag) {
        tag.chosen = false;
      });
      this.tagsChosen.forEach(function (tag) {
        self.tags[tag].chosen = true;
      });
      const tags = [];
      this.tags.forEach(function (tag) {
        if (tag.chosen) {
          tags.push(tag.id);
        }
      });
      this.update(tags);
    },
    update(tags) {
      this.$emit("update", {
        tags: tags,
        ignoreNoCn2: this.ignoreNoCn2,
        cn2: this.cn2,
        ignoreNoCn3: this.ignoreNoCn3,
        cn3: this.cn3,
      });
    },
  },
};
</script>
<style lang="scss" scopes>
.m-fix {
  margin-bottom: -24px;
}
</style>
