import Layout from "@/layout";

const dataFeedsRoutes = {
  path: "/data_feeds",
  name: "dataFeeds",
  component: Layout,
  meta: {
    requiresAuth: true,
    icon: "data-feeds.svg",
  },
  redirect: "/data_feeds/address_lists",
  children: [
    {
      path: "address_lists",
      component: () => import("@/views/dataFeeds/addressLists"),
      name: "addressLists",
      meta: {
        active: ["data_feeds", "address_lists"],
        icon: "address-list.svg",
      },
      redirect: "address_lists/custom/all",
      children: [
        {
          path: "custom",
          component: () => import("@/views/dataFeeds/addressLists/custom"),
          name: "addressListsCustom",
          meta: {
            active: ["data_feeds", "address_lists"],
            permission: ["view customaddress"],
          },
          hidden: true,
          redirect: "address_lists/custom/all",
          children: [
            {
              path: "all",
              component: () =>
                import("@/views/dataFeeds/addressLists/custom/all"),
              name: "addressListsCustomAll",
              meta: {
                id: "addressListsCustomAll",
                active: [
                  "data_feeds",
                  "address_lists",
                  "addressListsCustomAll",
                ],
                permission: ["view customaddress"],
              },
              hidden: true,
            },
            {
              path: "show/:id",
              component: () =>
                import("@/views/dataFeeds/addressLists/custom/show"),
              name: "addressListsCustomShow",
              meta: {
                id: "addressListsCustomShow",
                active: [
                  "data_feeds",
                  "address_lists",
                  "addressListsCustomShow",
                ],
                permission: ["view customaddress"],
              },
              hidden: true,
              redirect: "show/:id/source",
              children: [
                {
                  path: "source",
                  component: () =>
                    import("@/views/dataFeeds/addressLists/custom/show/source"),
                  name: "addressListsCustomSource",
                  meta: {
                    id: "addressListsCustomSource",
                    active: [
                      "data_feeds",
                      "address_lists",
                      "addressListsCustomShow",
                      "addressListsCustomSource",
                    ],
                    permission: ["view customaddress"],
                  },
                  hidden: true,
                },
                {
                  path: "destination",
                  component: () =>
                    import(
                      "@/views/dataFeeds/addressLists/custom/show/destination"
                    ),
                  name: "addressListsCustomDestination",
                  meta: {
                    id: "addressListsCustomDestination",
                    active: [
                      "data_feeds",
                      "address_lists",
                      "addressListsCustomShow",
                      "addressListsCustomDestination",
                    ],
                    permission: ["view customaddress"],
                  },
                  hidden: true,
                },
              ],
            },
          ],
        },
        {
          path: "predefined",
          component: () => import("@/views/dataFeeds/addressLists/predefined"),
          name: "addressListsPredefined",
          meta: { active: ["data_feeds", "address_lists"] },
          hidden: true,
          redirect: "address_lists/predefined/all",
          children: [
            {
              path: "all",
              component: () =>
                import("@/views/dataFeeds/addressLists/predefined/all"),
              name: "addressListsPredefinedAll",
              meta: {
                id: "addressListsPredefinedAll",
                active: [
                  "data_feeds",
                  "address_lists",
                  "addressListsPredefinedAll",
                ],
                permission: ["view predefinedaddress"],
              },
              hidden: true,
            },
            {
              path: "show/:id",
              component: () =>
                import("@/views/dataFeeds/addressLists/predefined/show"),
              name: "addressListsPredefinedShow",
              meta: {
                id: "addressListsPredefinedShow",
                active: [
                  "data_feeds",
                  "address_lists",
                  "addressListsPredefinedShow",
                ],
                permission: ["view predefinedaddress"],
              },
              hidden: true,
              redirect: "show/:id/IPv4",
              children: [
                {
                  path: "IPv4",
                  component: () =>
                    import(
                      "@/views/dataFeeds/addressLists/predefined/show/IPv4"
                    ),
                  name: "addressListsPredefinedIPv4",
                  meta: {
                    id: "addressListsPredefinedIPv4",
                    active: [
                      "data_feeds",
                      "address_lists",
                      "addressListsPredefinedShow",
                      "addressListsPredefinedIPv4",
                    ],
                    permission: ["view predefinedaddress"],
                  },
                  hidden: true,
                },
                {
                  path: "IPv6",
                  component: () =>
                    import(
                      "@/views/dataFeeds/addressLists/predefined/show/IPv6"
                    ),
                  name: "addressListsPredefinedIPv6",
                  meta: {
                    id: "addressListsPredefinedIPv6",
                    active: [
                      "data_feeds",
                      "address_lists",
                      "addressListsPredefinedShow",
                      "addressListsPredefinedIPv6",
                    ],
                    permission: ["view predefinedaddress"],
                  },
                  hidden: true,
                },
              ],
            },
          ],
        },
        {
          path: "connection",
          component: () => import("@/views/dataFeeds/addressLists/connection"),
          name: "addressListsConnection",
          meta: {
            active: ["data_feeds", "address_lists"],
            permission: ["view customaddress"],
          },
          hidden: true,
        },
      ],
    },
    {
      path: "domain_lists",
      component: () => import("@/views/dataFeeds/domainLists"),
      name: "domainLists",
      meta: {
        active: ["data_feeds", "domain_lists"],
        icon: "domain-list.svg",
      },
      redirect: "domain_lists/custom/all",
      children: [
        {
          path: "custom",
          component: () => import("@/views/dataFeeds/domainLists/custom"),
          name: "domainListsCustom",
          meta: {
            active: ["data_feeds", "domain_lists"],
            permission: ["view customdomain"],
          },
          hidden: true,
          redirect: "domain_lists/custom/all",
          children: [
            {
              path: "all",
              component: () =>
                import("@/views/dataFeeds/domainLists/custom/all"),
              name: "domainListsCustomAll",
              meta: {
                id: "domainListsCustomAll",
                active: ["data_feeds", "domain_lists", "domainListsCustomAll"],
                permission: ["view customdomain"],
              },
              hidden: true,
            },
            {
              path: "show/:id",
              component: () =>
                import("@/views/dataFeeds/domainLists/custom/show"),
              name: "domainListsCustomShow",
              meta: {
                id: "domainListsCustomShow",
                active: ["data_feeds", "domain_lists", "domainListsCustomShow"],
                permission: ["view customdomain"],
              },
              hidden: true,
            },
          ],
        },
        {
          path: "predefined",
          component: () => import("@/views/dataFeeds/domainLists/predefined"),
          name: "domainListsPredefined",
          meta: { active: ["data_feeds", "domain_lists"] },
          hidden: true,
          redirect: "domain_lists/predefined/all",
          children: [
            {
              path: "all",
              component: () =>
                import("@/views/dataFeeds/domainLists/predefined/all"),
              name: "domainListsPredefinedAll",
              meta: {
                id: "domainListsPredefinedAll",
                active: [
                  "data_feeds",
                  "domain_lists",
                  "domainListsPredefinedAll",
                ],
                permission: ["view predefineddomain"],
              },
              hidden: true,
            },
            {
              path: "show/:id",
              component: () =>
                import("@/views/dataFeeds/domainLists/predefined/show"),
              name: "domainListsPredefinedShow",
              meta: {
                id: "domainListsPredefinedShow",
                active: [
                  "data_feeds",
                  "domain_lists",
                  "domainListsPredefinedShow",
                ],
                permission: ["view predefineddomain"],
              },
              hidden: true,
            },
          ],
        },
      ],
    },
    {
      path: "url_lists",
      component: () => import("@/views/dataFeeds/urlLists"),
      name: "urlLists",
      meta: {
        active: ["data_feeds", "url_lists"],
        icon: "url-list.svg",
      },
      redirect: "url_lists/custom/all",
      children: [
        {
          path: "custom",
          component: () => import("@/views/dataFeeds/urlLists/custom"),
          name: "urlListsCustom",
          meta: {
            active: ["data_feeds", "url_lists"],
            permission: ["view customurl"],
          },
          hidden: true,
          redirect: "url_lists/custom/all",
          children: [
            {
              path: "all",
              component: () => import("@/views/dataFeeds/urlLists/custom/all"),
              name: "urlListsCustomAll",
              meta: {
                id: "urlListsCustomAll",
                active: ["data_feeds", "url_lists", "urlListsCustomAll"],
                permission: ["view customurl"],
              },
              hidden: true,
            },
            {
              path: "show/:id",
              component: () => import("@/views/dataFeeds/urlLists/custom/show"),
              name: "urlListsCustomShow",
              meta: {
                id: "urlListsCustomShow",
                active: ["data_feeds", "url_lists", "urlListsCustomShow"],
                permission: ["view customurl"],
              },
              hidden: true,
            },
          ],
        },
        {
          path: "predefined",
          component: () => import("@/views/dataFeeds/urlLists/predefined"),
          name: "urlListsPredefined",
          meta: { active: ["data_feeds", "url_lists"] },
          hidden: true,
          redirect: "url_lists/predefined/all",
          children: [
            {
              path: "all",
              component: () =>
                import("@/views/dataFeeds/urlLists/predefined/all"),
              name: "urlListsPredefinedAll",
              meta: {
                id: "urlListsPredefinedAll",
                active: ["data_feeds", "url_lists", "urlListsPredefinedAll"],
                permission: ["view predefinedurl"],
              },
              hidden: true,
            },
            {
              path: "show/:id",
              component: () =>
                import("@/views/dataFeeds/urlLists/predefined/show"),
              name: "urlListsPredefinedShow",
              meta: {
                id: "urlListsPredefinedShow",
                active: ["data_feeds", "url_lists", "urlListsPredefinedShow"],
                permission: ["view predefinedurl"],
              },
              hidden: true,
            },
          ],
        },
      ],
    },
  ],
};

export default dataFeedsRoutes;
