import request from "@/utils/request";

export function storeCentralManagement(data) {
  return request({
    url: "api/settings/central_management",
    method: "post",
    data: data,
  });
}

export function updateCentralManagement(data, id) {
  return request({
    url: "api/settings/central_management/" + id,
    method: "put",
    data: data,
  });
}

export function getCentralManagement(id) {
  return request({
    url: "api/settings/central_management/" + id,
    method: "get",
  });
}

export function getCentralManagementListByVendor(id) {
  return request({
    url: "api/settings/central_management/listbyvendor/" + id,
    method: "get",
  });
}

export function getCentralManagements(page, size, order, by) {
  const orderBy = order && by ? "&order=" + order + "&by=" + by : "";
  return request({
    url: "api/settings/central_management?page=" + page + "&size=" + size + orderBy,
    method: "get",
  });
}

export function checkCentralManagement(data) {
  return request({
    url: "api/settings/check_central_management",
    method: "post",
    data: data,
  });
}

export function deleteCentralManagement(id) {
  return request({
    url: "api/settings/central_management/" + id,
    method: "delete",
  });
}
