<template>
  <v-container :fluid="true" class="user-container pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-show="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pb-2 pt-3">
            <div class="edit-account-block" v-if="id === 0">
              <v-form ref="form" class="mb-5" v-model="valid" lazy-validation>
                <div class="section mb-10">
                  <span class="section-title font-ubuntu-bold font-size-16"
                    >Edit account</span
                  >
                  <v-row>
                    <v-col cols="6" class="py-0">
                      <label class="font-roboto-condensed-regular font-size-15"
                        >Username</label
                      >
                      <v-text-field
                        class="input-field pt-0"
                        label="Username"
                        :rules="nameRules"
                        v-model="username"
                        dense
                        solo
                        @input="changeMade = true"
                        autocomplete="false"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" class="py-0">
                      <label class="font-roboto-condensed-regular font-size-15"
                        >Email</label
                      >
                      <v-text-field
                        class="input-field pt-0"
                        label="Email"
                        :rules="emailRules"
                        validate-on-blur
                        v-model="email"
                        dense
                        solo
                        @input="changeMade = true"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" class="py-0">
                      <label class="font-roboto-condensed-regular font-size-15"
                        >First name</label
                      >
                      <v-text-field
                        class="input-field pt-0"
                        label="Name"
                        :rules="nameRules"
                        validate-on-blur
                        v-model="firstname"
                        dense
                        solo
                        @input="changeMade = true"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" class="py-0">
                      <label class="font-roboto-condensed-regular font-size-15"
                        >Surname</label
                      >
                      <v-text-field
                        class="input-field pt-0"
                        label="Surname"
                        :rules="nameRules"
                        validate-on-blur
                        v-model="lastname"
                        dense
                        solo
                        @input="changeMade = true"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" class="py-0">
                      <div class="toggle mb-8">
                        <v-switch
                          v-model="twoFAStatus"
                          :disabled="email === ''"
                          :value="false"
                          hide-details
                          label="Enable 2FA"
                          inset
                          @click="changeMade = true"
                          :class="[
                            twoFAStatus ? 'v-switch-on' : 'v-switch-off',
                          ]"
                          class="target-background v-switch-off"
                        />
                      </div>
                    </v-col>

                    <v-col cols="6" class="py-0">
                      <!--2FA Types-->
                      <v-select
                        v-model="twoFAType"
                        :disabled="!twoFAStatus"
                        :items="twoFATypes"
                        item-text="name"
                        item-value="value"
                        label="2FA"
                        placeholder="2FA"
                        dense
                        solo
                        @click="changeMade = true"
                      />
                      <!--END 2FA Types-->
                    </v-col>
                  </v-row>

                  <v-card-actions class="pa-0">
                    <v-btn
                      v-if="!loading"
                      :disabled="!valid || loading || !changeMade"
                      :loading="updateLoading"
                      elevation="3"
                      small
                      rounded
                      color="primary"
                      class="submit-btn mb-3"
                      @click="validate"
                    >
                      Update
                    </v-btn>
                    <v-icon v-else color="primary" class="mb-2">
                      fas fa-spinner fa-spin
                    </v-icon>
                  </v-card-actions>
                </div>
              </v-form>
              <v-form ref="formPw" v-model="validPw" lazy-validation>
                <div class="section">
                  <span class="section-title font-ubuntu-bold font-size-16"
                    >Change password</span
                  >
                  <v-row>
                    <v-col cols="6">
                      <label class="font-roboto-condensed-regular font-size-15"
                        >Password</label
                      >
                      <v-text-field
                        class="input-field pt-0"
                        :append-icon="pwVisible ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="pwRules"
                        :type="pwVisible ? 'text' : 'password'"
                        label="New password"
                        v-model="password"
                        @click:append="pwVisible = !pwVisible"
                        dense
                        solo
                        validate-on-blur
                        @input="changeMadePw = true"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6">
                      <label class="font-roboto-condensed-regular font-size-15"
                        >Confirm Password</label
                      >
                      <v-text-field
                        class="input-field pt-0"
                        :append-icon="
                          confirmPwVisible ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :rules="[
                          password === confirmPw || 'Password must match',
                          (v) => !!v || 'Required',
                        ]"
                        :type="confirmPwVisible ? 'text' : 'password'"
                        label="Confirm Password"
                        v-model="confirmPw"
                        @click:append="confirmPwVisible = !confirmPwVisible"
                        dense
                        solo
                        validate-on-blur
                        @input="changeMadePw = true"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-card-actions class="pa-0">
                    <v-btn
                      v-if="!loading"
                      :disabled="!validPw || loading || !changeMadePw"
                      :loading="updatePwLoading"
                      elevation="3"
                      small
                      rounded
                      color="primary"
                      class="submit-btn mb-3"
                      @click="validatePw"
                    >
                      Change password
                    </v-btn>
                    <v-icon v-else color="primary" class="mb-2">
                      fas fa-spinner fa-spin
                    </v-icon>
                  </v-card-actions>
                </div>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-row v-if="loading" class="mt-0 mx-0">
        <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
          <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
          <span
            v-if="size !== 1"
            class="font-roboto-condensed-light font-size-15 ml-2"
            >Loading...</span
          >
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import { getUser } from "@/api/system/user";
/* import { Validation } from "@/utils/validation"; */
/* import { getPermissions } from "@/api/system/permission"; */
import { updateUser } from "@/api/system/user";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      valid: false,
      validPw: false,
      changeMade: false,
      changeMadePw: false,
      userId: localStorage.getItem("id"),
      username: "",
      firstname: "",
      lastname: "",
      initialUsername: "",
      initialEmail: "",
      confirmPwVisible: "",
      pwVisible: false,
      password: "",
      confirmPw: "",
      name: "",
      email: "",
      requestSending: false,
      loading: false,
      updateLoading: false,
      updatePwLoading: false,
      twoFAStatus: false,
      twoFAType: 1,
      twoFATypes: [{ value: 1, name: "E-mail" }],
      emailRules: [
        (value) => !!value || "Required.",
        (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],
      nameRules: [
        (value) => !!value || "Required.",
        (value) => (value || "").length <= 20 || "Max 20 characters",
      ],
      pwRules: [
        (value) => !!value || "Required.",
        (value) =>
          (value || "").length >= 8 ||
          "Password must have at least 8 characters",
      ],
    };
  },

  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
    passwordConfirmationRule() {
      return this.password === this.confirmPw || "Password must match";
    },
  },
  watch: {},
  created() {
    if (this.userId && this.userId > 0) {
      this.loadById();
    }
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.updateAccount();
      }
    },
    validatePw() {
      if (this.$refs.formPw.validate()) {
        this.changePassword();
      }
    },
    loadById() {
      this.loading = true;
      getUser(this.userId)
        .then((response) => {
          this.loading = false;
          this.username = response.data.username;
          this.firstname = response.data.firstname;
          this.lastname = response.data.lastname;
          this.email = response.data.email;
          this.twoFAStatus = response.data.twoFAStatus;
          /* Inital values for password update req body */
          this.initialUsername = response.data.username;
          this.initialEmail = response.data.email;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    changePassword() {
      this.updatePwLoading = true;
      const data = {
        id: this.userId,
        password: this.password,
        password_confirmation: this.confirmPw,
        onlyPassword: true,
      };
      updateUser(data, this.userId)
        .then(() => {
          this.changeMadePw = false;
          this.updatePwLoading = false;
          this.$toast.open({
            message: "Your password has been changed successfully",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
        })
        .catch((e) => {
          Errors.show(e);
          this.updatePwLoading = false;
        });
    },
    updateAccount() {
      this.updateLoading = true;
      const data = {
        id: this.userId,
        username: this.username,
        email: this.email,
        firstname: this.firstname,
        lastname: this.lastname,
        twoFAStatus: this.twoFAStatus,
        twoFAType: this.twoFAType,
        onlyPassword: false,
      };
      updateUser(data, this.userId)
        .then(() => {
          this.changeMade = false;
          this.updateLoading = false;
          this.initialLastname = this.lastname;
          this.initialEmail = this.email;
          this.$toast.open({
            message: "Your account was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
        })
        .catch((e) => {
          Errors.show(e);
          this.updateLoading = false;
        });
    },
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "user");
    },
  },
};
</script>

<style lang="scss" scoped>
.input-field {
  /* width: 100%; */
  padding: 10px !important;
  padding-left: 0 !important;
  margin-bottom: -10px !important;
}
.btn-wrapper {
  margin-top: 20px;
  display: flex;
}
.v-list-item {
  height: 30px;
}

.v-list--dense .v-list-item,
.v-list-item--dense {
  min-height: 30px;
}

.add-form-sidebar.theme--dark .topbar-block {
  background-color: #000000;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
.input-field:-webkit-autofill,
.input-field:-webkit-autofill:hover,
.input-field:-webkit-autofill:focus {
  border: 1px solid green;
  -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.user-container {
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
  overflow-x: scroll;
  font-family: RobotoCondensedLight, sans-serif;
}

.v-navigation-drawer__content {
  height: unset;
  overflow-y: unset;
  overflow-x: auto;
}

.section {
  margin: 1rem 0;
  position: relative;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
}
.theme--dark {
  .section {
    background: #3e3e3e;
    box-shadow: 0px 0px 5px 0px #3e3e3e;
  }
}
.theme--light {
  .section {
    background: #f4f4f4;
    box-shadow: 0px 0px 5px 0px #f4f4f4;
  }
}
.section-title {
  position: absolute;
  top: -1.4rem;
  left: 0.4rem;
}
</style>
