<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="py-5 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new auditor" : "Edit auditor" }}
            </h3>
          </v-col>

          <v-col cols="12" class="pt-0 pb-2 pl-6">
            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'general'"
              :color="activeTab === 'general' ? 'primary' : ''"
              :outlined="activeTab === 'general'"
              @click="activeTab = 'general'"
            >
              <v-badge
                v-if="!validGeneral"
                color="error"
                icon="fas fa-exclamation"
              >
                General
              </v-badge>
              <span v-else>General</span>
            </v-btn>

            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'ruleset'"
              :color="activeTab === 'ruleset' ? 'primary' : ''"
              :outlined="activeTab === 'ruleset'"
              @click="activeTab = 'ruleset'"
            >
              <v-badge
                v-if="!validRuleset"
                color="error"
                icon="fas fa-exclamation"
              >
                Ruleset
              </v-badge>
              <span v-else>Ruleset</span>
            </v-btn>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <general
                  v-if="activeTab === 'general'"
                  :id="id"
                  :mount-data="general"
                  @update="general = $event"
                />
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <ruleset
                  v-if="activeTab === 'ruleset'"
                  :id="id"
                  :data-loaded="dataLoaded"
                  :mount-firewalls="firewalls"
                  :mount-data="ruleset"
                  @update="updateRuleset($event)"
                />
              </transition>
            </v-form>

            <v-row
              class="mx-0"
              :class="activeTab === 'general' ? 'mt-5' : 'mt-0'"
            >
              <v-col
                cols="12"
                :class="activeTab === 'general' ? 'pb-0 pt-4' : 'pb-0 pt-1'"
              >
                <v-icon v-if="saving" color="primary">
                  fas fa-spinner fa-spin
                </v-icon>
                <span
                  v-if="saving"
                  class="font-roboto-condensed-light font-size-15 ml-2"
                >
                  Processing...
                </span>
                <v-btn
                  v-if="!saving"
                  color="primary"
                  class="text-capitalize"
                  @click="validate"
                >
                  {{ id === 0 ? "Create" : "Update" }}
                </v-btn>
                <v-btn
                  v-if="!saving"
                  :disabled="!checkTestAvailability()"
                  text
                  outlined
                  color="primary"
                  class="text-capitalize ml-1"
                  @click="test"
                >
                  <v-icon x-small class="mr-1">fas fa-play</v-icon>Test Ruleset
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <test
      v-if="!loading"
      @onClose="showTestModal = false"
      :id="id"
      :show="showTestModal"
      :auditor-data="getAuditorData()"
    />
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { AuditorValidation } from "./validation";
import general from "./general";
import ruleset from "./ruleset";
import { Errors } from "@/utils/error";
import { getAuditor, storeAuditor, updateAuditor } from "@/api/auditor/ruleset";
import { AuditorParser } from "@/layout/FormSidebar/auditor/parser";
import Test from "@/layout/FormSidebar/auditor/ruleset/test"

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
    general,
    ruleset,
    Test
  },
  data() {
    return {
      activeTab: "general",

      valid: true,
      validGeneral: true,
      validRuleset: true,
      loading: true,
      saving: false,
      dataLoaded: false,

      firewalls: null,
      firewallGroups: null,

      general: {
        name: "",
        description: "",
        severity: null,
        action: null,
        state: true,
        firstRender: true,
        auditorGroup: [],
      },
      ruleset: {
        vendor: null,
        auditorMode: 1,
        fwType: "firewall",
        firewall: null,
        firewallGroup: null,
        firewallManagementServer: null,
        action: null,
        actionNegate: false,
        status: null,
        srcIp: "",
        srcIpOperator: false,
        srcIpRule: 1,
        srcIpNegate: false,
        dstIp: "",
        dstIpOperator: false,
        dstIpRule: 1,
        dstIpNegate: false,
        srcZone: "",
        srcZoneOperator: false,
        srcZoneRule: 6,
        srcZoneNegate: false,
        dstZone: "",
        dstZoneOperator: false,
        dstZoneRule: 6,
        dstZoneNegate: false,
        srcInterface: "",
        srcInterfaceRule: 19,
        dstInterface: "",
        dstInterfaceRule: 19,
        service: "",
        serviceOperator: false,
        serviceProtocol: 'any-protocol',
        serviceRule: 14,
        serviceNegate: false,
        logStart: "",
        logStartRule: 45,
        logStartNegate: false,
        logEnd: "",
        logEndRule: 45,
        logEndNegate: false,
        logSetting: "",
        logSettingRule: 46,
        logSettingNegate: false,
        tag: "",
        tagOperator: false,
        tagRule: 42,
        tagNegate: false,
        vpn: "",
        vpnRule: 34,
        profileSetting: "",
        profileSettingRule: 51,
        profileSettingNegate: false,
        profileSettingVirus: "",
        profileSettingVirusRule: 54,
        profileSettingVirusNegate: false,
        profileSettingSpyware: "",
        profileSettingSpywareRule: 54,
        profileSettingSpywareNegate: false,
        profileSettingVulnerability: "",
        profileSettingVulnerabilityRule: 54,
        profileSettingVulnerabilityNegate: false,
        profileSettingWildfire: "",
        profileSettingWildfireRule: 54,
        profileSettingWildfireNegate: false,
        profileSettingUrlFiltering: "",
        profileSettingUrlFilteringRule: 54,
        profileSettingUrlFilteringNegate: false,
        profileSettingDataFiltering: "",
        profileSettingDataFilteringRule: 54,
        profileSettingDataFilteringNegate: false,
        profileSettingFileBlocking: "",
        profileSettingFileBlockingRule: 54,
        profileSettingFileBlockingNegate: false,
        application: "",
        applicationOperator: false,
        applicationRule: 58,
        applicationNegate: false,
        category: "",
        categoryRule: 65,
        categoryNegate: false,
        categoryOperator: false,
        allowedEdl: [],
        firstRender: true,
      },

      showTestModal: false,
    };
  },
  watch: {
    activeTab: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue) {
          this.validateTabs(newValue);
        }
      },
    },
    valid: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (this.activeTab === "general") {
          this.validGeneral = newValue;
        } else if (this.activeTab === "ruleset") {
          this.validRuleset = newValue;
        }
      },
    },
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.activeTab = "general";
          this.validGeneral = true;
          this.validRuleset = true;
          this.general = {
            name: "",
            description: "",
            severity: null,
            action: null,
            state: true,
            firstRender: true,
            auditorGroup: [],
          };
          this.ruleset = {
            vendor: null,
            auditorMode: 1,
            fwType: "firewall",
            firewall: null,
            firewallManagementServer: null,
            action: null,
            actionNegate: false,
            status: null,
            srcIp: "",
            srcIpOperator: false,
            srcIpRule: 1,
            srcIpNegate: false,
            dstIp: "",
            dstIpOperator: false,
            dstIpRule: 1,
            dstIpNegate: false,
            srcZone: "",
            srcZoneOperator: false,
            srcZoneRule: 6,
            srcZoneNegate: false,
            dstZone: "",
            dstZoneOperator: false,
            dstZoneRule: 6,
            dstZoneNegate: false,
            srcInterface: "",
            srcInterfaceRule: 19,
            dstInterface: "",
            dstInterfaceRule: 19,
            service: "",
            serviceOperator: false,
            serviceProtocol: 'any-protocol',
            serviceRule: 14,
            serviceNegate: false,
            logStart: "anything",
            logStartRule: 45,
            logStartNegate: false,
            logEnd: "anything",
            logEndRule: 45,
            logEndtNegate: false,
            logSetting: "anything",
            logSettingRule: 46,
            logSettingNegate: false,
            profileSetting: "anything",
            profileSettingRule: 51,
            profileSettingNegate: false,
            profileSettingVirus: "anything",
            profileSettingVirusRule: 54,
            profileSettingVirusNegate: false,
            profileSettingSpyware: "anything",
            profileSettingSpywareRule: 54,
            profileSettingSpywareNegate: false,
            profileSettingVulnerability: "anything",
            profileSettingVulnerabilityRule: 54,
            profileSettingVulnerabilityNegate: false,
            profileSettingWildfire: "anything",
            profileSettingWildfireRule: 54,
            profileSettingWildfireNegate: false,
            profileSettingUrlFiltering: "anything",
            profileSettingUrlFilteringRule: 54,
            profileSettingUrlFilteringNegate: false,
            profileSettingDataFiltering: "anything",
            profileSettingDataFilteringRule: 54,
            profileSettingDataFilteringNegate: false,
            profileSettingFileBlocking: "anything",
            profileSettingFileBlockingRule: 54,
            profileSettingFileBlockingNegate: false,
            application: "anything",
            applicationOperator: false,
            applicationRule: 58,
            applicationNegate: false,
            category: "anything",
            categoryRule: 65,
            categoryNegate: false,
            categoryOperator: false,
            tag: "anything",
            tagOperator: false,
            tagRule: 42,
            tagNegate: false,
            vpn: "",
            vpnRule: 34,
            allowedEdl: [],
            firstRender: true,
          };
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "auditor");
    },
    test() {
      this.showTestModal = true;
    },
    loadById() {
      this.validGeneral = true;
      this.validRuleset = true;
      getAuditor(this.id)
        .then((response) => {
          const auditor = response.data;
          //general
          this.general.name = auditor.name;
          this.general.description = auditor.description;
          this.general.severity = auditor.severity;
          this.general.action = auditor.action;
          this.general.actionNegate = auditor.actionNegate;
          this.general.state = auditor.active === 1;
          this.general.auditorGroup = auditor.groups;
          //ruleset
          this.ruleset.vendor = auditor.vendorattribute_id;
          this.ruleset.auditorMode = auditor.ruleset_mode;

          this.ruleset.firewall = auditor.relatedFirewall;
          this.ruleset.firewallGroup = auditor.relatedFirewallGroup;
          this.ruleset.fwType = auditor.relatedFirewallType


          this.ruleset.firewallManagementServer = auditor.parent_id;
          this.ruleset.action = auditor.policy_action;
          this.ruleset.status = auditor.policy_status;
          this.ruleset.srcIp = AuditorParser.stringToMulti(
            auditor.src_address,
            auditor.rule_src_address
          );
          this.ruleset.srcIpOperator = auditor.src_address_operator;
          this.ruleset.srcIpRule = auditor.rule_src_address;
          this.ruleset.srcIpNegate = auditor.src_address_negate;
          this.ruleset.dstIp = AuditorParser.stringToMulti(
            auditor.dst_address,
            auditor.rule_dst_address
          );
          this.ruleset.dstIpOperator = auditor.dst_address_operator;
          this.ruleset.dstIpRule = auditor.rule_dst_address;
          this.ruleset.dstIpNegate = auditor.dst_address_negate;
          this.ruleset.srcZone = AuditorParser.stringToMulti(
            auditor.src_zone,
            auditor.rule_src_zone
          );
          this.ruleset.srcZoneOperator = auditor.src_zone_operator;
          this.ruleset.srcZoneRule = auditor.rule_src_zone;
          this.ruleset.srcZoneNegate = auditor.src_zone_negate;
          this.ruleset.dstZone = AuditorParser.stringToMulti(
            auditor.dst_zone,
            auditor.rule_dst_zone
          );
          this.ruleset.dstZoneOperator = auditor.dst_zone_operator;
          this.ruleset.dstZoneRule = auditor.rule_dst_zone;
          this.ruleset.dstZoneNegate = auditor.dst_zone_negate;
          this.ruleset.srcInterface = AuditorParser.stringToMulti(
            auditor.src_interface,
            auditor.rule_src_interface
          );
          this.ruleset.srcInterfaceRule = auditor.rule_src_interface;
          this.ruleset.dstInterface = AuditorParser.stringToMulti(
            auditor.dst_interface,
            auditor.rule_dst_interface
          );
          this.ruleset.dstInterfaceRule = auditor.rule_dst_interface;
          this.ruleset.service = AuditorParser.stringToMulti(
            auditor.port,
            auditor.rule_service
          );
          this.ruleset.serviceOperator = auditor.service_operator;
          this.ruleset.serviceProtocol = auditor.service_protocol;
          this.ruleset.serviceRule = auditor.rule_service;
          this.ruleset.serviceNegate = auditor.service_negate;
          this.ruleset.allowedEdl = AuditorParser.stringToMulti(
            auditor.allowededl
          );
          this.ruleset.logStart = auditor.log_start;
          this.ruleset.logStartRule = auditor.rule_log_start;
          this.ruleset.logStartNegate = auditor.log_start_negate;
          this.ruleset.logEnd = auditor.log_end;
          this.ruleset.logEndRule = auditor.rule_log_end;
          this.ruleset.logEndNegate = auditor.log_end_negate;
          this.ruleset.logSetting = auditor.log_setting;
          this.ruleset.logSettingRule = auditor.rule_log_setting;
          this.ruleset.logSettingNegate = auditor.log_setting_negate;
          this.ruleset.profileSetting = AuditorParser.stringToMulti(
            auditor.profile_setting,
            auditor.rule_profile_setting
          );
          this.ruleset.profileSettingNegate = auditor.profile_setting_negate;
          this.ruleset.profileSettingRule = auditor.rule_profile_setting;
          this.ruleset.profileSettingVirus = AuditorParser.stringToMulti(
            auditor.profile_setting_virus,
            auditor.rule_profile_setting_virus
          );
          this.ruleset.profileSettingVirusRule = auditor.rule_profile_setting_virus;
          this.ruleset.profileSettingVirusNegate = auditor.profile_setting_virus_negate;
          this.ruleset.profileSettingSpyware = AuditorParser.stringToMulti(
              auditor.profile_setting_spyware,
              auditor.rule_profile_setting_spyware
          );
          this.ruleset.profileSettingSpywareRule = auditor.rule_profile_setting_spyware;
          this.ruleset.profileSettingSpywareNegate = auditor.profile_setting_spyware_negate;
          this.ruleset.profileSettingVulnerability = AuditorParser.stringToMulti(
              auditor.profile_setting_vulnerability,
              auditor.rule_profile_setting_vulnerability
          );
          this.ruleset.profileSettingVulnerabilityRule = auditor.rule_profile_setting_vulnerability;
          this.ruleset.profileSettingVulnerabilityNegate = auditor.profile_setting_vulnerability_negate;
          this.ruleset.profileSettingWildfire = AuditorParser.stringToMulti(
              auditor.profile_setting_wildfire,
              auditor.rule_profile_setting_wildfire
          );
          this.ruleset.profileSettingWildfireRule = auditor.rule_profile_setting_wildfire;
          this.ruleset.profileSettingWildfireNegate = auditor.profile_setting_wildfire_negate;
          this.ruleset.profileSettingUrlFiltering = AuditorParser.stringToMulti(
              auditor.profile_setting_url_filtering,
              auditor.rule_profile_setting_url_filtering
          );
          this.ruleset.profileSettingUrlFilteringRule = auditor.rule_profile_setting_url_filtering;
          this.ruleset.profileSettingUrlFilteringNegate = auditor.profile_setting_url_filtering_negate;
          this.ruleset.profileSettingDataFiltering = AuditorParser.stringToMulti(
              auditor.profile_setting_data_filtering,
              auditor.rule_profile_setting_data_filtering
          );
          this.ruleset.profileSettingDataFilteringRule = auditor.rule_profile_setting_data_filtering;
          this.ruleset.profileSettingDataFilteringNegate = auditor.profile_setting_data_filtering_negate;
          this.ruleset.profileSettingFileBlocking = AuditorParser.stringToMulti(
              auditor.profile_setting_file_blocking,
              auditor.rule_profile_setting_file_blocking
          );
          this.ruleset.profileSettingFileBlockingRule = auditor.rule_profile_setting_file_blocking;
          this.ruleset.profileSettingFileBlockingNegate = auditor.profile_setting_file_blocking_negate;
          this.ruleset.tag = AuditorParser.stringToMulti(
              auditor.tag,
              auditor.rule_tag
          );
          this.ruleset.tagOperator = auditor.tag_operator;
          this.ruleset.tagRule = auditor.rule_tag;
          this.ruleset.tagNegate = auditor.tag_negate;
          this.ruleset.application = AuditorParser.stringToMulti(
              auditor.application,
              auditor.rule_application
          );
          this.ruleset.applicationOperator = auditor.application_operator;
          this.ruleset.applicationNegate = auditor.application_negate;
          this.ruleset.applicationRule = auditor.rule_application;

          this.ruleset.category = AuditorParser.stringToMulti(
            auditor.category,
            auditor.rule_category
          );
          this.ruleset.categoryNegate = auditor.category_negate;
          this.ruleset.categoryOperator = auditor.category_operator;
          this.ruleset.categoryRule = auditor.rule_category;
          this.ruleset.vpn = auditor.vpn;
          this.ruleset.vpnRule = auditor.rule_vpn;
          this.ruleset;
          this.loading = false;
          this.dataLoaded = true;

          if (auditor.firewalls && auditor.firewalls.length > 0) {
            this.firewalls = auditor.firewalls;
          }
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    checkTestAvailability() {
      return !this.loading && (this.ruleset.firewall || this.ruleset.firewallGroup) &&
          (
              this.ruleset.service &&
              this.ruleset.dstIp &&
              this.ruleset.dstZone &&
              this.ruleset.srcIp &&
              this.ruleset.srcZone
          );
    },
    validate() {
      this.$refs.form.validate();
      this.validateTabs();
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    validateTabs(tab) {
      if (tab) {
        if (tab === "general") {
          this.validRuleset = !!AuditorValidation.ruleset(this.ruleset);
        } else if (tab === "ruleset") {
          this.validGeneral = !!AuditorValidation.general(this.general);
        }
      }
      if (this.activeTab === "general" && !this.validGeneral) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      } else if (this.activeTab === "ruleset" && !this.validRuleset) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      }
    },
    getAuditorData() {
      return {
        //general
        name: this.general.name,
        description: this.general.description,
        severity: this.general.severity,
        action: this.general.action,
        active: this.general.state === true,
        //ruleset
        ruleset_mode: this.ruleset.auditorMode,
        vendorattribute_id: this.ruleset.vendor,
        parent_id: this.ruleset.firewallManagementServer,
        fw_type: this.ruleset.fwType,
        firewall_id: this.ruleset.firewall,
        firewall_group_id: this.ruleset.firewallGroup,
        policyaction: this.ruleset.action,
        policyaction_negate: this.ruleset.actionNegate,
        policystatus: this.ruleset.status,
        rule_src_address: this.ruleset.srcIpRule,
        src_address_negate: this.ruleset.srcIpNegate,
        src_address: AuditorParser.multiToString(
            this.ruleset.srcIp,
            this.ruleset.srcIpRule
        ),
        src_address_operator: this.ruleset.srcIpOperator,
        rule_dst_address: this.ruleset.dstIpRule,
        dst_address_negate: this.ruleset.dstIpNegate,
        dst_address: AuditorParser.multiToString(
            this.ruleset.dstIp,
            this.ruleset.dstIpRule
        ),
        dst_address_operator: this.ruleset.dstIpOperator,
        rule_src_zone: this.ruleset.srcZoneRule,
        src_zone_negate: this.ruleset.srcZoneNegate,
        src_zone: AuditorParser.multiToString(
            this.ruleset.srcZone,
            this.ruleset.srcZoneRule
        ),
        src_zone_operator: this.ruleset.srcZoneOperator,
        rule_dst_zone: this.ruleset.dstZoneRule,
        dst_zone_negate: this.ruleset.dstZoneNegate,
        dst_zone: AuditorParser.multiToString(
            this.ruleset.dstZone,
            this.ruleset.dstZoneRule
        ),
        dst_zone_operator: this.ruleset.dstZoneOperator,
        rule_src_interface: this.ruleset.srcInterfaceRule,
        src_interface: AuditorParser.multiToString(
            this.ruleset.srcInterface,
            this.ruleset.srcInterfaceRule
        ),
        rule_dst_interface: this.ruleset.dstInterfaceRule,
        dst_interface: AuditorParser.multiToString(
            this.ruleset.dstInterface,
            this.ruleset.dstInterfaceRule
        ),
        rule_service: this.ruleset.serviceRule,
        service_negate: this.ruleset.serviceNegate,
        service: AuditorParser.multiToString(
          this.ruleset.service,
          this.ruleset.serviceRule
        ),
        service_operator: this.ruleset.serviceOperator,
        service_protocol: this.ruleset.serviceProtocol,
        log_start: this.ruleset.logStart,
        rule_log_start: this.ruleset.logStartRule,
        log_start_negate: this.ruleset.logStartNegate,
        log_end: this.ruleset.logEnd,
        rule_log_end: this.ruleset.logEndRule,
        log_end_negate: this.ruleset.logEndNegate,
        log_setting: this.ruleset.logSetting,
        log_setting_negate: this.ruleset.logSettingNegate,
        rule_log_setting: this.ruleset.logSettingRule,
        tag: AuditorParser.multiToString(
            this.ruleset.tag,
            this.ruleset.tagRule
        ),
        tag_operator: this.ruleset.tagOperator,
        rule_tag: this.ruleset.tagRule,
        tag_negate: this.ruleset.tagNegate,
        profile_setting: AuditorParser.multiToString(
          this.ruleset.profileSetting,
          this.ruleset.profileSettingRule
        ),
        profile_setting_negate: this.ruleset.profileSettingNegate,
        rule_profile_setting: this.ruleset.profileSettingRule,
        profile_setting_virus: AuditorParser.multiToString(
          this.ruleset.profileSettingVirus,
          this.ruleset.profileSettingVirusRule
        ),
        rule_profile_setting_virus: this.ruleset.profileSettingVirusRule,
        profile_setting_virus_negate: this.ruleset.profileSettingVirusNegate,
        profile_setting_spyware: AuditorParser.multiToString(
          this.ruleset.profileSettingSpyware,
          this.ruleset.profileSettingSpywareRule
        ),
        rule_profile_setting_spyware: this.ruleset.profileSettingSpywareRule,
        profile_setting_spyware_negate: this.ruleset.profileSettingSpywareNegate,
        profile_setting_vulnerability: AuditorParser.multiToString(
          this.ruleset.profileSettingVulnerability,
          this.ruleset.profileSettingVulnerabilityRule
        ),
        rule_profile_setting_vulnerability: this.ruleset.profileSettingVulnerabilityRule,
        profile_setting_vulnerability_negate: this.ruleset.profileSettingVulnerabilityNegate,
        profile_setting_wildfire: AuditorParser.multiToString(
          this.ruleset.profileSettingWildfire,
          this.ruleset.profileSettingWildfireRule
        ),
        rule_profile_setting_wildfire: this.ruleset.profileSettingWildfireRule,
        profile_setting_wildfire_negate: this.ruleset.profileSettingWildfireNegate,
        profile_setting_url_filtering: AuditorParser.multiToString(
          this.ruleset.profileSettingUrlFiltering,
          this.ruleset.profileSettingUrlFilteringRule
        ),
        rule_profile_setting_url_filtering: this.ruleset.profileSettingUrlFilteringRule,
        profile_setting_url_filtering_negate: this.ruleset.profileSettingUrlFilteringNegate,
        profile_setting_data_filtering: AuditorParser.multiToString(
          this.ruleset.profileSettingDataFiltering,
          this.ruleset.profileSettingDataFilteringRule
        ),
        rule_profile_setting_data_filtering: this.ruleset.profileSettingDataFilteringRule,
        profile_setting_data_filtering_negate: this.ruleset.profileSettingDataFilteringNegate,
        profile_setting_file_blocking: AuditorParser.multiToString(
          this.ruleset.profileSettingFileBlocking,
          this.ruleset.profileSettingFileBlockingRule
        ),
        rule_profile_setting_file_blocking: this.ruleset.profileSettingFileBlockingRule,
        profile_setting_file_blocking_negate: this.ruleset.profileSettingFileBlockingNegate,
        application: AuditorParser.multiToString(
            this.ruleset.application,
            this.ruleset.applicationRule
        ),
        application_operator: this.ruleset.applicationOperator,
        application_negate: this.ruleset.applicationNegate,
        rule_application: this.ruleset.applicationRule,
        category: AuditorParser.multiToString(
            this.ruleset.category,
            this.ruleset.categoryRule
        ),
        category_negate: this.ruleset.categoryNegate,
        category_operator: this.ruleset.categoryOperator,
        rule_category: this.ruleset.categoryRule,
        rule_vpn: this.ruleset.vpnRule,
        vpn: this.ruleset.vpn,
        allowededl: AuditorParser.multiToString(this.ruleset.allowedEdl),
        auditorGroup: this.general.auditorGroup || [],
      };
    },
    save() {
      this.saving = true;
      const data = this.getAuditorData();
      if (this.id === 0) {
        storeAuditor(data)
          .then(() => {
            this.saving = false;
            this.$toast.open({
              message: "Auditor was successfully created!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.close();
          })
          .catch((e) => {
            Errors.show(e);
            this.saving = false;
          });
      } else {
        updateAuditor(data, this.id)
          .then(() => {
            this.saving = false;
            this.$toast.open({
              message: "Auditor was successfully updated!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.close();
          })
          .catch((e) => {
            Errors.show(e);
            this.saving = false;
          });
      }
    },
    updateRuleset(event) {
      this.ruleset = {
        ...this.ruleset,
        ...event
      };
    }
  },
};
</script>
