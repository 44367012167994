import request from '@/utils/request';

export function getSyslogs(page, size) {
		return request({
				url: 'api/object/syslogs?page=' + page + '&size=' + size,
				method: 'get',
		});
}

export function getSyslogList() {
		return request({
				url: 'api/object/sysloglist',
				method: 'get',
		});
}

export function getSyslog(id) {
		return request({
				url: 'api/object/syslog/' + id,
				method: 'get',
		});
}

export function storeSyslog(data) {
  return request({
    url: 'api/object/syslog/store',
    method: 'post',
    data: data,
  });
}

export function updateSyslog(data, id) {
  return request({
    url: 'api/object/syslog/update/' + id,
    method: 'put',
    data: data,
  });
}

export function deleteSyslog(id) {
		return request({
				url: 'api/object/syslog/destroy/' + id,
				method: 'delete',
		});
}
