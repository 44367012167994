export default {
  /**
   * @type {Object}
   * @description Auditor Ruleset table settings
   */
  auditorRulesetTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      { text: "Mode", value: "ruleset_mode", sortable: true, visible: true },
      {
        text: "Severity",
        value: "severity",
        sortable: true,
        visible: true,
        width: 125,
      },
      {
        text: "Vendor",
        value: "vendorattribute_id",
        sortable: true,
        visible: true,
      },
      {
        text: "Firewall / Group",
        value: "firewall",
        sortable: false,
        visible: true,
      },
      {
        text: "Source address",
        value: "src_address",
        sortable: true,
        visible: true,
        width: 145,
      },
      {
        text: "Destination address",
        value: "dst_address",
        sortable: true,
        visible: true,
        width: 175,
      },
      {
        text: "Active",
        value: "active",
        sortable: true,
        visible: true,
        width: 90,
      },
      {
        text: "Test ruleset",
        value: "test",
        sortable: false,
        visible: true,
        width: 110,
        align: "center",
      },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      {
        text: "Groups",
        value: "groups",
        sortable: true,
        visible: true,
      },
      {
        text: "Source zone",
        value: "src_zone",
        sortable: true,
        visible: true,
      },
      {
        text: "Destination zone",
        value: "dst_zone",
        sortable: true,
        visible: true,
      },
      {
        text: "Source interface",
        value: "src_interface",
        sortable: true,
        visible: true,
      },
      {
        text: "Destination interface",
        value: "dst_interface",
        sortable: true,
        visible: true,
      },
      { text: "Port", value: "port", sortable: true, visible: true },
      { text: "Protocol", value: "protocol", sortable: true, visible: true },
      { text: "Action", value: "action", sortable: true, visible: true },
      { text: "Log Start", value: "log_start", sortable: true, visible: true },
      { text: "Log End", value: "log_end", sortable: true, visible: true },
      {
        text: "Log Setting",
        value: "log_setting",
        sortable: true,
        visible: true,
      },
      {
        text: "Profile Setting",
        value: "profile_setting",
        sortable: true,
        visible: true,
      },
      {
        text: "Profile Antivirus",
        value: "profile_setting_virus",
        sortable: true,
        visible: true,
      },
      {
        text: "Profile Ani-Spyware",
        value: "profile_setting_spyware",
        sortable: true,
        visible: true,
      },
      {
        text: "Profile Vulnerability",
        value: "profile_setting_vulnerability",
        sortable: true,
        visible: true,
      },
      {
        text: "Profile Data Filtering",
        value: "profile_setting_data_filtering",
        sortable: true,
        visible: true,
      },
      {
        text: "Profile Url Filtering",
        value: "profile_setting_url_filtering",
        sortable: true,
        visible: true,
      },
      {
        text: "Profile File Blocking",
        value: "profile_setting_file_blocking",
        sortable: true,
        visible: true,
      },
      {
        text: "Profile Wildfire",
        value: "profile_setting_wildfire",
        sortable: true,
        visible: true,
      },
      {
        text: "Application",
        value: "application",
        sortable: true,
        visible: true,
      },
      { text: "Tag", value: "tag", sortable: true, visible: true },
    ],
  },
  /**
   * @type {Object}
   * @description Auditor CVE table settings
   */
  auditorCveTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      { text: "Vendor", value: "vendor", sortable: true, visible: true },
      {
        text: "Firewall / Group / CM",
        value: "firewall",
        sortable: false,
        visible: true,
      },
      { text: "Active", value: "active", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: false,
      },
    ],
  },
  /**
   * @type {Object}
   * @description Auditor Integrity table settings
   */
  auditorIntegrityTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      { text: "Vendor", value: "vendor", sortable: true, visible: true },
      {
        text: "Firewall / Group",
        value: "firewall",
        sortable: false,
        visible: true,
      },
      { text: "Active", value: "active", sortable: true, visible: true },
      { text: "Severity", value: "severity", sortable: true, visible: true },
      {
        text: "Test integrity",
        value: "test",
        sortable: false,
        visible: true,
        align: "center",
      },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      {
        text: "Groups",
        value: "groups",
        sortable: true,
        visible: true,
      },
      {
        text: "XPATH",
        value: "xpath",
        sortable: true,
        visible: true,
      },
      {
        text: "Content",
        value: "content",
        sortable: true,
        visible: true,
      },
      {
        text: "Custom conditions",
        value: "conditions",
        sortable: true,
        visible: true,
      },
    ],
  },
  auditorTemplatesTable: {
    headers: [
      { text: "Name", value: "title", sortable: true, visible: true },
      { text: "Rules", value: "rules", sortable: true, visible: true },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [],
  },
  auditorTemplateRulesTable: {
    headers: [
      {
        text: "CIS ID",
        value: "cis_id",
        sortable: true,
        visible: true,
        width: 90,
      },
      { text: "Name", value: "title", sortable: true, visible: true },
      { text: "Xpath", value: "xpath", sortable: true, visible: true },
      {
        text: "Severity",
        value: "severity",
        sortable: true,
        visible: true,
        width: 130,
      },
    ],
    expanded: [
      {
        text: "Expected output",
        value: "expected_output",
        sortable: true,
        visible: true,
      },
      {
        text: "Condition second",
        value: "condition_second",
        sortable: true,
        visible: true,
      },
      {
        text: "Fix",
        value: "fix",
        sortable: true,
        visible: true,
      },
      {
        text: "Cli",
        value: "cli",
        sortable: true,
        visible: true,
      },
    ],
  },
  /**
   * @type {Object}
   * @description Auditor Groups table settings
   */
  auditorGroupsTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [],
  },
  monitorEventsThreatsTable: {
    headers: [
      { text: "Created", value: "created_at", sortable: true, visible: true },
      { text: "Miner", value: "from", sortable: true, visible: true },
      { text: "Method", value: "method", sortable: true, visible: true },
      { text: "Source", value: "source", sortable: true, visible: true },
    ],
    expanded: [
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: false,
      },
      {
        text: "Destination",
        value: "destination",
        sortable: true,
        visible: false,
      },
    ],
  },
  monitorEventsSystemTable: {
    headers: [
      {
        text: "Created",
        value: "created_at",
        sortable: true,
        visible: true,
        width: "200px",
      },
      { text: "Object", value: "modul", sortable: true, visible: true },
      { text: "Severity", value: "severity", sortable: true, visible: true },
      { text: "Message", value: "message", sortable: true, visible: true },
    ],
    expanded: [],
  },
  monitorAuditorRulesetConfirmedTable: {
    headers: [
      {
        text: "Firewall",
        value: "firewalls.name",
        sortable: true,
        visible: true,
      },
      {
        text: "Auditor",
        value: "auditors.name",
        sortable: true,
        visible: true,
      },
      {
        text: "Security policy",
        value: "secPolicy",
        sortable: false,
        visible: true,
        width: 125,
      },
      {
        text: "Severity",
        value: "severity",
        sortable: true,
        visible: true,
        width: 110,
      },
      {
        text: "Description",
        value: "message",
        sortable: true,
        visible: true,
      },
      { text: "Datetime", value: "created_at", sortable: true, visible: true },
    ],
    expanded: [
      {
        text: "Comment",
        value: "confirm_comment",
        sortable: true,
        visible: true,
      },
    ],
  },
  monitorAuditorRulesetFixedTable: {
    headers: [
      {
        text: "Firewall",
        value: "firewalls.name",
        sortable: true,
        visible: true,
      },
      {
        text: "Auditor",
        value: "auditors.name",
        sortable: true,
        visible: true,
      },
      {
        text: "Security policy",
        value: "secPolicy",
        sortable: false,
        visible: true,
      },
      {
        text: "Severity",
        value: "severity",
        sortable: true,
        visible: true,
        width: 110,
      },
      {
        text: "Description",
        value: "message",
        sortable: true,
        visible: true,
      },
      { text: "Datetime", value: "created_at", sortable: true, visible: true },
    ],
    expanded: [],
  },
  monitorAuditorCveActiveTable: {
    headers: [
      { text: "Message", value: "message", sortable: true, visible: true },
      {
        text: "Severity",
        value: "severity",
        sortable: true,
        visible: true,
        width: 110,
      },
      {
        text: "Firewall / CM",
        value: "firewalls.name",
        sortable: true,
        visible: true,
      },
      { text: "CVE ID", value: "cve_id", sortable: true, visible: true },
      { text: "Published", value: "published", sortable: true, visible: true },
      { text: "Updated", value: "updated", sortable: true, visible: true },
    ],
    expanded: [],
  },
  monitorAuditorCveConfirmedTable: {
    headers: [
      { text: "Message", value: "message", sortable: true, visible: true },
      {
        text: "Severity",
        value: "severity",
        sortable: true,
        visible: true,
        width: 110,
      },
      {
        text: "Firewall / CM",
        value: "firewalls.name",
        sortable: true,
        visible: true,
      },
      { text: "CVE ID", value: "cve_id", sortable: true, visible: true },
      { text: "Published", value: "published", sortable: true, visible: true },
      { text: "Updated", value: "updated", sortable: true, visible: true },
    ],
    expanded: [
      {
        text: "Comment",
        value: "confirm_comment",
        sortable: true,
        visible: true,
      },
    ],
  },
  monitorAuditorIntegrityActiveTable: {
    headers: [
      {
        text: "Auditor",
        value: "auditors.name",
        sortable: true,
        visible: true,
      },
      {
        text: "Severity",
        value: "severity",
        sortable: true,
        width: 110,
        visible: true,
      },
      {
        text: "Firewall",
        value: "firewalls.name",
        sortable: true,
        visible: true,
      },
      { text: "Xpath", value: "xpath", sortable: true, visible: true },
    ],
    expanded: [
      {
        text: "Reasons for alert",
        value: "reasons",
        sortable: false,
        visible: true,
      },
      { text: "Mask", value: "mask", sortable: false, visible: true },
      { text: "Expected", value: "expected", sortable: true, visible: true },
      { text: "Result", value: "result", sortable: true, visible: true },
      { text: "Diff", value: "diff", sortable: false, visible: true },
      { text: "Hash", value: "hash", sortable: false, visible: false },
    ],
  },
  monitorAuditorIntegrityConfirmedTable: {
    headers: [
      {
        text: "Auditor",
        value: "auditors.name",
        sortable: true,
        visible: true,
      },
      {
        text: "Severity",
        value: "severity",
        sortable: true,
        width: 110,
        visible: true,
      },
      {
        text: "Firewall",
        value: "firewalls.name",
        sortable: true,
        visible: true,
      },
      { text: "Xpath", value: "xpath", sortable: true, visible: true },
    ],
    expanded: [
      {
        text: "Comment",
        value: "confirm_comment",
        sortable: true,
        visible: true,
      },
      {
        text: "Reasons for alert",
        value: "reasons",
        sortable: false,
        visible: true,
      },
      { text: "Mask", value: "mask", sortable: false, visible: true },
      { text: "Expected", value: "expected", sortable: true, visible: true },
      { text: "Result", value: "result", sortable: true, visible: true },
      { text: "Diff", value: "diff", sortable: false, visible: true },
      { text: "Hash", value: "hash", sortable: false, visible: false },
    ],
  },
  monitorAuditorIntegrityHistoryTable: {
    headers: [
      {
        text: "Auditor",
        value: "auditors.name",
        sortable: true,
        visible: true,
      },
      {
        text: "Severity",
        value: "severity",
        sortable: true,
        visible: true,
        width: 110,
      },
      {
        text: "Firewall",
        value: "firewalls.name",
        sortable: true,
        visible: true,
      },
      { text: "Xpath", value: "xpath", sortable: true, visible: true },
      {
        text: "First seen",
        value: "first_seen",
        sortable: true,
        visible: true,
      },
      { text: "Fixed", value: "fixed_date", sortable: true, visible: true },
      { text: "Status", value: "status", sortable: true, visible: true },
    ],
    expanded: [
      {
        text: "Reasons for alert",
        value: "reasons",
        sortable: false,
        visible: true,
      },
      { text: "Mask", value: "mask", sortable: false, visible: true },
      { text: "Expected", value: "expected", sortable: true, visible: true },
      { text: "Result", value: "result", sortable: true, visible: true },
      { text: "Diff", value: "diff", sortable: false, visible: true },
      { text: "Hash", value: "hash", sortable: false, visible: false },
    ],
  },
  monitorEventsSyslogTable: {
    headers: [
      {
        text: "Received At",
        value: "received_at",
        sortable: true,
        visible: true,
      },
      { text: "From", value: "from", sortable: true, visible: true },
      {
        text: "Short Message",
        value: "message_short",
        sortable: true,
        visible: true,
      },
    ],
    expanded: [
      {
        text: "Full Message",
        value: "message_full",
        sortable: true,
        visible: false,
      },
    ],
  },
  /**
   * @type {Object}
   * @description Monitor Interconnector Successful table settings
   */
  monitorInterconnectorSuccessfulTable: {
    headers: [
      { text: "Date", value: "updated_at", sortable: true, visible: true },
      {
        text: "Event ID",
        value: "call_name",
        sortable: true,
        visible: true,
      },
      { text: "Rulename", value: "rulename", sortable: true, visible: true },
      { text: "Miner", value: "miner", sortable: true, visible: true },
      { text: "Firewall", value: "fw", sortable: true, visible: true },
      { text: "Source", value: "src_short", sortable: true, visible: true },
      {
        text: "Destination",
        value: "target_short",
        sortable: true,
        visible: true,
      },
      { text: "Threat", value: "threat", sortable: true, visible: true },
    ],
    expanded: [
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      { text: "Source", value: "src", sortable: true, visible: true },
      { text: "Target", value: "target", sortable: true, visible: true },
      {
        text: "Sent by",
        value: "last_call_user",
        sortable: true,
        visible: true,
      },
      { text: "Internal ID", value: "id", sortable: true, visible: true },
      { text: "Syslog ID", value: "syslog_id", sortable: true, visible: true },
      { text: "API ID", value: "api_id", sortable: true, visible: true },
      { text: "Event Link", value: "eventlink", sortable: true, visible: true },
    ],
  },
  /**
   * @type {Object}
   * @description Monitor Interconnector Unsuccessful table settings
   */
  monitorInterconnectorUnsuccessfulTable: {
    headers: [
      { text: "Date", value: "updated_at", sortable: true, visible: true },
      {
        text: "Event ID",
        value: "call_name",
        sortable: true,
        visible: true,
      },
      { text: "Rulename", value: "rulename", sortable: true, visible: true },
      { text: "Miner", value: "miner", sortable: true, visible: true },
      { text: "Firewall", value: "fw", sortable: true, visible: true },
      { text: "Source", value: "src_short", sortable: true, visible: true },
      {
        text: "Destination",
        value: "target_short",
        sortable: true,
        visible: true,
      },
      { text: "Threat", value: "threat", sortable: true, visible: true },
    ],
    expanded: [
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      { text: "Source", value: "src", sortable: true, visible: true },
      { text: "Target", value: "target", sortable: true, visible: true },
      {
        text: "Sent by",
        value: "last_call_user",
        sortable: true,
        visible: true,
      },
      { text: "Internal ID", value: "id", sortable: true, visible: true },
      { text: "Syslog ID", value: "syslog_id", sortable: true, visible: true },
      { text: "API ID", value: "api_id", sortable: true, visible: true },
      { text: "Event Link", value: "eventlink", sortable: true, visible: true },
    ],
  },
  /**
   * @type {Object}
   * @description Monitor Interconnector Reject table settings
   */
  monitorInterconnectorRejectTable: {
    headers: [
      { text: "Date", value: "updated_at", sortable: true, visible: true },
      {
        text: "Event ID",
        value: "call_name",
        sortable: true,
        visible: true,
      },
      { text: "Rulename", value: "rulename", sortable: true, visible: true },
      { text: "Miner", value: "miner", sortable: true, visible: true },
      { text: "Firewall", value: "fw", sortable: true, visible: true },
      { text: "Source", value: "src_short", sortable: true, visible: true },
      {
        text: "Destination",
        value: "target_short",
        sortable: true,
        visible: true,
      },
      { text: "Threat", value: "threat", sortable: true, visible: true },
    ],
    expanded: [
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      { text: "Source", value: "src", sortable: true, visible: true },
      { text: "Target", value: "target", sortable: true, visible: true },
      {
        text: "Sent by",
        value: "last_call_user",
        sortable: true,
        visible: true,
      },
      { text: "Internal ID", value: "id", sortable: true, visible: true },
      { text: "Syslog ID", value: "syslog_id", sortable: true, visible: true },
      { text: "API ID", value: "api_id", sortable: true, visible: true },
      { text: "Event Link", value: "eventlink", sortable: true, visible: true },
    ],
  },
  /**
   * @type {Object}
   * @description Monitor Interconnector Revoke table settings
   */
  monitorInterconnectorRevokeTable: {
    headers: [
      { text: "Date", value: "updated_at", sortable: true, visible: true },
      {
        text: "Event ID",
        value: "call_name",
        sortable: true,
        visible: true,
      },
      { text: "Rulename", value: "rulename", sortable: true, visible: true },
      { text: "Miner", value: "miner", sortable: true, visible: true },
      { text: "Firewall", value: "fw", sortable: true, visible: true },
      { text: "Source", value: "src_short", sortable: true, visible: true },
      {
        text: "Destination",
        value: "target_short",
        sortable: true,
        visible: true,
      },
      { text: "Threat", value: "threat", sortable: true, visible: true },
    ],
    expanded: [
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      { text: "Source", value: "src", sortable: true, visible: true },
      { text: "Target", value: "target", sortable: true, visible: true },
      {
        text: "Sent by",
        value: "last_call_user",
        sortable: true,
        visible: true,
      },
      { text: "Internal ID", value: "id", sortable: true, visible: true },
      { text: "Syslog ID", value: "syslog_id", sortable: true, visible: true },
      { text: "API ID", value: "api_id", sortable: true, visible: true },
      { text: "Event Link", value: "eventlink", sortable: true, visible: true },
    ],
  },
  interconnectorSecurityPolicyTable: {
    headers: [
      { text: "Date", value: "updated_at", sortable: true, visible: true },
      {
        text: "Security policy name",
        value: "call_name",
        sortable: true,
        visible: true,
      },
      { text: "Rulename", value: "rulename", sortable: true, visible: true },
      { text: "Miner", value: "miner", sortable: true, visible: true },
      { text: "Firewall", value: "fw", sortable: true, visible: true },
      { text: "Active", value: "status", sortable: true, visible: true },
      {
        text: "Source short",
        value: "src_short",
        sortable: true,
        visible: true,
      },
      {
        text: "Target short",
        value: "target_short",
        sortable: true,
        visible: true,
      },
      { text: "Threat", value: "threat", sortable: true, visible: true },
    ],
    expanded: [
      {
        text: "Description",
        value: "description",
        sortable: false,
        visible: true,
      },
      { text: "Source", value: "source", sortable: false, visible: true },
      { text: "Target", value: "target", sortable: false, visible: true },
      {
        text: "Approved by",
        value: "last_call_user",
        sortable: false,
        visible: true,
      },
      { text: "Internal ID", value: "id", sortable: false, visible: true },
      { text: "Syslog ID", value: "syslog_id", sortable: false, visible: true },
      { text: "API ID", value: "api_id", sortable: false, visible: true },
    ],
  },
  interconnectorRulesDefaultTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      { text: "Miner", value: "miner", sortable: true, visible: true },
      {
        text: "Firewall",
        value: "firewall",
        sortable: true,
        visible: true,
        width: 150,
      },
      {
        text: "Mode",
        value: "mode",
        sortable: true,
        visible: true,
        width: 100,
      },
      { text: "List", value: "list", sortable: true, visible: true },
      {
        text: "Allow List",
        value: "allow_list",
        sortable: true,
        visible: true,
        width: 115,
      },
      {
        text: "Deny List",
        value: "deny_list",
        sortable: true,
        visible: true,
        width: 110,
      },
      {
        text: "Allow threats",
        value: "threats",
        sortable: true,
        visible: true,
      },
      { text: "Delete", value: "delete", sortable: false, visible: true },
    ],
    expanded: [
      { text: "Active", value: "active", sortable: false, visible: true },
      { text: "Call", value: "policy_call", sortable: false, visible: true },
      {
        text: "Rollback",
        value: "rollback_call",
        sortable: false,
        visible: true,
      },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      {
        text: "Member Timeout",
        value: "member_timeout",
        sortable: true,
        visible: true,
      },
      { text: "Hitcount", value: "hitcount", sortable: true, visible: true },
    ],
  },
  interconnectorRulesScriptTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      { text: "Miner", value: "miner", sortable: true, visible: true },
      { text: "Active", value: "active", sortable: false, visible: true },
      { text: "Delete", value: "delete", sortable: false, visible: true },
    ],
    expanded: [
      { text: "Script", value: "customQuery", sortable: false, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: false,
        visible: true,
      },
    ],
  },
  settingsMinerTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      {
        text: "IP address",
        value: "ip_address",
        sortable: true,
        visible: true,
      },
      { text: "Status", value: "onlinestatus", sortable: true, visible: true },
      { text: "Parser", value: "parser_name", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      { text: "Source", value: "source_name", sortable: true, visible: true },
      {
        text: "Last Message",
        value: "lastMessageTime",
        sortable: true,
        visible: true,
      },
      { text: "Lograte", value: "lograte", sortable: true, visible: true },
    ],
  },
  settingsFirewallGroupsTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      {
        text: "Firewalls",
        value: "firewallNames",
        sortable: true,
        visible: true,
      },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
    ],
  },
  settingsFirewallTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      {
        text: "IP address",
        value: "ip_address",
        sortable: true,
        visible: true,
      },
      {
        text: "Vendor",
        value: "vendor",
        sortable: true,
        visible: true,
      },
      { text: "Status", value: "onlinestatus", sortable: true, visible: true },
      {
        text: "SW Version",
        value: "sw_version",
        sortable: true,
        visible: true,
      },
      { text: "Model", value: "model", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      {
        text: "Zones",
        value: "zones_interfaces",
        sortable: false,
        visible: true,
      },
      { text: "EDL Lists", value: "edl_list", sortable: false, visible: true },
      {
        text: "Last commit",
        value: "last_commit",
        sortable: false,
        visible: true,
      },
      {
        text: "Security Policy",
        value: "security_policy_count",
        sortable: false,
        visible: true,
      },
      {
        text: "Available auditor types",
        value: "auditor_types",
        sortable: false,
        visible: true,
      },
      {
        text: "Panorama",
        value: "ip_panorama",
        sortable: false,
        visible: true,
      },
      {
        text: "Ruleset status",
        value: "ruleset_status",
        sortable: true,
        visible: true,
      },
      { text: "Serial", value: "serial", sortable: false, visible: true },
      {
        text: "System Info",
        value: "system_info",
        sortable: false,
        visible: true,
      },
    ],
  },
  settingsCentralManagementTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      {
        text: "IP address",
        value: "ip_address",
        sortable: true,
        visible: true,
      },
      {
        text: "Serial",
        value: "serial",
        sortable: true,
        visible: true,
      },
      {
        text: "Version",
        value: "version",
        sortable: true,
        visible: true,
      },
      {
        text: "Status",
        value: "status",
        sortable: true,
        visible: true,
      },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [
      {
        text: "Vendor",
        value: "vendor",
        sortable: true,
        visible: true,
      },
    ],
  },
  settingsParserTable: {
    headers: [
      {
        text: "Name",
        value: "name",
        width: 300,
        sortable: true,
        visible: true,
      },
      {
        text: "Syslog Type",
        value: "syslog_type",
        sortable: true,
        visible: true,
        width: 125,
      },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      { text: "Threats", value: "tags", sortable: true, visible: true },
      { text: "Lists", value: "lists", sortable: true, visible: true },
    ],
    expanded: [],
  },
  settingsListenerTable: {
    headers: [
      { text: "Title", value: "title", sortable: true, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      { text: "Script", value: "file_name", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [],
  },
  settingsIntegrationTable: {
    headers: [
      { text: "Title", value: "name", sortable: true, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      { text: "Fill", value: "fill", sortable: true, visible: true },
    ],
    expanded: [
      { text: "Key", value: "key", sortable: true, visible: true },
      {
        text: "Detail description",
        value: "detail",
        sortable: true,
        visible: true,
      },
    ],
  },
  settingsApiPaloAltoTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      {
        text: "Template",
        value: "template",
        sortable: true,
        visible: true,
        width: 110,
      },
      { text: "Url", value: "url", sortable: true, visible: true },
      { text: "Variable", value: "variable", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [
      { text: "Url full", value: "url_full", sortable: true, visible: true },
      { text: "Version", value: "version", sortable: true, visible: true },
      { text: "Data", value: "data", sortable: true, visible: true },
      {
        text: "Variable full",
        value: "variable_full",
        sortable: true,
        visible: true,
      },
    ],
  },
  settingsApiFortinetTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      {
        text: "Template",
        value: "template",
        sortable: true,
        visible: true,
        width: 110,
      },
      { text: "Url", value: "url", sortable: true, visible: true },
      { text: "Variable", value: "variable", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [
      { text: "Url full", value: "url_full", sortable: true, visible: true },
      { text: "Version", value: "version", sortable: true, visible: true },
      { text: "Data", value: "data", sortable: true, visible: true },
      {
        text: "Variable full",
        value: "variable_full",
        sortable: true,
        visible: true,
      },
    ],
  },
  settingsApiCheckpointTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      { text: "Template", value: "template", sortable: true, visible: true },
      { text: "Url", value: "url", sortable: true, visible: true },
      { text: "Variable", value: "variable", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [
      { text: "Url full", value: "url_full", sortable: true, visible: true },
      { text: "Version", value: "version", sortable: true, visible: true },
      { text: "Data", value: "data", sortable: true, visible: true },
      {
        text: "Variable full",
        value: "variable_full",
        sortable: true,
        visible: true,
      },
    ],
  },
  settingsApiSophosTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      { text: "Template", value: "template", sortable: true, visible: true },
      { text: "Url", value: "url", sortable: true, visible: true },
      { text: "Variable", value: "variable", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [
      { text: "Url full", value: "url_full", sortable: true, visible: true },
      { text: "Version", value: "version", sortable: true, visible: true },
      { text: "Data", value: "data", sortable: true, visible: true },
      {
        text: "Variable full",
        value: "variable_full",
        sortable: true,
        visible: true,
      },
    ],
  },
  settingsApiCustomTable: {
    headers: [
      { text: "Name", value: "name", sortable: true, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      { text: "Url", value: "url", sortable: true, visible: true },
      { text: "Variable", value: "variable", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [
      { text: "Data", value: "data", sortable: true, visible: true },
      {
        text: "Variable full",
        value: "variable_full",
        sortable: true,
        visible: true,
      },
    ],
  },
  /**
   * @type {Object}
   * @description Awaiting approval Security policy table settings
   */
  awaitingApprovalSecurityPolicyTable: {
    headers: [
      { text: "Date", value: "created_at", sortable: true, visible: true },
      { text: "Rules", value: "rules", sortable: true, visible: true },
      { text: "Firewall", value: "fw", sortable: true, visible: true },
      { text: "Source", value: "src_short", sortable: true, visible: true },
      {
        text: "Destination",
        value: "target_short",
        sortable: true,
        visible: true,
      },
      { text: "Threat", value: "tag", sortable: true, visible: true },
    ],
    expanded: [
      { text: "Source full", value: "src", sortable: true, visible: true },
      {
        text: "Destination full",
        value: "target",
        sortable: true,
        visible: true,
      },
      {
        text: "Internal ID",
        value: "id",
        sortable: true,
        visible: true,
      },
      { text: "Syslog ID", value: "syslog_id", sortable: true, visible: true },
      { text: "Message", value: "message_full", sortable: true, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
    ],
  },
  /**
   * @type {Object}
   * @description Awaiting approval Data feeds table settings
   */
  awaitingApprovalDataFeedsTable: {
    headers: [
      { text: "Date", value: "created_at", sortable: true, visible: true },
      { text: "Rules", value: "rules", sortable: true, visible: true },
      { text: "Source", value: "src_short", sortable: true, visible: true },
      {
        text: "Destination",
        value: "target_short",
        sortable: true,
        visible: true,
      },
      { text: "Threat", value: "tag", sortable: true, visible: true },
    ],
    expanded: [
      { text: "Source full", value: "src", sortable: true, visible: true },
      {
        text: "Destination full",
        value: "target",
        sortable: true,
        visible: true,
      },
      {
        text: "Internal ID",
        value: "id",
        sortable: true,
        visible: true,
      },
      { text: "Syslog ID", value: "syslog_id", sortable: true, visible: true },
      { text: "Message", value: "message_full", sortable: true, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
    ],
  },
  /**
   * @type {Object}
   * @description Data feeds Custom All table settings
   */
  dataFeedsAddrListCustomAllTable: {
    headers: [
      { text: "ID", value: "id", sortable: true, visible: true },
      { text: "Title", value: "title", sortable: true, visible: true },
      { text: "Lifetime", value: "lifetime", sortable: true, visible: true },
      { text: "Count", value: "count", sortable: true, visible: true },
      { text: "Last Access", value: "lastshow", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [
      { text: "Allow", value: "rule_name", sortable: false, visible: false },
    ],
  },
  /**
   * @type {Object}
   * @description Data feeds Connection table settings
   */
  dataFeedsAddrListConnectionTable: {
    headers: [
      { text: "Title", value: "title", sortable: true, visible: true },
      { text: "List Name", value: "list", sortable: true, visible: true },
      { text: "Call Name", value: "call", sortable: true, visible: true },
      { text: "Target", value: "target", sortable: true, visible: true },
      { text: "Interval", value: "interval", sortable: true, visible: true },
      { text: "Last run", value: "last_run", sortable: true, visible: true },
      { text: "Finding", value: "finding", sortable: true, visible: true },
      { text: "Active", value: "active", sortable: false, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [],
  },
  /**
   * @type {Object}
   * @description Data feeds Custom Show src table settings
   */
  dataFeedsAddrListCustomSrcTable: {
    headers: [
      { text: "IP", value: "ip_address", sortable: true, visible: true },
      { text: "Rule", value: "rule", sortable: true, visible: true },
      { text: "Lifetime", value: "lifetime", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [],
  },
  /**
   * @type {Object}
   * @description Data feeds Custom Show dst table settings
   */
  dataFeedsAddrListCustomDstTable: {
    headers: [
      { text: "IP", value: "ip_address", sortable: true, visible: true },
      { text: "Rule", value: "rule", sortable: true, visible: true },
      { text: "Lifetime", value: "lifetime", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [],
  },
  /**
   * @type {Object}
   * @description Data feeds Predefined All table settings
   */
  dataFeedsAddrListPredefinedAllTable: {
    headers: [
      { text: "Title", value: "title", sortable: true, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      {
        text: "Update Period",
        value: "update_period",
        sortable: true,
        visible: true,
      },
      {
        text: "API Last Update",
        value: "api_last_update",
        sortable: true,
        visible: true,
      },
      {
        text: "Last Check",
        value: "last_update",
        sortable: true,
        visible: true,
      },
      { text: "Count", value: "count", sortable: true, visible: true },
    ],
    expanded: [],
  },
  /**
   * @type {Object}
   * @description Data feeds Domain all table settings
   */
  dataFeedsDomainListAllTable: {
    headers: [
      { text: "Title", value: "title", sortable: true, visible: true },
      { text: "Lifetime", value: "lifetime", sortable: true, visible: true },
      { text: "Count", value: "count", sortable: true, visible: true },
      { text: "Last Access", value: "lastshow", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [],
  },
  /**
   * @type {Object}
   * @description Data feeds Domain show table settings
   */
  dataFeedsDomainListShowTable: {
    headers: [
      { text: "Domain", value: "domain", sortable: true, visible: true },
      {
        text: "Last show",
        value: "lastshow",
        sortable: true,
        visible: true,
      },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [],
  },
  /**
   * @type {Object}
   * @description Data feeds Domain predefined all table settings
   */
  dataFeedsDomainListPredefinedAllTable: {
    headers: [
      { text: "Title", value: "title", sortable: true, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      {
        text: "Update Period",
        value: "update_period",
        sortable: true,
        visible: true,
      },
      {
        text: "API Last Update",
        value: "api_last_update",
        sortable: true,
        visible: true,
      },
      {
        text: "Last Check",
        value: "last_update",
        sortable: true,
        visible: true,
      },
      { text: "Count", value: "count", sortable: true, visible: true },
    ],
    expanded: [],
  },
  /**
   * @type {Object}
   * @description Data feeds Url all table settings
   */
  dataFeedsUrlListCustomAllTable: {
    headers: [
      { text: "Title", value: "title", sortable: true, visible: true },
      { text: "Lifetime", value: "lifetime", sortable: true, visible: true },
      { text: "Count", value: "count", sortable: true, visible: true },
      { text: "Last Access", value: "lastshow", sortable: true, visible: true },
    ],
    expanded: [],
  },
  /**
   * @type {Object}
   * @description Data feeds Url predefined all table settings
   */
  dataFeedsUrlListPredefinedAllTable: {
    headers: [
      { text: "Title", value: "title", sortable: true, visible: true },
      {
        text: "Description",
        value: "description",
        sortable: true,
        visible: true,
      },
      {
        text: "Update Period",
        value: "update_period",
        sortable: true,
        visible: true,
      },
      {
        text: "API Last Update",
        value: "api_last_update",
        sortable: true,
        visible: true,
      },
      {
        text: "Last Check",
        value: "last_update",
        sortable: true,
        visible: true,
      },
      { text: "Count", value: "count", sortable: true, visible: true },
    ],
    expanded: [],
  },
  /**
   * @type {Object}
   * @description Data feeds Url show table settings
   */
  dataFeedsUrlListCustomShowTable: {
    headers: [
      { text: "Url", value: "url", sortable: true, visible: true },
      {
        text: "Last show",
        value: "lastshow",
        sortable: true,
        visible: true,
      },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [],
  },
  systemBackupRemoteTable: {
    headers: [
      { text: "Server", value: "server", sortable: true, visible: true },
      { text: "Protocol", value: "protocol", sortable: true, visible: true },
      { text: "Frequency", value: "frequency", sortable: true, visible: true },
      { text: "Type", value: "type", sortable: true, visible: true },
      { text: "Last execute", value: "last_exec", sortable: true, visible: true },
      { text: "Active", value: "active", sortable: true, visible: true },
      {
        text: "Delete",
        value: "delete",
        sortable: false,
        visible: true,
        align: "end",
      },
    ],
    expanded: [],
  },
};
