import request from "@/utils/request";

export function exportConfiguration(data) {
  return request({
    url: "api/system/export/configuration",
    method: "post",
    data: data,
    timeout: 300000,
  });
}

export function getBackupConfiguration() {
  return request({
    url: "api/system/export/configuration",
    method: "get",
  });
}

export function downloadBackupConfiguration(name) {
  return request({
    url: "api/system/export/configuration/" + name,
    method: "get",
  });
}

export function exportBackupFull() {
  return request({
    url: "api/system/export/fullbackup",
    method: "post",
    timeout: 300000,
  });
}

export function getBackupFull() {
  return request({
    url: "api/system/export/fullbackup",
    method: "get",
  });
}

export function downloadBackupFull(name) {
  return request({
    url: "api/system/export/fullbackup/" + name,
    method: "get",
  });
}

export function importConfiguration(data) {
  return request({
    url: "api/system/import/configuration",
    method: "post",
    data: data,
    timeout: 300000,
  });
}

export function importFullBackup(data) {
  return request({
    url: "api/system/import/fullbackup",
    method: "post",
    data: data,
    timeout: 300000,
  });
}

export function setRemoteBackupStatus(data, id) {
  return request({
    url: "api/system/remote_backup_status/" + id,
    method: "put",
    data: data,
  });
}
export function storeRemoteBackupSetting(data) {
  return request({
    url: "api/system/remote_backup_setting",
    method: "post",
    data: data,
  });
}

export function updateRemoteBackupSetting(data, id) {
  return request({
    url: "api/system/remote_backup_setting/" + id,
    method: "put",
    data: data,
  });
}

export function getRemoteBackupSetting(id) {
  return request({
    url: "api/system/remote_backup_setting/" + id,
    method: "get",
  });
}

export function getRemoteBackupSettings(page, size, order, by) {
  const orderBy = order && by ? "&order=" + order + "&by=" + by : "";
  return request({
    url: "api/system/remote_backup_setting?page=" + page + "&size=" + size + orderBy,
    method: "get",
  });
}

export function deleteRemoteBackupSetting(id) {
  return request({
    url: "api/system/remote_backup_setting/" + id,
    method: "delete",
  });
}

export function getRemoteBackupProtocols() {
  return request({
    url: "api/system/remote_backup_protocols",
    method: "get",
  });
}

export function getRemoteBackupFrequencies() {
  return request({
    url: "api/system/remote_backup_frequencies",
    method: "get",
  });
}

export function getRemoteBackupTypes() {
  return request({
    url: "api/system/remote_backup_types",
    method: "get",
  });
}

