import defaultSettings from "@/settings";

const {
  fixedHeader,
  defaultImages,
  appBackground,
  screenWidth,
  previousUrl,
  apiUrl,
  keywordAddForm,
  idAddForm,
  changeInAddForm,
  activeLicense,
  initialSetup,
  defaultTables,
} = defaultSettings;

const state = {
  fixedHeader: fixedHeader,
  defaultImages: defaultImages,
  appBackground: appBackground,
  screenWidth: screenWidth,
  previousUrl: previousUrl,
  apiUrl: apiUrl,

  idAddForm: idAddForm,
  keywordAddForm: keywordAddForm,
  changeInAddForm: changeInAddForm,

  activeLicense: activeLicense,
  initialSetup: initialSetup,

  defaultTables: defaultTables,
};

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value;
    }
  },
};

const actions = {
  changeSetting({ commit }, data) {
    commit("CHANGE_SETTING", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
