<template>
  <v-menu v-model="open" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" class="px-2">
        <v-avatar size="20" class="br-0">
          <img
            :src="
              $vuetify.theme.dark
                ? location + '/images/icons/white/user.svg'
                : location + '/images/icons/dark/user.svg'
            "
            alt="Add"
          />
        </v-avatar>
      </v-btn>
    </template>
    <v-list class="font-ubuntu-bold font-size-12" dense>
      <v-list-item> Username: {{ username }} </v-list-item>
      <v-list-item> Role: {{ role }} </v-list-item>
      <v-list-item
        @click="editAccount()"
        :ripple="false"
        class="cursor-pointer"
      >
        Edit Account
      </v-list-item>
      <!--       <v-list-item @click="resetPassword()" class="cursor-pointer">
        Change Password
      </v-list-item> -->
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    location: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      open: false,
      username: localStorage.getItem("username"),
      role: localStorage.getItem("role"),
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  methods: {
    editAccount() {
      this.keywordAddForm = "user";
      this.idAddForm = 0;
    },
    /*     resetPassword() {
      this.keywordAddForm = "user";
      this.idAddForm = 1;
    }, */
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  min-width: 40px !important;
  opacity: 0.7;
  transition-duration: 0.3s;
}
.v-btn:hover {
  opacity: 1;
}
.v-list-item {
  transition: 0.2s hover;
}

.v-list-item:hover:before {
  opacity: 0.08;
}

.v-list-item:focus:before {
  opacity: 0.1;
}
</style>
