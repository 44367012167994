var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"app-bar-container",class:!_vm.component ? 'py-0' : '',attrs:{"fluid":""}},[(_vm.component)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"py-0 app-bar-block",attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"popout":"","readonly":_vm.disabled},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('h1',{staticClass:"text-uppercase font-size-25",domProps:{"innerHTML":_vm._s(_vm.$t('sidebar.' + _vm.$route.name, { title: _vm.title }))}})]),_c('v-col',{staticClass:"text-right pt-2 pb-1",attrs:{"cols":"4"}},[(!_vm.disabled)?_c('v-avatar',{staticClass:"br-0 mr-1",attrs:{"size":"30"}},[_c('img',{attrs:{"src":_vm.$vuetify.theme.dark
                        ? _vm.location + '/images/icons/white/setup-white.png'
                        : _vm.location + '/images/icons/dark/setup.png',"alt":"Add"}})]):_vm._e(),(!_vm.disabled)?_c('v-avatar',{staticClass:"br-0 group-append-icon",attrs:{"size":"25"}},[_c('img',{attrs:{"src":_vm.$vuetify.theme.dark
                        ? _vm.location +
                          '/images/icons/white/sidebar/arrow-bottom.svg'
                        : _vm.location +
                          '/images/icons/dark/sidebar/arrow-bottom.svg',"alt":"Add"}})]):_vm._e()],1)],1)],1),_c('v-expansion-panel-content',[_c(_vm.component,{tag:"component",attrs:{"process":_vm.process},on:{"default":function($event){return _vm.setDefault($event)}}})],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }