import request from "@/utils/request";

export function login(data) {
  return request({
    url: "api/auth/login",
    method: "post",
    data: data,
  });
}
export function verify(data) {
  return request({
    url: "api/auth/verify",
    method: "post",
    data: data,
  });
}
export function logout() {
  return request({
    url: "api/auth/logout",
    method: "get",
  });
}
export function getInfo() {
  return request({
    url: "api/auth/userInfo",
    method: "get",
  });
}
export function resetPassword(data) {
  return request({
    url: "api/password/reset",
    method: "post",
    data: data,
  });
}
export function getPasswordResetToken(data) {
  return request({
    url: "api/password/getToken",
    method: "post",
    data: data,
  });
}
export function checkPasswordResetToken(data) {
  return request({
    url: "api/password/checkToken",
    method: "post",
    data: data,
  });
}
