import Layout from "@/layout";

const awaitingApprovalRoutes = {
  path: "/awaiting_approval",
  name: "awaitingApproval",
  component: Layout,
  meta: {
    requiresAuth: true,
    icon: "awaiting-approval.svg",
    permission: ["view menu awaitingapproval"],
  },
  redirect: "/awaiting_approval/show",
  children: [
    {
      path: "show",
      component: () => import("@/views/awaitingApproval/index"),
      name: "awaitingApproval",
      meta: {
        active: ["awaitingApproval"],
        permission: ["view menu awaitingapproval"],
      },
      redirect: "/awaiting_approval/show/security_policy",
      hidden: true,
      children: [
        {
          path: "security_policy",
          component: () => import("@/views/awaitingApproval/secPolicy"),
          name: "secPolicyAlert",
          meta: {
            id: "secPolicyAlert",
            active: ["awaitingApproval", "secPolicyAlert"],
            permission: ["view awaitingsecuitypolicy"],
          },
          hidden: true,
        },
        {
          path: "data_feeds",
          component: () => import("@/views/awaitingApproval/dataFeeds"),
          name: "dataFeedsAlert",
          meta: {
            id: "dataFeedsAlert",
            active: ["awaitingApproval", "dataFeedsAlert"],
            permission: ["view awaitingdatafeed"],
          },
          hidden: true,
        },
      ],
    },
  ],
};

export default awaitingApprovalRoutes;
