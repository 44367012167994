import request from '@/utils/request';

export function storeRole(data) {
  return request({
    url: 'api/system/role',
    method: 'post',
    data: data,
  });
}

export function updateRole(data, id) {
  return request({
    url: 'api/system/role/' + id,
    method: 'put',
    data: data,
  });
}

export function getRole(id) {
  return request({
    url: 'api/system/role/' + id,
    method: 'get',
  });
}

export function getRoles(page, size) {
  return request({
    url: 'api/system/role?page=' + page + '&size=' + size,
    method: 'get',
  });
}

export function deleteRole(id) {
  return request({
    url: 'api/system/role/' + id,
    method: 'delete',
  });
}

export function getRoleList() {
  return request({
    url: 'api/system/roleList/',
    method: 'get',
  });
}

export function getRolePermission(id) {
  return request({
    url: 'api/system/rolePermission/' + id,
    method: 'get',
  });
}