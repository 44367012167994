import request from '@/utils/request';

export function parserGetList() {
  return request({
    url: 'api/settings/parsers/list',
    method: 'get',
  });
}

export function parserGetByType() {
  return request({
    url: 'api/settings/parsertype',
    method: 'get',
  });
}

export function getParser(id) {
  return request({
    url: 'api/settings/parser/' + id,
    method: 'get',
  });
}

export function storeParser(data) {
  return request({
    url: 'api/settings/parser/store',
    method: 'post',
    data: data,
  });
}

export function updateParser(data, id) {
  return request({
    url: 'api/settings/parser/' + id,
    method: 'post',
    data: data,
  });
}

export function getParsers() {
  return request({
    url: 'api/settings/parser',
    method: 'get',
  });
}

export function parserTypeGetList() {
  return request({
    url: 'api/settings/parsertypelist',
    method: 'get',
  });
}
