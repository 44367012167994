import request from "@/utils/request";

export function storeAuditor(data) {
  return request({
    url: "api/auditor/store",
    method: "post",
    data: data,
  });
}

export function updateAuditor(data, id) {
  return request({
    url: "api/auditor/update/" + id,
    method: "post",
    data: data,
  });
}

export function getAuditor(id) {
  return request({
    url: "api/auditor/" + id,
    method: "get",
  });
}

export function getAuditors(page, size, order, by, search) {
  const orderBy = order && by ? "&order=" + order + "&by=" + by : "";
  const hasSearch = search ? "&searchTerm=" + search.searchTerm + "&advancedMode=" + (search.advancedMode ? 1 : 0) : '';
  return request({
    url: "api/auditor/ruleset?page=" + page + "&size=" + size + orderBy + hasSearch,
    method: "post",
  });
}

export function getAuditorsList() {
  return request({
    url: "api/auditor/ruleset",
    method: "get",
  });
}

export function getAuditorFwsByVendor(data) {
  return request({
    url: "api/auditor/findfw",
    method: "post",
    data: data,
  });
}

export function deleteAuditor(id) {
  return request({
    url: "api/auditor/destroy/" + id,
    method: "delete",
  });
}

export function testAuditorRuleset(id, data) {
  return request({
    url: "api/auditor/ruleset/test/" + id,
    method: "post",
    data: data,
  });
}
