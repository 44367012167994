<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import echarts from "echarts";
require("@/plugins/echarts/lightTheme");
require("@/plugins/echarts/darkTheme");
import { debounce } from "@/utils";

export default {
  props: {
    id: {
      type: String,
      default: "dashboard-auditor-alert-score-chart-widget",
    },
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "224px",
    },
    xAxisData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    legend: {
      type: Object,
      default: function () {
        return {
          data: ["Devices", "Rules", "Alerts"],
          selected: {
            Devices: false,
            Rules: false,
            Alerts: true,
          },
        };
      },
    },
    data: {
      type: Array,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    "$vuetify.theme.dark": {
      immediate: true,
      deep: true,
      handler() {
        if (this.chart) {
          this.chart.dispose();
          this.chart = null;
          this.initChart();
        }
      },
    },
    data: {
      immediate: true,
      deep: true,
      handler() {
        if (this.chart) {
          this.chart.dispose();
          this.chart = null;
        }
        this.$nextTick(() => {
          this.initChart();
        });
      },
    },
    refresh: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          if (this.chart) {
            this.chart.dispose();
            this.chart = null;
          }
          this.$nextTick(() => {
            this.initChart();
          });
        }
      },
    },
  },
  mounted() {
    this.initChart();
    this.__resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.__resizeHandler);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      const element = document.getElementById(this.id);
      if (this.$vuetify.theme.dark) {
        this.chart = echarts.init(element, "darkTheme");
      } else {
        this.chart = echarts.init(element, "lightTheme");
      }

      this.chart.setOption({
        xAxis: {
          show: true,
          type: "category",
          axisLabel: {
            formatter: (value) => {
              const date = new Date(value);
              if (date.getDate() === new Date().getDate()) {
                // if it's the current date, display only the time
                return date.toLocaleTimeString();
              } else {
                // otherwise, display the date and time
                return `{a|${date.toLocaleDateString()}} {b|${date.toLocaleTimeString(
                  [],
                  { hour: "numeric", minute: "2-digit", hour12: false }
                )}}`;
              }
            },
            rich: {
              a: {
                fontSize: 10,
              },
              b: {
                fontSize: 8,
              },
            },
          },
          data: this.xAxisData,
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: 30,
          bottom: 20,
          left: 30,
          right: 20,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            const date = new Date(params[0].name);
            let text = `<b>Run at: ${date.toLocaleDateString()} ${date.toLocaleTimeString()}</b><hr/>`;
            params.forEach(
              (param) =>
                (text += `<span style="color:${param.color};">${param.seriesName}: ${param.value}</span><br />`)
            );
            return text;
          },
        },
        legend: this.legend,
        series: this.data,
      });
    },
  },
};
</script>
