<template>
  <v-row class="mt-0 mx-0">
    <v-col cols="12" class="pb-0 px-6">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="data"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-select="data.length >= 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:header>
          <thead class="v-data-table-header additional-row">
            <tr>
              <th colspan="1" />
              <th colspan="2" class="text-center">Security Policy</th>
              <th colspan="2" class="text-center">Source</th>
              <th colspan="2" class="text-center">Destination</th>
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
              <th colspan="1" />
            </tr>
          </thead>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | moment("YYYY-MM-DD HH:mm:ss") }}
        </template>
        <template v-slot:item.secPolicy="{ item }">
          {{ item.firewall_policy ? item.firewall_policy : "-" }}
        </template>
        <template v-slot:item.idUuid="{ item }">
          {{ item.firewall_policy_id_seq ? item.firewall_policy_id_seq : "-" }} <br />
          {{ item.firewall_policy_uuid ? item.firewall_policy_uuid : "-" }}
        </template>
        <template v-slot:item.srcZone="{ item }">
          <div v-html="item['src_interface_zone']" />
        </template>
        <template v-slot:item.srcInterface="{ item }">
          <div v-html="item['src_interface_zone']" />
        </template>
        <template v-slot:item.srcAddr="{ item }">
          <div v-html="item.src_address" />
        </template>
        <template v-slot:item.dstZone="{ item }">
          <div v-html="item['dst_interface_zone']" />
        </template>
        <template v-slot:item.dstInterface="{ item }">
          <div v-html="item['dst_interface_zone']" />
        </template>
        <template v-slot:item.dstAddr="{ item }">
          <div v-html="item.dst_address" />
        </template>
        <template v-slot:item.service="{ item }">
          <div v-html="item.service" />
        </template>
        <template v-slot:item.tag="{ item }">
          <div v-html="item.tag" />
        </template>
        <template v-slot:item.logStart="{ item }">
          <div v-html="item.log_start" />
        </template>
        <template v-slot:item.logEnd="{ item }">
          <div v-html="item.log_end" />
        </template>
        <template v-slot:item.logSetting="{ item }">
          <div v-html="item.log_setting" />
        </template>
        <template v-slot:item.profileSetting="{ item }">
          <div v-html="item.profile_setting" />
        </template>
        <template v-slot:item.antivirus="{ item }">
          <div v-html="item.profile_setting_virus" />
        </template>
        <template v-slot:item.spyware="{ item }">
          <div v-html="item.profile_setting_spyware" />
        </template>
        <template v-slot:item.vulnerability="{ item }">
          <div v-html="item.profile_setting_vulnerability" />
        </template>
        <template v-slot:item.wildfire="{ item }">
          <div v-html="item.profile_setting_wildfire" />
        </template>
        <template v-slot:item.urlFiltering="{ item }">
          <div v-html="item.profile_setting_url_filtering" />
        </template>
        <template v-slot:item.fileBlocking="{ item }">
          <div v-html="item.profile_setting_file_blocking" />
        </template>
        <template v-slot:item.dataFiltering="{ item }">
          <div v-html="item.profile_setting_data_filtering" />
        </template>
        <template v-slot:item.app="{ item }">
          <div v-html="item.application" />
        </template>
        <template v-slot:item.effectiveApplication="{ item }">
          <div v-html="item.effective_application" />
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    vendorId: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      selected: [],
      location: "",

      page: 1,
      pageTotalCount: 1,
      size: 1,

      sizes: [5, 10, 25, 50, 100, 200],
      severityColors: ["neutral-3", "good-3", "medium-3", "bad-3"],
      severityNames: ["Low", "Medium", "High", "Critical"],
    };
  },
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit("select", newValue);
      },
    },
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== this.sizes.indexOf(this.tableSize)) {
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    loading: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) {
          this.selected = [];
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.synapsa-table.theme--dark ::v-deep {
  background-color: #1e1e1e96 !important;
  box-shadow: 13px 13px 30px rgb(0 0 0 / 30%);
  tbody:not(.expanded) {
    tr:nth-of-type(even):not(.expand-tr),
    tr:nth-of-type(even):not(.expand-tr):hover {
      background-color: #5e5e6099 !important;
    }
    tr:nth-of-type(even):not(.expand-tr):hover {
      background-color: #5e5e6099 !important;
    }
  }
}

.synapsa-table.theme--light ::v-deep {
  background-color: #ffffff6e !important;
  box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
  tbody:not(.expanded) {
    tr:nth-of-type(even):not(.expand-tr),
    tr:nth-of-type(even):not(.expand-tr):hover {
      background-color: #fff !important;
    }
    tr:nth-of-type(even):not(.expand-tr):hover {
      background-color: #fff !important;
    }
  }
}
.synapsa-table ::v-deep {
  th:not(:first-child) {
    min-width: 150px !important;
  }
}
.synapsa-table.theme--dark ::v-deep {
  td:not(:last-child),
  th:not(:last-child) {
    border-right: thin solid hsla(0, 0%, 100%, 0.12);
  }
  thead:nth-child(2) th {
    border-bottom: none !important;
    color: #fff !important;
  }
  thead:nth-child(2) th:nth-child(2),
  thead:nth-child(2) th:nth-child(3),
  thead:nth-child(2) th:nth-child(4) {
    border-bottom: thin solid hsla(0, 0%, 100%, 0.12) !important;
  }
  tbody td:nth-child(n + 4):nth-child(-n + 7) {
    background-color: #3e3e3e;
  }
}
.synapsa-table.theme--light ::v-deep {
  td:not(:last-child),
  th:not(:last-child) {
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
  thead:nth-child(2) th {
    border-bottom: none !important;
    color: #3d3d3f !important;
  }
  thead:nth-child(2) th:nth-child(2),
  thead:nth-child(2) th:nth-child(3),
  thead:nth-child(2) th:nth-child(4) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  }
  tbody td:nth-child(n + 4):nth-child(-n + 7) {
    background-color: #f4f4f4;
  }
}
</style>
