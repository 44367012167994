var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list-group',{class:_vm.childrenActive() ? 'active' : '',attrs:{"value":_vm.open,"append-icon":'',"color":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-avatar',{staticClass:"br-0",attrs:{"size":"20"}},[_c('img',{attrs:{"src":_vm.childrenActive()
              ? _vm.location + '/images/icons/green/sidebar/' + _vm.route.icon
              : _vm.location + _vm.iconRoute + _vm.route.icon,"alt":"Add"}})])],1),_c('v-list-item-title',{staticClass:"font-roboto-condensed-regular",class:_vm.childrenActive() ? 'primary--text' : ''},[_vm._v(_vm._s(_vm.$t("sidebar." + _vm.route.name))+" "),_c('v-avatar',{staticClass:"br-0 float-right pb-1 group-append-icon",attrs:{"size":"18"}},[_c('img',{attrs:{"src":_vm.childrenActive()
              ? _vm.location + '/images/icons/green/sidebar/arrow-bottom.svg'
              : _vm.location + _vm.iconRoute + 'arrow-bottom.svg',"alt":"Add"}})]),_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"d-none"}},[(
            _vm.route.name === 'monitor' &&
            _vm.alertCount.total > 0 &&
            _vm.showAlertCount
          )?_c('div',{staticClass:"alert-count mr-1"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"absolute":"","indeterminate":"","width":1,"size":"20","color":"white"}}):_c('span',[_vm._v(" "+_vm._s(_vm.alertCount.total < 10000 ? _vm.alertCount.total : ">9999")+" ")])],1):_vm._e()])],1)]},proxy:true}])},[_c('div',{staticClass:"list-group-body"},_vm._l((_vm.route.children),function(child){return _c('v-list-item',{key:child.id,staticClass:"pl-10",class:[
        _vm.active.includes(child.id) ? 'active' : '',
        _vm.alertCount.interconnector > 999 ? 'pl-10' : 'pl-12',
      ],attrs:{"to":child.route,"color":"#fff","link":""}},[_c('v-list-item-icon',[_c('v-avatar',{staticClass:"br-0",attrs:{"size":"20"}},[_c('img',{attrs:{"src":_vm.active.includes(child.id)
                ? _vm.location + '/images/icons/green/sidebar/' + child.icon
                : _vm.location + '/images/icons/white/sidebar/' + child.icon,"alt":"Add"}})])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-roboto-condensed-regular"},[_vm._v(_vm._s(_vm.$t("sidebar." + child.name))+" "),_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"d-none"}},[(
                child.name === 'auditorAlert' &&
                _vm.alertCount.auditor > 0 &&
                _vm.showAlertCount
              )?_c('div',{staticClass:"alert-count"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"absolute":"","indeterminate":"","width":1,"size":"20","color":"white"}}):_c('span',[_vm._v(" "+_vm._s(_vm.alertCount.auditor < 10000 ? _vm.alertCount.auditor : ">9999")+" ")])],1):_vm._e()]),_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"d-none"}},[(
                child.name === 'interconnectorAlert' &&
                _vm.alertCount.interconnector > 0 &&
                _vm.showAlertCount
              )?_c('div',{staticClass:"alert-count"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"absolute":"","indeterminate":"","width":1,"size":"20","color":"white"}}):_c('span',[_vm._v(" "+_vm._s(_vm.alertCount.interconnector < 1000 ? _vm.alertCount.interconnector : ">999")+" ")])],1):_vm._e()])],1)],1)],1)}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }