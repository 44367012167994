<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :keywordsResize2="keywordsResize2"
      @resize="resize($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="py-5 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new profile" : "Edit profile" }}
            </h3>
          </v-col>

          <!--TAB BUTTONS-->
          <v-col cols="12" class="pt-0 pb-2 pl-6">
            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'general'"
              :color="activeTab === 'general' ? 'primary' : ''"
              :outlined="activeTab === 'general'"
              @click="activeTab = 'general'"
            >
              <v-badge
                v-if="!validGeneral"
                color="error"
                icon="fas fa-exclamation"
              >
                General
              </v-badge>
              <span v-else>General</span>
            </v-btn>

            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'emails'"
              :color="activeTab === 'emails' ? 'primary' : ''"
              :outlined="activeTab === 'emails'"
              @click="activeTab = 'emails'"
            >
              <v-badge
                v-if="!validEmails"
                color="error"
                icon="fas fa-exclamation"
              >
                Emails
              </v-badge>
              <span v-else>Emails</span>
            </v-btn>

            <v-btn
              class="text-capitalize synapsa-tab"
              :text="activeTab !== 'syslog'"
              :color="activeTab === 'syslog' ? 'primary' : ''"
              :outlined="activeTab === 'syslog'"
              @click="activeTab = 'syslog'"
            >
              <v-badge
                v-if="!validSyslog"
                color="error"
                icon="fas fa-exclamation"
              >
                Syslog
              </v-badge>
              <span v-else>Syslog</span>
            </v-btn>

            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'datatype'"
              :color="activeTab === 'datatype' ? 'primary' : ''"
              :outlined="activeTab === 'datatype'"
              @click="activeTab = 'datatype'"
            >
              <v-badge
                v-if="!validDatatype"
                color="error"
                icon="fas fa-exclamation"
              >
                Data Type
              </v-badge>
              <span v-else>Data Type</span>
            </v-btn>
          </v-col>
          <!--END TAB BUTTONS-->

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--TABS-->
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <general
                  v-if="activeTab === 'general'"
                  :mount-data="general"
                  @update="general = $event"
                />
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <emails
                  v-if="activeTab === 'emails'"
                  :mount-data="emails"
                  :email-list="emailList"
                  @update="emails = $event"
                />
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <syslog
                  v-if="activeTab === 'syslog'"
                  :mount-data="syslogs"
                  :syslog-list="syslogList"
                  @update="syslogs = $event"
                />
              </transition>
              <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="d-none"
              >
                <datatype
                  v-if="activeTab === 'datatype'"
                  :mount-data="datatypes"
                  :object-list="objectList"
                  :severity-list="severityList"
                  @update="datatypes = $event"
                />
              </transition>
              <!--END TABS-->
            </v-form>

            <v-row
              class="mx-0"
              :class="activeTab === 'general' ? 'mt-6' : 'mt-0'"
            >
              <v-col cols="12" class="pb-0">
                <v-icon v-if="saving" color="primary">
                  fas fa-spinner fa-spin
                </v-icon>
                <span
                  v-if="saving"
                  class="font-roboto-condensed-light font-size-15 ml-2"
                >
                  Processing...
                </span>
                <v-btn
                  v-if="!saving"
                  color="primary"
                  class="text-capitalize"
                  @click="validate"
                >
                  {{ id === 0 ? "Create" : "Update" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import general from "./general";
import emails from "./emails";
import syslog from "./syslog";
import datatype from "./datatype";

import { Errors } from "@/utils/error";
import { Validation } from "./validation";
import {
  getProfile,
  storeProfile,
  updateProfile,
  getSeverityList,
  getObjectList,
} from "@/api/objects/profile";
import { getEmailList } from "@/api/objects/email";
import { getSyslogList } from "@/api/objects/syslog";
export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
    keywordsResize2: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    Topbar,
    general,
    emails,
    syslog,
    datatype,
  },
  data() {
    return {
      activeTab: "general",

      valid: true,
      validGeneral: true,
      validEmails: true,
      validSyslog: true,
      validDatatype: true,
      loading: true,
      loadingProfile: true,
      loadingEmailList: true,
      loadingSyslogList: true,
      loadingObjectList: true,
      loadingSeverytyList: true,
      saving: false,

      general: {
        name: "",
        description: "",
        active: false,
      },
      emails: {
        message_email: "",
        email: [],
      },
      syslogs: {
        syslog: [],
      },
      datatypes: {
        objectname: [],
        severity: [],
      },

      emailList: [],
      syslogList: [],
      objectList: [],
      severityList: [],
    };
  },
  watch: {
    activeTab: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.validateTabs(newValue);
      },
    },
    valid: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (this.activeTab === "general") {
          this.validGeneral = newValue;
        } else if (this.activeTab === "emails") {
          this.validEmails = newValue;
        } else if (this.activeTab === "syslog") {
          this.validSyslog = newValue;
        } else if (this.activeTab === "datatype") {
          this.validDatatype = newValue;
        }
      },
    },
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        this.activeTab = "general";
        if (this.id === 0) {
          this.loadEmails();
          this.loadSyslogs();
          this.loadObjects();
          this.loadSeverities();
          this.setLoadingData();
          this.validGeneral = true;
          this.validEmails = true;
          this.validSyslog = true;
          this.validDatatype = true;
          this.general = {
            name: "",
            description: "",
            active: false,
          };
          this.emails = {
            message_email: "",
            email: [],
          };
          this.syslogs = {
            syslog: [],
          };
          this.datatypes = {
            objectname: [],
            severity: [],
          };

          this.emailList = [];
          this.syslogList = [];
          this.objectList = [];
          this.severityList = [];
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  mounted() {
    this.loadEmails();
    this.loadSyslogs();
    this.loadObjects();
    this.loadSeverities();
    // if (this.id === 0) {
    //   this.setLoadingData();
    // } else {
    //   this.loadById();
    // }
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "objectprofile");
    },
    loadById() {
      this.validGeneral = true;
      this.validEmails = true;
      this.validSyslog = true;
      this.validDatatype = true;
      getProfile(this.id)
        .then((response) => {
          const profile = response.data;
          //general
          this.general.name = profile.name;
          this.general.description = profile.description;
          this.general.active = profile.active ? true : null;
          //emails
          this.emails.message_email = profile.message_email;
          this.emails.email = profile.email;
          //syslogs
          this.syslogs.syslog = profile.syslog;
          //datatypes
          this.datatypes.objectname = profile.objectname;
          this.datatypes.severity = profile.severity;
          this.loadingProfile = false;
          this.setLoadingData();
        })
        .catch((e) => {
          this.loadingProfile = false;
          this.setLoadingData();
          Errors.show(e);
        });
    },
    loadEmails() {
      getEmailList()
        .then((response) => {
          this.emailList = response.data;
          this.loadingEmailList = false;
          this.setLoadingData();
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadSyslogs() {
      getSyslogList()
        .then((response) => {
          this.syslogList = response.data;
          this.loadingSyslogList = false;
          this.setLoadingData();
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadObjects() {
      getObjectList()
        .then((response) => {
          this.objectList = response.data;
          this.loadingObjectList = false;
          this.setLoadingData();
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadSeverities() {
      getSeverityList()
        .then((response) => {
          this.severityList = response.data;
          this.loadingSeverytyList = false;
          this.setLoadingData();
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    setLoadingData() {
      if (this.id === 0) {
        if (
          !this.loadingEmailList &&
          !this.loadingSyslogList &&
          !this.loadingObjectList &&
          !this.loadingSeverytyList
        ) {
          this.loading = false;
        }
      } else {
        if (
          !this.loadingProfile &&
          !this.loadingEmailList &&
          !this.loadingSyslogList &&
          !this.loadingObjectList &&
          !this.loadingSeverytyList
        ) {
          this.loading = false;
        }
      }
    },
    validate() {
      this.$refs.form.validate();
      this.validateTabs();
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    validateTabs(tab) {
      if (tab) {
        if (tab === "general") {
          //GENERAL
        } else if (tab === "emails") {
          this.validGeneral = !!Validation.general(this.general);
        } else if (tab === "syslog") {
          this.validGeneral = !!Validation.general(this.general);
        } else if (tab === "datatype") {
          this.validGeneral = !!Validation.general(this.general);
        }
      }
      if (this.activeTab === "general" && !this.validGeneral) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      } else if (this.activeTab === "email" && !this.validEmails) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      } else if (this.activeTab === "syslog" && !this.validSyslog) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      } else if (this.activeTab === "datatype" && !this.validDatatype) {
        this.$nextTick(() => {
          this.$refs.form.validate();
        });
      }
    },
    changeEmails(data) {
      this.emails.message_email = data.message_email;
      this.emails.email = data.email;
    },
    changeSyslogs(data) {
      this.syslogs.syslog = data.syslog;
    },
    changeDatatypes(data) {
      this.datatypes.objectname = data.objectname;
      this.datatypes.severity = data.severity;
    },
    save() {
      this.saving = true;

      const data = {
        name: this.general.name,
        description: this.general.description,
        // active: this.general.active ? "on" : null,
        active: this.general.active,
        message_email: this.emails.message_email,
        email: this.emails.email,
        syslog: this.syslogs.syslog,
        objectname: this.datatypes.objectname,
        severity: this.datatypes.severity,
      };
      if (this.id === 0) {
        storeProfile(data)
          .then(() => {
            this.saving = false;
            this.$toast.open({
              message: "Profile was successfully created!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.close();
          })
          .catch((e) => {
            Errors.show(e);
            this.saving = false;
          });
      } else {
        updateProfile(data, this.id)
          .then(() => {
            this.saving = false;
            this.$toast.open({
              message: "Profile was successfully updated!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.close();
          })
          .catch((e) => {
            Errors.show(e);
            this.saving = false;
          });
      }
    },
  },
};
</script>
