import request from "@/utils/request";

export function getActiveRulesetAlerts(data) {
  return request({
    url: "api/events/alert/active",
    method: "post",
    data: data,
  });
}

export function getActivePaloRulesetAlerts(auditor, firewall, page, size) {
  return request({
    url:
      "api/events/alert/active/" +
      auditor +
      "/" +
      firewall +
      "?page=" +
      page +
      "&size=" +
      size,
    method: "get",
  });
}

export function getActiveFortiRulesetAlerts(auditor, firewall, page, size) {
  return request({
    url:
      "api/events/alert/active/fortinet/" +
      auditor +
      "/" +
      firewall +
      "?page=" +
      page +
      "&size=" +
      size,
    method: "get",
  });
}

export function getConfirmedRulesetAlerts(page, size, sortDesc, by, batch) {
  const requestBatch = batch ? "&batch=" + batch : "";
  return request({
    url:
      "api/events/alert/confirmed?page=" +
      page +
      "&size=" +
      size +
      "&sortDesc=" +
      sortDesc +
      "&by=" +
      by +
      requestBatch,
    method: "get",
  });
}

export function getFixedRulesetAlerts(page, size, sortDesc, by, batch) {
  const requestBatch = batch ? "&batch=" + batch : "";
  return request({
    url:
      "api/events/alert/fixed?page=" +
      page +
      "&size=" +
      size +
      "&sortDesc=" +
      sortDesc +
      "&by=" +
      by +
      requestBatch,
    method: "get",
  });
}

export function getActiveCveAlerts(page, size, sortDesc, by) {
  return request({
    url:
      "api/events/alert/cve/active?page=" +
      page +
      "&size=" +
      size +
      "&sortDesc=" +
      sortDesc +
      "&by=" +
      by,
    method: "get",
  });
}

export function getActiveIntegrityAlerts(
  page,
  size,
  sortDesc,
  by,
  search,
  batch
) {
  const hasSearch = search
    ? "&searchTerm=" +
      search.searchTerm +
      "&advancedMode=" +
      (search.advancedMode ? 1 : 0)
    : "";
  const requestBatch = batch ? "&batch=" + batch : "";
  return request({
    url:
      "api/events/alert/integrity/active?page=" +
      page +
      "&size=" +
      size +
      "&sortDesc=" +
      sortDesc +
      "&by=" +
      by +
      hasSearch +
      requestBatch,
    method: "get",
  });
}

export function getConfirmedCveAlerts(page, size, sortDesc, by) {
  return request({
    url:
      "api/events/alert/cve/confirmed?page=" +
      page +
      "&size=" +
      size +
      "&sortDesc=" +
      sortDesc +
      "&by=" +
      by,
    method: "get",
  });
}

export function getConfirmedIntegrityAlerts(
  page,
  size,
  sortDesc,
  by,
  search,
  batch
) {
  const hasSearch = search
    ? "&searchTerm=" +
      search.searchTerm +
      "&advancedMode=" +
      (search.advancedMode ? 1 : 0)
    : "";
  const requestBatch = batch ? "&batch=" + batch : "";
  return request({
    url:
      "api/events/alert/integrity/confirmed?page=" +
      page +
      "&size=" +
      size +
      "&sortDesc=" +
      sortDesc +
      "&by=" +
      by +
      hasSearch +
      requestBatch,
    method: "get",
  });
}

export function getCveAlert(id) {
  return request({
    url: "api/events/alert/cve/" + id,
    method: "get",
  });
}

export function getIntegrityAlert(id) {
  return request({
    url: "api/events/alert/integrity/" + id,
    method: "get",
  });
}

export function confirmRulesetAlerts(data) {
  return request({
    url: "api/events/alert/accept",
    method: "post",
    data: data,
  });
}

export function confirmRulesetNegateAlerts(data) {
  return request({
    url: "api/events/alert/accept_negate",
    method: "post",
    data: data,
  });
}

export function revertRulesetAlerts(data) {
  return request({
    url: "api/events/alert/revert",
    method: "post",
    data: data,
  });
}

export function confirmCveAlerts(data) {
  return request({
    url: "api/events/alert/cve/accept",
    method: "post",
    data: data,
  });
}

export function revertCveAlerts(data) {
  return request({
    url: "api/events/alert/cve/revert",
    method: "post",
    data: data,
  });
}

export function confirmIntegrityAlerts(data) {
  return request({
    url: "api/events/alert/integrity/accept",
    method: "post",
    data: data,
  });
}

export function revertIntegrityAlerts(data) {
  return request({
    url: "api/events/alert/integrity/revert",
    method: "post",
    data: data,
  });
}

export function fixRulesetAlerts(data) {
  return request({
    url: "api/events/alert/disable",
    method: "post",
    data: data,
  });
}

export function getIntegrityHistoryAlerts(page, size, sortDesc, by, search) {
  const hasSearch = search
    ? "&searchTerm=" +
      search.searchTerm +
      "&advancedMode=" +
      (search.advancedMode ? 1 : 0)
    : "";
  return request({
    url:
      "api/events/alert/integrity_history?page=" +
      page +
      "&size=" +
      size +
      "&sortDesc=" +
      sortDesc +
      "&by=" +
      by +
      hasSearch,
    method: "get",
  });
}

export function updateAuditorIntegrityAlert(id) {
  return request({
    url: "api/events/alert/integrity/update/" + id,
    method: "get",
  });
}

export function exportIntegrityData(data) {
  return request({
    url: "api/events/alert/integrity/export",
    method: "post",
    data: data,
    timeout: 300000,
  });
}

export function getAuditorResultsHistory(type, month) {
  const requestMonth = month ? "&month=" + month : "";
  return request({
    url: "api/events/results/history?type=" + type + requestMonth,
    method: "get",
  });
}
