import Vue from "vue";
import VueToast from "vue-toast-notification";

Vue.use(VueToast);

// import toolboxRoutes from "@/router/modules/toolbox";
// import monitorRoutes from "@/router/modules/monitor";
import settingRoutes from "@/router/modules/settings";
import systemRoutes from "@/router/modules/system";
import objectRoutes from "@/router/modules/objects";
import auditorRoutes from "@/router/modules/auditor";
import awaitingApprovalRoutes from "@/router/modules/awaitingApproval";
import interconnectorRoutes from "@/router/modules/interconnector";

export const PermRedirect = {
  nextRoute: (to, from, permissions) => {
    // SETTINGS
    if (
      [
        "miners",
        "firewalls",
        "central_management",
        "firewall_groups",
        "parser",
        "api",
        "apiPaloAlto",
        "apiFortinet",
        "apiCheckpoint",
        "apiSophos",
      ].includes(to.name)
    ) {
      return PermRedirect.getNext(
        to,
        from,
        permissions,
        "/settings/show/",
        settingRoutes
      );
    }
    // SYSTEM
    else if (
      [
        "backup",
        "logs",
        "tasks",
        "users",
        "roles",
        "version",
        "license",
        "ssl",
        "synapsa",
        "dns",
        "maintenance",
        "login",
      ].includes(to.name)
    ) {
      return PermRedirect.getNext(
        to,
        from,
        permissions,
        "/system/show/",
        systemRoutes
      );
    }
    // OBJECTS
    else if (
      ["emails", "syslog", "notificationProfiles", "remoteServers", "address"].includes(
        to.name
      )
    ) {
      return PermRedirect.getNext(
        to,
        from,
        permissions,
        "/objects/show/",
        objectRoutes
      );
    }
    // AUDITOR
    else if (["ruleset", "cve", "integrity"].includes(to.name)) {
      return PermRedirect.getNext(
        to,
        from,
        permissions,
        "/auditor/show/",
        auditorRoutes
      );
    }
    // AWAITING APPROVAL
    else if (["secPolicyAlert", "dataFeedsAlert"].includes(to.name)) {
      return PermRedirect.getNext(
        to,
        from,
        permissions,
        "/awaiting_approval/show/",
        awaitingApprovalRoutes
      );
    }
    // INTERCONNECTOR
    else if (["securityPolicy", "rules"].includes(to.name)) {
      return PermRedirect.getNext(
        to,
        from,
        permissions,
        "/interconnector/",
        interconnectorRoutes,
        false
      );
    }
    // DATA FEEDS ADDRESS
    else if (
      [
        "addressListsCustomAll",
        "addressListsCustomShow",
        "addressListsCustomSource",
        "addressListsCustomDestination",
        "addressListsPredefinedIPv4",
        "addressListsPredefinedIPv6",
      ].includes(to.name)
    ) {
      return PermRedirect.getNextDataFeeds(from, "address", permissions);
    }
    // DATA FEEDS DOMAIN
    else if (
      [
        "domainListsCustomAll",
        "domainListsCustomShow",
        "domainListsPredefinedAll",
        "domainListsPredefinedShow",
      ].includes(to.name)
    ) {
      return PermRedirect.getNextDataFeeds(from, "domain", permissions);
    }
    // DATA FEEDS URL
    else if (
      [
        "urlListsCustomAll",
        "urlListsCustomShow",
        "urlListsPredefinedAll",
        "urlListsPredefinedShow",
      ].includes(to.name)
    ) {
      return PermRedirect.getNextDataFeeds(from, "url", permissions);
    }
    // MONITOR AUDITOR
    else if (
      [
        "rulesetAlert",
        "rulesetAlertActive",
        "rulesetAlertConfirmed",
        "rulesetAlertFixed",
        "cveAlert",
        "cveAlertActive",
        "cveAlertConfirmed",
      ].includes(to.name)
    ) {
      return PermRedirect.getNextMonitor(to, from, "auditor", permissions);
    }
    // MONITOR INTERCONNECTOR
    else if (
      [
        "interconnectorAlert",
        "interconnectorAlertSuccessful",
        "interconnectorAlertUnsuccessful",
        "interconnectorAlertReject",
      ].includes(to.meta.id)
    ) {
      return PermRedirect.getNextMonitor(
        to,
        from,
        "interconnector",
        permissions
      );
    }
    // MONITOR EVENTS
    else if (["threats", "system", "syslog"].includes(to.name)) {
      return PermRedirect.getNextMonitor(to, from, "events", permissions);
    }
  },
  getNext: (to, from, permissions, base, routes, show = true) => {
    const usedPerms = {};
    if (show) {
      routes.children[0].children.map((child) => {
        usedPerms[child.name] = {
          parent: child.meta.permission,
          children: child.children
            ? child.children.map((childroute) => {
                return {
                  parent: childroute.meta.permission,
                  children: null,
                  route: base + child.path + "/" + childroute.path,
                };
              })
            : null,
          route: base + child.path,
        };
      });
    }
    /*eslint-disable */
    for (const [key, value] of Object.entries(usedPerms)) {
      let isAllowed = permissions.some((p) => value.parent.includes(p));
      if (isAllowed) {
        if (!value.children) {
          return value.route;
        } else {
          for (const [key, child] of Object.entries(value.children)) {
            let isAllowedChild = permissions.some((p) =>
              child.parent.includes(p)
            );
            if (isAllowedChild) {
              return child.route;
            }
          }
        }
      }
    }
    /*eslint-enable */
    Vue.$toast.open({
      message: "You dont have permissions for this module.",
      type: "error",
      position: "top-right",
      duration: 3000,
    });
    return from.path;
  },
  getNextDataFeeds: (from, type, permissions) => {
    if (type === "address") {
      if (permissions.includes("view predefinedaddress")) {
        return "/data_feeds/address_lists/predefined/all";
      } else if (permissions.includes("view customaddress")) {
        return "/data_feeds/address_lists/custom/all";
      }
    } else if (type === "domain") {
      if (permissions.includes("view predefineddomain")) {
        return "/data_feeds/domain_lists/predefined/all";
      } else if (permissions.includes("view customdomain")) {
        return "/data_feeds/domain_lists/custom/all";
      }
    } else if (type === "url") {
      if (permissions.includes("view predefinedurl")) {
        return "/data_feeds/url_lists/predefined/all";
      } else if (permissions.includes("view customurl")) {
        return "/data_feeds/url_lists/custom/all";
      }
    }
    Vue.$toast.open({
      message: "You dont have permissions for this module.",
      type: "error",
      position: "top-right",
      duration: 3000,
    });
    return from.path;
  },
  getNextMonitor(to, from, type, permissions) {
    if (type === "auditor") {
      const rulesetPerms = {
        active: {
          perm: ["view rulesetactivealert"],
          route: "/monitor/auditor/ruleset/active",
        },
        confirmed: {
          perm: ["view rulesetconfirmedalert"],
          route: "/monitor/auditor/ruleset/confirmed",
        },
        fixed: {
          perm: ["view rulesetfixedalert"],
          route: "/monitor/auditor/ruleset/fixed",
        },
      };
      const cvePerms = {
        active: {
          perm: ["view monitorauditorcve"],
          route: "/monitor/auditor/cve/active",
        },
        confirmed: {
          perm: ["view monitorauditorcve"],
          route: "/monitor/auditor/cve/confirmed",
        },
      };
      if (permissions.includes("view auditor")) {
        if (
          [
            "rulesetAlert",
            "rulesetAlertActive",
            "rulesetAlertConfirmed",
            "rulesetAlertFixed",
          ].includes(to.name)
        ) {
          if (permissions.includes("view monitorauditorruleset")) {
            /*eslint-disable */
            for (const [key, value] of Object.entries(rulesetPerms)) {
              let isAllowed = permissions.some((p) => value.perm.includes(p));
              if (isAllowed) {
                return value.route;
              }
            }
            /*eslint-enable */
          } else if (permissions.includes("view monitorauditorcve")) {
            /*eslint-disable */
            for (const [key, value] of Object.entries(cvePerms)) {
              let isAllowed = permissions.some((p) => value.perm.includes(p));
              if (isAllowed) {
                return value.route;
              }
            }
            /*eslint-enable */
          }
        } else if (
          ["cveAlert", "cveAlertActive", "cveAlertConfirmed"].includes(to.name)
        ) {
          if (permissions.includes("view monitorauditorcve")) {
            /*eslint-disable */
            for (const [key, value] of Object.entries(cvePerms)) {
              let isAllowed = permissions.some((p) => value.perm.includes(p));
              if (isAllowed) {
                return value.route;
              }
            }
            /*eslint-enable */
          } else if (permissions.includes("view monitorauditorruleset")) {
            /*eslint-disable */
            for (const [key, value] of Object.entries(rulesetPerms)) {
              let isAllowed = permissions.some((p) => value.perm.includes(p));
              if (isAllowed) {
                return value.route;
              }
            }
            /*eslint-enable */
          }
        }
      }
    } else if (type === "interconnector") {
      const perms = {
        successful: {
          perm: ["view successful"],
          route: "/monitor/interconnector/successful",
        },
        unsuccessful: {
          perm: ["view unsuccessful"],
          route: "/monitor/interconnector/unsuccessful",
        },
        reject: {
          perm: ["view rejected"],
          route: "/monitor/interconnector/reject",
        },
        revoke: {
          perm: ["view revoked"],
          route: "/monitor/interconnector/revoke",
        },
      };
      /*eslint-disable */
      for (const [key, value] of Object.entries(perms)) {
        let isAllowed = permissions.some((p) => value.perm.includes(p));
        if (isAllowed) {
          return value.route;
        }
      }
      /*eslint-enable */
    } else if (type === "events") {
      const perms = {
        threats: {
          perm: ["view eventthreats"],
          route: "/monitor/events/threats",
        },
        system: {
          perm: ["view eventsystem"],
          route: "/monitor/events/system",
        },
        syslog: {
          perm: ["view eventsyslog"],
          route: "/monitor/events/syslog",
        },
      };
      /*eslint-disable */
      for (const [key, value] of Object.entries(perms)) {
        let isAllowed = permissions.some((p) => value.perm.includes(p));
        if (isAllowed) {
          return value.route;
        }
      }
      /*eslint-enable */
    }
    Vue.$toast.open({
      message: "You dont have permissions for this module.",
      type: "error",
      position: "top-right",
      duration: 3000,
    });
    return from.path;
  },
};
