<template>
  <div class="d-flex mb-6 synapsa-search">
    <div class="pa-2 align-self-center fluid" :style="synapsaSearchInput">
      <v-text-field
        v-model="searchTerm"
        :label="searchLabel"
        :disabled="isLoading"
        @keyup.enter="searchData"
        @click:append="searchData"
        append-icon="mdi-magnify"
      ></v-text-field>
    </div>

    <div
      v-if="showAdvancedSearch"
      class="pa-2 align-self-center font-roboto-condensed-regular"
    >
      <div class="toggle mt-0">
        <v-switch
          v-model="advancedMode"
          hide-details
          inset
          label="Advanced Search"
          :class="[advancedMode ? 'v-switch-on' : 'v-switch-off']"
          class="target-background v-switch-off"
        />
      </div>
    </div>
    <div v-if="showAdvancedSearch" class="pa-2 align-self-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" rounded>
            <v-icon class="font-size-12 mb-1"> fas fa-info </v-icon>
          </v-btn>
        </template>
        <span class="font-roboto-condensed-regular">{{ info }}</span>
      </v-tooltip>
    </div>
    <div v-if="isLoading" class="pa-2 align-self-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-if="!isLoading && downloadCsvButton" class="pa-2 align-self-center">
      <v-btn
        color="primary"
        :loading="exportLoading"
        @click="exportData()"
        class="backup-btn"
        rounded
        small
        >Export to CSV</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchLabel: {
      type: String,
      default: function () {
        return "";
      },
    },
    showAdvancedSearch: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    info: {
      type: String,
      default: function () {
        return "";
      },
    },
    isLoading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    exportLoading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    downloadCsvButton: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    width: {
      type: String,
      default: function () {
        return "70";
      },
    },
  },
  computed: {
    synapsaSearchInput() {
      return {
        width: `${this.width}%`,
      };
    },
  },
  data() {
    return {
      searchTerm: "",
      advancedMode: false,
    };
  },
  methods: {
    searchData() {
      this.$emit("onSearch", {
        searchTerm: this.searchTerm,
        advancedMode: this.advancedMode,
      });
    },
    exportData() {
      this.$emit("exportData", {
        searchTerm: this.searchTerm,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$synapsaGreen: #69be28;
.synapsa-search {
  margin: 0;
  padding: 0 0.5em;
  .theme--dark.v-btn:hover,
  .theme--light.v-btn:hover {
    color: $synapsaGreen;
  }
  .v-btn {
    background-color: transparent;
    min-width: 36px;
  }
}
.v-application.theme--light {
  .synapsa-search {
    background-color: #ffffff6e !important;
    box-shadow: 13px 13px 30px rgb(0 0 0 / 10%);
    border-radius: 15px;
  }
  .backup-btn {
    background-color: transparent !important;
    background: transparent !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .backup-btn:hover {
    color: #69be28 !important;
  }
}
.v-application.theme--dark {
  .synapsa-search {
    background-color: #1e1e1e96 !important;
    box-shadow: 13px 13px 30px rgb(0 0 0 / 30%);
    border-radius: 15px;
    .backup-btn {
      background-color: transparent !important;
      background: hsla(0, 0%, 100%, 0.08) !important;
    }
  }
}

::v-deep .v-input__slot {
  background: none !important;
  box-shadow: none !important;
}
</style>
