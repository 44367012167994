<template>
  <v-card class="miner-logs-widget-block">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <widget-toolbar
        v-if="!loading && !failed"
        :title="$t('widgets.systemLogs.headline')"
        :data-time="dataLoadedTime"
        :mount-time="time"
        has-timeline
        has-zoom
        has-bar-chart
        @toggle-chart="toggleCharts"
        @timeline="changeTime($event)"
        @zoom="zoom"
        @remove="remove"
      />
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-card-text v-if="!loading && !failed" class="with-bg pb-4 pt-3">
        <chart
          :refresh="refreshChart"
          :x-axis-data="xAxisData"
          :data="[data.auditor, data.firewall, data.secpolicy, data.login]"
        />
      </v-card-text>
    </transition>
    <v-overlay :value="loading">
      <v-progress-circular absolute indeterminate size="40" color="good-3" />
    </v-overlay>
    <v-overlay :value="failed">
      <v-card-text>
        {{ $t("widgets.systemLogs.headline") }} - loading data from api failed.
      </v-card-text>
      <v-card-actions>
        <v-btn small color="bad-2" class="mx-auto" @click="loadData">
          Reload
        </v-btn>
      </v-card-actions>
    </v-overlay>
    <v-overlay class="overlay__time-data" :value="showCountdown">
      <countdown :radius="10" :stroke-width="2" @action="loadData" />
      <v-card-text class="overlay-text">
        {{ $t("widgets.systemLogs.headline") }} - {{ processingMessage }}
      </v-card-text>
    </v-overlay>
  </v-card>
</template>

<script>
import chart from "@/components/Widgets/SystemLogsWidget/chart";
import { widgetSystemLogs } from "@/api/dashboard";
import WidgetToolbar from "@/components/Widgets/WidgetToolbar";
import Countdown from "@/components/Countdown";

export default {
  components: {
    chart,
    WidgetToolbar,
    Countdown,
  },
  props: {
    i: {
      type: String,
      default: function () {
        return "0";
      },
    },
    w: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    h: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {
      loading: true,
      failed: false,
      refreshChart: false,
      dataLoadedTime: "",

      updatedTime: "lastweek",
      time: "lastweek",
      data: {
        auditor: {
          name: "Auditor",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        config: {
          name: "Config",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        firewall: {
          name: "Firewall",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        login: {
          name: "Login",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        miner: {
          name: "Miner",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        rule: {
          name: "Rule",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        secpolicy: {
          name: "SecPolicy",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        system: {
          name: "System",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      },
      xAxisData: [],
      processingMessage: "",
      showCountdown: false,
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    setTimeout(() => {
      this.loadData();
    }, 1);
  },
  methods: {
    remove() {
      this.$emit("remove", this.i);
    },
    zoom() {
      this.keywordAddForm = "widgetSystemLogs";
    },
    changeTime(time) {
      this.time = time;
      this.updatedTime = "";
      this.loadData();
    },
    loadData() {
      this.failed = false;
      this.loading = true;
      this.showCountdown = false;
      this.processingMessage = "";
      this.refreshChart = false;

      const data = {
        period: this.time,
      };

      if (this.updatedTime !== this.time) {
        this.updatedTime = this.time;
        widgetSystemLogs(data)
          .then((response) => {
            if (response.data.message) {
              this.processingMessage = response.data.message;
              this.showCountdown = true;
            } else {
              this.updateData(response.data);
              const dataToStore = {
                data: response.data,
                time: data.period,
              };

              localStorage.setItem(
                "widgetSystemLogsData",
                JSON.stringify(dataToStore)
              );
              localStorage.setItem(
                "widgetSystemLogsTimestamp",
                Date.now().toString()
              );
              this.loading = false;
            }
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }

      const storedData = JSON.parse(
        localStorage.getItem("widgetSystemLogsData")
      );
      const savedTime = JSON.parse(
        localStorage.getItem("widgetSystemLogsTimestamp")
      );

      if (savedTime && Date.now() - parseInt(savedTime, 10) < 300000) {
        this.dataLoadedTime = "";
        const transformedTimeStamp = new Date(savedTime);
        const hours = transformedTimeStamp.getHours();
        const minutes = transformedTimeStamp.getMinutes();
        this.dataLoadedTime = `${hours}:${minutes}`;
      }

      if (
        storedData &&
        savedTime &&
        Date.now() - parseInt(savedTime, 10) < 300000
      ) {
        this.updateData(storedData.data);
        this.updatedTime = storedData.time;
        this.time = storedData.time;
        this.loading = false;
      } else {
        widgetSystemLogs(data)
          .then((response) => {
            if (response.data.message) {
              this.processingMessage = response.data.message;
              this.showCountdown = true;
            } else {
              this.updateData(response.data);

              const dataToStore = {
                data: response.data,
                time: data.period,
              };
              this.$nextTick(() => {
                this.refreshChart = true;
              });
              this.loading = false;

              localStorage.setItem(
                "widgetSystemLogsData",
                JSON.stringify(dataToStore)
              );
              localStorage.setItem(
                "widgetSystemLogsTimestamp",
                Date.now().toString()
              );

              const savedTime = JSON.parse(
                localStorage.getItem("widgetSystemLogsTimestamp")
              );

              this.dataLoadedTime = "";
              const transformedTimeStamp = new Date(savedTime);
              const hours = transformedTimeStamp.getHours();
              const minutes = transformedTimeStamp.getMinutes();
              this.dataLoadedTime = `${hours}:${minutes}`;
            }
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }
    },
    updateData(data) {
      this.clearData();
      const self = this;
      data.forEach(function (value) {
        self.xAxisData.push(value.period);

        self.data.auditor.data.push(value.auditor);
        self.data.config.data.push(value.config);
        self.data.firewall.data.push(value.firewall);
        self.data.login.data.push(value.login);
        self.data.miner.data.push(value.miner);
        self.data.rule.data.push(value.rule);
        self.data.secpolicy.data.push(value.secpolicy);
        self.data.system.data.push(value.system);
      });
    },
    clearData() {
      this.xAxisData = [];
      this.data.auditor.data = [];
      this.data.config.data = [];
      this.data.firewall.data = [];
      this.data.login.data = [];
      this.data.miner.data = [];
      this.data.rule.data = [];
      this.data.secpolicy.data = [];
      this.data.system.data = [];
    },
    toggleCharts() {
      const datasArray = Object.entries(this.data);
      datasArray.map((data) => {
        if (data[1].type === "bar") {
          data[1].type = "line";
        } else {
          data[1].type = "bar";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-icon {
  width: 65px;
  height: 50px;
}
.v-card-text {
  background: #24272169;
  border-radius: 0;
}
.v-card__text.with-bg:not(.with-graph) {
  border-radius: 0 0 15px 15px !important;
}
.v-card__actions {
  border-radius: 0 0 15px 15px !important;
}
.overlay-text {
  text-align: center;
  position: absolute;
  bottom: 3rem;
}
.theme--light {
  .overlay-text {
    color: #535452;
  }
}
.dashboard-container {
  .v-card.theme--dark {
    .v-card__title {
      border-bottom: 2px solid #fff;
    }
  }
}
.dashboard-container {
  .v-card.theme--light {
    .v-card__title {
      border-bottom: 2px solid #3d3d3f;
    }
  }
}
.overlay__time-data {
  height: 95.1% !important;
}
</style>
