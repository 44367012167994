<template>
  <v-container :fluid="true" class="px-0 pb-0">
    <v-divider v-if="!loading && syslogList.length > 0" class="mt-2 mx-3" />

    <!--SYSLOG-->
    <v-row v-if="!loading && syslogList.length > 0" class="mx-0 mt-0 pb-3 px-3">
      <v-chip-group
        v-model="syslogsChosen"
        column
        multiple
        class="py-4"
        @change="chooseSyslog"
      >
        <v-chip
          v-for="syslog in syslogList"
          :key="syslog.id"
          filter
          outlined
          small
          :color="syslog.chosen ? 'primary' : ''"
        >
          {{ syslog.ip }}
        </v-chip>
      </v-chip-group>
    </v-row>
    <!--END SYSLOG-->

    <v-divider v-if="!loading && syslogList.length > 0" class="mb-5 mx-3" />

    <v-row v-if="loading" class="mt-3 mx-0">
      <v-col cols="12" class="pt-0 px-0 pb-5">
        <v-icon color="primary"> fas fa-spinner fa-spin </v-icon>
        <span class="font-roboto-condensed-light font-size-15 ml-2">
          Loading syslog...
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
    syslogList: {
      type: Array,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      syslog: [],
      syslogsChosen: [],

      loading: true,
    };
  },
  created() {
    if (this.mountData) {
      this.syslog = this.mountData.syslog;
      this.loadSyslogs();
      this.loading = false;
    }
  },
  methods: {
    loadSyslogs() {
      if (this.syslog) {
        const self = this;
        this.syslogList.forEach(function (item, key) {
          item.chosen = self.syslog.includes(item.id);
          if (self.syslog.includes(item.id)) {
            self.syslogsChosen.push(key);
          }
        });
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    chooseSyslog() {
      const self = this;
      this.syslogList.forEach(function (item) {
        item.chosen = false;
      });
      this.syslogsChosen.forEach(function (item) {
        self.syslogList[item].chosen = true;
      });
      const syslog = [];
      this.syslogList.forEach(function (item) {
        if (item.chosen) {
          syslog.push(item.id);
        }
      });
      this.update(syslog);
    },
    update(data) {
      this.$emit("update", {
        syslog: data,
      });
    },
  },
};
</script>
