<template>
  <v-container
    fill-height
    fluid
    :style="
      $vuetify.theme.dark
        ? 'background-image: url(\'images/background.png\')'
        : 'background-image: url(\'images/background_light.png\')'
    "
  >
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" lg="4">
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <!-- LOGIN -->
          <v-card v-if="!forgotPw && !twoFA" class="mx-auto elevation-5" max-width="344">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card-text>
                <v-img
                  contain
                  :src="
                    $vuetify.theme.dark
                      ? defaultImages.synapsaLogoFullLight
                      : defaultImages.synapsaLogoFull
                  "
                  class="px-3"
                />
              </v-card-text>
              <v-card-text class="pb-0">
                <v-text-field
                  v-model="username"
                  :rules="usernameRules"
                  label="Username"
                  placeholder="Write your username"
                  outlined
                  dense
                  @keyup.enter="validate"
                />
              </v-card-text>
              <v-card-text class="py-0">
                <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  label="Password"
                  type="password"
                  placeholder="Write your password"
                  outlined
                  dense
                  @keyup.enter="validate"
                />
              </v-card-text>

              <v-card-actions class="py-0">
                <v-spacer />
                <v-btn
                  color="grey"
                  @click="forgotPw = true"
                  text
                  class="text-capitalize font-roboto-condensed-regular"
                >
                  Forgot password?
                </v-btn>
              </v-card-actions>
              <v-card-actions class="justify-center">
                <v-btn
                  v-if="!sendRequest"
                  :disabled="!valid"
                  elevation="3"
                  outlined
                  color="primary"
                  class="mb-3 font-ubuntu-bold"
                  @click="validate"
                >
                  Sign in
                </v-btn>
                <v-icon v-else color="primary" class="mb-2">
                  fas fa-spinner fa-spin
                </v-icon>
              </v-card-actions>
            </v-form>
          </v-card>
          <!-- LOGIN END -->
        </transition>
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <!-- RESET PASSWORD -->
          <v-card
            v-if="forgotPw && !twoFA"
            class="forgot-pw-card mx-auto elevation-5"
            max-width="344"
          >
            <v-form ref="pwForm" v-model="validPw" lazy-validation>
              <v-card-text>
                <v-img
                  contain
                  :src="
                    $vuetify.theme.dark
                      ? defaultImages.synapsaLogoFullLight
                      : defaultImages.synapsaLogoFull
                  "
                  class="px-3"
                />
              </v-card-text>
              <v-card-text class="pb-0">
                <v-text-field
                  v-model="username"
                  :rules="usernameRules"
                  label="Username"
                  placeholder="Write your username"
                  outlined
                  dense
                  validate-on-blur
                  @keyup.enter="validate"
                />
              </v-card-text>
              <v-card-text class="pb-0 pt-0">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="Email"
                  placeholder="Write your email"
                  outlined
                  dense
                  validate-on-blur
                  @keyup.enter="validate"
                />
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  v-if="!sendRequest"
                  :disabled="!validPw || linkSent"
                  elevation="3"
                  outlined
                  color="primary"
                  class="mb-3 font-ubuntu-bold"
                  @click="validatePw"
                >
                  Reset Password
                </v-btn>
                <v-icon v-else color="primary" class="mb-2">
                  fas fa-spinner fa-spin
                </v-icon>
              </v-card-actions>
              <div v-if="linkSent" class="message-wrapper font-roboto">
                <span v-html="message" class=""></span>
              </div>
              <v-card-actions class="justify-center pb-6">
                <div>
                  <span
                    class="cursor-pointer font-ubuntu-bold loginForm-footer-back-link"
                    @click="forgotPw = false"
                  >
                    <i class="footer-back-icon fas fa-chevron-left" />
                    Back
                  </span>
                </div>
              </v-card-actions>
            </v-form>
          </v-card>
          <!-- RESET PASSWORD END -->

          <!-- 2FA VERIFY -->
          <v-card
            v-if="twoFA && !forgotPw"
            class="twoFA-card mx-auto elevation-5"
            max-width="344"
          >
            <v-form ref="authCodeForm" v-model="validAuthCode" lazy-validation>
              <v-card-text>
                <v-img
                  contain
                  :src="
                    $vuetify.theme.dark
                      ? defaultImages.synapsaLogoFullLight
                      : defaultImages.synapsaLogoFull
                  "
                  class="px-3"
                />
              </v-card-text>
              <v-card-text class="py-0">
                <v-text-field
                    v-model="authCode"
                    :rules="authCodeRules"
                    label="Authorization Code"
                    type="password"
                    placeholder="Write your authorization code"
                    outlined
                    dense
                    @keyup.enter="validateAuthCode"
                />
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  v-if="!sendAuthRequest"
                  :disabled="!validAuthCode"
                  elevation="3"
                  outlined
                  color="primary"
                  class="mb-3"
                  @click="validateAuthCode"
                >
                  Verify
                </v-btn>
                <v-icon v-else color="primary" class="mb-2">
                  fas fa-spinner fa-spin
                </v-icon>
              </v-card-actions>
            </v-form>
          </v-card>
          <!-- 2FA VERIFY END-->
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getPasswordResetToken } from "@/api/auth";

import { Errors } from "@/utils/error";

export default {
  data() {
    return {
      forgotPw: false,
      valid: true,
      validPw: false,
      email: "",
      username: "",
      message: "",
      linkSent: false,
      usernameRules: [(v) => !!v || "Username is required"],
      password: "",
      passwordRules: [(v) => !!v || "Password is required"],
      emailRules: [
        (value) => !!value || "Required.",
        (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],

      sendRequest: false,
      sendAuthRequest: false,
      otherQuery: {},
      twoFA: false,
      validAuthCode: false,
      authCode: '',
      authCodeRules: [(v) => !!v || "Authorization Code is required"],
    };
  },
  computed: {
    defaultImages: {
      get() {
        return this.$store.state.settings.defaultImages;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "defaultImages",
          value: val,
        });
      },
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.handleSubmit();
      }
    },
    validatePw() {
      if (this.$refs.pwForm.validate()) {
        this.handleResetPwSubmit();
      }
    },
    validateAuthCode() {
      if (this.$refs.authCodeForm.validate()) {
        this.handleSubmitAuthCode();
      }
    },
    handleSubmit() {
      if (this.password.length > 0 && this.username.length > 0) {
        this.sendRequest = true;
        const data = {
          username: this.username,
          password: this.password,
        };
        this.$store
          .dispatch("user/login", data)
          .then(() => {
            this.sendRequest = false;
            this.$toast.open({
              message: "Your sign in was succesfull!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.$router.push({
              path: this.redirect || "/",
              query: this.otherQuery,
            });
          })
          .catch((e) => {
            if(e.response.status === 406){
              this.$toast.open({
                message: "Please insert your Authorization Code",
                type: "success",
                position: "top-right",
                duration: 2000,
              });
              this.forgotPw = false;
              this.twoFA = true;
              this.sendAuthRequest = false;
            }else{
              this.sendRequest = false;
              this.sendAuthRequest = false;
              Errors.show(e);
            }
          });
      }
    },
    handleSubmitAuthCode() {
      if (this.password.length > 0 && this.username.length > 0 && this.authCode.length > 0) {
        this.sendAuthRequest = true;
        this.sendRequest = false;
        const data = {
          username: this.username,
          password: this.password,
          authCode: this.authCode,
        };
        this.$store
            .dispatch("user/verify", data)
            .then(() => {
              this.sendAuthRequest = false;
              this.$toast.open({
                message: "Your sign in was succesfull!",
                type: "success",
                position: "top-right",
                duration: 2000,
              });
              this.$router.push({
                path: this.redirect || "/",
                query: this.otherQuery,
              });
            })
            .catch((e) => {
              if(e.response.status === 406){
                this.forgotPw = false;
                this.twoFA = false;
                this.username = '';
                this.password = '';
                this.authCode = '';

                this.sendAuthRequest = false;
                Errors.show(e);
              }else{
                this.sendAuthRequest = false;
                Errors.show(e);
              }

            });
      }
    },
    handleResetPwSubmit() {
      this.linkSent = false;
      if (this.email.length > 0 && this.username.length > 0) {
        this.sendRequest = true;
        const data = {
          username: this.username,
          email: this.email,
        };
        getPasswordResetToken(data)
          .then((response) => {
            this.sendRequest = false;
            if (response.data) {
              this.linkSent = true;
              this.message = response.data;
            } else if (response.message) {
              this.linkSent = true;
              this.message = response.message;
            }
          })
          .catch((e) => {
            this.sendRequest = false;
            Errors.show(e);
          });
      }
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
  },
};
</script>

<style scoped lang="scss">
.v-card {
  border-radius: 15px !important;
}
.container.container--fluid.fill-height {
  background-repeat: no-repeat;
  background-size: cover;
}

.loginForm-footer-back-link {
  display: inline-block;
  transition: 0.2s;
  font-size: 14px;
}
.loginForm-footer-back-link:hover {
  color: #69be28;
  transition: 0.3s;
  transform: translateX(-5px);
}
.loginForm-footer-links:hover {
  color: #69be28;
}

.message-wrapper {
  width: 100%;
  padding: 15px;
  text-align: center;
}
.font-roboto {
  font-family: RobotoCondensedRegular, sans-serif !important;
}
</style>
