<template>
  <v-list-group
    :value="open"
    :append-icon="''"
    :class="childrenActive() ? 'active' : ''"
    color="white"
  >
    <template v-slot:activator>
      <v-list-item-icon>
        <v-avatar size="20" class="br-0">
          <img
            :src="
              childrenActive()
                ? location + '/images/icons/green/sidebar/' + route.icon
                : location + iconRoute + route.icon
            "
            alt="Add"
          />
        </v-avatar>
      </v-list-item-icon>
      <v-list-item-title
        class="font-roboto-condensed-regular"
        :class="childrenActive() ? 'primary--text' : ''"
        >{{ $t("sidebar." + route.name) }}
        <v-avatar size="18" class="br-0 float-right pb-1 group-append-icon">
          <img
            :src="
              childrenActive()
                ? location + '/images/icons/green/sidebar/arrow-bottom.svg'
                : location + iconRoute + 'arrow-bottom.svg'
            "
            alt="Add"
          />
        </v-avatar>
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <div
            v-if="
              route.name === 'monitor' &&
              alertCount.total > 0 &&
              showAlertCount
            "
            class="alert-count mr-1"
          >
            <v-progress-circular
              v-if="loading"
              absolute
              indeterminate
              :width="1"
              size="20"
              color="white"
            />
            <span v-else>
              {{
                alertCount.total &lt; 10000
                    ? alertCount.total
                    : ">9999"
              }}
            </span>
          </div>
        </transition>
      </v-list-item-title>
    </template>

    <div class="list-group-body">
      <v-list-item
        v-for="child in route.children"
        :key="child.id"
        :to="child.route"
        :class="[
          active.includes(child.id) ? 'active' : '',
          alertCount.interconnector > 999 ? 'pl-10' : 'pl-12',
        ]"
        class="pl-10"
        color="#fff"
        link
      >
        <v-list-item-icon>
          <v-avatar size="20" class="br-0">
            <img
              :src="
                active.includes(child.id)
                  ? location + '/images/icons/green/sidebar/' + child.icon
                  : location + '/images/icons/white/sidebar/' + child.icon
              "
              alt="Add"
            />
          </v-avatar>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="font-roboto-condensed-regular"
            >{{ $t("sidebar." + child.name) }}
            <transition
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="d-none"
            >
              <div
                v-if="
                  child.name === 'auditorAlert' &&
                  alertCount.auditor > 0 &&
                  showAlertCount
                "
                class="alert-count"
              >
                <v-progress-circular
                    v-if="loading"
                    absolute
                    indeterminate
                    :width="1"
                    size="20"
                    color="white"
                />
                <span v-else>
                  {{ alertCount.auditor &lt; 10000 ? alertCount.auditor : ">9999" }}
                </span>
              </div>
            </transition>
            <transition
              enter-active-class="animate__animated animate__fadeIn"
              leave-active-class="d-none"
            >
              <div
                v-if="
                  child.name === 'interconnectorAlert' &&
                  alertCount.interconnector > 0 &&
                  showAlertCount
                "
                class="alert-count"
              >
                <v-progress-circular
                    v-if="loading"
                    absolute
                    indeterminate
                    :width="1"
                    size="20"
                    color="white"
                />
                <span v-else>
                  {{ alertCount.interconnector &lt; 1000
                        ? alertCount.interconnector
                        : ">999" }}
                </span>
              </div>
            </transition>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list-group>
</template>

<script>
export default {
  props: {
    active: {
      type: Array,
      default: function () {
        return [];
      },
    },
    mini: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    route: {
      type: Object,
      default: function () {
        return null;
      },
    },
    location: {
      type: String,
      default: function () {
        return "";
      },
    },
    alertCount: {
      type: Object,
      default: function () {
        return {
          auditor: 0,
          approval: 0,
          interconnector: 0,
        };
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    }
  },
  data() {
    return {
      iconRoute: "/images/icons/white/sidebar/",
      open: false,

      showAlertCount: false,
    };
  },
  watch: {
    mini: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) {
          setTimeout(() => {
            this.showAlertCount = true;
          }, 500);
        } else {
          this.showAlertCount = false;
        }
      },
    },
  },
  methods: {
    childrenActive() {
      let active = false;
      this.route.children.map((child) => {
        if (this.active.includes(child.id)) {
          active = true;
        }
      });
      return active;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-group-body {
  background: rgb(78, 79, 79);
  background: linear-gradient(
    90deg,
    rgba(78, 79, 79, 1) 45%,
    rgba(54, 54, 54, 1) 80%
  );
}
.group-append-icon {
  -moz-transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  transition: transform 0.5s;
}
.v-list-group--active {
  .group-append-icon {
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
}
</style>
