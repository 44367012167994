import request from "@/utils/request";

export function storeLdap(data) {
  return request({
    url: "api/system/login/ldap",
    method: "post",
    data: data,
  });
}

export function testLdap(data) {
  return request({
    url: "api/system/login/ldap_test",
    method: "post",
    data: data,
  });
}

export function getLdap() {
  return request({
    url: "api/system/login/ldap",
    method: "get",
  });
}
export function getLdapStatus() {
  return request({
    url: "api/system/login/ldap_active",
    method: "get",
  });
}
export function setLdapStatus(data) {
  return request({
    url: "api/system/login/ldap_active",
    method: "post",
    data: data,
  });
}
