<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new auditor template rule" : "Edit auditor template rule" }}
            </h3>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="validCheck" lazy-validation>
              <!--Name-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Name</label
                  >
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    placeholder="Name"
                    dense
                    solo
                    aria-autocomplete="none"
                  />
                </v-col>
              </v-row>
              <!--END Name-->
              <!--CIS ID-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                  >CIS ID</label
                  >
                  <v-text-field
                    v-model="cisId"
                    :rules="cisIdRules"
                    label="CIS ID"
                    placeholder="CIS ID"
                    dense
                    solo
                    aria-autocomplete="none"
                  />
                </v-col>
              </v-row>
              <!--END CIS ID-->

              <!--Xpath-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                  >Xpath</label
                  >
                  <v-text-field
                    v-model="xpath"
                    label="Xpath"
                    placeholder="Xpath"
                    dense
                    solo
                    aria-autocomplete="none"
                  />
                </v-col>
              </v-row>
              <!--END Xpath-->

              <!--Expected-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                  >Expected output</label
                  >
                  <v-text-field
                    v-model="expectedOutput"
                    label="Expected output"
                    placeholder="Expected output"
                    dense
                    solo
                    aria-autocomplete="none"
                  />
                </v-col>
              </v-row>
              <!--END Expected-->

              <!--Condition-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                  >Second Conditions</label
                  >
                  <v-textarea
                    v-model="secondConditions"
                    placeholder="Second condition"
                    auto-grow
                    rows="3"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END Condition-->

              <!--Fix-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                  >Fix</label
                  >
                  <v-textarea
                    v-model="fix"
                    placeholder="Fix"
                    auto-grow
                    rows="3"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END Fix-->



              <!--Cli-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Cli</label
                  >
                  <v-text-field
                    v-model="cli"
                    label="Cli"
                    placeholder="Cli"
                    dense
                    solo
                    aria-autocomplete="none"
                  />
                </v-col>
              </v-row>
              <!--END Cli-->


              <!--SEVERITY-->
              <v-row class="mt-0 mx-0">
                <v-col cols="12" class="pb-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                  >Severity</label
                  >
                  <v-autocomplete
                    v-model="severity"
                    :items="severities"
                    item-text="name"
                    item-value="id"
                    label="Severity"
                    placeholder="Severity"
                    dense
                    solo
                  >
                    <template v-slot:selection="data">
                      <v-icon :color="data.item.color" small class="mr-2">
                        fas fa-circle
                      </v-icon>
                      <span class="default--text font-roboto-condensed-light">{{
                          data.item.name
                        }}</span>
                    </template>
                    <template v-slot:item="data">
                      <v-icon :color="data.item.color" small class="mr-2">
                        fas fa-circle
                      </v-icon>
                      <span class="default--text font-roboto-condensed-light">{{
                          data.item.name
                        }}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <!--END SEVERITY-->



              <v-row class="mt-6 mx-0">
                <v-col cols="12" class="pb-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { Errors } from "@/utils/error";
import {
  getAuditorTemplateRule,
  updateAuditorTemplateRule
} from "../../../api/auditor/template";
export default {
  name: "index",
  components: {
    Topbar,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  data() {
    return {
      activeTab: "general",
      loading: true,
      checkLoading: false,
      valid: true,
      validCheck: true,
      saving: false,
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      cisId: "",
      cisIdRules: [(v) => !!v || "CIS ID is required"],
      xpath: "",
      expectedOutput: "",
      secondConditions: "",
      vendor: "",
      fix: "",
      cli: "",
      description: "",
      severity: 0,
      severities: [
        { id: 0, name: "undefined", color: "neutral-5" },
        { id: 1, name: "Low", color: "neutral-3" },
        { id: 2, name: "Medium", color: "good-3" },
        { id: 3, name: "High", color: "medium-3" },
        { id: 4, name: "Critical", color: "bad-3" },
      ],
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.validCheck = true;
          this.name = "";
          this.cisId = "";
          this.xpath = "";
          this.severity = "";
          this.expectedOutput = "";
          this.secondConditions = "";
          this.fix = "";
          this.cli = "";
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  mounted() {
    // this.loadRule();
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "auditorTemplateRule");
    },
    loadById() {
      getAuditorTemplateRule(this.id)
        .then((response) => {
          this.name = response.data.title;
          this.cisId = response.data.cis_id;
          this.cli = response.data.cli;
          this.fix = response.data.fix;
          this.xpath = response.data.xpath;
          this.severity = response.data.severity;
          this.vendor = response.data.vendor;
          this.secondConditions = response.data.condition_second;
          this.expectedOutput = response.data.expected_output;
          this.loading = false;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    update() {
      this.saving = true;
      const data = {
        title: this.name,
        cis_id: this.cisId,
        cli: this.cli,
        fix: this.fix,
        xpath: this.xpath,
        severity: this.severity,
        condition_second: this.secondConditions,
        expected_output: this.expectedOutput,
        id: this.id,
        vendor: this.vendor,
      };
      updateAuditorTemplateRule(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Auditor Template Rule has been successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.saving = false;
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped></style>
