export const Settings = {
  getAddFormKeyword: function (route) {
    if (route.path === "/interconnector/rules/default") {
      return "rule-default";
    } else if (route.path === "/interconnector/rules/script") {
      return "rule-script";
    }

    if (route.name === "miners") {
      return "miner";
    } else if (route.name === "firewalls") {
      return "firewall";
    } else if (route.name === "centralManagement") {
      return "firewallCentralManagement";
    } else if (route.name === "firewallGroups") {
      return "firewallgroup";
    } else if (
      ["apiPaloAlto", "apiFortinet", "apiCheckpoint", "apiSophos"].includes(
        route.name
      )
    ) {
      return "apicall";
    } else if (route.name === "apiCustom") {
      return "apicallCustom";
    } else if (route.name === "listener") {
      return "listener";
    } else if (route.name === "rules") {
      return "rule";
    } else if (route.name === "emails") {
      return "objectemail";
    } else if (route.name === "syslog") {
      return "objectsyslog";
    } else if (route.name === "address") {
      return "objectaddress";
    } else if (route.name === "notificationProfiles") {
      return "objectprofile";
    } else if (route.name === "remoteServers") {
      return "objectserver";
    } else if (route.name === "users") {
      return "systemuser";
    } else if (route.name === "tasks") {
      return "systemtask";
    } else if (route.name === "ruleset") {
      return "auditor";
    } else if (route.name === "cve") {
      return "auditorcve";
    } else if (route.name === "integrity") {
      return "auditorintegrity";
    } else if (route.name === "groups") {
      return "auditorgroup";
    } else if (route.name === "addressListsCustomAll") {
      return "customaddresslist";
    } else if (route.name === "addressListsConnection") {
      return "connectionaddresslist";
    } else if (route.name === "addressListsCustomSource") {
      return "customSourceAddress";
    } else if (route.name === "addressListsCustomDestination") {
      return "customDestinationAddress";
    } else if (route.name === "domainListsCustomAll") {
      return "customdomainlist";
    } else if (route.name === "domainListsCustomShow") {
      return "customdomainlistshow";
    } else if (route.name === "urlListsCustomAll") {
      return "customurllist";
    } else if (route.name === "urlListsCustomShow") {
      return "customurllistshow";
    } else if (route.name === "license") {
      return "systemLicense";
    } else if (route.name === "ssl") {
      return "systemSsl";
    } else if (route.name === "synapsa") {
      return "systemSynapsa";
    } else if (route.name === "dns") {
      return "systemDns";
    } else if (route.name === "login") {
      return "systemLdap";
    } else if (route.name === "roles") {
      return "systemroles";
    } else if (route.name === "parser") {
      return "parser";
    } else if (route.name === "backupRemote") {
      return "systemRemoteBackup";
    } else {
      return "";
    }
  },
};
