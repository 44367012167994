<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :keywordsResize2="keywordsResize2"
      @resize="resize($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new auditor group" : "Edit auditor group" }}
            </h3>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="validCheck" lazy-validation>
              <!--Name-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Name</label
                  >
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    placeholder="Name"
                    dense
                    solo
                    aria-autocomplete="none"
                  />
                </v-col>
              </v-row>
              <!--END Name-->
              <!--Description-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Description</label
                  >
                  <v-textarea
                    v-model="description"
                    placeholder="Description"
                    auto-grow
                    rows="2"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END Description-->
              <v-row class="mt-6 mx-0">
                <v-col cols="12" class="pb-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { Errors } from "@/utils/error";
import {
  getAuditorGroup,
  storeAuditorGroup,
  updateAuditorGroup,
} from "@/api/auditor/group";
export default {
  name: "index",
  components: {
    Topbar,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
    keywordsResize2: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      activeTab: "general",
      loading: true,
      checkLoading: false,
      checkValid: null,
      perspectiveValid: null,
      checked: null,
      valid: true,
      validCheck: true,
      saving: false,
      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      description: "",
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.checkValid = null;
          this.perspectiveValid = null;
          this.checked = null;
          this.validCheck = true;
          this.name = "";
          this.description = "";
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  mounted() {},
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "auditorgroup");
    },
    loadById() {
      getAuditorGroup(this.id)
        .then((response) => {
          this.name = response.data.name;
          this.description = response.data.description;
          this.checkValid = true;
          this.loading = false;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    store() {
      this.saving = true;
      const data = {
        name: this.name,
        description: this.description,
      };
      storeAuditorGroup(data)
        .then(() => {
          this.$toast.open({
            message: "Auditor group has been successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    update() {
      this.saving = true;
      const data = {
        name: this.name,
        description: this.description,
        id: this.id,
      };
      updateAuditorGroup(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Auditor group has been successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.saving = false;
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped></style>
