import request from "@/utils/request";

export function storeRule(data) {
  return request({
    url: "api/rules",
    method: "post",
    data: data,
  });
}

export function updateRule(data, id) {
  return request({
    url: "api/rules/" + id,
    method: "put",
    data: data,
  });
}

export function getRule(id) {
  return request({
    url: "api/rules/" + id,
    method: "get",
  });
}

export function resetRuleCounter(id) {
  return request({
    url: "api/rule/resetCounter/" + id,
    method: "post",
  });
}

export function getRules(page, size) {
  return request({
    url: "api/rules?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function deleteRule(id) {
  return request({
    url: 'api/rules/' + id,
    method: 'delete',
  });
}