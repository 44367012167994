<template>
  <v-card class="miners-widget-block">
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <widget-toolbar
        v-if="!loading && !failed"
        :title="$t('widgets.miners.headline')"
        :data-time="dataLoadedTime"
        @remove="remove"
      />
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-card-text v-if="!loading && !failed" class="pb-4 with-bg pt-1">
        <div class="d-inline-block widget-icon mt-1">
          <v-img
            lazy-src="images/icons/miners.svg"
            src="images/icons/miners.svg"
            alt="Miner avatar"
            max-height="50"
            max-width="50"
            contain
          />
        </div>
        <div
          class="d-inline-block widget-content"
          style="width: calc(100% - 65px)"
        >
          <v-card-text class="py-1">
            <div
              class="d-inline-block text-right mr-2 font-roboto-condensed-regular"
              style="width: 125px"
            >
              All miners
            </div>
            <div class="d-inline-block font-roboto-condensed-bold">
              {{ allMiners }}
            </div>
          </v-card-text>
          <v-divider />
          <v-card-text class="py-1">
            <div
              class="d-inline-block text-right mr-2 font-roboto-condensed-regular"
              style="width: 125px"
            >
              Active in last 5 min
            </div>
            <div class="d-inline-block font-roboto-condensed-bold">
              {{ activeInFiveMiners }}
            </div>
          </v-card-text>
        </div>
      </v-card-text>
    </transition>
    <v-overlay :value="loading">
      <v-progress-circular absolute indeterminate size="40" color="good-3" />
    </v-overlay>
    <v-overlay :value="failed">
      <v-card-text>
        {{ $t("widgets.miners.headline") }} - loading data from api failed.
      </v-card-text>
      <v-card-actions>
        <v-btn small color="bad-2" class="mx-auto" @click="loadData">
          Reload
        </v-btn>
      </v-card-actions>
    </v-overlay>
  </v-card>
</template>

<script>
import { widgetMiners } from "@/api/dashboard";
import WidgetToolbar from "@/components/Widgets/WidgetToolbar";

export default {
  components: {
    WidgetToolbar,
  },
  props: {
    i: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      allMiners: 0,
      activeInFiveMiners: 0,
      loading: true,
      failed: false,
      dataLoadedTime: "",
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.failed = false;
      this.loading = true;

      const storedData = JSON.parse(localStorage.getItem("widgetMinersData"));
      const savedTime = JSON.parse(
        localStorage.getItem("widgetMinersTimestamp")
      );

      if (savedTime && Date.now() - parseInt(savedTime, 10) < 300000) {
        this.dataLoadedTime = "";
        const transformedTimeStamp = new Date(savedTime);
        const hours = transformedTimeStamp.getHours();
        const minutes = transformedTimeStamp.getMinutes();
        this.dataLoadedTime = `${hours}:${minutes}`;
      }

      if (
        storedData &&
        savedTime &&
        Date.now() - parseInt(savedTime, 10) < 300000
      ) {
        this.updateWidgetData(storedData);
        this.loading = false;
      } else {
        widgetMiners()
          .then((response) => {
            this.updateWidgetData(response);
            this.loading = false;

            localStorage.setItem("widgetMinersData", JSON.stringify(response));
            localStorage.setItem("widgetMinersTimestamp", Date.now());

            const savedTime = JSON.parse(
              localStorage.getItem("widgetSystemLogsTimestamp")
            );

            this.dataLoadedTime = "";
            const transformedTimeStamp = new Date(savedTime);
            const hours = transformedTimeStamp.getHours();
            const minutes = transformedTimeStamp.getMinutes();
            this.dataLoadedTime = `${hours}:${minutes}`;
          })
          .catch((e) => {
            this.$toast.open({
              message: e.response
                ? e.response.status + " - Data loading failed!"
                : e,
              type: "error",
              position: "top-right",
              duration: 2000,
            });
            this.loading = false;
            this.failed = true;
          });
      }
    },
    updateWidgetData(response) {
      this.allMiners = response.allMiners;
      this.activeInFiveMiners = response.activeMiners;
    },
    remove() {
      this.$emit("remove", this.i);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-icon {
  vertical-align: sub;
  width: 65px;
  height: 50px;
}
.v-card-text {
  background: #fff;
  border-radius: 0;
}
.v-card__text.with-bg {
  border-radius: 0 0 15px 15px !important;
}
.dashboard-container {
  .v-card.theme--dark {
    .v-card__title {
      border-bottom: 2px solid #fff;
    }
  }
}
.dashboard-container {
  .v-card.theme--light {
    .v-card__title {
      border-bottom: 2px solid #3d3d3f;
    }
  }
}
</style>
