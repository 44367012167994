import request from "@/utils/request";

export function storeSsl(data) {
  return request({
    url: "api/system/ssl",
    method: "post",
    data: data,
    timeout: 300000,
  });
}

export function getSsl() {
  return request({
    url: "api/system/ssl",
    method: "get",
  });
}
export function deleteSSL() {
  return request({
    url: "api/system/ssl",
    method: "delete",
  });
}
