<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pb-2 pt-3">
            <h3 class="font-ubuntu-bold">
              {{
                id === 0
                  ? "Create New remote backup setting"
                  : "Edit remote backup setting"
              }}
            </h3>
          </v-col>

          <v-col cols="12" class="py-2">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--General settings-->
              <div class="section mb-6">
                <span class="section-title font-ubuntu-bold font-size-16"
                  >General settings</span
                >
                <v-row class="mx-0 mt-0">
                  <v-col cols="6" class="py-0 pl-0 pr-4">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Server</label
                    >
                    <v-select
                      v-model="server"
                      :disabled="id !== 0 && !setEditable"
                      :items="servers"
                      :rules="serverRules"
                      item-text="name"
                      item-value="id"
                      label="Please Select Server"
                      placeholder="Please Select Server"
                      dense
                      solo
                    />
                  </v-col>

                  <v-col cols="6" class="py-0 px-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Protocol</label
                    >
                    <v-select
                      v-model="protocol"
                      :disabled="id !== 0 && !setEditable"
                      :items="protocols"
                      :rules="protocolRules"
                      item-text="name"
                      item-value="id"
                      label="Protocol"
                      placeholder="Protocol"
                      dense
                      solo
                    />
                  </v-col>

                  <v-col cols="6" class="py-0 pl-0 pr-4">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Frequency</label
                    >
                    <v-select
                      v-model="frequency"
                      :disabled="id !== 0 && !setEditable"
                      :items="frequencies"
                      :rules="frequencyRules"
                      item-text="name"
                      item-value="id"
                      label="Frequency"
                      placeholder="Frequency"
                      dense
                      solo
                    />
                  </v-col>

                  <v-col cols="6" class="py-0 px-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Type</label
                    >
                    <v-select
                      v-model="type"
                      :disabled="id !== 0 && !setEditable"
                      :items="types"
                      :rules="typeRules"
                      item-text="name"
                      item-value="id"
                      label="Type"
                      placeholder="Type"
                      dense
                      solo
                    />
                  </v-col>
                </v-row>
              </div>

              <!--Active settings-->
              <div class="section">
                <span class="section-title font-ubuntu-bold font-size-16"
                  >Status</span
                >
                <v-row class="mx-0 mt-0">
                  <v-col cols="12" class="py-0 px-0">
                    <label class="font-roboto-condensed-regular font-size-15"
                      >Active</label
                    >
                    <div class="toggle">
                      <v-switch
                        v-model="active"
                        :disabled="savingActive"
                        :input-value="false"
                        :true-value="true"
                        :false-value="false"
                        :label="active ? 'yes' : 'no'"
                        hide-details
                        inset
                        :class="[active ? 'v-switch-on' : 'v-switch-off']"
                        class="target-background v-switch-off"
                        @click="changeActiveStatus"
                      />
                      <v-col cols="12" class="py-0 pl-4">
                        <v-icon v-if="savingActive" color="primary">
                          fas fa-spinner fa-spin
                        </v-icon>
                        <span
                          v-if="savingActive"
                          class="font-roboto-condensed-light font-size-15 ml-2"
                        >
                          Processing...
                        </span>
                      </v-col>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-form>
            <v-row v-if="id !== 0 && !setEditable" class="mt-1 mx-0">
              <v-col cols="12" class="py-0 px-0">
                <v-btn
                  color="primary"
                  class="text-capitalize"
                  @click="setEditable = true"
                >
                  Edit
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else class="mt-1 mx-0">
              <v-col cols="12" class="py-0 px-0">
                <v-icon v-if="saving" color="primary">
                  fas fa-spinner fa-spin
                </v-icon>
                <span
                  v-if="saving"
                  class="font-roboto-condensed-light font-size-15 ml-2"
                >
                  Processing...
                </span>
                <v-btn
                  v-if="!saving"
                  color="primary"
                  class="text-capitalize"
                  @click="validate"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Errors } from "@/utils/error";
import { getServerList } from "@/api/objects/server";
import {
  getRemoteBackupFrequencies,
  getRemoteBackupProtocols,
  getRemoteBackupSetting,
  getRemoteBackupTypes,
  setRemoteBackupStatus,
  storeRemoteBackupSetting,
  updateRemoteBackupSetting,
} from "@/api/system/backup";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,

      valid: true,
      validCheck: true,

      saving: false,
      savingActive: false,
      setEditable: false,
      active: false,

      server: 0,
      servers: [],
      serverRules: [(v) => !!v || "Server is required"],
      protocol: 0,
      protocols: [],
      protocolRules: [(v) => !!v || "Protocol is required"],

      type: 0,
      types: [],
      typeRules: [(v) => !!v || "Type is required"],

      frequency: 0,
      frequencies: [],
      frequencyRules: [(v) => !!v || "Frequency is required"],
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.valid = true;
          this.validCheck = true;
          this.checkValid = null;
          this.server = "";
          this.active = false;
          this.protocol = "";
          this.type = "";
          this.frequency = "";
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  mounted() {
    this.loadServers();
    this.loadFrequencies();
    this.loadTypes();
    this.loadProtocols();
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "systemRemoteBackup");
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    loadById() {
      getRemoteBackupSetting(this.id)
        .then((response) => {
          this.loading = false;
          const backup = response.data;
          this.server = backup.server.id;
          this.type = backup.type.id;
          this.protocol = backup.protocol.id;
          this.frequency = backup.frequency.id;
          this.active = backup.active;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    loadServers() {
      getServerList()
        .then((response) => {
          this.servers = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadProtocols() {
      getRemoteBackupProtocols()
        .then((response) => {
          this.protocols = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadFrequencies() {
      getRemoteBackupFrequencies()
        .then((response) => {
          this.frequencies = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadTypes() {
      getRemoteBackupTypes()
        .then((response) => {
          this.types = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    changeActiveStatus() {
      if (this.id > 0 && !this.setEditable) {
        this.savingActive = true;
        const data = {
          active: this.active,
        };

        setRemoteBackupStatus(data, this.id)
          .then(() => {
            this.$toast.open({
              message: "Status was successfully updated!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.savingActive = false;
          })
          .catch((e) => {
            Errors.show(e);
            this.savingActive = false;
          });
      }
    },
    store() {
      this.saving = true;
      const data = {
        server_id: this.server,
        type_id: this.type,
        protocol_id: this.protocol,
        frequency_id: this.frequency,
        active: this.active,
      };
      storeRemoteBackupSetting(data)
        .then(() => {
          this.$toast.open({
            message: "Remote server was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    update() {
      this.saving = true;
      const data = {
        server_id: this.server,
        type_id: this.type,
        protocol_id: this.protocol,
        frequency_id: this.frequency,
        active: this.active,
      };
      updateRemoteBackupSetting(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Remote server was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-pencil {
  height: 18px !important;
  min-width: 14px !important;
  padding: 0 12px !important;
}
.pencil {
  font-size: 14px !important;
}
.severity-icon {
  font-size: 24px !important;
}
.section {
  margin: 1rem 0;
  position: relative;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
}
.theme--dark {
  .section {
    background: #3e3e3e;
    box-shadow: 0px 0px 5px 0px #3e3e3e;
  }
}
.theme--light {
  .section {
    background: #f4f4f4;
    box-shadow: 0px 0px 5px 0px #f4f4f4;
  }
}
.section-title {
  position: absolute;
  top: -1.4rem;
  left: 0.4rem;
}

::v-deep .v-input__slot {
  min-height: 38px !important;
}
.auditor-group ::v-deep {
  .v-input__append-inner {
    margin-top: 7px !important;
  }
}
.v-select__selections {
  padding: 0 !important;
}
.toggle {
  .v-input--selection-controls {
    margin-top: -7px;
  }
}
</style>
