<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">Edit auditor template</h3>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="validCheck" lazy-validation>
              <!--Name-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Name</label
                  >
                  <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    placeholder="Name"
                    dense
                    solo
                    aria-autocomplete="none"
                  />
                </v-col>
              </v-row>
              <!--END Name-->

              <!--Variable settings-->
              <div class="section" v-if="variables.length">
                <small class="section-title">Variable settings</small>
                <!--Variables-->
                <v-row class="mt-0 mx-0">
                  <v-col cols="12" class="pb-0">
                    <div v-for="(variable, index) in variables" :key="index">
                      <label
                        class="font-roboto-condensed-regular font-size-15"
                        >{{ variable.name }}</label
                      >
                      <v-text-field
                        v-model="variable.value"
                        :label="'Enter ' + variable.name"
                        :rules="[(v) => !!v || 'Required field']"
                        solo
                        dense
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <!--END Variables-->
              </div>

              <v-row class="mt-6 mx-0">
                <v-col cols="12" class="pb-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { Errors } from "@/utils/error";
import {
  getAuditorTemplate,
  updateAuditorTemplate,
  getTemplateVariables,
} from "@/api/auditor/template";
export default {
  name: "index",
  components: {
    Topbar,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  data() {
    return {
      activeTab: "general",
      loading: true,
      checkLoading: false,
      valid: true,
      validCheck: true,
      saving: false,
      name: "",
      nameRules: [(v) => !!v || "Name is required"],

      variables: [],
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.validCheck = true;
          this.name = "";
          this.cisId = "";
          this.xpath = "";
          this.severity = "";
          this.expectedOutput = "";
          this.secondConditions = "";
          this.fix = "";
          this.cli = "";
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  mounted() {},
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "auditorTemplate");
    },
    loadById() {
      getAuditorTemplate(this.id)
        .then((response) => {
          this.name = response.data.title;
          this.variables = response.data.variables;

          getTemplateVariables(this.id)
            .then((response) => {
              this.variables = this.variables.map((variable) => {
                let value = null;
                for (const item of response.variables) {
                  if (item.name === variable) {
                    value = item.value;
                    break; // exit the loop since we found the matching item
                  }
                }
                return { name: variable, value };
              });
              this.loading = false;
            })
            .catch((e) => {
              Errors.show(e);
            });
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    update() {
      this.saving = true;
      const data = {
        title: this.name,
        variables: this.variables,
      };
      updateAuditorTemplate(data, this.id)
        .then(() => {
          this.$toast.open({
            message: "Auditor Template Rule has been successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.saving = false;
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  margin: 1rem 0;
  position: relative;
  border: 1px solid #69be28;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
}
.section-title {
  position: absolute;
  top: -0.6rem;
}
.theme--dark {
  .section-title {
    background-color: #363636;
  }
}
.theme--light {
  .section-title {
    background-color: white;
  }
}
</style>
