<template>
  <v-row class="mt-0 mx-0">
    <v-col cols="12" class="py-0 px-0">
      <v-data-table
        :headers="headers"
        :items="data"
        hide-default-footer
        item-key="id"
        :items-per-page="data.length"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table in-sidebar-form"
      >
        <template v-slot:item.time="{ item }">
          {{ item.created_at | moment("YYYY-MM-DD HH:mm:ss") }}
        </template>
        <template v-slot:item.http="{ item }">
          <v-chip
            v-if="item.status_code !== null"
            x-small
            label
            :color="item.status_code === 200 ? 'good-3' : 'bad-3'"
            class="elevation-2 text-white"
          >
            {{ item.status_code }}
          </v-chip>
          <span v-else>-</span>
        </template>
        <template v-slot:item.response_status="{ item }">
          <v-chip
            x-small
            label
            :color="item.check_responsecode ? 'good-3' : 'bad-3'"
            class="elevation-2 text-white"
          >
            {{ item.check_responsecode ? "YES" : "NO" }}
          </v-chip>
        </template>
        <template v-slot:item.response_message="{ item }">
          {{ item.message_from_call ? item.message_from_call : "-" }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            x-small
            label
            :color="getStatusColor(item)"
            class="elevation-2 text-white text-capitalize"
          >
            {{ getStatusText(item) }}
          </v-chip>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="12" class="text-right">
      <v-icon v-if="processing && !done && !failed" color="primary" class="va-middle mr-2"
        >fas fa-spinner fa-spin</v-icon
      >
      <v-btn
          v-if="checkPermission(['write awaitingsecuitypolicy'])"
          rounded
          text
          color="tertiary"
          small
          class="text-capitalize"
          @click="download"
      >
        Download
        <v-avatar class="ml-1" size="18">
          <svg
              id="Vrstva_1"
              data-name="Vrstva 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 46 38"
          >
            <defs></defs>
            <path
                class="cls-1"
                d="M362.62,450.89h1c5.8,0,10-4.87,10-10.87a10.69,10.69,0,0,0-10.5-10.86h-.06a8.95,8.95,0,0,0-17.71-1,7.21,7.21,0,0,0-3.73-1,7.63,7.63,0,0,0-7.5,7.76,8.25,8.25,0,0,0,.24,1.94,7.23,7.23,0,0,0-4.74,6.85,7.13,7.13,0,0,0,7,7.25h4"
                transform="translate(-328.62 -419.89)"
            />
            <polyline class="cls-1" points="23 20 23 37 29 31" />
            <line class="cls-1" x1="23" y1="37" x2="17" y2="31" />
          </svg>
        </v-avatar>
      </v-btn>
      <proceed-menu
        v-if="!done && !failed && checkPermission(['write awaitingsecuitypolicy'])"
        :processing="processing"
        main-btn-name="Reject"
        headline="Are you sure you want to reject API CALL?"
        color="bad-3"
        :width="350"
        class-name="mr-2 text-white"
        @proceed="reject"
      />
      <proceed-menu
        v-if="!done && !failed && checkPermission(['write awaitingsecuitypolicy'])"
        :processing="processing"
        main-btn-name="Confirm"
        inside-btn-name="Send"
        headline="Are you sure you want to confirm API CALL?"
        color="good-3 text-white"
        :width="350"
        @proceed="confirm"
      />
      <transition
        enter-active-class="animate__animated animate__bounceIn"
        leave-active-class="d-none"
      >
        <v-btn
          v-if="!processing && done && !failed&& checkPermission(['write awaitingsecuitypolicy'])"
          color="primary"
          class="text-capitalize text-white"
          small
          @click="close"
        >
          <v-icon small color="white" class="mr-1">fas fa-check</v-icon>
          Done
        </v-btn>
      </transition>
      <transition
        enter-active-class="animate__animated animate__bounceIn"
        leave-active-class="d-none"
      >
        <v-btn
          v-if="!processing && done && failed && checkPermission(['write awaitingsecuitypolicy'])"
          color="bad-2"
          class="text-capitalize text-red"
          small
          @click="close"
        >
          Close
        </v-btn>
      </transition>
    </v-col>
  </v-row>
</template>

<script>
import proceedMenu from "@/components/ProceedMenu";
import permission from "@/directive/permission/permission.js";

export default {
  directives: { permission },
  components: {
    proceedMenu,
  },
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    processing: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    done: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    failed: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      permissions: localStorage.getItem("permissions").split(","),
    };
  },
  methods: {
    reject() {
      this.$emit("reject", true);
    },
    confirm() {
      this.$emit("confirm", true);
    },
    close() {
      this.$emit("close", true);
    },
    getStatusColor(call) {
      if (call.status === 0 && call.rollbackcall === 0) {
        return "medium-3";
      } else if (call.status === 0 && call.rollbackcall === 1) {
        return "neutral-5";
      } else if (call.status === 1) {
        return "good-3";
      } else if (call.status === 2 && call.check_status_code) {
        return "bad-3";
      } else if (call.status === 2 && call.check_status_code) {
        return "good-3";
      } else {
        if (call.response_code === call.successful_response_code) {
          return "good-3";
        } else if (
            call.response_code !== call.successful_response_code &&
            call.check_response_code === false
        ) {
          return "good-3";
        } else {
          return "bad-3";
        }
      }
    },
    getStatusText(call) {
      if (call.status === 0 && call.rollbackcall === 0) {
        return "On approval";
      } else if (call.status === 0 && call.rollbackcall === 1) {
        return "Automatic call for unsuccessful deploy";
      } else if (call.status === 1) {
        return "Accepted";
      } else if (call.status === 2 && call.check_status_code) {
        return "Not accepted";
      } else if (call.status === 2 && call.check_status_code) {
        return "Accepted";
      } else {
        if (call.response_code === call.successful_response_code) {
          return "Accepted";
        } else if (
            call.response_code !== call.successful_response_code &&
            call.check_response_code === false
        ) {
          return "Accepted";
        } else {
          return "Not accepted";
        }
      }
    },
    download() {
      const text = this.data.map(function(item) {
        if(item.method === 'GET'){
          return 'CALL NAME: ' + item.call_name + '\nMETHOD: GET\nURL: ' + item.call+ '\n';
        }else{
          return 'CALL NAME: ' + item.call_name + '\nMETHOD: ' + item.method + '\nURL: ' + item.call+ '\nPOST DATA: ' + item.postdata;
        }
      })
      var blob = new Blob([ text.join('\n\r') ], { "type" : "text/plain" });
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = Date.now() + '_api_calls.txt'
      link.click()
    },
    checkPermission(perms) {
      return this.permissions.some((p) => perms.includes(p));
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application.theme--dark {
  .cls-1 {
    transition: 0.3s;
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
}

.v-application.theme--light {
  .cls-1 {
    transition: 0.3s;
    fill: none;
    stroke: #363636;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
}
</style>
