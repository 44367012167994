import Layout from "@/layout";

const systemRoutes = {
  path: "/system",
  name: "system",
  component: Layout,
  meta: {
    requiresAuth: true,
    icon: "system.svg",
    permission: ["view menu system"],
  },
  redirect: "/system/show/backup/configuration",
  children: [
    {
      path: "show",
      component: () => import("@/views/system/index"),
      name: "showSystem",
      meta: { active: ["system", "backup"] },
      hidden: true,
      children: [
        {
          path: "backup",
          component: () => import("@/views/system/backup"),
          name: "backup",
          meta: {
            active: ["system", "backup"],
            permission: ["view backup"],
          },
          redirect: "/system/show/backup/configuration",
          hidden: true,
          children: [
            {
              path: "configuration",
              component: () => import("@/views/system/backup/configuration"),
              name: "backupConfiguration",
              meta: {
                id: "backupConfiguration",
                permission: ["view backup"],
                active: ["system", "backup", "configuration"],
              },
              hidden: true,
            },
            {
              path: "all",
              component: () => import("@/views/system/backup/all"),
              name: "backupAll",
              meta: {
                id: "backupAll",
                permission: ["view backup"],
                active: ["system", "backup", "all"],
              },
              hidden: true,
            },
            {
              path: "remote",
              component: () => import("@/views/system/backup/remote"),
              name: "backupRemote",
              meta: {
                id: "backupRemote",
                permission: ["view backup"],
                active: ["system", "backup", "remote"],
              },
              hidden: true,
            },
          ],
        },
        {
          path: "logs",
          component: () => import("@/views/system/logs"),
          name: "logs",
          meta: { active: ["system", "logs"], permission: ["view log"] },
          hidden: true,
        },
        {
          path: "tasks",
          component: () => import("@/views/system/tasks"),
          name: "tasks",
          meta: {
            active: ["system", "tasks"],
            permission: ["view task"],
          },
          hidden: true,
        },
        {
          path: "users",
          component: () => import("@/views/system/users"),
          name: "users",
          meta: {
            active: ["system", "users"],
            permission: ["view user"],
          },
          hidden: true,
        },
        {
          path: "roles",
          component: () => import("@/views/system/roles"),
          name: "roles",
          meta: {
            active: ["system", "roles"],
            permission: ["view role"],
          },
          hidden: true,
        },
        {
          path: "version",
          component: () => import("@/views/system/version"),
          name: "version",
          meta: {
            active: ["system", "version"],
            permission: ["view version"],
          },
          hidden: true,
        },
        {
          path: "license",
          component: () => import("@/views/system/license"),
          name: "license",
          meta: {
            active: ["system", "license"],
            permission: ["view license"],
          },
          hidden: true,
        },
        {
          path: "ssl",
          component: () => import("@/views/system/ssl"),
          name: "ssl",
          meta: { active: ["system", "ssl"], permission: ["view ssl"] },
          hidden: true,
        },
        {
          path: "synapsa",
          component: () => import("@/views/system/synapsa"),
          name: "synapsa",
          meta: {
            active: ["system", "synapsa"],
            permission: ["view synapsa"],
          },
          hidden: true,
        },
        {
          path: "dns",
          component: () => import("@/views/system/dns"),
          name: "dns",
          meta: { active: ["system", "dns"], permission: ["view dns"] },
          hidden: true,
        },
        {
          path: "maintenance",
          component: () => import("@/views/system/maintenance"),
          name: "maintenance",
          meta: { active: ["system", "maintenance"], permission: ["view maintenance"] },
          hidden: true,
        },
        {
          path: "login",
          component: () => import("@/views/system/login"),
          name: "login",
          meta: { active: ["system", "login"], permission: ["view maintenance"] },
          hidden: true,
        },
      ],
    },
  ],
};

export default systemRoutes;
