<template>
  <div class="widget">
    <div
      id="dashboard-overall-auditor-failure-widget"
      :class="className"
      :style="{ height: height, width: width }"
    />
    <div class="text">
      <p class="text-center">
        Currently there is {{ score }}% auditors generating alerts
      </p>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
require("echarts/theme/macarons");
import { debounce } from "@/utils";

export default {
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "250px",
    },
    score: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    score: {
      immediate: true,
      deep: true,
      handler() {
        if (this.chart) {
          this.chart.dispose();
          this.chart = null;
        }
        this.$nextTick(() => {
          this.initChart();
        });
      },
    },
  },
  mounted() {
    this.initChart();
    this.__resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.__resizeHandler);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      const element = document.getElementById(
        "dashboard-overall-auditor-failure-widget"
      );

      if (this.$vuetify.theme.dark) {
        this.chart = echarts.init(element, "darkTheme");
      } else {
        this.chart = echarts.init(element, "lightTheme");
      }

      // Define your chart options
      var options = {
        grid: {
          top: 10,
          bottom: 10,
          left: 20,
          right: 10,
        },
        series: [
          {
            type: "gauge",
            startAngle: 200,
            endAngle: -20,
            radius: "70%",
            axisLine: {
              lineStyle: {
                width: 8,
                color: [
                  [0.3, "#64c52a"],
                  [0.7, "#ffd976"],
                  [1, "#eb637b"],
                ],
              },
            },
            axisLabel: {
              show: false,
            },
            detail: { formatter: "{value}%" },
            data: [
              {
                value: this.score,
              },
            ],
          },
        ],
        graphic: [
          {
            type: "text",
            left: "15%",
            top: "20%",
            style: {
              text: "Low",
              fill: "#64c52a",
              fontSize: 16,
              fontWeight: "bold",
              textAlign: "center",
            },
          },
          {
            type: "text",
            left: "center",
            top: "5%",
            style: {
              text: "Medium",
              fill: "#ffd976",
              fontSize: 16,
              fontWeight: "bold",
              textAlign: "center",
            },
          },
          {
            type: "text",
            right: "15%",
            top: "20%",
            style: {
              text: "High",
              fill: "#eb637b",
              fontSize: 16,
              fontWeight: "bold",
              textAlign: "center",
            },
          },
        ],
      };

      // Set the options and render the chart
      this.chart.setOption(options);
    },
  },
};
</script>
<style lang="scss" scoped>
.widget {
  width: 100%;
  position: relative;

  .text {
    position: absolute;
    bottom: 25px;
    width: 100%;
  }
}
</style>
