import request from "@/utils/request";

export function storeCveAuditor(data) {
  return request({
    url: "api/auditor/cve/store",
    method: "post",
    data: data,
  });
}

export function updateCveAuditor(data, id) {
  return request({
    url: "api/auditor/cve/update/" + id,
    method: "post",
    data: data,
  });
}

export function getCveAuditors(page, size, search) {
  const hasSearch = search ? "&searchTerm=" + search.searchTerm + "&advancedMode=" + (search.advancedMode ? 1 : 0) : '';
  return request({
    url: "api/auditor/cve?page=" + page + "&size=" + size + hasSearch,
    method: "get",
  });
}

export function deleteAuditor(id) {
  return request({
    url: "api/auditor/cve/destroy/" + id,
    method: "delete",
  });
}
