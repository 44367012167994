import request from "@/utils/request";

export function getTemplates(page, size, search) {
  const hasSearch = search ? "&searchTerm=" + search.searchTerm : "";
  return request({
    url: "api/auditor_template?page=" + page + "&size=" + size + hasSearch,
    method: "get",
  });
}
export function getTemplateName(id) {
  return request({
    url: "api/auditor_template_name/" + id,
    method: "get",
  });
}

export function getTemplateRules(id, page, size, search) {
  const hasSearch = search ? "&searchTerm=" + search.searchTerm : "";
  return request({
    url:
      "api/auditor_template_rules/" +
      id +
      "?page=" +
      page +
      "&size=" +
      size +
      hasSearch,
    method: "get",
  });
}

export function getAuditorTemplate(id) {
  return request({
    url: "api/auditor_template/" + id,
    method: "get",
  });
}

export function updateAuditorTemplate(data, id) {
  return request({
    url: "api/auditor_template/" + id,
    method: "put",
    data: data,
  });
}

export function getTemplateVariables(id) {
  return request({
    url: "api/auditor_template/" + id + "/variables",
    method: "get",
  });
}

export function getAuditorTemplateRule(id) {
  return request({
    url: "api/auditor_template_rule/" + id,
    method: "get",
  });
}

export function updateAuditorTemplateRule(data, id) {
  return request({
    url: "api/auditor_template_rule/" + id,
    method: "put",
    data: data,
  });
}

export function cloneTemplate(id) {
  return request({
    url: "api/auditor_template/" + id + "/clone",
    method: "get",
  });
}

export function loadTemplate(id, data) {
  return request({
    url: "api/auditor_template/" + id + "/load",
    method: "post",
    data,
  });
}

export function deleteTemplate(id) {
  return request({
    url: "api/auditor_template/" + id,
    method: "delete",
  });
}
