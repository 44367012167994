var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"system-info-widget-block"},[_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"d-none"}},[(!_vm.loading && !_vm.failed)?_c('widget-toolbar',{attrs:{"title":_vm.$t('widgets.systemInfo.headline'),"data-time":_vm.dataLoadedTime},on:{"remove":_vm.remove}}):_vm._e()],1),_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"d-none"}},[(!_vm.loading && !_vm.failed)?_c('v-card-text',{staticClass:"pb-1 with-bg pt-1"},[_c('div',{staticClass:"d-inline-block widget-icon mt-1"},[_c('v-img',{attrs:{"lazy-src":"images/icons/system_info.svg","src":"images/icons/system_info.svg","alt":"Pie chart graph","max-height":"55","max-width":"45","contain":""}})],1),_c('div',{staticClass:"d-inline-block widget-content",staticStyle:{"width":"calc(100% - 65px)"}},[_c('v-card-text',{staticClass:"py-1"},[_c('div',{staticClass:"d-inline-block text-right mr-2 font-roboto-condensed-regular",style:(_vm.screenWidth > 1290 ? 'width: 115px;' : '')},[_vm._v(" System disk space ")]),_c('div',{staticClass:"d-inline-block"},[_c('div',{staticStyle:{"display":"inline-table"}},[_c('div',{staticClass:"font-roboto-condensed-regular"},[_c('span',{staticClass:"font-roboto-condensed-bold"},[_vm._v("Size")]),_vm._v(" "+_vm._s(_vm.diskSpace)+" ")]),_c('div',{class:_vm.availableSpaceAlert
                    ? 'font-roboto-condensed-bold bad-2--text'
                    : 'font-roboto-condensed-regular'},[_c('span',{staticClass:"font-roboto-condensed-bold"},[_vm._v("Free")]),_vm._v(" "+_vm._s(_vm.availableSpace)+" ")])])])]),_c('v-divider'),_c('v-card-text',{staticClass:"py-1"},[_c('div',{staticClass:"d-inline-block text-right mr-2 font-roboto-condensed-regular",style:(_vm.screenWidth > 1290 ? 'width: 115px;' : '')},[_vm._v(" Application space ")]),_c('div',{staticClass:"d-inline-block font-roboto-condensed-bold",staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"display":"inline-table"}},[_c('div',{staticClass:"font-roboto-condensed-regular"},[_c('span',{staticClass:"font-roboto-condensed-bold"},[_vm._v("Logs")]),_vm._v(" "+_vm._s(_vm.fileSizeLogs)+" ")])])])]),_c('v-card-text',{staticClass:"py-1"},[_c('div',{staticClass:"d-inline-block text-right mr-2 font-roboto-condensed-regular",style:(_vm.screenWidth > 1290 ? 'width: 115px;' : '')},[_vm._v(" Database ")]),_c('div',{staticClass:"d-inline-block font-roboto-condensed-bold",staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"display":"inline-table"}},[_c('div',{class:_vm.databaseFirstAlert
                    ? 'font-roboto-condensed-bold bad-2--text'
                    : 'font-roboto-condensed-regular'},[_c('span',{staticClass:"font-roboto-condensed-bold"},[_vm._v("Primary")]),_vm._v(" "+_vm._s(_vm.databaseFirst)+" ")]),_c('div',{class:_vm.databaseSecondAlert
                    ? 'font-roboto-condensed-bold bad-2--text'
                    : 'font-roboto-condensed-regular'},[_c('span',{staticClass:"font-roboto-condensed-bold"},[_vm._v("Secondary")]),_vm._v(" "+_vm._s(_vm.databaseSecond)+" ")])])])]),_c('v-card-text',{staticClass:"py-1"},[_c('div',{staticClass:"d-inline-block text-right mr-2 font-roboto-condensed-regular",style:(_vm.screenWidth > 1290 ? 'width: 115px;' : '')},[_vm._v(" Tables with logs ")]),_c('div',{staticClass:"d-inline-block font-roboto-condensed-bold",staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"display":"inline-table"}},[_c('div',{class:_vm.systemlogAlert
                    ? 'font-roboto-condensed-bold bad-2--text'
                    : 'font-roboto-condensed-regular'},[_c('span',{staticClass:"font-roboto-condensed-bold"},[_vm._v("System")]),_vm._v(" "+_vm._s(_vm.databaseSystemLogs)+" ")]),_c('div',{class:_vm.tasklogAlert
                    ? 'font-roboto-condensed-bold bad-2--text'
                    : 'font-roboto-condensed-regular'},[_c('span',{staticClass:"font-roboto-condensed-bold"},[_vm._v("Task")]),_vm._v(" "+_vm._s(_vm.databaseTaskLogs)+" ")])])])])],1)]):_vm._e()],1),_c('transition',{attrs:{"enter-active-class":"animate__animated animate__fadeIn","leave-active-class":"d-none"}},[(!_vm.loading && !_vm.failed)?_c('v-card-actions',{staticClass:"with-bg px-4"},[_c('v-progress-linear',{attrs:{"value":_vm.percentage,"height":"39","color":"#64c52a"}},[_c('strong',[_vm._v(_vm._s(_vm.percentage)+"% free")])])],1):_vm._e()],1),_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"absolute":"","indeterminate":"","size":"40","color":"good-3"}})],1),_c('v-overlay',{attrs:{"value":_vm.failed}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("widgets.systemInfo.headline"))+" - loading data from api failed. ")]),_c('v-card-actions',[_c('v-btn',{staticClass:"mx-auto",attrs:{"small":"","color":"bad-2"},on:{"click":_vm.loadData}},[_vm._v(" Reload ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }