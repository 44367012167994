var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"role-container pt-0 mh-100 form",attrs:{"fluid":true}},[_c('topbar',{attrs:{"size":_vm.size,"keywordsResize2":_vm.keywordsResize2},on:{"resize":function($event){return _vm.resize($event)},"close":_vm.close}}),_c('v-row',{staticClass:"form-block mt-0"},[_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"12"}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.size !== 1 && !_vm.loading),expression:"size !== 1 && !loading"}],staticClass:"mt-0 mx-0"},[_c('v-col',{staticClass:"pb-2 pt-3",attrs:{"cols":"12"}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"6"}},[_c('h3',{staticClass:"font-ubuntu-bold roles-sidebar-heading"},[_vm._v(" "+_vm._s(_vm.id === 0 ? "Create a role" : "Edit role")+" ")]),_c('v-text-field',{staticClass:"name-field",attrs:{"label":"Name","dense":"","solo":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"pb-4"},[_c('v-list-item-title',{staticClass:"font-roboto-condensed-regular font-size-15"},[_vm._v(" Permissions")])],1),_c('v-row',{staticClass:"custom-grid"},_vm._l((_vm.permissions),function(permission,index){return _c('v-col',{key:permission.name,staticClass:"permissionOuter section",class:`grid-item__${index}`},[_c('v-row',{staticClass:"px-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"section-title font-roboto-condensed-regular font-size-15"},[_vm._v(_vm._s())]),_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-list-item-action',[_c('i',{staticClass:"role-icon cursor-pointer fas",attrs:{"id":permission.id,"data-count":permission.count,"data-cluster":index,"data-type":"parent"},on:{"click":function($event){return _vm.changePermissionAll(
                                permission.id,
                                null,
                                'parent'
                              )}}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("permissions." + permission.name)))])],1)]},proxy:true}],null,true)}),_vm._l((permission.children),function(permChildren,indexChildren){return _c('div',{key:permChildren.id,staticClass:"permission-children-outer"},[_c('v-list-item',{staticClass:"permission-children",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-list-item-action',[_c('i',{staticClass:"role-icon cursor-pointer fas",attrs:{"id":permChildren.id,"data-count":permChildren.count,"data-cluster":index,"data-childrenCluster":index + ',' + indexChildren,"data-parentid":permChildren.parent_id,"data-type":"children"},on:{"click":function($event){return _vm.changePermissionAll(
                                  permChildren.id,
                                  permChildren.parent_id,
                                  'children'
                                )}}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("permissions." + permChildren.name))+" ")])],1)]},proxy:true}],null,true)}),_vm._l((permChildren.grandchildren),function(permGrandChildren,indexGrandchildren){return _c('div',{key:permGrandChildren.id,staticClass:"permission-grandchildren-outer"},[_c('v-list-item',{staticClass:"permission-grandchildren",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-list-item-action',[_c('i',{staticClass:"role-icon cursor-pointer fas",attrs:{"id":permGrandChildren.id,"data-count":permGrandChildren.count,"data-cluster":index,"data-childrenCluster":index + ',' + indexChildren,"data-grandchildrenCluster":index +
                                  ',' +
                                  indexChildren +
                                  ',' +
                                  indexGrandchildren,"data-type":"grandchildren","data-parentid":permGrandChildren.parent_id},on:{"click":function($event){return _vm.changePermissionAll(
                                    permGrandChildren.id,
                                    permGrandChildren.parent_id,
                                    'grandchildren'
                                  )}}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("permissions." + permGrandChildren.name)))])],1)]},proxy:true}],null,true)}),_vm._l((permGrandChildren.grandgrandchildren),function(permGrandGrandChildren,indexGrandGrandchildren){return _c('v-list-item',{key:permGrandGrandChildren.id,staticClass:"permission-grandgrandchildren",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-list-item-action',[_c('i',{staticClass:"role-icon cursor-pointer fas",attrs:{"id":permGrandGrandChildren.id,"data-count":permGrandGrandChildren.count,"data-cluster":index,"data-childrenCluster":index + ',' + indexChildren,"data-grandchildrenCluster":index +
                                  ',' +
                                  indexChildren +
                                  ',' +
                                  indexGrandchildren,"data-grandgrandchildrenCluster":index +
                                  ',' +
                                  indexChildren +
                                  ',' +
                                  indexGrandchildren +
                                  ',' +
                                  indexGrandGrandchildren,"data-parentid":permGrandGrandChildren.parent_id,"data-type":"grandgrandchildren"},on:{"click":function($event){return _vm.changePermissionAll(
                                    permGrandGrandChildren.id,
                                    permGrandGrandChildren.parent_id,
                                    'grandgrandchildren'
                                  )}}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t( "permissions." + permGrandGrandChildren.name )))])],1)]},proxy:true}],null,true)})})],2)})],2)})],2)],1)],1)}),1)],1),_c('div',{staticClass:"btn-wrapper py-0 px-0"},[(_vm.saving)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" fas fa-spinner fa-spin ")]):_vm._e(),(_vm.saving)?_c('span',{staticClass:"font-roboto-condensed-light font-size-15 ml-2"},[_vm._v(" Processing... ")]):_c('div',[(_vm.id === 0)?_c('v-btn',{staticClass:"text-right",attrs:{"color":"primary","small":"","rounded":""},on:{"click":function($event){return _vm.createRole()}}},[_vm._v("Create")]):_c('v-btn',{staticClass:"text-right",attrs:{"color":"primary","small":"","rounded":""},on:{"click":function($event){return _vm.updateRolePermissions()}}},[_vm._v("Update")])],1)],1)],1)],1),(_vm.loading)?_c('v-row',{staticClass:"mt-0 mx-0"},[_c('v-col',{class:_vm.size === 1 ? 'text-center' : '',attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fas fa-spinner fa-spin")]),(_vm.size !== 1)?_c('span',{staticClass:"font-roboto-condensed-light font-size-15 ml-2"},[_vm._v("Loading...")]):_vm._e()],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }