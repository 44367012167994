import request from "@/utils/request";

export function getAddresslists(page, size, search) {
  const hasSearch = search ? "&searchTerm=" + search.searchTerm + "&advancedMode=" + (search.advancedMode ? 1 : 0) : '';
  return request({
    url: "api/datafeed/addresslist/custom?page=" + page + "&size=" + size + hasSearch,
    method: "get",
  });
}

export function getAddresslistsList() {
  return request({
    url: "api/datafeed/addresslist/customlist",
    method: "get",
  });
}

export function getAddresslistsSrc(id, page, size,) {
  return request({
    url: "api/datafeed/addresslist/customdata/src/" + id + "?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function getAddresslistsDst(id, page, size,) {
  return request({
    url: "api/datafeed/addresslist/customdata/dst/" + id + "?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function storeAddressListData(data) {
  return request({
    url: "api/datafeed/addresslist/customip",
    method: "post",
    data: data,
  });
}

export function getAddressList(id) {
  return request({
    url: "api/datafeed/addresslist/custom/" + id,
    method: "get",
  });
}

export function getAddressListName(id) {
  return request({
    url: "api/datafeed/addresslist/custom_name/" + id,
    method: "get",
  });
}

export function storeAddressList(data) {
  return request({
    url: "api/datafeed/addresslist/custom",
    method: "post",
    data: data,
  });
}

export function updateAddressList(data, id) {
  return request({
    url: "api/datafeed/addresslist/custom/" + id,
    method: "post",
    data: data,
  });
}

export function deleteCustomAddressIp(id) {
  return request({
    url: "api/datafeed/addresslist/customip/" + id,
    method: "delete",
  });
}

export function deleteAddresslist(id) {
  return request({
    url: "api/datafeed/addresslist/custom/" + id,
    method: "delete",
  });
}
