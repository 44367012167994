import request from "@/utils/request";

export function getProfiles(page, size) {
  return request({
    url: "api/object/profiles?page=" + page + "&size=" + size,
    method: "get",
  });
}

export function getProfileList() {
  return request({
    url: "api/object/profilelist",
    method: "get",
  });
}

export function getProfile(id) {
  return request({
    url: "api/object/profile/" + id,
    method: "get",
  });
}

export function storeProfile(data) {
  return request({
    url: "api/object/profile/store",
    method: "post",
    data: data,
  });
}

export function updateProfile(data, id) {
  return request({
    url: "api/object/profile/update/" + id,
    method: "put",
    data: data,
  });
}

export function deleteProfile(id) {
  return request({
    url: "api/object/profile/destroy/" + id,
    method: "delete",
  });
}

export function getObjectList() {
  return request({
    url: "api/object/objectlist",
    method: "get",
  });
}

export function getSeverityList() {
  return request({
    url: "api/object/severitylist",
    method: "get",
  });
}
