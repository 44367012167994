<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pt-5 pb-0 pl-6">
            <h3 class="font-ubuntu-bold">
              {{ id === 0 ? "Add new listener" : "Edit listener" }}
            </h3>
          </v-col>

          <v-col cols="12">
            <v-form ref="form" v-model="validCheck" lazy-validation>
              <!--TITLE-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Title</label
                  >
                  <v-text-field
                    v-model="title"
                    :rules="titleRules"
                    label="Title"
                    placeholder="Title"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END TITLE-->

              <!--DESCRIPTION-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Description</label
                  >
                  <v-textarea
                    v-model="description"
                    :rules="descriptionRules"
                    placeholder="Description"
                    auto-grow
                    rows="2"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END DESCRIPTION-->

              <!--UPLOAD-->
              <v-row class="mx-0 mt-3">
                <v-col cols="12" class="py-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Python script</label
                  >
                  <v-file-input
                    v-model="filename"
                    :rules="filenameRules"
                    accept=".py"
                    placeholder="Choose .py file"
                    dense
                    solo
                  />
                </v-col>
              </v-row>
              <!--END UPLOAD-->

              <v-row class="mx-0 mt-6">
                <v-col cols="12" class="py-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    {{ id === 0 ? "Create" : "Update" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import {
  getListener,
  updateListener,
  storeListener,
} from "@/api/settings/listener";

import { Errors } from "@/utils/error";
export default {
  name: "index",
  components: {
    Topbar,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,

      valid: true,
      validCheck: true,

      saving: false,

      title: "",
      titleRules: [(v) => !!v || "Title is required"],
      description: "",
      descriptionRules: [(v) => !!v || "Description is required"],
      filename: null,
      filenameRules: [(v) => !!v || "File is required"],
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        if (this.id === 0) {
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "listener");
    },
    loadById() {
      this.loading = true;
      getListener(this.id)
        .then((response) => {
          this.title = response.data.title;
          this.description = response.data.description;
          this.filename = null;
          this.checkValid = true;
          this.loading = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    validateCheck() {
      this.$refs.formCheck.validate();
      if (this.$refs.formCheck.validate()) {
        this.check();
      }
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        if (this.id === 0) {
          this.store();
        } else {
          this.update();
        }
      }
    },
    store() {
      this.saving = true;
      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("title", this.title);
      formData.append("description", this.description);
      formData.append("filename", this.filename);
      storeListener(formData)
        .then(() => {
          this.$toast.open({
            message: "Listener was successfully created!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    update() {
      this.saving = true;
      let formData = new FormData();
      formData.append("id", this.id);
      formData.append("title", this.title);
      formData.append("description", this.description);
      formData.append("filename", this.filename);
      updateListener(formData, this.id)
        .then(() => {
          this.$toast.open({
            message: "Listener was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped></style>
