import request from '@/utils/request';

export function getAddresses(page, size) {
	return request({
		url: 'api/object/address?page=' + page + '&size=' + size,
		method: 'get',
	});
}

export function getAddress(id) {
	return request({
		url: 'api/object/address/' + id,
		method: 'get',
	});
}
export function getObjectAddressList() {
	return request({
		url: 'api/object/address-list',
		method: 'get',
	});
}

export function storeAddress(data) {
  return request({
    url: 'api/object/address',
    method: 'post',
    data: data,
  });
}

export function updateAddress(data, id) {
  return request({
    url: 'api/object/address/' + id,
    method: 'put',
    data: data,
  });
}

export function deleteAddress(id) {
	return request({
		url: 'api/object/address/' + id,
		method: 'delete',
	});
}
