<template>
  <v-container :fluid="true" class="px-0 py-0">
    <!--NAME-->
    <v-row class="mx-0 mt-0">
      <v-col cols="12" class="pb-0">
        <label class="font-roboto-condensed-regular font-size-15">Name</label>
        <v-text-field
          v-model="name"
          :rules="nameRules"
          label="Name"
          placeholder="Name"
          dense
          solo
          @keyup="update"
        />
      </v-col>
    </v-row>
    <!--END NAME-->

    <!--DESCRIPTION-->
    <v-row class="mt-0 mx-0">
      <v-col cols="12" class="pb-0">
        <label class="font-roboto-condensed-regular font-size-15"
          >Description</label
        >
        <v-textarea
          v-model="description"
          placeholder="Description"
          auto-grow
          rows="2"
          dense
          solo
          @keyup="update"
        />
      </v-col>
    </v-row>
    <!--END DESCRIPTION-->

    <!--ACTIVE-->
    <v-row class="mt-0 mx-0">
      <v-col cols="12" class="pb-0">
        <label class="font-roboto-condensed-regular font-size-15">Active</label>
          <div class="toggle">
            <v-switch
            v-model="active"
            :value="true"
            hide-details
            label="Active profile"
            @change="update"
            inset
            :class="[active ? 'v-switch-on' : 'v-switch-off']"
            class="target-background v-switch-off"
          />
      </div>
      </v-col>
    </v-row>
    <!--END ACTIVE-->
  </v-container>
</template>

<script>
export default {
  props: {
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      valid: true,

      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      description: "",
      active: true,
    };
  },
  created() {
    if (this.mountData) {
      this.name = this.mountData.name;
      this.description = this.mountData.description;
      this.active = this.mountData.active;
    }
  },
  methods: {
    update() {
      this.$emit("update", {
        name: this.name,
        description: this.description,
        active: this.active,
      });
    },
  },
};
</script>
