import request from "@/utils/request";

export function storeMiner(data) {
  return request({
    url: "api/settings/miner",
    method: "post",
    data: data,
  });
}

export function updateMiner(data, id) {
  return request({
    url: "api/settings/miner/" + id,
    method: "put",
    data: data,
  });
}

export function checkMinerCredentials(data) {
  return request({
    url: "api/settings/checkMinerCredentials",
    method: "post",
    data: data,
  });
}

export function getMinerPerspective(data) {
  return request({
    url: "api/settings/getMinerPerspective",
    method: "post",
    data: data,
  });
}

export function getMiner(id) {
  return request({
    url: "api/settings/miner/" + id,
    method: "get",
  });
}

export function getMiners(page, size, order, by) {
  const orderBy = order && by ? "&order=" + order + "&by=" + by : "";
  return request({
    url: "api/settings/miner?page=" + page + "&size=" + size + orderBy,
    method: "get",
  });
}

export function getMinersList() {
  return request({
    url: "api/settings/minerlist",
    method: "get",
  });
}

export function getMinerTags(id) {
  return request({
    url: "api/settings/minertags/" + id,
    method: "get",
  });
}
export function deleteMiner(id) {
  return request({
    url: "api/settings/miner/" + id,
    method: "delete",
  });
}
