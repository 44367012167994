var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.mini)?_c('v-tooltip',{attrs:{"right":"","color":"primary","open-delay":"1"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-group',_vm._g(_vm._b({attrs:{"value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[(
              _vm.route.name === 'monitor' &&
              _vm.alertCount.total > 0
            )?_c('v-badge',{attrs:{"color":"bad-3","dot":""}},[_c('v-avatar',{staticClass:"br-0",attrs:{"size":"20"}},[_c('img',{attrs:{"src":_vm.childrenActive()
                    ? _vm.location + '/images/icons/green/sidebar/' + _vm.route.icon
                    : _vm.location + _vm.iconRoute + _vm.route.icon,"alt":"Add"}})])],1):_c('v-avatar',{staticClass:"br-0",attrs:{"size":"20"}},[_c('img',{attrs:{"src":_vm.childrenActive()
                  ? _vm.location + '/images/icons/green/sidebar/' + _vm.route.icon
                  : _vm.location + _vm.iconRoute + _vm.route.icon,"alt":"Add"}})])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("sidebar." + _vm.route.name)))])]},proxy:true}],null,true)},'v-list-group',attrs,false),on),[_c('div',{staticClass:"list-group-body"},_vm._l((_vm.route.children),function(child){return _c('v-list-item',{key:child.id,class:{ active: _vm.active.includes(child.id) },attrs:{"to":child.route,"color":"#fff","link":""}},[_c('v-list-item-icon',[(_vm.mini)?_c('v-tooltip',{attrs:{"right":"","color":"primary","open-delay":"1","nudge-right":"20"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                    (child.name === 'auditorAlert' &&
                      _vm.alertCount.auditor > 0) ||
                    (child.name === 'interconnectorAlert' &&
                      _vm.alertCount.interconnector > 0)
                  )?_c('v-badge',{attrs:{"color":"bad-3","dot":""}},[_c('v-avatar',_vm._g(_vm._b({staticClass:"br-0",attrs:{"size":"20"}},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":_vm.active.includes(child.id)
                          ? _vm.location +
                            '/images/icons/green/sidebar/' +
                            child.icon
                          : _vm.location +
                            '/images/icons/white/sidebar/' +
                            child.icon,"alt":"Add"}})])],1):_c('v-avatar',_vm._g(_vm._b({staticClass:"br-0",attrs:{"size":"20"}},'v-avatar',attrs,false),on),[_c('img',{attrs:{"src":_vm.active.includes(child.id)
                        ? _vm.location +
                          '/images/icons/green/sidebar/' +
                          child.icon
                        : _vm.location +
                          '/images/icons/white/sidebar/' +
                          child.icon,"alt":"Add"}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("sidebar." + child.name)))])]):_c('v-avatar',{staticClass:"br-0",attrs:{"size":"20"}},[_c('img',{attrs:{"src":_vm.active.includes(child.id)
                    ? _vm.location + '/images/icons/green/sidebar/' + child.icon
                    : _vm.location + '/images/icons/white/sidebar/' + child.icon,"alt":"Add"}})])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("sidebar." + child.name)))])],1)],1)}),1)])]}}],null,false,2935207148)},[_c('span',[_vm._v(_vm._s(_vm.$t("sidebar." + _vm.route.name)))])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }