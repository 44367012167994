<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pb-2 pt-3">
            <h3 class="font-ubuntu-bold">
              {{
                id === 0 ? "Add new integrity Check" : "Edit integrity Check"
              }}
            </h3>
          </v-col>
          <v-col cols="12" class="pt-0 pb-2 pl-6">
            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'general'"
              :color="activeTab === 'general' ? 'primary' : ''"
              :outlined="activeTab === 'general'"
              @click="activeTab = 'general'"
            >
              <span>General</span>
            </v-btn>

            <v-btn
              class="text-capitalize mr-2 synapsa-tab"
              :text="activeTab !== 'help'"
              :color="activeTab === 'help' ? 'primary' : ''"
              :outlined="activeTab === 'help'"
              @click="
                () => {
                  this.activeTab = 'help';
                  this.resize('bigger');
                }
              "
            >
              <span>Help</span>
            </v-btn>
          </v-col>
          <v-col v-if="activeTab === 'general'" cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <!--General settings-->
                <v-col cols="6">
                  <div class="section">
                    <span class="section-title font-ubuntu-bold font-size-16"
                      >General settings</span
                    >
                    <!--NAME-->
                    <v-row class="mx-0 mt-0">
                      <v-col cols="12" class="py-0 px-0">
                        <label
                          class="font-roboto-condensed-regular font-size-15"
                          >Name</label
                        >
                        <v-text-field
                          v-model="name"
                          :rules="nameRules"
                          label="Name"
                          placeholder="Name"
                          dense
                          solo
                        />
                      </v-col>
                    </v-row>
                    <!--END NAME-->

                    <!--DESCRIPTION-->
                    <v-row class="mt-0 mx-0">
                      <v-col cols="12" class="py-0 px-0">
                        <label
                          class="font-roboto-condensed-regular font-size-15"
                          >Description</label
                        >
                        <v-textarea
                          v-model="description"
                          placeholder="Description"
                          auto-grow
                          rows="3"
                          dense
                          solo
                        />
                      </v-col>
                    </v-row>
                    <!--END DESCRIPTION-->

                    <!--AUDITOR GROUPS-->
                    <transition
                      enter-active-class="animate__animated animate__fadeIn"
                      leave-active-class="d-none"
                    >
                      <v-row class="mt-0 mx-0">
                        <v-col cols="12" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >Auditor Group</label
                          >
                          <v-autocomplete
                            v-model="auditorGroup"
                            :items="auditorGroups"
                            item-text="name"
                            item-value="id"
                            placeholder="Auditor group"
                            multiple
                            outlined
                            @change="checkLoading = false"
                            class="auditor-group"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                small
                                color="primary"
                                class="elevation-2 synapsa-chip"
                              >
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <v-chip
                                small
                                color="primary"
                                class="elevation-2 synapsa-chip"
                              >
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </transition>
                    <!--AUDITOR GROUPS-->
                  </div>
                </v-col>
                <!--Other settings-->
                <v-col cols="6">
                  <div class="section min-h-280">
                    <span class="section-title font-ubuntu-bold font-size-16"
                      >Other settings</span
                    >
                    <!--conditions-->
                    <v-row class="mt-0 mx-0">
                      <v-col cols="12" class="pt-0 px-0">
                        <label
                          class="font-roboto-condensed-regular font-size-15"
                          >Enable conditions</label
                        >
                        <div class="toggle">
                          <v-switch
                            v-model="conditionsEnabledCheckbox"
                            :value="true"
                            hide-details
                            label="Enabled"
                            inset
                            :class="[
                              conditionsEnabledCheckbox
                                ? 'v-switch-on'
                                : 'v-switch-off',
                            ]"
                            class="target-background v-switch-off"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-if="conditionsEnabled" class="mt-2 mx-0">
                      <v-col cols="12" class="py-0 px-0">
                        <label
                          class="font-roboto-condensed-regular font-size-15"
                          >Conditions</label
                        >
                        <v-textarea
                          v-model="conditions"
                          placeholder="Conditions"
                          auto-grow
                          rows="3"
                          dense
                          :rules="conditionRules"
                          solo
                        />
                      </v-col>
                    </v-row>
                    <!--END conditions-->

                    <!--SEVERITY-->
                    <v-row class="mt-2 mx-0">
                      <v-col cols="8" class="pb-0 py-0 px-0 pr-2">
                        <label
                          class="font-roboto-condensed-regular font-size-15"
                          >Severity</label
                        >
                        <v-autocomplete
                          v-model="severity"
                          :items="severities"
                          :rules="severityRules"
                          item-text="name"
                          item-value="id"
                          label="Severity"
                          placeholder="Severity"
                          dense
                          solo
                          @change="onChangeSeverity"
                        >
                          <template v-slot:selection="data">
                            <v-icon
                              :class="severityClass(data.item.id)"
                              small
                              class="mr-2 severity-icon"
                            >
                              mdi-view-grid
                            </v-icon>
                            <span
                              class="default--text font-roboto-condensed-light"
                              >{{ data.item.name }}</span
                            >
                          </template>
                          <template v-slot:item="data">
                            <v-icon
                              :class="severityClass(data.item.id)"
                              small
                              class="mr-2 severity-icon"
                            >
                              mdi-view-grid
                            </v-icon>
                            <span
                              class="default--text font-roboto-condensed-light"
                              >{{ data.item.name }}</span
                            >
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4" class="pb-0 py-0 px-0">
                        <label
                          class="font-roboto-condensed-regular font-size-15"
                          >Score multiplier</label
                        >
                        <v-text-field
                          v-model="scoreMultiplier"
                          :rules="scoreMultiplierRules"
                          :disabled="severity < 4"
                          :min="severity"
                          label="Score multiplier"
                          placeholder="Score multiplier"
                          hide-details
                          single-line
                          type="number"
                          solo
                          dense
                        />
                      </v-col>
                    </v-row>
                    <!--END SEVERITY-->

                    <!--STATE-->
                    <v-row class="mt-0 mx-0">
                      <v-col cols="12" class="pt-0 px-0">
                        <label
                          class="font-roboto-condensed-regular font-size-15"
                          >State</label
                        >
                        <div class="toggle">
                          <v-switch
                            v-model="active"
                            :value="true"
                            hide-details
                            label="Active"
                            inset
                            :class="[active ? 'v-switch-on' : 'v-switch-off']"
                            class="target-background v-switch-off"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <!--END STATE-->
                  </div>
                </v-col>
                <!--Firewall settings-->
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="section">
                    <span class="section-title font-ubuntu-bold font-size-16"
                      >Firewall settings</span
                    >
                    <!--VENDOR-->
                    <v-row>
                      <v-col cols="6">
                        <v-row class="mt-0 mx-0">
                          <v-col cols="12" class="py-0 px-0">
                            <label
                              class="font-roboto-condensed-regular font-size-15"
                              >Vendor</label
                            >
                            <v-select
                              v-model="vendor"
                              :items="vendors"
                              :rules="vendorRules"
                              disabled
                              item-text="name"
                              item-value="id"
                              label="Vendor"
                              placeholder="Vendor"
                              dense
                              solo
                            />
                          </v-col>
                        </v-row>
                        <!--END VENDOR-->

                        <v-row class="mx-0 mt-0">
                          <v-col cols="12" class="py-0">
                            <v-radio-group v-model="fwType" row class="mt-2">
                              <v-radio
                                label="Firewall"
                                value="firewall"
                                selected
                                @click="fwType = 'firewall'"
                              />
                              <v-radio
                                label="Firewall group"
                                value="group"
                                @click="fwType = 'group'"
                              />
                            </v-radio-group>
                          </v-col>
                        </v-row>

                        <!--FIREWALLS-->
                        <transition
                          enter-active-class="animate__animated animate__fadeIn"
                          leave-active-class="d-none"
                          v-if="fwType === 'firewall'"
                        >
                          <v-row class="mt-0 mx-0">
                            <v-col cols="12" class="py-0 px-0">
                              <label
                                class="font-roboto-condensed-regular font-size-15"
                                >Firewall</label
                              >
                              <p class="mb-0">
                                <label
                                  class="font-roboto-condensed-regular font-size-12"
                                  >Expected config will be read from the first
                                  device</label
                                >
                              </p>

                              <v-autocomplete
                                v-model="firewall"
                                :items="firewalls"
                                :rules="firewallRules"
                                item-text="name"
                                item-value="id"
                                placeholder="Firewall"
                                multiple
                                outlined
                                @change="checkLoading = false"
                                class="auditor-group"
                              >
                                <template v-slot:selection="data">
                                  <v-chip
                                    small
                                    color="primary"
                                    class="elevation-2 synapsa-chip"
                                  >
                                    {{ data.item.name }}
                                  </v-chip>
                                </template>
                                <template v-slot:item="data">
                                  <v-chip
                                    small
                                    color="primary"
                                    class="elevation-2 synapsa-chip"
                                  >
                                    {{ data.item.name }}
                                  </v-chip>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                        </transition>
                        <!--END FIREWALLS-->

                        <!--FIREWALL GROUPS-->
                        <transition
                          enter-active-class="animate__animated animate__fadeIn"
                          leave-active-class="d-none"
                          v-if="fwType === 'group'"
                        >
                          <v-row class="mt-0 mx-0">
                            <v-col cols="12" class="py-0 px-0">
                              <label
                                class="font-roboto-condensed-regular font-size-15"
                                >Firewall Group</label
                              >
                              <p class="mb-0">
                                <label
                                  class="font-roboto-condensed-regular font-size-12"
                                  >Expected config will be read from the first
                                  device</label
                                >
                              </p>
                              <v-autocomplete
                                v-model="firewallGroup"
                                :items="firewallGroups"
                                :rules="firewallGroupsRules"
                                item-text="name"
                                item-value="id"
                                placeholder="Firewall group"
                                multiple
                                outlined
                                @change="checkLoading = false"
                              >
                                <template v-slot:selection="data">
                                  <v-chip
                                    small
                                    color="primary"
                                    class="elevation-2 synapsa-chip"
                                  >
                                    {{ data.item.name }}
                                  </v-chip>
                                </template>
                                <template v-slot:item="data">
                                  <v-chip
                                    small
                                    color="primary"
                                    class="elevation-2 synapsa-chip"
                                  >
                                    {{ data.item.name }}
                                  </v-chip>
                                </template>
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                        </transition>
                        <!--FIREWALL GROUPS-->
                      </v-col>
                      <v-divider
                        class="border-opacity-100"
                        vertical
                      ></v-divider>
                      <v-col col="6">
                        <!--CALL-->
                        <v-row class="mx-0 mt-0">
                          <v-col cols="12" class="py-0 px-0">
                            <label
                              class="font-roboto-condensed-regular font-size-15"
                              >Call</label
                            >
                            <v-select
                              v-model="apicall"
                              :items="apicalls"
                              :rules="apicallRules"
                              item-text="name"
                              item-value="id"
                              label="Call"
                              placeholder="Call"
                              dense
                              solo
                            />
                          </v-col>
                        </v-row>
                        <!--END CALL-->

                        <!--X-PATH TITLE-->
                        <v-row class="mt-0 mx-0">
                          <v-col cols="12" class="py-0 px-0">
                            <label
                              class="font-roboto-condensed-regular font-size-15"
                              >XPATH TITLE</label
                            >
                            <v-select
                              v-model="choosedXpath"
                              :items="xpaths"
                              :rules="choosedXpathRules"
                              item-text="title"
                              return-object
                              label="Xpath Title"
                              placeholder="Select and update an Xpath"
                              dense
                              solo
                            />
                          </v-col>
                        </v-row>
                        <!--X-PATH-->
                        <v-row class="mt-0 mx-0">
                          <v-col
                            v-if="choosedXpath"
                            cols="12"
                            class="py-0 px-0"
                          >
                            <label
                              class="font-roboto-condensed-regular font-size-15"
                              >XPATH BODY</label
                            >
                            <v-textarea
                              v-model="choosedXpath.xpath"
                              :rules="choosedXpathRules"
                              placeholder="Content"
                              auto-grow
                              rows="3"
                              dense
                              solo
                            />
                          </v-col>
                        </v-row>
                        <!--END X-PATH-->
                      </v-col>

                      <v-col cols="12">
                        <v-divider></v-divider>
                        <!--WITHOUT CONTENT-->
                        <v-row class="mt-0 mx-0">
                          <v-col cols="12" class="pt-0 px-0">
                            <div class="toggle">
                              <v-switch
                                v-model="withoutContentCheckbox"
                                :value="true"
                                hide-details
                                label="Store without content"
                                inset
                                :class="[
                                  withoutContentCheckbox
                                    ? 'v-switch-on'
                                    : 'v-switch-off',
                                ]"
                                class="target-background v-switch-off"
                              />
                            </div>
                          </v-col>
                        </v-row>
                        <!--END WITHOUT CONTENT-->

                        <!--NORMALIZE-->
                        <v-row class="mt-0 mx-0">
                          <v-col cols="12" class="py-0 px-0">
                            <div class="toggle">
                              <v-switch
                                v-model="normalizeXml"
                                :true-value="true"
                                :false-value="false"
                                hide-details
                                label="Normalize content"
                                inset
                                :class="[
                                  normalizeXml ? 'v-switch-on' : 'v-switch-off',
                                ]"
                                class="target-background v-switch-off"
                              />
                            </div>
                          </v-col>
                        </v-row>
                        <!--END NORMALIZE-->

                        <div v-if="!withoutContent">
                          <!--FIREWALL CHECK-->
                          <transition
                            enter-active-class="animate__animated animate__fadeIn"
                            leave-active-class="d-none"
                          >
                            <v-row v-if="firewall && choosedXpath" class="mt-5">
                              <v-col cols="12" class="pt-0">
                                <v-btn
                                  color="primary"
                                  class="text-capitalize mb-2"
                                  @click="validateCheck"
                                >
                                  Check response
                                </v-btn>
                                <v-icon
                                  v-if="checkLoading"
                                  color="primary"
                                  class="mb-2"
                                >
                                  fas fa-spinner fa-spin
                                </v-icon>
                                <span
                                  v-if="checkLoading"
                                  class="font-roboto-condensed-light font-size-15 ml-2"
                                >
                                  {{ checkMessage }}
                                </span>
                              </v-col>
                            </v-row>
                          </transition>
                          <!--END FIREWALL CHECK-->

                          <v-row class="mt-0 mx-0">
                            <!--TABS-->
                            <v-col cols="12" class="pb-0">
                              <v-btn
                                class="text-capitalize mr-2"
                                :text="contentTab !== 'format'"
                                :color="
                                  contentTab === 'format' ? 'primary' : ''
                                "
                                :outlined="contentTab === 'format'"
                                rounded
                                @click="contentTab = 'format'"
                              >
                                <span class="font-ubuntu-bold">Format</span>
                              </v-btn>
                              <v-btn
                                class="text-capitalize mr-2"
                                :text="contentTab !== 'raw'"
                                :color="contentTab === 'raw' ? 'primary' : ''"
                                :outlined="contentTab === 'raw'"
                                rounded
                                @click="contentTab = 'raw'"
                              >
                                <span class="font-ubuntu-bold">Raw</span>
                              </v-btn>
                            </v-col>
                            <!--END TABS-->
                          </v-row>

                          <v-row v-if="contentTab === 'raw'" class="mt-4 mx-0">
                            <v-col cols="12" class="pt-0">
                              <transition
                                enter-active-class="animate__animated animate__fadeIn"
                                leave-active-class="d-none"
                              >
                                <!--CONTENT-->
                                <v-row class="mt-2 mx-0">
                                  <v-col cols="12" class="py-0 px-0">
                                    <label
                                      class="font-roboto-condensed-regular font-size-15"
                                      >Content</label
                                    >
                                    <v-btn
                                      small
                                      outlined
                                      class="ml-2 mb-1 btn-pencil"
                                      @click="isMask = !isMask"
                                    >
                                      <v-icon class="pencil" v-if="isMask"
                                        >mdi-pencil</v-icon
                                      ><v-icon class="pencil" v-else
                                        >mdi-pencil-off</v-icon
                                      >
                                    </v-btn>
                                    <v-textarea
                                      v-model="maskedContent"
                                      :rules="contentRules"
                                      placeholder="Content"
                                      auto-grow
                                      rows="11"
                                      dense
                                      solo
                                      :readonly="isMask"
                                    />
                                  </v-col>
                                </v-row>
                                <!--END CONTENT-->
                              </transition>
                            </v-col>
                          </v-row>

                          <v-row
                            v-if="contentTab === 'format'"
                            class="mt-4 mx-0"
                          >
                            <v-col cols="12" class="pt-0">
                              <transition
                                enter-active-class="animate__animated animate__fadeIn"
                                leave-active-class="d-none"
                              >
                                <div>
                                  <xml-viewer
                                    v-if="maskedContent"
                                    :xml="maskedContent"
                                    :theme="theme"
                                  />
                                  <span
                                    v-else
                                    class="py-4 font-roboto-condensed-regular font-size-15"
                                    >empty</span
                                  >
                                </div>
                              </transition>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-form>

            <v-row class="mt-1 mx-0">
              <v-col cols="12" class="py-0 px-0">
                <v-icon v-if="saving" color="primary">
                  fas fa-spinner fa-spin
                </v-icon>
                <span
                  v-if="saving"
                  class="font-roboto-condensed-light font-size-15 ml-2"
                >
                  Processing...
                </span>
                <v-btn
                  v-if="!saving"
                  :disabled="!checkButtonAvailability()"
                  color="primary"
                  class="text-capitalize"
                  @click="validate"
                >
                  {{
                    id === 0
                      ? "Create"
                      : checkButtonAvailability()
                      ? "Update"
                      : "Please check first"
                  }}
                </v-btn>
                <v-btn
                  v-if="!saving"
                  :disabled="!checkTestAvailability()"
                  text
                  outlined
                  color="primary"
                  class="text-capitalize ml-1"
                  @click="testIntegrity"
                >
                  <v-icon x-small class="mr-1">fas fa-play</v-icon>Test
                  Integrity
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-else-if="activeTab === 'help'"
            cols="12"
            class="px-6 font-roboto-condensed-regular font-size-15"
          >
            <span class="font-ubuntu-bold">How to add a custom condition:</span>
            <p>
              You need to enable conditions by checking the checkbox to appear
              the conditions input.
            </p>
            <p>
              Every condition has to be written in one line. First we name the
              condition which is located in the content as a name-tag. Then we
              provide a type, which condition should compare. Last but not least
              we need to make a decision higher, lower, equal or not equal or
              any other arithmetic operators. You can use even more complex
              conditions using regular expression, count (which can be also
              modified with arithmetic math signs, by default '==') or contains
              / notContains. Finally we need to define what the condition is
              compared to. Every part of the condition has to be strictly
              separated by pipes '|'!
            </p>
            <span class="font-ubuntu-bold">Example of conditions:</span>
            <p>
              minimum-length|int|>|8<br />
              status|string|==|succes<br />
              range|float|!=|12.5<br />
              control|bool|==|true<br />
              user-email|string|/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/|regex<br />
              user|int|count|5
              <small class="good-1--text"
                >(xml has to contain exactly 5 `user` tags)</small
              ><br />
              user|int|count>=|2
              <small class="good-1--text"
                >(xml has to contain at least 2 `user` tags)</small
              ><br />
              description|string|contains|word<br />
              description|string|notContains|word<br />
              error-message|empty<br />
              ip-address|notEmpty<br />
              high-availability/group/state-synchronizatison/ha2-keep-alive/enabled|string|==|no
              <small class="good-1--text">
                (XML has to contain exact path separated by '/' in custom
                condition to match the success expected result of the final tag) </small
              ><br />
            </p>
            <ol class="mb-2">
              <li>
                Note: supported types are: <b>[ string, bool, int, float ]</b>,
                everything else will be changed to type `string` by default.
              </li>
              <li>
                Note: supported comparison signs (conditions) are:
                <b>[ ==, !=, &gt;, &gt;=, &lt;, &lt;=, regex pattern ]</b>,
                everything else will be changed to not equals sign `!=` by
                default.
              </li>
              <li>
                Note: Regex is very specific and has to be checked by fourth
                condition value `regex` to verify and use this functionality
                further. Please use strict rules and do not forget the beginning
                and the end of the regex syntax. (/^ ... $/)
              </li>
            </ol>
            <span class="font-ubuntu-bold">What exactly It means:</span>
            <p>
              Let's look closer at this condition `minimum-length|int|>|8`. The
              algorithm will search for name-tag '&lt;minumum-length&gt;', then
              read the content inside and It compares data with provided data
              type, in our case 'int', and expected value, in our case, higher
              than 8.
            </p>
            <span class="font-ubuntu-bold">How do I disable my condition?</span>
            <p>
              If we want to disable some of the provided conditions in our
              integrity check, we can simply delete the whole line, where the
              condition is written or we can just put the '#' mark before the
              line we dont want to check anymore.
            </p>
            <span class="font-ubuntu-bold">Example of disabled condition:</span>
            <p><span class="primary">#</span>minimum-length|int|>|8<br /></p>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <test-integrity
      v-if="!loading"
      @onClose="showTestIntegrityModal = false"
      :id="id"
      :show="showTestIntegrityModal"
      :auditor-data="getAuditorData()"
    />
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import TestIntegrity from "@/layout/FormSidebar/auditorintegrity/testIntegrity";
import { getFirewallListByVendor } from "@/api/settings/firewall";
import { Errors } from "@/utils/error";
import {
  getAuditor,
  storeAuditor,
  updateAuditor,
  getIntegrityXpath,
  checkAuditor,
} from "@/api/auditor/integrity";
import { getIntegrityCalls } from "@/api/settings/apicall";
import { getFirewallGroupListByVendor } from "@/api/settings/firewallGroup";
import { getAuditorGroups } from "@/api/auditor/group";
import { maskTags } from "@/utils/auditor/index";
import XmlViewer from "@/plugins/xmlViewer";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
    TestIntegrity,
    XmlViewer,
  },
  data() {
    return {
      permissions: localStorage.getItem("permissions").split(","),
      activeTab: "general",
      contentTab: "format",
      valid: true,
      saving: false,
      responseOK: false,
      loading: true,
      checkLoading: false,
      checkMessage: null,

      name: "",
      nameRules: [(v) => !!v || "Name is required"],
      description: "",
      fwType: "firewall",
      choosedXpath: undefined,
      xpaths: [],
      choosedXpathRules: [(v) => !!v || "XPATH is required"],
      content: "",
      maskedTags: [],
      mask: "",
      contentRules: [(v) => !!v || "Content is required"],
      severity: 1,
      severityRules: [(v) => !!v || "Severity is required"],
      severities: [
        { id: 1, name: "Low", color: "good-3" },
        { id: 2, name: "Medium", color: "medium-3" },
        { id: 3, name: "High", color: "high-3" },
        { id: 4, name: "Critical", color: "bad-3" },
      ],
      scoreMultiplier: 1,
      scoreMultiplierRules: [(v) => !!v || "Score multiplier is required"],
      vendor: 1,
      vendorRules: [(v) => !!v || "Vendor is required"],
      vendors: [{ id: 1, name: "Palo Alto" }],
      apicall: null,
      apicallRules: [(v) => !!v || "Call is required"],
      apicalls: [],
      firewall: [],
      firewallGroup: [],
      auditorGroup: [],
      firewallRules: [(v) => (!!v && !!v.length) || "Firewalls are required"],
      firewalls: [],
      firewallGroups: [],
      auditorGroups: [],
      firewallGroupsRules: [
        (v) => (!!v && !!v.length) || "Firewall groups are required",
      ],
      relatedFirewalls: [],
      active: true,
      conditionsEnabled: false,
      conditions: "",
      conditionRules: [(v) => !!v || "Conditions are required"],

      showTestIntegrityModal: false,
      isMask: true,
      withoutContent: false,
      normalizeXml: false,
      normalizedContent: null,
    };
  },
  watch: {
    id: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.loading = true;
        this.activeTab = "general";
        if (newValue === 0) {
          this.name = "";
          this.description = "";
          this.vendor = 1;
          this.apicall = null;
          this.firewall = [];
          this.$nextTick(() => {
            this.loading = false;
          });
        } else if (newValue) {
          this.loadById();
        }
      },
    },
  },
  computed: {
    maskedContent: {
      get() {
        return this.getContentWithMaskedTags();
      },
      set(value) {
        if (this.normalizeXml) {
          this.normalizedContent = value;
        } else {
          this.content = value;
        }
      },
    },
    theme() {
      return this.$vuetify.theme.isDark ? "dark" : "light";
    },
    withoutContentCheckbox: {
      get() {
        return this.withoutContent;
      },
      set(value) {
        this.withoutContent = value;
        if (value) {
          this.conditionsEnabled = true;
        }
      },
    },
    conditionsEnabledCheckbox: {
      get() {
        return this.conditionsEnabled;
      },
      set(value) {
        this.conditionsEnabled = value;
        if (!value) {
          this.withoutContent = false;
        }
      },
    },
  },
  created() {
    this.loadFirewalls();
    this.loadFirewallGroups();
    this.loadXpath();
    this.loadApicalls();
    this.loadAuditorGroups();
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "integrity");
    },

    loadById() {
      getAuditor(this.id)
        .then((response) => {
          this.loading = false;
          const auditor = response.data;
          this.name = auditor.name;
          this.description = auditor.description;
          this.apicall = auditor.apicallId;
          this.choosedXpath = { title: undefined, xpath: auditor.xpath };
          this.content = auditor.content;
          this.normalizedContent = auditor.content;
          this.normalizeXml = auditor.normalized;
          this.maskedTags = auditor.maskedTags;
          this.mask = auditor.mask;
          this.severity = auditor.severity;
          this.scoreMultiplier = auditor.scoreMultiplier;
          this.active = auditor.active;
          this.firewall = auditor.relatedFirewall;
          this.firewallGroup = auditor.relatedFirewallGroup;
          this.auditorGroup = auditor.groups;
          this.fwType = auditor.relatedFirewallType;

          this.conditions = auditor.conditions
            .join("\n")
            .replace("&gt;", ">")
            .replace("&lt;", "<");
          this.conditionsEnabled = this.conditions.length > 0;
          this.withoutContent = !this.content;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    loadFirewalls() {
      if (this.vendor) {
        getFirewallListByVendor(this.vendor)
          .then((response) => {
            this.firewalls = response.data;
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
    loadFirewallGroups() {
      getFirewallGroupListByVendor(this.vendor)
        .then((response) => {
          this.firewallGroups = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadApicalls() {
      if (this.vendor) {
        getIntegrityCalls(this.vendor)
          .then((response) => {
            this.apicalls = response.data;
            if (!this.apicall) {
              this.apicall = this.apicalls.find(
                (api) =>
                  api.id === 125 || api.name === "PAN-Show-Config-effective"
              ).id;
            }
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
    loadXpath() {
      if (this.vendor) {
        getIntegrityXpath(this.vendor)
          .then((response) => {
            this.xpaths = response.data;
            if (!this.choosedXpath) {
              this.choosedXpath = this.xpaths.find(
                (item) =>
                  item.title === "CUSTOM" ||
                  item.xpath === "PLEASE INSERT YOUR XPATH VALUE"
              );
            }
          })
          .catch((e) => {
            Errors.show(e);
          });
      }
    },
    loadAuditorGroups() {
      getAuditorGroups()
        .then((response) => {
          this.auditorGroups = response;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    testIntegrity() {
      this.showTestIntegrityModal = true;
    },
    getAuditorData() {
      let conditions = [];

      if (this.conditionsEnabled && this.conditions.length > 0) {
        conditions = this.conditions.split("\n");
        conditions = conditions.filter((element) => {
          return element !== "";
        });
      }

      return {
        name: this.name,
        description: this.description,
        vendor: this.vendor,
        apicall: this.apicall,
        xpath: this.choosedXpath ? this.choosedXpath.xpath : undefined,
        content: this.normalizeXml ? this.normalizedContent : this.content,
        normalized: this.normalizeXml,
        severity: this.severity,
        scoreMultiplier: this.scoreMultiplier,
        active: this.active,
        firewall: this.firewall,
        fwType: this.fwType,
        firewallGroup: this.firewallGroup,
        auditorGroup: this.auditorGroup,
        withoutContent: this.withoutContent,
        conditions,
      };
    },
    save() {
      this.saving = true;
      const data = this.getAuditorData();

      if (!this.withoutContent && !this.content) {
        this.saving = false;
        this.$toast.open({
          message: "Missing content or custom conditions!",
          type: "error",
          position: "top-right",
          duration: 2000,
        });
      } else if (this.id === 0) {
        storeAuditor(data)
          .then(() => {
            this.saving = false;
            this.$toast.open({
              message: "Auditor Integrity Check was successfully created!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.close();
          })
          .catch((e) => {
            Errors.show(e);
            this.saving = false;
          });
      } else {
        updateAuditor(data, this.id)
          .then(() => {
            this.saving = false;
            this.$toast.open({
              message: "Auditor Integrity Check was successfully updated!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.close();
          })
          .catch((e) => {
            Errors.show(e);
            this.saving = false;
          });
      }
    },
    onChangeSeverity() {
      this.scoreMultiplier = this.severity;
    },
    validateCheck() {
      if (this.fwType === "firewall") {
        if (this.firewall === null || this.firewall.length === 0) {
          this.showNotify("Please select Firewall", "warning");
        }
      }
      if (this.fwType === "group") {
        if (this.firewallGroups === null || this.firewallGroups.length === 0) {
          this.showNotify("Please select Firewall Group", "warning");
        }
      }

      if (this.choosedXpath === undefined) {
        this.showNotify("Please select or fill Xpath", "warning");
      } else {
        this.checkMessage = "Checking ...";
        this.check();
      }
    },
    checkButtonAvailability() {
      return this.responseOK || this.checkAdvancedMode();
    },
    checkTestAvailability() {
      const hasContent =
        (this.responseOK || this.checkAdvancedMode()) &&
        this.content &&
        !this.withoutContent;
      const hasConditions = this.conditionsEnabled && this.conditions.length;
      const hasFirewalls = this.firewall.length || this.firewallGroup.length;
      return hasFirewalls && (hasContent || hasConditions);
    },
    checkAdvancedMode() {
      return this.checkPermission(["view advancedmode"]);
    },
    check() {
      this.checkLoading = true;
      const data = {
        type: this.fwType,
        firewall: this.firewall,
        firewallGroup: this.firewallGroup,
        apicall: this.apicall,
        xpath: this.choosedXpath.xpath,
      };
      checkAuditor(data)
        .then((response) => {
          this.checkLoading = false;
          this.content = response.data;
          this.normalizedContent = response.normalized;
          this.responseOK = true;
        })
        .catch((e) => {
          this.checkLoading = false;
          this.responseOK = false;
          if (e.response.status === 400) {
            this.content = e.response.data.message;
          } else {
            this.content = e.message;
          }
        });
    },
    showNotify(message, type) {
      this.$toast.open({
        message: message,
        type: type,
        position: "top-right",
        duration: 2000,
      });
    },
    checkPermission(perms) {
      return this.permissions.some((p) => perms.includes(p));
    },
    getContentWithMaskedTags() {
      if (this.normalizeXml) {
        if (
          this.normalizedContent &&
          this.maskedTags &&
          this.mask &&
          this.isMask
        ) {
          return maskTags(this.normalizedContent, this.maskedTags, this.mask);
        } else {
          return this.normalizedContent;
        }
      } else {
        if (this.content && this.maskedTags && this.mask && this.isMask) {
          return maskTags(this.content, this.maskedTags, this.mask);
        } else {
          return this.content;
        }
      }
    },
    severityClass(status) {
      if (status === 1) {
        return "signal-icon--low";
      } else if (status === 2) {
        return "signal-icon--medium";
      } else if (status === 3) {
        return "signal-icon--high";
      } else {
        return "signal-icon--critical";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-pencil {
  height: 18px !important;
  min-width: 14px !important;
  padding: 0 12px !important;
}
.pencil {
  font-size: 14px !important;
}
.severity-icon {
  font-size: 24px !important;
}
.section {
  margin: 1rem 0;
  position: relative;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
}
.theme--dark {
  .section {
    background: #3e3e3e;
    box-shadow: 0px 0px 5px 0px #3e3e3e;
  }
}
.theme--light {
  .section {
    background: #f4f4f4;
    box-shadow: 0px 0px 5px 0px #f4f4f4;
  }
}
.section-title {
  position: absolute;
  top: -1.4rem;
  left: 0.4rem;
}

::v-deep .v-input__slot {
  min-height: 38px !important;
}
.auditor-group ::v-deep {
  .v-input__append-inner {
    margin-top: 7px !important;
  }
}
.v-select__selections {
  padding: 0 !important;
}
.toggle {
  .v-input--selection-controls {
    margin-top: -7px;
  }
}
.min-h-304 {
  min-height: 304px;
}
.min-h-375 {
  min-height: 375px;
}
</style>
