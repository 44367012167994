import request from "@/utils/request";

export function storeFirewallGroup(data) {
  return request({
    url: "api/settings/firewallgroup",
    method: "post",
    data: data,
  });
}

export function updateFirewallGroup(data, id) {
  return request({
    url: "api/settings/firewallgroup/" + id,
    method: "put",
    data: data,
  });
}

export function getFirewallGroup(id) {
  return request({
    url: "api/settings/firewallgroup/" + id,
    method: "get",
  });
}

export function getFirewallGroups(page, size, order, by) {
  const orderBy = order && by ? "&order=" + order + "&by=" + by : "";
  return request({
    url: "api/settings/firewallgroup?page=" + page + "&size=" + size + orderBy,
    method: "get",
  });
}

export function getFirewallGroupsList() {
  return request({
    url: "api/settings/firewallgrouplist",
    method: "get",
  });
}

export function getFirewallCheckCalls(id) {
  return request({
    url: "api/settings/getFirewallCheckCall/" + id,
    method: "get",
  });
}

export function getFirewallVendors() {
  return request({
    url: "api/settings/vendorlist",
    method: "get",
  });
}

export function getFirewallGroupListByVendor(id) {
  return request({
    url: "api/settings/firewallgroups/listbyvendor/" + id,
    method: "get",
  });
}

export function checkFirewall(data) {
  return request({
    url: "api/settings/checkFirewall",
    method: "post",
    data: data,
  });
}

export function deleteFirewallGroup(id) {
  return request({
    url: "api/settings/firewallgroup/" + id,
    method: "delete",
  });
}
