<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pb-2 pt-3">
            <h3 class="font-ubuntu-bold">Set Synapsa Settings</h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--URL-->
              <v-row class="mx-0" :class="'mt-4'">
                <v-col cols="6" class="py-0 px-0 pr-2">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Application URL</label
                  >
                  <v-text-field
                    v-model="applicationUrl"
                    :rules="[applicationUrlRules.required]"
                    type="text"
                    label="Application URL"
                    placeholder="app.synapsa.tech"
                    dense
                    solo
                  />
                </v-col>

                <v-col cols="6" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Time format</label
                  >
                  <v-select
                    v-model="timeFormat"
                    :items="timeFormats"
                    item-text="name"
                    item-value="value"
                    label="Time Format"
                    placeholder="Y-m-d"
                    dense
                    solo
                  />
                </v-col>
                <!-- MAIL-->

                <v-col cols="6" class="py-0 px-0 pr-2">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Mail Server</label
                  >
                  <v-text-field
                    v-model="mailServer"
                    :rules="[mailServerRules.required, mailServerRulesValidate]"
                    type="text"
                    label="SMTP server"
                    placeholder="smpt.synapsa.tech"
                    dense
                    solo
                  />
                </v-col>

                <v-col cols="6" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Mail Login</label
                  >
                  <v-text-field
                    v-model="mailLogin"
                    type="text"
                    label="Login for SMTP"
                    placeholder="app@synapsa.tech"
                    dense
                    solo
                  />
                </v-col>

                <v-col cols="6" class="py-0 px-0 pr-2">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Mail Password</label
                  >
                  <v-text-field
                    v-model="mailPassword"
                    type="password"
                    label="Mail Password"
                    placeholder="yoursecretpassword"
                    dense
                    solo
                  />
                </v-col>

                <v-col cols="6" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Mail From Address</label
                  >
                  <v-text-field
                    v-model="mailFromAddress"
                    :rules="[mailFromAddressRules.required]"
                    type="text"
                    label="FROM Email Address"
                    placeholder="app@synapsa.tech"
                    dense
                    solo
                  />
                </v-col>

                <v-col cols="6" class="py-0 px-0 pr-2">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Mail Port</label
                  >
                  <v-text-field
                    v-model="mailPort"
                    :rules="[mailPortRules.required]"
                    type="number"
                    label="Port"
                    placeholder="587"
                    dense
                    solo
                  />
                </v-col>

                <v-col cols="6" class="py-0 px-0">
                  <label class="font-roboto-condensed-regular font-size-15"
                    >Mail Encryption</label
                  >
                  <v-select
                    v-model="mailEncryption"
                    :items="mailEncryptions"
                    item-text="name"
                    item-value="value"
                    label="Mail Encryption"
                    placeholder="TLS"
                    dense
                    solo
                  />
                </v-col>
              </v-row>

              <!--END MAIL-->
              <v-row class="mt-1 mx-0">
                <v-col cols="12" class="py-0 px-0">
                  <v-icon v-if="saving" color="primary">
                    fas fa-spinner fa-spin
                  </v-icon>
                  <span
                    v-if="saving"
                    class="font-roboto-condensed-light font-size-15 ml-2"
                  >
                    Processing...
                  </span>
                  <v-btn
                    v-if="!saving"
                    color="primary"
                    class="text-capitalize"
                    @click="validate"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";

import { Validation } from "@/utils/validation";
import { Errors } from "@/utils/error";
import { storeSynapsa, getSynapsa } from "@/api/system/synapsa";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      loading: true,
      checkLoading: false,
      checkValid: null,

      valid: true,
      validCheck: true,

      saving: false,

      applicationUrl: null,
      applicationUrlRules: {
        required: (v) => !!v || "Application Url is required",
      },
      mailServer: null,
      mailServerRules: {
        required: (v) => !!v || "Mail Server is required",
      },
      mailLogin: null,
      mailPassword: null,
      mailFromAddress: null,
      mailFromAddressRules: {
        required: (v) => !!v || "Mail From Address is required",
      },
      mailPort: null,
      mailPortRules: {
        required: (v) => !!v || "Mail Port is required",
      },
      mailEncryption: null,
      mailEncryptions: [
        { id: 1, value: "", name: "None" },
        { id: 2, value: "ssl", name: "SSL" },
        { id: 3, value: "tls", name: "TLS" },
        { id: 4, value: "starttls", name: "StartTLS" },
      ],
      timeFormat: null,
      timeFormats: [
        { id: 0, value: "Y-m-d", name: "Y-m-d (2021-01-01)" },
        { id: 1, value: "Y-M-d", name: "Y-M-d (2021-Jan-01)" },
        { id: 2, value: "d-m-Y", name: "d-m-Y (01-01-2021)" },
        { id: 3, value: "d-M-Y", name: "d-M-Y (01-Jan-2021)" },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "systemSynapsa");
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.store();
      }
    },
    load() {
      getSynapsa()
        .then((response) => {
          const synapsa = response.data;
          this.applicationUrl = synapsa.application_url;
          this.mailServer = synapsa.mail_server;
          this.mailLogin = synapsa.mail_login;
          this.mailPort = synapsa.mail_port;
          this.mailFromAddress = synapsa.mail_from_address;
          this.mailEncryption = synapsa.mail_encryption;
          this.timeFormat = synapsa.time_table_format;
          this.checkValid = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    store() {
      this.saving = true;
      const data = {
        application_url: this.applicationUrl,
        time_table_format: this.timeFormat,
        mail_server: this.mailServer,
        mail_login: this.mailLogin,
        mail_password: this.mailPassword,
        mail_from_address: this.mailFromAddress,
        mail_port: this.mailPort,
        mail_encryption: this.mailEncryption,
      };
      storeSynapsa(data)
        .then(() => {
          this.$toast.open({
            message: "Settings was successfully updated!",
            type: "success",
            position: "top-right",
            duration: 2000,
          });
          this.change();
          this.close();
        })
        .catch((e) => {
          Errors.show(e);
          this.saving = false;
        });
    },
    mailServerRulesValidate(val) {
      let domain = Validation.domain(val);
      let ip = Validation.ip(val);
      if (domain === true) {
        return true;
      } else if (ip === true) {
        return true;
      } else {
        return "Value must be IP or FQDN";
      }
    },
  },
};
</script>

<style lang="scss"></style>
