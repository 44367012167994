import request from "@/utils/request";

export function getAddressListsConnectionType(page, size) {
  return request({
    url: "api/datafeed/addresslist/connection?page=" + page + "&size=" + size,
    method: "get",
  });
}
export function getAddressListConnectionType(id) {
  return request({
    url: "api/datafeed/addresslist/connection/" + id,
    method: "get",
  });
}

export function storeAddressListConnectionType(data) {
  return request({
    url: "api/datafeed/addresslist/connection",
    method: "post",
    data: data,
  });
}

export function updateAddressListConnectionType(data, id) {
  return request({
    url: "api/datafeed/addresslist/connection/" + id,
    method: "post",
    data: data,
  });
}

export function deleteAddressListConnectionType(id) {
  return request({
    url: "api/datafeed/addresslist/connection/" + id,
    method: "delete",
  });
}

