import request from "@/utils/request";

export function getAddresslistPredefined(page, size, search) {
  const hasSearch = search ? "&searchTerm=" + search.searchTerm + "&advancedMode=" + (search.advancedMode ? 1 : 0) : '';
  return request({
    url: "api/datafeed/addresslist/predefined?page=" + page + "&size=" + size + hasSearch,
    method: "get",
  });
}

export function getAddresslistPredefinedData(id, type, page, size) {
  return request({
    url:
      "api/datafeed/addresslist/predefinedip/" +
      id +
      "/" +
      type +
      "?page=" +
      page +
      "&size=" +
      size,
    method: "get",
  });
}

export function getAddressListPredefinedName(id) {
  return request({
    url: "api/datafeed/addresslist/predefined_name/" + id,
    method: "get",
  });
}
