import request from "@/utils/request";

export function storeIntegration(data, id) {
  return request({
    url: "api/settings/integrations/" + id,
    method: "post",
    data: data,
  });
}

export function updateIntegration(data, id) {
  return request({
    url: "api/settings/integrations/" + id,
    method: "put",
    data: data,
  });
}

export function getIntegration(id) {
  return request({
    url: "api/settings/integrations/" + id,
    method: "get",
  });
}

export function getIntegrations(page, size) {
  return request({
    url: "api/settings/integrations?page=" + page + "&size=" + size,
    method: "get",
  });
}
export function getAllIntegrations() {
  return request({
    url: "api/settings/integrations",
    method: "get",
  });
}
