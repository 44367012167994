import Vue from "vue";
import App from "./App.vue";
import Cookies from "js-cookie";
import router from "@/router";
import vuetify from "@/plugins/vuetify";
import i18n from "@/lang";
import store from "./store";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import "animate.css/animate.min.css";
import "@/main.scss";
import "@/styles/synapsa.scss";
import "@/auth";
import { timeFormatPlugin } from '@/plugins/timeFormat.js';
import { convertToCsv } from '@/plugins/convertToCsv.js';

Vue.use(VueToast);
Vue.use(Cookies);
Vue.use(timeFormatPlugin);
Vue.use(convertToCsv);
Vue.use(require("vue-moment"));
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
  vuetify,
  i18n,
  store,
}).$mount("#app");
