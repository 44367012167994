<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar :size="size" @resize="resize($event)" @close="close" />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pb-2 pt-3">
            <h3 class="font-ubuntu-bold">Edit LDAP connection</h3>
          </v-col>

          <v-col cols="12" class="pt-0">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!--General settings-->
              <v-col class="px-0">
                <v-row>
                  <v-col cols="6">
                    <div class="section">
                      <span class="section-title font-ubuntu-bold font-size-16"
                        >General settings</span
                      >
                      <v-row class="mx-0 mt-0">
                        <v-col cols="12" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >Host</label
                          >
                          <v-text-field
                            v-model="host"
                            :rules="hostRules"
                            label="ldap.example.com"
                            placeholder="ldap.example.com"
                            dense
                            solo
                            @change="updateFrom"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="mx-0 mt-0">
                        <v-col cols="12" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >Port</label
                          >
                          <v-text-field
                            v-model="port"
                            label="389"
                            placeholder="389"
                            dense
                            solo
                            type="number"
                            min="1"
                            max="65535"
                            @change="updateFrom"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="mt-0 mx-0">
                        <v-col cols="3" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >Type</label
                          >
                          <v-select
                            v-model="type"
                            :items="types"
                            :rules="typeRules"
                            disabled
                            item-text="name"
                            item-value="id"
                            label="Type"
                            placeholder="Type"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col cols="4" class="py-0 px-10">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                          >SSL</label
                          >
                          <div class="toggle mt-2">
                            <v-switch
                              v-model="ssl"
                              :input-value="false"
                              :true-value="true"
                              :false-value="false"
                              :label="ssl ? 'yes' : 'no'"
                              hide-details
                              inset
                              :class="[
                                ssl ? 'v-switch-on' : 'v-switch-off',
                              ]"
                              class="target-background v-switch-off"
                            />
                          </div>
                        </v-col>
                        <v-col cols="5" class="py-0 px-10">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                          >Skip Verification</label
                          >
                          <div class="toggle mt-2">
                            <v-switch
                              v-model="skipCertificateValidation"
                              :input-value="false"
                              :true-value="true"
                              :false-value="false"
                              :label="skipCertificateValidation ? 'yes' : 'no'"
                              hide-details
                              inset
                              :class="[
                                skipCertificateValidation ? 'v-switch-on' : 'v-switch-off',
                              ]"
                              class="target-background v-switch-off"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>

                  <v-col>
                    <div class="section">
                      <span class="section-title font-ubuntu-bold font-size-16"
                        >Admin settings</span
                      >
                      <v-row class="mx-0 mt-0">
                        <v-col cols="12" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >Login</label
                          >
                          <v-text-field
                            v-model="adminLogin"
                            :rules="adminLoginRules"
                            label="cn=read-only-admin,dc=example,dc=com"
                            placeholder="cn=read-only-admin,dc=example,dc=com"
                            dense
                            solo
                            @change="updateFrom"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="mx-0 mt-0">
                        <v-col cols="12" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >Password</label
                          >
                          <v-text-field
                            v-model="adminPassword"
                            :rules="adminPasswordRules"
                            label="password"
                            placeholder="password"
                            type="password"
                            dense
                            solo
                            @change="updateFrom"
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <v-col class="px-0">
                <v-row>
                  <v-col cols="6">
                    <div class="section">
                      <span class="section-title font-ubuntu-bold font-size-16"
                        >Directory settings</span
                      >
                      <v-row class="mx-0 mt-0">
                        <v-col cols="12" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >Group DN</label
                          >
                          <v-text-field
                            v-model="groupDN"
                            :rules="groupDNRules"
                            label="ou=developers,dc=example,dc=com"
                            placeholder="ou=developers,dc=example,dc=com"
                            dense
                            solo
                            @change="updateFrom"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="mx-0 mt-0">
                        <v-col cols="12" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >User DN</label
                          >
                          <v-text-field
                            v-model="userDN"
                            :rules="userDNRules"
                            label="ou=users,dc=example,dc=com"
                            placeholder="ou=users,dc=example,dc=com"
                            dense
                            solo
                            @change="updateFrom"
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>

                  <v-col cols="6">
                    <div class="section">
                      <span class="section-title font-ubuntu-bold font-size-16"
                        >User Login test</span
                      >
                      <v-row class="mx-0 mt-0">
                        <v-col cols="12" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >Login</label
                          >
                          <v-text-field
                            v-model="userLogin"
                            :rules="userLoginRules"
                            label="testuser"
                            placeholder="testuser"
                            dense
                            solo
                          />
                        </v-col>
                      </v-row>
                      <v-row class="mx-0 mt-0">
                        <v-col cols="12" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >Password</label
                          >
                          <v-text-field
                            v-model="userPassword"
                            :rules="userPasswordRules"
                            label="password"
                            placeholder="password"
                            type="password"
                            dense
                            solo
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <v-col class="px-0">
                <v-row>
                  <v-col cols="6">
                    <div class="section">
                      <span class="section-title font-ubuntu-bold font-size-16"
                        >Login settings</span
                      >
                      <v-row class="mx-0 mt-0">
                        <v-col cols="12" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >Create user if not exist?</label
                          >
                          <div class="toggle">
                            <v-switch
                              v-model="createUser"
                              :input-value="false"
                              :true-value="true"
                              :false-value="false"
                              :label="createUser ? 'yes' : 'no'"
                              hide-details
                              inset
                              :class="[
                                createUser ? 'v-switch-on' : 'v-switch-off',
                              ]"
                              class="target-background v-switch-off"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="mt-6 mx-0">
                        <v-col cols="12" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >Role</label
                          >
                          <v-select
                            v-model="role"
                            :items="roles"
                            :rules="roleRules"
                            :disabled="!createUser"
                            item-text="name"
                            item-value="id"
                            label="Please Select Role"
                            placeholder="Please Select Role"
                            dense
                            solo
                          />
                        </v-col>
                      </v-row>

                      <v-row class="mx-0 mt-0">
                        <v-col cols="12" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >Enable login without LDAP for specific user?</label
                          >
                          <div class="toggle">
                            <v-switch
                              v-model="backupUser"
                              :input-value="false"
                              :true-value="true"
                              :false-value="false"
                              :label="backupUser ? 'yes' : 'no'"
                              hide-details
                              inset
                              :class="[
                                backupUser ? 'v-switch-on' : 'v-switch-off',
                              ]"
                              class="target-background v-switch-off"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <v-row class="mt-6 mx-0">
                        <v-col cols="12" class="py-0 px-0">
                          <label
                            class="font-roboto-condensed-regular font-size-15"
                            >User</label
                          >
                          <v-select
                            v-model="user"
                            :items="users"
                            :rules="userRules"
                            :disabled="!backupUser"
                            item-text="name"
                            item-value="id"
                            label="Please Select User"
                            placeholder="Please Select User"
                            dense
                            solo
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-form>

            <v-row class="mt-1 mx-0">
              <v-col cols="12" class="py-0 px-0">
                <v-icon v-if="saving" color="primary">
                  fas fa-spinner fa-spin
                </v-icon>
                <span
                  v-if="saving"
                  class="font-roboto-condensed-light font-size-15 ml-2"
                >
                  Processing...
                </span>
                <v-btn
                  v-if="!saving"
                  :disabled="!testIsSuccess"
                  color="primary"
                  class="text-capitalize"
                  @click="validate"
                >
                  {{ testIsSuccess ? "Save" : "Please check first" }}
                </v-btn>
                <span
                  v-if="checkLoading"
                  class="font-roboto-condensed-light font-size-15 ml-2"
                >
                  Processing...
                </span>
                <v-btn
                  v-if="!checkLoading"
                  :disabled="testIsSuccess"
                  text
                  outlined
                  color="primary"
                  class="text-capitalize ml-1"
                  @click="validateTest"
                >
                  <v-icon x-small class="mr-1">fas fa-play</v-icon>
                  Test Ldap
                </v-btn>
                <span
                  v-if="!checkLoading && resultCount > 0"
                  class="font-size-15 ml-1"
                  @click="showResult"
                  >Show result</span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="loading" class="mt-0 mx-0">
          <v-col cols="12" :class="size === 1 ? 'text-center' : ''">
            <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            <span
              v-if="size !== 1"
              class="font-roboto-condensed-light font-size-15 ml-2"
              >Loading...</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import { Errors } from "@/utils/error";
import { getLdap, storeLdap, testLdap } from "@/api/system/login";
import { getRoleList, getUserList } from "@/api/system/user";

export default {
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  components: {
    Topbar,
  },
  data() {
    return {
      permissions: localStorage.getItem("permissions").split(","),
      valid: true,
      saving: false,
      testIsSuccess: false,
      loading: true,
      checkLoading: false,
      resultCount: 0,
      checkMessage: null,
      content: null,

      host: "",
      hostRules: [(v) => !!v || "Host is required"],
      port: 389,
      adminLogin: null,
      adminLoginRules: [(v) => !!v || "Admin login is required"],
      adminPassword: null,
      adminPasswordRules: [(v) => !!v || "Admin password is required"],
      groupDN: null,
      groupDNRules: [(v) => !!v || "Group DN is required"],
      userDN: null,
      userDNRules: [(v) => !!v || "User DN is required"],
      userLogin: null,
      userLoginRules: [(v) => !!v || "User login is required for test"],
      userPassword: null,
      userPasswordRules: [(v) => !!v || "User password is required for test"],
      type: 1,
      typeRules: [(v) => !!v || "Type is required"],
      types: [{ id: 1, name: "LDAP" }],
      createUser: false,
      ssl: false,
      skipCertificateValidation: false,
      role: 0,
      roles: [],
      roleRules: [(v) => !this.createUser || !!v || "Rule is required"],
      backupUser: false,
      user: 0,
      users: [],
      userRules: [(v) => !this.backupUser || !!v || "User is required"],
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.isDark ? "dark" : "light";
    },
  },
  created() {
    this.loadData();
    this.loadRoles();
    this.loadUsers();
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "systemLdap");
    },

    loadData() {
      getLdap()
        .then((response) => {
          this.loading = false;
          const ldap = response.data;
          this.host = ldap.host;
          this.port = ldap.port === "" ? 0 : ldap.port;
          this.adminLogin = ldap.login;
          this.userDN = ldap.ldap_user_dn;
          this.groupDN = ldap.ldap_group_dn;
          this.createUser = ldap.create_user;
          this.ssl = ldap.ssl;
          this.skipCertificateValidation = ldap.skip_certificate_validation;
          this.role = ldap.role_id;
          this.backupUser = ldap.backup_user;
          this.user = ldap.user_id;
        })
        .catch((e) => {
          this.loading = false;
          Errors.show(e);
        });
    },
    loadRoles() {
      getRoleList()
        .then((response) => {
          this.roles = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    loadUsers() {
      getUserList()
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    updateFrom() {
      this.testIsSuccess = false;
    },
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.save();
      }
    },
    validateTest() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.testLdapSettings();
      }
    },
    showResult() {
      this.showNotify(
        this.content,
        this.testIsSuccess ? "success" : "error",
        10000
      );
    },
    getLdapData() {
      return {
        host: this.host,
        port: this.port,
        ldap_user_dn: this.userDN,
        ldap_group_dn: this.groupDN,
        admin_login: this.adminLogin,
        admin_password: this.adminPassword,
        user_login: this.userLogin,
        user_password: this.userPassword,
        create_user: this.createUser,
        ssl: this.ssl,
        skip_certificate_validation: this.skipCertificateValidation,
        role_id: this.role,
        backup_user: this.backupUser,
        user_id: this.user,
      };
    },
    save() {
      this.saving = true;
      const data = this.getLdapData();

      if (!this.withoutContent && !this.content) {
        this.saving = false;
        this.$toast.open({
          message: "Missing content!",
          type: "error",
          position: "top-right",
          duration: 2000,
        });
      } else {
        storeLdap(data)
          .then(() => {
            this.saving = false;
            this.$toast.open({
              message: "LDAP data was successfully created!",
              type: "success",
              position: "top-right",
              duration: 2000,
            });
            this.change();
            this.close();
          })
          .catch((e) => {
            Errors.show(e);
            this.saving = false;
          });
      }
    },
    testLdapSettings() {
      this.checkLoading = true;
      this.resultCount++;
      const data = this.getLdapData();
      testLdap(data)
        .then((response) => {
          this.checkLoading = false;
          this.testIsSuccess = true;
          this.content = response.msg;
          this.showNotify(this.content, "success", 10000);
        })
        .catch((e) => {
          this.checkLoading = false;
          this.testIsSuccess = false;
          if (e.response.status === 422) {
            this.content = e.response.data.msg;
          } else {
            this.content = e.message;
          }
          this.showNotify(this.content, "error", 10000);
        });
    },
    showNotify(message, type, duration) {
      this.$toast.open({
        message: message,
        type: type,
        position: "top-right",
        duration: duration,
      });
    },
    checkPermission(perms) {
      return this.permissions.some((p) => perms.includes(p));
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-pencil {
  height: 18px !important;
  min-width: 14px !important;
  padding: 0 12px !important;
}
.pencil {
  font-size: 14px !important;
}
.severity-icon {
  font-size: 24px !important;
}
.section {
  margin: 1rem 0;
  position: relative;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
}
.theme--dark {
  .section {
    background: #3e3e3e;
    box-shadow: 0px 0px 5px 0px #3e3e3e;
  }
}
.theme--light {
  .section {
    background: #f4f4f4;
    box-shadow: 0px 0px 5px 0px #f4f4f4;
  }
}
.section-title {
  position: absolute;
  top: -1.4rem;
  left: 0.4rem;
}

::v-deep .v-input__slot {
  min-height: 38px !important;
}
.auditor-group ::v-deep {
  .v-input__append-inner {
    margin-top: 7px !important;
  }
}
.v-select__selections {
  padding: 0 !important;
}
.toggle {
  .v-input--selection-controls {
    margin-top: -7px;
  }
}
</style>
