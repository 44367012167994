import store from "@/store";

export const Tables = {
  setDefaultSettings: () => {
    const tables = Object.keys(store.state.tables);
    let defaultSettings = {};
    for (let i = 0; i < tables.length; i++) {
      if (store.state.tables[tables[i]]) {
        const headers = [];
        store.state.tables[tables[i]].headers.map((column) => {
          headers.push({ ...column });
        });
        const expanded = [];
        store.state.tables[tables[i]].expanded.map((column) => {
          expanded.push({ ...column });
        });
        defaultSettings[tables[i]] = {
          headers: headers,
          expanded: expanded,
        };
      }
    }
    store.state.settings.defaultTables = defaultSettings;
  },
  setSettings: (settings) => {
    // Sem uz neni potreba pridavat kazde table settings, automatizovano
    const tables = Object.keys(store.state.tables);
    for (let i = 0; i < tables.length; i++) {
      if (settings[tables[i]]) {
        localStorage.setItem(tables[i], settings[tables[i]]);
      }
    }
  },
  getSettings: () => {
    // Sem uz neni potreba pridavat kazde table settings, automatizovano
    const tables = Object.keys(store.state.tables);
    for (let i = 0; i < tables.length; i++) {
      if (localStorage.getItem(tables[i])) {
        store.state.tables[tables[i]] = JSON.parse(
          localStorage.getItem(tables[i])
        );
      }
    }
  },
  setSetting: (route, table, parent) => {
    let name = "table";
    if (route === "ruleset") {
      name = "auditorRulesetTable";
    } else if (route === "cve") {
      name = "auditorCveTable";
    } else if (route === "integrity") {
      name = "auditorIntegrityTable";
    } else if (route === "templates") {
      name = "auditorTemplatesTable";
    } else if (route === "rules") {
      name = "auditorTemplateRulesTable";
    } else if (route === "syslog") {
      name = "monitorEventsSyslogTable";
    } else if (route === "system") {
      name = "monitorEventsSystemTable";
    } else if (route === "threats") {
      name = "monitorEventsThreatsTable";
    } else if (route === "successful") {
      name = "monitorInterconnectorSuccessfulTable";
    } else if (route === "unsuccessful") {
      name = "monitorInterconnectorUnsuccessfulTable";
    } else if (route === "reject") {
      name = "monitorInterconnectorRejectTable";
    } else if (route === "revoke") {
      name = "monitorInterconnectorRevokeTable";
    } else if (route === "security_policy") {
      if (parent === "awaitingApproval") {
        name = "awaitingApprovalSecurityPolicyTable";
      } else {
        name = "interconnectorSecurityPolicyTable";
      }
    } else if (route === "data_feeds") {
      name = "awaitingApprovalDataFeedsTable";
    } else if (route === "default") {
      name = "interconnectorRulesDefaultTable";
    } else if (route === "script") {
      name = "interconnectorRulesScriptTable";
    } else if (route === "miners") {
      name = "settingsMinerTable";
    } else if (route === "firewalls") {
      name = "settingsFirewallTable";
    } else if (route === "central_management") {
      name = "settingsCentralManagementTable";
    } else if (route === "firewall_groups") {
      name = "settingsFirewallGroupsTable";
    } else if (route === "parser") {
      name = "settingsParserTable";
    } else if (route === "integration") {
      name = "settingsListenerTable";
    } else if (route === "listener") {
      name = "settingsIntegrationTable";
    } else if (route === "apiPaloAlto") {
      name = "settingsApiPaloAltoTable";
    } else if (route === "apiFortinet") {
      name = "settingsApiFortinetTable";
    } else if (route === "apiCheckpoint") {
      name = "settingsApiCheckpointTable";
    } else if (route === "apiSophos") {
      name = "settingsApiSophosTable";
    } else if (route === "apiCustom") {
      name = "settingsApiCustomTable";
    } else if (route === "systemBackupRemote") {
      name = "systemBackupRemoteTable";
    } else if (route === "addressListsCustomAll") {
      name = "dataFeedsAddrListCustomAllTable";
    } else if (route === "addressListsCustomSource") {
      name = "dataFeedsAddrListCustomSrcTable";
    } else if (route === "addressListsCustomDestination") {
      name = "dataFeedsAddrListCustomDstTable";
    } else if (route === "addressListsPredefinedAll") {
      name = "dataFeedsAddrListPredefinedAllTable";
    } else if (route === "addressListsConnection") {
      name = "dataFeedsAddrListConnectionTable";
    } else if (route === "domainListsCustomAll") {
      name = "dataFeedsDomainListAllTable";
    } else if (route === "domainListsCustomShow") {
      name = "dataFeedsDomainListShowTable";
    } else if (route === "domainListsPredefinedAll") {
      name = "dataFeedsDomainListPredefinedAllTable";
    } else if (route === "domainListsPredefinedData") {
      name = "dataFeedsDomainListPredefinedDataTable";
    } else if (route === "urlListsCustomAll") {
      name = "dataFeedsUrlListCustomAllTable";
    } else if (route === "urlListsPredefinedAll") {
      name = "dataFeedsUrlListPredefinedAllTable";
    } else if (route === "urlListsPredefinedShow") {
      name = "dataFeedsUrlListCustomShowTable";
    }
    localStorage.setItem(name, JSON.stringify(table));
  },
};
