<template>
  <v-container :fluid="true" class="pt-0 mh-100 form">
    <topbar
      :size="size"
      :has-timeline="true"
      :mount-time="time"
      has-bar-chart
      @toggle-chart="toggleCharts"
      @resize="resize($event)"
      @timeline="changeTime($event)"
      @close="close"
    />
    <v-row class="form-block mt-0">
      <v-col cols="12" class="py-0 px-0">
        <v-row v-if="size !== 1 && !loading" class="mt-0 mx-0">
          <v-col cols="12" class="pb-2 pt-3">
            <chart
              :refresh="refreshChart"
              :id="'form-sidebar-system-logs-widget'"
              :x-axis-data="xAxisData"
              :data="[
                this.data.auditor,
                this.data.config,
                this.data.firewall,
                this.data.login,
                this.data.miner,
                this.data.rule,
                this.data.secpolicy,
                this.data.system,
              ]"
              :legend="legend"
              :height="'600px'"
            />
            <v-overlay :value="loading">
              <v-progress-circular
                absolute
                indeterminate
                size="40"
                color="good-3"
              />
            </v-overlay>
            <v-overlay :value="failed">
              <v-card-text>
                {{ $t("widgets.systemLogs.headline") }} - loading data from api
                failed.
              </v-card-text>
              <v-card-actions>
                <v-btn small color="bad-2" class="mx-auto" @click="loadData">
                  Reload
                </v-btn>
              </v-card-actions>
            </v-overlay>
            <v-overlay :value="showCountdown">
              <countdown :radius="30" :stroke-width="5" @action="loadData" />
              <v-card-text class="overlay-text">
                {{ $t("widgets.systemLogs.headline") }} -
                {{ processingMessage }}
              </v-card-text>
            </v-overlay>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Topbar from "@/layout/FormSidebar/topbar";
import chart from "@/components/Widgets/SystemLogsWidget/chart";
import { widgetSystemLogs } from "@/api/dashboard";
import Countdown from "@/components/Countdown";

export default {
  components: {
    Topbar,
    chart,
    Countdown,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    size: {
      type: Number,
      default: function () {
        return 2;
      },
    },
  },
  data() {
    return {
      loading: true,
      failed: false,
      refreshChart: false,

      time: "lastweek",
      data: {
        auditor: {
          name: "Auditor",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        config: {
          name: "Config",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        firewall: {
          name: "Firewall",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        login: {
          name: "Login",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        miner: {
          name: "Miner",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        rule: {
          name: "Rule",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        secpolicy: {
          name: "SecPolicy",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        system: {
          name: "System",
          type: "bar",
          areaStyle: {},
          smooth: true,
          barGap: 0,
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      },
      xAxisData: [],
      legend: {
        data: [
          "Auditor",
          "Config",
          "Firewall",
          "Login",
          "Miner",
          "Rule",
          "SecPolicy",
          "System",
        ],
        orient: "horizontal",
        left: "center",
      },

      processingMessage: "",
      showCountdown: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    resize(size) {
      this.$emit("resize", size);
    },
    close() {
      this.$emit("close", true);
    },
    change() {
      this.$emit("change", "firewall");
    },
    changeTime(time) {
      this.time = time;
      this.loadData();
    },
    loadData() {
      this.failed = false;
      this.loading = true;
      this.showCountdown = false;
      this.processingMessage = "";
      this.refreshChart = false;

      const data = {
        period: this.time,
      };
      widgetSystemLogs(data)
        .then((response) => {
          this.clearData();
          if (response.data.message) {
            this.processingMessage = response.data.message;
            this.showCountdown = true;
          } else {
            const self = this;
            response.data.forEach(function (value) {
              self.xAxisData.push(value.period);

              self.data.auditor.data.push(value.auditor);
              self.data.config.data.push(value.config);
              self.data.firewall.data.push(value.firewall);
              self.data.login.data.push(value.login);
              self.data.miner.data.push(value.miner);
              self.data.rule.data.push(value.rule);
              self.data.secpolicy.data.push(value.secpolicy);
              self.data.system.data.push(value.system);
            });
            this.refreshChart = true;
          }
          this.loading = false;
        })
        .catch((e) => {
          this.$toast.open({
            message: e.response
              ? e.response.status + " - Data loading failed!"
              : e,
            type: "error",
            position: "top-right",
            duration: 2000,
          });
          this.loading = false;
          this.failed = true;
        });
    },
    clearData() {
      this.xAxisData = [];
      this.data.auditor.data = [];
      this.data.config.data = [];
      this.data.firewall.data = [];
      this.data.login.data = [];
      this.data.miner.data = [];
      this.data.rule.data = [];
      this.data.secpolicy.data = [];
      this.data.system.data = [];
    },
    toggleCharts() {
      const datasArray = Object.entries(this.data);
      datasArray.map((data) => {
        if (data[1].type === "bar") {
          data[1].type = "line";
        } else {
          data[1].type = "bar";
        }
      });
    },
  },
};
</script>
