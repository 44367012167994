import request from "@/utils/request";

export function getMaintenanceCommandList() {
  return request({
    url: "api/system/maintenance/command/list",
    method: "get",
  });
}

export function getMaintenanceCommandResult(id) {
  return request({
    url: "api/system/maintenance/command/results/" + id,
    method: "get",
  });
}
export function executeCommand(data) {
  return request({
    url: "api/system/maintenance/command/execute",
    method: "post",
    data: data,
    timeout: 360000,
  });
}
