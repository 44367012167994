<template>
  <v-container :fluid="true" class="px-0 py-0">
    <v-row class="mx-0 mt-0">
      <v-col cols="6" class="pb-0">
        <label class="font-roboto-condensed-regular font-size-15">
          Firewall Type
        </label>
        <v-radio-group v-model="fwUnit" row class="mt-0">
          <v-radio label="Firewall" value="firewall" @click="update" />
          <v-radio label="Firewall group" value="group" @click="update" />
          <v-radio label="Custom" value="custom" @click="update" />
        </v-radio-group>
      </v-col>
      <!--FIREWALL-->
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-col v-if="fwUnit === 'firewall'" cols="6" class="mt-0 mx-0 pb-0">
          <label class="font-roboto-condensed-regular font-size-15">
            Firewall
          </label>
          <v-autocomplete
            v-model="firewall"
            :items="firewalls"
            item-text="name"
            item-value="id"
            label="Firewall"
            placeholder="Firewall"
            dense
            solo
            @change="changeFw"
          />
        </v-col>
      </transition>
      <!--END FIREWALL-->
      <!--FIREWALL GROUP-->
      <transition
        enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="d-none"
      >
        <v-col v-if="fwUnit === 'group'" cols="6" class="pb-0 mt-0 mx-0">
          <label class="font-roboto-condensed-regular font-size-15">
            Firewall group
          </label>
          <v-autocomplete
            v-model="firewallGroup"
            :items="firewallGroups"
            item-text="name"
            item-value="id"
            label="Firewall group"
            placeholder="Firewall group"
            dense
            solo
            @change="changeFw"
          />
        </v-col>
      </transition>
      <!--END FIREWALL GROUP-->
    </v-row>

    <v-divider />

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-row
        v-if="
          (fwUnit === 'firewall' && firewall !== '') ||
          (fwUnit === 'group' && firewallGroup !== '')
        "
        class="mt-0 mx-0"
      >
        <v-col cols="12" class="pb-1 pt-4">
          <v-btn
            class="text-capitalize mr-2"
            :text="activeTab !== 'policy'"
            :color="activeTab === 'policy' ? 'primary' : ''"
            :outlined="activeTab === 'policy'"
            rounded
            @click="activeTab = 'policy'"
          >
            Policy calls
          </v-btn>

          <v-btn
            class="text-capitalize mr-2"
            :text="activeTab !== 'rollback'"
            :color="activeTab === 'rollback' ? 'primary' : ''"
            :outlined="activeTab === 'rollback'"
            rounded
            @click="activeTab = 'rollback'"
          >
            Rollback calls
          </v-btn>
        </v-col>
      </v-row>
    </transition>

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-row v-if="fwUnit === 'custom'" class="mt-0 mx-0">
        <v-col cols="12" class="pb-1 pt-4">
          <v-btn
            class="text-capitalize mr-2"
            :text="activeTab !== 'policy'"
            :color="activeTab === 'policy' ? 'primary' : ''"
            :outlined="activeTab === 'policy'"
            rounded
            @click="activeTab = 'policy'"
          >
            Custom calls
          </v-btn>
        </v-col>
      </v-row>
    </transition>

    <!--MEMBER TIMEOUT-->
    <v-row
      class="mt-5 mx-0"
      v-if="fwUnit === 'firewall' && vendor === 1 && !apiCallsLoading"
    >
      <v-col cols="6" class="pb-0 pt-5">
        <label class="font-roboto-condensed-regular font-size-15"
          >Member Timeout
        </label>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon color="primary" x-small v-on="on"> fas fa-question </v-icon>
          </template>
          <span class="font-roboto-condensed-regular font-size-15"
            >Variable for specific call</span
          >
        </v-tooltip>
        <v-text-field
          v-model="membertimeout"
          :rules="membertimeoutRules"
          type="number"
          label="Timeout"
          min="1"
          max="3600"
          placeholder="3600"
          dense
          solo
          @keyup="update"
        />
      </v-col>
    </v-row>
    <!--END MEMBER TIMEOUT-->

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-row
        v-if="
          (fwUnit === 'firewall' && firewall !== '' && !apiCallsLoading) ||
          (fwUnit === 'group' && firewallGroup !== '' && !apiCallsLoading)
        "
        class="mx-0 mb-2"
        :class="vendor === 1 ? 'mt-0' : 'mt-5'"
      >
        <!--POLICY CALLS-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-col
            v-if="activeTab === 'policy'"
            cols="12"
            class="pb-0 pt-5 d-flex"
          >
            <draggable :list="policyCalls" @end="update" class="w-100 mr-4">
              <v-row
                v-for="(call, index) in policyCalls"
                :key="call.id"
                class="my-0 mx-0"
              >
                <div
                  class="d-flex w-100 elevation-2 cursor-pointer api-call-block mb-2"
                  v-if="index < policyCalls.length / 2"
                >
                  <v-col cols="11" class="py-2 font-size-15 mr-auto">
                    <div class="toggle mt-0 w-100">
                      <v-switch
                        v-model="call.chosen"
                        :value="true"
                        hide-details
                        :label="call.name"
                        inset
                        @change="update"
                        :class="[call.chosen ? 'v-switch-on' : 'v-switch-off']"
                        class="target-background v-switch-off"
                      />
                    </div>
                  </v-col>
                  <v-col cols="1" class="py-2 font-size-15 text-right">
                    <v-icon small>fas fa-arrows-alt-v</v-icon>
                  </v-col>
                </div>
              </v-row>
            </draggable>
            <draggable :list="policyCalls" @end="update" class="w-100">
              <v-row
                v-for="(call, index) in policyCalls"
                :key="call.id"
                class="my-0 mx-0"
              >
                <div
                  class="d-flex w-100 elevation-2 cursor-pointer api-call-block mb-2"
                  v-if="index + 1 > policyCalls.length / 2"
                >
                  <v-col cols="11" class="py-2 font-size-15 mr-auto">
                    <div class="toggle mt-0">
                      <v-switch
                        v-model="call.chosen"
                        :value="true"
                        hide-details
                        :label="call.name"
                        inset
                        @change="update"
                        :class="[call.chosen ? 'v-switch-on' : 'v-switch-off']"
                        class="target-background v-switch-off"
                      />
                    </div>
                  </v-col>
                  <v-col cols="1" class="py-2 font-size-15 text-right">
                    <v-icon small>fas fa-arrows-alt-v</v-icon>
                  </v-col>
                </div>
              </v-row>
            </draggable>
          </v-col>
        </transition>
        <!--END POLICY CALLS-->

        <!--ROLLBACK CALLS-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-col
            v-if="activeTab === 'rollback'"
            cols="12"
            class="pb-0 pt-5 d-flex"
          >
            <draggable :list="rollbackCalls" @end="update" class="w-100 mr-4">
              <v-row
                v-for="(rollback, index) in rollbackCalls"
                :key="rollback.id"
                class="my-0 mx-0"
              >
                <div
                  class="d-flex w-100 elevation-2 cursor-pointer api-call-block mb-2"
                  v-if="index < rollbackCalls.length / 2"
                >
                  <v-col cols="11" class="py-2 font-size-15 mr-auto">
                    <div class="toggle mt-0">
                      <v-switch
                        v-model="rollback.chosen"
                        :value="true"
                        hide-details
                        :label="rollback.name"
                        @change="update"
                        inset
                        :class="[
                          rollback.chosen ? 'v-switch-on' : 'v-switch-off',
                        ]"
                        class="target-background v-switch-off"
                      />
                    </div>
                  </v-col>
                  <v-col cols="1" class="py-2 font-size-15 text-right">
                    <v-icon small>fas fa-arrows-alt-v</v-icon>
                  </v-col>
                </div>
              </v-row>
            </draggable>
            <draggable :list="rollbackCalls" @end="update" class="w-100 mr-4">
              <v-row
                v-for="(rollback, index) in rollbackCalls"
                :key="rollback.id"
                class="my-0 mx-0"
              >
                <div
                  class="d-flex w-100 elevation-2 cursor-pointer api-call-block mb-2"
                  v-if="index + 1 > rollbackCalls.length / 2"
                >
                  <v-col cols="11" class="py-2 font-size-15 mr-auto">
                    <div class="toggle mt-0">
                      <v-switch
                        v-model="rollback.chosen"
                        :value="true"
                        hide-details
                        :label="rollback.name"
                        @change="update"
                        inset
                        :class="[
                          rollback.chosen ? 'v-switch-on' : 'v-switch-off',
                        ]"
                        class="target-background v-switch-off"
                      />
                    </div>
                  </v-col>
                  <v-col cols="1" class="py-2 font-size-15 text-right">
                    <v-icon small>fas fa-arrows-alt-v</v-icon>
                  </v-col>
                </div>
              </v-row>
            </draggable>
          </v-col>
        </transition>
        <!--END ROLLBACK CALLS-->
      </v-row>
    </transition>

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-row
        v-if="fwUnit === 'custom'"
        class="mx-0 mb-2"
        :class="vendor === 1 ? 'mt-0' : 'mt-5'"
      >
        <!--CUSTOM CALLS-->
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="d-none"
        >
          <v-col cols="12" class="pb-0 pt-5 d-flex">
            <draggable :list="customCalls" @end="update" class="w-100 mr-4">
              <v-row
                v-for="(call, index) in customCalls"
                :key="call.id"
                class="my-0 mx-0"
              >
                <div
                  class="d-flex w-100 elevation-2 cursor-pointer api-call-block mb-2"
                  v-if="index < customCalls.length / 2"
                >
                  <v-col cols="11" class="py-2 font-size-15">
                    <div class="toggle mt-0">
                      <v-switch
                        v-model="call.chosen"
                        :value="true"
                        hide-details
                        :label="call.name"
                        @change="update"
                        inset
                        :class="[call.chosen ? 'v-switch-on' : 'v-switch-off']"
                        class="target-background v-switch-off"
                      />
                    </div>
                  </v-col>
                  <v-col cols="1" class="py-2 font-size-15 text-right">
                    <v-icon small>fas fa-arrows-alt-v</v-icon>
                  </v-col>
                </div>
              </v-row>
            </draggable>
            <draggable :list="customCalls" @end="update" class="w-100 mr-4">
              <v-row
                v-for="(call, index) in customCalls"
                :key="call.id"
                class="my-0 mx-0"
              >
                <div
                  class="d-flex w-100 elevation-2 cursor-pointer api-call-block mb-2"
                  v-if="index + 1 > customCalls.length / 2"
                >
                  <v-col cols="11" class="py-2 font-size-15">
                    <div class="toggle mt-0">
                      <v-switch
                        v-model="call.chosen"
                        :value="true"
                        hide-details
                        :label="call.name"
                        @change="update"
                        inset
                        :class="[call.chosen ? 'v-switch-on' : 'v-switch-off']"
                        class="target-background v-switch-off"
                      />
                    </div>
                  </v-col>
                  <v-col cols="1" class="py-2 font-size-15 text-right">
                    <v-icon small>fas fa-arrows-alt-v</v-icon>
                  </v-col>
                </div>
              </v-row>
            </draggable>
          </v-col>
        </transition>
        <!--END CUSTOM CALLS-->
      </v-row>
    </transition>

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="d-none"
    >
      <v-row
        v-if="
          (fwUnit === 'firewall' && firewall !== '' && apiCallsLoading) ||
          (fwUnit === 'custom' && customApiCallsLoading)
        "
        class="mx-0 mt-5 mb-3"
      >
        <v-col cols="12">
          <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
          <span class="font-roboto-condensed-light font-size-15 ml-2"
            >Loading...</span
          >
        </v-col>
      </v-row>
    </transition>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import { Errors } from "@/utils/error";
import { getApiCallsByFw } from "@/api/settings/apicall";
import { getCustomApiList } from "@/api/settings/apicallcustom";

export default {
  components: {
    draggable,
  },
  props: {
    id: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    mountData: {
      type: Object,
      default: function () {
        return null;
      },
    },
    firewalls: {
      type: Array,
      default: function () {
        return [];
      },
    },
    firewallGroups: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      fwUnit: "firewall",
      ruleApiType: "firewall",
      activeTab: "policy",
      apiCallsLoading: false,
      customApiCallsLoading: false,

      firewall: "",
      firewallGroup: "",
      policyCalls: [],
      customCalls: [],
      rollbackCalls: [],
      vendor: null,
      lastVendor: null,
      membertimeout: 3600,
      membertimeoutRules: [(v) => !!v || "Timeout is required"],
    };
  },
  created() {
    if (this.mountData) {
      this.fwUnit = this.mountData.fwUnit;
      this.firewall = this.mountData.firewall;
      this.firewallGroup = this.mountData.firewallGroup;
      this.policyCalls = this.mountData.policy;
      this.customCalls = this.mountData.customCalls;
      this.ruleApiType = this.mountData.ruleApiType;
      this.rollbackCalls = this.mountData.rollback;
      this.vendor = this.mountData.vendor;
      this.membertimeout = this.mountData.membertimeout
        ? this.mountData.membertimeout
        : 3600;
    }
  },
  methods: {
    changeFw() {
      // Skontrolujeme či už máme PloAlto Cally načítané.
      // Nenačítavame samostatne podľa verzie
      const foundObject = this.firewalls.find(
        (obj) => obj.id === this.firewall
      );
      if (foundObject.vendor === 1 && this.lastVendor === 1) {
        return;
      }
      this.apiCallsLoading = true;
      const data = {
        type: this.fwUnit === "firewall" ? "firewall" : "firewallgroup",
        id: this.fwUnit === "firewall" ? this.firewall : this.firewallGroup,
      };
      getApiCallsByFw(data)
        .then((response) => {
          this.policyCalls = [];
          this.rollbackCalls = [];
          const self = this;
          this.vendor = response.vendor;
          this.lastVendor = response.vendor;
          response.data.forEach(function (call) {
            self.policyCalls.push({
              id: call.id,
              name: call.name,
              chosen: false,
            });
            self.rollbackCalls.push({
              id: call.id,
              name: call.name,
              chosen: false,
            });
          });
          this.apiCallsLoading = false;
          this.update();
        })
        .catch((e) => {
          this.apiCallsLoading = false;
          Errors.show(e);
        });
    },
    loadCustom() {
      this.customApiCallsLoading = true;
      getCustomApiList()
        .then((response) => {
          this.customCalls = [];
          const self = this;
          this.vendor = response.vendor;
          response.data.forEach(function (call) {
            self.customCalls.push({
              id: call.id,
              name: call.name,
              chosen: false,
            });
          });
          this.customApiCallsLoading = false;
          this.update();
        })
        .catch((e) => {
          this.customApiCallsLoading = false;
          Errors.show(e);
        });
    },
    update() {
      if (this.fwUnit === "custom" && this.customCalls.length === 0) {
        this.loadCustom();
      }
      this.$emit("update", {
        fwUnit: this.fwUnit,
        firewall: this.firewall,
        firewallGroup: this.firewallGroup,
        policy: this.policyCalls,
        rollback: this.rollbackCalls,
        customCalls: this.customCalls,
        ruleApiType: this.fwUnit,
        membertimeout: this.membertimeout,
        vendor: this.vendor,
      });
    },
  },
};
</script>

<style lang="scss">
.form-block {
  .api-call-block {
    border-radius: 4px;
  }
}
.theme--dark .api-call-block {
  background: #4e4f4e !important;
}
.theme--light .api-call-block {
  background: #f4f4f4 !important;
}
</style>
